import { Box, Typography } from '@mui/material';
import { appColors } from 'src/theme';

import { Logo } from '../AppLogo';

export const AppFooter = (): React.JSX.Element => (
  <Box
    alignItems="center"
    display="flex"
    flexDirection="row"
    justifyContent={{ xs: 'center', sm: 'flex-end' }}
    marginBottom={{ xs: 1, sm: 3 }}
    marginTop={1}
    paddingX={3}
  >
    <Typography
      color="secondary.main"
      fontSize={10}
      fontWeight={300}
      letterSpacing={1}
      marginRight={1}
      noWrap
    >
      POWERED BY
    </Typography>
    <Logo size={36} />
    <Typography
      color={appColors.chain}
      fontFamily="Poppins"
      fontWeight={600}
      marginLeft={1}
      marginTop="-2px"
      noWrap
      variant="body1"
    >
      agriplace
    </Typography>
  </Box>
);
