import { LoadingButton } from '@mui/lab';
import { Box, Chip } from '@mui/material';
import {
  GridActionsCellItem,
  type GridEnrichedColDef,
  type GridRenderCellParams,
  type GridRowParams,
} from '@mui/x-data-grid-pro';
import { type ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AccountTypeEnum,
  type InviteeOrganizationInvitationListOutputItem,
  type UserProfileDetailOutput,
} from 'src/__generated__/InternalApiTypes';
import {
  CustomNoRowsOverlay,
  MobileCompatibleDataGrid,
} from 'src/components/common/MobileCompatibleDataGrid';
import { useMutationFeedback } from 'src/hooks';
import { accountInvitationTableStrings, commonStrings } from 'src/languages/en-UK';
import {
  useAcceptOrganizationInviteeInvitationMutation,
  useGetOrganizationInviteeInvitationListQuery,
  useRejectOrganizationInviteeInvitationMutation,
} from 'src/services/farmApi';
import { ROUTE_PATH_CREATE_ORGANIZATION, ROUTE_PATH_DASHBOARD } from 'src/settings';

interface AccountInvitationTableProps {
  currentUser: UserProfileDetailOutput | undefined;
  isLoading: boolean;
}

export const AccountInvitationTable = ({
  currentUser,
  isLoading = false,
}: AccountInvitationTableProps): React.JSX.Element => {
  const navigate = useNavigate();
  const { data: accountInvitations, isLoading: isLoadingAccountInvitations } =
    useGetOrganizationInviteeInvitationListQuery({});
  const [acceptOrganizationInviteeInvitation, acceptOrganizationInviteeInvitationResult] =
    useAcceptOrganizationInviteeInvitationMutation();
  const [rejectOrganizationInviteeInvitation, rejectOrganizationInviteeInvitationResult] =
    useRejectOrganizationInviteeInvitationMutation();

  useMutationFeedback({
    result: acceptOrganizationInviteeInvitationResult,
    successMessage: accountInvitationTableStrings('joinOrganizationSuccess'),
    onSuccess: () => {
      navigate(ROUTE_PATH_DASHBOARD);
    },
    errorMessage: accountInvitationTableStrings('joinOrganizationFail'),
  });

  useMutationFeedback({
    result: rejectOrganizationInviteeInvitationResult,
    onSuccess: () => {
      if (
        currentUser?.memberships &&
        currentUser.memberships.length <= 0 &&
        currentUser.account_type === AccountTypeEnum.Farmer
      ) {
        navigate(ROUTE_PATH_CREATE_ORGANIZATION);
      }
    },
    successMessage: accountInvitationTableStrings('declineInvitationSuccess'),
    errorMessage: accountInvitationTableStrings('declineInvitationFail'),
  });

  const columns: GridEnrichedColDef[] = [
    {
      field: 'inviter_name',
      headerName: accountInvitationTableStrings('invitingOrganization'),
      flex: 2,
    },
    {
      field: 'role_labels',
      headerName: commonStrings('role'),
      flex: 1.2,
      valueGetter: (params) => {
        const rolesLabel: string[] = [];
        params?.value?.forEach((role: string) => {
          rolesLabel.push(role);
        });
        return rolesLabel?.join(', ');
      },
    },
    {
      field: 'status',
      headerName: commonStrings('status'),
      flex: 1.2,
      renderCell: (
        params: GridRenderCellParams<
          string,
          InviteeOrganizationInvitationListOutputItem,
          ReactNode
        >,
      ) =>
        params.value && (
          <Chip
            label={params.value}
            variant="outlined"
          />
        ),
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 0.8,
      align: 'right',
      getActions: (params: GridRowParams<InviteeOrganizationInvitationListOutputItem>) => [
        <LoadingButton
          key={`${params.row.uuid}_join`}
          color="info"
          loading={acceptOrganizationInviteeInvitationResult?.isLoading}
          onClick={() => {
            if (params.row.uuid) {
              acceptOrganizationInviteeInvitation({
                id: params.row.uuid,
              });
            }
          }}
          size="small"
          variant="contained"
        >
          {accountInvitationTableStrings('join')}
        </LoadingButton>,
        <GridActionsCellItem
          key={`${params.row.uuid}_decline`}
          label={accountInvitationTableStrings('declineInvitation')}
          onClick={() => {
            if (params.row.uuid) {
              rejectOrganizationInviteeInvitation({
                id: params.row.uuid,
              });
            }
          }}
          showInMenu
          sx={{ color: 'error.main' }}
        />,
      ],
    },
  ];

  return (
    <Box
      height="calc(100vh - 264px)"
      marginBottom={2}
    >
      <MobileCompatibleDataGrid
        columns={columns}
        loading={isLoading || isLoadingAccountInvitations}
        NoRowsOverlay={() =>
          CustomNoRowsOverlay(accountInvitationTableStrings('invitationTableNoRowsMsg'))
        }
        rows={accountInvitations || []}
        sortModel={[{ field: 'name', sort: 'asc' }]}
        sx={{
          '& .MuiDataGrid-actionsCell': {
            flex: 1,
            justifyContent: 'space-between',
          },
        }}
      />
    </Box>
  );
};
