import LoadingButton from '@mui/lab/LoadingButton';
import { Container, Paper, Stack, Toolbar, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppLogo } from 'src/components/common';
import { FormikPasswordTextField } from 'src/components/formikcomponents/FormikPasswordTextField';
import { FormikTextField } from 'src/components/formikcomponents/FormikTextField';
import { useAppDispatch } from 'src/hooks';
import { commonStrings, resetPasswordPageStrings } from 'src/languages/en-UK';
import { useResetPasswordMutation } from 'src/services/farmApi';
import { ROUTE_PATH_LOG_IN } from 'src/settings';
import { openSnackbar } from 'src/store/snackbarSlice';
import { canSubmitFormik } from 'src/utils/canSubmitFormik';
import * as Yup from 'yup';

export const ResetPasswordPage = (): React.JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const [isResetPasswordPending, setIsResetPasswordPending] = useState(false);
  const [resetPassword, { error: resetPasswordError, isSuccess: isResetPasswordSuccess }] =
    useResetPasswordMutation();

  const emailParam = params.get('email') || '';
  const codeParam = params.get('code') || '';

  useEffect(() => {
    if (resetPasswordError && 'data' in resetPasswordError) {
      const errorData = resetPasswordError.data as { code: string; message: string };

      dispatch(
        openSnackbar({
          severity: 'error',
          message: errorData.message,
        }),
      );
    }

    if (isResetPasswordSuccess) {
      dispatch(
        openSnackbar({
          severity: 'success',
          message: resetPasswordPageStrings('resetPasswordSuccess'),
        }),
      );
      navigate(ROUTE_PATH_LOG_IN);
    }
  }, [dispatch, isResetPasswordSuccess, navigate, resetPasswordError]);

  const formik = useFormik({
    initialValues: {
      email: emailParam,
      code: codeParam,
      password: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required(commonStrings('required')),
      code: Yup.string().required(commonStrings('required')),
      password: Yup.string().required(commonStrings('required')),
    }),
    onSubmit: async (values) => {
      const { email, code, password } = values;
      setIsResetPasswordPending(true);
      await resetPassword({ email, code, password });
      setIsResetPasswordPending(false);
    },
  });

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        justifyContent: 'center',
      }}
    >
      <Paper sx={{ p: 2, my: 4 }}>
        <AppLogo
          large
          showName
          sx={{ pt: 2, pb: 2.5, mx: 'auto' }}
        />
        <Stack spacing={2}>
          <Typography
            align="center"
            variant="h2"
          >
            {commonStrings('resetPassword')}
          </Typography>
          <form
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <Stack spacing={2}>
              <FormikTextField
                formik={formik}
                id="email"
                label={commonStrings('email')}
                required
                type="email"
              />
              <FormikTextField
                formik={formik}
                id="code"
                label={resetPasswordPageStrings('resetCode')}
                required
              />
              <FormikPasswordTextField
                formik={formik}
                id="password"
                label={commonStrings('newPassword')}
                required
              />
              <Toolbar
                disableGutters
                sx={{ justifyContent: 'flex-end' }}
                variant="dense"
              >
                <LoadingButton
                  disabled={!canSubmitFormik(formik, true)}
                  fullWidth
                  loading={isResetPasswordPending}
                  type="submit"
                  variant="contained"
                >
                  {resetPasswordPageStrings('resetMyPassword')}
                </LoadingButton>
              </Toolbar>
            </Stack>
          </form>
        </Stack>
      </Paper>
    </Container>
  );
};
