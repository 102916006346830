import { useEffect, useState } from 'react';
import { SignUpOriginEnum } from 'src/services/farmApi/endpoints/auth';

export const useSetTitle = () => {
  const [title, setTitle] = useState('Agriplace Farm');

  useEffect(() => {
    document.title = title;
  }, [title]);

  const changeTitle = (newTitle: string, origin: SignUpOriginEnum) => {
    let dynamicTitle = '';
    switch (origin) {
      case SignUpOriginEnum.SMK:
        dynamicTitle = `${newTitle} - On the way to PlanetProof`;
        break;
      case SignUpOriginEnum.LEAF:
        dynamicTitle = `${newTitle} - LEAF Marque`;
        break;
      default:
        dynamicTitle = `${newTitle} - Agriplace Farm`;
        break;
    }
    setTitle(dynamicTitle);
  };

  return {
    title,
    changeTitle,
  };
};
