import { FilterListRounded, StickyNote2Rounded } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  AppBar,
  Badge,
  Box,
  Button,
  Container,
  Paper,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { groupBy } from 'lodash';
import { type MouseEvent, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GroupedVirtuoso, type GroupedVirtuosoHandle } from 'react-virtuoso';
import {
  type AssessmentControlPointListOutputItem,
  type AssessmentModuleSidebarOutputItem,
  AssessmentStatusEnum,
  OrganizationTypesEnum,
  RolesEnum,
} from 'src/__generated__/InternalApiTypes';
import { AssessmentActionBarButtons } from 'src/components/AssessmentActionBarButtons';
import { LoadingArea } from 'src/components/common';
import { ControlPoint } from 'src/components/ControlPoint';
import { KebabMenuSlug } from 'src/components/ControlPointHeaderKebabMenu';
import {
  AssessmentControlPointStepProgressDialog,
  type AssessmentControlPointStepProgressDialogProps,
} from 'src/components/dialogs/AssessmentControlPointStepProgressDialog';
import { AssessmentControlPointCategoriesDrawer } from 'src/components/drawers/AssessmentControlPointCategoriesDrawer';
import { SidebarToggleButtonTypeEnum } from 'src/components/drawers/AssessmentControlPointDrawerToggle';
import { AssessmentControlPointModulesDrawer } from 'src/components/drawers/AssessmentControlPointModulesDrawer';
import { AssessmentControlPointStepFilters } from 'src/components/drawers/AssessmentControlPointStepFilters';
import { ThemeContext } from 'src/components/ThemeProvider';
import {
  ControlPointFilterProvider,
  defaultState,
  type FilterStateType,
} from 'src/context/ControlPointFilterContext';
import { ControlPointMasterDataProvider } from 'src/context/ControlPointMasterData';
import { type DynamicObjectType } from 'src/global';
import {
  useCurrentOrganization,
  useCurrentUserRoles,
  useDialogState,
  useMutationFeedback,
  useTenantId,
  useUserAuth,
} from 'src/hooks';
import { useGetUserOrigin } from 'src/hooks/useGetUserOrigin';
import { assessmentControlPointStepStrings, commonStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import {
  useGetAssessmentControlPointAnswerListQuery,
  useGetAssessmentControlPointListQuery,
  useGetAssessmentQuery,
  useGetModuleSidebarListQuery,
  useUpdateAssessmentToBeConfirmedMutation,
} from 'src/services/farmApi';
import { SignUpOriginEnum } from 'src/services/farmApi/endpoints/auth';
import { ROUTE_PATH_ASSESSMENT_ARCHIVE } from 'src/settings';

import { useGetAssessmentCategories } from '../AssessmentEvidenceStep';
import { type CategoryData } from '../AssessmentEvidenceStep/AssessmentEvidenceStep.hooks';
import { type StepperContentBaseProps } from '../StepperPage';

const DRAWER_WIDTH = 360; // The difference between the lg and md breakpoints

export const AssessmentControlPointStep = ({
  ...props
}: StepperContentBaseProps): React.JSX.Element => {
  const tid = useTenantId();
  const currentUser = useUserAuth();
  const virtuosoRef = useRef<GroupedVirtuosoHandle>(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [assessmentId] = useContext(AssessmentPageContext);
  const { data: organization } = useCurrentOrganization();
  const currentUserRoles = useCurrentUserRoles();
  const isFarmManager = currentUserRoles.includes(RolesEnum.FarmManager);
  const isFarmEmployee = currentUserRoles.includes(RolesEnum.FarmEmployee);
  const isAuditor = currentUserRoles.includes(RolesEnum.Auditor);
  const { theme } = useContext(ThemeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: assessmentDetail } = useGetAssessmentQuery(
    { tid, id: assessmentId },
    {
      skip: !assessmentId || !tid,
    },
  );
  const isAssessmentAuthor = assessmentDetail?.author_id === currentUser?.userId;
  const isCBManager =
    currentUserRoles.includes(RolesEnum.CertificationBodyManager) && !isAssessmentAuthor;
  const isReviewer =
    isAuditor &&
    !isAssessmentAuthor &&
    assessmentDetail?.status === AssessmentStatusEnum.ToBeReviewed;

  const [filterState, setFilterState] = useState({
    ...defaultState,
  });
  const [isNextTaskLoading, setIsNextTaskLoading] = useState(false);
  const [isPreviousTaskLoading, setIsPreviousTaskLoading] = useState(false);
  const [selectedMode, setSelectedMode] = useState<SidebarToggleButtonTypeEnum>(
    assessmentDetail?.status === AssessmentStatusEnum.ToBeAudited &&
      organization?.is_paying_organization
      ? SidebarToggleButtonTypeEnum.AuditingMode
      : SidebarToggleButtonTypeEnum.SelfAssessmentMode,
  );
  const isCategoryDrawer =
    organization?.type === OrganizationTypesEnum.Farm &&
    organization?.is_paying_organization &&
    selectedMode === SidebarToggleButtonTypeEnum.SelfAssessmentMode;

  // Master data for the categories drawer
  const [isLastItem, setIsLastItem] = useState(false);
  const [selectedLevel1Category, setSelectedLevel1Category] = useState<CategoryData | null>(null);
  const [selectedLevel2Category, setSelectedLevel2Category] = useState<CategoryData | null>(null);

  const { assessmentCategories: assessmentLevel1Categories } = useGetAssessmentCategories(
    assessmentId,
    1,
  );

  // Get Level 3 Categories for the checklist page section title
  const { assessmentCategories: assessmentLevel2Categories } = useGetAssessmentCategories(
    assessmentId,
    2,
  );

  const { isAssessmentCategoriesLoading, assessmentCategories: assessmentLevel3Categories } =
    useGetAssessmentCategories(assessmentId, 3);

  const filteredAssessmentLevel3Categories =
    assessmentLevel3Categories?.filter((c) =>
      selectedLevel2Category
        ? selectedLevel2Category?.uuid === c.parentCategoryId
        : assessmentLevel2Categories?.[0]?.uuid === c.parentCategoryId,
    ) ?? [];

  // Convert filter state 'ALL' values to null
  const filterStateForRequest: DynamicObjectType = Object.entries(filterState).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value === 'ALL' || value?.[0] === 'ALL' ? null : value,
    }),
    {},
  );
  const filterBadgeCount = Object.keys(filterStateForRequest).filter(
    (f) => filterStateForRequest[f] !== null,
  )?.length;

  // Master data for the module drawer
  const [selectedBaseModule, setSelectedBaseModule] =
    useState<AssessmentModuleSidebarOutputItem | null>(null);
  const [selectedLevel1Module, setSelectedLevel1Module] =
    useState<AssessmentModuleSidebarOutputItem | null>(null);
  const [selectedLevel2Module, setSelectedLevel2Module] =
    useState<AssessmentModuleSidebarOutputItem | null>(null);
  const { data: moduleSidebar, isLoading: isAssessmentModulesLoading } =
    useGetModuleSidebarListQuery(
      { id: assessmentId, tid },
      { skip: !tid || !assessmentId, refetchOnMountOrArgChange: true },
    );

  const assessmentLevel1Modules = moduleSidebar?.filter((m) => m.level === 1) ?? [];
  const assessmentBaseModules =
    moduleSidebar?.filter(
      (m) =>
        m.level === 0 &&
        assessmentLevel1Modules?.filter((level1Modules) => level1Modules.parent_id === m.uuid)
          ?.length > 0,
    ) ?? [];
  const assessmentLevel2Modules = moduleSidebar?.filter((m) => m.level === 2) ?? [];
  // Get Level 3 Modules for the checklist page section title
  const assessmentLevel3Modules =
    moduleSidebar?.filter(
      (m) =>
        m.level === 3 &&
        (selectedLevel2Module
          ? selectedLevel2Module?.uuid === m.parent_id
          : assessmentLevel2Modules?.[0]?.uuid === m.parent_id),
    ) ?? [];
  const [isLoadingNewModule, setIsLoadingNewModule] = useState(false);

  // User Data
  const {
    data: assessmentControlPointListResponse,
    isLoading: isAssessmentControlPointListLoading,
    isFetching: isAssessmentControlPointListFetching,
  } = useGetAssessmentControlPointListQuery(
    {
      tid,
      assessment_id: assessmentId,
      ...filterStateForRequest,
      category_id: [selectedLevel2Category?.uuid],
      module_id: selectedLevel2Module?.uuid
        ? [selectedLevel2Module?.uuid]
        : [selectedLevel1Module?.uuid],
      page_size: 1000,
    },
    {
      skip: (!selectedLevel2Category && !selectedLevel1Module && !selectedLevel2Module) || !tid,
    },
  );

  // Next Category Mechanism
  const {
    data: assessmentControlPointAnswerList,
    isLoading: isAssessmentControlPointAnswerListLoading,
  } = useGetAssessmentControlPointAnswerListQuery(
    {
      tid,
      assessment_id: [assessmentId],
    },
    { skip: !tid || !assessmentId },
  );

  const resetFilterState = (): void => {
    setFilterState({
      ...defaultState,
    });
  };

  const nextLevel2Category = () => {
    const controlPointId = searchParams.get('cp_id');
    if (controlPointId) {
      searchParams.delete('cp_id');
      setSearchParams(searchParams);
    }
    const selectedLevel2CategoryIndex = assessmentLevel2Categories.findIndex(
      (category) => category.uuid === selectedLevel2Category?.uuid,
    );
    // We want to hide the Next button in the last category. It must be -2 so that we can select the item before the last category.
    if (selectedLevel2CategoryIndex === assessmentLevel2Categories.length - 2) {
      setIsLastItem(true);
    } else {
      setIsLastItem(false);
    }

    if (selectedLevel2CategoryIndex === assessmentLevel2Categories.length - 1) {
      setSelectedLevel1Category(null);
      setSelectedLevel2Category(null);
      return;
    }

    const nextLevel2CategoryIndex = selectedLevel2CategoryIndex + 1;
    const nextSelectedLevel2Category = assessmentLevel2Categories[nextLevel2CategoryIndex];
    if (selectedLevel2Category?.parentCategoryId !== nextSelectedLevel2Category.parentCategoryId) {
      setSelectedLevel1Category(
        assessmentLevel1Categories?.find(
          (category) => category.uuid === nextSelectedLevel2Category.parentCategoryId,
        ) || null,
      );
    }
    setSelectedLevel2Category(nextSelectedLevel2Category ?? null);
  };

  const calculateBaseModule = () => {
    // Find active base module index on the sidebar if the assessment has more than one base module.
    const selectedBaseModuleIndex = assessmentBaseModules.findIndex(
      (module) => module.uuid === selectedBaseModule?.uuid,
    );

    // If active base module is the last item then we have to choose first accordion item.
    if (selectedBaseModuleIndex === assessmentBaseModules.length - 1) {
      setSelectedBaseModule(null);
      setSelectedLevel1Module(null);
      setSelectedLevel2Module(null);
      return;
    }
    // Calculate next base,level1 and level2 module and set them active.
    const nextSelectedBaseModule = assessmentBaseModules[selectedBaseModuleIndex + 1];
    const nextSelectedLevel1Module =
      assessmentLevel1Modules.find((module) => module.parent_id === nextSelectedBaseModule.uuid) ||
      null;
    const nextSelectedLevel2Module =
      assessmentLevel2Modules.find(
        (module) => module.parent_id === nextSelectedLevel1Module?.uuid,
      ) ?? null;

    setSelectedBaseModule(nextSelectedBaseModule);
    setSelectedLevel1Module(nextSelectedLevel1Module);
    setSelectedLevel2Module(nextSelectedLevel2Module);
  };

  const nextLevelModule = () => {
    const controlPointId = searchParams.get('cp_id');
    if (controlPointId) {
      searchParams.delete('cp_id');
      setSearchParams(searchParams);
    }
    // Filter the level2 module list of selected level1 item.
    const filteredAssessmentLevel2Modules =
      assessmentLevel2Modules?.filter((m) =>
        selectedLevel1Module ? selectedLevel1Module.uuid === m.parent_id : true,
      ) ?? [];

    // Find active level1 module index on the sidebar.
    const selectedLevel1ModuleIndex = assessmentLevel1Modules.findIndex(
      (module) => module.uuid === selectedLevel1Module?.uuid,
    );
    // Calculate next level1 module index.
    const nextLevel1ModuleIndex = selectedLevel1ModuleIndex + 1;
    const nextSelectedLevel1Module = assessmentLevel1Modules[nextLevel1ModuleIndex];
    // If this standard has only 2 levels, else 3 levels.
    if (!filteredAssessmentLevel2Modules?.length) {
      // We want to hide the Next button in the last module. It must be -2 so that we can select the item before the last module.
      if (selectedLevel1ModuleIndex === assessmentLevel1Modules.length - 2) {
        setIsLastItem(true);
      } else {
        setIsLastItem(false);
      }
      if (
        selectedLevel1Module?.parent_id !== nextSelectedLevel1Module?.parent_id &&
        assessmentBaseModules.length > 1
      ) {
        // If selected assessment has more than one different standard then we have to calculate which one should be next.
        calculateBaseModule();
      } else if (selectedLevel1ModuleIndex === assessmentLevel1Modules.length - 1) {
        // If active level1 module is the last item then we have to choose next accordion item.
        setSelectedLevel1Module(null);
      } else {
        // If next level1 module is exist then set it active.
        setSelectedLevel1Module(nextSelectedLevel1Module ?? null);
      }
    } else {
      // Find active level2 module index on the sidebar.
      const selectedLevel2ModuleIndex = assessmentLevel2Modules.findIndex(
        (module) => module.uuid === selectedLevel2Module?.uuid,
      );
      // Calculate next level2 module index.
      const nextLevel2ModuleIndex = selectedLevel2ModuleIndex + 1;
      const nextSelectedLevel2Module = assessmentLevel2Modules[nextLevel2ModuleIndex];
      // We want to hide the Next button in the last module. It must be -2 so that we can select the item before the last module.
      if (selectedLevel2ModuleIndex === assessmentLevel2Modules.length - 2) {
        setIsLastItem(true);
      } else {
        setIsLastItem(false);
      }
      if (
        selectedLevel2Module?.parent_id !== nextSelectedLevel2Module?.parent_id &&
        selectedLevel1Module?.parent_id !== nextSelectedLevel1Module?.parent_id &&
        assessmentBaseModules.length > 1
      ) {
        // If selected assessment has more than one different standard then we have to calculate which one should be next.
        calculateBaseModule();
      } else if (selectedLevel2ModuleIndex === assessmentLevel2Modules.length - 1) {
        // If active level2 module is the last item then we have to choose next accordion item.
        setSelectedLevel1Module(null);
        setSelectedLevel2Module(null);
      } else {
        // If next level2 module has different parent then set level1 module.
        setSelectedLevel1Module(
          assessmentLevel1Modules?.find(
            (module) => module.uuid === nextSelectedLevel2Module?.parent_id,
          ) || null,
        );
        // If next level2 module is exist then set it active.
        setSelectedLevel2Module(nextSelectedLevel2Module ?? null);
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const controlPointId = searchParams.get('cp_id');
    if (isAssessmentControlPointListFetching) {
      setIsLoadingNewModule(true);
    }

    if (!controlPointId) {
      setTimeout(() => {
        virtuosoRef?.current?.scrollToIndex({
          index: 0,
          align: 'start',
          behavior: 'smooth',
        });
      }, 750);
    }
    // Intentionally excluding isAssessmentControlPointListFetching from the dependencies
    // so we don't visually reload the whole list when updating a single control point.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLevel2Category, selectedLevel2Module, searchParams, virtuosoRef]);

  useEffect(() => {
    if (!isAssessmentControlPointListLoading && !isAssessmentControlPointListFetching) {
      setIsLoadingNewModule(false);
    }
  }, [isAssessmentControlPointListFetching, isAssessmentControlPointListLoading]);

  // Filters
  const [filterAnchor, setFilterAnchor] = useState<null | HTMLElement>(null);
  const handleClickFilter = (event: MouseEvent<HTMLElement>) => {
    setFilterAnchor(event.currentTarget);
  };

  // Progress
  const [isProgressDialogOpen, openProgressDialog, closeProgressDialog] =
    useDialogState<AssessmentControlPointStepProgressDialogProps>();

  const updateFilterState = (
    filterId: string,
    event: { target: { value: string } },
    isMultiSelect = false,
  ): void => {
    let newFilterState = {
      ...filterState,
      [filterId]: event.target.value,
    };

    // For some filters, you can only filter one thing at a time with using radio.
    // For some filters, you can filter more than one with using checkbox.
    // multi select --> (criticality_id)
    if (isMultiSelect) {
      let newValue = [...filterState[filterId as keyof FilterStateType]];
      const ind = newValue.findIndex((f) => f === event.target.value);
      if (newValue[0] === 'ALL' || event.target.value === 'ALL') {
        newValue = [event.target.value];
      } else if (ind < 0) {
        newValue.push(event.target.value);
      } else if (ind >= 0) {
        if (newValue.length === 1) {
          newValue = ['ALL'];
        } else {
          newValue.splice(ind, 1);
        }
      }
      newFilterState = {
        ...filterState,
        [filterId]: newValue,
      };
    }
    setFilterState(newFilterState);
  };

  // Navigation
  const [updateAssessmentToBeConfirmed, updateAssessmentToBeConfirmedResult] =
    useUpdateAssessmentToBeConfirmedMutation();

  useMutationFeedback({
    result: updateAssessmentToBeConfirmedResult,
    onSuccess: () => navigate(ROUTE_PATH_ASSESSMENT_ARCHIVE),
    successMessage: assessmentControlPointStepStrings('assessmentReadyForApprovalSuccess'),
  });

  const navigateToNextStep = () => {
    if (props.isFinalTask) {
      if (isReviewer && assessmentId && tid) {
        updateAssessmentToBeConfirmed({ id: assessmentId, tid });
      } else {
        props.openFinishDialog();
      }
    } else {
      setIsNextTaskLoading(true);
      props.moveToNextTask();
    }
  };

  const navigateToPreviousStep = () => {
    setIsPreviousTaskLoading(true);
    props.moveToPreviousTask();
  };

  const showLoading =
    isAssessmentControlPointListLoading ||
    isAssessmentControlPointAnswerListLoading ||
    isAssessmentCategoriesLoading;

  let nextButtonText = assessmentControlPointStepStrings('nextCategory');
  let subtitleText = assessmentControlPointStepStrings('controlPointStepSubtitle');
  const origin = useGetUserOrigin();
  if (origin === SignUpOriginEnum.SMK && (isAuditor || isFarmManager || isFarmEmployee)) {
    nextButtonText = assessmentControlPointStepStrings('nextTheme');
    subtitleText = assessmentControlPointStepStrings('controlPointStepSubtitleForSMK');
  } else if (origin === SignUpOriginEnum.AGRIPLACE && isAuditor) {
    nextButtonText = assessmentControlPointStepStrings('nextChapter');
    subtitleText = assessmentControlPointStepStrings('controlPointStepSubtitleForAuditor');
  }

  const renderFooter = (hideButton: boolean) =>
    hideButton ? (
      <Toolbar />
    ) : (
      <Container
        maxWidth="xl"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <LoadingButton
          loading={showLoading || isAssessmentControlPointListFetching}
          onClick={isCategoryDrawer ? nextLevel2Category : nextLevelModule}
          size="large"
          sx={{ borderRadius: 16, mb: 2 }}
          variant="contained"
        >
          {nextButtonText}
        </LoadingButton>
        <Toolbar />
      </Container>
    );

  let hidingKebabMenuList: Array<KebabMenuSlug>;
  if (isCBManager) {
    hidingKebabMenuList = [KebabMenuSlug.AttachEvidence, KebabMenuSlug.AddNonConformity];
  } else if (isAuditor && !isReviewer) {
    hidingKebabMenuList = [KebabMenuSlug.AddComment];
  } else if (isReviewer) {
    hidingKebabMenuList = [
      KebabMenuSlug.AddComment,
      KebabMenuSlug.AttachEvidence,
      KebabMenuSlug.AddNonConformity,
    ];
  }

  const row = (index: number, controlPoint: AssessmentControlPointListOutputItem) => (
    <ControlPoint
      key={`${controlPoint.uuid}_${index}`}
      assessmentControlPoint={controlPoint}
      assessmentControlPointAnswer={(assessmentControlPointAnswerList || []).filter(
        (item) => item.control_point_id === controlPoint?.control_point_id,
      )}
      hidingMenuList={hidingKebabMenuList}
      isLoadingNewModule={isLoadingNewModule}
    />
  );

  // Use Grouped List for third level categories or modules
  const controlPointList = assessmentControlPointListResponse?.results || [];
  const hasGroupList = isCategoryDrawer
    ? filteredAssessmentLevel3Categories?.length > 0
    : assessmentLevel3Modules?.length > 0;

  const groupedControlPoints = isCategoryDrawer
    ? groupBy(
        controlPointList,
        (controlPoint) =>
          controlPoint.categories?.[0]?.taxonomic_level === 3 && controlPoint.categories?.[0]?.uuid,
      )
    : groupBy(controlPointList, (controlPoint) => controlPoint.level_3_module_id || '');
  const groupCounts = Object.values(groupedControlPoints).map(
    (controlPoints) => controlPoints.length,
  );
  const groups = Object.keys(groupedControlPoints);
  const renderGroupContent = (index: number) => {
    const groupTitle = isCategoryDrawer
      ? filteredAssessmentLevel3Categories?.find(
          (categoryLevel3) => categoryLevel3.uuid === groups[index],
        )?.name
      : assessmentLevel3Modules?.find((moduleLevel3) => moduleLevel3.uuid === groups[index])?.name;

    return hasGroupList && groupTitle ? (
      <Box
        alignItems="center"
        display="flex"
        flex={1}
        paddingBottom={2}
        paddingTop={index === 0 ? 1 : 0}
      >
        <Box
          bgcolor="divider"
          flex={1}
          height="1px"
        />
        <Typography
          color="textSecondary"
          paddingX={0.5}
          variant="overline"
        >
          {groupTitle}
        </Typography>
        <Box
          bgcolor="divider"
          flex={1}
          height="1px"
        />
      </Box>
    ) : (
      <Box
        alignItems="center"
        display="flex"
        flex={1}
        minHeight={2}
        paddingTop={index === 0 ? 1 : 0}
      />
    );
  };

  // If you are using deep linking this useEffect should work.
  useEffect(() => {
    if (moduleSidebar && moduleSidebar.length > 0) {
      const baseModuleId = searchParams.get('base_m_id');
      const baseModule = moduleSidebar.find((base) => base.uuid === baseModuleId);
      const level1ModuleId = searchParams.get('level1_m_id');
      const level1Module = moduleSidebar.find((level1) => level1.uuid === level1ModuleId);
      const level2ModuleId = searchParams.get('level2_m_id');
      const level2Module = moduleSidebar.find((level2) => level2.uuid === level2ModuleId);
      let newBaseModule;
      let newLevel1Module;
      let newLevel2Module;
      if (baseModule) {
        if (selectedBaseModule !== baseModule) {
          if (selectedBaseModule) {
            newBaseModule = selectedBaseModule;
          } else {
            newBaseModule = baseModule;
          }
        }
        if (level1ModuleId) {
          if (selectedLevel1Module !== level1Module) {
            if (selectedLevel1Module) {
              newLevel1Module = selectedLevel1Module;
            } else {
              newLevel1Module = level1Module;
            }
          }
          if (level2ModuleId && selectedLevel2Module !== level2Module) {
            if (selectedLevel2Module) {
              newLevel2Module = selectedLevel2Module;
            } else {
              newLevel2Module = level2Module;
            }
          }
        }
      }
      if (newBaseModule?.uuid && newLevel1Module?.uuid && newLevel2Module?.uuid) {
        searchParams.set('base_m_id', newBaseModule.uuid);
        searchParams.set('level1_m_id', newLevel1Module.uuid);
        searchParams.set('level2_m_id', newLevel2Module.uuid);
        setSearchParams(searchParams);
        setSelectedBaseModule(newBaseModule);
        setSelectedLevel1Module(newLevel1Module);
        setSelectedLevel2Module(newLevel2Module);
      }
      if (!showLoading && assessmentControlPointListResponse?.results?.length) {
        const controlPointId = searchParams.get('cp_id');
        if (controlPointId) {
          const indexOfSelectedControlPoint = assessmentControlPointListResponse.results.findIndex(
            (cp) => cp.control_point_id === controlPointId,
          );
          setTimeout(() => {
            virtuosoRef?.current?.scrollToIndex({
              index: indexOfSelectedControlPoint,
              align: 'end',
              behavior: 'smooth',
            });
          }, 1000);
        }
      }
    }
  }, [
    assessmentControlPointListResponse?.results,
    moduleSidebar,
    searchParams,
    selectedBaseModule,
    selectedLevel1Module,
    selectedLevel2Module,
    setSearchParams,
    showLoading,
  ]);

  // @returns - The empty state, or a stack of control point components
  const renderControlPoints = (): React.JSX.Element => {
    if (showLoading) {
      return (
        <LoadingArea
          maxWidth="xl"
          sx={{ ml: 0 }}
        />
      );
    }
    const hideButton = isCategoryDrawer
      ? isAssessmentCategoriesLoading || assessmentLevel1Categories?.length <= 0 || isLastItem
      : isAssessmentModulesLoading || assessmentLevel1Modules?.length <= 0 || isLastItem;

    let emptyListMsg = assessmentControlPointStepStrings('noQuestionsToDisplay');
    if (origin === SignUpOriginEnum.SMK) {
      emptyListMsg = assessmentControlPointStepStrings('controlPointTableNoRowsMsgForSMK');
    } else if (origin === SignUpOriginEnum.AGRIPLACE) {
      if (isFarmManager || isFarmEmployee) {
        emptyListMsg = assessmentControlPointStepStrings(
          'controlPointTableNoRowsMsgForFarmManagers',
        );
      } else {
        emptyListMsg = assessmentControlPointStepStrings('controlPointTableNoRowsMsgForAuditors');
      }
    }

    // If not loading, and there's no results, show an empty state
    if (
      !(
        assessmentControlPointListResponse?.results &&
        assessmentControlPointListResponse.results.length
      ) &&
      !showLoading &&
      !isAssessmentControlPointListFetching
    ) {
      return (
        <>
          <Stack
            alignItems="center"
            display="flex"
            justifyContent="center"
            maxWidth="xl"
            paddingX={2}
          >
            <Typography
              color="text.disabled"
              textAlign="center"
              textTransform="uppercase"
              variant="body2"
            >
              {emptyListMsg}
            </Typography>
          </Stack>
          <Container
            maxWidth="xl"
            sx={{ ml: 0, height: '100%', pt: 2 }}
          >
            {renderFooter(hideButton)}
          </Container>
        </>
      );
    }

    return (
      <Container
        maxWidth="xl"
        sx={{ ml: 0, height: '100%' }}
      >
        <Paper sx={{ p: 2, mb: 1 }}>
          <Typography
            marginBottom={3}
            variant="h1"
          >
            {assessmentControlPointStepStrings('completingChecklist')}
          </Typography>
          <Typography
            marginBottom={2}
            variant="body1"
          >
            {subtitleText}
          </Typography>
        </Paper>
        <GroupedVirtuoso
          ref={virtuosoRef}
          components={{
            Footer: () => renderFooter(hideButton),
          }}
          groupContent={renderGroupContent}
          groupCounts={groupCounts}
          itemContent={(index, groupIndex) => {
            if (groups[groupIndex]) {
              // Group combine flow standards based on third level module or category
              const itemList = isCategoryDrawer
                ? controlPointList?.filter((cp) => cp.categories?.[0]?.uuid === groups[groupIndex])
                : controlPointList?.filter((cp) => cp.level_3_module_id === groups[groupIndex]);
              let totalGroupCount = 0;
              for (let ind = 1; ind <= groupIndex; ind += 1) {
                totalGroupCount += groupCounts[ind - 1];
              }
              if (itemList?.length >= index - totalGroupCount + 1) {
                return row(index, itemList[index - totalGroupCount]);
              }
            } else if (controlPointList?.length > 0) {
              return row(index, controlPointList[index]);
            }
            return null;
          }}
          useWindowScroll
        />
      </Container>
    );
  };

  return (
    <ControlPointMasterDataProvider>
      <ControlPointFilterProvider value={[filterState, updateFilterState, resetFilterState]}>
        <Box
          display="flex"
          flex="1 auto"
          flexDirection="column"
          paddingLeft={{ xs: 0, md: `${DRAWER_WIDTH}px` }}
          paddingTop={2}
        >
          {isCategoryDrawer ? (
            <AssessmentControlPointCategoriesDrawer
              assessmentLevel1Categories={assessmentLevel1Categories}
              assessmentLevel2Categories={assessmentLevel2Categories}
              drawerWidth={DRAWER_WIDTH}
              isLoading={isAssessmentCategoriesLoading}
              selectedLevel1Category={selectedLevel1Category}
              selectedLevel2Category={selectedLevel2Category}
              selectedMode={selectedMode}
              setIsLastItem={setIsLastItem}
              setSelectedLevel1Category={setSelectedLevel1Category}
              setSelectedLevel2Category={setSelectedLevel2Category}
              setSelectedMode={setSelectedMode}
            />
          ) : (
            <AssessmentControlPointModulesDrawer
              assessmentBaseModules={assessmentBaseModules}
              assessmentLevel1Modules={assessmentLevel1Modules}
              assessmentLevel2Modules={assessmentLevel2Modules}
              drawerWidth={DRAWER_WIDTH}
              isLoading={isAssessmentModulesLoading}
              selectedBaseModule={selectedBaseModule}
              selectedLevel1Module={selectedLevel1Module}
              selectedLevel2Module={selectedLevel2Module}
              selectedMode={selectedMode}
              setIsLastItem={setIsLastItem}
              setSelectedBaseModule={setSelectedBaseModule}
              setSelectedLevel1Module={setSelectedLevel1Module}
              setSelectedLevel2Module={setSelectedLevel2Module}
              setSelectedMode={setSelectedMode}
            />
          )}
          <AssessmentControlPointStepFilters
            filterAnchor={filterAnchor}
            setFilterAnchor={setFilterAnchor}
          />
          <AssessmentControlPointStepProgressDialog
            assessmentBaseModules={assessmentBaseModules}
            assessmentLevel1Modules={assessmentLevel1Modules}
            assessmentLevel2Modules={assessmentLevel2Modules}
            isOpen={isProgressDialogOpen}
            onClose={closeProgressDialog}
          />
          {renderControlPoints()}
        </Box>
      </ControlPointFilterProvider>
      <Toolbar sx={{ mb: -1 }} />
      <AppBar
        position="fixed"
        sx={{
          top: 'auto',
          bottom: 0,
          pr: { xs: 0, md: 2 },
          pl: { xs: 0, md: `${DRAWER_WIDTH + 16}px` },
        }}
      >
        <Container
          maxWidth="xl"
          sx={{ ml: 0 }}
        >
          <Toolbar
            disableGutters
            sx={{ justifyContent: 'space-between' }}
          >
            <Box marginLeft={{ xs: 0, md: `-${DRAWER_WIDTH + 24}px` }}>
              {!isAssessmentModulesLoading && (
                <Button
                  onClick={() => openProgressDialog()}
                  size="small"
                  startIcon={<StickyNote2Rounded />}
                  sx={{
                    py: 1,
                    mr: 1.5,
                    minWidth: isMobile ? 20 : undefined,
                    '.MuiButton-startIcon': {
                      mr: { xs: 0, sm: 1 },
                    },
                  }}
                  variant="outlined"
                >
                  {isMobile ? '' : assessmentControlPointStepStrings('progress')}
                </Button>
              )}
              <Badge
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                badgeContent={filterBadgeCount > 0 ? filterBadgeCount : undefined}
                color="primary"
              >
                <Button
                  onClick={handleClickFilter}
                  size="small"
                  startIcon={<FilterListRounded />}
                  sx={{
                    py: 1,
                    minWidth: isMobile ? 20 : undefined,
                    '.MuiButton-startIcon': {
                      mr: { xs: 0, sm: 1 },
                    },
                  }}
                  variant="outlined"
                >
                  {isMobile ? '' : commonStrings('filters')}
                </Button>
              </Badge>
            </Box>
            <Box>
              <AssessmentActionBarButtons
                hideBackButton={props.isFirstTask}
                isDisabled={isPreviousTaskLoading || isNextTaskLoading}
                isFinalTask={props.isFinalTask}
                isFirstTask={props.isFirstTask}
                isNextButtonLoading={isNextTaskLoading}
                isPreviousButtonLoading={isPreviousTaskLoading}
                nextTaskName={props.nextTaskName}
                onNext={navigateToNextStep}
                onPrevious={navigateToPreviousStep}
                withoutToolbar
              />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ControlPointMasterDataProvider>
  );
};
