import { DeleteRounded, EditRounded } from '@mui/icons-material';
import { Box, Chip, Toolbar, Typography } from '@mui/material';
import {
  GridActionsCellItem,
  type GridEnrichedColDef,
  type GridRenderCellParams,
  type GridRowParams,
} from '@mui/x-data-grid-pro';
import { type ReactNode } from 'react';
import {
  type OrganizationMemberListOutputItem,
  RolesEnum,
} from 'src/__generated__/InternalApiTypes';
import {
  CustomNoRowsOverlay,
  MobileCompatibleDataGrid,
} from 'src/components/common/MobileCompatibleDataGrid';
import {
  GenericDeleteDialog,
  type GenericDeleteDialogProps,
} from 'src/components/dialogs/GenericDeleteDialog';
import {
  OrganizationUserEditDialog,
  type OrganizationUserEditDialogProps,
} from 'src/components/dialogs/OrganizationUserEditDialog';
import { useCurrentUserRoles, useDialogState } from 'src/hooks';
import { commonStrings, organizationUserTableStrings } from 'src/languages/en-UK';
import { useDeleteOrganizationMemberMutation } from 'src/services/farmApi';

interface OrganizationUserTableProps {
  isLoading?: boolean;
  isManager?: boolean;
  organizationUsers?: OrganizationMemberListOutputItem[];
  userId?: string;
}
export const OrganizationUserTable = ({
  organizationUsers,
  userId,
  isLoading = false,
  isManager = false,
}: OrganizationUserTableProps): React.JSX.Element => {
  const [
    isOrganizationUserEditDialogOpen,
    openOrganizationUserEditDialog,
    closeOrganizationUserEditDialog,
    organizationUserEditDialogProps,
  ] = useDialogState<OrganizationUserEditDialogProps>();

  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog, deleteDialogProps] =
    useDialogState<Omit<GenericDeleteDialogProps, 'deleteMutation'>>();

  const currentUserRoles = useCurrentUserRoles();
  const isCBManager = currentUserRoles.includes(RolesEnum.CertificationBodyManager);

  const columns: GridEnrichedColDef[] = [
    { field: 'name', headerName: commonStrings('name'), flex: 2 },
    { field: 'email', headerName: commonStrings('email'), flex: 2 },
    {
      field: 'role_labels',
      headerName: commonStrings('role'),
      flex: 2,
      valueGetter: (params) => {
        const rolesLabel: string[] = [];
        params?.value?.forEach((role: string) => {
          rolesLabel.push(role);
        });
        return rolesLabel?.join(', ');
      },
    },
    {
      field: 'status',
      headerName: commonStrings('status'),
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<string, OrganizationMemberListOutputItem, ReactNode>,
      ) =>
        params.value && (
          <Chip
            label={params.value}
            variant="outlined"
          />
        ),
    },
  ];

  columns.push({
    field: 'actions',
    type: 'actions',
    align: 'right',
    getActions: (params: GridRowParams<OrganizationMemberListOutputItem>) => {
      const buttons = [];
      if (userId !== params.row.user_profile_id) {
        if (isManager && !params.row.roles.includes(RolesEnum.Owner)) {
          buttons.push(
            <GridActionsCellItem
              key={`${params.row.uuid}_edit`}
              icon={<EditRounded />}
              label={commonStrings('edit')}
              onClick={() =>
                openOrganizationUserEditDialog({
                  userId: params.row.uuid,
                  title: commonStrings('editingDialogTitle', { name: params.row.email }),
                })
              }
            />,
          );
          buttons.push(
            <GridActionsCellItem
              key={`${params.row.uuid}_delete`}
              icon={<DeleteRounded />}
              label={commonStrings('delete')}
              onClick={() =>
                openDeleteDialog({
                  entities: [
                    {
                      header: organizationUserTableStrings('user'),
                      name: params.row.email,
                      uuid: params.row.uuid || '',
                    },
                  ],
                })
              }
            />,
          );
        }
      }
      return buttons;
    },
  });

  return (
    <>
      <Toolbar
        disableGutters
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 2,
        }}
        variant="dense"
      >
        <Typography
          lineHeight={3.43}
          variant="subtitle2"
        >
          {isCBManager
            ? organizationUserTableStrings('colleagues')
            : organizationUserTableStrings('activeUsers')}
        </Typography>
      </Toolbar>
      <Box height={isManager ? 408 : 'calc(100vh - 328px)'}>
        <MobileCompatibleDataGrid
          columns={columns}
          loading={isLoading}
          NoRowsOverlay={() =>
            CustomNoRowsOverlay(organizationUserTableStrings('userTableNoRowsMsg'))
          }
          rows={organizationUsers || []}
          sortModel={[{ field: 'name', sort: 'asc' }]}
        />
      </Box>
      <OrganizationUserEditDialog
        isOpen={isOrganizationUserEditDialogOpen}
        onClose={closeOrganizationUserEditDialog}
        {...organizationUserEditDialogProps}
      />
      <GenericDeleteDialog
        deleteMutation={useDeleteOrganizationMemberMutation}
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        {...deleteDialogProps}
      />
    </>
  );
};
