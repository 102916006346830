import { useContext } from 'react';
import { OccurrenceEnum } from 'src/__generated__/InternalApiTypes';
import { useGetAssessmentUserPermission } from 'src/hooks';
import { commonStrings, editNonConformityDialogStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import {
  useCreateAssessmentControlPointNonConformityMutation,
  useGetAssessmentControlPointNonConformityQuery,
  useUpdateAssessmentControlPointNonConformityMutation,
} from 'src/services/farmApi';
import * as Yup from 'yup';

import { FormikDateField } from '../formikcomponents/FormikDateField';
import { FormikDialog } from '../formikcomponents/FormikDialog';
import { FormikSelectField } from '../formikcomponents/FormikSelectField';
import { FormikTextField } from '../formikcomponents/FormikTextField';
import { OccurrenceEnumLabel } from './NewNonConformityForm';

export interface EditNonConformityDialogProps {
  nonConformityId: string;
  isOpen: boolean;
  onClose: () => void;
  title: string;
}

export const EditNonConformityDialog = ({
  nonConformityId,
  onClose,
  title,
  isOpen = false,
}: EditNonConformityDialogProps): React.JSX.Element => {
  const defaultTitle = commonStrings('editNonConformity');
  const standardOptions = [
    { value: OccurrenceEnum.First, label: OccurrenceEnumLabel[OccurrenceEnum.First] },
    { value: OccurrenceEnum.Second, label: OccurrenceEnumLabel[OccurrenceEnum.Second] },
  ];
  const [assessmentId] = useContext(AssessmentPageContext);
  const permissions = useGetAssessmentUserPermission(assessmentId);

  return (
    <FormikDialog
      createHook={useCreateAssessmentControlPointNonConformityMutation}
      entityId={nonConformityId}
      fetchHook={useGetAssessmentControlPointNonConformityQuery}
      fields={[
        {
          id: 'text',
          renderAs: (props) => (
            <FormikTextField
              {...props}
              label={editNonConformityDialogStrings('nonConformityDescription')}
              multiline
              required
            />
          ),
          validator: Yup.string().required(commonStrings('required')), // not working
        },
        {
          id: 'reported_date',
          renderAs: (props) => (
            <FormikDateField
              {...props}
              label={editNonConformityDialogStrings('deadlineDate')}
              required
            />
          ),
          validator: Yup.date().required(commonStrings('required')),
        },
        {
          id: 'occurrence',
          renderAs: (props) => (
            <FormikSelectField
              {...props}
              isHideInputLabel
              label={commonStrings('timeNonConformityAppear')}
              options={standardOptions}
            />
          ),
        },
      ]}
      hasCreatePermission={permissions?.shortcomings?.create}
      hasUpdatePermission={permissions?.shortcomings?.update}
      isOpen={isOpen}
      onClose={onClose}
      propertyName="text"
      title={title || defaultTitle}
      updateHook={useUpdateAssessmentControlPointNonConformityMutation}
    />
  );
};
