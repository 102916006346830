import { AddRounded, ArrowBackRounded, CloseRounded } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link as MuiLink,
  Slide,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { difference, intersection } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  type AssessmentDetailOutput,
  type ModuleListOutputItem,
  RolesEnum,
} from 'src/__generated__/InternalApiTypes';
import { AssessmentConfigClientIdentifiers } from 'src/components/AssessmentConfigClientIdentifiers';
import { STANDARD_SELECTION_GLOBAL } from 'src/components/AssessmentConfigMatrix/AssessmentConfigMatrix.utils';
import { type CultivationOptionsType, SelectionList } from 'src/components/SelectionList';
import { ThemeContext } from 'src/components/ThemeProvider';
import {
  useAppDispatch,
  useAppSelector,
  useCurrentOrganization,
  useCurrentUserRoles,
  useDialogState,
  useSelectionListState,
  useTenantId,
} from 'src/hooks';
import { assessmentConfigDialogStrings, commonStrings } from 'src/languages/en-UK';
import {
  type TransformedOrganizationCultivationListOutputItem,
  useDeleteAssessmentPlotCultivationMutation,
  useGetOrganizationClientIdentifiersListQuery,
  useGetOrganizationCultivationListQuery,
} from 'src/services/farmApi';
import { assessmentConfigActions } from 'src/store/assessmentConfig';
import { type MatrixSelection } from 'src/store/assessmentConfig/types';
import { openSnackbar } from 'src/store/snackbarSlice';
import { appColors } from 'src/theme';
import { getCultivationDisplayName } from 'src/utils/getCultivationDisplayName';

import {
  AssessmentCultivationEditDialog,
  type AssessmentCultivationEditDialogProps,
} from '../AssessmentCultivationEditDialog';
import { GenericDeleteDialog, type GenericDeleteDialogProps } from '../GenericDeleteDialog';

interface AssessmentConfigDialogProps {
  assessment: AssessmentDetailOutput;
  isLoading: boolean;
  isOpen: boolean;
  moduleList: ModuleListOutputItem[];
  onClose: () => void;
  paidModuleList: ModuleListOutputItem[];
  setUsedOrganizationClientIdentifierIds?: (value: string[]) => void;
}

export const AssessmentConfigDialog = ({
  assessment,
  moduleList,
  onClose,
  paidModuleList,
  setUsedOrganizationClientIdentifierIds,
  isLoading = false,
  isOpen = false,
}: AssessmentConfigDialogProps): React.JSX.Element => {
  const tid = useTenantId();
  const dispatch = useAppDispatch();
  const EMPTY_ARRAY: TransformedOrganizationCultivationListOutputItem[] = [];
  const [step, setStep] = useState(0);
  const [standardsMissingClientId, setStandardsMissingClientId] = useState([]);
  const [selectedModuleList, setSelectedModuleList] = useState([]);
  // Role based stuff
  const { data: organization } = useCurrentOrganization();
  const currentUserRoles = useCurrentUserRoles();
  const isAuditor = currentUserRoles.includes(RolesEnum.Auditor);
  const isCBManager = currentUserRoles.includes(RolesEnum.CertificationBodyManager);
  const isFarmManager = currentUserRoles.includes(RolesEnum.FarmManager);
  const isFarmEmployee = currentUserRoles.includes(RolesEnum.FarmEmployee);
  const auditorClientIdentifierIds: string[] =
    isAuditor && assessment?.client_identifiers ? assessment?.client_identifiers : [];
  const { data: cultivationList = EMPTY_ARRAY, isLoading: isCultivationListLoading } =
    useGetOrganizationCultivationListQuery(
      { tid, client_identifier_id: auditorClientIdentifierIds },
      { skip: !isOpen || !tid },
    );
  // Cultivation delete dialog
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog, deleteDialogProps] =
    useDialogState<Omit<GenericDeleteDialogProps, 'deleteMutation'>>();

  const { data: organizationClientIdentifiers } = useGetOrganizationClientIdentifiersListQuery(
    tid,
    { skip: !tid },
  );

  /* Copy the currently selected cultivation and standard IDs from the context into temporary state
   * hooks. These will allow us to keep track of changes the user makes and then save or discard
   * later when the user clicks "Complete" or "Close", respectively.
   */
  const { columns, rows, selections } = useAppSelector((state) => state.assessmentConfig);
  const [previousSelections, setPreviousSelections] = useState<MatrixSelection[]>();

  // Get the initial cultivation ids
  const configuredRowIds = useMemo(() => rows.map((r) => r.id), [rows]);

  // Get the initial standard ids and sort them
  const configuredColumnIds = useMemo(
    () => {
      const sortedColumns = [...columns]
        .filter((col) => selections.some((s) => s.colId === col.id))
        .sort((a, b) => a.index - b.index);
      return sortedColumns.map((c) => c.id);
    },

    // TODOHasan: Do not add selections as a dependency. Find a correct way.
    [columns],
  );

  const isInitialState = columns.length === 0 || rows.length === 0;

  // Get the selected cultivations
  const [selectedCultivationIds, onChangeSelectedCultivationIds, resetSelectedCultivationIds] =
    useSelectionListState(configuredRowIds, undefined, isInitialState);

  // Get the selected standards
  const [selectedModuleIds, onChangeSelectedModuleIds, resetSelectedModuleIds] =
    useSelectionListState(configuredColumnIds, moduleList, isInitialState);

  useEffect(() => {
    if (selectedModuleIds) {
      setSelectedModuleList(
        moduleList?.filter((selectedModule) => selectedModuleIds.includes(selectedModule.uuid)),
      );
    }
  }, [selectedModuleIds, moduleList]);

  useEffect(() => {
    if (organizationClientIdentifiers && selectedModuleList) {
      const standardsWithClientId = organizationClientIdentifiers
        .flatMap((identifier) => identifier.standards)
        .map((standard) => standard.uuid);

      setStandardsMissingClientId(
        difference(
          selectedModuleList.map((module) => module.standard_id),
          standardsWithClientId,
        ),
      );

      setUsedOrganizationClientIdentifierIds(
        organizationClientIdentifiers
          .filter(
            (clientId) =>
              intersection(
                selectedModuleList.map((m) => m.standard_id),
                clientId.standards.map((s) => s.uuid),
              ).length > 0,
          )
          .map((clientId) => clientId.uuid),
      );
    }
  }, [organizationClientIdentifiers, selectedModuleList, setUsedOrganizationClientIdentifierIds]);

  // Mapping the standards list from the backend to the SelectionList Component
  const moduleOptions = useMemo(
    () =>
      (moduleList || []).map((module) => ({
        id: module.uuid,
        name: module.name,
        image: module.small_logo?.file_object, // TODO: change to standard logo after re-factoring assessment configuration page to use standards
        standardId: module.standard_id,
        isApplicableToOrganization: module.is_applicable_to_organization,
        description: module.description,
      })),
    [moduleList],
  );

  /**
   * For the first time, which standard is selected for which cultivation is not understood until
   * the dialog is opened and the configure button is pressed. That's why this method was added.
   */
  const replaceGlobalVariable = () => {
    let isGlobal = false;
    const newSelections: MatrixSelection[] = [];
    selections.forEach((selection) => {
      if (selection.rowId === STANDARD_SELECTION_GLOBAL) {
        isGlobal = true;
        selectedCultivationIds.forEach((selectedCultivationId) => {
          const newItem = {
            ...selection,
            rowId: selectedCultivationId,
          };
          newSelections.push(newItem);
        });
      } else if (selection.colId === STANDARD_SELECTION_GLOBAL) {
        isGlobal = true;
        selectedModuleIds.forEach((selectedModuleId) => {
          const newItem = {
            ...selection,
            colId: selectedModuleId,
          };
          newSelections.push(newItem);
        });
      }
    });
    if (isGlobal) {
      const uniqueSelection = newSelections.filter(
        (obj, i) =>
          i ===
          newSelections.findIndex(
            (o) =>
              obj.rowId === o.rowId &&
              obj.colId === o.colId &&
              obj.colId !== STANDARD_SELECTION_GLOBAL &&
              obj.rowId !== STANDARD_SELECTION_GLOBAL,
          ),
      );
      const changedSelections = [
        ...selections.filter(
          (s) => s.colId !== STANDARD_SELECTION_GLOBAL && s.rowId !== STANDARD_SELECTION_GLOBAL,
        ),
        ...uniqueSelection,
      ];
      setPreviousSelections(changedSelections);
      dispatch(
        assessmentConfigActions.changeSelection({
          selections: changedSelections,
        }),
      );
    }
  };

  /**
   * Handler for when the user clicks "Complete". Copies the local changed state to the context.
   */
  const handleComplete = (): void => {
    if (isFarmEmployee && standardsMissingClientId.length > 0) {
      dispatch(
        openSnackbar({
          message: assessmentConfigDialogStrings('missingClientIDForFarmEmployee'),
          severity: 'error',
        }),
      );
    } else {
      replaceGlobalVariable();
      dispatch(
        assessmentConfigActions.setLayout({
          cultivationList: cultivationList.filter((cultivation) =>
            selectedCultivationIds.includes(cultivation.uuid),
          ),
          moduleList: moduleList.filter((module) => selectedModuleIds.includes(module.uuid)),
          hasPlotName: isFarmManager || isFarmEmployee,
        }),
      );
      onClose();
      setStep(0);
    }
  };

  const [
    isAssessmentCultivationEditDialogOpen,
    openAssessmentCultivationEditDialog,
    closeAssessmentCultivationEditDialog,
    assessmentCultivationEditDialogProps,
  ] = useDialogState<AssessmentCultivationEditDialogProps>();

  const handleAddCultivation = () => {
    openAssessmentCultivationEditDialog({
      onChangeSelectedCultivationIds,
      assessmentId: assessment?.uuid,
      clientIdentifierIds: auditorClientIdentifierIds,
      title: commonStrings('addNewCultivation'),
    });
  };

  const handleEditCultivation = (cultivation?: CultivationOptionsType) => {
    if (cultivation) {
      openAssessmentCultivationEditDialog({
        onChangeSelectedCultivationIds,
        assessmentId: assessment?.uuid,
        clientIdentifierIds: auditorClientIdentifierIds,
        cultivationId: cultivation.id,
        title: commonStrings('editingDialogTitle', {
          name: cultivation.name,
        }),
      });
    }
  };

  const handleDeleteCultivation = (cultivation?: CultivationOptionsType) => {
    if (cultivation) {
      openDeleteDialog({
        additionalReqParams: { assessmentId: assessment?.uuid },
        entities: [
          {
            header: commonStrings('cultivation'),
            name: cultivation.name,
            uuid: cultivation.id,
          },
        ],
        customErrorCode: 400,
        customErrorMessage: commonStrings('cultivationDeleteCustomErrorMessage', {
          name: cultivation.name,
        }),
      });
    }
  };

  // Ensure that if the configuration changes in redux, we update reset the list state to match
  useEffect(() => {
    if (columns.length !== 0) {
      resetSelectedModuleIds(configuredColumnIds);
    }

    // TODOHasan: Do not add configuredColumnIds and resetSelectedModuleIds as a dependency.
    // Find a correct way.
  }, [columns]);

  useEffect(() => {
    if (!isOpen) {
      setPreviousSelections(selections);
    }
  }, [isOpen, selections]);

  /**
   * Handler for when the user clicks "Cancel".
   */
  const handleCancel = useCallback((): void => {
    onClose();

    /* Since the dialog doesn't actually unmount when closing, it is necessary to manually reset any
     changes so that when the dialog is opened again, it is in a "fresh" state.
     */
    resetSelectedCultivationIds();
    resetSelectedModuleIds();
    setStep(0);
    if (previousSelections) {
      dispatch(assessmentConfigActions.changeSelection({ selections: [...previousSelections] }));
    }
    dispatch(
      assessmentConfigActions.setLayout({
        cultivationList: cultivationList?.filter((cultivation) =>
          configuredRowIds.includes(cultivation.uuid),
        ),
        moduleList: moduleList?.filter((module) => configuredColumnIds.includes(module.uuid)),
        hasPlotName: isFarmManager || isFarmEmployee,
      }),
    );
  }, [
    configuredColumnIds,
    configuredRowIds,
    cultivationList,
    dispatch,
    isFarmEmployee,
    isFarmManager,
    moduleList,
    onClose,
    previousSelections,
    resetSelectedCultivationIds,
    resetSelectedModuleIds,
  ]);

  // Mapping the cultivation list from the backend to the SelectionList Component
  const cultivationOptions = useMemo(
    () =>
      (cultivationList || [])
        .map((cultivation) => ({
          id: cultivation.uuid,
          name: getCultivationDisplayName(cultivation, isFarmManager || isFarmEmployee),
        }))
        ?.sort((a, b) => Number(a.name.localeCompare(b.name))),
    [cultivationList, isFarmEmployee, isFarmManager],
  );

  const { theme } = useContext(ThemeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  let description = assessmentConfigDialogStrings('selectOrAddCultivationAndCertificate');

  if (step === 1) {
    description = assessmentConfigDialogStrings('addOrVerifyIdentificationNumbers');
  } else if (isAuditor || isCBManager) {
    description = assessmentConfigDialogStrings('selectOrAddCultivationAndCertificateForAuditors');
  }

  const hasNoData =
    cultivationOptions?.length === 0 ||
    selectedCultivationIds?.length === 0 ||
    selectedModuleIds?.length === 0;

  const isCompleteButtonDisabled =
    (!isAuditor && step === 1 && !isFarmEmployee && standardsMissingClientId?.length > 0) ||
    hasNoData;

  const isFreeVersion = (isFarmManager || isFarmEmployee) && !organization?.is_paying_organization;

  return (
    <form noValidate>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleCancel}
        open={isOpen}
        PaperProps={{
          style: {
            minHeight: '90%',
            minWidth: isMobile && step === 0 ? 'calc(100% - 16px)' : undefined,
          },
        }}
        scroll={isMobile && step === 0 ? 'body' : undefined}
        sx={{ mx: 'auto' }}
      >
        <IconButton
          onClick={handleCancel}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            padding: 1.5,
          }}
        >
          <CloseRounded />
        </IconButton>
        <DialogTitle sx={{ display: 'flex', flexDirection: 'column' }}>
          {!isAuditor && (
            <Typography
              marginBottom={1}
              variant="overline"
            >
              {assessmentConfigDialogStrings('dialogStep', { step: step + 1 })}
            </Typography>
          )}
          <Typography
            component="span"
            variant="h2"
          >
            {isAuditor || isCBManager
              ? commonStrings('inspectionSetup')
              : assessmentConfigDialogStrings('assessmentSetup')}
          </Typography>
        </DialogTitle>
        <Typography variant="body1">{description}</Typography>
        {isFreeVersion && (
          <Typography
            component="span"
            marginTop={3}
            variant="body1"
          >
            {step === 0
              ? assessmentConfigDialogStrings('getAccessWithUpgradedSubscriptionPlans')
              : ''}
            {step === 0 && (
              <MuiLink
                component="p"
                // TODOHasan: implement this after there is a payment plan mechanism.
                onClick={() => {}}
                sx={{
                  cursor: 'pointer',
                  color: appColors.blue,
                }}
                variant="body1"
              >
                {assessmentConfigDialogStrings('unlockMoreFeatures')}
              </MuiLink>
            )}
          </Typography>
        )}
        {step !== 1 && (
          <Button
            color="primary"
            onClick={handleAddCultivation}
            size="small"
            startIcon={<AddRounded />}
            sx={{
              py: 0.5,
              px: 1.25,
              my: 3,
              maxWidth: 184,
            }}
            variant={isAuditor ? 'contained' : 'outlined'}
          >
            {commonStrings('addCultivation')}
          </Button>
        )}
        <Slide
          direction="right"
          in={step === 1}
          mountOnEnter
          timeout={{
            enter: 600,
            exit: 0,
          }}
          unmountOnExit
        >
          <DialogContent
            sx={{
              minHeight: {
                xs: isFreeVersion ? 'calc(90vh - 440px)' : 'calc(90vh - 300px)',
                sm: isFreeVersion ? 'calc(90vh - 320px)' : 'calc(90vh - 224px)',
              },
            }}
          >
            <AssessmentConfigClientIdentifiers selectedModuleList={selectedModuleList} />
          </DialogContent>
        </Slide>
        <Slide
          appear={false}
          direction="right"
          in={step === 0}
          timeout={{
            enter: 600,
            exit: 0,
          }}
        >
          <DialogContent sx={{ display: 'flex' }}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              flex={1}
              gap={!isMobile ? 2.5 : undefined}
            >
              <SelectionList
                isDisabledInitialSelectAll
                isLoading={isCultivationListLoading}
                onChange={onChangeSelectedCultivationIds}
                onDeleteCultivationClick={handleDeleteCultivation}
                onEditCultivationClick={handleEditCultivation}
                options={cultivationOptions}
                selectedIds={selectedCultivationIds}
                title={commonStrings('cultivations')}
              />
              <SelectionList
                isDisabled={
                  (isFarmManager || isFarmEmployee) && !organization?.is_paying_organization
                }
                isDisabledInitialSelectAll={moduleOptions?.length > 1}
                isLoading={isLoading}
                onChange={onChangeSelectedModuleIds}
                options={moduleOptions}
                paidModuleList={paidModuleList}
                selectedIds={selectedModuleIds}
                selectedRows={selectedCultivationIds}
                shouldChangeStandardSelection
                title={
                  isFarmManager || isFarmEmployee
                    ? assessmentConfigDialogStrings('certificates')
                    : commonStrings('standards')
                }
              />
            </Stack>
          </DialogContent>
        </Slide>
        {isAuditor || step === 1 ? (
          <DialogActions>
            {step === 1 && (
              <Button
                onClick={() => setStep(0)}
                startIcon={<ArrowBackRounded />}
                variant="outlined"
              >
                {commonStrings('back')}
              </Button>
            )}
            <Button
              disabled={isCompleteButtonDisabled}
              onClick={handleComplete}
              variant="contained"
            >
              {commonStrings('complete')}
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button
              onClick={handleCancel}
              variant="outlined"
            >
              {commonStrings('cancel')}
            </Button>
            <Button
              disabled={selectedCultivationIds?.length === 0 || selectedModuleIds?.length === 0}
              onClick={() => setStep(1)}
              variant="contained"
            >
              {commonStrings('next')}
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <AssessmentCultivationEditDialog
        isOpen={isAssessmentCultivationEditDialogOpen}
        onClose={closeAssessmentCultivationEditDialog}
        {...assessmentCultivationEditDialogProps}
      />
      <GenericDeleteDialog
        deleteMutation={useDeleteAssessmentPlotCultivationMutation}
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        {...deleteDialogProps}
      />
    </form>
  );
};
