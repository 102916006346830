import { Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDialogState, useTenantId } from 'src/hooks';
import { dashboardUserBlockStrings } from 'src/languages/en-UK';
import { useGetOrganizationMemberListQuery } from 'src/services/farmApi';
import { ROUTE_PATH_ORGANIZATION_USERS } from 'src/settings';

import { LoadingArea } from '../common';
import { DashboardBlockRow } from '../DashboardBlockRow';
import { DashboardBlockWrapper } from '../DashboardBlockWrapper';
import {
  OrganizationInvitationEditDialog,
  type OrganizationInvitationEditDialogProps,
} from '../dialogs/OrganizationInvitationEditDialog';

export const DashboardUserBlock = (): React.JSX.Element => {
  const tid = useTenantId();
  const navigate = useNavigate();
  const { data: organizationUsers, isLoading: isOrganizationUsersLoading } =
    useGetOrganizationMemberListQuery({ tid }, { skip: !tid });

  const [
    isOrganizationInvitationEditDialogOpen,
    openOrganizationInvitationEditDialog,
    closeOrganizationInvitationEditDialog,
    organizationInvitationEditDialogProps,
  ] = useDialogState<OrganizationInvitationEditDialogProps>();

  const handleClickAdd = () => {
    openOrganizationInvitationEditDialog({
      title: dashboardUserBlockStrings('addingUserToOrganization'),
      defaultValues: { roles: 'auditor' },
    });
  };

  const handleViewTotalUsers = () => {
    navigate(ROUTE_PATH_ORGANIZATION_USERS);
  };

  return (
    <DashboardBlockWrapper
      sx={{ maxHeight: 216 }}
      title={dashboardUserBlockStrings('yourOrganization')}
    >
      {!organizationUsers ? (
        <LoadingArea />
      ) : (
        <Stack spacing={2}>
          <DashboardBlockRow
            count={organizationUsers?.length?.toString()}
            isLoading={isOrganizationUsersLoading}
            label={dashboardUserBlockStrings('totalUser')}
            onClick={handleViewTotalUsers}
          />
          <Button
            fullWidth
            onClick={handleClickAdd}
            variant="outlined"
          >
            {dashboardUserBlockStrings('addNewUser')}
          </Button>
        </Stack>
      )}
      <OrganizationInvitationEditDialog
        isOpen={isOrganizationInvitationEditDialogOpen}
        onClose={closeOrganizationInvitationEditDialog}
        {...organizationInvitationEditDialogProps}
      />
    </DashboardBlockWrapper>
  );
};
