import { Box } from '@mui/material';
import { memo, type ReactNode } from 'react';

import { borderWidth, gridSize, standardBorder } from './AssessmentConfigMatrix.styles';

interface TableCellProps {
  children: ReactNode;
}

export const TableCell = memo(
  ({ children }: TableCellProps): React.JSX.Element => (
    <Box
      sx={(theme) => ({
        minWidth: `${gridSize + borderWidth}px`,
        minHeight: `${gridSize + borderWidth}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRight: standardBorder(theme),
        boxSizing: 'border-box',
      })}
    >
      {children}
    </Box>
  ),
);
