import {
  type OrganizationTypeRolesListParams,
  type RoleListOutputItem,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { injectQueryParams } from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'organization-type-roles';

const organizationTypeRoleEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationTypeRoleList: build.query<RoleListOutputItem[], OrganizationTypeRolesListParams>(
      {
        query: ({ tid, ...restParams }) =>
          injectQueryParams(`/tid/${tid}/${RESOURCE}/`, restParams),
        providesTags: [{ type: TagType.OrganizationTypeRoles, id: 'LIST' }],
      },
    ),
  }),
  overrideExisting: false,
});

export const { useGetOrganizationTypeRoleListQuery } = organizationTypeRoleEndpoints;
