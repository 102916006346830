import { RolesEnum } from 'src/__generated__/InternalApiTypes';

import { useCurrentUserRoles } from './useCurrentUserRoles';

export const useRoleCheck = () => {
  const currentUserRoles = useCurrentUserRoles();

  const isAuditor = currentUserRoles.includes(RolesEnum.Auditor);
  const isCBManager = currentUserRoles.includes(RolesEnum.CertificationBodyManager);
  const isFarmManager = currentUserRoles.includes(RolesEnum.FarmManager);
  const isFarmEmployee = currentUserRoles.includes(RolesEnum.FarmEmployee);
  const isFarm = isFarmManager || isFarmEmployee;
  const isCB = isAuditor || isCBManager;

  return {
    isAuditor,
    isCBManager,
    isFarmManager,
    isFarmEmployee,
    isFarm,
    isCB,
  };
};
