import { DeleteRounded } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useFormik } from 'formik';
import { isEqual, unionWith } from 'lodash';
import { useEffect, useState } from 'react';
import {
  type OrganizationClientIdentifierListOutputItem,
  type OrganizationClientIdentifierUpdateInput,
} from 'src/__generated__/InternalApiTypes';
import {
  GenericDeleteDialog,
  type GenericDeleteDialogProps,
} from 'src/components/dialogs/GenericDeleteDialog';
import { FormikMultiSelectField } from 'src/components/formikcomponents/FormikMultiSelectField';
import { FormikSelectField } from 'src/components/formikcomponents/FormikSelectField';
import { FormikTextField } from 'src/components/formikcomponents/FormikTextField';
import { useDialogState, useMutationFeedback, useTenantId } from 'src/hooks';
import { commonStrings, organizationClientIdentifiersStrings } from 'src/languages/en-UK';
import {
  useCreateOrganizationClientIdentifierMutation,
  useDeleteOrganizationClientIdentifierMutation,
  useUpdateOrganizationClientIdentifierMutation,
} from 'src/services/farmApi/endpoints/orgnizationClientIdentifiers';
import { type LabelValuePair } from 'src/types/LabelValuePair';
import { populateFormValues } from 'src/utils/populateFormValues';
import * as Yup from 'yup';

interface OrganizationClientIdenitifierFormProps {
  certificationBodyOptions: LabelValuePair[];
  isDeleteDisabled: boolean;
  isReadOnly: boolean;
  standardOptions: LabelValuePair[];
  usedClientIds: string[];
  organizationClientIdentifier?: OrganizationClientIdentifierListOutputItem | null;
  setIsShowingNewOrganizationClientIdentifierForm?: ((value: boolean) => void) | null;
}

export const OrganizationClientIdentifierForm = ({
  certificationBodyOptions,
  standardOptions,
  usedClientIds,
  isDeleteDisabled = false,
  isReadOnly = false,
  organizationClientIdentifier = null,
  setIsShowingNewOrganizationClientIdentifierForm = null,
}: OrganizationClientIdenitifierFormProps): React.JSX.Element => {
  const tid = useTenantId();

  const [additionalCertificationBodyOptions, setAdditionalCertificationBodyOptions] =
    useState<LabelValuePair[]>(certificationBodyOptions);
  const [additionalStandardOptions, setAdditionalStandardOptions] =
    useState<LabelValuePair[]>(standardOptions);
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog, deleteDialogProps] =
    useDialogState<Omit<GenericDeleteDialogProps, 'deleteMutation'>>();
  const [updateClientIdentifier, updateClientIdentifierResult] =
    useUpdateOrganizationClientIdentifierMutation();
  const [createClientIdentifier, createClientIdentifierResult] =
    useCreateOrganizationClientIdentifierMutation();

  const defaultValues: OrganizationClientIdentifierUpdateInput = {
    certification_body_id: '',
    value: '',
    standard_ids: [],
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: populateFormValues(
      defaultValues,
      organizationClientIdentifier
        ? {
            certification_body_id: organizationClientIdentifier?.certification_body?.uuid,
            value: organizationClientIdentifier.value,
            standard_ids: organizationClientIdentifier?.standards?.map((standard) => standard.uuid),
          }
        : defaultValues,
    ),
    validationSchema: Yup.object().shape({
      certification_body_id: Yup.string().required(commonStrings('required')),
      value: Yup.string()
        .required(commonStrings('required'))
        .notOneOf(usedClientIds, organizationClientIdentifiersStrings('clientIdAlreadyExists')),
      standard_ids: Yup.array().required(commonStrings('required')),
    }),
    onSubmit: undefined,
  });

  useMutationFeedback({
    result: updateClientIdentifierResult,
    successMessage: commonStrings('backendSaveSuccessMessage', {
      name: `${commonStrings('clientId')} ${formik.values.value}`,
    }),
    errorMessage: commonStrings('backendSaveFailMessage', {
      name: `${commonStrings('clientId')} ${organizationClientIdentifier?.value}`,
    }),
  });

  useMutationFeedback({
    result: createClientIdentifierResult,
    successMessage: commonStrings('backendCreateSuccessMessage', {
      name: `${commonStrings('clientId')} ${formik.values.value}`,
    }),
    onSuccess: () => {
      if (setIsShowingNewOrganizationClientIdentifierForm) {
        setIsShowingNewOrganizationClientIdentifierForm(false);
      }
    },
    errorMessage: commonStrings('backendCreateFailMessage', {
      name: `${commonStrings('clientId')} ${organizationClientIdentifier?.value}`,
    }),
  });

  useEffect(() => {
    if (
      organizationClientIdentifier &&
      !certificationBodyOptions.some(
        (option) => option.value === organizationClientIdentifier?.certification_body?.uuid,
      )
    ) {
      setAdditionalCertificationBodyOptions([
        ...certificationBodyOptions,
        {
          value: organizationClientIdentifier?.certification_body?.uuid,
          label: organizationClientIdentifier?.certification_body?.name,
        },
      ]);
    } else if (!organizationClientIdentifier) {
      setAdditionalCertificationBodyOptions(certificationBodyOptions);
    }
  }, [certificationBodyOptions, organizationClientIdentifier]);

  useEffect(() => {
    const selectedStandards = organizationClientIdentifier?.standards?.map((standard) => ({
      value: standard.uuid,
      label: standard.name,
    }));
    setAdditionalStandardOptions(unionWith(standardOptions, selectedStandards, isEqual));
  }, [standardOptions, organizationClientIdentifier]);

  const onDelete = () => {
    if (organizationClientIdentifier) {
      openDeleteDialog({
        entities: [
          {
            header: commonStrings('clientId'),
            name: organizationClientIdentifier.value,
            uuid: organizationClientIdentifier.uuid,
          },
        ],
      });
    }

    if (setIsShowingNewOrganizationClientIdentifierForm) {
      setIsShowingNewOrganizationClientIdentifierForm(false);
    }
  };

  const handleCreateOrUpdateClientIdentifier = () => {
    if (
      isEqual(formik.values, formik.initialValues) ||
      (formik.errors && Object.keys(formik.errors).length > 0)
    ) {
      return;
    }

    if (organizationClientIdentifier?.uuid && tid) {
      updateClientIdentifier({
        tid,
        id: organizationClientIdentifier.uuid,
        body: {
          certification_body_id: formik.values.certification_body_id,
          value: formik.values.value,
          standard_ids: formik.values.standard_ids,
        },
      });
    } else if (
      tid &&
      formik.values.certification_body_id &&
      formik.values.value &&
      formik.values.standard_ids.length > 0
    ) {
      createClientIdentifier({
        tid,
        body: {
          certification_body_id: formik.values.certification_body_id,
          value: formik.values.value,
          standard_ids: formik.values.standard_ids,
        },
      });
    }
  };

  return (
    <>
      <Box
        display="grid"
        gap={1.5}
        gridAutoColumns="5fr 5fr 5fr 1fr"
        gridAutoFlow={{ xs: 'row', sm: 'column' }}
        marginBottom={3}
      >
        <FormikSelectField
          disabled={isReadOnly}
          formik={formik}
          id="certification_body_id"
          label={commonStrings('certificationCompany')}
          onBlur={(e) => {
            formik.handleBlur(e);
            handleCreateOrUpdateClientIdentifier();
          }}
          options={additionalCertificationBodyOptions}
        />
        <FormikTextField
          disabled={isReadOnly}
          formik={formik}
          id="value"
          label={commonStrings('clientId')}
          onBlur={(e) => {
            formik.handleBlur(e);
            handleCreateOrUpdateClientIdentifier();
          }}
        />
        <FormikMultiSelectField
          chipSize="small"
          formik={formik}
          id="standard_ids"
          isDisabled={updateClientIdentifierResult.isLoading || isReadOnly}
          label={organizationClientIdentifiersStrings('applicableCertifications')}
          onBlur={() => {
            handleCreateOrUpdateClientIdentifier();
          }}
          onDelete={handleCreateOrUpdateClientIdentifier}
          options={additionalStandardOptions}
        />
        <Button
          color="secondary"
          disabled={isDeleteDisabled}
          onClick={onDelete}
          size="small"
          startIcon={<DeleteRounded />}
          sx={{ '.MuiButton-startIcon': { m: 0 } }}
        />
      </Box>
      <GenericDeleteDialog
        deleteMutation={useDeleteOrganizationClientIdentifierMutation}
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        {...deleteDialogProps}
      />
    </>
  );
};
