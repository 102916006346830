import LoadingButton from '@mui/lab/LoadingButton';
import { Container, Link as MuiLink, Paper, Stack, Toolbar, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppLogo } from 'src/components/common';
import { type AlertData } from 'src/components/common/types/AlertData';
import { FormFeedbackWrapper } from 'src/components/formikcomponents/FormFeedbackWrapper';
import { FormikTextField } from 'src/components/formikcomponents/FormikTextField';
import { ThemeContext } from 'src/components/ThemeProvider';
import { useAppDispatch } from 'src/hooks';
import { useGetUserOrigin } from 'src/hooks/useGetUserOrigin';
import { commonStrings, forgotPasswordPageStrings } from 'src/languages/en-UK';
import { useForgotPasswordMutation } from 'src/services/farmApi';
import { SignUpOriginEnum } from 'src/services/farmApi/endpoints/auth';
import { ROUTE_PATH_LOG_IN } from 'src/settings';
import { openSnackbar } from 'src/store/snackbarSlice';
import { appColors, leafTheme, smkTheme, theme } from 'src/theme';
import { canSubmitFormik } from 'src/utils/canSubmitFormik';
import * as Yup from 'yup';

export const ForgotPasswordPage = (): React.JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const origin = useGetUserOrigin();
  const [isForgotPasswordPending, setIsForgotPasswordPending] = useState(false);
  const [feedbackAlert, setFeedbackAlert] = useState<AlertData | undefined>(undefined);
  const [forgotPassword, { error: forgotPasswordError, isSuccess: isForgotPasswordSuccess }] =
    useForgotPasswordMutation();
  const { changeTheme } = useContext(ThemeContext);
  useEffect(() => {
    let selectedTheme;
    switch (origin) {
      case SignUpOriginEnum.SMK:
        selectedTheme = smkTheme;
        break;
      case SignUpOriginEnum.LEAF:
        selectedTheme = leafTheme;
        break;
      default:
        selectedTheme = theme;
        break;
    }
    changeTheme?.(selectedTheme);
  }, [changeTheme, origin]);

  useEffect(() => {
    if (forgotPasswordError && 'data' in forgotPasswordError) {
      const errorData = forgotPasswordError.data as { code: string; message: string };

      dispatch(
        openSnackbar({
          severity: 'error',
          message: errorData.message,
        }),
      );
    }

    if (isForgotPasswordSuccess) {
      setFeedbackAlert({
        severity: 'success',
        message: forgotPasswordPageStrings('forgotPasswordResetSuccess'),
      });
    }
  }, [forgotPasswordError, isForgotPasswordSuccess, dispatch]);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email(commonStrings('invalidEmail')).required(commonStrings('required')),
    }),
    onSubmit: async (values) => {
      const { email } = values;
      setIsForgotPasswordPending(true);
      await forgotPassword({ email });
      setIsForgotPasswordPending(false);
    },
  });

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        justifyContent: 'center',
      }}
    >
      <Paper sx={{ p: 2, my: 4 }}>
        <AppLogo
          large
          showName
          sx={{ pt: 2, pb: 2.5, mx: 'auto' }}
        />
        <Stack spacing={2}>
          <Typography
            align="center"
            variant="h2"
          >
            {commonStrings('forgotPassword')}
          </Typography>
          <FormFeedbackWrapper alert={feedbackAlert}>
            <form
              noValidate
              onSubmit={formik.handleSubmit}
            >
              <Stack spacing={2}>
                <FormikTextField
                  formik={formik}
                  id="email"
                  label={commonStrings('email')}
                  required
                  type="email"
                />
                <Toolbar disableGutters>
                  <LoadingButton
                    disabled={!canSubmitFormik(formik, true)}
                    fullWidth
                    loading={isForgotPasswordPending}
                    type="submit"
                    variant="contained"
                  >
                    {forgotPasswordPageStrings('submit')}
                  </LoadingButton>
                </Toolbar>
              </Stack>
            </form>
          </FormFeedbackWrapper>
        </Stack>
        <Typography align="center">
          <MuiLink
            component="span"
            onClick={() => {
              navigate(origin ? `${ROUTE_PATH_LOG_IN}?origin=${origin}` : ROUTE_PATH_LOG_IN);
            }}
            sx={{
              cursor: 'pointer',
              color: appColors.blue,
            }}
            underline="hover"
          >
            {commonStrings('backToLogIn')}
          </MuiLink>
        </Typography>
      </Paper>
    </Container>
  );
};
