import { EditRounded } from '@mui/icons-material';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { isEqual } from 'lodash';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  AssessmentStatusEnum,
  type AssessmentUpdateInput,
  RolesEnum,
} from 'src/__generated__/InternalApiTypes';
import { AssessmentConfigActionBar } from 'src/components/AssessmentConfigActionBar';
import { AssessmentConfigMatrix } from 'src/components/AssessmentConfigMatrix';
import { LoadingArea } from 'src/components/common';
import {
  AssessmentAutoAttachRequestDialog,
  type AssessmentAutoAttachRequestDialogProps,
} from 'src/components/dialogs/AssessmentAutoAttachRequestDialog';
import { AssessmentConfigDialog } from 'src/components/dialogs/AssessmentConfigDialog';
import {
  useAppDispatch,
  useAppSelector,
  useCurrentOrganization,
  useCurrentUserRoles,
  useDialogState,
  useGetAssessmentUserPermission,
  useMutationFeedback,
  useTenantId,
} from 'src/hooks';
import { assessmentConfigStepStrings, commonStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import {
  useGetAssessmentQuery,
  useGetModuleListQuery,
  useGetOrganizationCultivationListQuery,
  useGetSubscriptionListQuery,
  useUpdateAssessmentMutation,
} from 'src/services/farmApi';
import { useValidEvidenceExistsQuery } from 'src/services/farmApi/endpoints/evidence';
import { assessmentConfigActions } from 'src/store/assessmentConfig';
import { openSnackbar } from 'src/store/snackbarSlice';

import { type StepperContentBaseProps } from '../StepperPage';
import { contentStackStyle } from './AssessmentConfigStep.styles';

/**
 * Step within the "Assessment Flow" to select standards on a per cultivation
 * basis.
 */
export const AssessmentConfigStep = ({ ...props }: StepperContentBaseProps): React.JSX.Element => {
  const tid = useTenantId();
  const currentUserRoles = useCurrentUserRoles();
  const isFarmManager = currentUserRoles.includes(RolesEnum.FarmManager);
  const isFarmEmployee = currentUserRoles.includes(RolesEnum.FarmEmployee);
  const isAuditor = currentUserRoles.includes(RolesEnum.Auditor);
  const isCBManager = currentUserRoles.includes(RolesEnum.CertificationBodyManager);

  const dispatch = useAppDispatch();
  const isInitialized = useAppSelector((state) => state.assessmentConfig.initialized);

  const [
    isAutoAttachDialogOpen,
    openIsAutoAttachDialog,
    closeIsAutoAttachDialog,
    isAutoAttachDialogProps,
  ] = useDialogState<AssessmentAutoAttachRequestDialogProps>();

  // Always reset the config matrix if the assessment ID changes
  const [assessmentId] = useContext(AssessmentPageContext);
  const permissions = useGetAssessmentUserPermission(assessmentId);
  const { data: organization } = useCurrentOrganization();

  useEffect(() => {
    dispatch(assessmentConfigActions.reset());
  }, [assessmentId, dispatch]);
  const skip = !tid || !assessmentId;

  const [isNextTaskLoading, setIsNextTaskLoading] = useState(false);
  const [isPreviousTaskLoading, setIsPreviousTaskLoading] = useState(false);
  const [usedOrganizationClientIdentifierIds, setUsedOrganizationClientIdentifierIds] = useState<
    string[]
  >([]);

  // Assessment Data
  const { data: assessment, isLoading: isAssessmentLoading } = useGetAssessmentQuery(
    { tid, id: assessmentId },
    { skip },
  );

  // Cultivation Data
  const auditorClientIdentifierIds: string[] =
    isAuditor && assessment?.client_identifiers ? assessment?.client_identifiers : [];
  const { data: cultivationList, isLoading: isCultivationListLoading } =
    useGetOrganizationCultivationListQuery(
      { tid, client_identifier_id: auditorClientIdentifierIds },
      { skip },
    );

  // Standard Data
  const { data: moduleList, isLoading: isModuleListLoading } = useGetModuleListQuery(
    {
      has_parent: false,
    },
    { skip: !assessment },
  );

  const { data: subscriptions } = useGetSubscriptionListQuery(
    { tid, status: ['active'] },
    { skip: !tid },
  );

  const paidModuleList = useMemo(
    () =>
      (moduleList || []).filter((standard) =>
        subscriptions?.some((sub) =>
          sub.subscription_plan_standard_ids.includes(standard.standard_id),
        ),
      ),
    [moduleList, subscriptions],
  );

  const [updateAssessment, updateAssessmentResult] = useUpdateAssessmentMutation();

  // Auto-attach evidence check
  const validEvidenceExistsResult = useValidEvidenceExistsQuery(tid, { skip: !tid });

  const isLoading = isAssessmentLoading || isCultivationListLoading || isModuleListLoading;

  const isDisabled = isLoading || updateAssessmentResult.isLoading;
  const buttonRef = useRef(null);

  const [isConfigDialogOpen, handleOpenConfigDialog, handleCloseConfigDialog] = useDialogState();

  // Rebuild the table when the required data is available
  useEffect(() => {
    if (isInitialized || isLoading) {
      return;
    }
    const selections = assessment ? assessment.module_cultivation_selections : null;

    // Determine what columns, rows, and selections to build the table with
    const visibleModuleList =
      paidModuleList && assessment
        ? paidModuleList.filter(
            (module) =>
              assessment?.standard_ids.includes(module.standard_id) &&
              selections?.some((s) => s.module_id === module.uuid),
          )
        : null;
    const visibleCultivationList =
      cultivationList && assessment
        ? cultivationList.filter((cultivation) =>
            assessment?.module_cultivation_selections?.some(
              (selection) => selection.organization_cultivation_id === cultivation.uuid,
            ),
          )
        : null;

    if (selections && visibleModuleList && visibleCultivationList) {
      dispatch(
        assessmentConfigActions.rebuildFromAssessment({
          selections,
          cultivationList: visibleCultivationList,
          moduleList: visibleModuleList,
          hasPlotName: isFarmManager || isFarmEmployee,
        }),
      );
    }

    // TODOHasan: Do not add handleOpenConfigDialog as a dependency. Find a correct way.
  }, [
    assessment,
    cultivationList,
    dispatch,
    isFarmEmployee,
    isFarmManager,
    isInitialized,
    isLoading,
    paidModuleList,
  ]);

  // Open the dialog immediately if the assessment has no selected standards.
  useEffect(() => {
    if (isInitialized) {
      return;
    }

    // To open configuration panel if just started assessment by default
    if (assessment?.module_cultivation_selections?.length === 0) {
      handleOpenConfigDialog();
    }
    // TODOHasan: Do not add handleOpenConfigDialog and isInitialized as a dependency.
    // Find a correct way.
  }, [assessment]);

  // Fake progress percantage value
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isNextTaskLoading) {
      timer = setInterval(() => {
        const increaseSpeed = updateAssessmentResult?.isLoading ? 1 : 15;
        const maxValue = updateAssessmentResult?.isLoading ? 99 : 90;
        setProgressValue((prevProgress) =>
          prevProgress + increaseSpeed >= maxValue ? 99 : prevProgress + increaseSpeed,
        );
      }, 150);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isNextTaskLoading, updateAssessmentResult?.isLoading]);

  const navigateToNextStep = () => {
    if (props.isFinalTask) {
      props.openFinishDialog();
    } else {
      setIsNextTaskLoading(true);
      props.moveToNextTask();
    }
  };

  const navigateToPreviousStep = () => {
    setIsPreviousTaskLoading(true);
    props.moveToPreviousTask();
  };

  const handleUpdateAssessment = (bodyValues: AssessmentUpdateInput) => {
    if (permissions?.assessments?.update && tid && assessmentId) {
      setIsNextTaskLoading(true);
      updateAssessment({
        tid,
        id: assessmentId,
        body: {
          ...bodyValues,
          ...(!isAuditor &&
            usedOrganizationClientIdentifierIds && {
              client_identifiers: usedOrganizationClientIdentifierIds,
            }),
        },
      });
    } else {
      dispatch(
        openSnackbar({
          message: commonStrings('notAuthorized'),
          severity: 'error',
        }),
      );
    }
  };

  const handleContinue = (bodyValues: AssessmentUpdateInput): void => {
    const comparableSelections = assessment?.module_cultivation_selections?.map((selection) => ({
      module_id: selection.module_id,
      organization_cultivation_id: selection.organization_cultivation_id,
      ...(selection.malus_points !== undefined &&
        selection.malus_points !== null && { malus_points: selection.malus_points }),
    }));
    if (!isEqual(comparableSelections, bodyValues.module_cultivation_selections)) {
      if (
        assessment?.status === AssessmentStatusEnum.Draft &&
        (isFarmManager || isFarmEmployee) &&
        validEvidenceExistsResult?.data?.valid_evidence_exists
      ) {
        openIsAutoAttachDialog({
          onClick: (enableAutoAttach) => {
            const updatedBodyValues = {
              ...bodyValues,
              is_evidence_auto_attached: enableAutoAttach,
            };
            handleUpdateAssessment(updatedBodyValues);
          },
        });
      } else {
        handleUpdateAssessment(bodyValues);
      }
    } else {
      navigateToNextStep();
    }
  };

  useMutationFeedback({
    result: updateAssessmentResult,
    onSuccess: () => navigateToNextStep(),
    onError: () => setIsNextTaskLoading(false),
    successMessage: commonStrings('backendSaveSuccessMessage', {
      name: assessment?.name,
    }),
    errorMessage: commonStrings('backendSaveFailMessage', {
      name: assessment?.name,
    }),
  });

  const maxWidth = 'xl';

  return (
    <>
      <Container maxWidth={maxWidth}>
        {isNextTaskLoading && (
          <Box
            alignItems="center"
            display="flex"
            flex={1}
            flexDirection="column"
            marginTop="25vh"
          >
            <LoadingArea
              size={48}
              value={progressValue}
              variant="determinate"
            />
            <Typography
              color="textSecondary"
              component="div"
              sx={{ marginTop: -1.25 }}
              variant="caption"
            >{`${Math.round(progressValue)}%`}</Typography>
            <Typography paddingTop={2}>{assessmentConfigStepStrings('loadingMessage')}</Typography>
          </Box>
        )}
        {!isNextTaskLoading && (
          <Stack
            spacing={2}
            sx={contentStackStyle}
          >
            <Typography
              paddingBottom={1}
              variant="h1"
            >
              {isAuditor || isCBManager
                ? commonStrings('inspectionSetup')
                : assessmentConfigStepStrings('isEverythingSetUpCorrectly')}
            </Typography>
            <Typography variant="body1">
              {isAuditor || isCBManager
                ? assessmentConfigStepStrings('assessmentConfigStepDescriptionForAuditor')
                : assessmentConfigStepStrings('assessmentConfigStepDesc')}
            </Typography>
            <AssessmentConfigMatrix
              emptyState={
                <Button
                  ref={buttonRef}
                  onClick={handleOpenConfigDialog}
                  startIcon={<EditRounded />}
                  variant="contained"
                >
                  {isAuditor || isCBManager
                    ? commonStrings('editInspectionSetup')
                    : commonStrings('editAssessmentSetup')}
                </Button>
              }
              isAuditorOrCBManager={isAuditor || isCBManager}
              isDisabled={isDisabled}
              isLoading={isLoading}
              selectedModuleList={paidModuleList}
            />
            <AssessmentConfigDialog
              assessment={assessment}
              isLoading={isModuleListLoading}
              isOpen={isConfigDialogOpen}
              moduleList={
                (isFarmManager || isFarmEmployee) && !organization?.is_paying_organization
                  ? moduleList
                  : paidModuleList
              }
              onClose={handleCloseConfigDialog}
              paidModuleList={paidModuleList}
              setUsedOrganizationClientIdentifierIds={setUsedOrganizationClientIdentifierIds}
            />
          </Stack>
        )}
      </Container>
      <AssessmentConfigActionBar
        isConfigButtonDisabled={isDisabled}
        isDisabled={isDisabled || isPreviousTaskLoading || isNextTaskLoading}
        isFinalTask={props.isFinalTask}
        isFirstTask={props.isFirstTask}
        isInitialized={isInitialized}
        isNextTaskLoading={isNextTaskLoading}
        isPreviousTaskLoading={isPreviousTaskLoading}
        maxWidth={maxWidth}
        nextTaskName={props.nextTaskName}
        onNext={handleContinue}
        onOpen={handleOpenConfigDialog}
        onPrevious={navigateToPreviousStep}
      />
      <AssessmentAutoAttachRequestDialog
        isOpen={isAutoAttachDialogOpen}
        onClose={closeIsAutoAttachDialog}
        {...isAutoAttachDialogProps}
      />
    </>
  );
};
