import { Menu } from '@mui/material';
import { type Dispatch, type SetStateAction } from 'react';

import { Filters } from './Filters';

interface AssessmentControlPointStepFiltersProps {
  filterAnchor: HTMLElement | null;
  setFilterAnchor: Dispatch<SetStateAction<HTMLElement | null>>;
}

export const AssessmentControlPointStepFilters = ({
  filterAnchor,
  setFilterAnchor,
}: AssessmentControlPointStepFiltersProps): React.JSX.Element => {
  const isOpen = Boolean(filterAnchor);
  const handleCloseMenu = () => {
    setFilterAnchor(null);
  };
  return (
    <Menu
      anchorEl={filterAnchor}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      disableScrollLock
      id="filter-menu"
      MenuListProps={{ 'aria-labelledby': 'filter-button' }}
      onClose={handleCloseMenu}
      open={isOpen}
      sx={{ '& .MuiMenu-paper': { height: '76%' } }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <Filters />
    </Menu>
  );
};
