import {
  ArrowBackRounded,
  BusinessRounded,
  CheckRounded,
  DeleteRounded,
  EditRounded,
  UploadRounded,
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Avatar, Box, Button, Link as MuiLink, Toolbar, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { omitBy } from 'lodash';
import { type ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { RolesEnum } from 'src/__generated__/InternalApiTypes';
import {
  SubscriptionPlanDialog,
  type SubscriptionPlanDialogProps,
} from 'src/components/dialogs/SubscriptionPlanDialog';
import { FormikCountryField } from 'src/components/formikcomponents/FormikCountryField';
import { FormikTextField } from 'src/components/formikcomponents/FormikTextField';
import { TenantContext } from 'src/components/TenantProvider';
import {
  useCurrentUser,
  useCurrentUserRoles,
  useDialogState,
  useMutationFeedback,
} from 'src/hooks';
import { useIsLeafOrigin } from 'src/hooks/useGetUserOrigin';
import { commonStrings, organizationDetailsFormStrings } from 'src/languages/en-UK';
import {
  useCreateOrganizationMutation,
  useGetOrganizationQuery,
  useUpdateOrganizationMutation,
} from 'src/services/farmApi';
import {
  useDeleteOrganizationLogoMutation,
  useUpdateOrganizationLogoMutation,
} from 'src/services/farmApi/endpoints/organizationLogo';
import {
  MEMBER_SUPPORT_MAIL_ADDRESS_LEAF,
  PHONE_REG_EX,
  ROUTE_PATH_CREATE_MORE_ORGANIZATION,
  URL_REG_EX,
} from 'src/settings';
import { appColors } from 'src/theme';
import { populateFormValues } from 'src/utils/populateFormValues';
import * as Yup from 'yup';

interface OrganizationDetailsFormProps {
  hasStepperButton: boolean;
  onSuccess: () => void | undefined;
  seenTabIndex: number;
  setSeenTabIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const OrganizationDetailsForm = ({
  onSuccess,
  seenTabIndex,
  setSeenTabIndex,
  hasStepperButton = false,
}: OrganizationDetailsFormProps): React.JSX.Element => {
  const location = useLocation();
  const isLeafOrigin = useIsLeafOrigin();
  const { organizationId, tenantId, setActiveMembership } = useContext(TenantContext);
  const { shouldResetOrganizationDetailForm } = location.state || {};
  const isSubsequentCreation =
    shouldResetOrganizationDetailForm && location.pathname === ROUTE_PATH_CREATE_MORE_ORGANIZATION;
  const [updateOrganization, updateOrganizationResult] = useUpdateOrganizationMutation();
  const [createOrganization, createOrganizationResult] = useCreateOrganizationMutation();
  const [updateOrganizationLogo] = useUpdateOrganizationLogoMutation();
  const [deleteOrganizationLogo, deleteOrganizationLogoResult] =
    useDeleteOrganizationLogoMutation();
  const currentUserRoles = useCurrentUserRoles();
  const currentUser = useCurrentUser();
  const isCBManager = currentUserRoles.includes(RolesEnum.CertificationBodyManager);
  const isFarmEmployee = currentUserRoles.includes(RolesEnum.FarmEmployee);
  const isFarmManager = currentUserRoles.includes(RolesEnum.FarmManager);
  const inputFileRef = useRef(null);
  const [originalFile, setOriginalFile] = useState<File | null>();
  const [base64Logo, setBase64Logo] = useState(null);
  const [
    isSubscriptionPlanOpen,
    // TODOHasan: This code will be reactivated after AFA-1217.
    // openSubscriptionPlan,
    closeSubscriptionPlan,
    subscriptionPlanDialogProps,
  ] = useDialogState<SubscriptionPlanDialogProps>();

  // Fetch data
  const { data: organization, isLoading: isGetOrganizationLoading } = useGetOrganizationQuery(
    { id: organizationId, tid: tenantId },
    {
      skip: !organizationId || !tenantId,
    },
  );

  // TODOHasan: BE should fixed this type error
  // @ts-expect-error
  const isOrganizationManageExternally = organization?.is_managed_externally;
  const isLeaf = isLeafOrigin && isOrganizationManageExternally;

  useEffect(() => {
    if (organization?.logo?.file_object && !isSubsequentCreation) {
      setBase64Logo(organization.logo.file_object);
    }
  }, [isSubsequentCreation, organization]);

  const defaultValues = {
    address: '',
    city: '',
    country: '',
    email: '',
    name: '',
    phone_number: '',
    postal_code: '',
    region: '',
    street1: '',
    street2: '',
    website: '',
  };

  let prefilledValues = defaultValues;
  if (organization && !isSubsequentCreation) {
    prefilledValues = {
      ...prefilledValues,
      ...organization,
    };
  }
  if (hasStepperButton && currentUser?.currentData?.email) {
    prefilledValues = {
      ...prefilledValues,
      email: currentUser?.currentData?.email,
    };
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: populateFormValues(defaultValues, prefilledValues),
    validationSchema: Yup.object().shape({
      address: Yup.string(),
      city: Yup.string(),
      country: Yup.string().required(commonStrings('required')),
      email: Yup.string().required(commonStrings('required')).email(commonStrings('invalidEmail')),
      name: Yup.string().required(commonStrings('required')),
      phone_number: Yup.string()
        .required(commonStrings('required'))
        .matches(PHONE_REG_EX, organizationDetailsFormStrings('invalidPhoneNumber')),
      postal_code: Yup.string(),
      region: Yup.string(),
      street1: Yup.string(),
      street2: Yup.string().nullable(),
      website: Yup.string().matches(
        URL_REG_EX,
        organizationDetailsFormStrings('websiteShouldBeValidUrl'),
      ),
    }),
    onSubmit: (values) => {
      const filteredValues = omitBy(values, (value) => value === '' || !value);
      if (organizationId && !isSubsequentCreation && tenantId) {
        updateOrganization({
          tid: tenantId,
          id: organizationId,
          body: values,
        });
      } else {
        createOrganization(filteredValues);
      }
    },
  });

  useMutationFeedback({
    result: updateOrganizationResult,
    onSuccess: () => {
      if (hasStepperButton && seenTabIndex === 0) {
        setSeenTabIndex(1);
      }
      onSuccess?.();
    },
    successMessage: commonStrings('backendSaveSuccessMessage', {
      name: formik.values.name,
    }),
    errorMessage: commonStrings('backendSaveFailMessage', {
      name: formik.values.name,
    }),
  });

  useMutationFeedback({
    result: createOrganizationResult,
    onSuccess: (result) => {
      if (originalFile) {
        updateOrganizationLogo({
          tid: result.tenant_id,
          id: result.uuid,
          body: {
            logo: originalFile,
          },
        });
      }
      if (hasStepperButton && seenTabIndex === 0) {
        setSeenTabIndex(1);
      }
      if (isSubsequentCreation) {
        setActiveMembership?.(result);
      }
      onSuccess?.();
    },
    successMessage: commonStrings('backendCreateSuccessMessage', {
      name: formik.values.name,
    }),
    errorMessage: commonStrings('backendCreateFailMessage', {
      name: formik.values.name,
    }),
  });

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.currentTarget.files?.[0];
      setOriginalFile(file);
      const reader = new FileReader();
      reader.onload = (evt) => {
        if (evt.target?.result) {
          setBase64Logo(evt.target.result);
          if (organizationId && !isSubsequentCreation && tenantId && file) {
            updateOrganizationLogo({
              tid: tenantId,
              id: organizationId,
              body: {
                logo: file,
              },
            });
          }
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadClick = () => {
    inputFileRef?.current?.click();
  };

  const handleDeleteClick = () => {
    setBase64Logo(null);
    setOriginalFile(null);
    if (organizationId && tenantId && !isSubsequentCreation) {
      deleteOrganizationLogo({
        tid: tenantId,
        id: organizationId,
      });
    }
  };

  const isSubmitLoading = createOrganizationResult.isLoading || updateOrganizationResult.isLoading;
  const isDisabled =
    isOrganizationManageExternally || isSubmitLoading || (isFarmEmployee && !isSubsequentCreation);

  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <Box
        display="flex"
        flexDirection="column"
        minHeight="calc(100vh - 264px)"
      >
        {/* TODOHasan: This code will be reactivated after AFA-1217. 
        <Typography
          lineHeight={3.43}
          variant="subtitle2"
        >
          {organizationDetailsFormStrings('yourOrganizationIsCurrentlyOn')}
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          gap={2.5}
          sx={{ my: 2 }}
        >
          <Chip
            color="primary"
            // TODOHasan: This should come from BE
            label="SMK plan"
            variant="outlined"
          />
          <Button
            color="info"
            onClick={() => {
              openSubscriptionPlan();
            }}
            size="small"
            variant="text"
          >
            {organizationDetailsFormStrings('changeYourPlan')}
          </Button>
        </Box> */}
        <Box
          display="grid"
          gap={1.5}
          gridTemplateAreas={`
          "generalHeader generalHeader"
          "name organizationLogo"
          "website organizationLogo"
          "email organizationLogo"
          "phoneNumber organizationLogo"
        `}
          gridTemplateColumns="repeat(2, 1fr)"
          gridTemplateRows="auto"
          marginBottom={2}
        >
          <Typography
            gridArea="generalHeader"
            lineHeight={3.43}
            variant="subtitle2"
          >
            {commonStrings('general')}
          </Typography>
          <FormikTextField
            disabled={isDisabled}
            formik={formik}
            id="name"
            isLoading={isGetOrganizationLoading}
            label={organizationDetailsFormStrings('organizationName')}
            required
            sx={{ gridArea: 'name' }}
          />
          <FormikTextField
            disabled={isDisabled}
            formik={formik}
            id="website"
            isLoading={isGetOrganizationLoading}
            label={organizationDetailsFormStrings('website')}
            sx={{ gridArea: 'website' }}
          />
          <FormikTextField
            disabled={isDisabled}
            formik={formik}
            id="email"
            isLoading={isGetOrganizationLoading}
            label={organizationDetailsFormStrings('primaryOrganizationContactEmail')}
            required
            sx={{ gridArea: 'email' }}
            type="email"
          />
          <FormikTextField
            disabled={isDisabled}
            formik={formik}
            id="phone_number"
            isLoading={isGetOrganizationLoading}
            label={organizationDetailsFormStrings('phoneNumber')}
            placeholder="+00 0 00 00 00 00"
            required
            sx={{ gridArea: 'phoneNumber' }}
          />
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            gap={2}
            sx={{ gridArea: 'organizationLogo' }}
          >
            <Typography variant="body1">
              {organizationDetailsFormStrings('organizationLogo')}
            </Typography>
            <Avatar
              src={base64Logo}
              sx={{ width: 174, height: 174 }}
            >
              {!base64Logo && <BusinessRounded sx={{ height: 50, width: 50 }} />}
            </Avatar>
            {(isFarmManager || isCBManager || isSubsequentCreation) && (
              <>
                <input
                  ref={inputFileRef}
                  hidden
                  onChange={handleFileChange}
                  type="file"
                />
                {base64Logo ? (
                  <Box
                    display="flex"
                    flexDirection="row"
                    gap={2}
                  >
                    <LoadingButton
                      loading={isGetOrganizationLoading}
                      onClick={handleUploadClick}
                      size="small"
                      startIcon={<EditRounded />}
                      variant="outlined"
                    >
                      {commonStrings('change')}
                    </LoadingButton>
                    <LoadingButton
                      color="error"
                      loading={isGetOrganizationLoading || deleteOrganizationLogoResult?.isLoading}
                      onClick={handleDeleteClick}
                      size="small"
                      startIcon={<DeleteRounded />}
                      variant="outlined"
                    >
                      {commonStrings('delete')}
                    </LoadingButton>
                  </Box>
                ) : (
                  <LoadingButton
                    loading={isGetOrganizationLoading}
                    onClick={handleUploadClick}
                    size="small"
                    startIcon={<UploadRounded />}
                    variant="outlined"
                  >
                    {commonStrings('upload')}
                  </LoadingButton>
                )}
              </>
            )}
          </Box>
        </Box>
        {!isCBManager && (
          <Box
            display="grid"
            gap={2}
            gridTemplateAreas={
              isLeaf
                ? `"addressHeader addressHeader"
          "address address"
          "postalCode region"
          "country country"`
                : `"addressHeader addressHeader"
          "street1 street1"
          "street2 street2"
          "city city"
          "postalCode region"
          "country country"`
            }
            gridTemplateColumns="repeat(2, 1fr)"
            gridTemplateRows="auto"
          >
            <Typography
              gridArea="addressHeader"
              lineHeight={3.43}
              variant="subtitle2"
            >
              {commonStrings('address')}
            </Typography>
            {isLeaf ? (
              <FormikTextField
                disabled={isDisabled}
                formik={formik}
                id="address"
                isLoading={isGetOrganizationLoading}
                label={commonStrings('address')}
                sx={{ gridArea: 'address' }}
              />
            ) : (
              <>
                <FormikTextField
                  disabled={isDisabled}
                  formik={formik}
                  id="street1"
                  isLoading={isGetOrganizationLoading}
                  label={organizationDetailsFormStrings('streetLine1')}
                  sx={{ gridArea: 'street1' }}
                />
                <FormikTextField
                  disabled={isDisabled}
                  formik={formik}
                  id="street2"
                  isLoading={isGetOrganizationLoading}
                  label={organizationDetailsFormStrings('streetLine2')}
                  sx={{ gridArea: 'street2' }}
                />
                <FormikTextField
                  disabled={isDisabled}
                  formik={formik}
                  id="city"
                  isLoading={isGetOrganizationLoading}
                  label={commonStrings('city')}
                  sx={{ gridArea: 'city' }}
                />
              </>
            )}
            <FormikTextField
              disabled={isDisabled}
              formik={formik}
              id="postal_code"
              isLoading={isGetOrganizationLoading}
              label={commonStrings('postalCode')}
              sx={{ gridArea: 'postalCode' }}
            />
            <FormikTextField
              disabled={isDisabled}
              formik={formik}
              id="region"
              isLoading={isGetOrganizationLoading}
              label={organizationDetailsFormStrings('stateProvinceRegionCounty')}
              sx={{ gridArea: 'region' }}
            />
            <FormikCountryField
              disabled={isDisabled}
              formik={formik}
              id="country"
              isLoading={isGetOrganizationLoading}
              label={commonStrings('country')}
              required
              sx={{ gridArea: 'country' }}
            />
          </Box>
        )}
        {isLeaf && (
          <Box sx={{ mt: 2 }}>
            <Typography component="span">
              {organizationDetailsFormStrings('leafInfoMsg')}
              <MuiLink
                component="a"
                href={`mailto:${MEMBER_SUPPORT_MAIL_ADDRESS_LEAF}`}
                sx={{
                  ml: 0.5,
                  cursor: 'pointer',
                  color: appColors.blue,
                }}
              >
                {MEMBER_SUPPORT_MAIL_ADDRESS_LEAF}
              </MuiLink>
            </Typography>
          </Box>
        )}
        {hasStepperButton ? (
          <Toolbar
            disableGutters
            sx={{ justifyContent: 'flex-end', alignItems: 'flex-end', flex: 1, mt: 2, gap: 1 }}
            variant="dense"
          >
            <Button
              disabled
              startIcon={<ArrowBackRounded />}
              variant="outlined"
            >
              {commonStrings('back')}
            </Button>
            <LoadingButton
              disabled={isDisabled}
              loading={isSubmitLoading}
              type="submit"
              variant="contained"
            >
              {commonStrings('next')}
            </LoadingButton>
          </Toolbar>
        ) : (
          <Toolbar
            disableGutters
            sx={{ justifyContent: 'flex-end', alignItems: 'flex-end', flex: 1, mt: 2, gap: 1 }}
            variant="dense"
          >
            <LoadingButton
              disabled={isDisabled}
              loading={isSubmitLoading}
              startIcon={<CheckRounded />}
              type="submit"
              variant="contained"
            >
              {commonStrings('saveChanges')}
            </LoadingButton>
          </Toolbar>
        )}
      </Box>
      <SubscriptionPlanDialog
        isOpen={isSubscriptionPlanOpen}
        onClose={closeSubscriptionPlan}
        {...subscriptionPlanDialogProps}
      />
    </form>
  );
};
