import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OrganizationTypesEnum, RolesEnum } from 'src/__generated__/InternalApiTypes';
import { TabbedPage, type TabbedPageTabDef } from 'src/components/common/TabbedPage';
import { OrganizationClientIdentifierFormLeaf } from 'src/components/forms/OrganizationClientIdentifierFormLeaf';
import { OrganizationDetailsForm } from 'src/components/forms/OrganizationDetailsForm';
import { OrganizationClientIdentifiers } from 'src/components/OrganizationClientIdentifiers';
import { OrganizationUser } from 'src/components/OrganizationUser';
import { OrganizationCultivationTable } from 'src/components/tables/OrganizationCultivationTable';
import { useCurrentOrganization, useCurrentUserRoles } from 'src/hooks';
import { useIsLeafOrigin } from 'src/hooks/useGetUserOrigin';
import { commonStrings, organizationPageStrings } from 'src/languages/en-UK';
import { ROUTE_PATH_CREATE_ORGANIZATION_CLIENT_IDS } from 'src/settings';

type OrganizationPageProps = {
  isFirstLogin?: boolean;
};

export const OrganizationPage = ({
  isFirstLogin = false,
}: OrganizationPageProps): React.JSX.Element => {
  const navigate = useNavigate();
  const { data: organization } = useCurrentOrganization();
  const isLeafOrigin = useIsLeafOrigin();
  const currentUserRoles = useCurrentUserRoles();
  const [seenTabIndex, setSeenTabIndex] = useState(0);

  const tabs = useMemo(() => {
    const isCBManager = currentUserRoles.includes(RolesEnum.CertificationBodyManager);
    const isAuditor = currentUserRoles.includes(RolesEnum.Auditor);
    let tabList: TabbedPageTabDef[] = [
      {
        slug: 'details',
        title: commonStrings('details'),
        content: (
          <OrganizationDetailsForm
            hasStepperButton={isFirstLogin}
            onSuccess={
              isFirstLogin ? () => navigate(ROUTE_PATH_CREATE_ORGANIZATION_CLIENT_IDS) : undefined
            }
            seenTabIndex={seenTabIndex}
            setSeenTabIndex={setSeenTabIndex}
          />
        ),
      },
      {
        slug: 'users',
        title: organizationPageStrings('users'),
        content: <OrganizationUser />,
      },
    ];
    if (!isCBManager && !isAuditor) {
      tabList.push({
        slug: 'cultivations',
        title: commonStrings('cultivations'),
        content: <OrganizationCultivationTable />,
      });
    }
    if (organization?.type !== OrganizationTypesEnum.CertificationBody) {
      // TODOHasan: BE should fixed this type error
      // @ts-expect-error
      const isOrganizationManageExternally = organization?.is_managed_externally;
      const isLeaf = isLeafOrigin && isOrganizationManageExternally;
      tabList = tabList?.toSpliced?.(1, 0, {
        slug: 'id-numbers',
        title: organizationPageStrings('idNumbers'),
        content: isLeaf ? (
          <OrganizationClientIdentifierFormLeaf />
        ) : (
          <OrganizationClientIdentifiers />
        ),
      });
    }
    return tabList;
  }, [
    currentUserRoles,
    isFirstLogin,
    isLeafOrigin,
    navigate,
    // TODOHasan: BE should fixed this type error
    // @ts-expect-error
    organization?.is_managed_externally,
    organization?.type,
    seenTabIndex,
  ]);

  return (
    <TabbedPage
      hasStepperButton={isFirstLogin}
      seenTabIndex={seenTabIndex}
      setSeenTabIndex={setSeenTabIndex}
      tabs={tabs}
      title={
        isFirstLogin || !organization
          ? commonStrings('organizationProfile')
          : commonStrings('organizationProfileDynamic', {
              organizationName: organization.name,
            })
      }
    />
  );
};
