import {
  FormHelperText,
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
  type ToggleButtonGroupProps,
} from '@mui/material';
import { type FormikProps, type FormikValues } from 'formik';
import { type LabelValuePair } from 'src/types/LabelValuePair';

interface FormikToggleButtonFieldProps extends ToggleButtonGroupProps {
  formik: FormikProps<FormikValues>;
  id: string;
  options: LabelValuePair[];
  isLoading?: boolean;
}

export const FormikToggleButtonField = ({
  id,
  formik,
  options,
  disabled = false,
  isLoading = false,
  ...restProps
}: FormikToggleButtonFieldProps) => {
  const hasError = formik.touched[id] && formik.errors[id];

  return isLoading ? (
    <Skeleton
      animation="wave"
      height={40}
      variant="text"
      width={100}
    />
  ) : (
    <>
      <ToggleButtonGroup
        color="primary"
        disabled={isLoading || disabled}
        exclusive
        id={id}
        onChange={formik.handleChange}
        size="small"
        sx={{
          boxShadow:
            '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
        }}
        value={formik.values[id]}
        {...restProps}
      >
        {options?.map((option) => (
          <ToggleButton
            key={option.value}
            fullWidth
            name={id}
            sx={{
              color: hasError ? 'error.main' : undefined,
              borderColor: hasError ? 'error.main' : undefined,
              paddingX: 2,
              whiteSpace: 'nowrap',
            }}
            value={option.value}
          >
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {hasError && (
        <FormHelperText sx={{ color: 'error.main' }}>{formik.errors[id] as string}</FormHelperText>
      )}
    </>
  );
};
