import {
  type OrganizationLogoUpdateInput,
  type OrganizationLogoUpdateOutput,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import {
  type DeleteParamsWithTenantId,
  formikToFormData,
  type UpdateParamsWithTenantId,
} from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'organization-logo';

type TransformedOrganizationLogoUpdateInput = Omit<OrganizationLogoUpdateInput, 'logo'> & {
  logo: File;
};

const organizationLogoEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    updateOrganizationLogo: build.mutation<
      OrganizationLogoUpdateOutput,
      UpdateParamsWithTenantId<TransformedOrganizationLogoUpdateInput>
    >({
      query: ({ id, tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/update/`,
        method: 'PATCH',
        body: formikToFormData(body),
      }),
      invalidatesTags: [TagType.Organization],
    }),
    deleteOrganizationLogo: build.mutation<void, DeleteParamsWithTenantId>({
      query: ({ id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/delete/`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, _params) => [TagType.Organization],
    }),
  }),
  overrideExisting: false,
});

export const { useDeleteOrganizationLogoMutation, useUpdateOrganizationLogoMutation } =
  organizationLogoEndpoints;
