import { Alert, Button, Container, Link, Paper, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppLogo, LoadingArea } from 'src/components/common';
import { type AlertData } from 'src/components/common/types/AlertData';
import { ThemeContext } from 'src/components/ThemeProvider';
import { useAppDispatch } from 'src/hooks';
import { useGetUserOrigin } from 'src/hooks/useGetUserOrigin';
import { commonStrings, signUpVerificationPageStrings } from 'src/languages/en-UK';
import { useResendSignUpVerificationMutation, useVerifySignUpMutation } from 'src/services/farmApi';
import { SignUpOriginEnum } from 'src/services/farmApi/endpoints/auth';
import { ROUTE_PATH_LOG_IN } from 'src/settings';
import { openSnackbar } from 'src/store/snackbarSlice';
import { leafTheme, smkTheme, theme } from 'src/theme';

export const SignUpVerificationPage = (): React.JSX.Element => {
  const [feedbackAlert, setFeedbackAlert] = useState<AlertData | undefined>(undefined);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const origin = useGetUserOrigin();
  const { changeTheme } = useContext(ThemeContext);
  useEffect(() => {
    let selectedTheme;
    switch (origin) {
      case SignUpOriginEnum.SMK:
        selectedTheme = smkTheme;
        break;
      case SignUpOriginEnum.LEAF:
        selectedTheme = leafTheme;
        break;
      default:
        selectedTheme = theme;
        break;
    }
    changeTheme?.(selectedTheme);
  }, [changeTheme, origin]);
  const [verifySignUp, { error: verifySignUpError, isSuccess: isVerifySignUpSuccess }] =
    useVerifySignUpMutation();
  const [
    resendSignUpVerification,
    { error: resendSignUpVerificationError, isSuccess: isResendSignUpVerificationSuccess },
  ] = useResendSignUpVerificationMutation();

  const email = params.get('email') || '';
  const code = params.get('code') || '';

  useEffect(() => {
    if (resendSignUpVerificationError && 'data' in resendSignUpVerificationError) {
      const errorData = resendSignUpVerificationError.data as { code: string; message: string };
      dispatch(
        openSnackbar({
          severity: 'error',
          message: errorData.message,
        }),
      );
    }

    if (isResendSignUpVerificationSuccess) {
      setFeedbackAlert({
        severity: 'success',
        message: `${signUpVerificationPageStrings('confirmationCodeResent')} ${commonStrings('checkYourMailToConfirm')}`,
      });
    }
  }, [resendSignUpVerificationError, isResendSignUpVerificationSuccess, dispatch]);

  useEffect(() => {
    if (email && code) {
      verifySignUp({ email, code });
    }
  }, [email, code, verifySignUp]);

  useEffect(() => {
    if (verifySignUpError && 'data' in verifySignUpError) {
      const errorData = verifySignUpError.data as { code: string; message: string };

      setFeedbackAlert({ severity: 'error', message: errorData.message });
    }

    if (isVerifySignUpSuccess) {
      dispatch(
        openSnackbar({
          severity: 'success',
          message: signUpVerificationPageStrings('yourAccountSuccessfullyVerified'),
        }),
      );
      navigate(ROUTE_PATH_LOG_IN);
    }
  }, [verifySignUp, verifySignUpError, isVerifySignUpSuccess, dispatch, navigate]);

  if (!verifySignUpError && !isVerifySignUpSuccess) {
    return (
      <LoadingArea
        hasAgriplaceLoadingAnimation
        size={72}
      />
    );
  }

  const resendVerificationCode = () => {
    if (email) {
      resendSignUpVerification({ email });
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <AppLogo
        large
        sx={{ my: 6, mx: 'auto' }}
      />
      <Paper sx={{ px: 6, py: 3, width: '100%' }}>
        <Stack spacing={2}>
          {feedbackAlert && (
            <Alert severity={feedbackAlert.severity}>{feedbackAlert.message}</Alert>
          )}
          <Button
            onClick={resendVerificationCode}
            variant="contained"
          >
            {signUpVerificationPageStrings('resendVerificationCode')}
          </Button>
          <Typography align="center">
            <Link
              href={origin ? `${ROUTE_PATH_LOG_IN}?origin=${origin}` : ROUTE_PATH_LOG_IN}
              underline="hover"
            >
              {commonStrings('backToLogIn')}
            </Link>
          </Typography>
        </Stack>
      </Paper>
    </Container>
  );
};
