import { type UIEvent, type UIEventHandler, useState } from 'react';

/**
 *
 * @returns
 */
export const useIsScrolled = (): [boolean, boolean, UIEventHandler<HTMLDivElement> | undefined] => {
  const threshold = 5;

  const [scroll, setScroll] = useState<{ top: boolean; left: boolean }>({
    top: false,
    left: false,
  });

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;

    const isVerticallyScrolled = target.scrollTop >= threshold;
    const isHorizontallyScrolled = target.scrollLeft >= threshold;

    // Only update state (and force re-renders) if something changed
    const changedVertically = scroll.top !== isVerticallyScrolled;
    const changedHorizontally = scroll.left !== isHorizontallyScrolled;

    if (changedVertically || changedHorizontally) {
      setScroll({
        top: isVerticallyScrolled,
        left: isHorizontallyScrolled,
      });
    }
  };

  return [scroll.top, scroll.left, handleScroll];
};
