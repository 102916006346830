import { ExpandMoreRounded } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  type PaperProps,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { type ReactElement, type ReactNode } from 'react';

interface EvidenceExpandableStackItemAction {
  label: ReactNode;
  onClick: () => void;
  endIcon?: ReactElement;
  getVisibility?: (props: { isLast: boolean }) => boolean;
  startIcon?: ReactElement;
  variant?: 'text' | 'outlined' | 'contained';
}

interface EvidenceExpandableStackItem {
  icon: ReactNode;
  details: ReactNode;
  title: ReactNode;
  bottomActions?: EvidenceExpandableStackItemAction[];
}

interface EvidenceExpandableStackProps extends PaperProps {
  items: EvidenceExpandableStackItem[] | undefined;
  expandedIndex: number;
  onChangeIndex: (nextIndex: number) => void;
}

export const EvidenceExpandableStack = ({
  items,
  expandedIndex,
  onChangeIndex: onChange,
  ...restProps
}: EvidenceExpandableStackProps): React.JSX.Element => (
  <Stack spacing={1}>
    {items?.map((item, i) => {
      const isLast = i === items.length - 1;
      return (
        <Box
          key={`${i}_type_accordion`}
          id={`${i}_type_accordion`}
        >
          <Accordion
            disableGutters
            expanded={expandedIndex === i}
            onChange={() => onChange?.(i)}
            slotProps={{ transition: { unmountOnExit: true } }}
            {...restProps}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreRounded />}
              sx={{ paddingY: 1 }}
            >
              <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                gap={1}
              >
                <Box sx={{ flex: 1 }}>{item.icon}</Box>
                <Typography
                  fontWeight={500}
                  lineHeight={1.667}
                  variant="overline"
                >
                  {item.title}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ pt: 0 }}>
              {item.details}
              {item?.bottomActions?.length ? (
                <Toolbar
                  disableGutters
                  sx={{ justifyContent: 'flex-end', gap: 1, px: 2 }}
                >
                  {item.bottomActions?.map((action, actionIndex) => {
                    const isVisible = action.getVisibility
                      ? action.getVisibility({ isLast })
                      : true;
                    if (!isVisible) {
                      return null;
                    }
                    return (
                      <Button
                        key={`${actionIndex}_expandable`}
                        endIcon={action.endIcon}
                        onClick={action.onClick}
                        size="small"
                        startIcon={action.startIcon}
                        variant={action.variant}
                      >
                        {action.label}
                      </Button>
                    );
                  })}
                </Toolbar>
              ) : null}
            </AccordionDetails>
          </Accordion>
        </Box>
      );
    })}
  </Stack>
);
