import { type ControlPointProductionTechniqueListOutputItem } from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { TagType } from '../TagType';

const RESOURCE = 'control-point-production-techniques';

const controlPointProductionTechniqueEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getControlPointProductionTechniqueList: build.query<
      ControlPointProductionTechniqueListOutputItem[],
      void
    >({
      query: () => `/${RESOURCE}/`,
      providesTags: [TagType.ControlPointProductionTechnique],
    }),
  }),
  overrideExisting: false,
});

export const { useGetControlPointProductionTechniqueListQuery } =
  controlPointProductionTechniqueEndpoints;
