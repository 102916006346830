import {
  type CbAssessmentMetadataListParams,
  type CertificationBodyAssessmentMetadataCreateInput,
  type CertificationBodyAssessmentMetadataCreateOutput,
  type CertificationBodyAssessmentMetadataDetailOutput,
  type CertificationBodyAssessmentMetadataListOutputItem,
  type CertificationBodyAssessmentMetadataUpdateInput,
  type CertificationBodyAssessmentMetadataUpdateOutput,
} from 'src/__generated__/InternalApiTypes';
import { baseApi } from 'src/services/farmApi/baseApi';
import { TagType } from 'src/services/farmApi/TagType';

import {
  type CreateParamsWithTenantId,
  type DetailParamsWithTenantId,
  injectQueryParams,
  type UpdateParamsWithTenantId,
} from '../helpers';

const RESOURCE = 'cb-assessment-metadata';

const cbAssessmentMetadataEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCbAssessmentMetadataList: build.query<
      CertificationBodyAssessmentMetadataListOutputItem[],
      CbAssessmentMetadataListParams
    >({
      query: ({ tid, ...restParams }) => injectQueryParams(`/tid/${tid}/${RESOURCE}/`, restParams),
      providesTags: [TagType.CbAssessmentMetadata],
    }),

    getCbAssessmentMetadata: build.query<
      CertificationBodyAssessmentMetadataDetailOutput,
      DetailParamsWithTenantId
    >({
      query: ({ tid, id }) => `/tid/${tid}/${RESOURCE}/${id}/`,
      providesTags: [TagType.CbAssessmentMetadata],
    }),

    createCbAssessmentMetadata: build.mutation<
      CertificationBodyAssessmentMetadataCreateOutput,
      CreateParamsWithTenantId<CertificationBodyAssessmentMetadataCreateInput>
    >({
      query: ({ tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/create/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TagType.CbAssessmentMetadata, TagType.Assessment],
    }),

    updateCbAssessmentMetadata: build.mutation<
      CertificationBodyAssessmentMetadataUpdateOutput,
      UpdateParamsWithTenantId<CertificationBodyAssessmentMetadataUpdateInput>
    >({
      query: ({ id, tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/update/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [TagType.CbAssessmentMetadata, TagType.Assessment],
    }),
  }),
});

export const {
  useGetCbAssessmentMetadataListQuery,
  useGetCbAssessmentMetadataQuery,
  useCreateCbAssessmentMetadataMutation,
  useUpdateCbAssessmentMetadataMutation,
} = cbAssessmentMetadataEndpoints;
