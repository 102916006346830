import {
  type ModuleListOutputItem,
  type ModulesListParams,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { injectQueryParams } from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'modules';

const moduleEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getModuleList: build.query<ModuleListOutputItem[], ModulesListParams>({
      query: (params) => injectQueryParams(`/${RESOURCE}/`, params),
      providesTags: (_result, _error, params) => [
        ...(params.standard_id?.map((standardId) => ({
          type: TagType.AssessmentControlPointEvidence,
          id: standardId,
        })) || []),
      ],
    }),
  }),
  overrideExisting: false,
});

export const { useGetModuleListQuery } = moduleEndpoints;
