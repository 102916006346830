import { Box, Container, Paper, Tab, Tabs, useMediaQuery } from '@mui/material';
import { cloneElement, useContext, useEffect, useMemo } from 'react';
import { AppLogo } from 'src/components/common';
import { AppFooter } from 'src/components/common/AppFooter';
import { useTabs } from 'src/components/common/TabbedPage/TabbedPage.hooks';
import { ThemeContext } from 'src/components/ThemeProvider';
import { useGetUserOrigin } from 'src/hooks/useGetUserOrigin';
import { commonStrings } from 'src/languages/en-UK';
import { SignUpOriginEnum } from 'src/services/farmApi/endpoints/auth';
import { leafTheme, smkTheme, theme } from 'src/theme';

import { LogInPage } from '../LogInPage';
import { SignUpPage } from '../SignUpPage';

export const AuthPage = (): React.JSX.Element => {
  const origin = useGetUserOrigin();
  const { changeTheme } = useContext(ThemeContext);

  useEffect(() => {
    let selectedTheme;
    switch (origin) {
      case SignUpOriginEnum.SMK:
        selectedTheme = smkTheme;
        break;
      case SignUpOriginEnum.LEAF:
        selectedTheme = leafTheme;
        break;
      default:
        selectedTheme = theme;
        break;
    }
    changeTheme?.(selectedTheme);
  }, [changeTheme, origin]);

  const tabs = useMemo(
    () => [
      {
        slug: 'login',
        title: commonStrings('logIn'),
        content: <LogInPage />,
      },
      {
        slug: 'signup',
        title: commonStrings('createAccount'),
        content: <SignUpPage />,
      },
    ],
    [],
  );

  const [activeTabIndex, handleChangeTab] = useTabs(tabs);
  const { theme: currentTheme } = useContext(ThemeContext);
  const isMobile = useMediaQuery(currentTheme.breakpoints.down('sm'));

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        height: isMobile ? '100%' : undefined,
      }}
    >
      <Paper
        sx={{
          p: { xs: 1, sm: 2 },
          my: 4,
          display: 'flex',
          flexDirection: 'column',
          flexGrow: { xs: 1, sm: 0 },
        }}
      >
        <AppLogo
          large
          showName
          sx={{ pt: 2, pb: 2.5, mx: 'auto' }}
        />
        <Tabs
          onChange={handleChangeTab}
          sx={{ px: { xs: 0, sm: 3 } }}
          TabIndicatorProps={{ sx: { height: 4 } }}
          value={activeTabIndex}
          variant="fullWidth"
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.slug}
              label={tab.title}
            />
          ))}
        </Tabs>
        <Box paddingTop={4}>
          {tabs.map((tab, i) =>
            activeTabIndex === i ? cloneElement(tab.content, { key: tab.slug }) : null,
          )}
        </Box>
        <Box
          display="flex"
          flex={1}
          flexDirection="column"
          flexGrow={1}
          justifyContent="flex-end"
        >
          {origin !== SignUpOriginEnum.AGRIPLACE && <AppFooter />}
        </Box>
      </Paper>
    </Container>
  );
};
