import { Chip, type ChipOwnProps, Skeleton } from '@mui/material';
import { type ReactNode } from 'react';

import { MobileCompatibleTooltip } from '../MobileCompatibleTooltip';

interface HeaderChipProps {
  isLoading: boolean;
  label: string | ReactNode | undefined;
  chipColor?: ChipOwnProps['color'];
  tooltipText?: string;
}

export const HeaderChip = ({
  label,
  tooltipText,
  chipColor = 'default',
  isLoading = false,
}: HeaderChipProps): React.JSX.Element => {
  if (isLoading || !label) {
    return (
      <Skeleton
        height={26}
        sx={{ borderRadius: 16 }}
        variant="rounded"
        width={96}
      />
    );
  }

  return tooltipText ? (
    <MobileCompatibleTooltip title={tooltipText}>
      <Chip
        color={chipColor}
        label={label}
        sx={{
          height: 26,
          '& .MuiChip-label': {
            px: '10px',
            lineHeight: '18px',
            letterSpacing: 0.16,
          },
        }}
        variant="outlined"
      />
    </MobileCompatibleTooltip>
  ) : (
    <Chip
      color={chipColor}
      label={label}
      sx={{
        height: 26,
        '& .MuiChip-label': {
          px: '10px',
          lineHeight: '18px',
          letterSpacing: 0.16,
        },
      }}
      variant="outlined"
    />
  );
};
