import { InfoOutlined } from '@mui/icons-material';
import { Chip, Skeleton } from '@mui/material';
import { controlPointAnsweredByTriggerChipStrings } from 'src/languages/en-UK';
import { appColors } from 'src/theme';

import { MobileCompatibleTooltip } from '../common/MobileCompatibleTooltip';

interface ControlPointAnsweredByTriggerChipProps {
  answerSetByEvidence: boolean;
  isLoading: boolean;
  label: string;
  triggerType: string;
  triggerName: string;
  triggerAnswer: string | null;
}

export const ControlPointAnsweredByTriggerChip = ({
  label,
  triggerType,
  triggerName,
  triggerAnswer,
  answerSetByEvidence = false,
  isLoading = false,
}: ControlPointAnsweredByTriggerChipProps): React.JSX.Element => {
  let tooltipTitle = '';
  if (answerSetByEvidence) {
    tooltipTitle = controlPointAnsweredByTriggerChipStrings('autoFilledAnswerWithEvidence');
  } else if (triggerAnswer) {
    if (triggerType === 'control_point') {
      tooltipTitle = controlPointAnsweredByTriggerChipStrings(
        'autoFilledAnswerWithAnswerToControlPoint',
        {
          triggerAnswer,
          triggerName,
        },
      );
    } else if (triggerType === 'preliminary_question') {
      tooltipTitle = controlPointAnsweredByTriggerChipStrings(
        'autoFilledAnswerWithAnswerToPreliminaryQuestion',
        {
          triggerAnswer,
          triggerName,
        },
      );
    }
  } else {
    tooltipTitle = controlPointAnsweredByTriggerChipStrings('autoFilledAnswer', {
      triggerName,
    });
  }

  return !label || isLoading ? (
    <Skeleton
      height={32}
      sx={{ borderRadius: 16 }}
      variant="rounded"
      width={96}
    />
  ) : (
    <MobileCompatibleTooltip title={tooltipTitle}>
      <Chip
        icon={<InfoOutlined />}
        label={label}
        size="small"
        sx={{
          borderColor: appColors.purple50,
          '& .MuiChip-icon': { color: appColors.purple },
          '& .MuiChip-label': { color: appColors.purple },
        }}
        variant="outlined"
      />
    </MobileCompatibleTooltip>
  );
};
