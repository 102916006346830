import { Alert, Slide, type SlideProps, Snackbar } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { closeSnackbar, selectAlert } from 'src/store/snackbarSlice';

const SlideTransition = (props: SlideProps) => <Slide {...props} />;

export const SnackbarDelegate = (): React.JSX.Element => {
  const dispatch = useAppDispatch();
  const snackbarProps = useAppSelector((state) => state.snackbar);
  const alert = useAppSelector(selectAlert);

  const handleSnackbarClose = () => {
    dispatch(closeSnackbar());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={5000}
      disableWindowBlurListener
      onClose={handleSnackbarClose}
      sx={{ mb: 4 }} // Ensure that the snackbar doesn't block buttons in the lower right corner
      TransitionComponent={SlideTransition}
      {...snackbarProps}
    >
      <Alert
        elevation={8}
        onClose={handleSnackbarClose}
        severity={alert.severity}
        sx={{ width: '100%' }}
      >
        {alert.message}
      </Alert>
    </Snackbar>
  );
};
