import { type FormikValues } from 'formik';
import {
  FormikDialog,
  type FormikDialogFieldProps,
} from 'src/components/formikcomponents/FormikDialog';
import { FormikInvitationRoleField } from 'src/components/formikcomponents/FormikInvitationRoleField';
import { FormikTextField } from 'src/components/formikcomponents/FormikTextField';
import { commonStrings } from 'src/languages/en-UK';
import {
  useCreateOrganizationMemberMutation,
  useGetOrganizationMemberQuery,
  useUpdateOrganizationMemberMutation,
} from 'src/services/farmApi';
import * as Yup from 'yup';

export interface OrganizationUserEditDialogProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  userId: string;
}

export const OrganizationUserEditDialog = ({
  onClose,
  title,
  userId,
  isOpen = false,
}: OrganizationUserEditDialogProps): React.JSX.Element => {
  const getFormikBodyMap = (values: FormikValues) => ({
    ...values,
    roles: [values.roles],
  });

  const getFormikFormMap = (values: FormikValues) => ({
    ...values,
    roles: values.roles[0],
  });

  return (
    <FormikDialog
      createHook={useCreateOrganizationMemberMutation}
      entityId={userId}
      fetchHook={useGetOrganizationMemberQuery}
      fields={[
        {
          id: 'name',
          renderAs: (props) => (
            <FormikTextField
              {...props}
              disabled
              label={commonStrings('name')}
              required
            />
          ),
          validator: Yup.string().required(commonStrings('required')),
        },
        {
          id: 'email',
          renderAs: (props) => (
            <FormikTextField
              {...props}
              disabled
              label={commonStrings('email')}
              required
            />
          ),
          validator: Yup.string().required(commonStrings('required')),
        },
        {
          id: 'roles',
          renderAs: (props: FormikDialogFieldProps) => (
            <FormikInvitationRoleField
              {...props}
              label={commonStrings('role')}
              required
            />
          ),
          validator: Yup.string().required(commonStrings('required')),
        },
      ]}
      getBodyMap={getFormikBodyMap}
      getFormMap={getFormikFormMap}
      isOpen={isOpen}
      onClose={onClose}
      propertyName="email"
      title={title}
      updateHook={useUpdateOrganizationMemberMutation}
    />
  );
};
