import { Box, Skeleton, type StandardTextFieldProps, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { type FormikProps, type FormikValues } from 'formik';
import { DATE_DISPLAY_FORMAT } from 'src/settings';
import { appColors } from 'src/theme';

type FormikDateFieldProps = Partial<StandardTextFieldProps> & {
  formik: FormikProps<FormikValues>;
  id: string;
  inputSize?: 'small' | 'medium';
  isLoading?: boolean;
};

export const FormikDateField = ({
  formik,
  id,
  label,
  placeholder,
  inputSize,
  disabled = false,
  isLoading = false,
  required = false,
  ...restProps
}: FormikDateFieldProps) =>
  isLoading ? (
    <Box
      border={1}
      borderColor={appColors.border}
      borderRadius={1}
      paddingX="14px"
      paddingY="15px"
      sx={restProps?.sx}
      width="100%"
    >
      <Skeleton
        animation="wave"
        variant="text"
        width="33%"
      />
    </Box>
  ) : (
    <DatePicker
      disabled={disabled}
      disablePast
      inputFormat={DATE_DISPLAY_FORMAT}
      label={label}
      onChange={(date) => formik.setFieldValue(id, date)}
      renderInput={(params) => (
        <TextField
          {...params}
          error={formik.touched[id] && Boolean(formik.errors[id])}
          fullWidth
          helperText={formik.touched[id] && (formik.errors[id] as string)}
          id={id}
          inputProps={{
            ...params.inputProps,
            placeholder,
          }}
          onBlur={formik.handleBlur}
          required={required}
          size={inputSize}
        />
      )}
      showToolbar={false}
      value={formik.values[id]}
    />
  );
