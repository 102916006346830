import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Checkbox, Stack, Toolbar, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { AccountTypeEnum } from 'src/__generated__/InternalApiTypes';
import { type AlertData } from 'src/components/common';
import { FormikLanguageField } from 'src/components/formikcomponents/FormikLanguageField';
import { FormikPasswordTextField } from 'src/components/formikcomponents/FormikPasswordTextField';
import { FormikRegisterAccountTypeField } from 'src/components/formikcomponents/FormikRegisterAccountTypeField';
import { FormikTextField } from 'src/components/formikcomponents/FormikTextField';
import { useAppDispatch } from 'src/hooks';
import { useGetUserOrigin } from 'src/hooks/useGetUserOrigin';
import { commonStrings, signUpFormStrings } from 'src/languages/en-UK';
import { useSignUpMutation } from 'src/services/farmApi';
import { SignUpOriginEnum } from 'src/services/farmApi/endpoints/auth';
import {
  PASSWORD_REG_EX,
  ROUTE_PATH_PRIVACY_POLICY,
  ROUTE_PATH_PRIVACY_POLICY_LEAF,
  ROUTE_PATH_PRIVACY_POLICY_SMK,
  ROUTE_PATH_TERMS_OF_SERVICE,
  ROUTE_PATH_TERMS_OF_SERVICE_LEAF,
} from 'src/settings';
import { openSnackbar } from 'src/store/snackbarSlice';
import { canSubmitFormik } from 'src/utils/canSubmitFormik';
import * as Yup from 'yup';

interface SignUpFormProps {
  setAlert: (value: AlertData) => void;
}

export const SignUpForm = ({ setAlert }: SignUpFormProps): React.JSX.Element => {
  const validatePrefilledAccountType = (
    accountTypeString: string | null,
  ): (typeof AccountTypeEnum)[keyof typeof AccountTypeEnum] | '' => {
    if (Object.values(AccountTypeEnum).includes(accountTypeString as AccountTypeEnum)) {
      return accountTypeString as (typeof AccountTypeEnum)[keyof typeof AccountTypeEnum];
    }
    return '';
  };
  // Utils
  const dispatch = useAppDispatch();
  const [isSignUpPending, setIsSignUpPending] = useState(false);
  const [searchParams] = useSearchParams();
  const prefilledEmail = searchParams.get('email') || '';
  const prefilledAccountType = validatePrefilledAccountType(searchParams.get('accounttype'));
  const [signUp, { error: signUpError, isSuccess: isSignUpSuccess }] = useSignUpMutation();
  const origin = useGetUserOrigin();

  useEffect(() => {
    if (signUpError && 'data' in signUpError) {
      const errorData = signUpError.data as { code: string; message: string };

      dispatch(
        openSnackbar({
          severity: 'error',
          message: errorData.message,
        }),
      );
    }

    if (isSignUpSuccess) {
      setAlert({
        severity: 'success',
        message: commonStrings('checkYourMailToConfirm'),
      });
    }
  }, [dispatch, isSignUpSuccess, setAlert, signUpError]);

  // Form
  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: prefilledEmail,
      password: '',
      passwordRepeat: '',
      // TODOHasan: This code will be reactivated after AFA-1217.
      // country: '',
      language: 'en',
      accountType: prefilledAccountType,
      termsAccept: false,
    },
    validationSchema: Yup.object().shape({
      fullName: Yup.string().required(commonStrings('required')),
      email: Yup.string().email(commonStrings('invalidEmail')).required(commonStrings('required')),
      password: Yup.string()
        .required(commonStrings('required'))
        .matches(PASSWORD_REG_EX, commonStrings('newPasswordRule')),
      passwordRepeat: Yup.string()
        .required(commonStrings('required'))
        .oneOf([Yup.ref('password'), null], commonStrings('passwordsNotMatch')),
      accountType: Yup.string().required(commonStrings('required')),
      termsAccept: Yup.boolean()
        .required(signUpFormStrings('termsAndConditions'))
        .oneOf([true], signUpFormStrings('termsAndConditions')),
    }),
    onSubmit: async (values) => {
      const { accountType, email, fullName, language, password } = values;
      setIsSignUpPending(true);
      await signUp({
        email,
        password,
        fullName,
        language,
        accountType,
        origin,
      });
      setIsSignUpPending(false);
    },
  });

  const renderTermsAndConditionsSection = () => {
    switch (origin) {
      case SignUpOriginEnum.SMK:
        return (
          <>
            {`${signUpFormStrings('understandAndAcceptPolicy')} SMK `}
            <Link
              target="_blank"
              to={ROUTE_PATH_PRIVACY_POLICY_SMK}
            >
              {signUpFormStrings('privacyPolicy')}
            </Link>
            .
          </>
        );

      case SignUpOriginEnum.LEAF:
        return (
          <>
            {`${signUpFormStrings('understandAndAcceptPolicy')} LEAF `}
            <Link
              target="_blank"
              to={ROUTE_PATH_PRIVACY_POLICY_LEAF}
            >
              {signUpFormStrings('privacyPolicy')}
            </Link>
            {` ${signUpFormStrings('and')} `}
            <Link
              target="_blank"
              to={ROUTE_PATH_TERMS_OF_SERVICE_LEAF}
            >
              {signUpFormStrings('termsOfService')}
            </Link>
            {` ${signUpFormStrings('and')} ${signUpFormStrings('understandAndAcceptPolicyAgriplace')}`}
          </>
        );

      default:
        return (
          <>
            {`${signUpFormStrings('understandAndAcceptPolicy')} Agriplace `}
            <Link
              target="_blank"
              to={ROUTE_PATH_PRIVACY_POLICY}
            >
              {signUpFormStrings('privacyPolicy')}
            </Link>
            {` ${signUpFormStrings('and')} `}
            <Link
              target="_blank"
              to={ROUTE_PATH_TERMS_OF_SERVICE}
            >
              {signUpFormStrings('termsOfService')}
            </Link>
            {` ${signUpFormStrings('and')} ${signUpFormStrings('understandAndAcceptPolicyAgriplace')}`}
          </>
        );
    }
  };

  return (
    <form
      autoComplete="on"
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <Stack spacing={2}>
        <FormikTextField
          formik={formik}
          id="fullName"
          label={commonStrings('fullName')}
          required
        />
        <FormikTextField
          disabled={!!prefilledEmail}
          formik={formik}
          id="email"
          label={commonStrings('email')}
          required
          type="email"
        />
        <FormikPasswordTextField
          formik={formik}
          id="password"
          label={commonStrings('passwordAsComponent')}
          placeholder={commonStrings('password')}
          required
        />
        <FormikPasswordTextField
          formik={formik}
          id="passwordRepeat"
          label={signUpFormStrings('repeatPassword')}
          required
        />
        {!prefilledAccountType && (
          <FormikRegisterAccountTypeField
            formik={formik}
            id="accountType"
            label={signUpFormStrings('planToUsePlatform')}
            required
          />
        )}
        {/* TODOHasan: This code will be reactivated after AFA-1217. 
        <FormikCountryField
          formik={formik}
          id="country"
          label={signUpFormStrings('country')}
          placeholder={signUpFormStrings('country')}
        /> */}
        <FormikLanguageField
          formik={formik}
          id="language"
          label={commonStrings('languageAsComponent')}
          placeholder={commonStrings('language')}
        />
        <Box
          alignItems="start"
          display="flex"
          flexDirection="row"
        >
          <Checkbox
            checked={formik.values.termsAccept}
            name="termsAccept"
            onChange={formik.handleChange}
            sx={{ mt: -1, mr: 0.5 }}
          />
          <Typography>{renderTermsAndConditionsSection()}</Typography>
        </Box>
        <Toolbar disableGutters>
          <LoadingButton
            disabled={!canSubmitFormik(formik, true)}
            fullWidth
            loading={isSignUpPending}
            type="submit"
            variant="contained"
          >
            {commonStrings('createAccount')}
          </LoadingButton>
        </Toolbar>
      </Stack>
    </form>
  );
};
