import { Navigate, Route, Routes } from 'react-router-dom';
import { ROUTE_PATH_DASHBOARD, ROUTES } from 'src/settings';

export const AppRoutes = () => (
  <Routes>
    {ROUTES.map((route) => (
      <Route
        key={route.path}
        element={route.element}
        path={route.path}
      />
    ))}
    <Route
      element={
        <Navigate
          replace
          to={ROUTE_PATH_DASHBOARD}
        />
      }
      path="/"
    />
  </Routes>
);
