import { DeleteRounded } from '@mui/icons-material';
import { Link as MuiLink, Paper, Toolbar, Typography } from '@mui/material';
import {
  GridActionsCellItem,
  type GridEnrichedColDef,
  type GridRenderCellParams,
  type GridRowParams,
  type GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { type ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  type AssessmentStandardRequestListOutputItem,
  OrganizationTypesEnum,
  RequestStatusEnum,
  RolesEnum,
} from 'src/__generated__/InternalApiTypes';
import {
  CustomNoRowsOverlay,
  MobileCompatibleDataGrid,
} from 'src/components/common/MobileCompatibleDataGrid';
import {
  ApprovedOutgoingRequestDialog,
  type ApprovedOutgoingRequestDialogProps,
} from 'src/components/dialogs/ApprovedOutgoingRequestDialog';
import {
  GenericDeleteDialog,
  type GenericDeleteDialogProps,
} from 'src/components/dialogs/GenericDeleteDialog';
import {
  PendingIncomingRequestDialog,
  type PendingIncomingRequestDialogProps,
} from 'src/components/dialogs/PendingIncomingRequestDialog';
import {
  useCurrentOrganization,
  useCurrentUserRoles,
  useDialogState,
  useTenantId,
  useUserAuth,
} from 'src/hooks';
import { commonStrings, dashboardRequestPageStrings } from 'src/languages/en-UK';
import {
  useDeleteAssessmentStandardRequestMutation,
  useGetAssessmentStandardRequestListQuery,
} from 'src/services/farmApi';
import { DATE_TIME_DISPLAY_FORMAT_FOR_TABLE } from 'src/settings';
import { appColors } from 'src/theme';

import { pageWrapperStyle } from './DashboardRequestPage.styles';

type ValueGetterParams = GridValueGetterParams<string, AssessmentStandardRequestListOutputItem>;

export const DashboardRequestPage = (): React.JSX.Element => {
  const tid = useTenantId();
  const currentUser = useUserAuth();
  const { data: organization, isLoading: isLoadingOrganization } = useCurrentOrganization();
  const currentUserRoles = useCurrentUserRoles();
  const isAuditor = currentUserRoles.includes(RolesEnum.Auditor);
  const isFarmManager = currentUserRoles.includes(RolesEnum.FarmManager);
  const isFarmEmployee = currentUserRoles.includes(RolesEnum.FarmEmployee);
  const [searchParams] = useSearchParams();
  const statusFilter = searchParams.get('status');
  const { data: requestList, isLoading } = useGetAssessmentStandardRequestListQuery(
    {
      tid,
      ...(organization?.type === OrganizationTypesEnum.CertificationBody && {
        requester_user_profile_id: currentUser?.userId,
      }),
    },
    { skip: !tid || !organization },
  );

  let filteredRequestList = requestList;
  if (statusFilter && requestList) {
    filteredRequestList = requestList.filter(
      (req) => req.status && statusFilter.includes(req.status),
    );
  }

  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog, deleteDialogProps] =
    useDialogState<Omit<GenericDeleteDialogProps, 'deleteMutation'>>();

  const [
    isRequestApprovedDialogOpen,
    openRequestApprovedDialog,
    closeRequestApprovedDialog,
    requestApprovedDialogProps,
  ] = useDialogState<ApprovedOutgoingRequestDialogProps>();

  const [
    isRequestPendingDialogOpen,
    openRequestPendingDialog,
    closeRequestPendingDialog,
    requestPendingDialogProps,
  ] = useDialogState<PendingIncomingRequestDialogProps>();

  const columns: GridEnrichedColDef[] = [
    {
      field: 'name',
      headerName: dashboardRequestPageStrings('request'),
      flex: 4,
      renderCell: (
        params: GridRenderCellParams<string, AssessmentStandardRequestListOutputItem, ReactNode>,
      ) => {
        const showDialog =
          (params.row.status === RequestStatusEnum.Pending && !isAuditor) ||
          (params.row.status === RequestStatusEnum.Approved && isAuditor);
        return showDialog ? (
          <MuiLink
            onClick={() => {
              if (isAuditor) {
                openRequestApprovedDialog({
                  requestDetail: requestList?.find((r) => r.uuid === params.row.uuid),
                });
              } else {
                openRequestPendingDialog({ uuid: params.row.uuid });
              }
            }}
            sx={{
              color: appColors.blue,
              cursor: 'pointer',
            }}
            variant="body1"
          >
            {params.value}
          </MuiLink>
        ) : (
          <Typography
            color="text.disabled"
            sx={{
              textDecoration: 'underline',
            }}
            variant="body1"
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: 'organization_name',
      headerName: dashboardRequestPageStrings('sentTo'),
      flex: 2,
    },
    {
      field: 'client_identifier_value',
      headerName: commonStrings('clientId'),
      flex: 2,
    },
    {
      field: 'created_at',
      headerName: dashboardRequestPageStrings('createdAt'),
      type: 'date',
      flex: 1.5,
      valueGetter: (params: ValueGetterParams) =>
        params?.value ? format(new Date(params.value), DATE_TIME_DISPLAY_FORMAT_FOR_TABLE) : '',
    },
    {
      field: 'status_label',
      headerName: commonStrings('status'),
      flex: 1.5,
      valueGetter: (params: ValueGetterParams) =>
        params?.value || commonStrings('notApplicableShort'),
    },
    {
      field: 'status_modified_at',
      headerName: dashboardRequestPageStrings('statusChangedAt'),
      type: 'date',
      flex: 1.5,
      valueGetter: (params: ValueGetterParams) =>
        params?.value ? format(new Date(params.value), DATE_TIME_DISPLAY_FORMAT_FOR_TABLE) : '',
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams<AssessmentStandardRequestListOutputItem>) => [
        <GridActionsCellItem
          key={`${params.row.uuid}_delete`}
          icon={<DeleteRounded />}
          label={commonStrings('delete')}
          onClick={() =>
            openDeleteDialog({
              entities: [
                {
                  header: dashboardRequestPageStrings('request'),
                  name: params.row.name,
                  uuid: params.row.uuid || '',
                },
              ],
            })
          }
        />,
      ],
    },
  ];

  return (
    <>
      <Toolbar />
      <Paper sx={pageWrapperStyle}>
        <Typography
          marginBottom={4}
          marginTop={3}
          variant="h1"
        >
          {commonStrings('requests')}
        </Typography>
        <MobileCompatibleDataGrid
          columns={columns}
          loading={isLoading || isLoadingOrganization}
          NoRowsOverlay={() =>
            CustomNoRowsOverlay(
              isFarmManager || isFarmEmployee
                ? dashboardRequestPageStrings('requestTableNoRowsMsgForFarmManagers')
                : dashboardRequestPageStrings('requestTableNoRowsMsg'),
            )
          }
          rows={filteredRequestList || []}
        />
      </Paper>
      <GenericDeleteDialog
        deleteMutation={useDeleteAssessmentStandardRequestMutation}
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        {...deleteDialogProps}
      />
      <ApprovedOutgoingRequestDialog
        isOpen={isRequestApprovedDialogOpen}
        onClose={closeRequestApprovedDialog}
        {...requestApprovedDialogProps}
      />
      <PendingIncomingRequestDialog
        isOpen={isRequestPendingDialogOpen}
        onClose={closeRequestPendingDialog}
        requestList={requestList?.find((r) => r.uuid === requestPendingDialogProps?.uuid)}
      />
    </>
  );
};
