interface PartiallyCompletedIconProps {
  color: string;
  size?: number;
}

export const PartiallyCompletedIcon = ({ color, size = 20 }: PartiallyCompletedIconProps) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 20 20"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 1.667C5.4 1.667 1.667 5.4 1.667 10S5.4 18.333 10 18.333 18.333 14.6 18.333 10 14.6 1.667 10 1.667m0 15c-3.683 0-6.667-2.983-6.667-6.667S6.316 3.333 10 3.333 16.667 6.316 16.667 10 13.684 16.667 10 16.667"
      fill={color}
    />
    <path
      d="M10 14.167V5.833a4.167 4.167 0 0 0 0 8.333"
      fill={color}
    />
  </svg>
);
