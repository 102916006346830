import { CloseRounded } from '@mui/icons-material';
import { Dialog, IconButton } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AssessmentStatusEnum,
  type ConfirmationStatusEnum,
  RolesEnum,
} from 'src/__generated__/InternalApiTypes';
import { AssessmentFinishDialogForAuditors } from 'src/components/dialogs/AssessmentFinishDialogForAuditors';
import { AssessmentFinishDialogForCBManagers } from 'src/components/dialogs/AssessmentFinishDialogForCBManagers';
import { AssessmentFinishDialogForFarmManagers } from 'src/components/dialogs/AssessmentFinishDialogForFarmManagers';
import { AssessmentFinishDialogForReviewers } from 'src/components/dialogs/AssessmentFinishDialogForReviewers';
import { useCurrentUserRoles, useMutationFeedback, useTenantId, useUserAuth } from 'src/hooks';
import { stepperFinishDialogStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import {
  useGetAssessmentQuery,
  useUpdateAssessmentConfirmedMutation,
  useUpdateAssessmentToBeAuditedMutation,
  useUpdateAssessmentToBeConfirmedMutation,
  useUpdateAssessmentToBeReviewedMutation,
} from 'src/services/farmApi';
import { ROUTE_PATH_DASHBOARD } from 'src/settings';

export interface StepperFinishDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const StepperFinishDialog = ({
  isOpen,
  onClose,
}: StepperFinishDialogProps): React.JSX.Element => {
  const navigate = useNavigate();
  const tid = useTenantId();
  const currentUser = useUserAuth();
  const [assessmentId] = useContext(AssessmentPageContext);
  const currentUserRoles = useCurrentUserRoles();
  const isFarmManager = currentUserRoles.includes(RolesEnum.FarmManager);
  const isFarmEmployee = currentUserRoles.includes(RolesEnum.FarmEmployee);
  const isAuditor = currentUserRoles.includes(RolesEnum.Auditor);
  const { data: assessmentDetail } = useGetAssessmentQuery(
    { tid, id: assessmentId },
    {
      skip: !assessmentId || !tid,
    },
  );
  const isAssessmentAuthor = assessmentDetail?.author_id === currentUser?.userId;
  const isReviewer =
    isAuditor &&
    !isAssessmentAuthor &&
    assessmentDetail.status === AssessmentStatusEnum.ToBeReviewed;
  const isCBManager =
    currentUserRoles.includes(RolesEnum.CertificationBodyManager) &&
    !isAssessmentAuthor &&
    assessmentDetail.status === AssessmentStatusEnum.ToBeConfirmed;
  const [updateAssessmentToBeAudited, updateAssessmentToBeAuditedResult] =
    useUpdateAssessmentToBeAuditedMutation();
  const [updateAssessmentToBeReviewed, updateAssessmentToBeReviewedResult] =
    useUpdateAssessmentToBeReviewedMutation();
  const [updateAssessmentToBeConfirmed, updateAssessmentToBeConfirmedResult] =
    useUpdateAssessmentToBeConfirmedMutation();
  const [updateAssessmentConfirmed, updateAssessmentConfirmedResult] =
    useUpdateAssessmentConfirmedMutation();

  const onFinish = (confirmationStatus?: ConfirmationStatusEnum) => {
    if (isFarmManager || isFarmEmployee) {
      updateAssessmentToBeAudited({ id: assessmentId, tid });
    } else if (isCBManager) {
      updateAssessmentConfirmed({
        tid,
        id: assessmentId,
        body: { confirmation_status: confirmationStatus },
      });
    } else if (isAuditor && !isReviewer) {
      updateAssessmentToBeReviewed({ id: assessmentId, tid });
    } else if (isReviewer) {
      updateAssessmentToBeConfirmed({ id: assessmentId, tid });
    }
  };

  useMutationFeedback({
    result: updateAssessmentToBeAuditedResult,
    onSuccess: () => navigate(ROUTE_PATH_DASHBOARD),
    successMessage: stepperFinishDialogStrings('madeReadyForAuditSuccess'),
  });

  useMutationFeedback({
    result: updateAssessmentToBeReviewedResult,
    onSuccess: () => navigate(ROUTE_PATH_DASHBOARD),
    successMessage: stepperFinishDialogStrings('madeReadyForReviewSuccess'),
  });

  useMutationFeedback({
    result: updateAssessmentToBeConfirmedResult,
    onSuccess: () => navigate(ROUTE_PATH_DASHBOARD),
    successMessage: stepperFinishDialogStrings('madeReadyForConfirmSuccess'),
  });

  useMutationFeedback({
    result: updateAssessmentConfirmedResult,
    onSuccess: () => navigate(ROUTE_PATH_DASHBOARD),
    successMessage: stepperFinishDialogStrings('madeReadyForConfirmedSuccess'),
  });

  let renderDialog = null;

  if (isFarmManager || isFarmEmployee) {
    renderDialog = (
      <AssessmentFinishDialogForFarmManagers
        onClose={() => {
          onClose();
          navigate(ROUTE_PATH_DASHBOARD);
        }}
        onFinish={onFinish}
      />
    );
  } else if (isCBManager) {
    renderDialog = (
      <AssessmentFinishDialogForCBManagers
        onClose={onClose}
        onFinish={onFinish}
      />
    );
  } else if (isAuditor && !isReviewer) {
    renderDialog = (
      <AssessmentFinishDialogForAuditors
        onClose={onClose}
        onFinish={onFinish}
      />
    );
  } else if (isReviewer) {
    renderDialog = (
      <AssessmentFinishDialogForReviewers
        onClose={onClose}
        onFinish={onFinish}
      />
    );
  }

  return (
    <Dialog
      fullWidth
      onClose={onClose}
      open={isOpen}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          padding: 1.5,
        }}
      >
        <CloseRounded />
      </IconButton>
      {renderDialog}
    </Dialog>
  );
};
