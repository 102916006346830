import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  type CamundaProcessDefinitionListOutputItem,
  RolesEnum,
} from 'src/__generated__/InternalApiTypes';
import { TenantContext } from 'src/components/TenantProvider';
import { useTenantId, useUserAuth } from 'src/hooks';
import {
  useCompleteCamundaTaskMutation,
  useGetAssessmentQuery,
  useGetCamundaProcessDefinitionListQuery,
  useGetCamundaProcessInstanceByAssessmentIdQuery,
  useGetCamundaTaskDefinitionListQuery,
  useGetCamundaTaskListQuery,
} from 'src/services/farmApi';

export const useCamundaState = (assessmentId?: string | null) => {
  const tid = useTenantId();
  const currentUser = useUserAuth();
  const { activeMembership } = useContext(TenantContext);
  const navigate = useNavigate();

  const { data: assessmentDetail } = useGetAssessmentQuery(
    { tid, id: assessmentId },
    {
      skip: !assessmentId || !tid,
    },
  );

  const { data: processDefinitionList } = useGetCamundaProcessDefinitionListQuery(tid, {
    skip: !tid,
  });

  const assessmentProcessDefinitions = processDefinitionList?.filter(
    (processDefinition) => processDefinition.workflow_id === assessmentDetail?.workflow_id,
  );

  let processDefinitionKey: string | null = null;
  let processDefinitionName: string | null = null;
  let processDefinitionId: string | null = null;

  if (assessmentProcessDefinitions?.length === 1) {
    processDefinitionKey = assessmentProcessDefinitions?.[0]?.process_definition_key || null;
    processDefinitionName = assessmentProcessDefinitions?.[0]?.process_definition_name;
    processDefinitionId = assessmentProcessDefinitions?.[0]?.process_definition_id;
  }

  if (
    assessmentProcessDefinitions &&
    assessmentProcessDefinitions.length > 1 &&
    [RolesEnum.Auditor, RolesEnum.AuditorReviewer, RolesEnum.CertificationBodyManager].some(
      (role) => activeMembership?.roles.includes(role),
    )
  ) {
    let processDefinition: CamundaProcessDefinitionListOutputItem | undefined;
    const isAssessmentAuthor = assessmentDetail?.author_id === currentUser?.userId;
    if (isAssessmentAuthor) {
      processDefinition = assessmentProcessDefinitions?.find((assessmentProcessDefinition) =>
        assessmentProcessDefinition?.roles?.includes(RolesEnum.Auditor),
      );
    } else if (activeMembership?.roles.includes(RolesEnum.CertificationBodyManager)) {
      processDefinition = assessmentProcessDefinitions?.find((assessmentProcessDefinition) =>
        assessmentProcessDefinition?.roles?.includes(RolesEnum.CertificationBodyManager),
      );
    } else {
      processDefinition = assessmentProcessDefinitions?.find((assessmentProcessDefinition) =>
        assessmentProcessDefinition?.roles?.includes(RolesEnum.AuditorReviewer),
      );
    }
    processDefinitionKey = processDefinition?.process_definition_key || null;
    processDefinitionName = processDefinition?.process_definition_name || null;
    processDefinitionId = processDefinition?.process_definition_id || null;
  }

  const {
    data: taskDefinitionList,
    isLoading: isTaskDefinitionListLoading,
    isFetching: isTaskDefinitionListFetching,
  } = useGetCamundaTaskDefinitionListQuery(processDefinitionId, { skip: !processDefinitionId });

  const {
    data: processInstance,
    isLoading: isProcessInstanceLoading,
    isFetching: isProcessInstanceFetching,
  } = useGetCamundaProcessInstanceByAssessmentIdQuery(
    {
      tid,
      uuid: assessmentId,
      process_definition_id: processDefinitionId,
    },
    { skip: !tid || !assessmentId || !processDefinitionId },
  );

  const [completeTask, completeTaskResult] = useCompleteCamundaTaskMutation();
  const completeCurrentTask = (taskId: string, newTaskSlug: string) => {
    if (tid && assessmentId) {
      completeTask({
        tid,
        id: taskId,
        body: {
          new_task_slug: newTaskSlug,
          assessment_id: assessmentId,
        },
      });
    }
  };

  const {
    data: taskList,
    isLoading: isTaskListLoading,
    isFetching: isTaskListFetching,
  } = useGetCamundaTaskListQuery(
    { tid, process_instance_id: processInstance?.uuid },
    { skip: !processInstance?.uuid || !tid },
  );
  const currentTask = completeTaskResult.data || taskList?.[0];

  const currentTaskIndex = taskDefinitionList?.findIndex(
    (taskDefinition) => taskDefinition?.slug === currentTask?.slug,
  );
  let nextTaskName = '';
  let nextTaskSlug = '';
  let previousTaskSlug = '';
  if (currentTaskIndex !== -1 && taskDefinitionList) {
    nextTaskName = taskDefinitionList[currentTaskIndex + 1]?.name;
    nextTaskSlug = taskDefinitionList[currentTaskIndex + 1]?.slug;
    previousTaskSlug = taskDefinitionList[currentTaskIndex - 1]?.slug;
  }

  const moveToNextTask = () => {
    completeCurrentTask(currentTask.uuid, nextTaskSlug);
  };

  const moveToPreviousTask = () => {
    if (currentTaskIndex === 0) {
      navigate('/');
      return;
    }
    completeCurrentTask(currentTask.uuid, previousTaskSlug);
  };

  const isFinalTask = currentTaskIndex === (taskDefinitionList?.length || 0) - 1;
  const isFirstTask = currentTaskIndex === 0;

  return {
    isCamundaStateLoading:
      isTaskDefinitionListLoading || isProcessInstanceLoading || isTaskListLoading,
    isCamundaStateFetching:
      isTaskDefinitionListFetching || isProcessInstanceFetching || isTaskListFetching,
    isNewTaskLoading: completeTaskResult.isLoading,
    currentTaskSlug: currentTask?.slug,
    currentTaskId: currentTask?.uuid,
    currentTaskName: currentTask?.name,
    processInstanceId: processInstance?.uuid,
    currentTaskIndex,
    nextTaskName,
    nextTaskSlug,
    previousTaskSlug,
    processDefinitionKey,
    processDefinitionName,
    taskDefinitionList,
    completeCurrentTask,
    moveToNextTask,
    moveToPreviousTask,
    isFinalTask,
    isFirstTask,
  };
};
