import {
  ArchiveRounded,
  DeleteRounded,
  DownloadRounded,
  EditRounded,
  ListAltRounded,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Link as MuiLink, Typography } from '@mui/material';
import {
  GridActionsCellItem,
  type GridEnrichedColDef,
  type GridRenderCellParams,
  type GridRowModel,
  type GridRowParams,
} from '@mui/x-data-grid-pro';
import { addYears } from 'date-fns';
import { type ReactNode, useState } from 'react';
import {
  imageFileExtensions,
  supportedEditingFileExtensions,
} from 'src/components/common/DocumentPreview';
import {
  CustomNoRowsOverlay,
  MobileCompatibleDataGrid,
} from 'src/components/common/MobileCompatibleDataGrid';
import {
  CreateEvidenceDialog,
  type CreateEvidenceDialogProps,
} from 'src/components/dialogs/CreateEvidenceDialog';
import {
  EvidenceDeleteDialog,
  type EvidenceDeleteDialogProps,
} from 'src/components/dialogs/EvidenceDeleteDialog';
import {
  EvidenceEditDialog,
  type EvidenceEditDialogProps,
} from 'src/components/dialogs/EvidenceEditDialog';
import {
  EvidencePreviewDialog,
  type EvidencePreviewDialogProps,
} from 'src/components/dialogs/EvidencePreviewDialog';
import {
  useAppDispatch,
  useDialogState,
  useMutationFeedback,
  useTenantId,
  useUserAuth,
} from 'src/hooks';
import { commonStrings, evidenceExpiredDocumentsListStrings } from 'src/languages/en-UK';
import {
  extractErrorMessage,
  type TransformedEvidenceListOutputItem,
  useArchiveEvidenceMutation,
  useCreateEvidenceMutation,
  useGetEvidenceListQuery,
  useGetEvidenceTypeListQuery,
} from 'src/services/farmApi';
import { openSnackbar } from 'src/store/snackbarSlice';
import { appColors } from 'src/theme';
import { dataGridValueFormatterForDate } from 'src/utils/dataGridUtils';
import { downloadFileToLocal, getFileExtension, getFileSourceFromUrl } from 'src/utils/fileUtils';

export const EvidenceExpiredDocumentsList = (): React.JSX.Element => {
  const tid = useTenantId();
  const dispatch = useAppDispatch();
  const currentUser = useUserAuth();

  const [isEditDialogOpen, openEditDialog, closeEditDialog, editDialogProps] =
    useDialogState<EvidenceEditDialogProps>();
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog, deleteDialogProps] =
    useDialogState<EvidenceDeleteDialogProps>();
  const [isPreviewDialogOpen, openPreviewDialog, closePreviewDialog, previewDialogProps] =
    useDialogState<EvidencePreviewDialogProps>();
  const [
    isCreateEvidenceDialogOpen,
    openCreateEvidenceDialog,
    closeCreateEvidenceDialog,
    createEvidenceDialogProps,
  ] = useDialogState<CreateEvidenceDialogProps>();

  const [isLoadingDocument, setIsLoadingDocument] = useState(false);
  const [clickedButtonId, setClickedButtonId] = useState<string | null>(null);
  const [archiveEvidence, archiveEvidenceResult] = useArchiveEvidenceMutation();
  const [createEvidence, createEvidenceResult] = useCreateEvidenceMutation();
  const { data: expiredEvidenceList, isLoading: isExpiredEvidenceListLoading } =
    useGetEvidenceListQuery({ tid, is_expired: true, is_archived: false }, { skip: !tid });
  const { data: archivedEvidenceList, isLoading: isArchivedEvidenceListLoading } =
    useGetEvidenceListQuery({ tid, is_archived: true }, { skip: !tid });
  const { data: evidenceTypeList, isLoading: isEvidenceTypeListLoading } =
    useGetEvidenceTypeListQuery();

  useMutationFeedback({
    result: archiveEvidenceResult,
    successMessage: evidenceExpiredDocumentsListStrings('archiveEvidenceSuccess'),
    errorMessage: evidenceExpiredDocumentsListStrings('archiveEvidenceFail'),
  });

  useMutationFeedback({
    result: createEvidenceResult,
    onSuccess: (response) => {
      if (response) {
        setClickedButtonId(null);
        setIsLoadingDocument(false);
        openCreateEvidenceDialog({
          evidenceId: response.uuid,
          isSignatureRequired: response.requires_signature,
          evidenceTypeIds: [response.evidence_type_id],
        });
      }
    },
    onError: () => {
      setClickedButtonId(null);
      setIsLoadingDocument(false);
    },
    successMessage: commonStrings('createDraftDocumentSuccess'),
    errorMessage: commonStrings('createDraftDocumentFail'),
  });

  const handleDownload = (row: TransformedEvidenceListOutputItem): void => {
    downloadFileToLocal(row?.file, row?.name, (msg) =>
      dispatch(openSnackbar({ message: msg, severity: 'error' })),
    );
  };

  const handleCreateEvidence = async (
    selectedEvidence: TransformedEvidenceListOutputItem,
  ): Promise<void> => {
    if (selectedEvidence?.file?.file_object) {
      setClickedButtonId(selectedEvidence.uuid);
      setIsLoadingDocument(true);
      try {
        const [fileSource, newDocumentTitle] = await getFileSourceFromUrl(
          selectedEvidence.file,
          selectedEvidence.name,
        );
        if (tid) {
          createEvidence({
            tid,
            body: {
              file: fileSource,
              name: newDocumentTitle,
              expiry_date: addYears(new Date(), 1),
              is_draft: true,
              evidence_type_id: selectedEvidence.evidence_type_id,
              source_evidence_id: selectedEvidence.uuid,
            },
          });
        }
      } catch (error) {
        setClickedButtonId(null);
        setIsLoadingDocument(false);
        const message = extractErrorMessage(error);
        dispatch(openSnackbar({ message, severity: 'error' }));
      }
    }
  };

  const columnsForExpiredDocuments: GridEnrichedColDef[] = [
    {
      field: 'name',
      headerName: commonStrings('name'),
      flex: 2,
      renderCell: (
        params: GridRenderCellParams<string, TransformedEvidenceListOutputItem, ReactNode>,
      ) => (
        <MuiLink
          onClick={() =>
            openPreviewDialog({
              evidenceId: String(params.id),
            })
          }
          sx={{
            cursor: 'pointer',
            color: appColors.blue,
          }}
          variant="body1"
        >
          {params.value}
        </MuiLink>
      ),
    },
    {
      field: 'evidence_type',
      headerName: commonStrings('documentType'),
      flex: 2,
    },
    {
      field: 'expiry_date',
      headerName: commonStrings('expiryDate'),
      type: 'date',
      flex: 1,
      valueFormatter: dataGridValueFormatterForDate,
    },
    {
      field: 'created_at',
      headerName: commonStrings('uploadDate'),
      type: 'date',
      flex: 1,
      valueFormatter: dataGridValueFormatterForDate,
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'right',
      flex: 1,
      getActions: (params: GridRowParams<TransformedEvidenceListOutputItem>) => {
        const buttons = [];
        const fileSource: string = params.row.file?.file_object;
        if (fileSource) {
          const fileType = getFileExtension(fileSource);
          const isNotEditableFile =
            imageFileExtensions.includes(fileType) ||
            !supportedEditingFileExtensions.includes(fileType);
          const isEvidenceAuthor = params?.row.author_id === currentUser?.userId;
          if (!isNotEditableFile && isEvidenceAuthor) {
            buttons.push(
              <LoadingButton
                key={`${params.row.uuid}_update`}
                color="info"
                disabled={isLoadingDocument}
                loading={isLoadingDocument && clickedButtonId === params.row.uuid}
                onClick={() => handleCreateEvidence(params.row)}
                size="small"
                variant="contained"
              >
                {commonStrings('update')}
              </LoadingButton>,
            );
          }
        }
        buttons.push(
          <GridActionsCellItem
            key={`${params.row.uuid}_edit`}
            icon={<EditRounded />}
            label={commonStrings('edit')}
            onClick={() =>
              openEditDialog({
                evidenceId: String(params.id),
                hideFileUpload: true,
                title: commonStrings('editingDialogTitle', { name: params.row.name }),
              })
            }
            showInMenu
          />,
        );
        buttons.push(
          <GridActionsCellItem
            key={`${params.row.uuid}_download`}
            icon={<DownloadRounded />}
            label={commonStrings('download')}
            onClick={() => handleDownload(params.row)}
            showInMenu
          />,
        );
        buttons.push(
          <GridActionsCellItem
            key={`${params.row.uuid}_archive`}
            icon={<ArchiveRounded />}
            label={evidenceExpiredDocumentsListStrings('archive')}
            onClick={() => {
              if (tid) {
                archiveEvidence({ tid, id: params.row.uuid });
              }
            }}
            showInMenu
          />,
        );
        buttons.push(
          <GridActionsCellItem
            key={`${params.row.uuid}_delete`}
            icon={<DeleteRounded color="error" />}
            label={commonStrings('delete')}
            onClick={() =>
              openDeleteDialog({
                evidenceName: params.row.name,
                evidenceId: String(params.id),
              })
            }
            showInMenu
            sx={{ color: appColors.error }}
          />,
        );

        return buttons;
      },
    },
  ];

  const columnsForArchivedDocuments: GridEnrichedColDef[] = [
    {
      field: 'name',
      headerName: commonStrings('name'),
      flex: 2,
      renderCell: (
        params: GridRenderCellParams<string, TransformedEvidenceListOutputItem, ReactNode>,
      ) => (
        <MuiLink
          onClick={() =>
            openPreviewDialog({
              evidenceId: String(params.id),
            })
          }
          sx={{
            cursor: 'pointer',
            color: appColors.blue,
          }}
          variant="body1"
        >
          {params.value}
        </MuiLink>
      ),
    },
    {
      field: 'evidence_type',
      headerName: commonStrings('documentType'),
      flex: 2,
    },
    {
      field: 'expiry_date',
      headerName: commonStrings('expiryDate'),
      type: 'date',
      flex: 1,
      valueFormatter: dataGridValueFormatterForDate,
    },
    {
      field: 'created_at',
      headerName: commonStrings('uploadDate'),
      type: 'date',
      flex: 1,
      valueFormatter: dataGridValueFormatterForDate,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams<TransformedEvidenceListOutputItem>) => {
        const buttons = [
          <GridActionsCellItem
            key={`${params.row.uuid}_download`}
            icon={<DownloadRounded />}
            label={commonStrings('download')}
            onClick={() => handleDownload(params.row)}
            showInMenu
          />,
        ];

        const fileSource: string = params.row.file?.file_object;
        if (fileSource) {
          const fileType = getFileExtension(fileSource);
          const isNotEditableFile =
            imageFileExtensions.includes(fileType) ||
            !supportedEditingFileExtensions.includes(fileType);
          if (!isNotEditableFile) {
            buttons.push(
              <GridActionsCellItem
                key={`${params.row.uuid}_create`}
                icon={<ListAltRounded />}
                label={commonStrings('createNewVersion')}
                onClick={() => handleCreateEvidence(params.row)}
                showInMenu
              />,
            );
          }
        }

        buttons.push(
          <GridActionsCellItem
            key={`${params.row.uuid}_delete`}
            icon={<DeleteRounded color="error" />}
            label={commonStrings('delete')}
            onClick={() =>
              openDeleteDialog({
                evidenceName: params.row.name,
                evidenceId: String(params.id),
              })
            }
            showInMenu
            sx={{ color: appColors.error }}
          />,
        );

        return buttons;
      },
    },
  ];

  const expiredEvidenceRows = (expiredEvidenceList || []).map(
    (evidence): GridRowModel => ({
      uuid: evidence.uuid,
      name: evidence.name,
      file: evidence.file,
      evidence_type_id: evidence.evidence_type_id,
      evidence_type:
        (evidenceTypeList || []).find((type) => type.uuid === evidence.evidence_type_id)?.name ||
        '',
      expiry_date: evidence.expiry_date,
      created_at: evidence.created_at,
      client_identifiers: evidence.client_identifiers,
      author_id: evidence.author_id,
    }),
  );

  const archivedEvidenceRows = (archivedEvidenceList || []).map(
    (evidence): GridRowModel => ({
      uuid: evidence.uuid,
      name: evidence.name,
      file: evidence.file,
      evidence_type_id: evidence.evidence_type_id,
      evidence_type:
        (evidenceTypeList || []).find((type) => type.uuid === evidence.evidence_type_id)?.name ||
        '',
      expiry_date: evidence.expiry_date,
      created_at: evidence.created_at,
      client_identifiers: evidence.client_identifiers,
    }),
  );

  const isLoading =
    isArchivedEvidenceListLoading || isExpiredEvidenceListLoading || isEvidenceTypeListLoading;
  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      <Typography
        lineHeight={3.43}
        paddingBottom={2}
        paddingTop={1}
        variant="subtitle2"
      >
        {commonStrings('expiredDocuments')}
      </Typography>
      <MobileCompatibleDataGrid
        columns={columnsForExpiredDocuments}
        hideFooter
        loading={isLoading}
        NoRowsOverlay={() => CustomNoRowsOverlay(commonStrings('evidenceTableNoRowsMsg'))}
        rows={expiredEvidenceRows}
        rowsPerPageOptions={[5, 10, 25]}
        sortModel={[
          {
            field: 'created_at',
            sort: 'desc',
          },
        ]}
        sx={{ minHeight: '50vh' }}
      />
      <Typography
        lineHeight={3.43}
        paddingBottom={2}
        paddingTop={3}
        variant="subtitle2"
      >
        {evidenceExpiredDocumentsListStrings('archivedDocuments')}
      </Typography>
      <MobileCompatibleDataGrid
        columns={columnsForArchivedDocuments}
        hideFooter
        loading={isLoading}
        NoRowsOverlay={() => CustomNoRowsOverlay(commonStrings('evidenceTableNoRowsMsg'))}
        rows={archivedEvidenceRows}
        rowsPerPageOptions={[5, 10, 25]}
        sortModel={[
          {
            field: 'created_at',
            sort: 'desc',
          },
        ]}
        sx={{ minHeight: '50vh' }}
      />
      <EvidencePreviewDialog
        isOpen={isPreviewDialogOpen}
        onClose={closePreviewDialog}
        {...previewDialogProps}
      />
      <EvidenceEditDialog
        isOpen={isEditDialogOpen}
        onClose={closeEditDialog}
        submitButtonText={commonStrings('save')}
        {...editDialogProps}
      />
      <EvidenceDeleteDialog
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        {...deleteDialogProps}
      />
      <CreateEvidenceDialog
        isOpen={isCreateEvidenceDialogOpen}
        onClose={closeCreateEvidenceDialog}
        {...createEvidenceDialogProps}
      />
    </Box>
  );
};
