import { type MatrixSelection } from '../types';

/**
 * Checks whether a given MatrixSelection exists in the selection set. Comparison is done using an
 * explicit check of the colId and rowId, NOT using lodash's isEqual() function, because that will
 * return false negatives
 * @param selections - Full selection set
 * @param a - MatrixSelection to check
 * @returns Whether or not the selection exists in the selection set
 */
export const doesSelectionExist = (selections: MatrixSelection[], a: MatrixSelection) =>
  Boolean(selections.find((b) => b.colId === a.colId && b.rowId === a.rowId));
