import {
  type CategoriesListParams,
  type CategoryListOutputItem,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { injectQueryParams } from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'categories';

const categoryEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCategoryList: build.query<CategoryListOutputItem[], CategoriesListParams>({
      query: (restParams) => injectQueryParams(`/${RESOURCE}/`, restParams),
      providesTags: [TagType.Category],
    }),
  }),
  overrideExisting: false,
});

export const { useGetCategoryListQuery } = categoryEndpoints;
