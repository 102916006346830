import { type AlertColor, type SnackbarProps } from '@mui/material';
import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type AlertData } from 'src/components/common/types/AlertData';

interface InitialState extends SnackbarProps {
  severity: AlertColor | null;
}

const initialState: InitialState = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
  autoHideDuration: 5000,
  open: false,
  severity: null,
  message: null,
};

export const snackbarSlice = createSlice({
  initialState,
  name: 'snackbar',
  reducers: {
    openSnackbar: (state, action: PayloadAction<AlertData>) => ({
      ...state,
      open: true,
      ...action.payload,
    }),
    closeSnackbar: (state) => ({
      ...state,
      open: false,
    }),
  },
});

export const { reducer } = snackbarSlice;
export const { openSnackbar, closeSnackbar } = snackbarSlice.actions;

export const selectAlert = createSelector(
  [(state) => state.snackbar],
  (snackbar: InitialState) => ({
    message: snackbar.message,
    severity: snackbar.severity,
  }),
);
