import { type HelpDocumentTypeListOutputItem } from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { TagType } from '../TagType';

const RESOURCE = 'help-document-types';

const helpDocumentTypeEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getHelpDocumentTypeList: build.query<HelpDocumentTypeListOutputItem[], void>({
      query: () => `/${RESOURCE}/`,
      providesTags: [TagType.HelpDocumentType],
    }),
  }),
  overrideExisting: false,
});

export const { useGetHelpDocumentTypeListQuery } = helpDocumentTypeEndpoints;
