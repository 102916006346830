import { CloseRounded } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
} from '@mui/material';
import { addYears, subDays } from 'date-fns';
import { useFormik } from 'formik';
import { FormikDateField } from 'src/components/formikcomponents/FormikDateField';
import { FormikEvidenceTypeField } from 'src/components/formikcomponents/FormikEvidenceTypeField';
import { FormikTextField } from 'src/components/formikcomponents/FormikTextField';
import { useMutationFeedback, useTenantId } from 'src/hooks';
import { commonStrings, createEvidenceSaveDialogStrings } from 'src/languages/en-UK';
import {
  useCreateBulkAssessmentControlPointEvidenceMutation,
  useUpdateEvidenceMutation,
} from 'src/services/farmApi';
import { canSubmitFormik } from 'src/utils/canSubmitFormik';
import * as Yup from 'yup';

export interface CreateEvidenceSaveDialogProps {
  assessmentId: string;
  controlPointIds: string[];
  controlPointName: string;
  documentTitle: string;
  evidenceId: string;
  evidenceTypeIds: string[];
  isOpen: boolean;
  isSignatureRequired: boolean;
  onClose: () => void;
  onCloseCreateEvidenceDialog: () => void;
  onCloseEvidenceFormsDialog: () => void;
}

export const CreateEvidenceSaveDialog = ({
  assessmentId,
  controlPointIds,
  controlPointName,
  documentTitle,
  evidenceId,
  evidenceTypeIds,
  onClose,
  onCloseCreateEvidenceDialog,
  onCloseEvidenceFormsDialog,
  isOpen = false,
  isSignatureRequired = false,
}: CreateEvidenceSaveDialogProps): React.JSX.Element => {
  const tid = useTenantId();
  const [updateEvidence, updateEvidenceResult] = useUpdateEvidenceMutation();
  const [createBulkControlPointEvidence, createBulkControlPointEvidenceResult] =
    useCreateBulkAssessmentControlPointEvidenceMutation();
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      documentTitle,
      expiryDate: addYears(new Date(), 1),
      ...(isSignatureRequired && { confirmationCheckbox: false }),
      ...((controlPointIds || !evidenceTypeIds) && { evidenceTypeId: '' }),
    },
    validationSchema: Yup.object().shape({
      documentTitle: Yup.string().required(commonStrings('required')),
      expiryDate: Yup.date()
        .required(commonStrings('required'))
        .typeError(commonStrings('dateFormatErrorMsg'))
        .min(subDays(new Date(), 1), commonStrings('minDateErrorMessage')),
      ...(isSignatureRequired && {
        confirmationCheckbox: Yup.boolean().required(commonStrings('required')).oneOf([true], ''),
      }),
      ...((controlPointIds || !evidenceTypeIds) && {
        evidenceTypeId: Yup.string().required(commonStrings('pleaseSelectDocumentType')),
      }),
    }),
    onSubmit: (values) => {
      if (evidenceId && tid) {
        updateEvidence({
          tid,
          id: evidenceId,
          body: {
            name: values.documentTitle,
            expiry_date: values.expiryDate,
            is_draft: false,
            ...(isSignatureRequired && { signed_at: new Date() }),
            ...(values.evidenceTypeId && { evidence_type_id: values.evidenceTypeId }),
          },
        });
      }
    },
  });

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  useMutationFeedback({
    result: updateEvidenceResult,
    onSuccess: (response) => {
      if (assessmentId && controlPointIds && tid && response?.uuid) {
        createBulkControlPointEvidence({
          tid,
          body: {
            assessment_id: assessmentId,
            control_point_ids: controlPointIds,
            evidence_id: response.uuid,
          },
        });
      } else {
        onClose();
        onCloseCreateEvidenceDialog?.();
        onCloseEvidenceFormsDialog?.();
        formik.resetForm();
      }
    },
    successMessage: !controlPointName
      ? createEvidenceSaveDialogStrings('createDocumentSuccessWithName', {
          evidence_name: formik.values.documentTitle,
        })
      : '',
    errorMessage: !controlPointName
      ? createEvidenceSaveDialogStrings('createDocumentFailWithName', {
          evidence_name: formik.values.documentTitle,
        })
      : '',
    onError: () => {
      handleClose();
    },
  });

  useMutationFeedback({
    result: createBulkControlPointEvidenceResult,
    onSuccess: () => {
      onClose();
      onCloseCreateEvidenceDialog?.();
      onCloseEvidenceFormsDialog?.();
      formik.resetForm();
    },
    onError: () => {
      handleClose();
    },
    successMessage: controlPointName
      ? createEvidenceSaveDialogStrings('createAndAttachedDocumentSuccessMessageForChecklistPage', {
          evidence_name: formik.values.documentTitle,
          control_point_name: controlPointName,
        })
      : commonStrings('bulkAttachEvidenceSuccess'),
    errorMessage: controlPointName
      ? createEvidenceSaveDialogStrings('createAndAttachedDocumentFailMessageForChecklistPage', {
          evidence_name: formik.values.documentTitle,
          control_point_name: controlPointName,
        })
      : commonStrings('bulkAttachEvidenceFail'),
  });

  const isLoading =
    createBulkControlPointEvidenceResult?.isLoading || updateEvidenceResult?.isLoading;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={isOpen}
    >
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          padding: 1.5,
        }}
      >
        <CloseRounded />
      </IconButton>
      <form
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <DialogTitle>{commonStrings('saveAs')}</DialogTitle>
        <DialogContent>
          <FormikTextField
            formik={formik}
            id="documentTitle"
            label={commonStrings('documentTitle')}
            required
            sx={{ mb: 2, mt: 1 }}
          />
          <FormikDateField
            formik={formik}
            id="expiryDate"
            label={commonStrings('expiryDate')}
            required
          />
          {(controlPointIds || !evidenceTypeIds) && (
            <FormikEvidenceTypeField
              evidenceTypeIds={evidenceTypeIds}
              formik={formik}
              id="evidenceTypeId"
              sx={{ mt: 2 }}
            />
          )}
          {isSignatureRequired && (
            <FormControlLabel
              control={<Checkbox checked={formik.values.confirmationCheckbox} />}
              label={createEvidenceSaveDialogStrings('confirmEvidenceInformationProvided')}
              name="confirmationCheckbox"
              onChange={formik.handleChange}
              required
              sx={{ ml: 0, mt: 3 }}
            />
          )}
        </DialogContent>
        <DialogActions
          sx={{
            mt: 5,
          }}
        >
          <LoadingButton
            disabled={
              !(
                canSubmitFormik(formik, false) &&
                (isSignatureRequired ? formik.values.confirmationCheckbox : true)
              )
            }
            loading={isLoading}
            size="large"
            type="submit"
            variant="contained"
          >
            {commonStrings('save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
