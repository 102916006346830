import { CloseRounded } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import { useMutationFeedback, useTenantId } from 'src/hooks';
import { commonStrings, evidenceDeleteDialogStrings } from 'src/languages/en-UK';
import { useDeleteEvidenceMutation } from 'src/services/farmApi';

export interface EvidenceDeleteDialogProps {
  evidenceName: string;
  evidenceId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const EvidenceDeleteDialog = ({
  evidenceName,
  evidenceId,
  onClose,
  isOpen = false,
}: EvidenceDeleteDialogProps): React.JSX.Element => {
  const tid = useTenantId();
  const [deleteEvidence, deleteEvidenceResult] = useDeleteEvidenceMutation();

  const handleDelete = (id: string): void => {
    if (id && tid) {
      deleteEvidence({ tid, id });
    }
  };
  const handleClose = (): void => {
    onClose();
  };

  useMutationFeedback({
    result: deleteEvidenceResult,
    onSuccess: handleClose,
    errorMessage: commonStrings('backendDeleteFailMessage', { name: evidenceName }),
    successMessage: evidenceDeleteDialogStrings('backendDeleteSuccessMessage', {
      name: evidenceName,
    }),
  });

  return (
    <Dialog
      fullWidth
      onClose={handleClose}
      open={isOpen}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          padding: 1.5,
        }}
      >
        <CloseRounded />
      </IconButton>
      <DialogTitle>{evidenceDeleteDialogStrings('deleteTheDocument')}</DialogTitle>
      <DialogContent>
        <Stack
          marginTop={1}
          spacing={2}
        />
        {evidenceDeleteDialogStrings('deleteEvidenceDialogDescription', {
          evidenceName,
        })}
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        <Button
          color="error"
          onClick={handleClose}
        >
          {commonStrings('cancel')}
        </Button>
        <LoadingButton
          color="error"
          loading={deleteEvidenceResult?.isLoading}
          onClick={() => handleDelete(String(evidenceId))}
          variant="contained"
        >
          {commonStrings('delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
