import { type PayloadAction } from '@reduxjs/toolkit';
import { type ModuleCultivationSelections } from 'src/__generated__/InternalApiTypes';

import { type AssessmentConfigState, type MatrixSelection } from '../types';
import { buildRowsColumns } from '../utils/buildRowsColumns';
import { type SetLayoutPayload } from './setLayout';

interface RebuildFromAssessmentPayload extends SetLayoutPayload {
  selections: ModuleCultivationSelections[];
}

/**
 * Re-builds the assessment config matrix's state from an existing assessment.
 *
 * @param state - The existing state; fully replaced (and thus unused)
 * @param action - The action payload (cultivation and module combinations from the assessment)
 * @returns
 */
export const rebuildFromAssessment = (
  _state: AssessmentConfigState,
  action: PayloadAction<RebuildFromAssessmentPayload>,
): AssessmentConfigState => {
  const { rows, columns } = buildRowsColumns(
    action.payload.cultivationList,
    action.payload.moduleList,
    action.payload.hasPlotName,
  );

  return {
    rows,
    columns,
    initialized: rows.length !== 0 && columns.length !== 0,
    selections: action.payload.selections.map(
      (selection): MatrixSelection => ({
        colId: selection.module_id,
        rowId: selection.organization_cultivation_id,
        malusPoint: selection.malus_points,
      }),
    ),
  };
};
