import {
  AddRounded,
  DeleteRounded,
  DownloadRounded,
  EditRounded,
  ListAltRounded,
} from '@mui/icons-material';
import { Box, Button, Link as MuiLink } from '@mui/material';
import {
  GridActionsCellItem,
  type GridEnrichedColDef,
  type GridRenderCellParams,
  type GridRowModel,
  type GridRowParams,
} from '@mui/x-data-grid-pro';
import { addYears } from 'date-fns';
import { type ReactNode, useState } from 'react';
import { LoadingArea } from 'src/components/common';
import {
  imageFileExtensions,
  supportedEditingFileExtensions,
} from 'src/components/common/DocumentPreview';
import {
  CustomNoRowsOverlay,
  MobileCompatibleDataGrid,
} from 'src/components/common/MobileCompatibleDataGrid';
import {
  CreateEvidenceDialog,
  type CreateEvidenceDialogProps,
} from 'src/components/dialogs/CreateEvidenceDialog';
import {
  EvidenceDeleteDialog,
  type EvidenceDeleteDialogProps,
} from 'src/components/dialogs/EvidenceDeleteDialog';
import {
  EvidenceEditDialog,
  type EvidenceEditDialogProps,
} from 'src/components/dialogs/EvidenceEditDialog';
import {
  EvidencePreviewDialog,
  type EvidencePreviewDialogProps,
} from 'src/components/dialogs/EvidencePreviewDialog';
import { useAppDispatch, useDialogState, useMutationFeedback, useTenantId } from 'src/hooks';
import { commonStrings } from 'src/languages/en-UK';
import {
  extractErrorMessage,
  type TransformedEvidenceListOutputItem,
  useCreateEvidenceMutation,
  useGetEvidenceListQuery,
  useGetEvidenceTypeListQuery,
} from 'src/services/farmApi';
import { evidenceEndpoints } from 'src/services/farmApi/endpoints/evidence';
import { openSnackbar } from 'src/store/snackbarSlice';
import { appColors } from 'src/theme';
import { dataGridValueFormatterForDate } from 'src/utils/dataGridUtils';
import { downloadFileToLocal, getFileExtension, getFileSourceFromUrl } from 'src/utils/fileUtils';

export const EvidenceValidDocumentsList = (): React.JSX.Element => {
  const tid = useTenantId();
  const dispatch = useAppDispatch();
  const [isEditDialogOpen, openEditDialog, closeEditDialog, editDialogProps] =
    useDialogState<EvidenceEditDialogProps>();
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog, deleteDialogProps] =
    useDialogState<EvidenceDeleteDialogProps>();
  const [isPreviewDialogOpen, openPreviewDialog, closePreviewDialog, previewDialogProps] =
    useDialogState<EvidencePreviewDialogProps>();
  const [
    isCreateEvidenceDialogOpen,
    openCreateEvidenceDialog,
    closeCreateEvidenceDialog,
    createEvidenceDialogProps,
  ] = useDialogState<CreateEvidenceDialogProps>();

  const [createEvidence, createEvidenceResult] = useCreateEvidenceMutation();
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  const { data: evidenceList, isLoading: isEvidenceListLoading } = useGetEvidenceListQuery(
    { tid, is_archived: false, is_expired: false },
    { skip: !tid },
  );
  const { data: evidenceTypeList, isLoading: isEvidenceTypeListLoading } =
    useGetEvidenceTypeListQuery();

  const handleDownload = (row: TransformedEvidenceListOutputItem): void => {
    const pollingLatestData = async () => {
      if (tid) {
        const { data: evidenceDetail } = await dispatch(
          evidenceEndpoints.endpoints.getEvidence.initiate(
            { tid, id: row?.uuid },
            { forceRefetch: true },
          ),
        );
        if (
          !evidenceDetail?.is_edited_online ||
          (evidenceDetail?.is_edited_online && evidenceDetail?.is_saved_by_webhook)
        ) {
          setIsDownloadLoading(false);
          if (evidenceDetail?.file) {
            downloadFileToLocal(evidenceDetail.file, row?.name, (msg) =>
              dispatch(openSnackbar({ message: msg, severity: 'error' })),
            );
          }
        }
      } else {
        setIsDownloadLoading(true);
        pollingLatestData();
      }
    };
    pollingLatestData();
  };

  const handleCreateEvidence = async (
    selectedEvidence: TransformedEvidenceListOutputItem,
  ): Promise<void> => {
    if (selectedEvidence?.file?.file_object) {
      try {
        const [fileSource, newDocumentTitle] = await getFileSourceFromUrl(
          selectedEvidence.file,
          selectedEvidence.name,
        );
        if (tid) {
          createEvidence({
            tid,
            body: {
              file: fileSource,
              name: newDocumentTitle,
              expiry_date: addYears(new Date(), 1),
              is_draft: true,
              evidence_type_id: selectedEvidence.evidence_type_id,
              source_evidence_id: selectedEvidence?.uuid,
            },
          });
        }
      } catch (error) {
        const message = extractErrorMessage(error);
        dispatch(openSnackbar({ message, severity: 'error' }));
      }
    }
  };

  useMutationFeedback({
    result: createEvidenceResult,
    onSuccess: (response) => {
      if (response) {
        openCreateEvidenceDialog({
          evidenceId: response.uuid,
          isSignatureRequired: response.requires_signature,
          evidenceTypeIds: [response.evidence_type_id],
        });
      }
    },
    successMessage: commonStrings('createDraftDocumentSuccess'),
    errorMessage: commonStrings('createDraftDocumentFail'),
  });

  const columns: GridEnrichedColDef[] = [
    {
      field: 'name',
      headerName: commonStrings('name'),
      flex: 2,
      renderCell: (
        params: GridRenderCellParams<string, TransformedEvidenceListOutputItem, ReactNode>,
      ) => (
        <MuiLink
          onClick={() =>
            openPreviewDialog({
              evidenceId: String(params.id),
            })
          }
          sx={{
            cursor: 'pointer',
            color: appColors.blue,
          }}
          variant="body1"
        >
          {params.value}
        </MuiLink>
      ),
    },
    {
      field: 'evidence_type',
      headerName: commonStrings('documentType'),
      flex: 2,
    },
    {
      field: 'expiry_date',
      headerName: commonStrings('expiryDate'),
      type: 'date',
      flex: 1,
      valueFormatter: dataGridValueFormatterForDate,
    },
    {
      field: 'created_at',
      headerName: commonStrings('uploadDate'),
      type: 'date',
      flex: 1,
      valueFormatter: dataGridValueFormatterForDate,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams<TransformedEvidenceListOutputItem>) => {
        const buttons = [
          <GridActionsCellItem
            key={`${params.row.uuid}_edit`}
            icon={<EditRounded />}
            label={commonStrings('edit')}
            onClick={() =>
              openEditDialog({
                evidenceId: String(params.id),
                hideFileUpload: true,
                submitButtonText: commonStrings('save'),
                title: commonStrings('editingDialogTitle', { name: params.row.name }),
              })
            }
            showInMenu
          />,
          <GridActionsCellItem
            key={`${params.row.uuid}_download`}
            icon={<DownloadRounded />}
            label={commonStrings('download')}
            onClick={() => handleDownload(params.row)}
            showInMenu
          />,
        ];

        const fileSource: string = params.row.file?.file_object;
        if (fileSource) {
          const fileType = getFileExtension(fileSource);
          const isNotEditableFile =
            imageFileExtensions.includes(fileType) ||
            !supportedEditingFileExtensions.includes(fileType);
          if (!isNotEditableFile) {
            buttons.push(
              <GridActionsCellItem
                key={`${params.row.uuid}_create`}
                icon={<ListAltRounded />}
                label={commonStrings('createNewVersion')}
                onClick={() => handleCreateEvidence(params.row)}
                showInMenu
              />,
            );
          }
        }

        buttons.push(
          <GridActionsCellItem
            key={`${params.row.uuid}_delete`}
            icon={<DeleteRounded color="error" />}
            label={commonStrings('delete')}
            onClick={() =>
              openDeleteDialog({
                evidenceName: params.row.name,
                evidenceId: String(params.id),
              })
            }
            showInMenu
            sx={{ color: appColors.error }}
          />,
        );

        return buttons;
      },
    },
  ];

  const rows = (evidenceList || []).map(
    (evidence): GridRowModel => ({
      uuid: evidence.uuid,
      name: evidence.name,
      file: evidence.file,
      evidence_type_id: evidence.evidence_type_id,
      evidence_type:
        (evidenceTypeList || []).find((type) => type.uuid === evidence.evidence_type_id)?.name ||
        '',
      expiry_date: evidence.expiry_date,
      created_at: evidence.created_at,
      client_identifiers: evidence.client_identifiers,
    }),
  );

  const isLoading = isEvidenceListLoading || isEvidenceTypeListLoading;

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="calc(100vh - 280px)"
    >
      <Button
        onClick={() =>
          openEditDialog({
            title: commonStrings('uploadNewDocument'),
            defaultValues: {
              expiry_date: addYears(new Date(), 1),
            },
          })
        }
        startIcon={<AddRounded />}
        sx={{ mb: 2, alignSelf: 'end' }}
        variant="contained"
      >
        {commonStrings('uploadDocument')}
      </Button>
      <MobileCompatibleDataGrid
        columns={columns}
        loading={isLoading}
        NoRowsOverlay={() => CustomNoRowsOverlay(commonStrings('evidenceTableNoRowsMsg'))}
        rows={rows}
        sortModel={[
          {
            field: 'created_at',
            sort: 'desc',
          },
        ]}
      />
      <EvidencePreviewDialog
        isOpen={isPreviewDialogOpen}
        onClose={closePreviewDialog}
        {...previewDialogProps}
      />
      <EvidenceEditDialog
        isOpen={isEditDialogOpen}
        onClose={closeEditDialog}
        submitButtonText={commonStrings('upload')}
        {...editDialogProps}
      />
      <EvidenceDeleteDialog
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        {...deleteDialogProps}
      />
      <CreateEvidenceDialog
        isOpen={isCreateEvidenceDialogOpen}
        onClose={closeCreateEvidenceDialog}
        {...createEvidenceDialogProps}
      />
      {isDownloadLoading && (
        <LoadingArea
          maxWidth={null}
          sx={{
            position: 'fixed',
            width: 'calc(100vw - 80px)',
            height: 'calc(100vh - 280px)',
            zIndex: 2,
          }}
        />
      )}
    </Box>
  );
};
