import { subDays } from 'date-fns';
import { type FormikValues } from 'formik';
import { omit } from 'lodash';
import React from 'react';
import {
  type EvidenceCreateInput,
  type EvidenceCreateOutput,
} from 'src/__generated__/InternalApiTypes';
import { FormikDateField } from 'src/components/formikcomponents/FormikDateField';
import { FormikDialog } from 'src/components/formikcomponents/FormikDialog';
import { FormikEvidenceTypeField } from 'src/components/formikcomponents/FormikEvidenceTypeField';
import { FormikFileUploadField } from 'src/components/formikcomponents/FormikFileUploadField';
import { FormikTextField } from 'src/components/formikcomponents/FormikTextField';
import { commonStrings, evidenceEditDialogStrings } from 'src/languages/en-UK';
import {
  useCreateEvidenceMutation,
  useGetEvidenceQuery,
  useUpdateEvidenceMutation,
} from 'src/services/farmApi';
import * as Yup from 'yup';

export const validFileNameRegex: RegExp = /^[^\\/:*?"<>|]*$/;

export interface EvidenceEditDialogProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  defaultValues?: FormikValues;
  evidenceId?: string;
  evidenceTypeIds?: string[];
  forcedValues?: Partial<EvidenceCreateInput>;
  hideFileUpload?: boolean;
  onCreateSuccess?: (data: EvidenceCreateOutput) => void;
  submitButtonText?: string;
}

export const EvidenceEditDialog = ({
  defaultValues,
  evidenceId,
  evidenceTypeIds,
  forcedValues,
  hideFileUpload,

  onClose,
  onCreateSuccess,
  submitButtonText,
  title,
  isOpen = false,
}: EvidenceEditDialogProps): React.JSX.Element => {
  const defaultTitle = evidenceEditDialogStrings('uploadEditEvidence');

  const getFormikBodyMap = (values: FormikValues) => {
    const filteredValues = omit(values, 'file');
    return {
      ...filteredValues,
      ...(values.file instanceof File && {
        file: values.file,
      }),
    };
  };

  return (
    <FormikDialog
      createHook={useCreateEvidenceMutation}
      entityId={evidenceId}
      fetchHook={useGetEvidenceQuery}
      fields={[
        {
          id: 'file',
          renderAs: (props) => <FormikFileUploadField {...props} />,
          hidden: hideFileUpload,
          validator: Yup.mixed().required(evidenceEditDialogStrings('pleaseSelectDocument')),
        },
        {
          id: 'name',
          renderAs: (props) => (
            <FormikTextField
              {...props}
              label={commonStrings('documentTitle')}
              required
            />
          ),
          validator: Yup.string()
            .required(evidenceEditDialogStrings('pleaseAddDocumentTitle'))
            .matches(validFileNameRegex, evidenceEditDialogStrings('invalidFileName')),
        },
        {
          id: 'expiry_date',
          defaultValue: defaultValues?.expiry_date,
          renderAs: (props) => (
            <FormikDateField
              {...props}
              label={commonStrings('expiryDate')}
              required
            />
          ),
          validator: Yup.date()
            .required(evidenceEditDialogStrings('pleaseAddExpiryDateForTheUploadedDocument'))
            .typeError(commonStrings('dateFormatErrorMsg'))
            .min(subDays(new Date(), 1), commonStrings('minDateErrorMessage')),
        },
        {
          id: 'evidence_type_id',
          renderAs: (props) => (
            <FormikEvidenceTypeField
              {...props}
              evidenceTypeIds={evidenceTypeIds}
              required
            />
          ),
          validator: Yup.string().required(commonStrings('pleaseSelectDocumentType')),
        },
      ]}
      forcedValues={forcedValues}
      getBodyMap={getFormikBodyMap}
      hideCreateSuccessMsg
      isOpen={isOpen}
      onClose={onClose}
      onCreateSuccess={onCreateSuccess}
      onError={onClose}
      propertyName="name"
      submitButtonText={submitButtonText || commonStrings('upload')}
      title={title || defaultTitle}
      updateHook={useUpdateEvidenceMutation}
    />
  );
};
