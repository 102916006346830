import {
  type AssessmentStandardRequestApproveInput,
  type AssessmentStandardRequestApproveOutput,
  type AssessmentStandardRequestCreateInput,
  type AssessmentStandardRequestCreateOutput,
  type AssessmentStandardRequestListOutputItem,
  type AssessmentStandardRequestRejectOutput,
  type AssessmentStandardRequestsListParams,
} from 'src/__generated__/InternalApiTypes';
import { baseApi } from 'src/services/farmApi/baseApi';
import { TagType } from 'src/services/farmApi/TagType';

import {
  type CreateParamsWithTenantId,
  type DeleteParamsWithTenantId,
  injectQueryParams,
  type UpdateParamsWithTenantId,
} from '../helpers';

const RESOURCE = 'assessment-standard-requests';

const assessmentStandardRequestEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAssessmentStandardRequestList: build.query<
      AssessmentStandardRequestListOutputItem[],
      AssessmentStandardRequestsListParams
    >({
      query: ({ tid, ...restParams }) => injectQueryParams(`/tid/${tid}/${RESOURCE}/`, restParams),
      providesTags: [TagType.AssessmentStandardRequest],
    }),

    deleteAssessmentStandardRequest: build.mutation<void, DeleteParamsWithTenantId>({
      query: ({ id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/delete/`,
        method: 'DELETE',
      }),
      invalidatesTags: [TagType.AssessmentStandardRequest],
    }),

    createAssessmentStandardRequest: build.mutation<
      AssessmentStandardRequestCreateOutput,
      CreateParamsWithTenantId<AssessmentStandardRequestCreateInput>
    >({
      query: ({ tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/create/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TagType.AssessmentStandardRequest],
    }),

    approveAssessmentStandardRequest: build.mutation<
      AssessmentStandardRequestApproveOutput,
      UpdateParamsWithTenantId<AssessmentStandardRequestApproveInput>
    >({
      query: ({ id, tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/approve/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [TagType.AssessmentStandardRequest],
    }),

    rejectAssessmentStandardRequest: build.mutation<
      AssessmentStandardRequestRejectOutput,
      UpdateParamsWithTenantId<AssessmentStandardRequestApproveInput>
    >({
      query: ({ id, tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/reject/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [TagType.AssessmentStandardRequest],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAssessmentStandardRequestListQuery,
  useDeleteAssessmentStandardRequestMutation,
  useCreateAssessmentStandardRequestMutation,
  useApproveAssessmentStandardRequestMutation,
  useRejectAssessmentStandardRequestMutation,
} = assessmentStandardRequestEndpoints;
