import { InfoRounded } from '@mui/icons-material';
import { Box, Paper, Skeleton, TextField, Typography, useMediaQuery } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useAppDispatch } from 'src/hooks';
import { commonStrings } from 'src/languages/en-UK';
import { assessmentConfigActions } from 'src/store/assessmentConfig';
import { type MatrixAxisWithChecked } from 'src/store/assessmentConfig/types';
import { appColors } from 'src/theme';

import { MobileCompatibleTooltip } from '../common/MobileCompatibleTooltip';
import { ThemeContext } from '../ThemeProvider';
import { headerTextStyle, standardBorder } from './AssessmentConfigMatrix.styles';
import { CultivationCheckboxSet } from './CultivationCheckboxSet';

const SKELETON_TEXT_HEIGHT = 16;

interface HeaderLabelProps {
  columnId: string;
  isLoading: boolean;
  row: MatrixAxisWithChecked;
  usesMalusPoint: boolean;
  backgroundColor?: string;
  isSwitchDisabled?: boolean;
  tooltipText?: string;
}

// Cultivations row inside of standards tab
export const HeaderLabel = ({
  backgroundColor,
  columnId,
  row,
  isSwitchDisabled = false,
  isLoading = false,
  tooltipText = '',
  usesMalusPoint = false,
}: HeaderLabelProps) => {
  const dispatch = useAppDispatch();
  const [malusPointValue, setMalusPointValue] = useState<string>();
  const { theme } = useContext(ThemeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (row.malusPoint || row.malusPoint === 0) {
      setMalusPointValue(row.malusPoint.toString());
    }
  }, [row.malusPoint]);

  const onChangeMalusPoint = (evt: { target: { value: string } } | undefined) => {
    let val = evt?.target?.value;
    if (val === '-') {
      val = val.slice(0, -1);
    }
    if (val) {
      const numberVal = parseInt(val, 10);
      let malusPoint: number | undefined;
      if (!Number.isNaN(numberVal)) {
        if (numberVal > 0) {
          malusPoint = -numberVal;
        } else {
          malusPoint = numberVal;
        }
      }
      setMalusPointValue(malusPoint?.toString());
      dispatch(
        assessmentConfigActions.changeMalusPoint({
          malusPoint,
          isChecked: row.isChecked,
          colId: columnId,
          rowId: row.id,
        }),
      );
    } else {
      setMalusPointValue(val);
      dispatch(
        assessmentConfigActions.changeMalusPoint({
          malusPoint: undefined,
          isChecked: row.isChecked,
          colId: columnId,
          rowId: row.id,
        }),
      );
    }
  };

  return (
    <Box display={{ xs: 'block', sm: 'flex' }}>
      <Paper
        sx={{
          display: { xs: 'block', sm: 'flex' },
          backgroundColor: backgroundColor || appColors.customPaper,
          alignItems: 'center',
          minWidth: { xs: 'calc(100vw - 40px)', sm: 560 },
          width: '100%',
          my: { xs: 2, sm: 1 },
          mr: { xs: 1, sm: 5 },
        }}
      >
        {isLoading ? (
          <Skeleton
            height={SKELETON_TEXT_HEIGHT}
            sx={{ mx: 1 }}
            variant="rounded"
            width="50%"
          />
        ) : (
          <Box
            display="flex"
            flex={1}
            justifyContent="space-between"
          >
            <Typography
              color={isSwitchDisabled ? 'text.disabled' : 'textPrimary'}
              lineHeight={{ xs: 1.5, sm: 2.625 }}
              paddingY={{ xs: 1, sm: 2 }}
              sx={headerTextStyle}
              variant="body1"
            >
              {row?.label}
            </Typography>
            <CultivationCheckboxSet
              colId={columnId}
              isChecked={row.isChecked}
              isSwitchDisabled={isSwitchDisabled}
              rowId={row.id}
            />
          </Box>
        )}
        {usesMalusPoint && isMobile && (
          <Box
            alignItems="center"
            borderRight={standardBorder(theme)}
            display="flex"
            flex={1}
            justifyContent="space-between"
            padding={1}
          >
            <Typography
              component="span"
              variant="overline"
            >
              {commonStrings('malusPoints')}
              <Typography
                color="error"
                component="span"
                variant="body1"
              >
                *
              </Typography>
            </Typography>
            <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
              <TextField
                disabled={!row.isChecked}
                id={`row_${row.id}`}
                onBlur={onChangeMalusPoint}
                onChange={(e) => {
                  const val = e.target.value;
                  const numberRegex = /^$|^-?\d*$/;
                  const isValidNumber = numberRegex.test(val);
                  if (isValidNumber) {
                    setMalusPointValue(val);
                  } else {
                    setMalusPointValue(malusPointValue || '');
                  }
                }}
                size="small"
                sx={{
                  alignSelf: 'center',
                  width: 75,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderStyle: !row.isChecked ? 'dashed' : 'solid',
                  },
                }}
                value={malusPointValue}
              />
              {tooltipText && (
                <MobileCompatibleTooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  sx={{ alignSelf: 'center' }}
                  title={tooltipText}
                >
                  <InfoRounded
                    color="secondary"
                    fontSize="small"
                  />
                </MobileCompatibleTooltip>
              )}
            </Box>
          </Box>
        )}
      </Paper>
      {usesMalusPoint && !isMobile && (
        <TextField
          disabled={!row.isChecked}
          id={`row_${row.id}`}
          onBlur={onChangeMalusPoint}
          onChange={(e) => {
            const val = e.target.value;
            const numberRegex = /^$|^-?\d*$/;
            const isValidNumber = numberRegex.test(val);
            if (isValidNumber) {
              setMalusPointValue(val);
            } else {
              setMalusPointValue(malusPointValue || '');
            }
          }}
          sx={{
            alignSelf: 'center',
            minWidth: 124,
            '& .MuiOutlinedInput-notchedOutline': {
              borderStyle: !row.isChecked ? 'dashed' : 'solid',
            },
          }}
          value={malusPointValue}
        />
      )}
      {tooltipText && !isMobile && (
        <MobileCompatibleTooltip
          sx={{ alignSelf: 'center', ml: 1 }}
          title={tooltipText}
        >
          <InfoRounded
            color="secondary"
            fontSize="small"
          />
        </MobileCompatibleTooltip>
      )}
    </Box>
  );
};
