import {
  type AssessmentControlPointEvidenceBulkCreateInput,
  type AssessmentControlPointEvidenceBulkCreateOutput,
  type AssessmentControlPointEvidenceCreateInput,
  type AssessmentControlPointEvidenceCreateOutput,
  type AssessmentControlPointEvidenceListOutputItem,
  type AssessmentControlPointEvidenceListParams,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import {
  type BulkUpdateParamsWithTenantId,
  type CreateParamsWithTenantId,
  type DeleteParamsWithTenantId,
  injectQueryParams,
} from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'assessment-control-point-evidence';

/**
 * Caching is performed for both lists (which are per control point) and individual evidence links.
 * These are defined using the following tag types:
 *   - AssessmentControlPointEvidenceList
 *   - AssessmentControlPointEvidenceItem
 * One quirk is that the DELETE mutation hook needs the relevant control point ID, so that after
 * deletion, the "List" tag for that control point can be re-fetched without doing a blanket refetch
 * of ALL evidence on all control points (which is very wasteful). (See comment below on the DELETE
 * endpoint.)
 */

const assessmentControlPointEvidenceEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAssessmentControlPointEvidenceList: build.query<
      AssessmentControlPointEvidenceListOutputItem[],
      AssessmentControlPointEvidenceListParams
    >({
      query: ({ tid, ...restParams }) => injectQueryParams(`/tid/${tid}/${RESOURCE}/`, restParams),
      providesTags: (result, _error, _params) => [
        { type: TagType.AssessmentControlPointEvidence, id: 'LIST' },
        ...(result || []).map((evidenceLink) => ({
          type: TagType.AssessmentControlPointEvidence,
          id: evidenceLink?.uuid,
        })),
      ],
    }),

    createAssessmentControlPointEvidence: build.mutation<
      AssessmentControlPointEvidenceCreateOutput,
      CreateParamsWithTenantId<AssessmentControlPointEvidenceCreateInput>
    >({
      query: ({ tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/create/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result) => [
        TagType.AssessmentControlPointEvidence,
        TagType.AssessmentControlPointAnswer,
        { type: TagType.AssessmentControlPoint, id: result?.control_point_id },
      ],
    }),

    createBulkAssessmentControlPointEvidence: build.mutation<
      AssessmentControlPointEvidenceBulkCreateOutput,
      CreateParamsWithTenantId<AssessmentControlPointEvidenceBulkCreateInput>
    >({
      query: ({ tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/bulk-create/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result) => [
        TagType.AssessmentControlPointEvidence,
        TagType.AssessmentControlPointAnswer,
        { type: TagType.AssessmentControlPoint, id: result?.control_point_id },
      ],
    }),

    deleteAssessmentControlPointEvidence: build.mutation<void, DeleteParamsWithTenantId>({
      query: ({ id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/delete/`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, params) => [
        TagType.AssessmentControlPointEvidence,
        { type: TagType.AssessmentControlPoint, id: params.parentResourceId },
        { type: TagType.AssessmentControlPointEvidence, id: params.id },
      ],
    }),

    deleteBulkAssessmentControlPointEvidence: build.mutation<
      void,
      BulkUpdateParamsWithTenantId<{
        assessment_control_point_evidence_ids: string[];
      }>
    >({
      query: ({ tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/bulk-delete/`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [TagType.AssessmentControlPointEvidence],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateAssessmentControlPointEvidenceMutation,
  useCreateBulkAssessmentControlPointEvidenceMutation,
  useDeleteAssessmentControlPointEvidenceMutation,
  useDeleteBulkAssessmentControlPointEvidenceMutation,
  useGetAssessmentControlPointEvidenceListQuery,
} = assessmentControlPointEvidenceEndpoints;
