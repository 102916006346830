import { useEffect } from 'react';

import { openSnackbar } from '../store/snackbarSlice';
import { useAppDispatch } from './useAppDispatch';

export type ResultType = {
  isSuccess: boolean;
  isError: boolean;
  // Since it is dynamic, it must be any type.
  error?: any;
  // Since it is dynamic, it must be any type.
  data?: any;
  requestId?: string;
};

interface UseMutationFeedbackConfig {
  result: ResultType | null;
  successMessage?: string;
  // Since it is dynamic, it must be any type.
  onSuccess?: (data?: any) => void;
  errorMessage?: string;
  onError?: (errorMessage?: string) => void;
}

/**
 * Takes an RTK Query mutation hook result object and displays a success or error message within a
 * snackbar.
 *
 * @param config - Configuration object
 */

export const useMutationFeedback = ({
  result,
  onSuccess,
  successMessage,
  onError,
  errorMessage,
}: UseMutationFeedbackConfig): void => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (result?.isSuccess) {
      if (onSuccess) {
        onSuccess(result?.data);
      }
      if (successMessage) {
        dispatch(
          openSnackbar({
            message: successMessage,
            severity: 'success',
          }),
        );
      }
    }
    if (result?.isError) {
      if (onError) {
        onError(errorMessage);
      }
      if (errorMessage) {
        dispatch(
          openSnackbar({
            message: errorMessage,
            severity: 'error',
          }),
        );
      }
    }
  }, [result?.isSuccess, result?.isError, result?.requestId]);
};
