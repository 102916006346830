import { Box, Tab, Tabs, Toolbar, Typography } from '@mui/material';
import { cloneElement, useMemo } from 'react';
import { useTabs } from 'src/components/common/TabbedPage/TabbedPage.hooks';
import { EvidenceExpiredDocumentsList } from 'src/components/tables/EvidenceExpiredDocumentsList';
import { EvidenceFormsList } from 'src/components/tables/EvidenceFormsList';
import { EvidenceValidDocumentsList } from 'src/components/tables/EvidenceValidDocumentsList';
import { commonStrings, EvidenceTabsPageStrings } from 'src/languages/en-UK';

import { pageWrapperStyle } from './EvidenceTabsPage.styles';

export const EvidenceTabsPage = (): React.JSX.Element => {
  const tabs = useMemo(
    () => [
      {
        slug: 'valid-documents',
        title: EvidenceTabsPageStrings('validDocuments'),
        content: <EvidenceValidDocumentsList />,
      },
      {
        slug: 'evidence-forms',
        title: commonStrings('evidenceForms'),
        content: <EvidenceFormsList />,
      },
      {
        slug: 'expired-documents',
        title: commonStrings('expiredDocuments'),
        content: <EvidenceExpiredDocumentsList />,
      },
    ],
    [],
  );

  const [activeTabIndex, handleChangeTab] = useTabs(tabs);

  return (
    <>
      <Toolbar />
      <Box sx={pageWrapperStyle}>
        <Typography
          marginBottom={4}
          marginTop={3}
          variant="h1"
        >
          {commonStrings('documents')}
        </Typography>
        <Tabs
          onChange={handleChangeTab}
          sx={{
            borderRadius: 1,
            borderBottom: 1,
            borderBottomColor: 'divider',
          }}
          TabIndicatorProps={{ sx: { height: 4 } }}
          value={activeTabIndex}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.slug}
              label={tab.title}
            />
          ))}
        </Tabs>
        <Box paddingTop={2}>
          {tabs.map((tab, i) =>
            activeTabIndex === i ? cloneElement(tab.content, { key: tab.slug }) : null,
          )}
        </Box>
      </Box>
    </>
  );
};
