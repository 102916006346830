import { Box, Link as MuiLink, Typography, useMediaQuery } from '@mui/material';
import {
  type ReactNode,
  type SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import {
  type ModuleListOutputItem,
  type Product,
  type ProductionTechniqueOrganizationCultivationList,
} from 'src/__generated__/InternalApiTypes';
import { useAppDispatch, useAppSelector, useCurrentOrganization } from 'src/hooks';
import { useIsLeafOrigin } from 'src/hooks/useGetUserOrigin';
import { assessmentConfigMatrixStrings, commonStrings } from 'src/languages/en-UK';
import { useGetModuleListQuery, useGetRetailersListQuery } from 'src/services/farmApi';
import { REVIEW_MAIL_ADDRESS_LEAF, ROUTE_MALUS_POINT_EXPLANATION_LINK } from 'src/settings';
import { assessmentConfigActions } from 'src/store/assessmentConfig';
import { type MatrixRow } from 'src/store/assessmentConfig/types';
import { appColors } from 'src/theme';

import { LoadingArea } from '../common';
import { ThemeContext } from '../ThemeProvider';
import { AssessmentConfigDisabledContextProvider } from './AssessmentConfigMatrix.context';
import { useIsScrolled } from './AssessmentConfigMatrix.hooks';
import { bodyEmptyStateStyle, cultivationAreaStyle } from './AssessmentConfigMatrix.styles';
import { getIsChecked, getMalusPoint } from './AssessmentConfigMatrix.utils';
import { AssessmentConfigMatrixWrapper } from './AssessmentConfigMatrixWrapper';
import { ColumnHeaderBlock } from './ColumnHeaderBlock';
import { HeaderLabel } from './HeaderLabel';

interface AssessmentConfigMatrixProps {
  emptyState: ReactNode;
  isAuditorOrCBManager: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  selectedModuleList: ModuleListOutputItem[];
}

interface CultivationListItemType {
  malusPoint: number;
  isChecked: boolean;
  product: Product;
  productionTechnique: ProductionTechniqueOrganizationCultivationList;
  id: string;
  label: string;
}

export const AssessmentConfigMatrix = ({
  emptyState,
  selectedModuleList,
  isAuditorOrCBManager = false,
  isDisabled = false,
  isLoading = false,
}: AssessmentConfigMatrixProps): React.JSX.Element => {
  const [, , handleScroll] = useIsScrolled();
  const dispatch = useAppDispatch();
  const columns = useAppSelector((state) => state.assessmentConfig.columns);
  const rows = useAppSelector((state) => state.assessmentConfig.rows);
  const isLeafOrigin = useIsLeafOrigin();
  const [activeTabId, setActiveTabId] = useState('');
  const [seenTabIds, setSeenTabIds] = useState([]);
  const { theme } = useContext(ThemeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const selections = useAppSelector((state) => state.assessmentConfig.selections);
  const { data: moduleList } = useGetModuleListQuery({ has_parent: false });
  const sortedColumns = useMemo(() => [...columns].sort((a, b) => a.index - b.index), [columns]);

  const handleChangeTab = (_evt: SyntheticEvent, currentId: string) => {
    setActiveTabId(currentId);
    if (!seenTabIds.includes(currentId)) {
      setSeenTabIds([...seenTabIds, currentId]);
    }
  };

  useEffect(() => {
    // Added to make the first tab active if the last selected standard is removed.
    if (sortedColumns?.length > 0 && !sortedColumns.find((c) => c.id === activeTabId)) {
      setActiveTabId(sortedColumns[0].id);
      if (!seenTabIds.includes(sortedColumns[0].id)) {
        setSeenTabIds([...seenTabIds, sortedColumns[0].id]);
      }
    }
  }, [activeTabId, seenTabIds, sortedColumns]);

  const { usesMalusPoint, retailerIds: moduleRetailerIds } =
    columns.find((c) => c.id === activeTabId) || {};
  const { data: masterRetailers } = useGetRetailersListQuery();
  const { data: organization } = useCurrentOrganization();
  // Filter the retailers master data based on the selected module and organization
  const filteredRetailers = masterRetailers?.filter(
    (retailer) =>
      moduleRetailerIds?.includes(retailer.uuid) &&
      organization?.retailer_ids?.includes(retailer.uuid),
  );

  // Decide enable disable toggle based on higher malusPoint, same product and same production technique
  useEffect(() => {
    const getListOfMultipleProductAndProductionTechnique = (): MatrixRow[] =>
      rows.reduce((acc: MatrixRow[], obj, index) => {
        const tempDuplicateList = rows.filter(
          (item, i) =>
            i !== index &&
            item.productionTechnique?.uuid === obj.productionTechnique?.uuid &&
            item.product?.uuid === obj.product?.uuid,
        );

        if (
          tempDuplicateList.length > 0 &&
          !acc.some(
            (dup) =>
              dup.productionTechnique?.uuid === obj.productionTechnique?.uuid &&
              dup.product?.uuid === obj.product?.uuid,
          )
        ) {
          return acc.concat(
            rows.filter(
              (dup) =>
                dup.productionTechnique?.uuid === obj.productionTechnique?.uuid &&
                dup.product?.uuid === obj.product?.uuid &&
                selections.some((s) => s.rowId === dup.id),
            ),
          );
        }
        return acc;
      }, []);

    const checkDuplicateProduct = (colId: string) => {
      const duplicatedList = getListOfMultipleProductAndProductionTechnique();
      const findHighestMalusPoint = (list: MatrixRow[]) => {
        let minValue = 1;
        let selectedItem;
        const isEmptySelection = selections
          .filter((s) => colId === s.colId && s.malusPoint === undefined)
          .some((s) => list.some((l) => l.id === s.rowId));

        if (isEmptySelection) {
          return null;
        }

        for (let i = 0; i < list.length; i += 1) {
          const malusPoint = getMalusPoint(selections, colId, list[i].id);
          if (malusPoint < minValue) {
            minValue = malusPoint;
            selectedItem = list[i];
          }
        }
        return selectedItem;
      };

      if (duplicatedList?.length > 0) {
        duplicatedList.forEach((d) => {
          const matchingList = duplicatedList.filter(
            (l) =>
              d.productionTechnique?.uuid === l.productionTechnique?.uuid &&
              d.product?.uuid === l.product?.uuid,
          );
          const selectedItem = findHighestMalusPoint(matchingList);
          if (
            selectedItem &&
            d.id !== selectedItem.id &&
            d.productionTechnique?.uuid === selectedItem.productionTechnique?.uuid &&
            d.product?.uuid === selectedItem.product?.uuid &&
            selections.some((s) => s.colId === colId && s.rowId === d.id)
          ) {
            dispatch(
              assessmentConfigActions.changeCultivationSelection({
                selection: {
                  colId,
                  rowId: d.id,
                  malusPoint: undefined,
                },
                isChecked: false,
                isToggleButton: true,
              }),
            );
          }
        });
      }
    };
    if (usesMalusPoint) {
      checkDuplicateProduct(activeTabId);
    }
  }, [activeTabId, dispatch, rows, selections, usesMalusPoint]);

  const productIds = selectedModuleList.find((m) => m.uuid === activeTabId)?.product_ids;

  const getTooltipTextIfExist = (selectedCultivation: CultivationListItemType) => {
    let tooltipText = '';
    let isSwitchDisabled = false;

    // Check if there is another cultivation that exists with the same product and production technique.
    if (
      rows.some(
        (cultivation) =>
          cultivation.productionTechnique?.uuid === selectedCultivation.productionTechnique?.uuid &&
          cultivation.product?.uuid === selectedCultivation.product?.uuid &&
          cultivation.id !== selectedCultivation.id,
      ) &&
      usesMalusPoint
    ) {
      tooltipText = assessmentConfigMatrixStrings('excludePPTooltip');
    }

    // Check if there is a product for selected standard.
    if (productIds && !productIds.includes(selectedCultivation.product?.uuid)) {
      isSwitchDisabled = true;
      tooltipText = assessmentConfigMatrixStrings('excludeSMKTooltip');
    }
    return { isSwitchDisabled, tooltipText };
  };

  // Decide enable disable toggle based on products list of selected module
  useEffect(() => {
    const checkProductsNotOnTheStandardList = (colId: string) => {
      rows.forEach((r) => {
        if (
          productIds &&
          !productIds.includes(r.product?.uuid) &&
          !selectedModuleList.find((s) => s.uuid === colId)?.is_applicable_to_organization
        ) {
          dispatch(
            assessmentConfigActions.changeCultivationSelection({
              selection: {
                colId,
                rowId: r.id,
                malusPoint: undefined,
              },
              isChecked: false,
              isToggleButton: true,
            }),
          );
        }
      });
    };
    checkProductsNotOnTheStandardList(activeTabId);
  }, [activeTabId, dispatch, selectedModuleList, productIds, rows]);

  // Mapping the cultivation list from the backend to the HeaderLabel Component
  const rowsCollection = useMemo(
    () =>
      rows
        .map((row) => ({
          ...row,
          malusPoint: getMalusPoint(selections, activeTabId, row.id),
          isChecked: getIsChecked(selections, activeTabId, row.id),
        }))
        ?.sort((a, b) => Number(a.label.localeCompare(b.label))),
    [activeTabId, rows, selections],
  );

  // Show excluded header if there is unselected cultivation in a selected standards tab.
  const showExcludedHeader = rows.some((row) => !getIsChecked(selections, activeTabId, row.id));

  if (isLoading) {
    return (
      <AssessmentConfigMatrixWrapper
        onScroll={handleScroll}
        sx={{
          flex: '1 auto',
          justifyContent: 'center',
        }}
      >
        <LoadingArea
          hasAgriplaceLoadingAnimation
          size={72}
          sx={{ paddingTop: '50vh' }}
        />
      </AssessmentConfigMatrixWrapper>
    );
  }

  const isApplicableToOrganization = sortedColumns.some(
    (c) => c.isApplicableToOrganization && c.id === activeTabId,
  );

  return (
    <AssessmentConfigDisabledContextProvider value={isDisabled}>
      <AssessmentConfigMatrixWrapper
        onScroll={handleScroll}
        sx={{ opacity: isDisabled ? 0.5 : 1.0, flex: '1 auto' }}
      >
        {columns.length > 0 && rows.length > 0 ? (
          <>
            <ColumnHeaderBlock
              activeTabId={activeTabId}
              columns={sortedColumns}
              handleChangeTab={handleChangeTab}
              moduleList={moduleList}
              rows={rows}
              seenTabIds={seenTabIds}
              selectedModuleList={selectedModuleList}
            />
            <Box
              display="flex"
              justifyContent={{ xs: 'center', sm: 'space-between' }}
              maxWidth={723}
            >
              <Typography
                sx={{ pb: { xs: 1, sm: 2 }, pt: { xs: 3, sm: 5 } }}
                variant="button"
              >
                {commonStrings('cultivations').toLocaleUpperCase()}
              </Typography>
              {usesMalusPoint && !isMobile && (
                <Typography
                  component="span"
                  paddingBottom={4}
                  paddingTop={5}
                  variant="button"
                >
                  {commonStrings('malusPoints')}
                  <Typography
                    color="error"
                    component="span"
                    variant="body1"
                  >
                    *
                  </Typography>
                </Typography>
              )}
            </Box>
            {isApplicableToOrganization ? (
              <Typography variant="body2">
                {assessmentConfigMatrixStrings('certificationAppliesToWholeFarm', {
                  cellLabel: columns.find((c) => c.id === activeTabId)?.label.toUpperCase(),
                })}
              </Typography>
            ) : (
              <Box
                maxWidth="sm"
                sx={cultivationAreaStyle}
              >
                {rowsCollection.map(
                  (row) =>
                    row.isChecked && (
                      <HeaderLabel
                        key={row.id}
                        columnId={activeTabId}
                        isLoading={isLoading || !row?.label}
                        row={row}
                        usesMalusPoint={!!usesMalusPoint}
                      />
                    ),
                )}
              </Box>
            )}
            {!isApplicableToOrganization && showExcludedHeader && (
              <Box maxWidth="sm">
                <Typography
                  alignItems="center"
                  display="flex"
                  paddingY={{ xs: 0, sm: 1 }}
                  textAlign="center"
                  variant="overline"
                >
                  {!isMobile && (
                    <Box
                      bgcolor={appColors.border}
                      flexGrow={1}
                      height="1px"
                      marginRight={0.5}
                    />
                  )}
                  {isAuditorOrCBManager
                    ? assessmentConfigMatrixStrings('cultivationsExcludedFromInspection')
                    : assessmentConfigMatrixStrings('cultivationsExcludedFromAssessment')}
                  {!isMobile && (
                    <Box
                      bgcolor={appColors.border}
                      flexGrow={1}
                      height="1px"
                      marginLeft={0.5}
                      marginRight={5}
                    />
                  )}
                </Typography>
              </Box>
            )}
            <Box
              marginBottom={isMobile && !usesMalusPoint ? 8 : 0}
              maxWidth="sm"
              sx={cultivationAreaStyle}
            >
              {rowsCollection.map((product) => {
                const { isSwitchDisabled, tooltipText } = getTooltipTextIfExist(product);
                return (
                  !product.isChecked &&
                  !isApplicableToOrganization && (
                    <HeaderLabel
                      key={product.id}
                      columnId={activeTabId}
                      isLoading={isLoading || !product?.label}
                      isSwitchDisabled={isSwitchDisabled}
                      row={product}
                      tooltipText={tooltipText}
                      usesMalusPoint={!!usesMalusPoint}
                    />
                  )
                );
              })}
            </Box>
            {usesMalusPoint && (
              <Typography
                component="span"
                display="flex"
                paddingY={2}
                variant="body2"
              >
                <Typography
                  color="error"
                  component="span"
                  variant="body2"
                >
                  *
                </Typography>
                <Typography
                  component="span"
                  variant="body2"
                >
                  {`${assessmentConfigMatrixStrings('malusPointsDescriptionWithLink')} `}
                  <MuiLink
                    component={Link}
                    sx={{
                      color: appColors.blue,
                    }}
                    target="_blank"
                    to={ROUTE_MALUS_POINT_EXPLANATION_LINK}
                    variant="body2"
                  >
                    {`"${assessmentConfigMatrixStrings('howToCalculateMalusPoints')}"`}
                  </MuiLink>
                </Typography>
              </Typography>
            )}
            {isLeafOrigin && (
              <Box sx={{ mt: 5 }}>
                <Typography
                  component="span"
                  variant="button"
                >
                  {assessmentConfigMatrixStrings('youWillSeeAdditionalQuestionsFrom')}
                  <Typography
                    color="error"
                    component="span"
                    variant="body1"
                  >
                    *
                  </Typography>
                  {' :'}
                </Typography>
                {filteredRetailers?.length > 0 ? (
                  filteredRetailers.map((retailer) => (
                    <Box
                      key={retailer.uuid}
                      sx={{ alignItems: 'center', display: 'flex', mt: 2 }}
                    >
                      {retailer.logo && (
                        <img
                          alt={`${retailer.name}`}
                          src={retailer.logo.file_object}
                          style={{
                            marginRight: '4px',
                            height: '40px',
                            width: '40px',
                          }}
                        />
                      )}
                      <Typography>{retailer.name}</Typography>
                    </Box>
                  ))
                ) : (
                  <Typography
                    component="p"
                    sx={{ color: 'text.secondary', mt: 5, mb: 2 }}
                    variant="overline"
                  >
                    {assessmentConfigMatrixStrings('thereIsNoRecordOfAnyRetailers')}
                  </Typography>
                )}
                <Typography
                  component="span"
                  sx={{ display: 'flex', pt: 3, pb: 2 }}
                  variant="body2"
                >
                  <Typography
                    color="error"
                    component="span"
                    variant="body2"
                  >
                    *
                  </Typography>
                  <Typography component="span">
                    {` ${assessmentConfigMatrixStrings('leafReviewMailInfo')} `}
                    <MuiLink
                      component="a"
                      href={`mailto:${REVIEW_MAIL_ADDRESS_LEAF}`}
                      sx={{
                        color: appColors.blue,
                      }}
                    >
                      {REVIEW_MAIL_ADDRESS_LEAF}
                    </MuiLink>
                  </Typography>
                </Typography>
              </Box>
            )}
          </>
        ) : (
          <Box sx={bodyEmptyStateStyle}>{emptyState || null}</Box>
        )}
      </AssessmentConfigMatrixWrapper>
    </AssessmentConfigDisabledContextProvider>
  );
};
