import { Box, TextField, Typography, useMediaQuery } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { isValid, parse } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import {
  AnswerTypeEnum,
  type AssessmentControlPointAnswerListOutputItem,
  type AssessmentControlPointInputControlPointInputList,
  ControlPointAnswerTypeEnum,
} from 'src/__generated__/InternalApiTypes';
import { useGetCurrentUserProfile } from 'src/hooks';
import { commonStrings } from 'src/languages/en-UK';
import { DATE_DISPLAY_FORMAT } from 'src/settings';

import {
  type AnswerChipStatus,
  ControlPointAnswerPoints,
  getComplianceStatusLabel,
  mapComplianceStatusToAnswerChip,
} from '../ControlPointAnswerPoints';
import { ThemeContext } from '../ThemeProvider';

interface ControlPointInputAnswerProps {
  controlPointInput: AssessmentControlPointInputControlPointInputList;
  existingAnswers: AssessmentControlPointAnswerListOutputItem[];
  isDisabled: boolean;
  isScored: boolean;
  isShowChip: boolean;
  onChangeAnswer: (answers: string | number) => void;
  placeholder: string;
  value: number | string | Date;
}

export const getDynamicCalculatedInputData = (
  existingAnswers: AssessmentControlPointAnswerListOutputItem[],
): [AnswerChipStatus, string, number] => {
  const answer = existingAnswers[0];
  const status = mapComplianceStatusToAnswerChip(answer?.compliance_status);
  const statusLabel = getComplianceStatusLabel(answer?.compliance_status_label);
  return [status, statusLabel, answer?.points ?? 0];
};

export const ControlPointInputAnswer = ({
  controlPointInput,
  existingAnswers,
  onChangeAnswer,
  placeholder,
  value,
  isDisabled = false,
  isScored = false,
  isShowChip = false,
}: ControlPointInputAnswerProps): React.JSX.Element => {
  const [status, statusLabel, points] = getDynamicCalculatedInputData(existingAnswers);
  const { theme } = useContext(ThemeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallDesktop = useMediaQuery(theme.breakpoints.down('lg'));
  const { data: userProfile } = useGetCurrentUserProfile();
  const language = userProfile?.language;
  const {
    name,
    answer_type: answerType,
    max_answer_value: maxAnswerValue,
    max_decimal_places: maxDecimalPlaces,
    min_answer_value: minAnswerValue,
  } = controlPointInput;
  let inputAnswerType = 'string';
  if (answerType === ControlPointAnswerTypeEnum.FarmLevelInputNumber) {
    inputAnswerType = AnswerTypeEnum.Number;
  } else if (answerType === ControlPointAnswerTypeEnum.FarmLevelInputDate) {
    inputAnswerType = 'date';
  }
  const [errorMessage, setErrorMessage] = useState('');
  const [dynamicValue, setDynamicValue] = useState(value);

  useEffect(() => {
    if (inputAnswerType === AnswerTypeEnum.Number && value && language !== 'en') {
      setDynamicValue(value.toString().replace('.', ','));
    } else {
      setDynamicValue(value);
    }
  }, [inputAnswerType, language, value]);

  return (
    <Box
      alignItems={{ xs: 'flex-start', lg: 'center' }}
      display="flex"
      flexDirection={{ xs: 'column', lg: 'row' }}
    >
      <Typography
        flex={1}
        maxWidth={isMobile ? undefined : 320}
        paddingRight={2}
        variant="body1"
      >
        {name}
      </Typography>
      <Box
        display="flex"
        flex={1}
        flexGrow={1}
        marginTop={isSmallDesktop ? 1 : undefined}
        width={isMobile ? '100%' : undefined}
      >
        {inputAnswerType === 'date' ? (
          <DatePicker
            disabled={isDisabled}
            disablePast
            inputFormat={DATE_DISPLAY_FORMAT}
            onChange={(date, keyboardInput) => {
              setDynamicValue(date);
              if (!keyboardInput && isValid(date)) {
                onChangeAnswer((date as Date).toISOString());
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                onBlur={(e) => {
                  if (e?.target?.value) {
                    const date = parse(e.target.value, DATE_DISPLAY_FORMAT, new Date());
                    if (isValid(date)) {
                      onChangeAnswer(date.toISOString());
                    }
                  }
                }}
                size="small"
                sx={{
                  '& .MuiInputBase-input': {
                    px: 1.5,
                  },
                  '& .MuiInputLabel-root': {
                    pl: 0.25,
                  },
                }}
              />
            )}
            showToolbar={false}
            value={dynamicValue}
          />
        ) : (
          <TextField
            disabled={isDisabled}
            error={!!errorMessage}
            helperText={errorMessage}
            label={placeholder}
            onBlur={(e) => {
              let val = e.target.value;
              let errorMsg = '';
              if (inputAnswerType === AnswerTypeEnum.Number) {
                if (val.endsWith(',') || val.endsWith('.') || val === '-') {
                  val = val.slice(0, -1);
                }
                const numberVal = val.replace(',', '.');
                if (numberVal) {
                  if (minAnswerValue !== null && minAnswerValue > parseFloat(numberVal)) {
                    errorMsg = commonStrings('minErrorMessage', { min: minAnswerValue });
                  } else if (maxAnswerValue !== null && parseFloat(numberVal) > maxAnswerValue) {
                    errorMsg = commonStrings('maxErrorMessage', { max: maxAnswerValue });
                  }
                }
                setErrorMessage(errorMsg);
              }
              setDynamicValue(val);
              if (!errorMsg) {
                onChangeAnswer(
                  inputAnswerType === AnswerTypeEnum.Number && val
                    ? parseFloat(val.replace(',', '.'))
                    : val,
                );
              }
            }}
            onChange={(e) => {
              const val = e.target.value;
              if (inputAnswerType === AnswerTypeEnum.Number) {
                const maxPlacesRegex = maxDecimalPlaces ?? '';
                const seperatorRegex = language === 'en' ? '.' : ',';
                const numberRegex = new RegExp(
                  `^$|^-?\\d{0,}(\\${seperatorRegex})?\\d{0,${maxPlacesRegex}}$`,
                );
                const isValidNumber = numberRegex.test(val);
                if (isValidNumber) {
                  setDynamicValue(val);
                } else {
                  setDynamicValue(dynamicValue || '');
                }
              } else {
                setDynamicValue(val);
              }
            }}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                // It's MUI Textfield error so we should use any.
                (ev?.target as any)?.blur?.();
                ev.preventDefault();
              }
            }}
            size="small"
            sx={{
              width: inputAnswerType === AnswerTypeEnum.Number && !isMobile ? 192 : '100%',
              minWidth: inputAnswerType === AnswerTypeEnum.Number || isMobile ? undefined : 430,
              '& .MuiInputBase-input': {
                px: 1.5,
                textAlign: inputAnswerType === AnswerTypeEnum.Number ? 'center' : 'left',
              },
              '& .MuiInputLabel-root': {
                pl: 0.25,
              },
            }}
            value={dynamicValue}
          />
        )}
      </Box>
      {isShowChip && (
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          width={isSmallDesktop ? '100%' : undefined}
        >
          <ControlPointAnswerPoints
            showPointBox={isScored}
            status={status}
            statusLabel={statusLabel}
            value={points}
          />
        </Box>
      )}
    </Box>
  );
};
