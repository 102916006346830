import {
  type EvidenceCreateInput,
  type EvidenceCreateOutput,
  type EvidenceDetailOutput,
  type EvidenceListOutputItem,
  type EvidenceListParams,
  type EvidenceRetrieveParams,
  type EvidenceUpdateInput,
  type EvidenceUpdateOutput,
  type ValidEvidenceExistsOutput,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import {
  type CreateParamsWithTenantId,
  type DeleteParamsWithTenantId,
  type DetailParamsGeneric,
  type DetailParamsWithTenantId,
  formikToFormData,
  injectQueryParams,
  transformObject,
  type UpdateParamsWithTenantId,
} from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'evidence';

export type TransformedEvidenceListOutputItem = Omit<
  EvidenceListOutputItem,
  'expiry_date' | 'created_at'
> & {
  created_at: Date;
  expiry_date: Date;
};

type TransformedEvidenceCreateInput = Omit<EvidenceCreateInput, 'expiry_date' | 'file'> & {
  expiry_date: Date;
  file: File;
};

type TransformedEvidenceUpdateInput = Omit<EvidenceUpdateInput, 'expiry_date' | 'signed_at'> & {
  // Since it is dynamic, it must be any type.
  file?: any;
  expiry_date: Date;
  signed_at?: Date;
};

export type TransformedEvidenceDetailOutput = Omit<EvidenceDetailOutput, 'expiry_date' | 'uuid'> & {
  expiry_date: Date;
  uuid: string;
};

const responseTransformations = {
  created_at: (value: string): Date => new Date(value),
  expiry_date: (value: string): Date => new Date(value),
};

const requestTransformations = {
  expiry_date: (date: Date): string => date.toISOString(),
};

export const evidenceEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getEvidenceList: build.query<TransformedEvidenceListOutputItem[], EvidenceListParams>({
      query: ({ tid, ...restParams }) => injectQueryParams(`/tid/${tid}/${RESOURCE}/`, restParams),
      providesTags: [TagType.Evidence, TagType.AssessmentControlPointEvidence],
      transformResponse: (data: EvidenceListOutputItem[]): TransformedEvidenceListOutputItem[] =>
        data.map((item) => transformObject(item, responseTransformations)),
    }),

    validEvidenceExists: build.query<ValidEvidenceExistsOutput, string>({
      query: (tid) => `/tid/${tid}/${RESOURCE}/valid-evidence-exists/`,
      providesTags: [TagType.Evidence],
    }),

    getEvidence: build.query<
      TransformedEvidenceDetailOutput,
      DetailParamsGeneric<EvidenceRetrieveParams>
    >({
      query: ({ tid, id, ...restParams }) =>
        injectQueryParams(`/tid/${tid}/${RESOURCE}/${id}/`, restParams),
      providesTags: [TagType.Evidence],
      transformResponse: (data: EvidenceDetailOutput) =>
        transformObject(data, responseTransformations),
    }),

    createEvidence: build.mutation<
      EvidenceCreateOutput,
      CreateParamsWithTenantId<TransformedEvidenceCreateInput>
    >({
      query: ({ tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/create/`,
        method: 'POST',
        body: formikToFormData(transformObject(body, requestTransformations)),
      }),
      invalidatesTags: [TagType.Evidence],
    }),

    archiveEvidence: build.mutation<void, DetailParamsWithTenantId>({
      query: ({ id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/archive/`,
        method: 'POST',
      }),
      invalidatesTags: [TagType.Evidence],
    }),

    deleteEvidence: build.mutation<void, DeleteParamsWithTenantId>({
      query: ({ id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/delete/`,
        method: 'DELETE',
      }),
      invalidatesTags: [TagType.Evidence],
    }),

    updateEvidence: build.mutation<
      EvidenceUpdateOutput,
      UpdateParamsWithTenantId<TransformedEvidenceUpdateInput>
    >({
      query: ({ id, tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/update/`,
        method: 'PATCH',
        body:
          body?.file instanceof File
            ? formikToFormData(transformObject(body, requestTransformations))
            : transformObject(body, requestTransformations),
      }),
      invalidatesTags: [TagType.Evidence],
    }),
  }),
  overrideExisting: false,
});

export const {
  useArchiveEvidenceMutation,
  useCreateEvidenceMutation,
  useGetEvidenceListQuery,
  useGetEvidenceQuery,
  useDeleteEvidenceMutation,
  useUpdateEvidenceMutation,
  useValidEvidenceExistsQuery,
} = evidenceEndpoints;
