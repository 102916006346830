import { type ControlPointDetailOutput } from 'src/__generated__/InternalApiTypes';
import { ControlPointDetailGuidanceTabs } from 'src/enums/ControlPointDetailGuidanceTabs';

export const getInitialTab = (
  controlPointDetail: ControlPointDetailOutput | undefined,
): ControlPointDetailGuidanceTabs | null => {
  if (!controlPointDetail) {
    return null;
  }
  if (controlPointDetail.criteria) {
    return ControlPointDetailGuidanceTabs.Criteria;
  }
  if (controlPointDetail.guidance) {
    return ControlPointDetailGuidanceTabs.Guidance;
  }
  if (controlPointDetail.justification_guideline) {
    return ControlPointDetailGuidanceTabs.JustificationGuideline;
  }
  if (controlPointDetail.verification_method_ids.length > 0) {
    return ControlPointDetailGuidanceTabs.VerificationMethod;
  }

  return null;
};
