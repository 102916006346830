import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@mui/icons-material';
import { Button, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { type MouseEvent, type ReactNode, useState } from 'react';

export type MenuItemType = {
  label: string;
  onClick: () => void;
  dense?: boolean;
  icon?: ReactNode;
};

interface ButtonMenuProps {
  children: ReactNode;
  menuItems: MenuItemType[];
}

export const ButtonMenu = ({ children, menuItems }: ButtonMenuProps): React.JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItemsWithCloseHandler = menuItems.map((item) => ({
    dense: item.dense,
    icon: item.icon,
    handleClick: () => {
      item.onClick();
      handleClose();
    },
    label: item.label,
  }));

  return (
    <>
      <Button
        endIcon={isOpen ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
        onClick={handleClick}
        sx={{
          p: 0,
          textTransform: 'none',
          '& .MuiButton-endIcon': { ml: 0.5, mr: 0 },
        }}
      >
        {children}
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={handleClose}
        open={isOpen}
        sx={{ mt: 1 }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuItemsWithCloseHandler.map((item, i) => (
          <MenuItem
            key={`${i}_button-menu`}
            dense={item.dense}
            onClick={item.handleClick}
          >
            {item.icon && (
              <ListItemIcon sx={{ minWidth: '20px !important', pr: 0.5 }}>{item.icon}</ListItemIcon>
            )}
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
