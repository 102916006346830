import { createContext, type ReactNode, useMemo } from 'react';
import {
  type ControlPointCriticalityListOutputItem,
  type PossibleAnswerListOutputItem,
  type PossibleAnswerSetListOutputItem,
} from 'src/__generated__/InternalApiTypes';
import {
  useGetControlPointCriticalityListQuery,
  useGetPossibleAnswerListQuery,
  useGetPossibleAnswerSetListQuery,
} from 'src/services/farmApi';

export const ControlPointMasterDataContext = createContext<{
  controlPointCriticalities: ControlPointCriticalityListOutputItem[] | null | undefined;
  isFullyLoaded: boolean;
  possibleAnswers: PossibleAnswerListOutputItem[] | null | undefined;
  possibleAnswerSets: PossibleAnswerSetListOutputItem[] | null | undefined;
}>({
  controlPointCriticalities: null,
  isFullyLoaded: false,
  possibleAnswers: null,
  possibleAnswerSets: null,
});

interface ControlPointMasterDataProviderProps {
  children: ReactNode;
}

export const ControlPointMasterDataProvider = ({
  children,
}: ControlPointMasterDataProviderProps): React.JSX.Element => {
  const { data: controlPointCriticalities } = useGetControlPointCriticalityListQuery({});
  const { data: possibleAnswerSets } = useGetPossibleAnswerSetListQuery();
  const { data: possibleAnswers } = useGetPossibleAnswerListQuery();

  const data = useMemo(
    () => ({
      controlPointCriticalities,
      possibleAnswers,
      possibleAnswerSets,
      isFullyLoaded: Boolean(controlPointCriticalities && possibleAnswers && possibleAnswerSets),
    }),
    [controlPointCriticalities, possibleAnswers, possibleAnswerSets],
  );

  return (
    <ControlPointMasterDataContext.Provider value={data}>
      {children}
    </ControlPointMasterDataContext.Provider>
  );
};
