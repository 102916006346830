import { DeleteForeverRounded } from '@mui/icons-material';
import { Box, IconButton, Paper, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useContext } from 'react';
import {
  useAppDispatch,
  useGetAssessmentUserPermission,
  useMutationFeedback,
  useTenantId,
  useUserAuth,
} from 'src/hooks';
import { commentStrings, commonStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import {
  type TransformedAssessmentControlPointCommentOutputItem,
  useDeleteAssessmentControlPointCommentMutation,
} from 'src/services/farmApi';
import { DATE_TIME_DISPLAY_FORMAT } from 'src/settings';
import { openSnackbar } from 'src/store/snackbarSlice';
import { appColors } from 'src/theme';

import { MobileCompatibleTooltip } from '../common/MobileCompatibleTooltip';
import { RolesLabel } from '../formikcomponents/FormikInvitationRoleField';

interface CommentProps {
  comment: TransformedAssessmentControlPointCommentOutputItem;
}

export const Comment = ({ comment }: CommentProps) => {
  const tid = useTenantId();
  const currentUser = useUserAuth();
  const [deleteComment, deleteCommentResult] = useDeleteAssessmentControlPointCommentMutation();
  const [assessmentId] = useContext(AssessmentPageContext);
  const dispatch = useAppDispatch();
  const permissions = useGetAssessmentUserPermission(assessmentId);
  const isCommentAuthor = comment.author_id === currentUser?.userId;

  useMutationFeedback({
    result: deleteCommentResult,
    errorMessage: commentStrings('deleteCommentFail'),
    successMessage: commentStrings('deleteCommentSuccess'),
  });

  const handleDelete = () => {
    if (permissions?.comments?.delete) {
      deleteComment({ tid, id: comment.uuid, parentResourceId: comment.control_point_id });
    } else {
      dispatch(
        openSnackbar({
          message: commonStrings('notAuthorized'),
          severity: 'error',
        }),
      );
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        borderWidth: 1,
        borderRadius: 0.5,
        border: 1,
        px: 1.5,
        pb: 2,
        borderColor: appColors.border,
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          paddingBottom={1}
          paddingTop={1.5}
        >
          <Typography
            fontWeight={700}
            variant="caption"
          >
            {comment.author_name}
          </Typography>
          <Typography
            paddingLeft={1}
            variant="caption"
          >
            {RolesLabel[comment.author_role as keyof typeof RolesLabel]}
          </Typography>
          <Typography
            paddingLeft={1}
            variant="caption"
          >
            {format(comment.created_at, DATE_TIME_DISPLAY_FORMAT)}
          </Typography>
        </Box>
        {isCommentAuthor && (
          <IconButton
            onClick={handleDelete}
            sx={{ padding: 0, maxHeight: 24 }}
          >
            <MobileCompatibleTooltip title={commentStrings('deleteComment')}>
              <DeleteForeverRounded />
            </MobileCompatibleTooltip>
          </IconButton>
        )}
      </Stack>
      <Typography
        align="left"
        display="block"
        variant="body1"
      >
        {comment.text}
      </Typography>
      <Stack direction="row-reverse" />
    </Paper>
  );
};
