import { Autocomplete, Box, Skeleton, TextField } from '@mui/material';
import { type SyntheticEvent } from 'react';
import { appColors } from 'src/theme';
import { type LabelValuePair } from 'src/types/LabelValuePair';

import { type FormikTextFieldProps } from '../FormikTextField';

interface FormikAutocompleteFieldProps extends FormikTextFieldProps {
  isLoading: boolean;
  options: LabelValuePair[];
}

/**
 * This component mates a Formik instance (created via the useFormik hook) with a MUI (autocomplete)
 * TextField.
 */
export const FormikAutocompleteField = ({
  formik,
  id,
  label,
  options,
  disabled = false,
  isLoading = false,
  placeholder = '',
  ...restProps
}: FormikAutocompleteFieldProps) =>
  isLoading ? (
    <Box
      border={1}
      borderColor={appColors.border}
      borderRadius={1}
      paddingX="14px"
      paddingY="15px"
      width="100%"
    >
      <Skeleton
        animation="wave"
        variant="text"
        width="33%"
      />
    </Box>
  ) : (
    <Autocomplete
      disabled={isLoading || disabled}
      fullWidth
      getOptionLabel={(option) => {
        if (option?.label) {
          return option?.label;
        }
        return options.find((opt) => opt.value === option)?.label || '';
      }}
      id={id}
      isOptionEqualToValue={(option, selectedItem) =>
        option?.value?.toString() === selectedItem?.toString()
      }
      onChange={(_: SyntheticEvent, newValue: LabelValuePair | null) => {
        formik?.setFieldValue(id, newValue?.value);
      }}
      options={options ?? []}
      renderInput={(params) => (
        <TextField
          {...params}
          error={formik?.touched[id] && Boolean(formik.errors[id])}
          helperText={formik?.touched[id] && (formik.errors[id] as string)}
          id={id}
          label={label}
          name={id}
          onBlur={formik?.handleBlur}
          placeholder={typeof label === 'string' ? placeholder || label : placeholder}
          {...restProps}
        />
      )}
      value={formik?.values[id] ? formik.values[id] : null}
    />
  );
