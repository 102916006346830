import { type ModuleListOutputItem } from 'src/__generated__/InternalApiTypes';

import { type MatrixColumn } from '../types';

/**
 * Converts a module list item to a matrix column.
 * @param module - Module to convert
 * @param children
 * @returns
 */
export const moduleToMatrixColumn = (
  module: ModuleListOutputItem,
  children: MatrixColumn[] = [],
): MatrixColumn => ({
  children,
  description: module.description,
  id: module.uuid,
  index: module.index,
  isApplicableToOrganization: module.is_applicable_to_organization,
  label: module.name,
  retailerIds: module.retailer_ids,
  standardId: module.standard_id,
  usesMalusPoint: module.uses_malus_points,
});
