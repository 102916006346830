import { Typography } from '@mui/material';
import { OrganizationTypesEnum, type RoleListOutputItem } from 'src/__generated__/InternalApiTypes';
import { useCurrentOrganization, useTenantId } from 'src/hooks';
import { formikInvitationRoleFieldStrings } from 'src/languages/en-UK';
import { useGetOrganizationTypeRoleListQuery } from 'src/services/farmApi';

import { FormikSelectField } from '../FormikSelectField';
import { type FormikTextFieldProps } from '../FormikTextField';

type FormikInvitationRoleFieldProps = FormikTextFieldProps;

export enum RolesLabel {
  owner = 'Owner',
  farm_manager = 'Farm Manager',
  farm_employee = 'Farm Employee',
  auditor = 'Auditor',
  auditor_reviewer = 'Reviewer',
  certification_body_manager = 'Certification Body Manager',
  standard_setter = 'Standard Setter',
  farm_advisor = 'Farm Advisor',
  internal_auditor = 'Internal Auditor',
  internal_inspector = 'Internal Inspector',
}

/**
 * This component pre-fills a FormikSelectField with invitation roles options.
 */
export const FormikInvitationRoleField = ({
  formik,
  id,
  label,
  isLoading = false,
  ...restProps
}: FormikInvitationRoleFieldProps) => {
  const tid = useTenantId();
  const { data: organizationTypeRoles } = useGetOrganizationTypeRoleListQuery(
    { tid, include_owner: false },
    {
      skip: !tid,
    },
  );
  const { data: organization } = useCurrentOrganization();
  const options = (organizationTypeRoles || [])?.map((role: RoleListOutputItem) => ({
    value: role.slug,
    label: role.name,
  }));

  return (
    <FormikSelectField
      formik={formik}
      id={id}
      isLoading={isLoading}
      label={label}
      options={options}
      tooltipTitle={
        organization?.type === OrganizationTypesEnum.Farm && (
          <Typography component="span">
            <Typography
              component="p"
              fontSize={10}
              fontWeight={500}
              lineHeight={1.4}
              variant="caption"
            >
              {formikInvitationRoleFieldStrings('tooltipTitleForFarmManager')}
            </Typography>
            <Typography
              component="p"
              fontSize={10}
              fontWeight={500}
              lineHeight={1.4}
              variant="caption"
            >
              {formikInvitationRoleFieldStrings('tooltipTitleForFarmEmployee')}
            </Typography>
          </Typography>
        )
      }
      {...restProps}
    />
  );
};
