import { type GridValueFormatterParams, type GridValueGetterParams } from '@mui/x-data-grid-pro';
import { format, isValid } from 'date-fns';
import { commonStrings } from 'src/languages/en-UK';
import { DATE_DISPLAY_FORMAT_FOR_TABLE } from 'src/settings';

export const dataGridValueGetterForDate = (params: GridValueGetterParams) => {
  const { field, row } = params;
  if (!row[field] || !isValid(new Date(row[field]))) {
    return row[field];
  }
  return format(new Date(row[field]), DATE_DISPLAY_FORMAT_FOR_TABLE);
};

export const dataGridValueFormatterForDate = (
  params: GridValueFormatterParams,
  defaultText = commonStrings('notApplicableShort'),
) =>
  params?.value
    ? format(new Date(params.value.toString()), DATE_DISPLAY_FORMAT_FOR_TABLE)
    : defaultText;
