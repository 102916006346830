import { FormatAlignLeft } from '@mui/icons-material';
import { Box, Drawer, Fab, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { useContext, useEffect } from 'react';
import { type CategoryData } from 'src/components/assessmentsteps/AssessmentEvidenceStep/AssessmentEvidenceStep.hooks';
import { ExpandableArea, LoadingArea } from 'src/components/common';
import { MobileCompatibleTooltip } from 'src/components/common/MobileCompatibleTooltip';
import { ThemeContext } from 'src/components/ThemeProvider';
import { useDialogState } from 'src/hooks';
import { assessmentControlPointCategoriesDrawerStrings } from 'src/languages/en-UK';
import { appColors } from 'src/theme';

import {
  AssessmentControlPointDrawerToggle,
  SidebarToggleButtonTypeEnum,
} from '../AssessmentControlPointDrawerToggle';

interface AssessmentControlPointCategoriesDrawerProps {
  assessmentLevel1Categories: CategoryData[];
  assessmentLevel2Categories: CategoryData[];
  drawerWidth: number;
  isLoading: boolean;
  selectedLevel1Category: CategoryData | null;
  selectedLevel2Category: CategoryData | null;
  selectedMode: SidebarToggleButtonTypeEnum;
  setIsLastItem: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedLevel1Category: React.Dispatch<React.SetStateAction<CategoryData | null>>;
  setSelectedLevel2Category: React.Dispatch<React.SetStateAction<CategoryData | null>>;
  setSelectedMode: React.Dispatch<React.SetStateAction<SidebarToggleButtonTypeEnum>>;
}

export const AssessmentControlPointCategoriesDrawer = ({
  assessmentLevel1Categories,
  assessmentLevel2Categories,
  drawerWidth,
  selectedLevel1Category,
  selectedLevel2Category,
  selectedMode,
  setIsLastItem,
  setSelectedLevel1Category,
  setSelectedLevel2Category,
  setSelectedMode,
  isLoading = false,
}: AssessmentControlPointCategoriesDrawerProps): React.JSX.Element => {
  const { theme } = useContext(ThemeContext);
  const [isCategoryDrawerOpen, openCategoryDrawer, closeCategoryDrawer] = useDialogState();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
  useEffect(() => {
    if (!selectedLevel1Category && assessmentLevel1Categories?.length > 0) {
      setSelectedLevel1Category(assessmentLevel1Categories[0]);
    }
  }, [assessmentLevel1Categories, selectedLevel1Category, setSelectedLevel1Category]);

  useEffect(() => {
    const filteredAssessmentLevel2Categories = assessmentLevel2Categories?.filter(
      (lvl2) => lvl2.parentCategoryId === selectedLevel1Category?.uuid,
    );
    if (!selectedLevel2Category && filteredAssessmentLevel2Categories?.length > 0) {
      setSelectedLevel2Category(filteredAssessmentLevel2Categories[0]);
      setIsLastItem(false);
    }
  }, [
    assessmentLevel2Categories,
    selectedLevel1Category?.uuid,
    selectedLevel2Category,
    setIsLastItem,
    setSelectedLevel2Category,
  ]);

  const handleLevel1Change = (category: CategoryData) => {
    setSelectedLevel1Category(category ?? null);
    setSelectedLevel2Category(null);
    if (isSmallDevice) {
      closeCategoryDrawer();
    }
  };

  const handleLevel2Change = (category: CategoryData) => {
    setSelectedLevel2Category(category ?? null);
    const selectedLevel2CategoryIndex = assessmentLevel2Categories.findIndex(
      (cat) => cat.uuid === category?.uuid,
    );

    if (selectedLevel2CategoryIndex === assessmentLevel2Categories.length - 1) {
      setIsLastItem(true);
    } else {
      setIsLastItem(false);
    }
    if (isSmallDevice) {
      closeCategoryDrawer();
    }
  };

  return (
    <>
      <Drawer
        ModalProps={{
          keepMounted: true, // Better open performance on mobile
        }}
        onClose={closeCategoryDrawer}
        open={isSmallDevice ? isCategoryDrawerOpen : true}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
          '& .MuiBox-root': {
            '&:hover': {
              backgroundColor: assessmentLevel1Categories?.length
                ? `${theme.palette.primary.main}0A`
                : 'transparent',
            },
          },
        }}
        variant={isSmallDevice ? 'temporary' : 'permanent'}
      >
        <AssessmentControlPointDrawerToggle
          selectedMode={selectedMode}
          setSelectedMode={(mode) => {
            if (mode === SidebarToggleButtonTypeEnum.AuditingMode) {
              setSelectedLevel1Category(null);
              setSelectedLevel2Category(null);
            }
            setSelectedMode(mode);
          }}
        />
        {isLoading && <LoadingArea />}
        {!isLoading && !assessmentLevel1Categories?.length ? (
          <>
            <Typography
              color="text.disabled"
              textAlign="center"
              textTransform="uppercase"
              variant="body2"
            >
              {assessmentControlPointCategoriesDrawerStrings('noCategoriesToDisplay')}
            </Typography>
            <Typography
              color="text.disabled"
              textAlign="center"
              variant="body2"
            >
              {assessmentControlPointCategoriesDrawerStrings('chooseDifferentStandard')}
            </Typography>
          </>
        ) : (
          <Typography
            lineHeight={3.43}
            marginTop={-2}
            paddingX={2}
            textTransform="uppercase"
            variant="subtitle2"
          >
            {!isLoading && assessmentControlPointCategoriesDrawerStrings('categories')}
          </Typography>
        )}
        {assessmentLevel1Categories?.map((level1Category) => {
          const isSelectedLvl1 = selectedLevel1Category?.uuid === level1Category.uuid;
          return (
            <ExpandableArea
              key={level1Category.uuid}
              disableGutters={false}
              expanded={isSelectedLvl1}
              onChange={() => handleLevel1Change(level1Category)}
              title={level1Category.name}
            >
              {assessmentLevel2Categories
                ?.filter(
                  (level2Category) => level2Category.parentCategoryId === level1Category.uuid,
                )
                .map((level2Category) => {
                  const isSelectedLvl2 = selectedLevel2Category?.uuid === level2Category.uuid;
                  const textColorLvl2 = isSelectedLvl2 ? 'primary' : 'textPrimary';

                  return (
                    <Box
                      key={level2Category.uuid}
                      bgcolor={isSelectedLvl2 ? `${theme.palette.primary.main}0A` : 'transparent'}
                      onClick={() => handleLevel2Change(level2Category)}
                      paddingLeft={6}
                      paddingY={1.5}
                      sx={{
                        '&:hover': {
                          backgroundColor: appColors.greyBorder,
                          cursor: 'pointer',
                        },
                      }}
                    >
                      <Typography
                        color={textColorLvl2}
                        variant="body1"
                      >
                        {level2Category.name}
                      </Typography>
                    </Box>
                  );
                })}
            </ExpandableArea>
          );
        })}
        <Toolbar sx={{ mt: 1 }} />
      </Drawer>
      <MobileCompatibleTooltip
        title={
          isCategoryDrawerOpen
            ? assessmentControlPointCategoriesDrawerStrings('hideCategories')
            : assessmentControlPointCategoriesDrawerStrings('showCategories')
        }
      >
        <Fab
          color="primary"
          onClick={isCategoryDrawerOpen ? closeCategoryDrawer : openCategoryDrawer}
          sx={{
            position: 'fixed',
            bottom: 75,
            left: isCategoryDrawerOpen ? 288 : 8,
            display: {
              md: 'none',
              xs: 'inline-flex',
            },
          }}
        >
          <FormatAlignLeft />
        </Fab>
      </MobileCompatibleTooltip>
    </>
  );
};
