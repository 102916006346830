import { Box, Typography, useMediaQuery } from '@mui/material';
import { type ReactNode, useContext, useRef } from 'react';
import {
  type AssessmentControlPointAnswerListOutputItem,
  type OrganizationCultivationsAssessmentControlPointCultivationList,
  type PossibleAnswerListOutputItem,
} from 'src/__generated__/InternalApiTypes';
import { useRoleCheck } from 'src/hooks';
import { commonStrings, controlPointDropdownAnswerStrings } from 'src/languages/en-UK';
import { getCultivationDisplayNameForControlPoint } from 'src/utils/getCultivationDisplayName';

import { SelectField } from '../common/SelectField';
import { JustificationTextField } from '../ControlPointAnswer/JustificationTextField';
import {
  type AnswerChipStatus,
  ControlPointAnswerPoints,
  getComplianceStatusLabel,
  mapComplianceStatusToAnswerChip,
} from '../ControlPointAnswerPoints';
import { ThemeContext } from '../ThemeProvider';

export interface ControlPointDropdownAnswerProps {
  availablePossibleAnswers: PossibleAnswerListOutputItem[];
  existingAnswers: AssessmentControlPointAnswerListOutputItem[];
  hasPlotName: boolean;
  id: string;
  isDisabled: boolean;
  isScored: boolean;
  onSelectAnswer: (answers: string[]) => void;
  cultivation?: OrganizationCultivationsAssessmentControlPointCultivationList;
  index?: number;
  isDisableJustificationText?: boolean;
  isLast?: boolean;
  isLoadingJustificationText?: boolean;
  isMultiple?: boolean;
  isShowChip?: boolean;
  label?: ReactNode;
  onUpdateJustificationText?: (
    newValue: string,
    cId: string,
    existingJustificationText: string | null,
  ) => void;
  showTitle?: boolean;
  value?: string[];
}

export const getDropdownData = (
  existingAnswers: AssessmentControlPointAnswerListOutputItem[],
  cultivation?: OrganizationCultivationsAssessmentControlPointCultivationList,
): [AnswerChipStatus, string, string[], number] => {
  const existingItem = existingAnswers?.find(
    (e) => e.organization_cultivation_id === cultivation?.organization_cultivation_id,
  );

  let answer: AssessmentControlPointAnswerListOutputItem;
  if (cultivation) {
    if (existingItem) {
      answer = existingItem;
    }
  } else if (existingAnswers?.length > 0) {
    answer = existingAnswers[0];
  }
  const status = mapComplianceStatusToAnswerChip(answer?.compliance_status);
  const statusLabel = getComplianceStatusLabel(answer?.compliance_status_label);
  return [status, statusLabel, answer?.answer_ids ?? [], answer?.points ?? 0];
};

export const ControlPointDropdownAnswer = ({
  availablePossibleAnswers,
  cultivation,
  existingAnswers,
  id,
  index,
  onSelectAnswer,
  onUpdateJustificationText,
  value: valueProp,
  label = null,
  hasPlotName = false,
  isDisabled = false,
  isDisableJustificationText = false,
  isLoadingJustificationText = false,
  isLast = false,
  isMultiple = false,
  isScored = false,
  isShowChip = true,
  showTitle = false,
}: ControlPointDropdownAnswerProps): React.JSX.Element => {
  const { isCB } = useRoleCheck();
  const textField = useRef<HTMLInputElement>(null);
  const isJustificationRequired =
    !cultivation &&
    existingAnswers?.some((e) => {
      const matchingAnswer = availablePossibleAnswers?.find((possibleAnswer) =>
        e.answer_ids.some((a) => a === possibleAnswer?.uuid),
      );

      return matchingAnswer && matchingAnswer.is_justification_required;
    });

  const options = (availablePossibleAnswers ?? []).map((answer) => ({
    value: answer.uuid,
    label: answer.main_text,
  }));

  const [status, statusLabel, value, points] = getDropdownData(existingAnswers, cultivation);

  const getAnswerPointValue = () => points || 0;
  const { theme } = useContext(ThemeContext);
  const isSmallDesktop = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Box
      key={id}
      display="flex"
      flex={1}
      flexDirection="column"
      marginBottom={isLast || !cultivation ? 0 : 2}
    >
      {showTitle && cultivation && index === 0 && (
        <Typography
          color="text.disabled"
          marginBottom={1}
          variant="overline"
        >
          {commonStrings('answersByCultivations')}
        </Typography>
      )}
      <Box
        alignItems={{ xs: 'flex-start', lg: 'center' }}
        display="flex"
        flexDirection={{ xs: 'column', lg: 'row' }}
      >
        {cultivation ? (
          <Typography
            flex={{ xs: 1, sm: 0.3 }}
            maxWidth={isSmallDesktop ? undefined : 320}
            minWidth={isSmallDesktop ? undefined : 320}
            paddingRight={2}
            variant="body1"
          >
            {getCultivationDisplayNameForControlPoint(cultivation, hasPlotName)}
          </Typography>
        ) : (
          label
        )}
        <Box
          display="flex"
          flex={1}
          flexGrow={1}
          marginTop={isSmallDesktop ? 1 : undefined}
          paddingRight={{ xs: 0, lg: 3 }}
          width={isSmallDesktop ? '100%' : undefined}
        >
          <SelectField
            disabled={isDisabled}
            id={id}
            label={controlPointDropdownAnswerStrings('selectOption')}
            multiple={isMultiple}
            onChange={!isMultiple ? onSelectAnswer : undefined}
            onCloseMenu={isMultiple ? onSelectAnswer : undefined}
            options={options}
            showClearAll={!isDisabled}
            size="small"
            value={valueProp || value}
          />
        </Box>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          width={isSmallDesktop ? '100%' : undefined}
        >
          {isShowChip && (
            <ControlPointAnswerPoints
              showPointBox={isScored}
              status={status as AnswerChipStatus}
              statusLabel={statusLabel}
              value={getAnswerPointValue()}
            />
          )}
        </Box>
      </Box>
      {isCB && isJustificationRequired && existingAnswers?.length > 0 && (
        <JustificationTextField
          answerItem={existingAnswers[0]}
          inputRef={textField}
          isDisabled={isDisableJustificationText || isDisabled}
          isLoading={isLoadingJustificationText}
          onUpdate={(textValue, cultivationId, existingTextValue) => {
            onUpdateJustificationText(textValue, cultivationId, existingTextValue);
          }}
        />
      )}
    </Box>
  );
};
