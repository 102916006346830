import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import {
  type ModuleListOutputItem,
  type OrganizationClientIdentifierListCertificationBodyOutput,
} from 'src/__generated__/InternalApiTypes';
import { useTenantId } from 'src/hooks';
import {
  useGetCertificationBodiesListQuery,
  useGetOrganizationClientIdentifiersListQuery,
} from 'src/services/farmApi';

import { AssessmentConfigClientIdentifierForm } from '../forms/AssessmentConfigClientIdentifierForm';

interface AssessmentConfigClientIdentifiersProps {
  selectedModuleList: ModuleListOutputItem[];
}

export interface StandardClientIdentifier {
  certificationBody: OrganizationClientIdentifierListCertificationBodyOutput | null;
  clientIdentifierId: string | null;
  standardId: string;
  value: string | null;
  logo?: string;
}

export const AssessmentConfigClientIdentifiers = ({
  selectedModuleList,
}: AssessmentConfigClientIdentifiersProps): React.JSX.Element => {
  const tid = useTenantId();
  const { data: organizationClientIdentifiers, isLoading: isLoadingOrganizationClientIdentifiers } =
    useGetOrganizationClientIdentifiersListQuery(tid, { skip: !tid });
  const { data: certificationBodies, isLoading: isLoadingCertificationBodies } =
    useGetCertificationBodiesListQuery(tid, { skip: !tid });
  const certificationBodyOptions = useMemo(() => {
    if (certificationBodies) {
      return (
        certificationBodies?.map((certificationBody) => ({
          value: certificationBody.uuid,
          label: certificationBody.name,
        })) ?? []
      );
    }
    return [];
  }, [certificationBodies]);
  const [standardClientIdentifiers, setStandardClientIdentifiers] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedStandards, setSelectedStandards] = useState<
    Pick<StandardClientIdentifier, 'standardId' | 'logo'>[]
  >([]);

  useEffect(() => {
    if (selectedModuleList.length > 0) {
      const standards = selectedModuleList.map((module) => ({
        standardId: module.standard_id,
        logo: module.small_logo?.file_object, // TODO: change to standard logo after re-factoring assessment configuration page to use standards
      }));
      setSelectedStandards(standards);
    }
  }, [selectedModuleList]);

  useEffect(() => {
    if (organizationClientIdentifiers) {
      let newStandardClientIdentifiers = {};

      selectedStandards.forEach((standard) => {
        const standardClientIdentifier: StandardClientIdentifier = {
          certificationBody: null,
          clientIdentifierId: null,
          logo: standard.logo,
          standardId: standard.standardId,
          value: null,
        };
        newStandardClientIdentifiers = {
          ...newStandardClientIdentifiers,
          [standard.standardId]: standardClientIdentifier,
        };
      });

      organizationClientIdentifiers.forEach((organizationClientIdentifier) => {
        organizationClientIdentifier?.standards?.forEach((standard) => {
          const matchingStandard = selectedStandards.find(
            (selectedStandard) => selectedStandard.standardId === standard.uuid,
          );
          if (matchingStandard) {
            const standardClientIdentifier: StandardClientIdentifier = {
              ...matchingStandard,
              certificationBody: organizationClientIdentifier?.certification_body,
              clientIdentifierId: organizationClientIdentifier?.uuid,
              value: organizationClientIdentifier.value,
            };
            newStandardClientIdentifiers = {
              ...newStandardClientIdentifiers,
              [standard.uuid]: standardClientIdentifier,
            };
          }
        });
      });
      setStandardClientIdentifiers(newStandardClientIdentifiers);
    }
  }, [organizationClientIdentifiers, selectedStandards]);

  const isLoading = isLoadingOrganizationClientIdentifiers || isLoadingCertificationBodies;

  return (
    <Box marginTop={4}>
      {!isLoading &&
        certificationBodyOptions &&
        standardClientIdentifiers &&
        Object.values(standardClientIdentifiers).map(
          (standardClientIdentifier: StandardClientIdentifier) => (
            <AssessmentConfigClientIdentifierForm
              key={standardClientIdentifier.standardId}
              certificationBodyOptions={certificationBodyOptions}
              isDisabled={isDisabled}
              setIsDisabled={setIsDisabled}
              standardClientIdentifier={standardClientIdentifier}
            />
          ),
        )}
    </Box>
  );
};
