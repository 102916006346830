import {
  Box,
  Paper,
  Step,
  StepButton,
  Stepper,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { LoadingArea } from 'src/components/common';
import { ThemeContext } from 'src/components/ThemeProvider';
import { useCamundaState, useDialogState } from 'src/hooks';
import { useGetUserOrigin } from 'src/hooks/useGetUserOrigin';
import { useSetTitle } from 'src/hooks/useSetTitle';
import { stepperPageStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import { type SignUpOriginEnum } from 'src/services/farmApi/endpoints/auth';

import { stepperConfig, type StepperConfigStep } from './StepperConfig';
import { StepperFinishDialog, type StepperFinishDialogProps } from './StepperFinishDialog';
import { type StepperPageStepDef } from './StepperPage.types';

interface StepperPageProps {
  steps: StepperPageStepDef[];
}

export const StepperPage = ({ steps }: StepperPageProps): React.JSX.Element => {
  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [deeplinkParams, setDeeplinkParams] = useState(null);
  const [assessmentId] = useContext(AssessmentPageContext);
  const { changeTitle } = useSetTitle();
  const origin = useGetUserOrigin();
  const task = searchParams.get('task');

  const { currentTaskSlug, currentTaskName, currentTaskId, currentTaskIndex } =
    useCamundaState(assessmentId);

  const [isFinishDialogOpen, openFinishDialog, closeFinishDialog] =
    useDialogState<StepperFinishDialogProps>();

  const getCurrentStep = useCallback(
    () => steps?.find((step) => currentTaskSlug === step.currentTaskSlug),
    [currentTaskSlug, steps],
  );

  // Apply URL changes to UI
  useEffect((): void => {
    if (!task) {
      return;
    }
    if (task !== currentTaskSlug) {
      const currentStep = getCurrentStep();
      currentStep?.completeTask(currentTaskId, task);
    }

    // TODOHasan: Do not add currentTaskId, currentTaskSlug and getCurrentStep as a dependency.
    // Find a correct way.
  }, [location, task]);

  useEffect(() => {
    if (!deeplinkParams) {
      const baseModuleId = searchParams.get('base_m_id');
      const level1ModuleId = searchParams.get('level1_m_id');
      const level2ModuleId = searchParams.get('level2_m_id');
      const controlPointId = searchParams.get('cp_id');
      if (baseModuleId && level1ModuleId && level2ModuleId && controlPointId) {
        setDeeplinkParams({
          base_m_id: baseModuleId,
          level1_m_id: level1ModuleId,
          level2_m_id: level2ModuleId,
          cp_id: controlPointId,
        });
        const currentStep = getCurrentStep();
        if (currentTaskSlug !== 'checklist') {
          currentStep?.completeTask(currentTaskId, 'checklist');
        }
      }
    }
  }, [currentTaskId, currentTaskSlug, deeplinkParams, getCurrentStep, searchParams]);

  // Apply UI changes to URL
  useEffect((): void => {
    if (task !== currentTaskSlug) {
      if (currentTaskSlug === 'checklist') {
        if (deeplinkParams) {
          searchParams.set('base_m_id', deeplinkParams.base_m_id);
          searchParams.set('level1_m_id', deeplinkParams.level1_m_id);
          searchParams.set('level2_m_id', deeplinkParams.level2_m_id);
          searchParams.set('cp_id', deeplinkParams.cp_id);
          setDeeplinkParams(null);
        }
        searchParams.set('task', currentTaskSlug);
        searchParams.delete('tab');
        setSearchParams(searchParams);
      } else {
        navigate(
          {
            pathname: location.pathname,
            search: `?task=${currentTaskSlug}`,
          },
          { replace: !task },
        );
      }
    }
  }, [
    currentTaskSlug,
    deeplinkParams,
    location.pathname,
    navigate,
    searchParams,
    setSearchParams,
    task,
  ]);

  useEffect((): void => {
    changeTitle(currentTaskName, origin as SignUpOriginEnum);
  }, [changeTitle, currentTaskName, origin]);

  const getStepComponent = (taskSlug: string) => {
    const currentStep = getCurrentStep();

    if (!currentStep) {
      return (
        <LoadingArea
          hasAgriplaceLoadingAnimation
          size={72}
        />
      );
    }

    const stepComponentProps = {
      currentTaskId: currentStep.currentTaskId,
      nextTaskName: currentStep.nextTaskName,
      nextTaskSlug: currentStep.nextTaskSlug,
      previousTaskSlug: currentStep.previousTaskSlug,
      moveToNextTask: currentStep.moveToNextTask,
      moveToPreviousTask: currentStep.moveToPreviousTask,
      completeTask: currentStep.completeTask,
      isFinalTask: currentStep.isFinalTask,
      isFirstTask: currentStep.isFirstTask,
      openFinishDialog,
    };

    const matchingStep: StepperConfigStep = stepperConfig[taskSlug];
    if (!matchingStep) {
      return (
        <Typography alignSelf="center">{stepperPageStrings('couldNotFindThatTask')}</Typography>
      );
    }

    const StepComponent = matchingStep.component;

    return (
      <StepComponent
        {...stepComponentProps}
        {...matchingStep.additionalProps}
      />
    );
  };

  const isSmallDesktop = useMediaQuery(theme.breakpoints.down('lg'));
  const selectedStep: StepperConfigStep = stepperConfig[currentTaskSlug];
  const { hasSidebar } = selectedStep?.additionalProps || {};

  return (
    <>
      <Toolbar />
      <Paper
        sx={{
          boxShadow: 'none',
          px: { xs: 0, sm: 2 },
          py: { xs: 1, sm: 3, xl: 8 },
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          flex: 1,
          minHeight: '90%',
          backgroundColor: currentTaskIndex > 0 ? 'background.default' : 'primary.contrastText',
        }}
      >
        {!isSmallDesktop && steps?.length > 1 && (
          <Stepper
            activeStep={currentTaskIndex}
            sx={{
              mt: 1,
              mb: 5,
              display: 'flex',
              justifyContent: 'flex-start',
              width: hasSidebar ? undefined : '100%',
              maxWidth: hasSidebar ? 1392 : 1300,
              mr: hasSidebar ? 3 : 'auto',
              ml: hasSidebar ? 48 : 'auto',
            }}
          >
            {steps?.map((item) => (
              <Step key={item.currentTaskSlug}>
                <StepButton
                  color="inherit"
                  disabled
                >
                  {item.currentTaskName}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        )}
        {isSmallDesktop && <Box height={24} />}
        {getStepComponent(currentTaskSlug)}
        <StepperFinishDialog
          isOpen={isFinishDialogOpen}
          onClose={closeFinishDialog}
        />
      </Paper>
    </>
  );
};
