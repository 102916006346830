import {
  getGridStringOperators,
  type GridEnrichedColDef,
  type GridRenderCellParams,
  type GridRowsProp,
  GridToolbar,
} from '@mui/x-data-grid-pro';
import { type GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { type MutableRefObject, type ReactNode, useContext } from 'react';
import {
  type AssessmentCategoryEvidenceTypeControlPointListOutputItem,
  type AssessmentControlPointListOutputItem,
} from 'src/__generated__/InternalApiTypes';
import {
  CustomNoRowsOverlay,
  MobileCompatibleDataGrid,
} from 'src/components/common/MobileCompatibleDataGrid';
import { EvidenceAttachToggle } from 'src/components/EvidenceAttachToggle';
import { useTenantId } from 'src/hooks';
import { commonStrings, evidenceControlPointTableStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import { useGetAssessmentControlPointEvidenceListQuery } from 'src/services/farmApi';
import { type TransformedEvidenceDetailOutput } from 'src/services/farmApi';

interface EvidenceControlPointTableProps {
  controlPointList: AssessmentCategoryEvidenceTypeControlPointListOutputItem[];
  evidence: TransformedEvidenceDetailOutput | undefined;
  isControlPointListLoading: boolean;
  isControlPointListFetching: boolean;
  tableRef: MutableRefObject<GridApiPro>;
}

export const EvidenceControlPointTable = ({
  controlPointList,
  evidence,
  tableRef,
  isControlPointListLoading = false,
  isControlPointListFetching = false,
}: EvidenceControlPointTableProps) => {
  const tid = useTenantId();
  const [assessmentId] = useContext(AssessmentPageContext);

  const {
    data: assessmentControlPointEvidenceList,
    isLoading: isAssessmentControlPointEvidenceListLoading,
  } = useGetAssessmentControlPointEvidenceListQuery(
    {
      tid,
      assessment_id: [assessmentId],
    },
    { skip: !tid },
  );

  const rows: GridRowsProp = (controlPointList || []).map((controlPoint) => ({
    uuid: controlPoint.uuid,
    displayCode: controlPoint.display_code,
    mainText: controlPoint.main_text,
  }));

  const attachedList = evidence?.farmer_control_point_ids || [];

  const sortedRows =
    attachedList?.length > 0
      ? [...rows]?.sort((a, b) => attachedList.indexOf(b.uuid) - attachedList.indexOf(a.uuid))
      : rows;

  const handlePageChange = () => {
    tableRef.current?.scroll?.({ left: 0, top: 0 });
  };

  const columns: GridEnrichedColDef[] = [
    {
      field: 'displayCode',
      headerName: evidenceControlPointTableStrings('cp'),
      filterable: true,
      filterOperators: getGridStringOperators().filter(({ value }) =>
        ['contains', 'isAnyOf'].includes(value),
      ),
      flex: 1,
    },
    {
      field: 'mainText',
      headerName: evidenceControlPointTableStrings('description'),
      filterable: true,
      filterOperators: getGridStringOperators().filter(({ value }) =>
        ['contains', 'isAnyOf'].includes(value),
      ),
      flex: 4,
    },
    {
      field: 'attachmentCount',
      headerName: commonStrings('attach'),
      hideable: false,
      disableColumnMenu: true,
      filterable: false,
      width: 100,
      renderCell: (
        params: GridRenderCellParams<string, AssessmentControlPointListOutputItem, ReactNode>,
      ) => {
        const evidenceLinkId = (assessmentControlPointEvidenceList || []).find(
          (e) => e.control_point_id === params.row.uuid && e.evidence_id === evidence?.uuid,
        )?.uuid;
        return (
          <EvidenceAttachToggle
            attachedList={attachedList}
            controlPointId={params.row.uuid}
            evidence={evidence}
            evidenceLinkId={evidenceLinkId}
            tooltipText={
              attachedList.includes(params.row.uuid)
                ? evidenceControlPointTableStrings('evidenceWasAttachedTooltip')
                : ''
            }
            variant="switch"
          />
        );
      },
    },
  ];

  return (
    <MobileCompatibleDataGrid
      apiRef={tableRef}
      columns={columns}
      components={{
        NoRowsOverlay: () =>
          CustomNoRowsOverlay(evidenceControlPointTableStrings('noControlPointsToDisplay')),
        Toolbar: GridToolbar,
      }}
      componentsProps={{
        toolbar: {
          csvOptions: { disableToolbarButton: true },
          printOptions: { disableToolbarButton: true },
        },
      }}
      disableColumnSelector
      loading={
        isControlPointListLoading ||
        isControlPointListFetching ||
        isAssessmentControlPointEvidenceListLoading
      }
      onPageChange={handlePageChange}
      rows={sortedRows}
      sx={{ mx: 1 }}
    />
  );
};
