import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StepperPage } from 'src/components/assessmentsteps/StepperPage';
import { LoadingArea } from 'src/components/common';
import { useAppDispatch, useCamundaState } from 'src/hooks';
import { assessmentConfigActions } from 'src/store/assessmentConfig';

import { AssessmentPageContextProvider } from './AssessmentPage.context';

export const AssessmentPage = (): React.JSX.Element => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [assessmentId, setAssessmentId] = useState<string | null>(id || null);

  useEffect(() => {
    dispatch(assessmentConfigActions.reset());
    if (id !== assessmentId) {
      setAssessmentId(id || null);
    }
  }, [assessmentId, dispatch, id]);

  const {
    isCamundaStateLoading,
    currentTaskId,
    nextTaskName,
    nextTaskSlug,
    previousTaskSlug,
    processInstanceId,
    taskDefinitionList,
    completeCurrentTask,
    moveToNextTask,
    moveToPreviousTask,
    isFinalTask,
    isFirstTask,
  } = useCamundaState(assessmentId);

  const steps = taskDefinitionList?.map((taskDefinition) => ({
    currentTaskId,
    nextTaskName,
    nextTaskSlug,
    previousTaskSlug,
    isFinalTask,
    isFirstTask,
    currentTaskSlug: taskDefinition.slug,
    currentTaskName: taskDefinition.name,
    moveToNextTask: () => moveToNextTask(),
    moveToPreviousTask: () => moveToPreviousTask(),
    completeTask: (taskId: string, newTaskSlug: string) => completeCurrentTask(taskId, newTaskSlug),
  }));

  return (
    <AssessmentPageContextProvider value={[assessmentId, setAssessmentId]}>
      {isCamundaStateLoading && (
        <LoadingArea
          hasAgriplaceLoadingAnimation
          size={72}
        />
      )}
      {!isCamundaStateLoading && processInstanceId && <StepperPage steps={steps} />}
    </AssessmentPageContextProvider>
  );
};
