import { CloseRounded, LinkOffRounded, LinkRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Skeleton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { type GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  type AssessmentCategoryEvidenceTypeControlPointListOutputItem,
  RolesEnum,
} from 'src/__generated__/InternalApiTypes';
import { LoadingArea } from 'src/components/common';
import { DocumentPreview } from 'src/components/common/DocumentPreview';
import { ScrollPanel } from 'src/components/common/ScrollPanel';
import { EvidenceReviewControlPoint } from 'src/components/EvidenceReviewControlPoint';
import { useCurrentUserRoles, useMutationFeedback, useTenantId } from 'src/hooks';
import { commonStrings, evidenceReviewDrawerStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import {
  useCreateBulkAssessmentControlPointEvidenceMutation,
  useDeleteBulkAssessmentControlPointEvidenceMutation,
  useGetAssessmentCategoryEvidenceTypeControlPointListQuery,
  useGetAssessmentControlPointEvidenceListQuery,
  useGetEvidenceQuery,
} from 'src/services/farmApi';

import { EvidenceControlPointTable } from './EvidenceControlPointTable';

export enum ControlPointPresentationVariants {
  STACK = 'stack',
  DATATABLE = 'datatable',
}

export interface EvidenceReviewDialogProps {
  evidenceTypeId?: string | null;
}

export interface EvidenceReviewDrawerProps extends EvidenceReviewDialogProps {
  evidenceId: string;
  isDisableNextButton: boolean;
  isOpen: boolean;
  onClose: () => void;
  onNext: () => void;
  assessmentAgriplaceCriticalityIds?: string[];
  categoryId?: string;
  categoryName?: string;
  hasAuditorAssessmentId?: boolean;
  variant?: ControlPointPresentationVariants;
}

export const EvidenceReviewDrawer = ({
  assessmentAgriplaceCriticalityIds,
  categoryId,
  categoryName,
  evidenceId,
  evidenceTypeId,
  onClose,
  onNext,
  hasAuditorAssessmentId = false,
  isDisableNextButton = false,
  isOpen = false,
  variant = ControlPointPresentationVariants.STACK,
}: EvidenceReviewDrawerProps): React.JSX.Element => {
  const tid = useTenantId();
  const currentUserRoles = useCurrentUserRoles();
  const isAuditor = currentUserRoles.includes(RolesEnum.Auditor);
  const [assessmentId] = useContext(AssessmentPageContext);
  const [skipPolling, setSkipPolling] = useState(false);
  const tableRef = useRef<GridApiPro>();
  const { data: evidence, isFetching: isGetEvidenceFetching } = useGetEvidenceQuery(
    {
      tid,
      id: evidenceId,
      ...(hasAuditorAssessmentId && {
        auditor_assessment_id: assessmentId,
      }),
    },
    {
      pollingInterval: 1000,
      skip: skipPolling || !evidenceId || !tid,
    },
  );

  useEffect(() => {
    if (evidence && evidence.uuid === evidenceId) {
      const shouldSkipPolling =
        !evidence?.is_edited_online ||
        !!(evidence?.is_edited_online && evidence?.is_saved_by_webhook);

      setSkipPolling(shouldSkipPolling);
    } else {
      setSkipPolling(false);
    }
  }, [evidence, evidenceId]);

  const [filteredAssessmentControlPoints, setFilteredAssessmentControlPoints] = useState<
    AssessmentCategoryEvidenceTypeControlPointListOutputItem[]
  >([]);

  const {
    data: assessmentControlPointList,
    isLoading: isAssessmentControlPointListLoading,
    isFetching: isAssessmentControlPointListFetching,
  } = useGetAssessmentCategoryEvidenceTypeControlPointListQuery(
    {
      tid,
      assessment_id: assessmentId,
      evidence_type_id: evidenceTypeId,
      ...(categoryId && { category_id: categoryId }),
      ...(assessmentAgriplaceCriticalityIds && {
        agriplace_criticality_id: assessmentAgriplaceCriticalityIds,
      }),
      page_size: 1000,
    },
    {
      skip: (!isAuditor && !evidenceTypeId && !evidence?.evidence_type_id) || !assessmentId || !tid,
    },
  );

  const [createBulkControlPointEvidence, createBulkControlPointEvidenceResult] =
    useCreateBulkAssessmentControlPointEvidenceMutation();
  const [deleteBulkControlPointEvidence, deleteBulkControlPointEvidenceResult] =
    useDeleteBulkAssessmentControlPointEvidenceMutation();
  const controlPointIds = filteredAssessmentControlPoints?.map((cp) => cp.uuid);
  const { controlPointIdsWithEvidence, evidenceLinkIds } =
    useGetAssessmentControlPointEvidenceListQuery(
      {
        tid,
        assessment_id: [assessmentId],
        control_point_id: controlPointIds,
      },
      {
        skip: !controlPointIds || !assessmentId || !tid,
        selectFromResult: ({ data, isLoading, isFetching }) => {
          const evidenceLinks = (data || []).filter(
            (assessmentControlPointEvidence) =>
              assessmentControlPointEvidence.evidence_id === evidenceId &&
              controlPointIds.includes(assessmentControlPointEvidence.control_point_id),
          );
          return {
            evidenceLinkIds: evidenceLinks.map((e) => e.uuid),
            controlPointIdsWithEvidence: evidenceLinks.map((e) => e.control_point_id),
            isLoading,
            isFetching,
          };
        },
      },
    );

  const handleBulkEvidenceAttach = () => {
    if (tid && assessmentId) {
      createBulkControlPointEvidence({
        tid,
        body: {
          assessment_id: assessmentId,
          control_point_ids: controlPointIds?.filter(
            (cp) => !controlPointIdsWithEvidence.includes(cp),
          ),
          evidence_id: evidenceId,
        },
      });
    }
  };

  const handleBulkEvidenceDetach = () => {
    if (tid) {
      deleteBulkControlPointEvidence({
        tid,
        body: { assessment_control_point_evidence_ids: evidenceLinkIds },
      });
    }
  };

  useMutationFeedback({
    result: createBulkControlPointEvidenceResult,
    successMessage: commonStrings('bulkAttachEvidenceSuccess'),
    errorMessage: commonStrings('bulkAttachEvidenceFail'),
  });

  useMutationFeedback({
    result: deleteBulkControlPointEvidenceResult,
    successMessage: evidenceReviewDrawerStrings('bulkDetachEvidenceSuccess'),
    errorMessage: evidenceReviewDrawerStrings('bulkDetachEvidenceFail'),
  });

  useEffect(() => {
    if (!isAssessmentControlPointListLoading && assessmentControlPointList?.results) {
      setFilteredAssessmentControlPoints(assessmentControlPointList.results);
    }
  }, [
    assessmentControlPointList,
    categoryId,
    evidence?.evidence_type_id,
    isAssessmentControlPointListLoading,
  ]);

  const panelStyle = {
    height: { lg: '100%', xs: '50%' },
    maxWidth: { lg: '50%', xs: '100%' },
    maxHeight: { lg: '100%', xs: '50%' },
    flex: '1 auto',
  };

  const isLoadingEvidence =
    isGetEvidenceFetching || !evidence || Boolean(!categoryName && categoryId) || !skipPolling;

  const isLoadingControlPoints =
    isAssessmentControlPointListLoading || isAssessmentControlPointListFetching;

  return (
    <Drawer
      anchor="right"
      onClose={onClose}
      open={isOpen}
      sx={{
        zIndex: 1200,
        width: { xs: 'calc(100vw - 32px)', sm: 'calc(100vw - 96px)' },
        '& .MuiDrawer-paper': {
          width: { xs: 'calc(100vw - 32px)', sm: 'calc(100vw - 96px)' },
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          px: 2,
          maxHeight: '100vh',
        },
      }}
    >
      <>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            padding: 1.5,
          }}
        >
          <CloseRounded />
        </IconButton>
        <Box marginY={3}>
          <Typography variant="h1">
            {isLoadingEvidence ? (
              <Skeleton
                animation="wave"
                variant="text"
                width="50%"
              />
            ) : (
              <Typography
                component="span"
                variant="h4"
              >
                {evidenceReviewDrawerStrings('previewEvidenceAndManageAttachments', {
                  evidenceName: evidence?.name,
                })}
              </Typography>
            )}
          </Typography>
        </Box>
        <Box
          display="flex"
          flex="1 auto"
          flexDirection={{ xs: 'column', lg: 'row' }}
          gap={2}
          overflow="hidden"
        >
          {isLoadingControlPoints ? (
            <Box width="50%">
              <Typography variant="h2">
                <Skeleton
                  animation="wave"
                  variant="text"
                  width="80%"
                />
              </Typography>
              <LoadingArea />
            </Box>
          ) : (
            <Stack
              spacing={2}
              sx={panelStyle}
            >
              {isAuditor && (
                <Typography
                  marginTop={1}
                  variant="body1"
                >
                  {evidenceReviewDrawerStrings('reviewTheEvidenceAndControlPoints')}
                </Typography>
              )}
              {!isAuditor &&
                filteredAssessmentControlPoints &&
                !isLoadingEvidence &&
                !isLoadingControlPoints && (
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <LoadingButton
                      color="error"
                      disabled={!evidenceLinkIds || evidenceLinkIds?.length === 0}
                      loading={deleteBulkControlPointEvidenceResult?.isLoading}
                      onClick={handleBulkEvidenceDetach}
                      size="small"
                      startIcon={<LinkOffRounded />}
                      sx={{ mr: 2 }}
                      variant="outlined"
                    >
                      {evidenceReviewDrawerStrings('detachFromAll')}
                    </LoadingButton>
                    <LoadingButton
                      color="info"
                      disabled={evidenceLinkIds?.length === filteredAssessmentControlPoints?.length}
                      loading={createBulkControlPointEvidenceResult?.isLoading}
                      onClick={handleBulkEvidenceAttach}
                      size="small"
                      startIcon={<LinkRounded />}
                      variant="contained"
                    >
                      {commonStrings('attachToAll')}
                    </LoadingButton>
                  </Box>
                )}
              {variant === ControlPointPresentationVariants.STACK && (
                <ScrollPanel sx={{ flex: '1 auto' }}>
                  <Stack spacing={2}>
                    {isAssessmentControlPointListLoading ? (
                      <EvidenceReviewControlPoint />
                    ) : (
                      filteredAssessmentControlPoints?.map((assessmentControlPoint) => (
                        <EvidenceReviewControlPoint
                          key={assessmentControlPoint?.uuid}
                          controlPointId={assessmentControlPoint?.uuid}
                          evidenceId={evidence?.uuid}
                          evidenceName={evidence?.name}
                        />
                      ))
                    )}
                  </Stack>
                </ScrollPanel>
              )}
              {variant === ControlPointPresentationVariants.DATATABLE && (
                <EvidenceControlPointTable
                  controlPointList={filteredAssessmentControlPoints}
                  evidence={evidence}
                  isControlPointListFetching={isAssessmentControlPointListFetching}
                  isControlPointListLoading={isAssessmentControlPointListLoading}
                  tableRef={tableRef}
                />
              )}
            </Stack>
          )}
          {isLoadingEvidence ? (
            <Box width="50%">
              <LoadingArea />
            </Box>
          ) : (
            <DocumentPreview
              id={evidence?.file?.uuid}
              sx={panelStyle}
              title={evidence?.name}
              url={evidence?.file?.file_object}
            />
          )}
        </Box>
        <Toolbar
          disableGutters
          sx={{ justifyContent: 'flex-end', gap: 2 }}
        >
          <Button
            disabled={isDisableNextButton}
            onClick={() => {
              tableRef.current?.setPage(0);
              onNext();
            }}
            variant="outlined"
          >
            {isAuditor
              ? evidenceReviewDrawerStrings('nextEvidence')
              : evidenceReviewDrawerStrings('nextDocument')}
          </Button>
          <Button
            onClick={onClose}
            variant="contained"
          >
            {commonStrings('complete')}
          </Button>
        </Toolbar>
      </>
    </Drawer>
  );
};
