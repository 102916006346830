import {
  AddBoxOutlined,
  ArrowForward,
  IosShareRounded,
  MoreVertRounded,
} from '@mui/icons-material';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Logo } from 'src/components/common/AppLogo';
import { ThemeContext } from 'src/components/ThemeProvider';
import { useGetUserOrigin } from 'src/hooks/useGetUserOrigin';
import { commonStrings, splashPageStrings } from 'src/languages/en-UK';
import { SignUpOriginEnum } from 'src/services/farmApi/endpoints/auth';
import { ROUTE_PATH_LOG_IN } from 'src/settings';
import { leafTheme, smkTheme, theme } from 'src/theme';

import { getMobileOperatingSystem } from './SplashPage.utils';

const renderAndroidSplash = () => (
  <Stack
    alignItems="flex-start"
    marginBottom={6.25}
    spacing={0.5}
  >
    <Typography variant="body1">
      {splashPageStrings('androidStep1')}
      <MoreVertRounded
        fontSize="small"
        sx={{ color: '#007AFF', mb: -0.5 }}
      />
      {splashPageStrings('button')}
    </Typography>
    <Typography variant="body1">{splashPageStrings('androidStep2')}</Typography>
    <Typography variant="body1">{splashPageStrings('androidStep3')}</Typography>
  </Stack>
);

const renderIosSplash = () => (
  <Stack
    alignItems="flex-start"
    marginBottom={6.25}
    spacing={0.5}
  >
    <Typography variant="body1">
      {splashPageStrings('iosStep1')}
      <IosShareRounded
        fontSize="small"
        sx={{ color: '#007AFF', mx: 0.25, mb: -0.25 }}
      />
    </Typography>
    <Typography variant="body1">
      {splashPageStrings('iosStep2')}
      <AddBoxOutlined
        fontSize="small"
        sx={{ color: '#007AFF', mx: 0.25, mb: -0.5 }}
      />
    </Typography>
    <Typography
      component="span"
      variant="body1"
    >
      {splashPageStrings('iosStep3')}
      <Typography
        component="span"
        marginX={0.5}
        sx={{ color: '#007AFF' }}
        variant="body1"
      >
        {commonStrings('add')}
      </Typography>
      {`${splashPageStrings('button')}.`}
    </Typography>
  </Stack>
);

export const SplashPage = (): React.JSX.Element => {
  const navigate = useNavigate();
  const [, userOS] = getMobileOperatingSystem();
  const origin = useGetUserOrigin();
  const { changeTheme } = useContext(ThemeContext);
  useEffect(() => {
    let selectedTheme;
    switch (origin) {
      case SignUpOriginEnum.SMK:
        selectedTheme = smkTheme;
        break;
      case SignUpOriginEnum.LEAF:
        selectedTheme = leafTheme;
        break;
      default:
        selectedTheme = theme;
        break;
    }
    changeTheme?.(selectedTheme);
  }, [changeTheme, origin]);
  return (
    <Container
      maxWidth="sm"
      sx={{ height: '100%', display: 'flex', alignItems: 'center' }}
    >
      <Box
        border={1}
        borderColor="divider"
        borderRadius={1.5}
        paddingX={1}
        paddingY={2}
      >
        <Box
          display="flex"
          justifyContent="center"
          paddingBottom={7.25}
        >
          <Logo size={170} />
        </Box>
        <Typography
          align="center"
          borderBottom={1}
          borderColor="divider"
          marginBottom={2}
          paddingBottom={3}
          variant="h2"
        >
          {splashPageStrings('addWebAppToHomeScreen')}
        </Typography>
        {userOS === 'Android' ? renderAndroidSplash() : renderIosSplash()}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <Button
            color="primary"
            endIcon={<ArrowForward />}
            onClick={() => {
              navigate(origin ? `${ROUTE_PATH_LOG_IN}?origin=${origin}` : ROUTE_PATH_LOG_IN);
            }}
            variant="contained"
          >
            {splashPageStrings('goToLoginPage')}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};
