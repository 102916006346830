import { CloseRounded } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {
  type UseMutation,
  type UseMutationStateResult,
} from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useState } from 'react';
import { type AssessmentUserPermissionsOutput } from 'src/__generated__/InternalApiTypes';
import { LoadingArea } from 'src/components/common';
import {
  useAppDispatch,
  useBulkMutation,
  useGetAssessmentUserPermission,
  useMutationFeedback,
} from 'src/hooks';
import { type ResultType } from 'src/hooks/useMutationFeedback';
import { commonStrings, genericDeleteDialogStrings } from 'src/languages/en-UK';
import { openSnackbar } from 'src/store/snackbarSlice';

export interface GenericDeleteDialogProps {
  // Since it is dynamic, it must be any type.
  deleteMutation: UseMutation<any>;
  isOpen: boolean;
  onClose: () => void;
  additionalReqParams?: Object;
  customErrorCode?: number;
  customErrorMessage?: string;
  entities?: {
    header: string;
    name: string;
    uuid: string | undefined;
    assessmentId?: string;
    permissionType?: keyof AssessmentUserPermissionsOutput;
  }[];
}

export const GenericDeleteDialog = ({
  additionalReqParams,
  customErrorCode,
  customErrorMessage,
  deleteMutation,
  onClose,
  entities = [],
  isOpen = false,
}: GenericDeleteDialogProps): React.JSX.Element => {
  const dispatch = useAppDispatch();
  const { assessmentId, permissionType } = entities?.[0] || {};
  const permissions = useGetAssessmentUserPermission(assessmentId);
  // Since it is dynamic, it must be any type.
  const [lastMutationResult, setLastMutationResult] = useState<
    (UseMutationStateResult<any, ResultType> & { entityName: string }) | null
  >(null);

  const { enqueueIds, isLoading } = useBulkMutation(
    deleteMutation,
    onClose,
    // Since it is dynamic, it must be any type.
    (result: UseMutationStateResult<any, any>) => {
      setLastMutationResult({
        ...result,
        entityName:
          entities.find((entity) => entity.uuid === result.originalArgs)?.name ||
          genericDeleteDialogStrings('unknownEntity'),
      });
    },
    additionalReqParams,
  );

  const handleDelete = (): void => {
    if (assessmentId && permissionType) {
      if (permissions?.[permissionType]?.delete) {
        enqueueIds(entities.map((entity) => entity.uuid));
      } else {
        dispatch(
          openSnackbar({
            message: commonStrings('notAuthorized'),
            severity: 'error',
          }),
        );
      }
    } else {
      enqueueIds(entities.map((entity) => entity.uuid));
    }
  };
  const handleClose = (): void => {
    onClose();
  };

  const entityNames: string[] = entities.map((entity) => entity.name);

  const buildErrorMessage = (
    mutationResult:
      | (ResultType & { originalArgs?: unknown; reset: () => void } & {
          entityName: string;
        })
      | null,
  ): string => {
    if (
      customErrorMessage &&
      (!customErrorCode || mutationResult?.error?.status === customErrorCode)
    ) {
      return customErrorMessage;
    }

    return commonStrings('backendDeleteFailMessage', { name: entityNames.join(', ') });
  };

  useMutationFeedback({
    result: lastMutationResult,
    errorMessage: buildErrorMessage(lastMutationResult),
    onSuccess: handleClose,
    successMessage: genericDeleteDialogStrings('deleteEntitySuccess', {
      entityNames: entityNames.join(', '),
      header: entities?.[0]?.header,
    }),
  });

  if (entities.length < 1) {
    return (
      <Dialog
        fullWidth
        onClose={handleClose}
        open={isOpen}
      >
        <LoadingArea />
      </Dialog>
    );
  }

  const titleLabel =
    entities.length > 1 ? genericDeleteDialogStrings('multipleItems') : entities[0]?.name;

  return (
    <Dialog
      fullWidth
      onClose={handleClose}
      open={isOpen}
    >
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          padding: 1.5,
        }}
      >
        <CloseRounded />
      </IconButton>
      <DialogTitle>
        {genericDeleteDialogStrings('deleteDialogHeaderDescription', {
          name: entities?.[0]?.header?.toLowerCase?.() || titleLabel?.toLowerCase?.(),
        })}
      </DialogTitle>
      <DialogContent sx={{ mb: 5 }}>
        {entities.length > 1 ? (
          <>
            {genericDeleteDialogStrings('notPossibleToRecoverItGeneric')}
            <ul>
              {entityNames.map((name, i) => (
                <li key={`${i}_delete-title`}>{name}</li>
              ))}
            </ul>
          </>
        ) : (
          genericDeleteDialogStrings('notPossibleToRecoverItMsg', {
            entityName: entityNames[0],
            header: entities?.[0]?.header?.toLowerCase?.() || titleLabel?.toLowerCase?.(),
          })
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isLoading}
          onClick={handleClose}
          sx={{ mr: 2 }}
          variant="outlined"
        >
          {commonStrings('cancel')}
        </Button>
        <LoadingButton
          color="error"
          loading={isLoading}
          onClick={handleDelete}
          variant="contained"
        >
          {commonStrings('delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
