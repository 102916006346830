import { type FormikValues } from 'formik';
import { useMemo } from 'react';
import { type OrganizationPlotCultivationUpdateOutput } from 'src/__generated__/InternalApiTypes';
import { FormikDialog } from 'src/components/formikcomponents/FormikDialog';
import { FormikProductField } from 'src/components/formikcomponents/FormikProductField';
import { FormikProductionTechniqueField } from 'src/components/formikcomponents/FormikProductionTechniqueField';
import { FormikTextField } from 'src/components/formikcomponents/FormikTextField';
import { useTenantId } from 'src/hooks';
import { commonStrings } from 'src/languages/en-UK';
import {
  useCreateOrganizationPlotCultivationMutation,
  useGetModuleListQuery,
  useGetOrganizationPlotCultivationQuery,
  useGetSubscriptionListQuery,
  useUpdateOrganizationPlotCultivationMutation,
} from 'src/services/farmApi';
import { getCultivationDisplayName } from 'src/utils/getCultivationDisplayName';
import * as Yup from 'yup';

export interface OrganizationCultivationEditDialogProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  cultivationId?: string;
}

export const OrganizationCultivationEditDialog = ({
  cultivationId,
  onClose,
  title,
  isOpen = false,
}: OrganizationCultivationEditDialogProps): React.JSX.Element => {
  const tid = useTenantId();
  const defaultTitle = commonStrings('createOrEditCultivation');

  // Get the module list for filtering products
  const { data: moduleList } = useGetModuleListQuery(
    {
      has_parent: false,
    },
    { skip: !tid },
  );
  const { data: subscriptions } = useGetSubscriptionListQuery(
    { tid, status: ['active'] },
    { skip: !tid },
  );

  const paidModuleList = useMemo(
    () =>
      moduleList?.filter((standard) =>
        subscriptions?.some((sub) =>
          sub.subscription_plan_standard_ids.includes(standard.standard_id),
        ),
      ),
    [moduleList, subscriptions],
  );

  const moduleListStandardIds = useMemo(
    () => paidModuleList?.map((module) => module.standard_id),
    [paidModuleList],
  );

  const getFormikBodyMap = (values: FormikValues) => ({
    ...values,
    organization_plot_area: values.organization_plot_area
      ? parseFloat(values.organization_plot_area.toString().replace(',', '.'))
      : 0,
  });

  const getFormikPropertyName = (result: OrganizationPlotCultivationUpdateOutput) => {
    if (result) {
      return getCultivationDisplayName(result);
    }
    return '';
  };

  return (
    <FormikDialog
      createHook={useCreateOrganizationPlotCultivationMutation}
      entityId={cultivationId}
      fetchHook={useGetOrganizationPlotCultivationQuery}
      fields={[
        {
          id: 'product_id',
          getValue: (selectedItem) => selectedItem?.product?.uuid,
          renderAs: (props) => (
            <FormikProductField
              {...props}
              required
              standardIds={moduleListStandardIds}
            />
          ),
          validator: Yup.string().uuid().required(commonStrings('required')),
        },
        {
          id: 'production_technique_id',
          getValue: (selectedItem) => selectedItem?.production_technique?.uuid,
          renderAs: (props) => (
            <FormikProductionTechniqueField
              {...props}
              label={commonStrings('cultivationType')}
              required
            />
          ),
          validator: Yup.string().required(commonStrings('required')),
        },
        {
          id: 'organization_plot_name',
          getValue: (selectedItem) => selectedItem?.organization_plot?.name,
          renderAs: (props) => (
            <FormikTextField
              {...props}
              label={commonStrings('plotLabel')}
              required
            />
          ),
          validator: Yup.string().required(commonStrings('required')),
        },
        {
          id: 'organization_plot_area',
          getValue: (selectedItem) => selectedItem?.organization_plot?.area,
          renderAs: (props) => (
            <FormikTextField
              {...props}
              label={commonStrings('plotSurfaceArea')}
              onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
              type="number"
            />
          ),
        },
      ]}
      getBodyMap={getFormikBodyMap}
      getPropertyName={getFormikPropertyName}
      isOpen={isOpen}
      onClose={onClose}
      onError={onClose}
      propertyName="product_id"
      submitButtonText={commonStrings('save')}
      title={title || defaultTitle}
      updateHook={useUpdateOrganizationPlotCultivationMutation}
    />
  );
};
