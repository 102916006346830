import { Box, Link as MuiLink, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { useDialogState, useGetEvidenceControlPointDetail } from 'src/hooks';
import { commonStrings, evidenceReviewControlPointStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import { appColors } from 'src/theme';
import { parseHtml } from 'src/utils/parseHtml';

import { HeaderChip } from '../common/HeaderChip';
import { SkeletonMultiLineText } from '../common/SkeletonMultiLineText';
import { ControlPointGuidanceDialog } from '../dialogs/ControlPointGuidanceDialog';
import { EvidenceAttachToggle } from '../EvidenceAttachToggle';

interface EvidenceReviewControlPointProps {
  controlPointId?: string;
  evidenceId?: string;
  evidenceName?: string;
}

export const EvidenceReviewControlPoint = ({
  controlPointId,
  evidenceId,
  evidenceName,
}: EvidenceReviewControlPointProps): React.JSX.Element => {
  const [assessmentId] = useContext(AssessmentPageContext);
  const {
    controlPointCode,
    controlPointCriteria,
    controlPointCriticalityList,
    controlPointDisplayCode,
    criticalityId,
    evidenceLinkId,
    smallLogo,
    isLoading,
    mainText,
  } = useGetEvidenceControlPointDetail(controlPointId, assessmentId, evidenceId);

  const [isGuidanceDialogOpen, openGuidanceDialog, closeGuidanceDialog] = useDialogState();

  const getChipColor = (priority: number | undefined) => {
    switch (priority) {
      case 1:
        return 'error';
      case 2:
        return 'warning';
      case 3:
        return 'success';
      default:
        return 'default';
    }
  };

  const criticality = (controlPointCriticalityList || []).find(
    (item) => item.uuid === criticalityId,
  );

  return (
    <Paper sx={{ p: 2, position: 'sticky' }}>
      <Stack position="sticky">
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={1}
        >
          {isLoading && (!controlPointDisplayCode || !controlPointCode) ? (
            <Skeleton
              height={24}
              variant="rounded"
              width="33%"
            />
          ) : (
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              gap={0.5}
            >
              {smallLogo && (
                <img
                  alt={`list item: ${smallLogo?.name}`}
                  src={smallLogo?.file_object}
                  style={{
                    height: '20px',
                    width: '20px',
                  }}
                />
              )}
              <Typography variant="body1">{`Question ${
                controlPointDisplayCode || controlPointCode
              }`}</Typography>
            </Box>
          )}
          <HeaderChip
            chipColor={getChipColor(criticality?.priority)}
            isLoading={isLoading}
            label={criticality?.name}
            tooltipText={commonStrings('criticality')}
          />
        </Stack>
        {isLoading ? (
          <SkeletonMultiLineText variant="body2" />
        ) : (
          <Typography
            component="span"
            marginY={2}
            variant="body2"
          >
            {mainText ? parseHtml(mainText) : ''}
          </Typography>
        )}
        {controlPointCriteria !== null && (
          <Box marginBottom={2}>
            <Typography
              color="textSecondary"
              component="p"
              marginBottom={0.5}
              variant="overline"
            >
              {commonStrings('criteria')}
            </Typography>
            <Typography
              component="span"
              variant="body2"
            >
              {controlPointCriteria ? parseHtml(controlPointCriteria) : ''}
            </Typography>
          </Box>
        )}
        <MuiLink
          onClick={openGuidanceDialog}
          sx={{
            cursor: 'pointer',
            color: appColors.blue,
            mb: 2,
          }}
          variant="caption"
        >
          {evidenceReviewControlPointStrings('readMore')}
        </MuiLink>
        {isLoading ? (
          <Skeleton
            height={36}
            sx={{ ml: 'auto', alignSelf: 'flex-end' }}
            variant="rounded"
            width={120}
          />
        ) : (
          <EvidenceAttachToggle
            controlPointId={controlPointId}
            evidence={{ uuid: evidenceId, name: evidenceName }}
            evidenceLinkId={evidenceLinkId}
          />
        )}
        <ControlPointGuidanceDialog
          controlPointId={controlPointId}
          isOpen={isGuidanceDialogOpen}
          onClose={closeGuidanceDialog}
        />
      </Stack>
    </Paper>
  );
};
