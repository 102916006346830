import { isEqual } from 'lodash';
import { type MatrixSelection } from 'src/store/assessmentConfig/types';

export const STANDARD_SELECTION_GLOBAL = 'GLOBAL';

// Get malus points for each cultivations
export const getMalusPoint = (
  selections: MatrixSelection[],
  activeTabId: string,
  id: string,
): number | undefined => {
  const selectedItem = selections.filter(
    ({ colId, rowId }) => rowId === id && colId === activeTabId,
  );
  if (selectedItem.length > 0) {
    return selectedItem[0].malusPoint;
  }
  return undefined;
};

// To understand which cultivation is selected for which standard
export const getIsChecked = (
  selections: MatrixSelection[],
  colId: string,
  rowId: string,
): boolean => selections.some((s) => isEqual({ colId: s.colId, rowId: s.rowId }, { rowId, colId }));
