import { Box, Paper, Skeleton, type SxProps, Typography } from '@mui/material';

interface DashboardBlockWrapperProps {
  children: React.JSX.Element | React.JSX.Element[];
  title: string;
  caption?: string;
  isLoading?: boolean;
  sx?: SxProps;
}

export const DashboardBlockWrapper = ({
  children,
  sx,
  title,
  caption = '',
  isLoading = false,
}: DashboardBlockWrapperProps): React.JSX.Element => (
  <Paper
    sx={{
      flex: 1,
      flexGrow: 1,
      px: 2,
      pt: 2,
      pb: 1,
      ...sx,
    }}
  >
    <Box
      borderBottom={1}
      borderColor="divider"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      marginBottom={2}
      paddingBottom={caption ? 1 : 3.5}
    >
      {isLoading ? (
        <Skeleton
          animation="pulse"
          height={32}
          sx={{ flex: '0.2 auto' }}
          variant="rounded"
        />
      ) : (
        <>
          <Typography
            align="center"
            variant="h2"
          >
            {title}
          </Typography>
          {caption && (
            <Typography
              align="center"
              color="textSecondary"
              variant="caption"
            >
              {caption}
            </Typography>
          )}
        </>
      )}
    </Box>
    {children}
  </Paper>
);
