import { RolesEnum } from 'src/__generated__/InternalApiTypes';
import { useTenantId, useUserAuth } from 'src/hooks';
import { useGetOrganizationMemberListQuery } from 'src/services/farmApi';

import { OrganizationInvitationTable } from '../tables/OrganizationInvitationTable';
import { OrganizationUserTable } from '../tables/OrganizationUserTable';

export const OrganizationUser = (): React.JSX.Element => {
  const tid = useTenantId();
  const currentUser = useUserAuth();
  const { data: organizationUsers, isLoading } = useGetOrganizationMemberListQuery(
    { tid },
    { skip: !tid },
  );

  const isManager = organizationUsers?.some(
    (user) =>
      user.user_profile_id === currentUser.userId &&
      (user.roles.includes(RolesEnum.Owner) ||
        user.roles.includes(RolesEnum.FarmManager) ||
        user.roles.includes(RolesEnum.CertificationBodyManager)),
  );

  return (
    <>
      {isManager && <OrganizationInvitationTable />}
      <OrganizationUserTable
        isLoading={isLoading}
        isManager={isManager}
        organizationUsers={organizationUsers}
        userId={currentUser.userId}
      />
    </>
  );
};
