import { DeleteForeverRounded, EditRounded } from '@mui/icons-material';
import { IconButton, Paper, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import {
  useDialogState,
  useGetCurrentUserProfile,
  useMutationFeedback,
  useTenantId,
} from 'src/hooks';
import { commonStrings, correctiveActionStrings } from 'src/languages/en-UK';
import {
  type TransformedNonConformityAndCorrectiveActionListOutputItem,
  useDeleteNonConformityAndCorrectiveActionMutation,
} from 'src/services/farmApi';
import { DATE_DISPLAY_FORMAT } from 'src/settings';
import { appColors } from 'src/theme';

import { MobileCompatibleTooltip } from '../common/MobileCompatibleTooltip';
import {
  EditCorrectiveActionDialog,
  type EditCorrectiveActionDialogProps,
} from './EditCorrectiveActionDialog';

interface CorrectiveActionProps {
  correctiveActionList: TransformedNonConformityAndCorrectiveActionListOutputItem[];
  isDisable: boolean;
  setIsNewCorrectiveActionFormVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CorrectiveAction = ({
  correctiveActionList,
  setIsNewCorrectiveActionFormVisible,
  isDisable = false,
}: CorrectiveActionProps): React.JSX.Element => {
  const tid = useTenantId();
  const { data: userProfile } = useGetCurrentUserProfile();
  const [isEditDialogOpen, openEditDialog, closeEditDialog, editDialogProps] =
    useDialogState<EditCorrectiveActionDialogProps>();

  const [deleteCorrectiveAction, deleteCorrectiveActionResult] =
    useDeleteNonConformityAndCorrectiveActionMutation();

  useMutationFeedback({
    result: deleteCorrectiveActionResult,
    errorMessage: correctiveActionStrings('deleteCorrectiveActionFail'),
    successMessage: correctiveActionStrings('deleteCorrectiveActionSuccess'),
    onSuccess: () => setIsNewCorrectiveActionFormVisible(true),
  });

  return (
    <>
      {(correctiveActionList || []).map((correctiveAction) => (
        <Paper
          key={correctiveAction.uuid}
          elevation={0}
          sx={{
            borderWidth: 1,
            borderRadius: 0.5,
            border: 1,
            mt: 1,
            px: 1,
            py: 0.5,
            borderColor: appColors.border,
          }}
        >
          <Stack
            direction="row"
            gap={2}
            justifyContent="space-between"
          >
            <Stack gap={0.75}>
              <Typography
                color="text.disabled"
                variant="overline"
              >
                {correctiveActionStrings('correctiveActions')}
              </Typography>
              <Typography variant="body1">{correctiveAction.text}</Typography>
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              gap={2}
            >
              {correctiveAction.author_id === userProfile.uuid && !isDisable && (
                <>
                  <IconButton
                    onClick={() =>
                      openEditDialog({
                        correctiveActionId: String(correctiveAction.uuid),
                        title: commonStrings('editCorrectiveAction'),
                      })
                    }
                    sx={{ padding: 0, maxHeight: 24 }}
                  >
                    <MobileCompatibleTooltip title={commonStrings('editCorrectiveAction')}>
                      <EditRounded />
                    </MobileCompatibleTooltip>
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      deleteCorrectiveAction({ id: correctiveAction.uuid, tid });
                    }}
                    sx={{ padding: 0, maxHeight: 24 }}
                  >
                    <MobileCompatibleTooltip
                      title={correctiveActionStrings('deleteCorrectiveAction')}
                    >
                      <DeleteForeverRounded />
                    </MobileCompatibleTooltip>
                  </IconButton>
                </>
              )}
            </Stack>
          </Stack>
          <Stack
            gap={0.75}
            marginTop={1.25}
          >
            <Typography
              color="text.disabled"
              variant="overline"
            >
              {commonStrings('dateCorrectiveActionsReported')}
            </Typography>
            <Typography variant="body2">
              {format(correctiveAction.reported_date, DATE_DISPLAY_FORMAT)}
            </Typography>
          </Stack>
        </Paper>
      ))}
      <EditCorrectiveActionDialog
        isOpen={isEditDialogOpen}
        onClose={closeEditDialog}
        {...editDialogProps}
      />
    </>
  );
};
