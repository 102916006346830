import { Send as SendIcon } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import { useContext } from 'react';
import {
  useAppDispatch,
  useGetAssessmentUserPermission,
  useMutationFeedback,
  useTenantId,
} from 'src/hooks';
import { commonStrings, newCommentFormStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import { useCreateAssessmentControlPointCommentMutation } from 'src/services/farmApi';
import { openSnackbar } from 'src/store/snackbarSlice';
import * as Yup from 'yup';

import { FormikTextField } from '../formikcomponents/FormikTextField';

interface NewCommentFormProps {
  controlPointId: string;
}

export const NewCommentForm = ({ controlPointId }: NewCommentFormProps): React.JSX.Element => {
  const [assessmentId] = useContext(AssessmentPageContext);
  const tid = useTenantId();

  const [createComment, createCommentResult] = useCreateAssessmentControlPointCommentMutation();
  const dispatch = useAppDispatch();
  const permissions = useGetAssessmentUserPermission(assessmentId);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      text: '',
    },
    validationSchema: Yup.object().shape({
      text: Yup.string(),
    }),

    onSubmit: (values) => {
      if (permissions?.comments?.create) {
        createComment({
          tid,
          body: {
            assessment_id: assessmentId,
            control_point_id: controlPointId,
            text: values.text,
          },
        });
      } else {
        dispatch(
          openSnackbar({
            message: commonStrings('notAuthorized'),
            severity: 'error',
          }),
        );
      }
    },
  });

  useMutationFeedback({
    result: createCommentResult,
    errorMessage: newCommentFormStrings('addNewCommentFail'),
    successMessage: newCommentFormStrings('addNewCommentSuccess'),
    onSuccess: () => formik.resetForm(),
  });

  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <Stack
        alignItems="center"
        direction="row"
        spacing={1}
      >
        <FormikTextField
          color="secondary"
          disabled={createCommentResult.isLoading}
          formik={formik}
          id="text"
          maxRows={4}
          minRows={1}
          multiline
          placeholder={newCommentFormStrings('addNewComment')}
          size="small"
        />
        <LoadingButton
          color="info"
          endIcon={<SendIcon />}
          loading={createCommentResult.isLoading}
          sx={{ paddingY: 0.875 }}
          type="submit"
          variant="outlined"
        >
          {commonStrings('add')}
        </LoadingButton>
      </Stack>
    </form>
  );
};
