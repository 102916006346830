import { type GridValueGetterParams } from '@mui/x-data-grid-pro';
import { commonStrings } from 'src/languages/en-UK';

type ReturnType = (params: GridValueGetterParams) => string;

export const createDataGridLinkedIdValueGetter =
  (entityList: { uuid?: string; name: string }[], field: string): ReturnType =>
  (params: GridValueGetterParams) => {
    const searchId = params.row[field];
    if (!searchId || !entityList?.length) {
      return commonStrings('unknown');
    }
    return entityList.find((entity) => entity?.uuid === searchId)?.name || commonStrings('unknown');
  };
