import {
  AttachFileRounded,
  ForumRounded,
  InfoRounded,
  MenuBookRounded,
  ReportProblemRounded,
} from '@mui/icons-material';
import { Menu, MenuItem, Typography } from '@mui/material';
import { type Dispatch, type ReactNode, type SetStateAction } from 'react';
import { type HelpDocumentsListParams } from 'src/__generated__/InternalApiTypes';
import { useTenantId } from 'src/hooks';
import { commonStrings, controlPointHeaderKebabMenuStrings } from 'src/languages/en-UK';
import {
  type TransformedAssessmentControlPointNonConformityOutputItem,
  useGetAssessmentControlPointQuery,
  useGetControlPointQuery,
  useGetHelpDocumentListQuery,
} from 'src/services/farmApi';

import { getInitialTab } from '../ControlPointGuidanceTab/ControlPointGuidanceTab.utils';

export enum KebabMenuSlug {
  AttachEvidence = 'attach_evidence',
  AddComment = 'add_comment',
  AddNonConformity = 'add_non_conformity',
  EvidenceFormsAndGuidelines = 'evidence-forms-and-guidelines',
  QuestionDetails = 'question_details',
}

type ActionMenuList = {
  slug: KebabMenuSlug;
  icon: ReactNode;
  label: string;
  onClick: () => void;
  isDisabled?: boolean;
};

interface ControlPointHeaderKebabMenuProps {
  assessmentControlPointId: string;
  controlPointId: string;
  filterParams: HelpDocumentsListParams;
  hidingMenuList: Array<KebabMenuSlug>;
  onClickMenuItem: (id: string) => void;
  setThreeDotsMenuAnchor: Dispatch<SetStateAction<HTMLElement>>;
  threeDotsMenuAnchor: HTMLElement;
}

export const ControlPointHeaderKebabMenu = ({
  assessmentControlPointId,
  controlPointId,
  filterParams,
  hidingMenuList,
  onClickMenuItem,
  setThreeDotsMenuAnchor,
  threeDotsMenuAnchor,
}: ControlPointHeaderKebabMenuProps): React.JSX.Element => {
  const tid = useTenantId();
  const { data: helpDocumentList } = useGetHelpDocumentListQuery(filterParams, {
    skip: !filterParams?.category_and_evidence_type_based_control_point_id,
  });
  const EMPTY_ARRAY: TransformedAssessmentControlPointNonConformityOutputItem[] = [];

  const { shortcomings: controlPointNonConformityList } = useGetAssessmentControlPointQuery(
    {
      tid,
      id: assessmentControlPointId,
    },
    {
      selectFromResult: ({ data }) => ({
        shortcomings: data?.shortcomings ?? EMPTY_ARRAY,
      }),
    },
  );

  const { data: controlPointDetail } = useGetControlPointQuery(controlPointId, {
    skip: !controlPointId,
  });

  const handleCloseMenu = () => {
    setThreeDotsMenuAnchor(null);
  };

  let actionMenuList: ActionMenuList[] = [
    {
      slug: KebabMenuSlug.AttachEvidence,
      label: controlPointHeaderKebabMenuStrings('attachDocument'),
      icon: <AttachFileRounded />,
      onClick: () => {
        onClickMenuItem('0');
        handleCloseMenu();
      },
    },
    {
      slug: KebabMenuSlug.AddComment,
      label: controlPointHeaderKebabMenuStrings('addComment'),
      icon: <ForumRounded />,
      onClick: () => {
        onClickMenuItem('1');
        handleCloseMenu();
      },
    },
    {
      slug: KebabMenuSlug.AddNonConformity,
      isDisabled: controlPointNonConformityList?.length > 0,
      label: commonStrings('addNonConformity'),
      icon: <ReportProblemRounded />,
      onClick: () => {
        onClickMenuItem('2');
        handleCloseMenu();
      },
    },
    {
      slug: KebabMenuSlug.EvidenceFormsAndGuidelines,
      isDisabled: !helpDocumentList?.length,
      label: commonStrings('evidenceFormsAndGuidelines'),
      icon: <MenuBookRounded />,
      onClick: () => {
        onClickMenuItem('3');
        handleCloseMenu();
      },
    },
    {
      slug: KebabMenuSlug.QuestionDetails,
      isDisabled: !getInitialTab(controlPointDetail),
      label: controlPointHeaderKebabMenuStrings('questionDetails'),
      icon: <InfoRounded />,
      onClick: () => {
        onClickMenuItem('4');
        handleCloseMenu();
      },
    },
  ];

  if (hidingMenuList?.length > 0) {
    hidingMenuList.forEach((menuSlug) => {
      actionMenuList = actionMenuList.filter((menuItem) => menuItem.slug !== menuSlug);
    });
  }

  return (
    <Menu
      anchorEl={threeDotsMenuAnchor}
      id="control-point-kebab-menu"
      MenuListProps={{ 'aria-labelledby': 'control-point-kebab-button' }}
      onClose={handleCloseMenu}
      open
    >
      {actionMenuList.map((actionMenuItem) => (
        <MenuItem
          key={actionMenuItem.slug}
          disabled={!!actionMenuItem.isDisabled}
          onClick={actionMenuItem.onClick}
        >
          {actionMenuItem.icon}
          <Typography
            marginLeft={0.5}
            variant="body1"
          >
            {actionMenuItem.label}
          </Typography>
        </MenuItem>
      ))}
    </Menu>
  );
};
