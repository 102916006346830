import { Typography } from '@mui/material';
import {
  DataGridPro,
  type DataGridProProps,
  GridLoadingOverlay,
  GridOverlay,
  type GridRowModel,
  type GridSortModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { type GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import AgriplaceLoadingLogo from 'assets/agriplace-loading-logo.svg';
import { useRef } from 'react';
import { commonStrings, mobileCompatibleDataGridStrings } from 'src/languages/en-UK';
import { DEFAULT_DATA_GRID_PAGINATION, DEFAULT_DATA_GRID_STATE } from 'src/settings';

interface MobileCompatibleDataGridProps extends DataGridProProps {
  NoRowsOverlay?: React.JSXElementConstructor<string>;
  hasAgriplaceLoadingAnimation?: boolean;
  hideToolbar?: boolean;
  sortModel?: GridSortModel;
}

type NoDataOverlayProps = {
  message: string;
};

const NoDataOverlay = ({ message }: NoDataOverlayProps): React.JSX.Element => (
  <Typography
    alignItems="center"
    color="textSecondary"
    display="flex"
    height="100%"
    justifyContent="center"
    paddingX={1}
    paddingY={0.5}
    textAlign="center"
    variant="overline"
    whiteSpace="pre-line"
  >
    {message}
  </Typography>
);

export const CustomNoRowsOverlay = (msg: string) => <NoDataOverlay message={msg} />;

/**
 * Especially for mobile requirements and transifex text replacement, creating and managing a single component instead of using the same props over and over again facilitated code repetition and management.
 */
export const MobileCompatibleDataGrid = ({
  apiRef,
  NoRowsOverlay,
  sortModel,
  rowsPerPageOptions = DEFAULT_DATA_GRID_PAGINATION,
  checkboxSelection = false,
  disableColumnSelector = true,
  disableDensitySelector = true,
  disableSelectionOnClick = true,
  hasAgriplaceLoadingAnimation = false,
  hideToolbar = false,
  ...restProps
}: MobileCompatibleDataGridProps) => {
  const getRowId = (row: GridRowModel) => row.uuid;
  const tableRef = useRef<GridApiPro>();
  const handlePageChange = () => {
    tableRef.current?.scroll?.({ left: 0, top: 0 });
  };

  const customLoadingOverlay = () => (
    <GridOverlay>
      <img
        alt="AgriPlace logo with loading animation"
        height="48px"
        src={AgriplaceLoadingLogo}
        width="48px"
      />
    </GridOverlay>
  );

  const customToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarFilterButton
        // TODOHasan: I had to add this because MUI v5 give a typescript error. It might be fixed when I upgrade.
        onPointerEnterCapture={undefined}
        // TODOHasan: I had to add this because MUI v5 give a typescript error. It might be fixed when I upgrade.
        onPointerLeaveCapture={undefined}
        // TODOHasan: I had to add this because MUI v5 give a typescript error. It might be fixed when I upgrade.
        placeholder={undefined}
      />
    </GridToolbarContainer>
  );

  return (
    <DataGridPro
      apiRef={apiRef || tableRef}
      checkboxSelection={checkboxSelection}
      components={{
        NoRowsOverlay,
        LoadingOverlay: hasAgriplaceLoadingAnimation ? customLoadingOverlay : GridLoadingOverlay,
        ...(!hideToolbar && { Toolbar: customToolbar }),
      }}
      disableColumnSelector={disableColumnSelector}
      disableDensitySelector={disableDensitySelector}
      disableSelectionOnClick={disableSelectionOnClick}
      getRowId={getRowId}
      initialState={{
        ...DEFAULT_DATA_GRID_STATE,
        ...(sortModel && { sorting: { sortModel } }),
      }}
      localeText={{
        MuiTablePagination: {
          labelRowsPerPage: mobileCompatibleDataGridStrings('rowsPerPage'),
        },
        checkboxSelectionHeaderName: mobileCompatibleDataGridStrings('checkboxSelection'),
        columnsPanelHideAllButton: mobileCompatibleDataGridStrings('hideAll'),
        columnsPanelShowAllButton: mobileCompatibleDataGridStrings('showAll'),
        columnsPanelTextFieldLabel: mobileCompatibleDataGridStrings('findColumn'),
        columnsPanelTextFieldPlaceholder: mobileCompatibleDataGridStrings('columnTitle'),
        filterOperatorContains: mobileCompatibleDataGridStrings('contains'),
        filterOperatorEquals: mobileCompatibleDataGridStrings('equals'),
        filterOperatorStartsWith: mobileCompatibleDataGridStrings('startsWith'),
        filterOperatorEndsWith: mobileCompatibleDataGridStrings('endsWith'),
        filterOperatorIsEmpty: mobileCompatibleDataGridStrings('isEmpty'),
        filterOperatorIsNotEmpty: mobileCompatibleDataGridStrings('isNotEmpty'),
        filterOperatorIsAnyOf: mobileCompatibleDataGridStrings('isAnyOf'),
        filterPanelColumns: mobileCompatibleDataGridStrings('columns'),
        filterPanelDeleteIconLabel: commonStrings('delete'),
        filterPanelInputLabel: mobileCompatibleDataGridStrings('value'),
        filterPanelInputPlaceholder: mobileCompatibleDataGridStrings('filterValue'),
        filterPanelOperators: mobileCompatibleDataGridStrings('operator'),
        footerRowSelected: (count) => `${count} ${mobileCompatibleDataGridStrings('rowSelected')}`,
        toolbarColumns: mobileCompatibleDataGridStrings('columns'),
        toolbarColumnsLabel: mobileCompatibleDataGridStrings('columns'),
        toolbarDensity: mobileCompatibleDataGridStrings('density'),
        toolbarDensityComfortable: mobileCompatibleDataGridStrings('comfortable'),
        toolbarDensityCompact: mobileCompatibleDataGridStrings('compact'),
        toolbarDensityLabel: mobileCompatibleDataGridStrings('density'),
        toolbarDensityStandard: commonStrings('standard'),
        toolbarExport: mobileCompatibleDataGridStrings('export'),
        toolbarExportCSV: mobileCompatibleDataGridStrings('downloadAsCSV'),
        toolbarExportLabel: mobileCompatibleDataGridStrings('export'),
        toolbarExportPrint: mobileCompatibleDataGridStrings('print'),
        toolbarFilters: commonStrings('filters'),
        toolbarFiltersLabel: commonStrings('filters'),
        toolbarFiltersTooltipHide: mobileCompatibleDataGridStrings('hideFilters'),
        toolbarFiltersTooltipShow: mobileCompatibleDataGridStrings('showFilters'),
      }}
      onPageChange={handlePageChange}
      pagination
      rowsPerPageOptions={rowsPerPageOptions}
      {...restProps}
    />
  );
};
