import { AddRounded, DeleteRounded, ShareRounded } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Chip, Link as MuiLink, Paper, Toolbar, Typography } from '@mui/material';
import {
  GridActionsCellItem,
  type GridEnrichedColDef,
  type GridRenderCellParams,
  type GridRowParams,
  type GridValueFormatterParams,
  type GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { isBefore } from 'date-fns';
import { type ReactNode } from 'react';
import { generatePath, Link, useNavigate, useSearchParams } from 'react-router-dom';
import { type AssessmentListOutputItem } from 'src/__generated__/InternalApiTypes';
import {
  CustomNoRowsOverlay,
  MobileCompatibleDataGrid,
} from 'src/components/common/MobileCompatibleDataGrid';
import {
  AssessmentCreateDialog,
  type AssessmentCreateDialogProps,
} from 'src/components/dialogs/AssessmentCreateDialog';
import {
  AssessmentShareDialog,
  type AssessmentShareDialogProps,
} from 'src/components/dialogs/AssessmentShareDialog';
import {
  GenericDeleteDialog,
  type GenericDeleteDialogProps,
} from 'src/components/dialogs/GenericDeleteDialog';
import {
  useDialogState,
  useMutationFeedback,
  useRoleCheck,
  useTenantId,
  useUserAuth,
} from 'src/hooks';
import { useGetUserOrigin } from 'src/hooks/useGetUserOrigin';
import { assessmentArchivePageStrings, commonStrings } from 'src/languages/en-UK';
import {
  useCreateAssessmentMutation,
  useDeleteAssessmentMutation,
  useGetAssessmentListQuery,
  useGetRetailersListQuery,
  useGetStandardLogoListQuery,
} from 'src/services/farmApi';
import { SignUpOriginEnum } from 'src/services/farmApi/endpoints/auth';
import { ROUTE_PATH_ASSESSMENT } from 'src/settings';
import { appColors } from 'src/theme';
import { dataGridValueFormatterForDate } from 'src/utils/dataGridUtils';

import { pageWrapperStyle } from './AssessmentArchivePage.styles';

type ValueGetterParams = GridValueGetterParams<string, AssessmentListOutputItem>;

export const AssessmentArchivePage = (): React.JSX.Element => {
  const tid = useTenantId();
  const navigate = useNavigate();
  const currentUser = useUserAuth();
  const origin = useGetUserOrigin();
  const { isFarm, isCB, isAuditor, isCBManager, isFarmManager, isFarmEmployee } = useRoleCheck();
  const [searchParams] = useSearchParams();
  const statusFilter = searchParams.getAll('status');
  const { data: masterRetailers } = useGetRetailersListQuery();
  const { data: assessmentList, isLoading } = useGetAssessmentListQuery(
    {
      tid,
      ...(isAuditor && {
        author_id: currentUser?.userId,
      }),
    },
    { skip: !tid },
  );
  let filteredAssessmentList = assessmentList;
  if (assessmentList && statusFilter?.length > 0) {
    filteredAssessmentList = assessmentList.filter(
      (assessment) => assessment.status && statusFilter.includes(assessment.status),
    );
  }
  const [createAssessment, createAssessmentResult] = useCreateAssessmentMutation();

  const onCreateAssessment = () => {
    if (tid) {
      createAssessment({ tid, body: undefined });
    }
  };
  const getDecisionConclusionText = (params: ValueGetterParams) => {
    if (params.row.confirmed_date && params.row.declined_date) {
      if (isBefore(new Date(params.row.declined_date), new Date(params.row.confirmed_date))) {
        return { conclusion: commonStrings('compliant'), color: 'success.main' };
      }
      return { conclusion: commonStrings('nonCompliant'), color: 'error' };
    }
    if (params.row.declined_date) {
      return { conclusion: commonStrings('nonCompliant'), color: 'error' };
    }
    if (params.row.confirmed_date) {
      return { conclusion: commonStrings('compliant'), color: 'success.main' };
    }

    return { conclusion: '-', color: 'text.primary' };
  };
  const { data: standardLogos } = useGetStandardLogoListQuery();

  useMutationFeedback({
    result: createAssessmentResult,
    onSuccess: (result) => {
      navigate(generatePath(ROUTE_PATH_ASSESSMENT, { id: result.uuid }));
    },
    successMessage: commonStrings('backendCreateSuccessMessage', {
      name: createAssessmentResult?.data?.name,
    }),
    errorMessage: commonStrings('failedToCreateAssessment'),
  });

  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog, deleteDialogProps] =
    useDialogState<Omit<GenericDeleteDialogProps, 'deleteMutation'>>();
  const [isCreateDialogOpen, openCreateDialog, closeCreateDialog] =
    useDialogState<AssessmentCreateDialogProps>();
  const [isShareDialogOpen, openShareDialog, closeShareDialog, shareDialogProps] =
    useDialogState<AssessmentShareDialogProps>();

  const columnsForLEAF: GridEnrichedColDef[] = [
    {
      field: 'name',
      headerName: commonStrings('name'),
      flex: 4,
      renderCell: (params: GridRenderCellParams<string, AssessmentListOutputItem, ReactNode>) => {
        const isAssessmentAuthor = params.row.author_id === currentUser?.userId;
        return isFarmEmployee && !isAssessmentAuthor ? (
          <Typography
            color="text.disabled"
            sx={{
              textDecoration: 'underline',
            }}
            variant="body1"
          >
            {params.value}
          </Typography>
        ) : (
          <MuiLink
            component={Link}
            sx={{ color: appColors.blue }}
            to={generatePath(ROUTE_PATH_ASSESSMENT, { id: params.row.uuid || null })}
            variant="body1"
          >
            {params.value}
          </MuiLink>
        );
      },
    },
    {
      field: 'retailer_ids',
      headerName: assessmentArchivePageStrings('retailers'),
      flex: 2,
      renderCell: (params: GridRenderCellParams<string[], AssessmentListOutputItem, ReactNode>) =>
        params?.value?.length > 0 && (
          <Box flexDirection="row">
            {params?.value.map((retailerId) => {
              const retailer = masterRetailers?.find(
                (masterRetailer) => masterRetailer.uuid === retailerId,
              );
              return (
                <img
                  key={retailer.uuid}
                  alt={`${retailer.name}`}
                  src={retailer.logo.file_object}
                  style={{
                    marginRight: '2px',
                    height: '32px',
                    width: '32px',
                  }}
                />
              );
            })}
          </Box>
        ),
    },
    {
      field: 'started_date',
      headerName: assessmentArchivePageStrings('startedOn'),
      type: 'date',
      flex: 2,
      valueFormatter: (params: GridValueFormatterParams) =>
        dataGridValueFormatterForDate(params, ''),
    },
    {
      field: 'to_be_audited_date',
      headerName: assessmentArchivePageStrings('completedOn'),
      type: 'date',
      flex: 2,
      valueFormatter: (params: GridValueFormatterParams) =>
        dataGridValueFormatterForDate(params, ''),
      valueGetter: (params: ValueGetterParams) =>
        (params.value ?? params.row.to_be_confirmed_date) || null,
    },
    {
      field: 'status_label',
      headerName: commonStrings('status'),
      flex: 1.5,
      valueGetter: (params: ValueGetterParams) =>
        params.value || commonStrings('notApplicableShort'),
    },
  ];

  const columnsForFarmer: GridEnrichedColDef[] = [
    {
      field: 'name',
      headerName: commonStrings('name'),
      flex: 4,
      renderCell: (params: GridRenderCellParams<string, AssessmentListOutputItem, ReactNode>) => {
        const isAssessmentAuthor = params.row.author_id === currentUser?.userId;
        return isFarmEmployee && !isAssessmentAuthor ? (
          <Typography
            color="text.disabled"
            sx={{
              textDecoration: 'underline',
            }}
            variant="body1"
          >
            {params.value}
          </Typography>
        ) : (
          <MuiLink
            component={Link}
            sx={{ color: appColors.blue }}
            to={generatePath(ROUTE_PATH_ASSESSMENT, { id: params.row.uuid || null })}
            variant="body1"
          >
            {params.value}
          </MuiLink>
        );
      },
    },
    {
      field: 'standard_logo_ids',
      headerName: assessmentArchivePageStrings('includedStandards'),
      flex: 2,
      renderCell: (params: GridRenderCellParams<string[], AssessmentListOutputItem, ReactNode>) => {
        const logos = params.value?.map((id) => standardLogos?.[id]).filter(Boolean);
        return (
          logos?.length > 0 && (
            <Box flexDirection="row">
              {logos.map((logo) => (
                <img
                  key={logo.uuid}
                  alt={`list item: ${logo.name}`}
                  src={logo.file_object}
                  style={{
                    marginRight: '2px',
                    height: '32px',
                    width: '32px',
                  }}
                />
              ))}
            </Box>
          )
        );
      },
    },
    {
      field: 'started_date',
      headerName: assessmentArchivePageStrings('startedOn'),
      type: 'date',
      flex: 2,
      valueFormatter: (params: GridValueFormatterParams) =>
        dataGridValueFormatterForDate(params, ''),
    },
    {
      field: 'to_be_audited_date',
      headerName: assessmentArchivePageStrings('completedOn'),
      type: 'date',
      flex: 2,
      valueFormatter: (params: GridValueFormatterParams) =>
        dataGridValueFormatterForDate(params, ''),
      valueGetter: (params: ValueGetterParams) =>
        (params.value ?? params.row.to_be_confirmed_date) || null,
    },
    {
      field: 'status_label',
      headerName: commonStrings('status'),
      flex: 1.5,
      valueGetter: (params: ValueGetterParams) =>
        params.value || commonStrings('notApplicableShort'),
    },
  ];

  const columnsForAuditor: GridEnrichedColDef[] = [
    {
      field: 'name',
      headerName: commonStrings('name'),
      flex: 4,
      renderCell: (params: GridRenderCellParams<string, AssessmentListOutputItem, ReactNode>) => {
        const isAssessmentAuthor = params.row.author_id === currentUser?.userId;
        return !isAssessmentAuthor ? (
          <Typography
            color="text.disabled"
            sx={{
              textDecoration: 'underline',
            }}
            variant="body1"
          >
            {params.value}
          </Typography>
        ) : (
          <MuiLink
            component={Link}
            sx={{ color: appColors.blue }}
            to={generatePath(ROUTE_PATH_ASSESSMENT, { id: params.row.uuid || null })}
            variant="body1"
          >
            {params.value}
          </MuiLink>
        );
      },
    },
    {
      field: 'farm_name',
      headerName: commonStrings('farmOrganizationName'),
      flex: 2,
      valueGetter: (params) => params.row.metadata?.farm_name || '',
    },
    {
      field: 'address',
      headerName: commonStrings('address'),
      flex: 2,
      valueGetter: (params) => params.row.metadata?.farm_address || '',
    },
    {
      field: 'status_label',
      headerName: commonStrings('status'),
      flex: 1.5,
      valueGetter: (params: ValueGetterParams) =>
        params.value || commonStrings('notApplicableShort'),
    },
    {
      field: 'to_be_audited_date',
      headerName: assessmentArchivePageStrings('completedOn'),
      type: 'date',
      flex: 2,
      valueFormatter: (params: GridValueFormatterParams) =>
        dataGridValueFormatterForDate(params, ''),
      valueGetter: (params: ValueGetterParams) =>
        (params.value ?? params.row.to_be_confirmed_date) || null,
    },
    {
      field: 'standard_logo_ids',
      headerName: assessmentArchivePageStrings('includedStandards'),
      flex: 2,
      renderCell: (params: GridRenderCellParams<string[], AssessmentListOutputItem, ReactNode>) => {
        const logos = params.value?.map((id) => standardLogos?.[id]).filter(Boolean);
        return (
          logos?.length > 0 && (
            <Box flexDirection="row">
              {logos.map((logo) => (
                <img
                  key={logo.uuid}
                  alt={`list item: ${logo.name}`}
                  src={logo.file_object}
                  style={{
                    marginRight: '2px',
                    height: '32px',
                    width: '32px',
                  }}
                />
              ))}
            </Box>
          )
        );
      },
    },
    {
      field: 'inspection_type_label',
      headerName: commonStrings('type'),
      flex: 1.3,
      valueGetter: (params: ValueGetterParams) =>
        params.value?.length > 1 ? params.value.charAt(0).toUpperCase() + params.value.slice(1) : '',
      renderCell: (params: GridRenderCellParams<string, AssessmentListOutputItem, ReactNode>) =>
        params.value && (
          <Chip
            label={params.value}
            variant="outlined"
          />
        ),
    },
  ];

  const columnsForCBManager: GridEnrichedColDef[] = [
    {
      field: 'name',
      headerName: commonStrings('name'),
      flex: 4,
      renderCell: (params: GridRenderCellParams<string, AssessmentListOutputItem, ReactNode>) => (
        <MuiLink
          component={Link}
          sx={{ color: appColors.blue }}
          to={generatePath(ROUTE_PATH_ASSESSMENT, { id: params.row.uuid || null })}
          variant="body1"
        >
          {params.value}
        </MuiLink>
      ),
    },
    {
      field: 'farm_name',
      headerName: commonStrings('farmOrganizationName'),
      flex: 2,
      valueGetter: (params) => params.row.metadata?.farm_name || '',
    },
    {
      field: 'address',
      headerName: commonStrings('address'),
      flex: 2,
      valueGetter: (params) => params.row.metadata?.farm_address || '',
    },
    {
      field: 'author_name',
      headerName: assessmentArchivePageStrings('auditorName'),
      flex: 1.5,
    },
    {
      field: 'status_label',
      headerName: commonStrings('status'),
      flex: 2,
      valueGetter: (params: ValueGetterParams) =>
        params.value || commonStrings('notApplicableShort'),
    },
    {
      field: 'to_be_audited_date',
      headerName: assessmentArchivePageStrings('auditDate'),
      type: 'date',
      flex: 1.5,
      valueFormatter: (params: GridValueFormatterParams) =>
        dataGridValueFormatterForDate(params, ''),
      valueGetter: (params: ValueGetterParams) =>
        (params.value ?? params.row.to_be_audited_date) || null,
    },
    {
      field: 'standard_logo_ids',
      headerName: assessmentArchivePageStrings('includedStandards'),
      flex: 2,
      renderCell: (params: GridRenderCellParams<string[], AssessmentListOutputItem, ReactNode>) => {
        const logos = params.value?.map((id) => standardLogos?.[id]).filter(Boolean);
        return (
          logos?.length > 0 && (
            <Box flexDirection="row">
              {logos.map((logo) => (
                <img
                  key={logo.uuid}
                  alt={`list item: ${logo.name}`}
                  src={logo.file_object}
                  style={{
                    marginRight: '2px',
                    height: '32px',
                    width: '32px',
                  }}
                />
              ))}
            </Box>
          )
        );
      },
    },
    {
      field: 'decision_conclusion',
      headerName: assessmentArchivePageStrings('decisionOrConclusion'),
      flex: 2,
      renderCell: (params: GridRenderCellParams<string, AssessmentListOutputItem, ReactNode>) => (
        <Typography
          color={getDecisionConclusionText(params).color}
          variant="body2"
        >
          {params.value}
        </Typography>
      ),
      valueGetter: (params: ValueGetterParams) => getDecisionConclusionText(params).conclusion,
    },
    {
      field: 'inspection_type_label',
      headerName: commonStrings('type'),
      flex: 1.2,
      valueGetter: (params: ValueGetterParams) =>
        params.value?.length > 1 ? params.value.charAt(0).toUpperCase() + params.value.slice(1) : '',
      renderCell: (params: GridRenderCellParams<string, AssessmentListOutputItem, ReactNode>) =>
        params.value && (
          <Chip
            label={params.value}
            variant="outlined"
          />
        ),
    },
  ];

  const finalColumns: GridEnrichedColDef[] = [];
  if (isAuditor) {
    finalColumns.push(...columnsForAuditor);
  } else if (isCBManager) {
    finalColumns.push(...columnsForCBManager);
  } else if (origin === SignUpOriginEnum.LEAF) {
    finalColumns.push(...columnsForLEAF);
  } else {
    finalColumns.push(...columnsForFarmer);
  }
  if (!isFarmEmployee) {
    finalColumns.push({
      field: 'actions',
      type: 'actions',
      align: 'right',
      getActions: (params: GridRowParams<AssessmentListOutputItem>) => {
        const actionButtons = [];
        actionButtons.push(
          <GridActionsCellItem
            key={`${params.row.uuid}_share`}
            icon={<ShareRounded />}
            label={commonStrings('share')}
            onClick={() => {
              const urlToShare = `${window.location.toString()}/${params.row.uuid}`;
              openShareDialog({ urlToShare });
            }}
          />,
        );
        const isAssessmentAuthor = params.row.author_id === currentUser?.userId;
        if (isAssessmentAuthor || isFarmManager) {
          actionButtons.push(
            <GridActionsCellItem
              key={`${params.row.uuid}_delete`}
              icon={<DeleteRounded />}
              label={commonStrings('delete')}
              onClick={() =>
                openDeleteDialog({
                  entities: [
                    {
                      assessmentId: params.row.uuid,
                      header: isFarm
                        ? assessmentArchivePageStrings('selfAssessment')
                        : assessmentArchivePageStrings('inspection'),
                      name: params.row.name,
                      permissionType: 'assessments',
                      uuid: params.row.uuid || '',
                    },
                  ],
                })
              }
            />,
          );
        }
        return actionButtons;
      },
    });
  }

  return (
    <>
      <Toolbar />
      <Paper sx={pageWrapperStyle}>
        <Typography
          marginBottom={4}
          marginTop={3}
          variant="h1"
        >
          {isCB ? commonStrings('inspections') : commonStrings('selfAssessments')}
        </Typography>
        {!isCBManager && (
          <Toolbar disableGutters>
            <LoadingButton
              loading={isLoading || createAssessmentResult?.isLoading}
              onClick={isCB ? openCreateDialog : onCreateAssessment}
              startIcon={<AddRounded />}
              sx={{ mb: 3 }}
              variant="contained"
            >
              {isCB ? commonStrings('startInspection') : commonStrings('startNewSelfAssessment')}
            </LoadingButton>
          </Toolbar>
        )}
        <MobileCompatibleDataGrid
          columns={finalColumns}
          loading={isLoading}
          NoRowsOverlay={() =>
            CustomNoRowsOverlay(
              isCB
                ? assessmentArchivePageStrings('assessmentTableNoRowsMsgForAuditors')
                : assessmentArchivePageStrings('assessmentTableNoRowsMsg'),
            )
          }
          rows={filteredAssessmentList || []}
          sortModel={[{ field: 'started_date', sort: 'desc' }]}
        />
      </Paper>
      <GenericDeleteDialog
        deleteMutation={useDeleteAssessmentMutation}
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        {...deleteDialogProps}
      />
      <AssessmentCreateDialog
        isOpen={isCreateDialogOpen}
        onClose={closeCreateDialog}
      />
      <AssessmentShareDialog
        isOpen={isShareDialogOpen}
        onClose={closeShareDialog}
        urlToShare={shareDialogProps?.urlToShare || ''}
      />
    </>
  );
};
