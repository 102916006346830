import { Send as SendIcon } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Stack } from '@mui/material';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { useContext } from 'react';
import { OccurrenceEnum } from 'src/__generated__/InternalApiTypes';
import { useMutationFeedback, useTenantId } from 'src/hooks';
import { commonStrings, newNonConformityFormStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import { useCreateAssessmentControlPointNonConformityMutation } from 'src/services/farmApi';
import { DATE_SERVER_FORMAT } from 'src/settings';
import * as Yup from 'yup';

import { FormikDateField } from '../formikcomponents/FormikDateField';
import { FormikSelectField } from '../formikcomponents/FormikSelectField';
import { FormikTextField } from '../formikcomponents/FormikTextField';

interface NewNonConformityFormProps {
  controlPointId: string;
}

export enum OccurrenceEnumLabel {
  'first' = '1st time',
  'second' = '2nd time',
}

export const NewNonConformityForm = ({
  controlPointId,
}: NewNonConformityFormProps): React.JSX.Element => {
  const [assessmentId] = useContext(AssessmentPageContext);
  const tid = useTenantId();

  const [createNonConformity, createNonConformityResult] =
    useCreateAssessmentControlPointNonConformityMutation();
  const currentDate = format(new Date(), DATE_SERVER_FORMAT);
  const formik = useFormik<{
    text: string;
    occurrence: OccurrenceEnum | '';
    conformityReportDate: string;
  }>({
    enableReinitialize: true,
    initialValues: {
      text: '',
      occurrence: '',
      conformityReportDate: currentDate,
    },
    validationSchema: Yup.object().shape({
      text: Yup.string(),
    }),
    onSubmit: async (values) => {
      createNonConformity({
        tid,
        body: {
          assessment_id: assessmentId,
          control_point_id: controlPointId,
          text: values.text,
          reported_date: values.conformityReportDate,
          occurrence: values.occurrence as OccurrenceEnum,
        },
      });
    },
  });

  useMutationFeedback({
    result: createNonConformityResult,
    errorMessage: newNonConformityFormStrings('addNonConformityFail'),
    successMessage: newNonConformityFormStrings('addNonConformitySuccess'),
    onSuccess: () => formik.resetForm(),
  });

  const standardOptions = [
    { value: OccurrenceEnum.First, label: OccurrenceEnumLabel[OccurrenceEnum.First] },
    { value: OccurrenceEnum.Second, label: OccurrenceEnumLabel[OccurrenceEnum.Second] },
  ];

  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <Stack spacing={1}>
        <FormikTextField
          color="secondary"
          disabled={createNonConformityResult.isLoading}
          formik={formik}
          id="text"
          maxRows={4}
          minRows={1}
          multiline
          placeholder={commonStrings('addNonConformity')}
          size="small"
        />
        <FormikSelectField
          formik={formik}
          id="occurrence"
          isHideInputLabel
          label={commonStrings('timeNonConformityAppear')}
          options={standardOptions}
          size="small"
        />
        <Stack
          alignItems="center"
          direction="row"
          marginTop="8px"
          spacing={1.25}
        >
          <FormikDateField
            formik={formik}
            id="conformityReportDate"
            inputSize="small"
            placeholder={commonStrings('dateNonConformityReported')}
          />
          <LoadingButton
            color="info"
            endIcon={<SendIcon />}
            loading={createNonConformityResult.isLoading}
            sx={{ paddingY: 0.875 }}
            type="submit"
            variant="outlined"
          >
            {commonStrings('add')}
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
};
