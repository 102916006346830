import { Alert } from '@mui/material';
import { type AlertData } from 'src/components/common';

interface FormFeedbackWrapperProps {
  alert: AlertData;
  children: React.JSX.Element;
}

export const FormFeedbackWrapper = ({
  alert,
  children,
}: FormFeedbackWrapperProps): React.JSX.Element => (
  <>
    {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}
    {(!alert || (alert && alert.severity === 'error')) && children}
  </>
);
