import { CloseRounded, SendRounded } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useMemo } from 'react';
import { FormikSelectField } from 'src/components/formikcomponents/FormikSelectField';
import { useDialogState, useMutationFeedback, useTenantId } from 'src/hooks';
import { assessmentShareWithAuditorDialogStrings, commonStrings } from 'src/languages/en-UK';
import {
  useGetCertificationBodiesListQuery,
  useGetOrganizationClientIdentifiersListQuery,
} from 'src/services/farmApi';
import { useCreateShareableAssessmentSummaryExportMutation } from 'src/services/farmApi/endpoints/assessmentExports';
import { canSubmitFormik } from 'src/utils/canSubmitFormik';

import {
  AssessmentShareWithAuditorResultDialog,
  type AssessmentShareWithAuditorResultDialogProps,
} from '../AssessmentShareWithAuditorResultDialog';

export interface AssessmentShareWithAuditorDialogProps {
  assessmentId: string;
  assessmentName: string;
  isOpen: boolean;
  onClose: () => void;
  standardId: string;
  standardName: string;
}

export const AssessmentShareWithAuditorDialog = ({
  assessmentId,
  assessmentName,
  onClose,
  standardId,
  standardName,
  isOpen = false,
}: AssessmentShareWithAuditorDialogProps): React.JSX.Element => {
  const tid = useTenantId();

  const { data: certificationBodies } = useGetCertificationBodiesListQuery(tid, { skip: !tid });
  const { data: organizationClientIdentifiers } = useGetOrganizationClientIdentifiersListQuery(
    tid,
    { skip: !tid },
  );

  const [createShareableAssessmentSummaryExport, createShareableAssessmentSummaryExportResult] =
    useCreateShareableAssessmentSummaryExportMutation();

  const [
    isShareResultDialogOpen,
    openShareResultDialog,
    closeShareResultDialog,
    shareResultDialogProps,
  ] = useDialogState<AssessmentShareWithAuditorResultDialogProps>();

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      certification_body_id: '',
      email: '',
    },
    onSubmit: (values) => {
      if (tid) {
        createShareableAssessmentSummaryExport({
          standardId,
          tid,
          id: assessmentId,
          body: {
            certification_body_id: values.certification_body_id,
            ...(values.email && { email: values.email }),
          },
        });
      }
    },
  });

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  useMutationFeedback({
    result: createShareableAssessmentSummaryExportResult,
    onSuccess: () => {
      handleClose();
      openShareResultDialog();
    },
    errorMessage: assessmentShareWithAuditorDialogStrings('shareAssessmentWithTheAuditorFail'),
  });

  useEffect(() => {
    let certificationBodyId = '';
    organizationClientIdentifiers?.forEach((o) => {
      if (standardId && o.standards?.some((s) => s.uuid === standardId)) {
        certificationBodyId = o.certification_body?.uuid || '';
      }
    });
    formik.setFieldValue('certification_body_id', certificationBodyId);
    // TODOHasan: Do not add formik as a dependency. Find a correct way.
  }, [organizationClientIdentifiers, standardId]);

  const certificationBodyOptions = useMemo(() => {
    if (certificationBodies) {
      return (
        certificationBodies?.map((certificationBody) => ({
          value: certificationBody.uuid,
          label: certificationBody.name,
        })) ?? []
      );
    }
    return [];
  }, [certificationBodies]);

  return (
    <>
      <Dialog
        fullWidth
        onClose={handleClose}
        open={isOpen}
      >
        <form
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: 1.5,
            }}
          >
            <CloseRounded />
          </IconButton>
          <DialogTitle>
            {assessmentShareWithAuditorDialogStrings('shareAssessmentWithTheAuditor', {
              assessmentName,
              standardName,
            })}
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              {assessmentShareWithAuditorDialogStrings('shareAssessmentWithTheAuditorDesc')}
            </Typography>
            <FormikSelectField
              disabled
              formik={formik}
              id="certification_body_id"
              label={commonStrings('certificationCompany')}
              options={certificationBodyOptions}
              sx={{ mt: 3 }}
            />
            <Typography
              marginTop={3}
              variant="body1"
            >
              {assessmentShareWithAuditorDialogStrings('afterSharingWarningInfo')}
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              flex: 1,
              gap: 1,
              mt: 3,
            }}
          >
            <Button
              disabled={!canSubmitFormik(formik, false)}
              size="large"
              startIcon={<SendRounded />}
              type="submit"
              variant="contained"
            >
              {assessmentShareWithAuditorDialogStrings('send')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <AssessmentShareWithAuditorResultDialog
        isOpen={isShareResultDialogOpen}
        onClose={closeShareResultDialog}
        {...shareResultDialogProps}
      />
    </>
  );
};
