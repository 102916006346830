import { AddRounded, CloseRounded, EditRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link as MuiLink,
  Tab,
  Tabs,
  useMediaQuery,
} from '@mui/material';
import {
  GridActionsCellItem,
  type GridEnrichedColDef,
  type GridRenderCellParams,
  type GridRowModel,
  type GridRowParams,
  type GridRowsProp,
} from '@mui/x-data-grid-pro';
import { addYears } from 'date-fns';
import { type ReactNode, type SyntheticEvent, useContext, useState } from 'react';
import {
  type AssessmentControlPointEvidenceOutputItem,
  type EvidenceCreateOutput,
} from 'src/__generated__/InternalApiTypes';
import {
  CustomNoRowsOverlay,
  MobileCompatibleDataGrid,
} from 'src/components/common/MobileCompatibleDataGrid';
import { EvidenceAttachToggle } from 'src/components/EvidenceAttachToggle';
import {
  useAppDispatch,
  useDialogState,
  useGetAssessmentUserPermission,
  useTenantId,
} from 'src/hooks';
import { commonStrings, controlPointEvidenceDialogStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import {
  farmApi,
  type TransformedEvidenceListOutputItem,
  useCreateAssessmentControlPointEvidenceMutation,
  useGetAssessmentControlPointQuery,
  useGetControlPointQuery,
  useGetEvidenceListQuery,
  useGetEvidenceTypeListQuery,
} from 'src/services/farmApi';
import { TagType } from 'src/services/farmApi/TagType';
import { openSnackbar } from 'src/store/snackbarSlice';
import { appColors, theme } from 'src/theme';
import { dataGridValueFormatterForDate } from 'src/utils/dataGridUtils';

import { EvidenceEditDialog, type EvidenceEditDialogProps } from '../EvidenceEditDialog';
import { EvidencePreviewDialog, type EvidencePreviewDialogProps } from '../EvidencePreviewDialog';

export interface ControlPointEvidenceDialogProps {
  assessmentControlPointId: string;
  closePreviewDialog: () => void;
  evidenceTypeIds: string[];
  isOpen: boolean;
  isPreviewDialogOpen: boolean;
  onClose: () => void;
  openPreviewDialog: (detail: Omit<EvidencePreviewDialogProps, 'isOpen' | 'onClose'>) => void;
  previewDialogProps: Omit<EvidencePreviewDialogProps, 'isOpen' | 'onClose'>;
  controlPointId: string;
}

enum ControlPointEvidenceDialogDocumentTabs {
  Suggested = 'suggested',
  All = 'all',
}

export const ControlPointEvidenceDialog = ({
  assessmentControlPointId,
  closePreviewDialog,
  controlPointId,
  evidenceTypeIds,
  onClose: handleClose,
  openPreviewDialog,
  previewDialogProps,
  isOpen = false,
  isPreviewDialogOpen = false,
}: ControlPointEvidenceDialogProps): React.JSX.Element => {
  const dispatch = useAppDispatch();
  const tid = useTenantId();
  const [assessmentId] = useContext(AssessmentPageContext);
  const permissions = useGetAssessmentUserPermission(assessmentId);
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedTab, setSelectedTab] = useState<ControlPointEvidenceDialogDocumentTabs>(
    ControlPointEvidenceDialogDocumentTabs.Suggested,
  );
  const [isEditDialogOpen, openEditDialog, closeEditDialog, editDialogProps] =
    useDialogState<EvidenceEditDialogProps>();

  const skip = !controlPointId || !isOpen;
  const { data: controlPointDetail, isLoading: isControlPointDetailLoading } =
    useGetControlPointQuery(controlPointId, { skip });
  const { data: evidenceList, isLoading: isEvidenceListLoading } = useGetEvidenceListQuery(
    { tid, is_expired: false, is_archived: false },
    { skip: skip || !tid || selectedTab !== ControlPointEvidenceDialogDocumentTabs.All },
  );
  const { data: suggestedEvidenceList, isLoading: isSuggestedEvidenceListLoading } =
    useGetEvidenceListQuery({ tid, control_point_id: controlPointId }, { skip: skip || !tid });
  const { data: evidenceTypeList, isLoading: isEvidenceTypeListLoading } =
    useGetEvidenceTypeListQuery();
  const EMPTY_ARRAY: AssessmentControlPointEvidenceOutputItem[] = [];

  const { evidence: controlPointEvidenceLinks, isLoading: isControlPointEvidenceLinkLoading } =
    useGetAssessmentControlPointQuery(
      {
        tid,
        id: assessmentControlPointId,
      },
      {
        skip: !assessmentControlPointId || !tid,
        selectFromResult: ({ data, isLoading: isLoadingResult }) => ({
          evidence: data?.evidence ?? EMPTY_ARRAY,
          isLoading: isLoadingResult,
        }),
      },
    );

  const isLoading =
    isEvidenceListLoading ||
    isSuggestedEvidenceListLoading ||
    isEvidenceTypeListLoading ||
    isControlPointEvidenceLinkLoading ||
    isControlPointDetailLoading;

  const [createControlPointEvidence] = useCreateAssessmentControlPointEvidenceMutation();

  /**
   * Associate newly uploaded evidence with this control point (and assessment).
   * @param evidence - Evidence creation response data
   */
  const handleCreateSuccess = (evidence: EvidenceCreateOutput): void => {
    if (
      permissions?.evidence?.create &&
      tid &&
      assessmentId &&
      controlPointDetail?.uuid &&
      evidence?.uuid
    ) {
      createControlPointEvidence({
        tid,
        body: {
          assessment_id: assessmentId,
          control_point_id: controlPointDetail.uuid,
          evidence_id: evidence.uuid,
        },
      });
    } else {
      dispatch(
        openSnackbar({
          message: commonStrings('notAuthorized'),
          severity: 'error',
        }),
      );
    }
  };

  const handleTabChange = (
    _event: SyntheticEvent,
    newIndexValue: ControlPointEvidenceDialogDocumentTabs,
  ): void => {
    setSelectedTab(newIndexValue);
  };

  const columns: GridEnrichedColDef[] = [
    {
      field: 'name',
      headerName: commonStrings('name'),
      flex: 2,
      renderCell: (
        params: GridRenderCellParams<string, TransformedEvidenceListOutputItem, ReactNode>,
      ) => (
        <MuiLink
          onClick={() =>
            openPreviewDialog({
              evidenceId: String(params.id),
            })
          }
          sx={{
            cursor: 'pointer',
            color: appColors.blue,
          }}
          variant="body1"
        >
          {params.value}
        </MuiLink>
      ),
    },
    {
      field: 'evidence_type',
      headerName: commonStrings('documentType'),
      flex: 2,
    },
    {
      field: 'expiry_date',
      headerName: commonStrings('expiryDate'),
      type: 'date',
      flex: 1,
      valueFormatter: dataGridValueFormatterForDate,
    },
    {
      field: 'created_at',
      headerName: commonStrings('uploadDate'),
      type: 'date',
      flex: 1,
      valueFormatter: dataGridValueFormatterForDate,
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'right',
      flex: isSmallDevice ? 2.2 : 1.5,
      getActions: (params: GridRowParams<TransformedEvidenceListOutputItem>) => {
        const evidenceLinkId =
          controlPointEvidenceLinks?.find((link) => link.evidence_id === params.id)?.uuid || null;
        const filteredEvidenceList =
          selectedTab === ControlPointEvidenceDialogDocumentTabs.Suggested
            ? suggestedEvidenceList
            : evidenceList;
        const evidence = filteredEvidenceList?.find((e) => e.uuid === params.id);
        return [
          <Box
            key={`${params.id}_attach`}
            marginRight={1}
          >
            <EvidenceAttachToggle
              controlPointId={controlPointId}
              evidence={evidence}
              evidenceLinkId={evidenceLinkId}
            />
          </Box>,
          <GridActionsCellItem
            key={`${params.id}_edit`}
            icon={<EditRounded />}
            label={commonStrings('edit')}
            onClick={() => {
              openEditDialog({
                evidenceTypeIds:
                  selectedTab === ControlPointEvidenceDialogDocumentTabs.Suggested
                    ? evidenceTypeIds
                    : [params.row.evidence_type_id],
                evidenceId: String(params.id),
                hideFileUpload: true,
                submitButtonText: commonStrings('save'),
                title: commonStrings('editingDialogTitle', {
                  name: params.row.name,
                }),
              });
            }}
          />,
        ];
      },
    },
  ];

  const selectedEvidenceList =
    selectedTab === ControlPointEvidenceDialogDocumentTabs.Suggested
      ? suggestedEvidenceList
      : evidenceList;

  const rows: GridRowsProp = (selectedEvidenceList || []).map(
    (evidence): GridRowModel => ({
      uuid: evidence.uuid,
      name: evidence.name,
      evidence_type_id: evidence.evidence_type_id,
      evidence_type:
        evidenceTypeList?.find((evidenceType) => evidenceType.uuid === evidence.evidence_type_id)
          ?.name || '',
      expiry_date: evidence.expiry_date,
      created_at: evidence.created_at,
    }),
  );

  const closeDialog = () => {
    // We don't want to invalidate this tag everytime we toggle evidence, but we do need to
    // refresh the control point list when we close the dialog, so we do it manually here.
    dispatch(farmApi.util.invalidateTags([TagType.AssessmentControlPointEvidenceFilter]));
    handleClose();
    setSelectedTab(ControlPointEvidenceDialogDocumentTabs.Suggested);
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={closeDialog}
        open={isOpen}
      >
        <IconButton
          onClick={closeDialog}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            padding: 1.5,
          }}
        >
          <CloseRounded />
        </IconButton>
        <DialogTitle>
          {controlPointEvidenceDialogStrings('attachDocumentForQuestionCode', {
            questionCode: controlPointDetail?.display_code || controlPointDetail?.code,
          })}
        </DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="row"
            gap={1}
            justifyContent="flex-end"
            marginBottom={2.5}
            width="100%"
          >
            <Button
              color="primary"
              id="attach_file_button"
              onClick={() =>
                openEditDialog({
                  evidenceTypeIds,
                  title: commonStrings('uploadDocument'),
                  defaultValues: {
                    expiry_date: addYears(new Date(), 1),
                  },
                })
              }
              size="large"
              startIcon={<AddRounded />}
              variant="outlined"
            >
              {commonStrings('uploadNewDocument')}
            </Button>
          </Box>
          <Tabs
            onChange={handleTabChange}
            TabIndicatorProps={{ sx: { height: 4 } }}
            value={selectedTab}
          >
            <Tab
              label={controlPointEvidenceDialogStrings('suggestedDocuments')}
              sx={{ px: 1.5 }}
              value={ControlPointEvidenceDialogDocumentTabs.Suggested}
            />
            <Tab
              label={controlPointEvidenceDialogStrings('allDocuments')}
              sx={{ px: 1.5 }}
              value={ControlPointEvidenceDialogDocumentTabs.All}
            />
          </Tabs>
          <MobileCompatibleDataGrid
            columns={columns}
            disableColumnSelector
            loading={isLoading}
            NoRowsOverlay={() => CustomNoRowsOverlay(commonStrings('uploadDocumentTableEmptyMsg'))}
            rows={rows}
            rowsPerPageOptions={[10, 15, 25]}
            sx={{ minHeight: '50vh', my: 3 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={closeDialog}
            variant="contained"
          >
            {commonStrings('complete')}
          </Button>
        </DialogActions>
      </Dialog>
      <EvidencePreviewDialog
        isOpen={isPreviewDialogOpen}
        onClose={closePreviewDialog}
        {...previewDialogProps}
      />
      {isOpen && (
        <EvidenceEditDialog
          isOpen={isEditDialogOpen}
          onClose={closeEditDialog}
          onCreateSuccess={handleCreateSuccess}
          {...editDialogProps}
        />
      )}
    </>
  );
};
