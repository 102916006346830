import {
  type OrganizationPlotCultivationCreateInput,
  type OrganizationPlotCultivationCreateOutput,
  type OrganizationPlotCultivationDetailOutput,
  type OrganizationPlotCultivationListOutputItem,
  type OrganizationPlotCultivationUpdateInput,
  type OrganizationPlotCultivationUpdateOutput,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import {
  type CreateParamsWithTenantId,
  type DeleteParamsWithTenantId,
  type DetailParamsWithTenantId,
  type UpdateParamsWithTenantId,
} from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'organization-plot-cultivations';

const organizationPlotCultivationEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationPlotCultivationList: build.query<
      OrganizationPlotCultivationListOutputItem[],
      { tid: string }
    >({
      query: ({ tid }) => `/tid/${tid}/${RESOURCE}/`,
      providesTags: [TagType.OrganizationPlotCultivation],
    }),

    getOrganizationPlotCultivation: build.query<
      OrganizationPlotCultivationDetailOutput,
      DetailParamsWithTenantId
    >({
      query: ({ id, tid }) => `/tid/${tid}/${RESOURCE}/${id}/`,
      providesTags: [TagType.OrganizationPlotCultivation, TagType.UserProfile],
    }),

    createOrganizationPlotCultivation: build.mutation<
      OrganizationPlotCultivationCreateOutput,
      CreateParamsWithTenantId<OrganizationPlotCultivationCreateInput>
    >({
      query: ({ body, tid }) => ({
        body,
        url: `/tid/${tid}/${RESOURCE}/create/`,
        method: 'POST',
      }),
      invalidatesTags: [TagType.OrganizationPlotCultivation],
    }),

    deleteOrganizationPlotCultivation: build.mutation<void, DeleteParamsWithTenantId>({
      query: ({ id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/delete/`,
        method: 'DELETE',
      }),
      invalidatesTags: [TagType.OrganizationPlotCultivation],
    }),

    updateOrganizationPlotCultivation: build.mutation<
      OrganizationPlotCultivationUpdateOutput,
      UpdateParamsWithTenantId<OrganizationPlotCultivationUpdateInput>
    >({
      query: ({ body, id, tid }) => ({
        body,
        url: `/tid/${tid}/${RESOURCE}/${id}/update/`,
        method: 'PATCH',
      }),
      invalidatesTags: [TagType.OrganizationPlotCultivation],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateOrganizationPlotCultivationMutation,
  useDeleteOrganizationPlotCultivationMutation,
  useGetOrganizationPlotCultivationListQuery,
  useGetOrganizationPlotCultivationQuery,
  useUpdateOrganizationPlotCultivationMutation,
} = organizationPlotCultivationEndpoints;
