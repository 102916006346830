import {
  type OrganizationClientIdentifierCreateInput,
  type OrganizationClientIdentifierCreateOutput,
  type OrganizationClientIdentifierListOutputItem,
  type OrganizationClientIdentifierMoveStandardInput,
  type OrganizationClientIdentifierMoveStandardOutput,
  type OrganizationClientIdentifierUpdateInput,
  type OrganizationClientIdentifierUpdateOutput,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import {
  type CreateParamsWithTenantId,
  type DeleteParamsWithTenantId,
  type UpdateParamsWithTenantId,
} from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'organization-client-identifiers';

const organizationClientIdentifierEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationClientIdentifiersList: build.query<
      OrganizationClientIdentifierListOutputItem[],
      string
    >({
      query: (tid) => `/tid/${tid}/${RESOURCE}/`,
      providesTags: (_result, _error, _params) => [TagType.OrganizationClientIdentifiers],
    }),
    createOrganizationClientIdentifier: build.mutation<
      OrganizationClientIdentifierCreateOutput,
      CreateParamsWithTenantId<OrganizationClientIdentifierCreateInput>
    >({
      query: ({ tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/create/`,
        method: 'POST',
        body,
      }),
      // Push the response to the existing cache of client identifiers to avoid a new query.
      async onQueryStarted({ tid }, { dispatch, queryFulfilled }) {
        try {
          const { data: createdClientIdentifier } = await queryFulfilled;
          dispatch(
            organizationClientIdentifierEndpoints.util.updateQueryData(
              'getOrganizationClientIdentifiersList',
              tid,
              (draftClientIdentifiers) => {
                draftClientIdentifiers.push(createdClientIdentifier);
              },
            ),
          );
        } catch {
          // Do nothing.
        }
      },
    }),
    updateOrganizationClientIdentifier: build.mutation<
      OrganizationClientIdentifierUpdateOutput,
      UpdateParamsWithTenantId<OrganizationClientIdentifierUpdateInput>
    >({
      query: ({ id, tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/update/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [TagType.OrganizationClientIdentifiers],
    }),
    moveStandardOrganizationClientIdentifier: build.mutation<
      OrganizationClientIdentifierMoveStandardOutput,
      UpdateParamsWithTenantId<OrganizationClientIdentifierMoveStandardInput>
    >({
      query: ({ id, tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/move-standard/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [TagType.OrganizationClientIdentifiers],
    }),
    deleteOrganizationClientIdentifier: build.mutation<void, DeleteParamsWithTenantId>({
      query: ({ id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/delete/`,
        method: 'DELETE',
      }),
      invalidatesTags: [TagType.OrganizationClientIdentifiers],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetOrganizationClientIdentifiersListQuery,
  useCreateOrganizationClientIdentifierMutation,
  useUpdateOrganizationClientIdentifierMutation,
  useMoveStandardOrganizationClientIdentifierMutation,
  useDeleteOrganizationClientIdentifierMutation,
} = organizationClientIdentifierEndpoints;
