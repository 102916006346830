import { CloseRounded } from '@mui/icons-material';
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  Typography,
} from '@mui/material';
import {
  type DataGridProProps,
  type GridEnrichedColDef,
  type GridGroupingColDefOverride,
} from '@mui/x-data-grid-pro';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  type AssessmentModuleProgressOutput,
  type AssessmentModuleSidebarOutputItem,
} from 'src/__generated__/InternalApiTypes';
import { LoadingArea } from 'src/components/common';
import { MobileCompatibleDataGrid } from 'src/components/common/MobileCompatibleDataGrid';
import { useTenantId } from 'src/hooks';
import { assessmentControlPointStepProgressDialogStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import {
  useGetAssessmentProgressBarQuery,
  useGetModuleListQuery,
  useGetStandardLogoListQuery,
  useLazyGetAssessmentProgressQuery,
} from 'src/services/farmApi';
import { appColors } from 'src/theme';

export interface AssessmentControlPointStepProgressDialogProps {
  assessmentBaseModules: AssessmentModuleSidebarOutputItem[];
  assessmentLevel1Modules: AssessmentModuleSidebarOutputItem[];
  assessmentLevel2Modules: AssessmentModuleSidebarOutputItem[];
  isOpen: boolean;
  onClose: () => void;
}

interface RowType {
  id: string;
  rowData: AssessmentModuleProgressOutput[];
}

const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.hierarchy;

const CUSTOM_GROUPING_COL_DEF: GridGroupingColDefOverride = {
  headerName: '',
  flex: 1,
  hideDescendantCount: true,
  valueGetter: (params) => params?.row?.module_name,
};

export const AssessmentControlPointStepProgressDialog = ({
  assessmentBaseModules,
  assessmentLevel1Modules,
  assessmentLevel2Modules,
  onClose,
  isOpen = false,
}: AssessmentControlPointStepProgressDialogProps): React.JSX.Element => {
  const tid = useTenantId();
  const [assessmentId] = useContext(AssessmentPageContext);
  const [rows, setRows] = useState<RowType[]>();
  const { data: standardLogos } = useGetStandardLogoListQuery();
  const { data: moduleList } = useGetModuleListQuery(
    {
      has_parent: false,
    },
    { skip: !assessmentId },
  );
  const { data: assessmentProgressTotal, isLoading: isAssessmentProgressTotalLoading } =
    useGetAssessmentProgressBarQuery(
      { tid, id: assessmentId },
      { skip: !assessmentId || !tid || !isOpen },
    );

  const [getAssessmentProgress] = useLazyGetAssessmentProgressQuery();

  useEffect(() => {
    const getBaseModuleProgressData = async () => {
      const baseModuleRows: RowType[] = [];
      await Promise.all(
        assessmentBaseModules.map(async (baseModule) => {
          const { data: moduleListOfProgress } = await getAssessmentProgress({
            tid,
            id: assessmentId,
            module_id: baseModule.uuid,
          });
          const mappedModuleListOfProgress = moduleListOfProgress?.map((module) => {
            if (module.module_level === 0) {
              return {
                ...module,
                module_name: assessmentControlPointStepProgressDialogStrings('allChapters'),
                hierarchy: [...module.hierarchy, module.module_id],
              };
            }
            return {
              ...module,
              hierarchy: [...module.hierarchy, module.module_id],
            };
          });
          baseModuleRows.push({
            id: baseModule.uuid,
            rowData: mappedModuleListOfProgress,
          });
        }),
      );

      setRows(baseModuleRows);
    };

    if (isOpen) {
      getBaseModuleProgressData();
    }
  }, [
    assessmentBaseModules,
    assessmentId,
    assessmentLevel1Modules,
    assessmentLevel2Modules,
    getAssessmentProgress,
    isOpen,
    tid,
  ]);

  const closeDialog = () => {
    onClose();
  };

  const columns: GridEnrichedColDef[] = useMemo(
    () => [
      {
        field: 'answered_questions',
        headerName: assessmentControlPointStepProgressDialogStrings('answeredQuestionsAndTotal'),
        type: 'string',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params) => `${params.value} / ${params?.row?.total_questions}`,
      },
      {
        field: 'progress',
        headerName: '%',
        type: 'string',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
      },
    ],
    [],
  );

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      onClose={closeDialog}
      open={isOpen}
      PaperProps={{
        style: {
          height: '88%',
        },
      }}
    >
      <IconButton
        onClick={closeDialog}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          padding: 1.5,
        }}
      >
        <CloseRounded />
      </IconButton>
      <DialogTitle>
        {assessmentControlPointStepProgressDialogStrings('selfAssessmentOverview')}
      </DialogTitle>
      <DialogContent>
        {assessmentProgressTotal?.progress >= 0 && (
          <>
            <Typography variant="h3">
              {assessmentControlPointStepProgressDialogStrings('totalSelfAssessmentProgress')}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 3 }}>
              <Box sx={{ width: '100%', mr: 2, position: 'relative', mt: 0.75 }}>
                <LinearProgress
                  sx={{
                    height: 12,
                    borderRadius: 6,
                    [`&.${linearProgressClasses.colorPrimary}`]: {
                      backgroundColor: appColors.black8,
                    },
                  }}
                  value={assessmentProgressTotal.progress}
                  variant="determinate"
                />
                {assessmentProgressTotal?.auto_filled_progress ? (
                  <>
                    <Box
                      sx={{
                        bgcolor: appColors.greenProgress,
                        height: 12,
                        position: 'absolute',
                        top: 0,
                        minWidth: 6,
                        borderTopLeftRadius: 6,
                        borderBottomLeftRadius: 6,
                        width: `${assessmentProgressTotal.auto_filled_progress}%`,
                      }}
                    />
                    <Box
                      sx={{
                        height: '0.57px',
                        position: 'absolute',
                        backgroundColor: appColors.purple50,
                        left: 0,
                        top: 12,
                        transform: 'translateX(3%) rotate(-171.57deg)',
                        width: 81.46,
                      }}
                    />
                    <Chip
                      label={`${assessmentProgressTotal.auto_filled_progress}% ${assessmentControlPointStepProgressDialogStrings('autoFilledControlPoints')}`}
                      size="small"
                      sx={{
                        mt: 0.75,
                        borderColor: appColors.purple50,
                        '& .MuiChip-label': { color: appColors.purple },
                      }}
                      variant="outlined"
                    />
                  </>
                ) : null}
              </Box>
              <Box sx={{ minWidth: 40 }}>
                <Typography>{`${assessmentProgressTotal.progress}%`}</Typography>
              </Box>
            </Box>
          </>
        )}
        <Typography variant="h3">
          {assessmentBaseModules?.length > 1
            ? assessmentControlPointStepProgressDialogStrings('selfAssessmentProgressByStandard')
            : assessmentControlPointStepProgressDialogStrings('selfAssessmentProgressByChapter')}
        </Typography>
        {rows ? (
          assessmentBaseModules?.map((baseModule) => {
            const matchedStandardId = moduleList.find(
              (m) => m.uuid === baseModule.uuid,
            )?.standard_id;
            const matchedLogo = Object.values(standardLogos)?.find(
              (logo) => logo.standard_id === matchedStandardId,
            );
            return (
              <Box
                key={baseModule.uuid}
                sx={{ mt: 3.5 }}
              >
                {assessmentBaseModules?.length > 1 && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: 2,
                    }}
                  >
                    {matchedLogo && (
                      <img
                        alt={`list item: ${matchedLogo.name}`}
                        src={matchedLogo.file_object}
                        style={{
                          marginRight: '8px',
                          height: '40px',
                          width: '40px',
                        }}
                      />
                    )}
                    <Typography variant="button">{baseModule.name}</Typography>
                  </Box>
                )}
                <MobileCompatibleDataGrid
                  autoHeight
                  columns={columns}
                  disableColumnSelector
                  disableVirtualization
                  getRowId={(row) => row.module_id}
                  getTreeDataPath={getTreeDataPath}
                  groupingColDef={CUSTOM_GROUPING_COL_DEF}
                  hideFooter
                  hideToolbar
                  loading={isAssessmentProgressTotalLoading}
                  rows={rows.find((r) => r.id === baseModule.uuid)?.rowData || []}
                  treeData
                />
              </Box>
            );
          })
        ) : (
          <LoadingArea />
        )}
      </DialogContent>
    </Dialog>
  );
};
