import { DeleteRounded, EditRounded } from '@mui/icons-material';
import { Menu, MenuItem, Typography } from '@mui/material';
import { type Dispatch, type SetStateAction } from 'react';
import { commonStrings } from 'src/languages/en-UK';

interface SelectionListKebabMenuProps {
  onClose: () => void;
  onDeleteCultivationClick: () => void;
  onEditCultivationClick: () => void;
  setThreeDotsMenuAnchor: Dispatch<SetStateAction<HTMLElement | null>>;
  showDeleteItem: boolean;
  showEditItem: boolean;
  threeDotsMenuAnchor: HTMLElement | null;
}

export const SelectionListKebabMenu = ({
  onClose,
  onDeleteCultivationClick,
  onEditCultivationClick,
  setThreeDotsMenuAnchor,
  threeDotsMenuAnchor,
  showDeleteItem = false,
  showEditItem = false,
}: SelectionListKebabMenuProps): React.JSX.Element => {
  const handleCloseMenu = () => {
    onClose();
    setThreeDotsMenuAnchor(null);
  };

  return (
    <Menu
      anchorEl={threeDotsMenuAnchor}
      id="edit-delete-cultivation-menu"
      MenuListProps={{ 'aria-labelledby': 'edit-delete-cultivation-button' }}
      onClose={handleCloseMenu}
      open
    >
      {showEditItem && (
        <MenuItem onClick={onEditCultivationClick}>
          <EditRounded />
          <Typography
            marginLeft={4}
            variant="body1"
          >
            {commonStrings('edit')}
          </Typography>
        </MenuItem>
      )}
      {showDeleteItem && (
        <MenuItem onClick={onDeleteCultivationClick}>
          <DeleteRounded color="error" />
          <Typography
            color="error"
            marginLeft={4}
            variant="body1"
          >
            {commonStrings('delete')}
          </Typography>
        </MenuItem>
      )}
    </Menu>
  );
};
