import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { assessmentFinishDialogForFarmManagersStrings, commonStrings } from 'src/languages/en-UK';

interface AssessmentFinishDialogForFarmManagersProps {
  onClose: () => void;
  onFinish: () => void;
}

export const AssessmentFinishDialogForFarmManagers = ({
  onClose,
  onFinish,
}: AssessmentFinishDialogForFarmManagersProps): React.JSX.Element => (
  <>
    <DialogTitle>
      {assessmentFinishDialogForFarmManagersStrings('selfAssessmentWasSaved')}
    </DialogTitle>
    <DialogContent>
      <Typography
        component="span"
        variant="body1"
      >
        {assessmentFinishDialogForFarmManagersStrings(
          'assessmentFinishDialogCompleteDescriptionForFarmManagers',
        )}
        <Typography
          component="span"
          fontWeight={700}
          variant="body1"
        >
          {commonStrings('complete')}
          <Typography
            color="error"
            component="span"
            fontWeight={700}
            variant="body1"
          >
            *
          </Typography>
        </Typography>
      </Typography>
      <Box marginTop={1} />
      <Typography
        component="span"
        variant="body1"
      >
        {assessmentFinishDialogForFarmManagersStrings(
          'assessmentFinishDialogContinueLaterDescriptionForFarmManagers',
        )}
        <Typography
          component="span"
          fontWeight={700}
          variant="body1"
        >
          {`${assessmentFinishDialogForFarmManagersStrings('continueLater')}.`}
        </Typography>
        {assessmentFinishDialogForFarmManagersStrings(
          'assessmentFinishDialogSubtitleForFarmManagers',
        )}
      </Typography>
      <Box marginTop={4} />
      <Typography
        component="span"
        variant="caption"
      >
        <Typography
          color="error"
          component="span"
          fontWeight={700}
          variant="body1"
        >
          *
        </Typography>
        {assessmentFinishDialogForFarmManagersStrings(
          'assessmentFinishDialogDescriptionForFarmManagers',
        )}
      </Typography>
    </DialogContent>
    <DialogActions
      sx={{
        mt: 3,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Button
        onClick={onClose}
        variant="outlined"
      >
        {assessmentFinishDialogForFarmManagersStrings('continueLater')}
      </Button>
      <Button
        onClick={onFinish}
        variant="outlined"
      >
        {commonStrings('complete')}
      </Button>
    </DialogActions>
  </>
);
