import { DownloadRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Link as MuiLink, useMediaQuery } from '@mui/material';
import {
  GridActionsCellItem,
  type GridEnrichedColDef,
  type GridRenderCellParams,
  type GridRowParams,
} from '@mui/x-data-grid-pro';
import { addYears } from 'date-fns';
import { type ReactNode, useState } from 'react';
import { type HelpDocumentListOutputItem } from 'src/__generated__/InternalApiTypes';
import {
  imageFileExtensions,
  supportedEditingFileExtensions,
} from 'src/components/common/DocumentPreview';
import {
  CustomNoRowsOverlay,
  MobileCompatibleDataGrid,
} from 'src/components/common/MobileCompatibleDataGrid';
import {
  CreateEvidenceDialog,
  type CreateEvidenceDialogProps,
} from 'src/components/dialogs/CreateEvidenceDialog';
import {
  HelpDocumentPreviewDialog,
  type HelpDocumentPreviewDialogProps,
} from 'src/components/dialogs/HelpDocumentPreviewDialog';
import { useAppDispatch, useDialogState, useMutationFeedback, useTenantId } from 'src/hooks';
import { commonStrings } from 'src/languages/en-UK';
import {
  extractErrorMessage,
  type TransformedEvidenceListOutputItem,
  useCreateEvidenceMutation,
  useGetHelpDocumentListQuery,
  useGetHelpDocumentTypeListQuery,
} from 'src/services/farmApi';
import { openSnackbar } from 'src/store/snackbarSlice';
import { appColors, theme } from 'src/theme';
import { createDataGridLinkedIdValueGetter } from 'src/utils/createDataGridLinkedIdValueGetter';
import { dataGridValueFormatterForDate } from 'src/utils/dataGridUtils';
import { downloadFileToLocal, getFileExtension, getFileSourceFromUrl } from 'src/utils/fileUtils';

export const EvidenceFormsList = (): React.JSX.Element => {
  const dispatch = useAppDispatch();
  const tid = useTenantId();
  const isSmallDesktop = useMediaQuery(theme.breakpoints.down('lg'));
  const [createEvidence, createEvidenceResult] = useCreateEvidenceMutation();
  const [isLoadingDocument, setIsLoadingDocument] = useState(false);
  const [clickedButtonId, setClickedButtonId] = useState<string | null>(null);
  const { data: evidenceFormList, isLoading: isHelpDocumentListLoading } =
    useGetHelpDocumentListQuery({
      is_evidence_form: true,
    });
  const { data: helpDocumentTypeList, isLoading: isHelpDocumentTypeListLoading } =
    useGetHelpDocumentTypeListQuery();

  const [
    isCreateEvidenceDialogOpen,
    openCreateEvidenceDialog,
    closeCreateEvidenceDialog,
    createEvidenceDialogProps,
  ] = useDialogState<CreateEvidenceDialogProps>();
  const [isPreviewDialogOpen, openPreviewDialog, closePreviewDialog, previewDialogProps] =
    useDialogState<HelpDocumentPreviewDialogProps>();

  useMutationFeedback({
    result: createEvidenceResult,
    onSuccess: (response) => {
      if (response) {
        setClickedButtonId(null);
        setIsLoadingDocument(false);
        openCreateEvidenceDialog({
          evidenceId: String(response.uuid),
          isSignatureRequired: response.requires_signature,
        });
      }
    },
    onError: () => {
      setClickedButtonId(null);
      setIsLoadingDocument(false);
    },
    successMessage: commonStrings('createDraftDocumentWithEvidenceFormSuccess'),
    errorMessage: commonStrings('createDraftDocumentWithEvidenceFormFail'),
  });

  const handleCreateEvidence = async (
    selectedHelpDocument: HelpDocumentListOutputItem,
  ): Promise<void> => {
    if (selectedHelpDocument?.file?.file_object) {
      setClickedButtonId(selectedHelpDocument.uuid);
      setIsLoadingDocument(true);
      try {
        const [fileSource, newDocumentTitle] = await getFileSourceFromUrl(
          selectedHelpDocument.file,
          selectedHelpDocument.name,
        );
        if (tid) {
          createEvidence({
            tid,
            body: {
              file: fileSource,
              name: newDocumentTitle,
              expiry_date: addYears(new Date(), 1),
              is_draft: true,
              source_help_document_id: selectedHelpDocument.uuid,
            },
          });
        }
      } catch (error) {
        setClickedButtonId(null);
        setIsLoadingDocument(false);
        const message = extractErrorMessage(error);
        dispatch(openSnackbar({ message, severity: 'error' }));
      }
    }
  };

  const handleDownload = (row: HelpDocumentListOutputItem): void => {
    downloadFileToLocal(row?.file, row?.name, (msg) =>
      dispatch(openSnackbar({ message: msg, severity: 'error' })),
    );
  };

  const columns: GridEnrichedColDef[] = [
    {
      field: 'name',
      headerName: commonStrings('name'),
      flex: 2.5,
      renderCell: (
        params: GridRenderCellParams<string, TransformedEvidenceListOutputItem, ReactNode>,
      ) => (
        <MuiLink
          onClick={() =>
            openPreviewDialog({
              helpDocumentId: String(params.id),
            })
          }
          sx={{
            cursor: 'pointer',
            color: appColors.blue,
          }}
          variant="body1"
        >
          {params.value}
        </MuiLink>
      ),
    },
    {
      field: 'document_type',
      headerName: commonStrings('documentType'),
      type: 'string',
      flex: 1.5,
      valueGetter: createDataGridLinkedIdValueGetter(helpDocumentTypeList, 'help_document_type_id'),
    },
    {
      field: 'organization_name',
      headerName: commonStrings('publishedBy'),
      type: 'string',
      flex: 1.5,
    },
    {
      field: 'expiry_date',
      headerName: commonStrings('expiryDate'),
      type: 'date',
      flex: 1,
      valueFormatter: dataGridValueFormatterForDate,
    },
    {
      field: 'created_at',
      headerName: commonStrings('uploadedDate'),
      type: 'date',
      flex: 1,
      valueFormatter: dataGridValueFormatterForDate,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: isSmallDesktop ? 2.5 : 1.4,
      align: 'right',
      getActions: (params: GridRowParams<HelpDocumentListOutputItem>) => {
        const buttons = [];
        const fileSource: string | undefined = params.row.file?.file_object;
        if (fileSource) {
          const fileType = getFileExtension(fileSource);
          const isNotEditableFile =
            imageFileExtensions.includes(fileType) ||
            !supportedEditingFileExtensions.includes(fileType);
          if (!isNotEditableFile) {
            buttons.push(
              <LoadingButton
                key={`${params.row.uuid}_create`}
                color="info"
                disabled={isLoadingDocument}
                loading={isLoadingDocument && clickedButtonId === params.row.uuid}
                onClick={() => handleCreateEvidence(params.row)}
                size="small"
                variant="contained"
              >
                {commonStrings('createEvidence')}
              </LoadingButton>,
            );
          }
        }

        buttons.push(
          <GridActionsCellItem
            key={`${params.row.uuid}_download`}
            icon={<DownloadRounded />}
            label={commonStrings('download')}
            onClick={() => handleDownload(params.row)}
            showInMenu
          />,
        );
        return buttons;
      },
    },
  ];

  const isLoading = isHelpDocumentListLoading || isHelpDocumentTypeListLoading;

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="calc(100vh - 280px)"
    >
      <MobileCompatibleDataGrid
        columns={columns}
        loading={isLoading}
        NoRowsOverlay={() => CustomNoRowsOverlay(commonStrings('evidenceTableNoRowsMsg'))}
        rows={evidenceFormList || []}
        sortModel={[
          {
            field: 'created_at',
            sort: 'desc',
          },
        ]}
      />
      <HelpDocumentPreviewDialog
        isOpen={isPreviewDialogOpen}
        onClose={closePreviewDialog}
        {...previewDialogProps}
      />
      <CreateEvidenceDialog
        isOpen={isCreateEvidenceDialogOpen}
        onClose={closeCreateEvidenceDialog}
        {...createEvidenceDialogProps}
      />
    </Box>
  );
};
