import { InfoOutlined } from '@mui/icons-material';
import { Box, Container, Stack, Toolbar, Typography } from '@mui/material';
import { OrganizationTypesEnum, RolesEnum } from 'src/__generated__/InternalApiTypes';
import {
  DashboardAssessmentsBlock,
  type TextsType,
} from 'src/components/DashboardAssessmentsBlock';
import { DashboardOrganizationBlock } from 'src/components/DashboardOrganizationBlock';
import { DashboardRequestsBlock, RequestType } from 'src/components/DashboardRequestsBlock';
import { DashboardUserBlock } from 'src/components/DashboardUserBlock';
import { useCurrentOrganization, useCurrentUserRoles } from 'src/hooks';
import { useGetUserOrigin } from 'src/hooks/useGetUserOrigin';
import { commonStrings, dashboardPageStrings } from 'src/languages/en-UK';
import { SignUpOriginEnum } from 'src/services/farmApi/endpoints/auth';
import { appColors } from 'src/theme';

export const DashboardPage = (): React.JSX.Element => {
  const currentUserRoles = useCurrentUserRoles();
  const isFarmManager = currentUserRoles.includes(RolesEnum.FarmManager);
  const isFarmEmployee = currentUserRoles.includes(RolesEnum.FarmEmployee);
  const isAuditor = currentUserRoles.includes(RolesEnum.Auditor);
  const isCBManager = currentUserRoles.includes(RolesEnum.CertificationBodyManager);
  const { data: organization } = useCurrentOrganization();
  const origin = useGetUserOrigin();

  let titlesAndTexts: TextsType = {
    allTitle: dashboardPageStrings('allSelfAssessments'),
    createButton: commonStrings('startNewSelfAssessment'),
    openTitle: dashboardPageStrings('inProgressSelfAssessments'),
    title: commonStrings('selfAssessments'),
  };
  if (isCBManager) {
    titlesAndTexts = {
      allTitle: dashboardPageStrings('allInspections'),
      confirmTitle: dashboardPageStrings('confirmedInspections'),
      openTitle: dashboardPageStrings('newInspectionsToConfirm'),
      title: commonStrings('inspections'),
    };
  } else if (isAuditor) {
    titlesAndTexts = {
      allTitle: dashboardPageStrings('allInspections'),
      createButton: commonStrings('startInspection'),
      openTitle: dashboardPageStrings('inProgressInspections'),
      title: commonStrings('inspections'),
    };
  }

  return (
    <>
      <Toolbar />
      <Container
        maxWidth="xl"
        sx={{ pt: 5, pb: 5 }}
      >
        <Typography
          paddingBottom={3}
          variant="h1"
        >
          {isFarmManager || isFarmEmployee
            ? dashboardPageStrings('myOverview')
            : commonStrings('dashboard')}
        </Typography>
        {origin === SignUpOriginEnum.AGRIPLACE &&
          organization?.type === OrganizationTypesEnum.Farm && (
            <Box
              display="flex"
              flex={1}
              marginBottom={5}
              paddingX={2}
              paddingY={1.75}
              sx={{ bgcolor: appColors.info12 }}
            >
              <InfoOutlined
                color="info"
                fontSize="small"
                sx={{ marginTop: 0.25, marginRight: 1.5 }}
              />
              <Box>
                <Typography
                  fontWeight={500}
                  paddingBottom={0.5}
                  sx={{ color: appColors.alert }}
                  variant="body1"
                >
                  {dashboardPageStrings('bannerTitle')}
                </Typography>
                <Typography
                  color="textSecondary"
                  sx={{ color: appColors.alert }}
                  variant="body2"
                >
                  {dashboardPageStrings('bannerSubTitle')}
                </Typography>
              </Box>
            </Box>
          )}
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={3}
        >
          <DashboardAssessmentsBlock
            showCreateDialog={isAuditor}
            texts={titlesAndTexts}
          />
          {(isFarmManager || isFarmEmployee || (isAuditor && !isCBManager)) && (
            <DashboardRequestsBlock
              requestType={isAuditor ? RequestType.Outgoing : RequestType.Incoming}
            />
          )}
          {(isFarmManager || isFarmEmployee) && <DashboardOrganizationBlock />}
          {isCBManager && <DashboardUserBlock />}
        </Stack>
      </Container>
    </>
  );
};
