import { Box, Button, Skeleton, Typography } from '@mui/material';
import { dashboardBlockRowStrings } from 'src/languages/en-UK';

interface DashboardBlockRowProps {
  count: string;
  label: string;
  onClick: () => void;
  isLoading?: boolean;
}

export const DashboardBlockRow = ({
  count,
  label,
  onClick,
  isLoading = false,
}: DashboardBlockRowProps): React.JSX.Element => (
  <Box
    alignItems="center"
    display="flex"
    flexDirection="row"
    justifyContent="space-between"
  >
    <Box>
      <Typography
        fontWeight={500}
        variant="overline"
      >
        {label}
      </Typography>
      {isLoading ? (
        <Skeleton />
      ) : (
        <Typography
          color="primary"
          variant="h3"
        >
          {count}
        </Typography>
      )}
    </Box>
    <Button
      onClick={onClick}
      size="small"
      sx={{ maxHeight: 30 }}
      variant="outlined"
    >
      {dashboardBlockRowStrings('view')}
    </Button>
  </Box>
);
