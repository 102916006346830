import { CloseRounded } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  type AssessmentStandardRequestCreateOutput,
  CountryEnum,
} from 'src/__generated__/InternalApiTypes';
import { FormikCountryField } from 'src/components/formikcomponents/FormikCountryField';
import { FormikSelectField } from 'src/components/formikcomponents/FormikSelectField';
import { FormikTextField } from 'src/components/formikcomponents/FormikTextField';
import { useCurrentOrganization, useMutationFeedback, useTenantId } from 'src/hooks';
import { assessmentCreateDialogStrings, commonStrings } from 'src/languages/en-UK';
import {
  useCreateAssessmentMutation,
  useCreateAssessmentStandardRequestMutation,
} from 'src/services/farmApi';
import { ROUTE_PATH_ASSESSMENT } from 'src/settings';
import * as Yup from 'yup';

export interface AssessmentCreateDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AssessmentCreateDialog = ({
  onClose,
  isOpen = false,
}: AssessmentCreateDialogProps): React.JSX.Element => {
  const tid = useTenantId();
  const navigate = useNavigate();
  const [createRequestResponse, setCreateRequestResponse] =
    useState<AssessmentStandardRequestCreateOutput | null>(null);

  const [createRequest, createRequestResult] = useCreateAssessmentStandardRequestMutation();
  const [createAssessment, createAssessmentResult] = useCreateAssessmentMutation();
  const { data: organization } = useCurrentOrganization();
  const standardOptions = useMemo(
    () =>
      organization?.active_subscription_standards?.map((standard) => ({
        value: standard.uuid,
        label: standard.name,
      })) ?? [],
    [organization?.active_subscription_standards],
  );
  const selectedStandard = standardOptions?.length === 1 ? standardOptions[0]?.value : '';

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      selfAssessment: '0',
      standard: selectedStandard,
      clientIdNumber: '',
      farmOrganizationName: '',
      address: '',
      city: '',
      postalCode: '',
      country: '',
    },
    validationSchema: Yup.object().shape({
      selfAssessment: Yup.string().required(commonStrings('required')),
      standard: Yup.string().required(commonStrings('required')),
      clientIdNumber: Yup.string().required(commonStrings('required')),
      farmOrganizationName: Yup.string().required(commonStrings('required')),
      address: Yup.string().required(commonStrings('required')),
      city: Yup.string().required(commonStrings('required')),
      postalCode: Yup.string().required(commonStrings('required')),
      country: Yup.string().required(commonStrings('required')),
    }),
    onSubmit: (values) => {
      if (values.selfAssessment === '0') {
        if (tid) {
          createAssessment({
            tid,
            body: {
              standard_id: values.standard,
              client_identifier_value: values.clientIdNumber,
              metadata: {
                farm_name: values.farmOrganizationName,
                farm_address: values.address,
                farm_city: values.city,
                farm_postal_code: values.postalCode,
                farm_country: CountryEnum[values.country as keyof typeof CountryEnum],
              },
            },
          });
        }
      } else {
        const requestName = `${
          standardOptions.find((s) => s.value === values.standard)?.label
        }_${organization?.name}`.replace(/\s/g, '-');
        if (tid) {
          createRequest({
            tid,
            body: {
              name: requestName,
              standard_id: values.standard,
              client_identifier_value: values.clientIdNumber,
              organization_name: values.farmOrganizationName,
              organization_address: values.address,
              organization_city: values.city,
              organization_postal_code: values.postalCode,
              organization_country: CountryEnum[values.country as keyof typeof CountryEnum],
            },
          });
        }
      }
    },
  });

  const options = [
    { value: '1', label: commonStrings('yes') },
    { value: '0', label: commonStrings('no') },
  ];

  useMutationFeedback({
    result: createRequestResult,
    onSuccess: (result) => {
      setCreateRequestResponse(result);
    },
    errorMessage: assessmentCreateDialogStrings('createRequestFail'),
  });

  useMutationFeedback({
    result: createAssessmentResult,
    onSuccess: (result) => {
      navigate(generatePath(ROUTE_PATH_ASSESSMENT, { id: result.uuid }));
    },
    successMessage: commonStrings('backendCreateSuccessMessage', {
      name: createAssessmentResult?.data?.name,
    }),
    errorMessage: commonStrings('failedToCreateAssessment'),
  });

  const handleClose = () => {
    onClose();
    formik.resetForm();
    setCreateRequestResponse(null);
  };

  return (
    <Dialog
      fullWidth
      onClose={handleClose}
      open={isOpen}
    >
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          padding: 1.5,
        }}
      >
        <CloseRounded />
      </IconButton>
      <form
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <DialogContent>
          <Stack spacing={2}>
            <Typography
              marginBottom={1}
              variant="h2"
            >
              {commonStrings('startInspection')}
            </Typography>
            {createRequestResponse ? (
              <>
                <Typography variant="h3">{assessmentCreateDialogStrings('requestSent')}</Typography>
                <Typography variant="body1">
                  {assessmentCreateDialogStrings('waitForRequestToBeApproved')}
                </Typography>
                <Box
                  alignItems="center"
                  display="flex"
                  paddingLeft={0.5}
                  paddingY={0.5}
                >
                  <Avatar
                    sx={{
                      fontSize: 'auto',
                    }}
                  >
                    {createRequestResponse?.approver_name?.substring(0, 1).toUpperCase()}
                  </Avatar>
                  <Typography
                    marginLeft={0.5}
                    variant="body1"
                  >
                    {createRequestResponse?.approver_name}
                  </Typography>
                </Box>
              </>
            ) : (
              <Box
                display="grid"
                gap={2}
                gridTemplateAreas={`
              "selfAssessment selfAssessment"
              "standard standard"
              "farmOrganizationName farmOrganizationName"
              "address address"
              "city postalCode"
              "country country"
              "clientIdNumber clientIdNumber"
            `}
                gridTemplateColumns="repeat(2, 1fr)"
                gridTemplateRows="auto"
              >
                <FormikSelectField
                  formik={formik}
                  id="selfAssessment"
                  label={assessmentCreateDialogStrings(
                    'hasFarmManagerPreparedSelfAssessmentWithAgriplace',
                  )}
                  options={options}
                  required
                  sx={{ gridArea: 'selfAssessment' }}
                />
                <FormikSelectField
                  formik={formik}
                  id="standard"
                  label={assessmentCreateDialogStrings('selectStandard')}
                  options={standardOptions}
                  required
                  sx={{ gridArea: 'standard' }}
                />
                <FormikTextField
                  formik={formik}
                  id="farmOrganizationName"
                  label={commonStrings('farmOrganizationName')}
                  required
                  sx={{ gridArea: 'farmOrganizationName' }}
                />
                <FormikTextField
                  formik={formik}
                  id="address"
                  label={commonStrings('address')}
                  required
                  sx={{ gridArea: 'address' }}
                />
                <FormikTextField
                  formik={formik}
                  id="city"
                  label={commonStrings('city')}
                  required
                  sx={{ gridArea: 'city' }}
                />
                <FormikTextField
                  formik={formik}
                  id="postalCode"
                  label={commonStrings('postalCode')}
                  required
                  sx={{ gridArea: 'postalCode' }}
                />
                <FormikCountryField
                  formik={formik}
                  id="country"
                  label={commonStrings('country')}
                  required
                  sx={{ gridArea: 'country' }}
                />
                <FormikTextField
                  formik={formik}
                  id="clientIdNumber"
                  label={assessmentCreateDialogStrings('clientIdNumber')}
                  required
                  sx={{ gridArea: 'clientIdNumber' }}
                />
              </Box>
            )}
          </Stack>
        </DialogContent>
        {createRequestResponse ? (
          <DialogActions sx={{ justifyContent: 'center', pt: 3 }}>
            <Button
              onClick={handleClose}
              variant="outlined"
            >
              {commonStrings('close')}
            </Button>
          </DialogActions>
        ) : (
          <DialogActions sx={{ justifyContent: 'space-between', pt: 3 }}>
            <LoadingButton
              disabled={formik.values.selfAssessment === '1'}
              loading={createAssessmentResult.isLoading}
              type="submit"
              variant="outlined"
            >
              {assessmentCreateDialogStrings('startNewInspection')}
            </LoadingButton>
            <LoadingButton
              disabled={formik.values.selfAssessment === '0'}
              loading={createRequestResult.isLoading}
              type="submit"
              variant="contained"
            >
              {assessmentCreateDialogStrings('requestAccess')}
            </LoadingButton>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};
