import {
  type ConnectedAssessmentCreateInput,
  type ConnectedAssessmentCreateOutput,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { type CreateParamsWithTenantId } from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'connected-assessments';

const connectedAssessmentEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createConnectedAssessment: build.mutation<
      ConnectedAssessmentCreateOutput,
      CreateParamsWithTenantId<ConnectedAssessmentCreateInput>
    >({
      query: ({ tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/create/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: TagType.ConnectedAssessment, id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const { useCreateConnectedAssessmentMutation } = connectedAssessmentEndpoints;
