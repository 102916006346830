import { KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { isEqual } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  AnswerTypeEnum,
  type AssessmentControlPointAnswerListOutputItem,
  type AssessmentControlPointInputOutputItem,
  type AssessmentControlPointListOutputItem,
  ControlPointAnswerTypeEnum,
  LevelEnum,
  type OrganizationCultivationsAssessmentControlPointCultivationList,
} from 'src/__generated__/InternalApiTypes';
import { ControlPointMasterDataContext } from 'src/context/ControlPointMasterData';
import { type DynamicObjectType } from 'src/global';
import {
  useAppDispatch,
  useGetAssessmentUserPermission,
  useMutationFeedback,
  useRoleCheck,
  useTenantId,
} from 'src/hooks';
import { commonStrings, controlPointAnswerStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import {
  useBulkUpdateAssessmentControlPointAnswerMutation,
  useCreateAssessmentControlPointAnswerMutation,
  useDeleteAssessmentControlPointAnswerMutation,
  useDeleteAssessmentControlPointInputMutation,
  useGetAssessmentControlPointQuery,
  useGetControlPointCultivationsQuery,
  useUpdateAssessmentControlPointAnswerMutation,
  useUpdateAssessmentControlPointInputMutation,
} from 'src/services/farmApi';
import { openSnackbar } from 'src/store/snackbarSlice';
import { getCultivationDisplayNameForControlPoint } from 'src/utils/getCultivationDisplayName';

import * as styles from '../common/ExpandableArea/ExpandableArea.module.scss';
import {
  AnswerChipStatus,
  ControlPointAnswerPoints,
  getComplianceStatusLabel,
  mapComplianceStatusToAnswerChip,
} from '../ControlPointAnswerPoints';
import { ControlPointButtonGroupAnswer } from '../ControlPointButtonGroupAnswer';
import { ControlPointDropdownAnswer, getDropdownData } from '../ControlPointDropdownAnswer';
import { ControlPointDynamicAnswer } from '../ControlPointDynamicAnswer';
import { ControlPointInputAnswer } from '../ControlPointInputAnswer';
import { ControlPointNonConformities } from '../ControlPointNonConformities';
import { ThemeContext } from '../ThemeProvider';
import { JustificationTextField } from './JustificationTextField';

interface ControlPointAnswerProps {
  assessmentControlPoint: AssessmentControlPointListOutputItem;
  existingAnswers: AssessmentControlPointAnswerListOutputItem[];
  hasPlotName: boolean;
  isAutoTriggerNonConformity: boolean;
  includeOnlyNonCompliantCultivations: boolean;
  isDisableAnswer: boolean;
  isDisableJustificationText: boolean;
  isDisableNonConformity: boolean;
  isExpanded: boolean;
  isLoading: boolean;
}

export const ControlPointAnswer = ({
  assessmentControlPoint,
  existingAnswers,
  hasPlotName,
  includeOnlyNonCompliantCultivations = false,
  isAutoTriggerNonConformity = false,
  isDisableAnswer = false,
  isDisableJustificationText = false,
  isDisableNonConformity = false,
  isExpanded = false,
  isLoading = false,
}: ControlPointAnswerProps): React.JSX.Element => {
  const tid = useTenantId();
  const [assessmentId] = useContext(AssessmentPageContext);
  const chipStatus = useRef(AnswerChipStatus.NoAnswer);
  const {
    uuid: assessmentControlPointId,
    code: controlPointCode,
    display_code: controlPointDisplayCode,
    control_point_answer_type: controlPointAnswerType,
    control_point_id: controlPointId,
    level: controlPointLevel,
    possible_answer_set_id: possibleAnswerSetId,
    is_scored: isScored,
  } = assessmentControlPoint || {};
  const EMPTY_ARRAY: AssessmentControlPointInputOutputItem[] = [];

  const { inputs: controlPointInputs, isLoading: isControlPointInputsLoading } =
    useGetAssessmentControlPointQuery(
      {
        tid,
        id: assessmentControlPointId,
      },
      {
        selectFromResult: ({ data, isLoading: isLoadingControlPointInputs }) => ({
          inputs: data?.inputs ?? EMPTY_ARRAY,
          isLoading: isLoadingControlPointInputs,
        }),
      },
    );
  const { theme } = useContext(ThemeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isNotExtraLargeDevice = useMediaQuery(theme.breakpoints.down('xl'));
  const isInputAnswer = controlPointInputs?.some((cp) => cp.control_point_input.is_answer);

  const { data: controlPointCultivations } = useGetControlPointCultivationsQuery({
    tid,
    uuid: assessmentId,
    include_only_non_compliant_cultivations: includeOnlyNonCompliantCultivations,
  });
  const { possibleAnswerSets, possibleAnswers } = useContext(ControlPointMasterDataContext);

  const { isCB } = useRoleCheck();
  const textField = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const permissions = useGetAssessmentUserPermission(assessmentId);

  const possibleAnswerSet = (possibleAnswerSets || []).find(
    (item) => item.uuid === possibleAnswerSetId,
  );

  const availablePossibleAnswers = possibleAnswerSet?.ordered_possible_answers.map((item) =>
    (possibleAnswers || []).find((possibleAnswer) => possibleAnswer.uuid === item.uuid),
  );

  const isJustificationRequired = existingAnswers?.some((e) => {
    const matchingAnswer = availablePossibleAnswers?.find((possibleAnswer) =>
      e.answer_ids.some((a) => a === possibleAnswer?.uuid),
    );
    return matchingAnswer && matchingAnswer.is_justification_required;
  });

  const [createAnswer, createAnswerResult] = useCreateAssessmentControlPointAnswerMutation();
  const [deleteAnswer, deleteAnswerResult] = useDeleteAssessmentControlPointAnswerMutation();
  const [updateAnswer, updateAnswerResult] = useUpdateAssessmentControlPointAnswerMutation();
  const [deleteInputAnswer, deleteInputAnswerResult] =
    useDeleteAssessmentControlPointInputMutation();
  const [updateInputAnswer, updateInputAnswerResult] =
    useUpdateAssessmentControlPointInputMutation();
  const [bulkUpdateAnswer, bulkUpdateAnswerResult] =
    useBulkUpdateAssessmentControlPointAnswerMutation();

  const containerRef = useRef(null);
  const [expandedIds, setExpandedIds] = useState([]);

  const handleCollapseChange = (id: string, autoCollapsed = false) => {
    const newArr = [...expandedIds];
    const visibleIndex = newArr.findIndex((e) => e === id);
    if (autoCollapsed) {
      if (visibleIndex < 0) {
        newArr.push(id);
        setExpandedIds(newArr);
      }
    } else {
      if (visibleIndex < 0) {
        newArr.push(id);
      } else {
        newArr.splice(visibleIndex, 1);
      }
      if (!isEqual(expandedIds, newArr)) {
        setExpandedIds(newArr);
      }
    }
  };

  useEffect(() => {
    if (isExpanded) {
      handleCollapseChange(controlPointId, true);
    }
    // TODOHasan: Do not add handleCollapseChange as a dependency. Find a correct way.
  }, [controlPointId, isExpanded]);

  /* Auto expand for the accordion
  const changeExpandedStatus = (entries) => {
    const [entry] = entries;
    const newArr = [...expandedIds];
    const visibleIndex = newArr.findIndex((e) => e === entry?.target?.id);
    if (entry.isIntersecting && visibleIndex < 0) {
      newArr.push(entry?.target?.id);
    }
    if (!entry.isIntersecting && visibleIndex >= 0) {
      newArr.splice(visibleIndex, 1);
    }
    if (!isEqual(expandedIds, newArr)) {
      setExpandedIds(newArr);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      thresholds: [0, 1],
    };
    const observer = new IntersectionObserver(changeExpandedStatus, options);
    if (containerRef?.current) {
      const newArr = [...expandedIds];
      const visibleIndex = newArr.findIndex((e) => e === containerRef?.current?.id);
      if (visibleIndex < 0) {
        newArr.push(containerRef.current.id);
        setExpandedIds(newArr);
      }
      observer.observe(containerRef.current);
    }
    return () => {
      observer.disconnect();
    };

    // TODOHasan: Do not add changeExpandedStatus and expandedIds as a dependency.
    // Find a correct way.
  }, [containerRef]); */

  const checkExist = (
    cultivationId?: string,
  ): [AssessmentControlPointAnswerListOutputItem, boolean] => {
    let answer;
    let isExisting = false;
    if (cultivationId) {
      if (existingAnswers?.some((e) => e.organization_cultivation_id === cultivationId)) {
        isExisting = true;
        answer = existingAnswers?.find((e) => e.organization_cultivation_id === cultivationId);
      }
    } else if (existingAnswers?.length > 0) {
      answer = existingAnswers?.[0];
      isExisting = true;
    }
    return [answer, isExisting];
  };

  const updateOrCreateAnswer = (
    selectedAnswers: string[],
    cultivationId?: string,
    dynamicAnswer?: string | number,
    isDynamicAnswer = false,
  ) => {
    const [answer, isExisting] = checkExist(cultivationId);
    if (isExisting) {
      if (isDynamicAnswer && !dynamicAnswer && dynamicAnswer !== 0) {
        if (permissions?.answers?.delete) {
          deleteAnswer({
            tid,
            id: answer?.uuid,
          });
        } else {
          dispatch(
            openSnackbar({
              message: commonStrings('notAuthorized'),
              severity: 'error',
            }),
          );
        }
      } else if (permissions?.answers?.update) {
        updateAnswer({
          tid,
          id: answer?.uuid,
          body: {
            answer_ids: selectedAnswers,
            ...(cultivationId && { organization_cultivation_id: cultivationId }),
            ...(isDynamicAnswer && { dynamic_answer: dynamicAnswer }),
          },
        });
      } else {
        dispatch(
          openSnackbar({
            message: commonStrings('notAuthorized'),
            severity: 'error',
          }),
        );
      }
    } else if (
      selectedAnswers.length > 0 &&
      ((isDynamicAnswer && (dynamicAnswer || dynamicAnswer === 0)) || !isDynamicAnswer)
    ) {
      if (permissions?.answers?.create) {
        createAnswer({
          tid,
          body: {
            assessment_id: assessmentId,
            control_point_id: controlPointId,
            answer_ids: selectedAnswers,
            ...(cultivationId && { organization_cultivation_id: cultivationId }),
            ...(isDynamicAnswer && { dynamic_answer: dynamicAnswer }),
          },
        });
      } else {
        dispatch(
          openSnackbar({
            message: commonStrings('notAuthorized'),
            severity: 'error',
          }),
        );
      }
    }
  };

  const updateJustificationText = (
    newValue: string,
    cId: string,
    existingJustificationText: string | null,
  ) => {
    if (newValue === existingJustificationText || (newValue === '' && !existingJustificationText)) {
      return;
    }

    const [answer] = checkExist(cId);
    if (permissions?.answers?.update) {
      updateAnswer({
        tid,
        id: answer.uuid,
        body: {
          justification_text: newValue || null,
          answer_ids: answer?.answer_ids,
        },
      });
    } else {
      dispatch(
        openSnackbar({
          message: commonStrings('notAuthorized'),
          severity: 'error',
        }),
      );
    }
  };

  useMutationFeedback({
    result: updateAnswerResult,
    successMessage: controlPointAnswerStrings('updateAnswerChangeToQuestionSuccess', {
      controlPointCode: controlPointDisplayCode || controlPointCode,
    }),
    errorMessage: controlPointAnswerStrings('updateAnswerChangeToQuestionFail', {
      controlPointCode: controlPointDisplayCode || controlPointCode,
    }),
  });

  useMutationFeedback({
    result: deleteAnswerResult,
    successMessage: controlPointAnswerStrings('updateAnswerChangeToQuestionSuccess', {
      controlPointCode: controlPointDisplayCode || controlPointCode,
    }),
    errorMessage: controlPointAnswerStrings('updateAnswerChangeToQuestionFail', {
      controlPointCode: controlPointDisplayCode || controlPointCode,
    }),
  });

  useMutationFeedback({
    result: deleteInputAnswerResult,
    successMessage: controlPointAnswerStrings('updateAnswerChangeToQuestionSuccess', {
      controlPointCode: controlPointDisplayCode || controlPointCode,
    }),
    errorMessage: controlPointAnswerStrings('updateAnswerChangeToQuestionFail', {
      controlPointCode: controlPointDisplayCode || controlPointCode,
    }),
  });

  useMutationFeedback({
    result: updateInputAnswerResult,
    successMessage: controlPointAnswerStrings('updateAnswerChangeToQuestionSuccess', {
      controlPointCode: controlPointDisplayCode || controlPointCode,
    }),
    errorMessage: controlPointAnswerStrings('updateAnswerChangeToQuestionFail', {
      controlPointCode: controlPointDisplayCode || controlPointCode,
    }),
  });

  useMutationFeedback({
    result: bulkUpdateAnswerResult,
    onSuccess: () => {
      handleCollapseChange(controlPointId, true);
    },
    successMessage: controlPointAnswerStrings('updateAnswerChangeToQuestionSuccess', {
      controlPointCode: controlPointDisplayCode || controlPointCode,
    }),
    errorMessage: controlPointAnswerStrings('updateAnswerChangeToQuestionFail', {
      controlPointCode: controlPointDisplayCode || controlPointCode,
    }),
  });

  useMutationFeedback({
    result: createAnswerResult,
    successMessage: controlPointAnswerStrings('createAnswerChangeToQuestionSuccess', {
      controlPointCode: controlPointDisplayCode || controlPointCode,
    }),
    errorMessage: controlPointAnswerStrings('createAnswerChangeToQuestionFail', {
      controlPointCode: controlPointDisplayCode || controlPointCode,
    }),
  });

  const isUpdateLoading =
    updateAnswerResult.isLoading ||
    bulkUpdateAnswerResult.isLoading ||
    updateInputAnswerResult.isLoading;

  const isDeleteLoading = deleteAnswerResult.isLoading || deleteInputAnswerResult.isLoading;

  const isDisabled =
    isUpdateLoading ||
    isDeleteLoading ||
    createAnswerResult.isLoading ||
    isDisableAnswer ||
    !(permissions?.answers?.create && permissions?.answers?.update);

  const organizationCultivations: OrganizationCultivationsAssessmentControlPointCultivationList[] =
    controlPointCultivations?.find(
      (c) => c.control_point_id === controlPointId,
    )?.organization_cultivations;

  const cultivationList = organizationCultivations
    ? organizationCultivations.slice().sort((a, b) => a.product_name.localeCompare(b.product_name))
    : [];

  const selectedId =
    existingAnswers?.length > 0 && existingAnswers[0].answer_ids?.length > 0
      ? existingAnswers[0].answer_ids[0]
      : '';
  const answerType = availablePossibleAnswers?.[0]?.answer_type;

  const renderDropdownAnswer = (isMultiple = false) => {
    const [status] = getDropdownData(existingAnswers);
    chipStatus.current = status;
    return (
      <ControlPointDropdownAnswer
        availablePossibleAnswers={availablePossibleAnswers}
        existingAnswers={existingAnswers}
        hasPlotName={hasPlotName}
        id={controlPointId}
        isDisabled={isDisabled}
        isDisableJustificationText={isDisableJustificationText}
        isLoadingJustificationText={updateAnswerResult?.isLoading}
        isMultiple={isMultiple}
        isScored={isScored}
        label={
          cultivationList?.length > 0 && controlPointLevel === LevelEnum.Cultivation ? (
            <Typography
              flex={0.3}
              paddingRight={2}
              variant="body1"
            >
              {controlPointAnswerStrings('productionTechniqueName', {
                productionTechniqueName: cultivationList[0].production_technique_name,
              })}
            </Typography>
          ) : null
        }
        onSelectAnswer={(answers) => updateOrCreateAnswer(answers)}
        onUpdateJustificationText={(textValue, cultivationId, existingTextValue) => {
          updateJustificationText(textValue, cultivationId, existingTextValue);
        }}
      />
    );
  };

  const renderSkeletonForCultivations = () => (
    <Box
      alignItems="center"
      display="flex"
      flex={1}
      flexDirection="row"
      justifyContent="space-between"
    >
      <Skeleton
        animation="pulse"
        height={24}
        sx={{ flex: '0.2 auto' }}
        variant="rounded"
      />
      <Skeleton
        animation="pulse"
        height={40}
        sx={{ flex: '0.5 auto' }}
        variant="rounded"
      />
      <Skeleton
        animation="pulse"
        height={32}
        sx={{ flex: '0.1 auto' }}
        variant="rounded"
      />
    </Box>
  );

  const renderDropdownAnswerByCultivation = (
    isMultiple = false,
    showTitle = true,
    isReadOnly = false,
  ) => {
    chipStatus.current = AnswerChipStatus.NoAnswer;
    return (
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
      >
        {!cultivationList
          ? renderSkeletonForCultivations()
          : cultivationList?.map((cultivation, index) => {
              const [status] = getDropdownData(existingAnswers, cultivation);
              if (chipStatus.current !== AnswerChipStatus.NonCompliant) {
                chipStatus.current = status;
              }
              return (
                <Box key={cultivation.product_id + cultivation.organization_cultivation_id}>
                  <ControlPointDropdownAnswer
                    availablePossibleAnswers={availablePossibleAnswers}
                    cultivation={cultivation}
                    existingAnswers={existingAnswers}
                    hasPlotName={hasPlotName}
                    id={cultivation.product_id}
                    index={index}
                    isDisabled={isDisabled || isReadOnly}
                    isLast={index === cultivationList.length - 1}
                    isMultiple={isMultiple}
                    isScored={isScored}
                    onSelectAnswer={(answers) =>
                      updateOrCreateAnswer(answers, cultivation?.organization_cultivation_id)
                    }
                    showTitle={showTitle}
                  />
                </Box>
              );
            })}
      </Box>
    );
  };

  const findMostFrequentBulkAnswer = (
    answers: AssessmentControlPointAnswerListOutputItem[],
    isMultiple = true,
  ) => {
    let compare = 0;
    let mostFreq: string[] = [];
    if (cultivationList?.length !== answers.length) {
      return [];
    }
    const reducableArr = isMultiple
      ? answers.map((a) => a.answer_ids)
      : answers.map((a) => a.answer_ids?.[0]);
    // Since it is dynamic, it must be any type.
    reducableArr.reduce((acc: DynamicObjectType, val: any) => {
      if (val in acc) {
        acc[val] += 1;
      } else {
        acc[val] = 1;
      }
      if (acc[val] > compare) {
        compare = acc[val];
        mostFreq = val;
      }
      return acc;
    }, {});
    if (cultivationList?.length !== compare) {
      return [];
    }
    return mostFreq;
  };

  const renderDropdownAnswerByCultivationBulk = (isMultiple = false, isReadOnly = false) => (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flex={1}
        flexDirection="row"
        marginBottom={0.5}
      >
        <Box
          display="flex"
          flex={1}
          flexGrow={1}
        >
          <ControlPointDropdownAnswer
            availablePossibleAnswers={availablePossibleAnswers}
            existingAnswers={existingAnswers}
            hasPlotName={hasPlotName}
            id={controlPointId}
            isDisabled={isDisabled}
            isDisableJustificationText={isDisableJustificationText}
            isLoadingJustificationText={updateAnswerResult?.isLoading}
            isMultiple={isMultiple}
            isScored={isScored}
            onSelectAnswer={(selectedAnswers) => {
              const hasAnswer = existingAnswers?.some((e) =>
                availablePossibleAnswers?.find((possibleAnswer) =>
                  e.answer_ids.some((a) => a === possibleAnswer?.uuid),
                ),
              );
              const isSkipUpdate =
                selectedAnswers?.length === 0 &&
                hasAnswer &&
                findMostFrequentBulkAnswer(existingAnswers)?.length === 0;
              if (!isSkipUpdate) {
                if (permissions?.answers?.update) {
                  bulkUpdateAnswer({
                    tid,
                    body: {
                      assessment_id: assessmentId,
                      control_point_id: controlPointId,
                      answer_ids: selectedAnswers,
                      organization_cultivation_ids: cultivationList.map(
                        (c) => c.organization_cultivation_id,
                      ),
                    },
                  });
                } else {
                  dispatch(
                    openSnackbar({
                      message: commonStrings('notAuthorized'),
                      severity: 'error',
                    }),
                  );
                }
              }
            }}
            onUpdateJustificationText={(textValue, cultivationId, existingTextValue) => {
              updateJustificationText(textValue, cultivationId, existingTextValue);
            }}
            value={findMostFrequentBulkAnswer(existingAnswers)}
          />
        </Box>
      </Box>
      <Accordion
        ref={containerRef}
        disableGutters
        elevation={0}
        expanded={expandedIds.includes(controlPointId)}
        id={controlPointId}
        onChange={() => handleCollapseChange(controlPointId)}
        square
        sx={{
          '&:before': {
            display: 'none',
          },
        }}
      >
        <AccordionSummary
          aria-controls={`${controlPointId}-content`}
          expandIcon={<KeyboardArrowDownRounded />}
          id={`${controlPointId}-header`}
          sx={{
            '&&&': {
              pl: 0,
              justifyContent: 'left',
            },
            '& .MuiAccordionSummary-content': {
              flexGrow: 0,
            },
          }}
        >
          <Typography
            color="text.disabled"
            variant="overline"
          >
            {controlPointAnswerStrings('verifyAnswersByCultivations')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: styles.AccordionDetailsRoot,
          }}
          sx={{
            '& .MuiTypography-root': {
              letterSpacing: 0.15,
            },
          }}
        >
          {renderDropdownAnswerByCultivation(isMultiple, false, isReadOnly)}
        </AccordionDetails>
      </Accordion>
    </Box>
  );

  const renderDynamicInput = () => (
    <ControlPointDynamicAnswer
      answerType={answerType}
      assessmentControlPoint={assessmentControlPoint}
      existingAnswers={existingAnswers}
      hasPlotName={hasPlotName}
      isDisabled={isDisabled}
      label={
        cultivationList?.length > 0 && controlPointLevel === LevelEnum.Cultivation ? (
          <Typography
            flex={{ xs: 1, sm: 0.3 }}
            maxWidth={isMobile ? undefined : 320}
            paddingRight={2}
            variant="body1"
          >
            {controlPointAnswerStrings('productionTechniqueName', {
              productionTechniqueName: cultivationList[0].production_technique_name,
            })}
          </Typography>
        ) : null
      }
      onChangeAnswer={(val) =>
        updateOrCreateAnswer([availablePossibleAnswers?.[0]?.uuid], undefined, val, true)
      }
      placeholder={
        availablePossibleAnswers?.[0]?.answer_type === AnswerTypeEnum.Number
          ? possibleAnswerSet?.ordered_possible_answers?.find(
              (p) => p.uuid === availablePossibleAnswers?.[0]?.uuid,
            )?.possible_answer_unit_symbol
          : ''
      }
    />
  );

  const renderDynamicInputByCultivation = () => (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
    >
      {!cultivationList
        ? renderSkeletonForCultivations()
        : cultivationList?.map((cultivation, index) => (
            <Box key={cultivation.product_id + cultivation.organization_cultivation_id}>
              <ControlPointDynamicAnswer
                answerType={answerType}
                assessmentControlPoint={assessmentControlPoint}
                cultivation={cultivation}
                existingAnswers={existingAnswers}
                hasPlotName={hasPlotName}
                index={index}
                isDisabled={isDisabled}
                isLast={index === cultivationList.length - 1}
                onChangeAnswer={(val) =>
                  updateOrCreateAnswer(
                    [availablePossibleAnswers?.[0]?.uuid],
                    cultivation?.organization_cultivation_id,
                    val,
                    true,
                  )
                }
                placeholder={
                  availablePossibleAnswers?.[0]?.answer_type === AnswerTypeEnum.Number
                    ? possibleAnswerSet?.ordered_possible_answers?.find(
                        (p) => p.uuid === availablePossibleAnswers?.[0]?.uuid,
                      )?.possible_answer_unit_symbol
                    : ''
                }
              />
            </Box>
          ))}
    </Box>
  );

  const getButtonGroupData = (
    cultivation: OrganizationCultivationsAssessmentControlPointCultivationList,
  ): [string, number, AnswerChipStatus, string] => {
    const existingItem = existingAnswers?.find(
      (e) => e.organization_cultivation_id === cultivation.organization_cultivation_id,
    );

    let value: string;
    let points = 0;
    if (existingItem) {
      value = existingItem.answer_ids?.[0];
      points = existingItem.points || 0;
    }
    const status = mapComplianceStatusToAnswerChip(existingItem?.compliance_status);
    const statusLabel = getComplianceStatusLabel(existingItem?.compliance_status_label);
    return [value, points, status, statusLabel];
  };

  const renderToggleButtonByCultivation = (isShowTitle = true, isReadOnly = false) => {
    chipStatus.current = AnswerChipStatus.NoAnswer;
    return (
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
      >
        {!cultivationList
          ? renderSkeletonForCultivations()
          : cultivationList?.map((cultivation, index) => {
              const [value, points, status, statusLabel] = getButtonGroupData(cultivation);
              if (chipStatus.current !== AnswerChipStatus.NonCompliant) {
                chipStatus.current = status;
              }
              return (
                <Box
                  key={cultivation.product_id + cultivation.organization_cultivation_id}
                  display="flex"
                  flex={1}
                  flexDirection="column"
                  marginBottom={2}
                  sx={{
                    '&:last-child': {
                      mb: 0,
                    },
                  }}
                >
                  {index === 0 && isShowTitle && (
                    <Typography
                      color="text.disabled"
                      marginBottom={1}
                      variant="overline"
                    >
                      {commonStrings('answersByCultivations')}
                    </Typography>
                  )}
                  <Box
                    alignItems={{ xs: 'flex-start', xl: 'center' }}
                    display="flex"
                    flexDirection={{ xs: 'column', xl: 'row' }}
                  >
                    <Typography
                      flex={{ xs: 1, sm: 0.3 }}
                      maxWidth={isNotExtraLargeDevice ? undefined : 320}
                      minWidth={isNotExtraLargeDevice ? undefined : 320}
                      paddingRight={2}
                      variant="body1"
                    >
                      {getCultivationDisplayNameForControlPoint(cultivation, hasPlotName)}
                    </Typography>
                    <Box
                      display="flex"
                      flex={1}
                      flexGrow={1}
                      marginTop={isNotExtraLargeDevice ? 1 : undefined}
                      paddingRight={{ xs: 0, sm: 3 }}
                      width={isNotExtraLargeDevice ? '100%' : undefined}
                    >
                      <ControlPointButtonGroupAnswer
                        availablePossibleAnswers={availablePossibleAnswers}
                        isDisabled={isDisabled || isReadOnly}
                        onSelectAnswer={(val) => {
                          updateOrCreateAnswer(val, cultivation.organization_cultivation_id);
                        }}
                        value={value}
                      />
                    </Box>
                    {isNotExtraLargeDevice ? (
                      <Box
                        alignItems="center"
                        display="flex"
                        width="100%"
                      >
                        <ControlPointAnswerPoints
                          showPointBox={isScored}
                          status={status}
                          statusLabel={statusLabel}
                          value={points}
                        />
                      </Box>
                    ) : (
                      <ControlPointAnswerPoints
                        showPointBox={isScored}
                        status={status}
                        statusLabel={statusLabel}
                        value={points}
                      />
                    )}
                  </Box>
                </Box>
              );
            })}
      </Box>
    );
  };

  const renderToggleButtonByCultivationBulk = (isReadOnly = false) => {
    const mostFrequentAnswer = findMostFrequentBulkAnswer(existingAnswers);
    return (
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
      >
        <Box
          alignItems="center"
          display="flex"
          flex={1}
          flexDirection="row"
          marginBottom={0.5}
        >
          <Box
            display="flex"
            flex={1}
            flexGrow={1}
            paddingRight={{ xs: 0, sm: 3 }}
          >
            <ControlPointButtonGroupAnswer
              availablePossibleAnswers={availablePossibleAnswers}
              isDisabled={isDisabled}
              onSelectAnswer={(selectedAnswers) => {
                if (permissions?.answers?.update) {
                  bulkUpdateAnswer({
                    tid,
                    body: {
                      assessment_id: assessmentId,
                      control_point_id: controlPointId,
                      answer_ids: selectedAnswers,
                      organization_cultivation_ids: cultivationList?.map(
                        (c) => c.organization_cultivation_id,
                      ),
                    },
                  });
                } else {
                  dispatch(
                    openSnackbar({
                      message: commonStrings('notAuthorized'),
                      severity: 'error',
                    }),
                  );
                }
              }}
              value={mostFrequentAnswer?.length > 0 ? mostFrequentAnswer?.[0] : ''}
            />
          </Box>
        </Box>
        {isCB && isJustificationRequired && existingAnswers?.length > 0 && (
          <JustificationTextField
            answerItem={existingAnswers[0]}
            inputRef={textField}
            isDisabled={isDisableJustificationText || isDisabled}
            isLoading={updateAnswerResult?.isLoading}
            onUpdate={(textValue, cultivationId, existingTextValue) => {
              updateJustificationText(textValue, cultivationId, existingTextValue);
            }}
          />
        )}
        <Accordion
          ref={containerRef}
          disableGutters
          elevation={0}
          expanded={expandedIds.includes(controlPointId)}
          id={controlPointId}
          onChange={() => handleCollapseChange(controlPointId)}
          square
          sx={{
            '&:before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary
            aria-controls={`${controlPointId}-content`}
            expandIcon={<KeyboardArrowDownRounded />}
            id={`${controlPointId}-header`}
            sx={{
              '&&&': {
                pl: 0,
                justifyContent: 'left',
              },
              '& .MuiAccordionSummary-content': {
                flexGrow: 0,
              },
            }}
          >
            <Typography
              color="text.disabled"
              variant="overline"
            >
              {controlPointAnswerStrings('verifyAnswersByCultivations')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: styles.AccordionDetailsRoot,
            }}
            sx={{
              '& .MuiTypography-root': {
                letterSpacing: 0.15,
              },
            }}
          >
            {renderToggleButtonByCultivation(false, isReadOnly)}
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  };

  const renderControlPointInputs = (
    controlPointInput: AssessmentControlPointInputOutputItem,
    isLast: boolean,
  ) => {
    const {
      name,
      uuid,
      answer_type: inputAnswerType,
      is_editable: isEditable,
      possible_answer_set_id: inputPossibleAnswerSetId,
    } = controlPointInput?.control_point_input || {};
    const inputPossibleAnswerSet = (possibleAnswerSets || []).find(
      (item) => item.uuid === inputPossibleAnswerSetId,
    );
    const inputAvailablePossibleAnswers = inputPossibleAnswerSet?.ordered_possible_answers.map(
      (item) => (possibleAnswers || []).find((possibleAnswer) => possibleAnswer.uuid === item.uuid),
    );

    const renderAnswer = () => {
      const answerId = inputAvailablePossibleAnswers?.[0]?.uuid;
      switch (inputAnswerType) {
        case ControlPointAnswerTypeEnum.SingleChoiceFarmLevelButtonGroup: {
          const status = mapComplianceStatusToAnswerChip(existingAnswers[0]?.compliance_status);
          const statusLabel = getComplianceStatusLabel(existingAnswers[0]?.compliance_status_label);
          const getAnswerPointValue = () => existingAnswers[0]?.points || 0;
          return (
            <Box
              key={uuid}
              alignItems={{ xs: 'flex-start', xl: 'center' }}
              display="flex"
              flexDirection={{ xs: 'column', xl: 'row' }}
            >
              <Typography
                flex={1}
                maxWidth={isMobile ? undefined : 320}
                paddingRight={2}
                variant="body1"
              >
                {name}
              </Typography>
              <Box
                display="flex"
                flex={1}
                flexGrow={1}
                marginTop={isNotExtraLargeDevice ? 1 : undefined}
                width={isNotExtraLargeDevice ? '100%' : undefined}
              >
                <ControlPointButtonGroupAnswer
                  availablePossibleAnswers={inputAvailablePossibleAnswers}
                  isDisabled={isDisabled || !isEditable}
                  onSelectAnswer={(val) => {
                    updateInputAnswer({
                      tid,
                      id: controlPointInput?.uuid,
                      body: {
                        answer_id: val?.[0],
                      },
                    });
                  }}
                  value={controlPointInput?.answer_id}
                />
              </Box>
              {isLast && isInputAnswer && (
                <ControlPointAnswerPoints
                  status={status}
                  statusLabel={statusLabel}
                  value={getAnswerPointValue()}
                />
              )}
            </Box>
          );
        }
        case ControlPointAnswerTypeEnum.SingleChoiceFarmLevelDropdown:
          return (
            <ControlPointDropdownAnswer
              key={uuid}
              availablePossibleAnswers={inputAvailablePossibleAnswers}
              existingAnswers={existingAnswers}
              hasPlotName={hasPlotName}
              id={controlPointId}
              isDisabled={isDisabled || !isEditable}
              isDisableJustificationText={isDisableJustificationText}
              isLoadingJustificationText={updateAnswerResult?.isLoading}
              isScored={isScored}
              isShowChip={isLast && isInputAnswer}
              label={
                <Typography
                  flex={1}
                  maxWidth={320}
                  paddingRight={2}
                  variant="body1"
                >
                  {name}
                </Typography>
              }
              onSelectAnswer={(val) =>
                updateInputAnswer({
                  tid,
                  id: controlPointInput?.uuid,
                  body: {
                    answer_id: val?.[0],
                  },
                })
              }
              onUpdateJustificationText={(textValue, cultivationId, existingTextValue) => {
                updateJustificationText(textValue, cultivationId, existingTextValue);
              }}
              value={[controlPointInput?.answer_id]}
            />
          );
        case ControlPointAnswerTypeEnum.FarmLevelInputString:
        case ControlPointAnswerTypeEnum.FarmLevelInputNumber:
        case ControlPointAnswerTypeEnum.FarmLevelInputDate: {
          const currentVal = controlPointInput?.dynamic_answer as number | string | Date;
          return (
            <ControlPointInputAnswer
              key={uuid}
              controlPointInput={controlPointInput?.control_point_input}
              existingAnswers={existingAnswers}
              isDisabled={isDisabled || !isEditable}
              isScored={isScored}
              isShowChip={isLast && isInputAnswer}
              onChangeAnswer={(val) => {
                if (!val && val !== 0 && (currentVal || currentVal === 0)) {
                  if (permissions?.answers?.delete) {
                    deleteInputAnswer({
                      tid,
                      id: controlPointInput?.uuid,
                      parentResourceId: controlPointId,
                    });
                  } else {
                    dispatch(
                      openSnackbar({
                        message: commonStrings('notAuthorized'),
                        severity: 'error',
                      }),
                    );
                  }
                } else if (val || val === 0) {
                  if (permissions?.answers?.update) {
                    updateInputAnswer({
                      tid,
                      id: controlPointInput?.uuid,
                      body: {
                        answer_id: answerId,
                        dynamic_answer: val,
                      },
                    });
                  } else {
                    dispatch(
                      openSnackbar({
                        message: commonStrings('notAuthorized'),
                        severity: 'error',
                      }),
                    );
                  }
                }
              }}
              placeholder={
                inputAvailablePossibleAnswers?.[0]?.answer_type === AnswerTypeEnum.Number
                  ? inputPossibleAnswerSet?.ordered_possible_answers?.find(
                      (p) => p.uuid === inputAvailablePossibleAnswers?.[0]?.uuid,
                    )?.possible_answer_unit_symbol
                  : ''
              }
              value={typeof currentVal === AnswerTypeEnum.Number ? currentVal : currentVal || ''}
            />
          );
        }
        default:
          return null;
      }
    };
    return renderAnswer();
  };

  const answerTypeDecider = () => {
    if (!controlPointAnswerType) {
      return null;
    }

    switch (controlPointAnswerType) {
      case ControlPointAnswerTypeEnum.FarmLevelInputString:
      case ControlPointAnswerTypeEnum.FarmLevelInputNumber:
      case ControlPointAnswerTypeEnum.FarmLevelInputDate:
        return renderDynamicInput();
      case ControlPointAnswerTypeEnum.CultivationLevelInputString:
      case ControlPointAnswerTypeEnum.CultivationLevelInputNumber:
      case ControlPointAnswerTypeEnum.CultivationLevelInputDate:
        return renderDynamicInputByCultivation();
      case ControlPointAnswerTypeEnum.SingleChoiceFarmLevelDropdown:
        return renderDropdownAnswer();
      case ControlPointAnswerTypeEnum.SingleChoiceCultivationLevelDropdown:
        return renderDropdownAnswerByCultivation();
      case ControlPointAnswerTypeEnum.SingleChoiceCultivationLevelBulkDropdown:
        return renderDropdownAnswerByCultivationBulk();
      case ControlPointAnswerTypeEnum.MultipleChoiceFarmLevelDropdown:
        return renderDropdownAnswer(true);
      case ControlPointAnswerTypeEnum.MultipleChoiceCultivationLevelDropdown:
        return renderDropdownAnswerByCultivation(true);
      case ControlPointAnswerTypeEnum.MultipleChoiceCultivationLevelBulkDropdown:
        return renderDropdownAnswerByCultivationBulk(true);
      case ControlPointAnswerTypeEnum.CultivationLevelBulkInputString:
      case ControlPointAnswerTypeEnum.CultivationLevelBulkInputNumber:
      case ControlPointAnswerTypeEnum.CultivationLevelBulkInputDate:
        return null;
      case ControlPointAnswerTypeEnum.SingleChoiceCultivationLevelBulkButtonGroup:
        return (
          <Box
            display="flex"
            flex={1}
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flex={1}
            >
              {renderToggleButtonByCultivationBulk()}
            </Box>
          </Box>
        );
      case ControlPointAnswerTypeEnum.SingleChoiceCultivationLevelButtonGroup:
        return (
          <Box
            display="flex"
            flex={1}
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flex={1}
            >
              {renderToggleButtonByCultivation()}
            </Box>
          </Box>
        );
      case ControlPointAnswerTypeEnum.SingleChoiceFarmLevelButtonGroup: {
        const status = mapComplianceStatusToAnswerChip(existingAnswers[0]?.compliance_status);
        const statusLabel = getComplianceStatusLabel(existingAnswers[0]?.compliance_status_label);
        chipStatus.current = status;
        const getAnswerPointValue = () => existingAnswers[0]?.points || 0;

        return (
          <>
            <Box
              display="flex"
              flex={1}
              flexDirection={{ xs: 'column', xl: 'row' }}
              justifyContent="space-between"
            >
              <Box
                display="flex"
                flex={1}
              >
                <ControlPointButtonGroupAnswer
                  availablePossibleAnswers={availablePossibleAnswers}
                  isDisabled={isDisabled}
                  onSelectAnswer={(val) => {
                    updateOrCreateAnswer(val);
                  }}
                  value={selectedId}
                />
              </Box>
              {isNotExtraLargeDevice ? (
                <Box
                  alignItems="center"
                  display="flex"
                >
                  <ControlPointAnswerPoints
                    showPointBox={isScored}
                    status={status}
                    statusLabel={statusLabel}
                    value={getAnswerPointValue()}
                  />
                </Box>
              ) : (
                <ControlPointAnswerPoints
                  showPointBox={isScored}
                  status={status}
                  statusLabel={statusLabel}
                  value={getAnswerPointValue()}
                />
              )}
            </Box>
            {isCB && isJustificationRequired && existingAnswers?.length > 0 && (
              <JustificationTextField
                answerItem={existingAnswers[0]}
                inputRef={textField}
                isDisabled={isDisableJustificationText || isDisabled}
                isLoading={updateAnswerResult?.isLoading}
                onUpdate={(textValue, cultivationId, existingTextValue) => {
                  updateJustificationText(textValue, cultivationId, existingTextValue);
                }}
              />
            )}
          </>
        );
      }
      default:
        return null;
    }
  };

  return isLoading ? (
    <>
      <Skeleton
        animation="pulse"
        height={40}
        variant="rounded"
        width={150}
      />
      <Skeleton
        animation="pulse"
        height={40}
        variant="rounded"
        width={150}
      />
    </>
  ) : (
    <>
      {controlPointInputs?.map((controlPointInput, index) =>
        renderControlPointInputs(controlPointInput, index === controlPointInputs.length - 1),
      )}
      {!isInputAnswer && (
        <Stack
          direction="row"
          display="flex"
          spacing={2}
        >
          {isControlPointInputsLoading ? (
            <>
              <Skeleton
                animation="pulse"
                height={40}
                variant="rounded"
                width={150}
              />
              <Skeleton
                animation="pulse"
                height={40}
                sx={{ ml: '4px !important' }}
                variant="rounded"
                width={150}
              />
            </>
          ) : (
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
            >
              {answerTypeDecider()}
            </Box>
          )}
        </Stack>
      )}
      <ControlPointNonConformities
        assessmentControlPointId={assessmentControlPointId}
        controlPointId={controlPointId}
        isDisable={isDisableNonConformity}
        isVisible={
          isAutoTriggerNonConformity || chipStatus.current === AnswerChipStatus.NonCompliant
        }
      />
    </>
  );
};
