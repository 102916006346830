import {
  type BonusPointsPerThemeCultivationDetails,
  type MalusCompensationCultivationDetails,
  type OrganizationCultivationsAssessmentControlPointCultivationList,
} from 'src/__generated__/InternalApiTypes';
import { getCultivationDisplayNameStrings } from 'src/languages/en-UK';
import { type AnyCultivation } from 'src/types/AnyCultivation';

export const getCultivationDisplayName = (
  cultivation: AnyCultivation,
  hasPlotName = false,
): string =>
  getCultivationDisplayNameStrings('cultivationDisplayName', {
    cultivationName: cultivation?.product?.name || '',
    plotName: hasPlotName ? cultivation?.organization_plot?.name || '' : '',
    productionTechniqueName: cultivation?.production_technique?.name || '',
  });

export const getCultivationDisplayNameForControlPoint = (
  cultivation:
    | OrganizationCultivationsAssessmentControlPointCultivationList
    | BonusPointsPerThemeCultivationDetails
    | MalusCompensationCultivationDetails,
  hasPlotName = false,
): string =>
  getCultivationDisplayNameStrings('cultivationDisplayName', {
    cultivationName: cultivation?.product_name || '',
    plotName: hasPlotName ? cultivation?.organization_plot_name || '' : '',
    productionTechniqueName: cultivation?.production_technique_name || '',
  });
