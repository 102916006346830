import { useContext } from 'react';
import { useTenantId } from 'src/hooks';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import { useGetAssessmentControlPointBreadcrumbsListQuery } from 'src/services/farmApi';
import { type Breadcrumb } from 'src/services/farmApi';

export const useControlPointBreadcrumbs = (controlPointId: string): [Breadcrumb, boolean] => {
  const [assessmentId] = useContext(AssessmentPageContext);
  const tid = useTenantId();
  const { data: controlPointBreadcrumbsData, isLoading: isControlPointBreadcrumbsDataLoading } =
    useGetAssessmentControlPointBreadcrumbsListQuery(
      {
        tid,
        id: assessmentId,
      },
      { skip: !tid || !assessmentId },
    );

  const controlPointBreadcrumb = controlPointBreadcrumbsData?.[controlPointId];

  return [controlPointBreadcrumb, isControlPointBreadcrumbsDataLoading];
};
