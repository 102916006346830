import { useEffect } from 'react';
import { commonStrings } from 'src/languages/en-UK';
import { useGetEvidenceTypeListQuery } from 'src/services/farmApi';

import { FormikSelectField } from '../FormikSelectField';
import { type FormikTextFieldProps } from '../FormikTextField';

type FormikEvidenceTypeFieldProps = FormikTextFieldProps & {
  evidenceTypeIds: string[];
};

/**
 * This component pre-fills a FormikSelectField with evidence type options.
 */
export const FormikEvidenceTypeField = ({
  evidenceTypeIds,
  formik,
  id,
  disabled = false,
  isLoading = false,
  ...restProps
}: FormikEvidenceTypeFieldProps) => {
  const { data: evidenceTypes, isLoading: isEvidenceTypesLoading } = useGetEvidenceTypeListQuery();
  let filteredEvidenceTypes = evidenceTypes;
  if (evidenceTypeIds && evidenceTypeIds.length > 0) {
    filteredEvidenceTypes = evidenceTypes?.filter(
      (e) => e.uuid && evidenceTypeIds.includes(e.uuid),
    );
  }
  const evidenceTypeOptions = (filteredEvidenceTypes || [])
    .map((evidenceType) => ({
      label: evidenceType.name,
      value: evidenceType.uuid || '',
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (filteredEvidenceTypes?.length === 1 && !formik?.values?.[id]) {
      formik?.setFieldValue(id, filteredEvidenceTypes?.[0].uuid);
    }
  }, [filteredEvidenceTypes, formik, id]);

  return (
    <FormikSelectField
      disabled={evidenceTypeOptions.length === 1 || disabled}
      formik={formik}
      id={id}
      isLoading={isLoading || isEvidenceTypesLoading}
      label={commonStrings('documentType')}
      options={evidenceTypeOptions}
      {...restProps}
    />
  );
};
