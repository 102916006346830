import {
  type AssessmentPreliminaryQuestionAnswerBulkUpdateDraftOutput,
  type AssessmentPreliminaryQuestionAnswerBulkUpdateInput,
  type AssessmentPreliminaryQuestionAnswerBulkUpdateOutput,
  type AssessmentPreliminaryQuestionAnswerListOutputItem,
  type AssessmentPreliminaryQuestionAnswersListParams,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { type CreateParamsWithTenantId, injectQueryParams } from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'assessment-preliminary-question-answers';

const assessmentPreliminaryQuestionAnswerEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAssessmentPreliminaryQuestionAnswerList: build.query<
      AssessmentPreliminaryQuestionAnswerListOutputItem[],
      AssessmentPreliminaryQuestionAnswersListParams
    >({
      query: ({ tid, assessment_id }) =>
        injectQueryParams(`/tid/${tid}/${RESOURCE}/`, { assessment_id }),
      providesTags: [TagType.AssessmentPreliminaryQuestionAnswer, TagType.Assessment],
    }),

    bulkUpdateAssessmentPreliminaryQuestionAnswers: build.mutation<
      AssessmentPreliminaryQuestionAnswerBulkUpdateOutput,
      CreateParamsWithTenantId<AssessmentPreliminaryQuestionAnswerBulkUpdateInput>
    >({
      query: ({ tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/bulk-update/`,
        method: 'PUT',
        body: {
          ...body,
          is_draft: false,
        },
      }),
      invalidatesTags: [
        TagType.AssessmentPreliminaryQuestionAnswer,
        TagType.AssessmentControlPointAnswer,
        TagType.AssessmentControlPointList,
        TagType.AssessmentControlPoint,
      ],
    }),

    bulkUpdateAssessmentPreliminaryQuestionAnswersDraft: build.mutation<
      AssessmentPreliminaryQuestionAnswerBulkUpdateDraftOutput,
      CreateParamsWithTenantId<AssessmentPreliminaryQuestionAnswerBulkUpdateInput>
    >({
      query: ({ tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/bulk-update/`,
        method: 'PUT',
        body: {
          ...body,
          is_draft: true,
        },
      }),
      invalidatesTags: [
        TagType.AssessmentPreliminaryQuestionAnswer,
        TagType.AssessmentControlPointAnswer,
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useBulkUpdateAssessmentPreliminaryQuestionAnswersMutation,
  useBulkUpdateAssessmentPreliminaryQuestionAnswersDraftMutation,
  useGetAssessmentPreliminaryQuestionAnswerListQuery,
} = assessmentPreliminaryQuestionAnswerEndpoints;
