import { AddRounded, DeleteForeverRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { type FormikProps, type FormikValues } from 'formik';
import { omit } from 'lodash';
import { type ChangeEvent, useState } from 'react';
import { allSupportedFileExtensions } from 'src/components/common/DocumentPreview';
import { MobileCompatibleTooltip } from 'src/components/common/MobileCompatibleTooltip';
import { formikFileUploadFieldStrings } from 'src/languages/en-UK';
import { appColors } from 'src/theme';
import { getFileExtension } from 'src/utils/fileUtils';

const maxFileSize = 1024 * 1024 * 100; // 100MB

interface FormikFileUploadFieldProps {
  formik: FormikProps<FormikValues>;
  id: string;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export const FormikFileUploadField = ({
  formik,
  id,
  isDisabled = false,
  isLoading = false,
}: FormikFileUploadFieldProps) => {
  const [uploadedFile, setUploadedFile] = useState(formik.values[id]);
  const [fileUploadError, setFileUploadError] = useState<string | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setFileUploadError(null);
    if (event.target.files && event.target.files.length > 0) {
      // check file size is valid
      const file = event.currentTarget.files?.[0];
      if (file?.size && file.size > maxFileSize) {
        setFileUploadError(formikFileUploadFieldStrings('fileTooLarge'));
        return;
      }
      // check file extension is valid
      const fileExtension = getFileExtension(file?.name);
      if (!allSupportedFileExtensions.includes(fileExtension)) {
        setFileUploadError(
          `${formikFileUploadFieldStrings('fileTypeNotSupported')}: ${fileExtension}`,
        );
        return;
      }
      formik.setFieldValue(id, file);
      if (formik.values.name === '') {
        formik.setFieldValue('name', file?.name.substring(0, file.name.lastIndexOf('.')));
      }
      setTimeout(() => {
        formik.setErrors(omit(formik.errors, [id, 'name']));
      }, 0);
      setUploadedFile(file);
    }
  };

  const handleDelete = (): void => {
    setFileUploadError(null); // Clear the fileUploadError state
    formik.setFieldValue(id, null); // Clear the uploaded file value
    formik.setFieldValue('name', ''); // Clear the name field value
    setUploadedFile(null); // Clear the uploadedFile state
  };

  return isLoading ? (
    <Box
      border={1}
      borderColor={appColors.border}
      borderRadius={1}
      paddingX="14px"
      paddingY="15px"
      width="100%"
    >
      <Skeleton
        animation="wave"
        variant="text"
        width="33%"
      />
    </Box>
  ) : (
    <Stack spacing={2}>
      <Button
        color="info"
        component="label"
        disabled={!!uploadedFile || isDisabled}
        startIcon={<AddRounded />}
        variant="contained"
      >
        {formikFileUploadFieldStrings('selectFile')}
        <input
          key={formik?.values?.file?.name || 'evidenceFile'}
          hidden
          onChange={handleFileChange}
          type="file"
        />
      </Button>
      {fileUploadError && <FormHelperText error>{fileUploadError}</FormHelperText>}
      {uploadedFile && (
        <Stack
          alignItems="center"
          direction="row"
        >
          <MobileCompatibleTooltip title={formik.values[id].name}>
            <Typography
              overflow="hidden"
              paddingRight={1}
              sx={{
                textDecoration: 'underline',
              }}
              textOverflow="ellipsis"
              variant="body1"
              whiteSpace="nowrap"
            >
              {formik.values[id].name}
            </Typography>
          </MobileCompatibleTooltip>
          {formik.values[id].name && (
            <IconButton
              onClick={handleDelete}
              sx={{ padding: 0 }}
            >
              <DeleteForeverRounded
                color="action"
                fontSize="small"
              />
            </IconButton>
          )}
        </Stack>
      )}
    </Stack>
  );
};
