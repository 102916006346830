import { FormatAlignLeft } from '@mui/icons-material';
import { Box, Drawer, Fab, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { useContext, useEffect } from 'react';
import {
  type AssessmentModuleSidebarOutputItem,
  RolesEnum,
} from 'src/__generated__/InternalApiTypes';
import { ExpandableArea, LoadingArea } from 'src/components/common';
import { MobileCompatibleTooltip } from 'src/components/common/MobileCompatibleTooltip';
import { ThemeContext } from 'src/components/ThemeProvider';
import { useCurrentOrganization, useCurrentUserRoles, useDialogState } from 'src/hooks';
import { assessmentControlPointModulesDrawerStrings, commonStrings } from 'src/languages/en-UK';

import {
  AssessmentControlPointDrawerToggle,
  SidebarToggleButtonTypeEnum,
} from '../AssessmentControlPointDrawerToggle';

interface AssessmentControlPointModulesDrawerProps {
  assessmentBaseModules: AssessmentModuleSidebarOutputItem[];
  assessmentLevel1Modules: AssessmentModuleSidebarOutputItem[];
  assessmentLevel2Modules: AssessmentModuleSidebarOutputItem[];
  drawerWidth: number;
  isLoading: boolean;
  selectedBaseModule: AssessmentModuleSidebarOutputItem | null;
  selectedLevel1Module: AssessmentModuleSidebarOutputItem | null;
  selectedLevel2Module: AssessmentModuleSidebarOutputItem | null;
  selectedMode: SidebarToggleButtonTypeEnum;
  setIsLastItem: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedBaseModule: React.Dispatch<
    React.SetStateAction<AssessmentModuleSidebarOutputItem | null>
  >;
  setSelectedLevel1Module: React.Dispatch<
    React.SetStateAction<AssessmentModuleSidebarOutputItem | null>
  >;
  setSelectedLevel2Module: React.Dispatch<
    React.SetStateAction<AssessmentModuleSidebarOutputItem | null>
  >;
  setSelectedMode: React.Dispatch<React.SetStateAction<SidebarToggleButtonTypeEnum>>;
}

export const AssessmentControlPointModulesDrawer = ({
  assessmentBaseModules,
  assessmentLevel1Modules,
  assessmentLevel2Modules,
  drawerWidth,
  selectedBaseModule,
  selectedLevel1Module,
  selectedLevel2Module,
  selectedMode,
  setIsLastItem,
  setSelectedBaseModule,
  setSelectedLevel1Module,
  setSelectedLevel2Module,
  setSelectedMode,
  isLoading = false,
}: AssessmentControlPointModulesDrawerProps): React.JSX.Element => {
  const { theme } = useContext(ThemeContext);
  const [isModuleDrawerOpen, openModuleDrawer, closeModuleDrawer] = useDialogState();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
  const currentUserRoles = useCurrentUserRoles();
  const isFarmManager = currentUserRoles.includes(RolesEnum.FarmManager);
  const isFarmEmployee = currentUserRoles.includes(RolesEnum.FarmEmployee);
  const { data: organization } = useCurrentOrganization();

  useEffect(() => {
    if (!selectedBaseModule && assessmentBaseModules.length > 0) {
      setSelectedBaseModule(assessmentBaseModules[0]);
    }
  }, [assessmentBaseModules, selectedBaseModule, setSelectedBaseModule]);

  useEffect(() => {
    const filteredAssessmentLevel1Modules = assessmentLevel1Modules?.filter(
      (lvl1) => lvl1.parent_id === selectedBaseModule?.uuid,
    );
    if (!selectedLevel1Module && filteredAssessmentLevel1Modules?.length > 0) {
      setSelectedLevel1Module(filteredAssessmentLevel1Modules[0]);
    }
  }, [assessmentLevel1Modules, selectedBaseModule, selectedLevel1Module, setSelectedLevel1Module]);

  useEffect(() => {
    if (!selectedLevel2Module) {
      if (
        !assessmentLevel2Modules?.some((module) => module.parent_id === selectedLevel1Module?.uuid)
      ) {
        setSelectedLevel2Module(selectedLevel2Module);
      } else if (assessmentLevel2Modules?.length > 0) {
        const filteredAssessmentLevel2Modules = assessmentLevel2Modules.filter(
          (lvl2) => lvl2.parent_id === selectedLevel1Module?.uuid,
        );
        if (filteredAssessmentLevel2Modules?.length > 0) {
          setSelectedLevel2Module(filteredAssessmentLevel2Modules[0]);
          setIsLastItem(false);
        }
      }
    }
  }, [
    assessmentLevel2Modules,
    selectedLevel1Module,
    selectedLevel2Module,
    setIsLastItem,
    setSelectedLevel2Module,
  ]);

  const handleBaseChange = (modules: AssessmentModuleSidebarOutputItem) => {
    setSelectedBaseModule(modules ?? null);
    setSelectedLevel1Module(null);
    setSelectedLevel2Module(null);
    if (isSmallDevice) {
      closeModuleDrawer();
    }
  };

  const handleLevel1Change = (modules: AssessmentModuleSidebarOutputItem) => {
    setSelectedLevel1Module(modules ?? null);
    setSelectedLevel2Module(null);
    const selectedLevel1ModuleIndex = assessmentLevel1Modules.findIndex(
      (module) => module.uuid === modules?.uuid,
    );
    if (selectedLevel1ModuleIndex === assessmentLevel1Modules.length - 1) {
      setIsLastItem(true);
    } else {
      setIsLastItem(false);
    }
    if (isSmallDevice) {
      closeModuleDrawer();
    }
  };

  const handleLevel2Change = (modules: AssessmentModuleSidebarOutputItem) => {
    setSelectedLevel2Module(modules ?? null);
    const selectedLevel2ModuleIndex = assessmentLevel2Modules.findIndex(
      (module) => module.uuid === modules?.uuid,
    );
    if (selectedLevel2ModuleIndex === assessmentLevel2Modules.length - 1) {
      setIsLastItem(true);
    } else {
      setIsLastItem(false);
    }
    if (isSmallDevice) {
      closeModuleDrawer();
    }
  };

  const render3LevelExpanded = () =>
    assessmentBaseModules?.map((baseModule) => {
      const isSelectedLvl0 = selectedBaseModule?.uuid === baseModule.uuid;

      return (
        <ExpandableArea
          key={baseModule.uuid}
          disableGutters={false}
          expanded={isSelectedLvl0}
          onChange={() => handleBaseChange(baseModule)}
          title={baseModule.name}
        >
          {assessmentLevel1Modules
            ?.filter((level1Module) => level1Module.parent_id === baseModule.uuid)
            .map((level1Module) => {
              const hasChild = assessmentLevel2Modules?.some(
                (level2Module) => level2Module.parent_id === level1Module.uuid,
              );
              const isSelectedLvl1 = selectedLevel1Module?.uuid === level1Module.uuid;
              const textColorLvl1 = isSelectedLvl1 ? 'primary' : 'textPrimary';

              return hasChild ? (
                <ExpandableArea
                  key={level1Module.uuid}
                  disableGutters={false}
                  expanded={isSelectedLvl1}
                  onChange={() => handleLevel1Change(level1Module)}
                  paddingLeft={4}
                  title={level1Module.name}
                >
                  {assessmentLevel2Modules
                    ?.filter((level2Module) => level2Module.parent_id === level1Module.uuid)
                    .map((level2Module) => {
                      const isSelectedLvl2 = selectedLevel2Module?.uuid === level2Module.uuid;
                      const textColorLvl2 = isSelectedLvl2 ? 'primary' : 'textPrimary';

                      return (
                        <Box
                          key={level2Module.uuid}
                          bgcolor={
                            isSelectedLvl2 ? `${theme.palette.primary.main}0A` : 'transparent'
                          }
                          onClick={() => handleLevel2Change(level2Module)}
                          paddingBottom={1.5}
                          paddingLeft={8}
                          paddingTop={1.5}
                          sx={{
                            '&:hover': {
                              backgroundColor: `${theme.palette.primary.main}0A`,
                              cursor: 'pointer',
                            },
                          }}
                        >
                          <Typography
                            color={textColorLvl2}
                            variant="body1"
                          >
                            {level2Module.name}
                          </Typography>
                        </Box>
                      );
                    })}
                </ExpandableArea>
              ) : (
                <Box
                  key={level1Module.uuid}
                  bgcolor={isSelectedLvl1 ? `${theme.palette.primary.main}0A` : 'transparent'}
                  onClick={() => handleLevel1Change(level1Module)}
                  paddingBottom={1.5}
                  paddingLeft={6}
                  paddingTop={1.5}
                  sx={{
                    '&:hover': {
                      backgroundColor: `${theme.palette.primary.main}0A`,
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Typography
                    color={textColorLvl1}
                    variant="body1"
                  >
                    {level1Module.name}
                  </Typography>
                </Box>
              );
            })}
        </ExpandableArea>
      );
    });

  return (
    <>
      <Drawer
        ModalProps={{
          keepMounted: true, // Better open performance on mobile
        }}
        onClose={closeModuleDrawer}
        open={isSmallDevice ? isModuleDrawerOpen : true}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
        variant={isSmallDevice ? 'temporary' : 'permanent'}
      >
        {organization?.is_paying_organization && (isFarmManager || isFarmEmployee) ? (
          <AssessmentControlPointDrawerToggle
            selectedMode={selectedMode}
            setSelectedMode={(mode) => {
              if (mode === SidebarToggleButtonTypeEnum.SelfAssessmentMode) {
                setSelectedBaseModule(null);
                setSelectedLevel1Module(null);
                setSelectedLevel2Module(null);
              }
              setSelectedMode(mode);
            }}
          />
        ) : (
          <Toolbar sx={{ mt: 1 }} />
        )}
        {isLoading && <LoadingArea />}
        {!isLoading && assessmentBaseModules.length > 1 && (
          <Typography
            lineHeight={3.43}
            marginTop={-2}
            paddingX={2}
            textTransform="uppercase"
            variant="subtitle2"
          >
            {commonStrings('standards')}
          </Typography>
        )}
        {!isLoading && assessmentBaseModules?.length > 1
          ? render3LevelExpanded()
          : !isLoading &&
            assessmentLevel1Modules?.map((level1Module) => {
              const hasChild = assessmentLevel2Modules?.some(
                (level2Module) => level2Module.parent_id === level1Module.uuid,
              );
              const isSelectedLvl1 = selectedLevel1Module?.uuid === level1Module.uuid;
              const textColorLvl1 = isSelectedLvl1 ? 'primary' : 'textPrimary';

              return (
                <Box key={level1Module.uuid}>
                  {hasChild ? (
                    <Typography
                      paddingX={2}
                      paddingY={1.628}
                      textTransform="uppercase"
                      variant="subtitle2"
                    >
                      {level1Module.name}
                    </Typography>
                  ) : (
                    <Box
                      bgcolor={isSelectedLvl1 ? `${theme.palette.primary.main}0A` : 'transparent'}
                      onClick={() => handleLevel1Change(level1Module)}
                      sx={{
                        '&:hover': {
                          backgroundColor: `${theme.palette.primary.main}0A`,
                          cursor: 'pointer',
                        },
                      }}
                    >
                      <Typography
                        color={textColorLvl1}
                        paddingX={2}
                        paddingY={1.5}
                        variant="body1"
                      >
                        {level1Module.name}
                      </Typography>
                    </Box>
                  )}
                  {assessmentLevel2Modules
                    ?.filter((level2Module) => level2Module.parent_id === level1Module.uuid)
                    .map((level2Module) => {
                      const isSelectedLvl2 = selectedLevel2Module?.uuid === level2Module.uuid;
                      const textColorLvl2 = isSelectedLvl2 ? 'primary' : 'textPrimary';

                      return (
                        <Box
                          key={level2Module.uuid}
                          bgcolor={
                            isSelectedLvl2 ? `${theme.palette.primary.main}0A` : 'transparent'
                          }
                          onClick={() => handleLevel2Change(level2Module)}
                          paddingBottom={1.5}
                          paddingLeft={4}
                          paddingTop={1.5}
                          sx={{
                            '&:hover': {
                              backgroundColor: `${theme.palette.primary.main}0A`,
                              cursor: 'pointer',
                            },
                          }}
                        >
                          <Typography
                            color={textColorLvl2}
                            variant="body1"
                          >
                            {level2Module.name}
                          </Typography>
                        </Box>
                      );
                    })}
                </Box>
              );
            })}
        <Toolbar sx={{ mt: 1 }} />
      </Drawer>
      <MobileCompatibleTooltip
        title={
          isModuleDrawerOpen
            ? assessmentControlPointModulesDrawerStrings('hideModules')
            : assessmentControlPointModulesDrawerStrings('showModules')
        }
      >
        <Fab
          color="primary"
          onClick={isModuleDrawerOpen ? closeModuleDrawer : openModuleDrawer}
          sx={{
            position: 'fixed',
            bottom: 75,
            left: isModuleDrawerOpen ? 288 : 8,
            display: {
              md: 'none',
              xs: 'inline-flex',
            },
          }}
        >
          <FormatAlignLeft />
        </Fab>
      </MobileCompatibleTooltip>
    </>
  );
};
