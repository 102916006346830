interface LogoProps {
  size: number;
}

export const Logo = ({ size }: LogoProps) => (
  <svg
    height={size}
    id="Layer_1"
    version="1.1"
    viewBox="0 0 214 214"
    width={size}
    x="0px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    y="0px"
  >
    <path
      d="M122.462 209.868C125.875 209.868 128.642 207.103 128.642 203.692C128.642 200.281 125.875 197.516 122.462 197.516C119.048 197.516 116.281 200.281 116.281 203.692C116.281 207.103 119.048 209.868 122.462 209.868Z"
      fill="#1B365D"
    />
    <path
      d="M151.821 200.602C155.235 200.602 158.002 197.837 158.002 194.426C158.002 191.015 155.235 188.25 151.821 188.25C148.408 188.25 145.641 191.015 145.641 194.426C145.641 197.837 148.408 200.602 151.821 200.602Z"
      fill="#0072CE"
    />
    <path
      d="M176.54 182.075C179.953 182.075 182.72 179.31 182.72 175.899C182.72 172.488 179.953 169.723 176.54 169.723C173.126 169.723 170.359 172.488 170.359 175.899C170.359 179.31 173.126 182.075 176.54 182.075Z"
      fill="#84BD00"
    />
    <path
      d="M194.563 157.37C197.977 157.37 200.744 154.604 200.744 151.194C200.744 147.783 197.977 145.018 194.563 145.018C191.15 145.018 188.383 147.783 188.383 151.194C188.383 154.604 191.15 157.37 194.563 157.37Z"
      fill="#00A699"
    />
    <path
      d="M203.837 128.551C207.25 128.551 210.017 125.786 210.017 122.375C210.017 118.964 207.25 116.199 203.837 116.199C200.423 116.199 197.656 118.964 197.656 122.375C197.656 125.786 200.423 128.551 203.837 128.551Z"
      fill="#1B365D"
    />
    <path
      d="M203.837 97.6684C207.25 97.6684 210.017 94.9033 210.017 91.4924C210.017 88.0815 207.25 85.3164 203.837 85.3164C200.423 85.3164 197.656 88.0815 197.656 91.4924C197.656 94.9033 200.423 97.6684 203.837 97.6684Z"
      fill="#0072CE"
    />
    <path
      d="M194.563 68.8481C197.977 68.8481 200.744 66.083 200.744 62.6721C200.744 59.2612 197.977 56.4961 194.563 56.4961C191.15 56.4961 188.383 59.2612 188.383 62.6721C188.383 66.083 191.15 68.8481 194.563 68.8481Z"
      fill="#84BD00"
    />
    <path
      d="M176.54 44.145C179.953 44.145 182.72 41.3799 182.72 37.969C182.72 34.5581 179.953 31.793 176.54 31.793C173.126 31.793 170.359 34.5581 170.359 37.969C170.359 41.3799 173.126 44.145 176.54 44.145Z"
      fill="#00A699"
    />
    <path
      d="M151.821 26.1313C155.235 26.1313 158.002 23.3662 158.002 19.9553C158.002 16.5444 155.235 13.7793 151.821 13.7793C148.408 13.7793 145.641 16.5444 145.641 19.9553C145.641 23.3662 148.408 26.1313 151.821 26.1313Z"
      fill="#1B365D"
    />
    <path
      d="M122.462 16.352C125.875 16.352 128.642 13.5869 128.642 10.176C128.642 6.76509 125.875 4 122.462 4C119.048 4 116.281 6.76509 116.281 10.176C116.281 13.5869 119.048 16.352 122.462 16.352Z"
      fill="#0072CE"
    />
    <path
      d="M92.0711 16.352C95.4845 16.352 98.2516 13.5869 98.2516 10.176C98.2516 6.76509 95.4845 4 92.0711 4C88.6577 4 85.8906 6.76509 85.8906 10.176C85.8906 13.5869 88.6577 16.352 92.0711 16.352Z"
      fill="#84BD00"
    />
    <path
      d="M62.7196 26.1313C66.133 26.1313 68.9001 23.3662 68.9001 19.9553C68.9001 16.5444 66.133 13.7793 62.7196 13.7793C59.3062 13.7793 56.5391 16.5444 56.5391 19.9553C56.5391 23.3662 59.3062 26.1313 62.7196 26.1313Z"
      fill="#00A699"
    />
    <path
      d="M37.993 44.145C41.4064 44.145 44.1735 41.3799 44.1735 37.969C44.1735 34.5581 41.4064 31.793 37.993 31.793C34.5796 31.793 31.8125 34.5581 31.8125 37.969C31.8125 41.3799 34.5796 44.145 37.993 44.145Z"
      fill="#1B365D"
    />
    <path
      d="M19.9696 68.8481C23.383 68.8481 26.1501 66.083 26.1501 62.6721C26.1501 59.2612 23.383 56.4961 19.9696 56.4961C16.5562 56.4961 13.7891 59.2612 13.7891 62.6721C13.7891 66.083 16.5562 68.8481 19.9696 68.8481Z"
      fill="#0072CE"
    />
    <path
      d="M10.1805 97.6684C13.5939 97.6684 16.361 94.9033 16.361 91.4924C16.361 88.0815 13.5939 85.3164 10.1805 85.3164C6.76711 85.3164 4 88.0815 4 91.4924C4 94.9033 6.76711 97.6684 10.1805 97.6684Z"
      fill="#84BD00"
    />
    <path
      d="M10.1805 128.551C13.5939 128.551 16.361 125.786 16.361 122.375C16.361 118.964 13.5939 116.199 10.1805 116.199C6.76711 116.199 4 118.964 4 122.375C4 125.786 6.76711 128.551 10.1805 128.551Z"
      fill="#00A699"
    />
    <path
      d="M19.9696 157.885C23.383 157.885 26.1501 155.12 26.1501 151.709C26.1501 148.298 23.383 145.533 19.9696 145.533C16.5562 145.533 13.7891 148.298 13.7891 151.709C13.7891 155.12 16.5562 157.885 19.9696 157.885Z"
      fill="#1B365D"
    />
    <path
      d="M37.993 182.59C41.4064 182.59 44.1735 179.825 44.1735 176.414C44.1735 173.003 41.4064 170.238 37.993 170.238C34.5796 170.238 31.8125 173.003 31.8125 176.414C31.8125 179.825 34.5796 182.59 37.993 182.59Z"
      fill="#0072CE"
    />
    <path
      d="M62.7196 200.602C66.133 200.602 68.9001 197.837 68.9001 194.426C68.9001 191.015 66.133 188.25 62.7196 188.25C59.3062 188.25 56.5391 191.015 56.5391 194.426C56.5391 197.837 59.3062 200.602 62.7196 200.602Z"
      fill="#84BD00"
    />
    <path
      d="M92.0711 209.868C95.4845 209.868 98.2516 207.103 98.2516 203.692C98.2516 200.281 95.4845 197.516 92.0711 197.516C88.6577 197.516 85.8906 200.281 85.8906 203.692C85.8906 207.103 88.6577 209.868 92.0711 209.868Z"
      fill="#00A699"
    />
    <path
      d="M108.041 191.338C110.886 191.338 113.191 189.034 113.191 186.192C113.191 183.349 110.886 181.045 108.041 181.045C105.197 181.045 102.891 183.349 102.891 186.192C102.891 189.034 105.197 191.338 108.041 191.338Z"
      fill="#84BD00"
    />
    <path
      d="M132.244 187.223C135.089 187.223 137.395 184.919 137.395 182.076C137.395 179.234 135.089 176.93 132.244 176.93C129.4 176.93 127.094 179.234 127.094 182.076C127.094 184.919 129.4 187.223 132.244 187.223Z"
      fill="#00A699"
    />
    <path
      d="M153.361 176.414C156.206 176.414 158.512 174.11 158.512 171.268C158.512 168.425 156.206 166.121 153.361 166.121C150.517 166.121 148.211 168.425 148.211 171.268C148.211 174.11 150.517 176.414 153.361 176.414Z"
      fill="#1B365D"
    />
    <path
      d="M170.361 159.428C173.206 159.428 175.512 157.124 175.512 154.281C175.512 151.439 173.206 149.135 170.361 149.135C167.517 149.135 165.211 151.439 165.211 154.281C165.211 157.124 167.517 159.428 170.361 159.428Z"
      fill="#0072CE"
    />
    <path
      d="M181.174 137.297C184.018 137.297 186.324 134.993 186.324 132.151C186.324 129.308 184.018 127.004 181.174 127.004C178.329 127.004 176.023 129.308 176.023 132.151C176.023 134.993 178.329 137.297 181.174 137.297Z"
      fill="#84BD00"
    />
    <path
      d="M184.783 113.11C187.628 113.11 189.934 110.806 189.934 107.963C189.934 105.121 187.628 102.816 184.783 102.816C181.939 102.816 179.633 105.121 179.633 107.963C179.633 110.806 181.939 113.11 184.783 113.11Z"
      fill="#00A699"
    />
    <path
      d="M180.658 88.9222C183.503 88.9222 185.809 86.618 185.809 83.7756C185.809 80.9331 183.503 78.6289 180.658 78.6289C177.814 78.6289 175.508 80.9331 175.508 83.7756C175.508 86.618 177.814 88.9222 180.658 88.9222Z"
      fill="#1B365D"
    />
    <path
      d="M169.33 66.2738C172.175 66.2738 174.481 63.9696 174.481 61.1271C174.481 58.2847 172.175 55.9805 169.33 55.9805C166.486 55.9805 164.18 58.2847 164.18 61.1271C164.18 63.9696 166.486 66.2738 169.33 66.2738Z"
      fill="#0072CE"
    />
    <path
      d="M152.338 48.7758C155.182 48.7758 157.488 46.4715 157.488 43.6291C157.488 40.7867 155.182 38.4824 152.338 38.4824C149.493 38.4824 147.188 40.7867 147.188 43.6291C147.188 46.4715 149.493 48.7758 152.338 48.7758Z"
      fill="#84BD00"
    />
    <path
      d="M130.182 37.4535C133.026 37.4535 135.332 35.1493 135.332 32.3068C135.332 29.4644 133.026 27.1602 130.182 27.1602C127.337 27.1602 125.031 29.4644 125.031 32.3068C125.031 35.1493 127.337 37.4535 130.182 37.4535Z"
      fill="#00A699"
    />
    <path
      d="M105.979 33.3363C108.823 33.3363 111.129 31.0321 111.129 28.1896C111.129 25.3472 108.823 23.043 105.979 23.043C103.134 23.043 100.828 25.3472 100.828 28.1896C100.828 31.0321 103.134 33.3363 105.979 33.3363Z"
      fill="#1B365D"
    />
    <path
      d="M82.291 36.9379C85.1355 36.9379 87.4415 34.6336 87.4415 31.7912C87.4415 28.9488 85.1355 26.6445 82.291 26.6445C79.4465 26.6445 77.1406 28.9488 77.1406 31.7912C77.1406 34.6336 79.4465 36.9379 82.291 36.9379Z"
      fill="#0072CE"
    />
    <path
      d="M60.6582 47.7465C63.5027 47.7465 65.8086 45.4422 65.8086 42.5998C65.8086 39.7574 63.5027 37.4531 60.6582 37.4531C57.8137 37.4531 55.5078 39.7574 55.5078 42.5998C55.5078 45.4422 57.8137 47.7465 60.6582 47.7465Z"
      fill="#84BD00"
    />
    <path
      d="M43.6582 64.7309C46.5027 64.7309 48.8087 62.4266 48.8087 59.5842C48.8087 56.7417 46.5027 54.4375 43.6582 54.4375C40.8137 54.4375 38.5078 56.7417 38.5078 59.5842C38.5078 62.4266 40.8137 64.7309 43.6582 64.7309Z"
      fill="#00A699"
    />
    <path
      d="M32.8379 86.8597C35.6824 86.8597 37.9883 84.5555 37.9883 81.7131C37.9883 78.8707 35.6824 76.5664 32.8379 76.5664C29.9934 76.5664 27.6875 78.8707 27.6875 81.7131C27.6875 84.5555 29.9934 86.8597 32.8379 86.8597Z"
      fill="#1B365D"
    />
    <path
      d="M29.2364 111.051C32.0809 111.051 34.3868 108.747 34.3868 105.904C34.3868 103.062 32.0809 100.758 29.2364 100.758C26.3919 100.758 24.0859 103.062 24.0859 105.904C24.0859 108.747 26.3919 111.051 29.2364 111.051Z"
      fill="#0072CE"
    />
    <path
      d="M33.8692 135.754C36.7137 135.754 39.0196 133.45 39.0196 130.608C39.0196 127.765 36.7137 125.461 33.8692 125.461C31.0247 125.461 28.7188 127.765 28.7188 130.608C28.7188 133.45 31.0247 135.754 33.8692 135.754Z"
      fill="#84BD00"
    />
    <path
      d="M44.6895 157.885C47.534 157.885 49.8399 155.581 49.8399 152.738C49.8399 149.896 47.534 147.592 44.6895 147.592C41.845 147.592 39.5391 149.896 39.5391 152.738C39.5391 155.581 41.845 157.885 44.6895 157.885Z"
      fill="#00A699"
    />
    <path
      d="M62.1973 175.383C65.0418 175.383 67.3477 173.079 67.3477 170.237C67.3477 167.394 65.0418 165.09 62.1973 165.09C59.3528 165.09 57.0469 167.394 57.0469 170.237C57.0469 173.079 59.3528 175.383 62.1973 175.383Z"
      fill="#1B365D"
    />
    <path
      d="M83.8301 186.707C86.6746 186.707 88.9805 184.403 88.9805 181.561C88.9805 178.718 86.6746 176.414 83.8301 176.414C80.9856 176.414 78.6797 178.718 78.6797 181.561C78.6797 184.403 80.9856 186.707 83.8301 186.707Z"
      fill="#0072CE"
    />
    <path
      d="M97.2219 172.811C99.4975 172.811 101.342 170.967 101.342 168.694C101.342 166.42 99.4975 164.576 97.2219 164.576C94.9463 164.576 93.1016 166.42 93.1016 168.694C93.1016 170.967 94.9463 172.811 97.2219 172.811Z"
      fill="#1B365D"
    />
    <path
      d="M116.8 172.811C119.076 172.811 120.92 170.967 120.92 168.694C120.92 166.42 119.076 164.576 116.8 164.576C114.524 164.576 112.68 166.42 112.68 168.694C112.68 170.967 114.524 172.811 116.8 172.811Z"
      fill="#0072CE"
    />
    <path
      d="M135.847 167.149C138.122 167.149 139.967 165.305 139.967 163.031C139.967 160.757 138.122 158.914 135.847 158.914C133.571 158.914 131.727 160.757 131.727 163.031C131.727 165.305 133.571 167.149 135.847 167.149Z"
      fill="#84BD00"
    />
    <path
      d="M151.816 155.313C154.091 155.313 155.936 153.469 155.936 151.195C155.936 148.922 154.091 147.078 151.816 147.078C149.54 147.078 147.695 148.922 147.695 151.195C147.695 153.469 149.54 155.313 151.816 155.313Z"
      fill="#00A699"
    />
    <path
      d="M163.144 139.358C165.419 139.358 167.264 137.514 167.264 135.24C167.264 132.966 165.419 131.123 163.144 131.123C160.868 131.123 159.023 132.966 159.023 135.24C159.023 137.514 160.868 139.358 163.144 139.358Z"
      fill="#1B365D"
    />
    <path
      d="M168.808 120.83C171.083 120.83 172.928 118.987 172.928 116.713C172.928 114.439 171.083 112.596 168.808 112.596C166.532 112.596 164.688 114.439 164.688 116.713C164.688 118.987 166.532 120.83 168.808 120.83Z"
      fill="#0072CE"
    />
    <path
      d="M168.808 101.274C171.083 101.274 172.928 99.4303 172.928 97.1564C172.928 94.8824 171.083 93.0391 168.808 93.0391C166.532 93.0391 164.688 94.8824 164.688 97.1564C164.688 99.4303 166.532 101.274 168.808 101.274Z"
      fill="#84BD00"
    />
    <path
      d="M163.144 82.7444C165.419 82.7444 167.264 80.901 167.264 78.6271C167.264 76.3532 165.419 74.5098 163.144 74.5098C160.868 74.5098 159.023 76.3532 159.023 78.6271C159.023 80.901 160.868 82.7444 163.144 82.7444Z"
      fill="#00A699"
    />
    <path
      d="M151.3 66.7894C153.576 66.7894 155.42 64.946 155.42 62.672C155.42 60.3981 153.576 58.5547 151.3 58.5547C149.024 58.5547 147.18 60.3981 147.18 62.672C147.18 64.946 149.024 66.7894 151.3 66.7894Z"
      fill="#1B365D"
    />
    <path
      d="M135.847 55.469C138.122 55.469 139.967 53.6257 139.967 51.3517C139.967 49.0778 138.122 47.2344 135.847 47.2344C133.571 47.2344 131.727 49.0778 131.727 51.3517C131.727 53.6257 133.571 55.469 135.847 55.469Z"
      fill="#0072CE"
    />
    <path
      d="M116.8 49.2913C119.076 49.2913 120.92 47.4479 120.92 45.174C120.92 42.9 119.076 41.0566 116.8 41.0566C114.524 41.0566 112.68 42.9 112.68 45.174C112.68 47.4479 114.524 49.2913 116.8 49.2913Z"
      fill="#84BD00"
    />
    <path
      d="M97.2219 49.2913C99.4975 49.2913 101.342 47.4479 101.342 45.174C101.342 42.9 99.4975 41.0566 97.2219 41.0566C94.9463 41.0566 93.1016 42.9 93.1016 45.174C93.1016 47.4479 94.9463 49.2913 97.2219 49.2913Z"
      fill="#00A699"
    />
    <path
      d="M78.6828 55.469C80.9584 55.469 82.8032 53.6257 82.8032 51.3517C82.8032 49.0778 80.9584 47.2344 78.6828 47.2344C76.4072 47.2344 74.5625 49.0778 74.5625 51.3517C74.5625 53.6257 76.4072 55.469 78.6828 55.469Z"
      fill="#1B365D"
    />
    <path
      d="M62.7141 66.7894C64.9897 66.7894 66.8344 64.946 66.8344 62.672C66.8344 60.3981 64.9897 58.5547 62.7141 58.5547C60.4385 58.5547 58.5938 60.3981 58.5938 62.672C58.5938 64.946 60.4385 66.7894 62.7141 66.7894Z"
      fill="#0072CE"
    />
    <path
      d="M51.3782 82.7444C53.6538 82.7444 55.4985 80.901 55.4985 78.6271C55.4985 76.3532 53.6538 74.5098 51.3782 74.5098C49.1026 74.5098 47.2578 76.3532 47.2578 78.6271C47.2578 80.901 49.1026 82.7444 51.3782 82.7444Z"
      fill="#84BD00"
    />
    <path
      d="M45.2063 101.274C47.4819 101.274 49.3266 99.4303 49.3266 97.1564C49.3266 94.8824 47.4819 93.0391 45.2063 93.0391C42.9307 93.0391 41.0859 94.8824 41.0859 97.1564C41.0859 99.4303 42.9307 101.274 45.2063 101.274Z"
      fill="#00A699"
    />
    <path
      d="M45.2063 120.83C47.4819 120.83 49.3266 118.987 49.3266 116.713C49.3266 114.439 47.4819 112.596 45.2063 112.596C42.9307 112.596 41.0859 114.439 41.0859 116.713C41.0859 118.987 42.9307 120.83 45.2063 120.83Z"
      fill="#1B365D"
    />
    <path
      d="M51.3782 139.358C53.6538 139.358 55.4985 137.514 55.4985 135.24C55.4985 132.966 53.6538 131.123 51.3782 131.123C49.1026 131.123 47.2578 132.966 47.2578 135.24C47.2578 137.514 49.1026 139.358 51.3782 139.358Z"
      fill="#0072CE"
    />
    <path
      d="M63.2297 155.313C65.5053 155.313 67.35 153.469 67.35 151.195C67.35 148.922 65.5053 147.078 63.2297 147.078C60.9541 147.078 59.1094 148.922 59.1094 151.195C59.1094 153.469 60.9541 155.313 63.2297 155.313Z"
      fill="#84BD00"
    />
    <path
      d="M78.6828 167.149C80.9584 167.149 82.8032 165.305 82.8032 163.031C82.8032 160.757 80.9584 158.914 78.6828 158.914C76.4072 158.914 74.5625 160.757 74.5625 163.031C74.5625 165.305 76.4072 167.149 78.6828 167.149Z"
      fill="#00A699"
    />
    <path
      d="M92.3321 158.402C94.1811 158.402 95.6799 156.904 95.6799 155.056C95.6799 153.209 94.1811 151.711 92.3321 151.711C90.4832 151.711 88.9844 153.209 88.9844 155.056C88.9844 156.904 90.4832 158.402 92.3321 158.402Z"
      fill="#84BD00"
    />
    <path
      d="M107.777 160.972C109.626 160.972 111.125 159.474 111.125 157.627C111.125 155.779 109.626 154.281 107.777 154.281C105.929 154.281 104.43 155.779 104.43 157.627C104.43 159.474 105.929 160.972 107.777 160.972Z"
      fill="#00A699"
    />
    <path
      d="M123.231 158.402C125.08 158.402 126.578 156.904 126.578 155.056C126.578 153.209 125.08 151.711 123.231 151.711C121.382 151.711 119.883 153.209 119.883 155.056C119.883 156.904 121.382 158.402 123.231 158.402Z"
      fill="#1B365D"
    />
    <path
      d="M136.621 151.71C138.47 151.71 139.969 150.212 139.969 148.365C139.969 146.517 138.47 145.02 136.621 145.02C134.772 145.02 133.273 146.517 133.273 148.365C133.273 150.212 134.772 151.71 136.621 151.71Z"
      fill="#0072CE"
    />
    <path
      d="M147.949 140.386C149.798 140.386 151.297 138.888 151.297 137.041C151.297 135.193 149.798 133.695 147.949 133.695C146.1 133.695 144.602 135.193 144.602 137.041C144.602 138.888 146.1 140.386 147.949 140.386Z"
      fill="#84BD00"
    />
    <path
      d="M154.652 126.491C156.501 126.491 158 124.994 158 123.146C158 121.299 156.501 119.801 154.652 119.801C152.804 119.801 151.305 121.299 151.305 123.146C151.305 124.994 152.804 126.491 154.652 126.491Z"
      fill="#00A699"
    />
    <path
      d="M156.715 111.052C158.564 111.052 160.063 109.554 160.063 107.707C160.063 105.859 158.564 104.361 156.715 104.361C154.866 104.361 153.367 105.859 153.367 107.707C153.367 109.554 154.866 111.052 156.715 111.052Z"
      fill="#1B365D"
    />
    <path
      d="M154.129 95.0969C155.978 95.0969 157.477 93.5992 157.477 91.7516C157.477 89.904 155.978 88.4062 154.129 88.4062C152.28 88.4062 150.781 89.904 150.781 91.7516C150.781 93.5992 152.28 95.0969 154.129 95.0969Z"
      fill="#0072CE"
    />
    <path
      d="M146.926 81.1985C148.775 81.1985 150.274 79.7007 150.274 77.8531C150.274 76.0056 148.775 74.5078 146.926 74.5078C145.077 74.5078 143.578 76.0056 143.578 77.8531C143.578 79.7007 145.077 81.1985 146.926 81.1985Z"
      fill="#84BD00"
    />
    <path
      d="M136.106 69.8782C137.955 69.8782 139.453 68.3804 139.453 66.5328C139.453 64.6853 137.955 63.1875 136.106 63.1875C134.257 63.1875 132.758 64.6853 132.758 66.5328C132.758 68.3804 134.257 69.8782 136.106 69.8782Z"
      fill="#00A699"
    />
    <path
      d="M122.199 62.6711C124.048 62.6711 125.547 61.1734 125.547 59.3258C125.547 57.4782 124.048 55.9805 122.199 55.9805C120.35 55.9805 118.852 57.4782 118.852 59.3258C118.852 61.1734 120.35 62.6711 122.199 62.6711Z"
      fill="#1B365D"
    />
    <path
      d="M106.754 60.0989C108.603 60.0989 110.102 58.6011 110.102 56.7535C110.102 54.906 108.603 53.4082 106.754 53.4082C104.905 53.4082 103.406 54.906 103.406 56.7535C103.406 58.6011 104.905 60.0989 106.754 60.0989Z"
      fill="#0072CE"
    />
    <path
      d="M91.3009 62.1575C93.1498 62.1575 94.6487 60.6597 94.6487 58.8121C94.6487 56.9646 93.1498 55.4668 91.3009 55.4668C89.452 55.4668 87.9531 56.9646 87.9531 58.8121C87.9531 60.6597 89.452 62.1575 91.3009 62.1575Z"
      fill="#84BD00"
    />
    <path
      d="M77.3947 69.3625C79.2436 69.3625 80.7424 67.8648 80.7424 66.0172C80.7424 64.1696 79.2436 62.6719 77.3947 62.6719C75.5457 62.6719 74.0469 64.1696 74.0469 66.0172C74.0469 67.8648 75.5457 69.3625 77.3947 69.3625Z"
      fill="#00A699"
    />
    <path
      d="M66.5822 80.1711C68.4311 80.1711 69.9299 78.6734 69.9299 76.8258C69.9299 74.9782 68.4311 73.4805 66.5822 73.4805C64.7332 73.4805 63.2344 74.9782 63.2344 76.8258C63.2344 78.6734 64.7332 80.1711 66.5822 80.1711Z"
      fill="#1B365D"
    />
    <path
      d="M59.8868 94.0657C61.7358 94.0657 63.2346 92.5679 63.2346 90.7203C63.2346 88.8728 61.7358 87.375 59.8868 87.375C58.0379 87.375 56.5391 88.8728 56.5391 90.7203C56.5391 92.5679 58.0379 94.0657 59.8868 94.0657Z"
      fill="#0072CE"
    />
    <path
      d="M57.3087 110.023C59.1576 110.023 60.6565 108.525 60.6565 106.677C60.6565 104.83 59.1576 103.332 57.3087 103.332C55.4598 103.332 53.9609 104.83 53.9609 106.677C53.9609 108.525 55.4598 110.023 57.3087 110.023Z"
      fill="#84BD00"
    />
    <path
      d="M59.8868 125.462C61.7358 125.462 63.2346 123.964 63.2346 122.117C63.2346 120.269 61.7358 118.771 59.8868 118.771C58.0379 118.771 56.5391 120.269 56.5391 122.117C56.5391 123.964 58.0379 125.462 59.8868 125.462Z"
      fill="#00A699"
    />
    <path
      d="M67.0978 139.872C68.9467 139.872 70.4456 138.375 70.4456 136.527C70.4456 134.679 68.9467 133.182 67.0978 133.182C65.2489 133.182 63.75 134.679 63.75 136.527C63.75 138.375 65.2489 139.872 67.0978 139.872Z"
      fill="#1B365D"
    />
    <path
      d="M79.4499 137.814C81.0144 137.814 82.2827 136.546 82.2827 134.983C82.2827 133.42 81.0144 132.152 79.4499 132.152C77.8854 132.152 76.6172 133.42 76.6172 134.983C76.6172 136.546 77.8854 137.814 79.4499 137.814Z"
      fill="#00A699"
    />
    <path
      d="M89.239 145.532C90.8035 145.532 92.0717 144.265 92.0717 142.702C92.0717 141.138 90.8035 139.871 89.239 139.871C87.6745 139.871 86.4062 141.138 86.4062 142.702C86.4062 144.265 87.6745 145.532 89.239 145.532Z"
      fill="#1B365D"
    />
    <path
      d="M101.083 149.652C102.647 149.652 103.915 148.384 103.915 146.821C103.915 145.258 102.647 143.99 101.083 143.99C99.5183 143.99 98.25 145.258 98.25 146.821C98.25 148.384 99.5183 149.652 101.083 149.652Z"
      fill="#0072CE"
    />
    <path
      d="M113.442 149.652C115.007 149.652 116.275 148.384 116.275 146.821C116.275 145.258 115.007 143.99 113.442 143.99C111.878 143.99 110.609 145.258 110.609 146.821C110.609 148.384 111.878 149.652 113.442 149.652Z"
      fill="#84BD00"
    />
    <path
      d="M124.778 145.532C126.343 145.532 127.611 144.265 127.611 142.702C127.611 141.138 126.343 139.871 124.778 139.871C123.214 139.871 121.945 141.138 121.945 142.702C121.945 144.265 123.214 145.532 124.778 145.532Z"
      fill="#00A699"
    />
    <path
      d="M134.559 138.329C136.124 138.329 137.392 137.062 137.392 135.499C137.392 133.935 136.124 132.668 134.559 132.668C132.995 132.668 131.727 133.935 131.727 135.499C131.727 137.062 132.995 138.329 134.559 138.329Z"
      fill="#1B365D"
    />
    <path
      d="M141.77 128.034C143.335 128.034 144.603 126.767 144.603 125.204C144.603 123.64 143.335 122.373 141.77 122.373C140.206 122.373 138.938 123.64 138.938 125.204C138.938 126.767 140.206 128.034 141.77 128.034Z"
      fill="#0072CE"
    />
    <path
      d="M145.895 116.196C147.46 116.196 148.728 114.929 148.728 113.366C148.728 111.802 147.46 110.535 145.895 110.535C144.331 110.535 143.062 111.802 143.062 113.366C143.062 114.929 144.331 116.196 145.895 116.196Z"
      fill="#84BD00"
    />
    <path
      d="M145.895 103.845C147.46 103.845 148.728 102.578 148.728 101.014C148.728 99.4509 147.46 98.1836 145.895 98.1836C144.331 98.1836 143.062 99.4509 143.062 101.014C143.062 102.578 144.331 103.845 145.895 103.845Z"
      fill="#00A699"
    />
    <path
      d="M141.77 91.4934C143.335 91.4934 144.603 90.226 144.603 88.6627C144.603 87.0994 143.335 85.832 141.77 85.832C140.206 85.832 138.938 87.0994 138.938 88.6627C138.938 90.226 140.206 91.4934 141.77 91.4934Z"
      fill="#1B365D"
    />
    <path
      d="M135.075 81.716C136.639 81.716 137.908 80.4487 137.908 78.8854C137.908 77.322 136.639 76.0547 135.075 76.0547C133.51 76.0547 132.242 77.322 132.242 78.8854C132.242 80.4487 133.51 81.716 135.075 81.716Z"
      fill="#0072CE"
    />
    <path
      d="M125.294 74.509C126.858 74.509 128.126 73.2417 128.126 71.6783C128.126 70.115 126.858 68.8477 125.294 68.8477C123.729 68.8477 122.461 70.115 122.461 71.6783C122.461 73.2417 123.729 74.509 125.294 74.509Z"
      fill="#84BD00"
    />
    <path
      d="M113.442 70.3918C115.007 70.3918 116.275 69.1245 116.275 67.5611C116.275 65.9978 115.007 64.7305 113.442 64.7305C111.878 64.7305 110.609 65.9978 110.609 67.5611C110.609 69.1245 111.878 70.3918 113.442 70.3918Z"
      fill="#00A699"
    />
    <path
      d="M101.083 70.3918C102.647 70.3918 103.915 69.1245 103.915 67.5611C103.915 65.9978 102.647 64.7305 101.083 64.7305C99.5183 64.7305 98.25 65.9978 98.25 67.5611C98.25 69.1245 99.5183 70.3918 101.083 70.3918Z"
      fill="#1B365D"
    />
    <path
      d="M89.7546 73.9934C91.3191 73.9934 92.5873 72.726 92.5873 71.1627C92.5873 69.5994 91.3191 68.332 89.7546 68.332C88.1901 68.332 86.9219 69.5994 86.9219 71.1627C86.9219 72.726 88.1901 73.9934 89.7546 73.9934Z"
      fill="#0072CE"
    />
    <path
      d="M79.4499 81.2004C81.0144 81.2004 82.2827 79.9331 82.2827 78.3697C82.2827 76.8064 81.0144 75.5391 79.4499 75.5391C77.8854 75.5391 76.6172 76.8064 76.6172 78.3697C76.6172 79.9331 77.8854 81.2004 79.4499 81.2004Z"
      fill="#84BD00"
    />
    <path
      d="M72.2468 91.4934C73.8113 91.4934 75.0795 90.226 75.0795 88.6627C75.0795 87.0994 73.8113 85.832 72.2468 85.832C70.6823 85.832 69.4141 87.0994 69.4141 88.6627C69.4141 90.226 70.6823 91.4934 72.2468 91.4934Z"
      fill="#00A699"
    />
    <path
      d="M68.6374 103.329C70.2019 103.329 71.4702 102.062 71.4702 100.499C71.4702 98.9353 70.2019 97.668 68.6374 97.668C67.0729 97.668 65.8047 98.9353 65.8047 100.499C65.8047 102.062 67.0729 103.329 68.6374 103.329Z"
      fill="#1B365D"
    />
    <path
      d="M68.6374 116.196C70.2019 116.196 71.4702 114.929 71.4702 113.366C71.4702 111.802 70.2019 110.535 68.6374 110.535C67.0729 110.535 65.8047 111.802 65.8047 113.366C65.8047 114.929 67.0729 116.196 68.6374 116.196Z"
      fill="#0072CE"
    />
    <path
      d="M72.2468 128.034C73.8113 128.034 75.0795 126.767 75.0795 125.204C75.0795 123.64 73.8113 122.373 72.2468 122.373C70.6823 122.373 69.4141 123.64 69.4141 125.204C69.4141 126.767 70.6823 128.034 72.2468 128.034Z"
      fill="#84BD00"
    />
    <path
      d="M78.4259 150.681C80.2748 150.681 81.7737 149.183 81.7737 147.336C81.7737 145.488 80.2748 143.99 78.4259 143.99C76.577 143.99 75.0781 145.488 75.0781 147.336C75.0781 149.183 76.577 150.681 78.4259 150.681Z"
      fill="#0072CE"
    />
  </svg>
);
