import {
  type ControlPointCriticalitiesListParams,
  type ControlPointCriticalityListOutputItem,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { injectQueryParams } from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'control-point-criticalities';

const controlPointCriticalityEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getControlPointCriticalityList: build.query<
      ControlPointCriticalityListOutputItem[],
      ControlPointCriticalitiesListParams
    >({
      query: (restParams) => injectQueryParams(`/${RESOURCE}/`, restParams),
      providesTags: [TagType.UserProfile, TagType.ControlPointCriticality],
    }),
  }),
  overrideExisting: false,
});

export const { useGetControlPointCriticalityListQuery } = controlPointCriticalityEndpoints;
