import { CloseRounded, DownloadRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogContent, DialogTitle, IconButton, Link as MuiLink } from '@mui/material';
import {
  GridActionsCellItem,
  type GridEnrichedColDef,
  type GridRenderCellParams,
  type GridRowId,
  type GridRowParams,
} from '@mui/x-data-grid-pro';
import { addYears } from 'date-fns';
import { type ReactNode, useContext, useState } from 'react';
import { type HelpDocumentListOutputItem } from 'src/__generated__/InternalApiTypes';
import {
  imageFileExtensions,
  supportedEditingFileExtensions,
} from 'src/components/common/DocumentPreview';
import {
  CustomNoRowsOverlay,
  MobileCompatibleDataGrid,
} from 'src/components/common/MobileCompatibleDataGrid';
import { useAppDispatch, useDialogState, useMutationFeedback, useTenantId } from 'src/hooks';
import { commonStrings, evidenceFormsDialogStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import {
  extractErrorMessage,
  useCreateEvidenceMutation,
  useGetHelpDocumentTypeListQuery,
} from 'src/services/farmApi';
import { type TransformedHelpDocumentListOutputItem } from 'src/services/farmApi';
import { openSnackbar } from 'src/store/snackbarSlice';
import { appColors } from 'src/theme';
import { createDataGridLinkedIdValueGetter } from 'src/utils/createDataGridLinkedIdValueGetter';
import { dataGridValueFormatterForDate } from 'src/utils/dataGridUtils';
import { downloadFileToLocal, getFileExtension, getFileSourceFromUrl } from 'src/utils/fileUtils';

import { CreateEvidenceDialog, type CreateEvidenceDialogProps } from '../CreateEvidenceDialog';
import {
  HelpDocumentPreviewDialog,
  type HelpDocumentPreviewDialogProps,
} from '../HelpDocumentPreviewDialog';

export interface EvidenceFormsDialogProps {
  categoryId: string;
  controlPointIds?: string[];
  evidenceTypeId: string;
  helpDocuments: TransformedHelpDocumentListOutputItem[];
  isOpen: boolean;
  onClose: () => void;
}

export const EvidenceFormsDialog = ({
  categoryId,
  controlPointIds,
  evidenceTypeId,
  helpDocuments,
  onClose: handleClose,
  isOpen = false,
}: EvidenceFormsDialogProps): React.JSX.Element => {
  const tid = useTenantId();
  const dispatch = useAppDispatch();
  const [assessmentId] = useContext(AssessmentPageContext);
  const [createEvidence, createEvidenceResult] = useCreateEvidenceMutation();
  const [createdDocumentName, setCreatedDocumentName] = useState('');
  const [isLoadingDocument, setIsLoadingDocument] = useState(false);
  const [clickedButtonId, setClickedButtonId] = useState<string | null>(null);
  const [isPreviewDialogOpen, openPreviewDialog, closePreviewDialog, previewDialogProps] =
    useDialogState<HelpDocumentPreviewDialogProps>();
  const [
    isCreateEvidenceDialogOpen,
    openCreateEvidenceDialog,
    closeCreateEvidenceDialog,
    createEvidenceDialogProps,
  ] = useDialogState<CreateEvidenceDialogProps>();

  const { data: helpDocumentTypeList, isLoading: isHelpDocumentTypeListLoading } =
    useGetHelpDocumentTypeListQuery();

  const handleDownload = (row: HelpDocumentListOutputItem): void => {
    downloadFileToLocal(row?.file, row?.name, (msg) =>
      dispatch(openSnackbar({ message: msg, severity: 'error' })),
    );
  };

  const handleCreateEvidence = async (
    selectedHelpDocument: HelpDocumentListOutputItem,
  ): Promise<void> => {
    if (selectedHelpDocument?.file?.file_object) {
      setClickedButtonId(selectedHelpDocument.uuid);
      setIsLoadingDocument(true);
      try {
        const [fileSource, newDocumentTitle] = await getFileSourceFromUrl(
          selectedHelpDocument.file,
          selectedHelpDocument.name,
        );
        setCreatedDocumentName(newDocumentTitle);
        if (tid) {
          createEvidence({
            tid,
            body: {
              file: fileSource,
              name: newDocumentTitle,
              expiry_date: addYears(new Date(), 1),
              is_draft: true,
              evidence_type_id: evidenceTypeId,
              category_ids: [categoryId],
              source_help_document_id: selectedHelpDocument.uuid,
            },
          });
        }
      } catch (error) {
        setClickedButtonId(null);
        setIsLoadingDocument(false);
        const message = extractErrorMessage(error);
        dispatch(openSnackbar({ message, severity: 'error' }));
      }
    }
  };

  useMutationFeedback({
    result: createEvidenceResult,
    onSuccess: (response) => {
      if (response) {
        setClickedButtonId(null);
        setIsLoadingDocument(false);
        openCreateEvidenceDialog({
          assessmentId,
          controlPointIds,
          evidenceId: String(response.uuid),
          evidenceTypeIds: [evidenceTypeId],
          isSignatureRequired: response.requires_signature,
          onCloseEvidenceFormsDialog: handleClose,
        });
      }
    },
    onError: () => {
      setClickedButtonId(null);
      setIsLoadingDocument(false);
    },
    successMessage: evidenceFormsDialogStrings('createDraftDocumentSuccessWithName', {
      name: createdDocumentName,
    }),
    errorMessage: evidenceFormsDialogStrings('createDraftDocumentFailWithName', {
      name: createdDocumentName,
    }),
  });

  const handleClickPreview = (id: GridRowId): void => {
    openPreviewDialog({
      helpDocumentId: String(id),
    });
  };

  const columns: GridEnrichedColDef[] = [
    {
      field: 'name',
      headerName: commonStrings('name'),
      flex: 2.6,
      renderCell: (
        params: GridRenderCellParams<string, TransformedHelpDocumentListOutputItem, ReactNode>,
      ) => (
        <MuiLink
          onClick={() => handleClickPreview(params.id)}
          sx={{
            color: appColors.blue,
            cursor: 'pointer',
          }}
          variant="body1"
        >
          {params.value}
        </MuiLink>
      ),
    },
    {
      field: 'document_type',
      headerName: commonStrings('type'),
      type: 'string',
      flex: 1.5,
      valueGetter: createDataGridLinkedIdValueGetter(helpDocumentTypeList, 'help_document_type_id'),
    },
    {
      field: 'organization_name',
      headerName: commonStrings('publishedBy'),
      type: 'string',
      flex: 1.4,
    },
    {
      field: 'expiry_date',
      headerName: commonStrings('expiryDate'),
      type: 'date',
      flex: 1,
      valueFormatter: dataGridValueFormatterForDate,
    },
    {
      field: 'created_at',
      headerName: evidenceFormsDialogStrings('publishedDate'),
      type: 'date',
      flex: 1,
      valueFormatter: dataGridValueFormatterForDate,
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'right',
      flex: 2,
      getActions: (params: GridRowParams<HelpDocumentListOutputItem>) => {
        const buttons = [];
        const fileSource: string | undefined = params.row.file?.file_object;
        if (fileSource) {
          const fileType = getFileExtension(fileSource);
          const isNotEditableFile =
            imageFileExtensions.includes(fileType) ||
            !supportedEditingFileExtensions.includes(fileType);
          if (!isNotEditableFile) {
            buttons.push(
              <LoadingButton
                key={`${params.row.uuid}_create`}
                color="info"
                disabled={isLoadingDocument}
                loading={isLoadingDocument && clickedButtonId === params.row.uuid}
                onClick={() => handleCreateEvidence(params.row)}
                size="small"
                variant="contained"
              >
                {commonStrings('createEvidence')}
              </LoadingButton>,
            );
          }
        }

        buttons.push(
          <GridActionsCellItem
            key={`${params.row.uuid}_download`}
            icon={<DownloadRounded />}
            label={commonStrings('download')}
            onClick={() => handleDownload(params.row)}
          />,
        );
        return buttons;
      },
    },
  ];

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        open={isOpen}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            padding: 1.5,
          }}
        >
          <CloseRounded />
        </IconButton>
        <DialogTitle>{commonStrings('evidenceForms')}</DialogTitle>
        <DialogContent>
          <MobileCompatibleDataGrid
            columns={columns}
            disableColumnSelector
            loading={isHelpDocumentTypeListLoading}
            NoRowsOverlay={() =>
              CustomNoRowsOverlay(evidenceFormsDialogStrings('noEvidenceFormToDisplay'))
            }
            rows={helpDocuments || []}
            sx={{ minHeight: 300 }}
          />
        </DialogContent>
      </Dialog>
      <HelpDocumentPreviewDialog
        isOpen={isPreviewDialogOpen}
        onClose={closePreviewDialog}
        {...previewDialogProps}
      />
      <CreateEvidenceDialog
        isOpen={isCreateEvidenceDialogOpen}
        onClose={closeCreateEvidenceDialog}
        {...createEvidenceDialogProps}
      />
    </>
  );
};
