import { type ControlPointVerificationMethodListOutputItem } from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { TagType } from '../TagType';

const RESOURCE = 'control-point-verification-methods';

const controlPointVerificationMethodEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getControlPointVerificationMethodList: build.query<
      ControlPointVerificationMethodListOutputItem[],
      void
    >({
      query: () => `/${RESOURCE}/`,
      providesTags: [TagType.ControlPointVerificationMethod],
    }),
  }),
  overrideExisting: false,
});

export const { useGetControlPointVerificationMethodListQuery } =
  controlPointVerificationMethodEndpoints;
