import { useTenantId } from 'src/hooks';
import {
  useGetAssessmentControlPointEvidenceListQuery,
  useGetControlPointCriticalityListQuery,
  useGetControlPointQuery,
} from 'src/services/farmApi';

export const useGetEvidenceControlPointDetail = (
  controlPointId: string,
  assessmentId: string,
  evidenceId: string,
) => {
  const skip = !controlPointId || !assessmentId;
  const tid = useTenantId();

  const {
    controlPointCode,
    controlPointCriteria,
    controlPointDisplayCode,
    criticalityId,
    mainText,
    smallLogo,
    isLoading: isControlPointLoading,
    isFetching: isControlPointFetching,
  } = useGetControlPointQuery(controlPointId, {
    skip,
    selectFromResult: ({ data, isLoading, isFetching }) => ({
      controlPointCode: data?.code,
      controlPointCriteria: data?.criteria,
      controlPointDisplayCode: data?.display_code,
      criticalityId: data?.criticality_id,
      mainText: data?.main_text,
      smallLogo: data?.logo,
      isLoading,
      isFetching,
    }),
  });

  const {
    evidenceLinkId,
    isLoading: isAssessmentControlPointEvidenceListLoading,
    isFetching: isAssessmentControlPointEvidenceListFetching,
  } = useGetAssessmentControlPointEvidenceListQuery(
    {
      tid,
      assessment_id: [assessmentId],
      control_point_id: [controlPointId],
    },
    {
      skip: skip || !tid,
      selectFromResult: ({ data, isLoading, isFetching }) => {
        const evidenceLink = (data || []).find(
          (assessmentControlPointEvidence) =>
            assessmentControlPointEvidence.evidence_id === evidenceId &&
            assessmentControlPointEvidence.control_point_id === controlPointId,
        );
        return {
          evidenceLinkId: evidenceLink?.uuid,
          isLoading,
          isFetching,
        };
      },
    },
  );

  const {
    data: controlPointCriticalityList,
    isLoading: isControlPointCriticalityListLoading,
    isFetching: isControlPointCriticalityListFetching,
  } = useGetControlPointCriticalityListQuery({});
  return {
    isLoading:
      isControlPointLoading ||
      isAssessmentControlPointEvidenceListLoading ||
      isControlPointCriticalityListLoading,
    isFetching:
      isControlPointFetching ||
      isAssessmentControlPointEvidenceListFetching ||
      isControlPointCriticalityListFetching,
    controlPointCode,
    controlPointCriteria,
    controlPointCriticalityList,
    controlPointDisplayCode,
    criticalityId,
    evidenceLinkId,
    mainText,
    smallLogo,
  };
};
