import { Box } from '@mui/material';
import { memo } from 'react';

import { cultivationRowStyle } from './AssessmentConfigMatrix.styles';
import { CheckboxCell } from './CheckboxCell';

interface CultivationCheckboxSetProps {
  colId: string;
  isChecked: boolean;
  isSwitchDisabled: boolean;
  rowId: string;
}

// Container of radio selection for the cultivations
export const CultivationCheckboxSet = memo(
  ({
    colId,
    rowId,
    isChecked = false,
    isSwitchDisabled = false,
  }: CultivationCheckboxSetProps): React.JSX.Element => (
    <Box sx={cultivationRowStyle}>
      <CheckboxCell
        colId={colId}
        isChecked={isChecked}
        isSwitchDisabled={isSwitchDisabled}
        rowId={rowId}
      />
    </Box>
  ),
);
