import { Check, ContentCopyRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { MobileCompatibleTooltip } from 'src/components/common/MobileCompatibleTooltip';
import { ThemeContext } from 'src/components/ThemeProvider';
import { assessmentFinishDialogForReviewersStrings, commonStrings } from 'src/languages/en-UK';

interface AssessmentFinishDialogForReviewersProps {
  onClose: () => void;
  onFinish: () => void;
}

export const AssessmentFinishDialogForReviewers = ({
  onClose,
  onFinish,
}: AssessmentFinishDialogForReviewersProps): React.JSX.Element => {
  const { theme } = useContext(ThemeContext);
  const urlToShare = window.location.toString().split('?')[0];
  const [hasCopiedUrl, setHasCopiedUrl] = useState(false);

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(urlToShare);
    setHasCopiedUrl(true);
  };
  const onCloseWithCopy = () => {
    handleCopyUrl();
    onClose();
  };
  const onFinishWithCopy = () => {
    handleCopyUrl();
    onFinish();
  };

  return (
    <>
      <DialogTitle>{assessmentFinishDialogForReviewersStrings('reviewCompleted')}</DialogTitle>
      <DialogContent>
        <Typography marginBottom={2}>
          {assessmentFinishDialogForReviewersStrings(
            'assessmentFinishDialogCompletedMsgForReviewers',
          )}
        </Typography>
        <Box marginBottom={2}>
          <Typography component="span">
            {assessmentFinishDialogForReviewersStrings(
              'assessmentFinishDialogApproveDescForReviewers',
            )}
            <Typography
              component="span"
              fontWeight={700}
            >
              {assessmentFinishDialogForReviewersStrings('approve')}
            </Typography>
            {assessmentFinishDialogForReviewersStrings(
              'assessmentFinishDialogNotifyDescForReviewers',
            )}
          </Typography>
        </Box>
        <Typography component="span">
          {assessmentFinishDialogForReviewersStrings(
            'assessmentFinishDialogApprovePendingDescForReviewers',
          )}
          <Typography
            component="span"
            fontWeight={700}
          >
            {assessmentFinishDialogForReviewersStrings('approvalPending')}
          </Typography>
          {assessmentFinishDialogForReviewersStrings(
            'assessmentFinishDialogContinueLaterDescForReviewers',
          )}
        </Typography>
        <Typography marginY={2}>
          {assessmentFinishDialogForReviewersStrings(
            'assessmentFinishDialogCopyLinkDescForReviewers',
          )}
        </Typography>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
        >
          <TextField
            disabled
            fullWidth
            id="outlined-url"
            multiline
            sx={{
              pr: 1,
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: theme.palette.text.primary,
              },
            }}
            value={urlToShare}
            variant="outlined"
          />
          <IconButton
            aria-haspopup="true"
            aria-label="copy shareable link"
            id="copy-button"
            onClick={handleCopyUrl}
          >
            {hasCopiedUrl ? (
              <MobileCompatibleTooltip title={commonStrings('copied')}>
                <Check color="success" />
              </MobileCompatibleTooltip>
            ) : (
              <MobileCompatibleTooltip title={commonStrings('copyLinkToClipboard')}>
                <ContentCopyRounded />
              </MobileCompatibleTooltip>
            )}
          </IconButton>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{ mt: 3, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
      >
        <Button
          onClick={onCloseWithCopy}
          variant="outlined"
        >
          {assessmentFinishDialogForReviewersStrings('approvalPending')}
        </Button>
        <Button
          onClick={onFinishWithCopy}
          variant="contained"
        >
          {assessmentFinishDialogForReviewersStrings('approve')}
        </Button>
      </DialogActions>
    </>
  );
};
