import { Box, Chip, TextField, useMediaQuery } from '@mui/material';
import { useContext } from 'react';
import { controlPointAnswerPointsStrings } from 'src/languages/en-UK';

import { ThemeContext } from '../ThemeProvider';

export enum AnswerChipStatus {
  NonCompliant = 'Non-Compliant',
  NoAnswer = 'No Answer',
  NotApplicable = 'N/A',
  Compliant = 'Compliant',
  Answered = 'Answered',
}

interface ControlPointAnswerPointsProps {
  status: AnswerChipStatus;
  statusLabel: string;
  value: number;
  showPointBox?: boolean;
}

export const mapComplianceStatusToAnswerChip = (complianceStatus: string): AnswerChipStatus => {
  switch (complianceStatus) {
    case 'not_compliant':
      return AnswerChipStatus.NonCompliant;
    case 'not_applicable':
      return AnswerChipStatus.NotApplicable;
    case 'compliant':
      return AnswerChipStatus.Compliant;
    case 'answered':
      return AnswerChipStatus.Answered;
    default:
      return AnswerChipStatus.NoAnswer;
  }
};

export const getComplianceStatusLabel = (statusLabel: string): string =>
  statusLabel || controlPointAnswerPointsStrings('noAnswer');

export const ControlPointAnswerPoints = ({
  status,
  statusLabel,
  value,
  showPointBox = false,
}: ControlPointAnswerPointsProps): React.JSX.Element => {
  let color: 'default' | 'success' | 'error' = 'default';
  if (status === AnswerChipStatus.Compliant || status === AnswerChipStatus.Answered) {
    color = 'success';
  } else if (status === AnswerChipStatus.NonCompliant) {
    color = 'error';
  }
  const { theme } = useContext(ThemeContext);
  const isNotExtraLargeDevice = useMediaQuery(theme.breakpoints.down('xl'));

  return (
    <>
      {showPointBox && (value || value === 0) ? (
        <TextField
          disabled
          label={controlPointAnswerPointsStrings('points')}
          sx={{
            maxWidth: 67,
            ml: { xs: 0, xl: 2 },
            mt: isNotExtraLargeDevice ? 1.6 : undefined,
            '& .MuiOutlinedInput-notchedOutline': {
              borderStyle: 'dashed',
            },
            '& .MuiInputBase-input': {
              py: 1,
              pr: 1.75,
              pl: 1.25,
              textAlign: 'center',
            },
          }}
          value={value}
        />
      ) : null}
      <Box
        alignItems="center"
        display="flex"
        justifyContent="flex-end"
        marginTop={isNotExtraLargeDevice ? 1 : undefined}
        width={{ xs: '100%', xl: 133 }}
      >
        <Chip
          color={color}
          label={statusLabel}
          sx={{ minWidth: 46 }}
          variant="filled"
        />
      </Box>
    </>
  );
};
