import { CheckCircleRounded, CloseRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { appColors, theme } from 'src/theme';

export interface SubscriptionPlanDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

type PlansType = {
  id: number;
  bgColor: string;
  buttonName: string;
  description: string;
  isDisabled: boolean;
  name: string;
  planAdvantages: { id: number; label: string }[];
  amount?: string;
  amountPeriod?: string;
  discountAmount?: string;
  discountAmountPeriod?: string;
  isBestText?: string;
};

export const SubscriptionPlanDialog = ({
  isOpen,
  onClose,
}: SubscriptionPlanDialogProps): React.JSX.Element => {
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
  // TODOHasan: This data should come from AFA-1217.
  const plans: PlansType[] = [
    {
      id: 1,
      bgColor: '#00796B1F',
      buttonName: 'Coming Jan \'25',
      description: 'Prepare for 4 main certificates separately with a smart checklist',
      isDisabled: true,
      name: 'Basic Plan',
      planAdvantages: [
        { id: 1, label: 'Smart self assessment' },
        { id: 2, label: 'Reuse your data next year' },
        { id: 3, label: 'VVAK certificate' },
        { id: 4, label: 'PlanetProof certificate' },
        { id: 5, label: 'GlobalGAP IFA v.6 certificate' },
        { id: 6, label: 'GRASP v2 certificate' },
      ],
    },
    {
      id: 2,
      bgColor: '#1976D21F',
      buttonName: 'Coming Jan \'25',
      description: 'Combine GlobalGAP with all its add-ons into one efficient self assessment',
      isDisabled: true,
      name: 'Plus Plan',
      planAdvantages: [
        { id: 1, label: 'Smart self assessment' },
        { id: 2, label: 'Reuse your data next year' },
        { id: 3, label: 'VVAK certificate' },
        { id: 4, label: 'PlanetProof certificate' },
        { id: 5, label: 'GlobalGAP IFA v.6 certificate' },
        {
          id: 6,
          label:
            'All GlobalGAP add-ons (AH-DLL GROW, AH Beter voor, GRASP v2) together with GlobalGAP IFA v.6 in one combined self assessment',
        },
      ],
    },
    {
      id: 3,
      amount: '€245',
      amountPeriod: 'UNTIL JAN 2025',
      bgColor: '#FAEEE3',
      buttonName: 'Get started',
      description: 'Boost all your certificate preparation in one combined self assessment',
      isBestText: 'BETA',
      isDisabled: false,
      name: 'Pro Plan',
      planAdvantages: [
        { id: 1, label: 'Smart self assessment' },
        { id: 2, label: 'Reuse your data next year' },
        { id: 3, label: 'VVAK certificate' },
        { id: 4, label: 'PlanetProof certificate' },
        { id: 5, label: 'GlobalGAP IFA v.6 certificate' },
        { id: 6, label: 'All GlobalGAP add-ons (AH-DLL GROW, AH Beter voor, GRASP v2)' },
        { id: 7, label: 'Combined self assessment with all certificates included in one' },
      ],
    },
    {
      id: 4,
      amount: 'Let\'s talk',
      bgColor: '#F9F2FA',
      buttonName: 'Contact us',
      description: 'Customised to meet the needs of your members, clients or suppliers',
      isDisabled: false,
      name: 'Assurance Plan',
      planAdvantages: [
        { id: 1, label: 'For standard setters' },
        { id: 2, label: 'For farm assurers/ advisors' },
        { id: 3, label: 'For farm associations' },
        { id: 4, label: 'For buyers' },
        { id: 5, label: 'For universities & knowledge institutions' },
        { id: 6, label: 'For certification bodies' },
        { id: 7, label: 'For technical working groups' },
      ],
    },
  ];

  return (
    <Dialog
      fullWidth
      onClose={onClose}
      open={isOpen}
      PaperProps={{
        style: {
          minWidth: '95%',
          minHeight: '90%',
        },
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          padding: 1.5,
        }}
      >
        <CloseRounded />
      </IconButton>
      <DialogContent>
        <Stack
          alignItems="center"
          display="flex"
          marginTop={1}
        >
          <Typography
            fontSize={40}
            lineHeight={1.167}
            textAlign="center"
            variant="h1"
          >
            Ready to cut audit preparation time?
          </Typography>
          <Typography
            marginY={3}
            variant="h2"
          >
            Choose the Agriplace plan that works for you
          </Typography>
          <Box
            display="flex"
            flexDirection={isSmallDevice ? 'column' : 'row'}
            padding={2}
          >
            {plans.map((plan) => (
              <Paper
                key={plan.id}
                sx={{
                  mr: 1.5,
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden',
                  position: 'relative',
                  flex: 1,
                  my: !plan.isBestText ? 2 : 0,
                  opacity: plan.isDisabled ? 0.5 : 1,
                }}
              >
                {plan.isBestText && (
                  <Box
                    sx={{
                      bgcolor: '#E56115',
                      position: 'absolute',
                      left: -40,
                      transform: 'translateX(50%) rotate(37.6deg)',
                      top: 20,
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    <Typography
                      lineHeight={1.172}
                      sx={{ color: appColors.white }}
                      variant="overline"
                    >
                      {plan.isBestText}
                    </Typography>
                  </Box>
                )}
                <Box
                  alignItems="center"
                  bgcolor={plan.bgColor}
                  borderBottom={0.5}
                  borderColor="divider"
                  display="flex"
                  flexDirection="column"
                  marginBottom={1}
                  padding={2}
                >
                  <Typography
                    marginBottom={1}
                    marginTop={plan.isBestText ? 1 : 0}
                    textAlign="center"
                    variant="h2"
                  >
                    {plan.name}
                  </Typography>
                  <Typography
                    sx={{ px: 2 }}
                    textAlign="center"
                    variant="body2"
                  >
                    {plan.description}
                  </Typography>
                </Box>
                <Box
                  flex={1}
                  paddingBottom={1}
                  paddingTop={3}
                  paddingX={2}
                >
                  {plan.planAdvantages.map((advantage) => (
                    <Box
                      key={advantage.id}
                      alignItems="center"
                      display="flex"
                      flexDirection="row"
                      paddingBottom={2}
                    >
                      <CheckCircleRounded color="success" />
                      <Typography
                        marginLeft={2}
                        variant="body1"
                      >
                        {advantage.label}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                {plan.discountAmount && (
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="center"
                    marginBottom={0.25}
                  >
                    <Typography
                      fontWeight={500}
                      marginRight={1}
                      variant="h2"
                    >
                      {plan.discountAmount}
                    </Typography>
                    {plan.discountAmountPeriod && (
                      <Typography
                        lineHeight={1.172}
                        variant="overline"
                      >{`/${plan.discountAmountPeriod}`}</Typography>
                    )}
                  </Box>
                )}
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="center"
                  marginBottom={1}
                >
                  {plan.amount && (
                    <Typography
                      color={plan.discountAmount ? 'textSecondary' : 'textPrimary'}
                      fontWeight={500}
                      marginRight={1}
                      sx={{ textDecoration: plan.discountAmount ? 'line-through' : 'default' }}
                      variant="h2"
                    >
                      {plan.amount}
                    </Typography>
                  )}
                  {plan.amountPeriod && (
                    <Typography
                      color={plan.discountAmount ? 'textSecondary' : 'textPrimary'}
                      lineHeight={1.172}
                      sx={{ textDecoration: plan.discountAmount ? 'line-through' : 'default' }}
                      variant="overline"
                    >{`/${plan.amountPeriod}`}</Typography>
                  )}
                </Box>
                <Button
                  disabled={plan.isDisabled}
                  // TODOHasan: This click should trigger some endpoint.
                  onClick={() => {}}
                  sx={{ m: 2 }}
                  variant="contained"
                >
                  {plan.buttonName}
                </Button>
              </Paper>
            ))}
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
