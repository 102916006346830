import { ExpandMoreRounded } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  type PaperProps,
  Typography,
} from '@mui/material';
import { type ReactElement, type ReactNode, useContext } from 'react';
import { useTenantId } from 'src/hooks';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import { useGetAssessmentCategoryEvidenceTypeStatusQuery } from 'src/services/farmApi';

import { getStatusIcon } from '../AssessmentEvidenceCategory';

interface EvidenceExpandableStackItemAction {
  label: ReactNode;
  onClick: () => void;
  variant?: 'text' | 'outlined' | 'contained';
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  getVisibility?: (props: { isLast: boolean }) => boolean;
}

interface EvidenceExpandableStackItemDetail {
  title: ReactNode;
  details: ReactNode;
  bottomActions?: EvidenceExpandableStackItemAction[];
}

interface EvidenceExpandableStackItemProps extends PaperProps {
  criticalityId: string;
  expandedId: string;
  id: string;
  index: number;
  item: EvidenceExpandableStackItemDetail;
  onChangeIndex: (ind: number) => void;
}

export const EvidenceExpandableStackItem = ({
  criticalityId,
  expandedId,
  id,
  index,
  item,
  onChangeIndex,
}: EvidenceExpandableStackItemProps): React.JSX.Element => {
  const tid = useTenantId();
  const [assessmentId] = useContext(AssessmentPageContext);

  const { data: assessmentCategoryEvidenceTypeStatus } =
    useGetAssessmentCategoryEvidenceTypeStatusQuery(
      {
        tid,
        id: assessmentId,
        agriplace_criticality_id: criticalityId,
        category_id: id,
      },
      { skip: !tid || !assessmentId },
    );

  const status = assessmentCategoryEvidenceTypeStatus?.category?.status;

  return (
    <Box
      key={`${id}_accordion`}
      id={`${id}_accordion`}
      marginTop={1}
    >
      <Accordion
        disableGutters
        expanded={expandedId === id}
        onChange={() => onChangeIndex?.(index)}
        slotProps={{ transition: { unmountOnExit: true } }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          sx={{ paddingY: 1 }}
        >
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            gap={1}
          >
            <Box sx={{ flex: 1 }}>{getStatusIcon(status)}</Box>
            <Typography
              fontWeight={500}
              lineHeight={1.667}
              variant="overline"
            >
              {item.title}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ pt: 0 }}>{item.details}</AccordionDetails>
      </Accordion>
    </Box>
  );
};
