import { CloseRounded } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { assessmentAutoAttachRequestDialogStrings, commonStrings } from 'src/languages/en-UK';
import { theme } from 'src/theme';

export interface AssessmentAutoAttachRequestDialogProps {
  isOpen: boolean;
  onClick: (enableAutoAttach?: boolean) => void;
  onClose: () => void;
}

export const AssessmentAutoAttachRequestDialog = ({
  onClick,
  onClose,
  isOpen = false,
}: AssessmentAutoAttachRequestDialogProps): React.JSX.Element => {
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
  const handleAnswerDialog = (enableAutoAttach = false) => {
    onClose();
    onClick(enableAutoAttach);
  };

  return (
    <Dialog
      fullWidth
      onClose={() => {
        onClose();
      }}
      open={isOpen}
      PaperProps={{
        style: {
          minWidth: isSmallDevice ? undefined : 625,
        },
      }}
    >
      <IconButton
        onClick={() => {
          onClose();
        }}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          padding: 1.5,
        }}
      >
        <CloseRounded />
      </IconButton>
      <DialogTitle>{assessmentAutoAttachRequestDialogStrings('doYouWantToAutoAttach')}</DialogTitle>
      <DialogContent>
        <Typography component="span">
          {assessmentAutoAttachRequestDialogStrings('ifYouWantAutoAttached')}
          <Typography
            component="span"
            fontWeight={700}
          >{` ${commonStrings('yes')}.`}</Typography>
        </Typography>
        <Typography marginTop={3}>
          {assessmentAutoAttachRequestDialogStrings('ifYouPreferMakeAttachmentsYourself')}
          <Typography
            component="span"
            fontWeight={700}
          >{` ${commonStrings('no')}. `}</Typography>
        </Typography>
        <Typography marginTop={3}>
          {assessmentAutoAttachRequestDialogStrings('benefitFromAutomaticReuse')}
        </Typography>
        <Typography marginTop={3}>
          {assessmentAutoAttachRequestDialogStrings('selectionCouldNotBeChangedLater')}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          gap: 1,
          flex: 1,
          mt: 1,
        }}
      >
        <Button
          onClick={() => {
            handleAnswerDialog();
          }}
          variant="outlined"
        >
          {commonStrings('no')}
        </Button>
        <Button
          onClick={() => {
            handleAnswerDialog(true);
          }}
          variant="outlined"
        >
          {commonStrings('yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
