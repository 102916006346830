import { AppBar, Box, Toolbar } from '@mui/material';
import { type ReactNode } from 'react';

import { AppPrimaryNavigation } from '../AppPrimaryNavigation';
// import { HelpDeskMenu } from '../HelpDeskMenu';
import { type RouteDefinition } from '../types/RouteDefinition';

interface AppHeaderProps {
  routes: RouteDefinition[];
  children: ReactNode;
}

export const AppHeader = ({ routes, children }: AppHeaderProps): React.JSX.Element => (
  <AppBar position="fixed">
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <AppPrimaryNavigation routes={routes} />
      <Box display="flex">
        {/* <HelpDeskMenu /> */}
        <Box
          alignItems="center"
          display="flex"
          gap={2}
        >
          {children}
        </Box>
      </Box>
    </Toolbar>
  </AppBar>
);
