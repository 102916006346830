import { EditRounded } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCurrentOrganization, useTenantId } from 'src/hooks';
import { commonStrings, dashboardOrganizationBlockStrings } from 'src/languages/en-UK';
import { useGetOrganizationPlotCultivationListQuery } from 'src/services/farmApi';
import { ROUTE_PATH_ORGANIZATION, ROUTE_PATH_ORGANIZATION_CULTIVATIONS } from 'src/settings';

import { LoadingArea } from '../common';
import { DashboardBlockRow } from '../DashboardBlockRow';
import { DashboardBlockWrapper } from '../DashboardBlockWrapper';

export const DashboardOrganizationBlock = (): React.JSX.Element => {
  const tid = useTenantId();
  const navigate = useNavigate();
  const { data: organization, isLoading: isOrganizationLoading } = useCurrentOrganization();

  const { data: organizationCultivations, isLoading: isOrganizationCultivationsLoading } =
    useGetOrganizationPlotCultivationListQuery(
      { tid },
      {
        skip: !organization || !tid,
      },
    );

  const handleClickEdit = () => {
    navigate(ROUTE_PATH_ORGANIZATION);
  };

  const handleClickCultivations = () => {
    navigate(ROUTE_PATH_ORGANIZATION_CULTIVATIONS);
  };

  return (
    <DashboardBlockWrapper
      isLoading={isOrganizationLoading}
      sx={{ height: 'min-content', minHeight: 210 }}
      title={
        organization?.name
          ? commonStrings('organizationProfileDynamic', {
              organizationName: organization.name,
            })
          : commonStrings('organizationProfile')
      }
    >
      {!organization ? (
        <LoadingArea sx={{ mt: 8 }} />
      ) : (
        <Stack
          marginBottom={1}
          spacing={2}
        >
          <DashboardBlockRow
            count={organizationCultivations?.length?.toString() || '0'}
            isLoading={isOrganizationCultivationsLoading}
            label={dashboardOrganizationBlockStrings('cultivationsAdded')}
            onClick={handleClickCultivations}
          />
          <Button
            fullWidth
            onClick={handleClickEdit}
            size="large"
            startIcon={<EditRounded />}
            variant="outlined"
          >
            {dashboardOrganizationBlockStrings('editProfileDetails')}
          </Button>
        </Stack>
      )}
    </DashboardBlockWrapper>
  );
};
