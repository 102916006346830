import { EditRounded } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Container,
  type ContainerProps,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import { isEqual, uniqWith } from 'lodash';
import { useContext } from 'react';
import {
  type AssessmentCreateInput,
  type AssessmentUpdateInput,
  RolesEnum,
} from 'src/__generated__/InternalApiTypes';
import { useAppDispatch, useAppSelector, useCurrentUserRoles } from 'src/hooks';
import { assessmentConfigActionBarStrings, commonStrings } from 'src/languages/en-UK';
import { openSnackbar } from 'src/store/snackbarSlice';

import { AssessmentActionBarButtons } from '../AssessmentActionBarButtons';
import { ThemeContext } from '../ThemeProvider';

interface AssessmentConfigActionBarProps {
  isConfigButtonDisabled: boolean;
  isDisabled: boolean;
  isFinalTask: boolean;
  isFirstTask: boolean;
  isInitialized: boolean;
  isNextTaskLoading: boolean;
  isPreviousTaskLoading: boolean;
  maxWidth: ContainerProps['maxWidth'];
  nextTaskName: string;
  onNext: (data: AssessmentCreateInput | AssessmentUpdateInput) => void;
  onOpen: () => void;
  onPrevious: () => void;
}

export const AssessmentConfigActionBar = ({
  nextTaskName,
  onNext,
  onOpen,
  onPrevious,
  isConfigButtonDisabled = false,
  isDisabled = false,
  isFinalTask = false,
  isFirstTask = false,
  isInitialized = false,
  isNextTaskLoading = false,
  isPreviousTaskLoading = false,
  maxWidth = 'md',
}: AssessmentConfigActionBarProps): React.JSX.Element => {
  const dispatch = useAppDispatch();
  const standardsCount = useAppSelector((state) => state.assessmentConfig.columns.length);
  const cultivationsCount = useAppSelector((state) => state.assessmentConfig.rows.length);
  const selections = useAppSelector((state) => state.assessmentConfig.selections);
  const columns = useAppSelector((state) => state.assessmentConfig.columns);
  const { theme } = useContext(ThemeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const currentUserRoles = useCurrentUserRoles();
  const isAuditor = currentUserRoles.includes(RolesEnum.Auditor);
  const isCBManager = currentUserRoles.includes(RolesEnum.CertificationBodyManager);

  const handleClickNext = (): void => {
    let errorMsg = '';

    // Show "validation error" if user has not configured the Assessment Config Matrix
    if (standardsCount < 1 || cultivationsCount < 1) {
      errorMsg = assessmentConfigActionBarStrings('configureAtLeastOneCultivation');
    }

    // Show "validation error" if user has not selected at least one module/cultivation combo
    if (selections.length < 1) {
      errorMsg = assessmentConfigActionBarStrings('selectAtLeastOneCultivation');
    }

    // Show "malus point error" if user has not entered any malus points if required.
    selections.forEach((s) => {
      if (columns.some((c) => c.usesMalusPoint && c.id === s.colId) && s.malusPoint === undefined) {
        errorMsg = assessmentConfigActionBarStrings('malusPointsAreRequired');
      }
    });

    if (!errorMsg) {
      const filteredSelections = selections.map((selection) => ({
        module_id: selection.colId,
        organization_cultivation_id: selection.rowId,
        ...(selection.malusPoint !== undefined &&
          selection.malusPoint !== null && { malus_points: selection.malusPoint }),
      }));
      const uniqueSelection = uniqWith(filteredSelections, isEqual);
      onNext({
        module_cultivation_selections: uniqueSelection,
      });
    } else {
      dispatch(
        openSnackbar({
          message: errorMsg,
          severity: 'error',
        }),
      );
    }
  };

  let editButtonLabel = '';
  if (!isMobile) {
    if (isAuditor || isCBManager) {
      editButtonLabel = commonStrings('editInspectionSetup');
    } else {
      editButtonLabel = commonStrings('editAssessmentSetup');
    }
  }

  return (
    <>
      <Toolbar />
      <AppBar
        position="fixed"
        sx={{ top: 'auto', bottom: 0 }}
      >
        <Container
          maxWidth={maxWidth}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: { xs: 'flex-start', sm: 'center' },
          }}
        >
          <Box>
            {isInitialized && (
              <Button
                disabled={isConfigButtonDisabled}
                onClick={onOpen}
                size="small"
                startIcon={<EditRounded />}
                sx={{
                  py: 1,
                  mt: { xs: 1.5, sm: 0 },
                  minWidth: isMobile ? 20 : undefined,
                  '.MuiButton-startIcon': {
                    mr: { xs: 0, sm: 1 },
                  },
                }}
                variant="outlined"
              >
                {editButtonLabel}
              </Button>
            )}
          </Box>
          <AssessmentActionBarButtons
            isDisabled={isDisabled}
            isFinalTask={isFinalTask}
            isFirstTask={isFirstTask}
            isNextButtonLoading={isNextTaskLoading}
            isPreviousButtonLoading={isPreviousTaskLoading}
            nextButtonVariant={isInitialized && selections.length > 0 ? 'contained' : 'outlined'}
            nextTaskName={nextTaskName}
            onNext={handleClickNext}
            onPrevious={onPrevious}
          />
        </Container>
      </AppBar>
    </>
  );
};
