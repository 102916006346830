import { type FormikValues } from 'formik';
import { FormikDialog } from 'src/components/formikcomponents/FormikDialog';
import { FormikInvitationRoleField } from 'src/components/formikcomponents/FormikInvitationRoleField';
import { FormikTextField } from 'src/components/formikcomponents/FormikTextField';
import { useAppDispatch } from 'src/hooks';
import { commonStrings, organizationInvitationEditDialogStrings } from 'src/languages/en-UK';
import {
  useCreateOrganizationInviterInvitationMutation,
  useGetOrganizationInviterInvitationQuery,
  useUpdateOrganizationInviterInvitationMutation,
} from 'src/services/farmApi';
import { openSnackbar } from 'src/store/snackbarSlice';
import * as Yup from 'yup';

export interface OrganizationInvitationEditDialogProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  defaultValues?: FormikValues;
  userId?: string;
}

export const OrganizationInvitationEditDialog = ({
  defaultValues,
  onClose,
  title,
  userId,
  isOpen = false,
}: OrganizationInvitationEditDialogProps): React.JSX.Element => {
  const dispatch = useAppDispatch();
  const getFormikBodyMap = (values: FormikValues) => ({
    ...values,
    invitee: values.invitee ? values.invitee.toLowerCase() : values.invitee,
    roles: [values.roles],
  });

  const getFormikFormMap = (values: FormikValues) => ({
    ...values,
    roles: values.roles[0],
  });

  return (
    <FormikDialog
      createHook={useCreateOrganizationInviterInvitationMutation}
      entityId={userId}
      fetchHook={useGetOrganizationInviterInvitationQuery}
      fields={[
        {
          id: 'name',
          renderAs: (props) => (
            <FormikTextField
              {...props}
              label={commonStrings('fullName')}
              required
            />
          ),
          validator: Yup.string().required(commonStrings('required')),
        },
        {
          id: 'invitee',
          renderAs: (props) => (
            <FormikTextField
              {...props}
              label={commonStrings('email')}
              required
            />
          ),
          validator: Yup.string().required(commonStrings('required')),
        },
        {
          id: 'roles',
          defaultValue: defaultValues?.roles,
          renderAs: (props) => (
            <FormikInvitationRoleField
              {...props}
              label={commonStrings('role')}
              required
            />
          ),
          validator: Yup.string().required(commonStrings('required')),
        },
      ]}
      getBodyMap={getFormikBodyMap}
      getFormMap={getFormikFormMap}
      hideCreateSuccessMsg
      isOpen={isOpen}
      onClose={onClose}
      onCreateSuccess={(createResponse) => {
        if (createResponse?.invitee) {
          dispatch(
            openSnackbar({
              message: organizationInvitationEditDialogStrings('createInviteSuccessMessage', {
                email: createResponse.invitee,
              }),
              severity: 'success',
            }),
          );
        }
      }}
      propertyName="invitee"
      submitButtonText={organizationInvitationEditDialogStrings('sendInvite')}
      title={title}
      updateHook={useUpdateOrganizationInviterInvitationMutation}
    />
  );
};
