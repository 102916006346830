import { Box, type Breakpoint, CircularProgress, Container, type SxProps } from '@mui/material';
import AgriplaceLoadingLogo from 'assets/agriplace-loading-logo.svg';

interface LoadingAreaProps {
  hasAgriplaceLoadingAnimation?: boolean;
  height?: number;
  maxWidth?: Breakpoint | null;
  size?: number;
  sx?: SxProps;
  value?: number;
  variant?: 'determinate' | 'indeterminate';
}

export const LoadingArea = ({
  height,
  maxWidth,
  sx,
  value,
  hasAgriplaceLoadingAnimation = false,
  size = 40,
  variant = 'indeterminate',
}: LoadingAreaProps): React.JSX.Element => (
  <Container
    maxWidth={maxWidth}
    sx={{ flex: '1 0 auto', position: 'relative', height: height || '100%', ...sx }}
  >
    <Box
      left="50%"
      marginLeft={`${-0.5 * size}px`}
      marginTop={`${-0.5 * size}px`}
      position="absolute"
      top="50%"
    >
      {hasAgriplaceLoadingAnimation ? (
        <img
          alt="AgriPlace logo with loading animation"
          height={`${size}px`}
          src={AgriplaceLoadingLogo}
          width={`${size}px`}
        />
      ) : (
        <CircularProgress
          size={size}
          value={value}
          variant={variant}
        />
      )}
    </Box>
  </Container>
);
