import { AddRounded, DeleteRounded, EditRounded } from '@mui/icons-material';
import { Box, Button, Chip, Toolbar, Typography } from '@mui/material';
import {
  GridActionsCellItem,
  type GridEnrichedColDef,
  type GridRenderCellParams,
  type GridRowParams,
} from '@mui/x-data-grid-pro';
import { type ReactNode } from 'react';
import {
  type InviterOrganizationInvitationListOutputItem,
  RolesEnum,
} from 'src/__generated__/InternalApiTypes';
import {
  CustomNoRowsOverlay,
  MobileCompatibleDataGrid,
} from 'src/components/common/MobileCompatibleDataGrid';
import {
  GenericDeleteDialog,
  type GenericDeleteDialogProps,
} from 'src/components/dialogs/GenericDeleteDialog';
import {
  OrganizationInvitationEditDialog,
  type OrganizationInvitationEditDialogProps,
} from 'src/components/dialogs/OrganizationInvitationEditDialog';
import { useCurrentUserRoles, useDialogState, useTenantId } from 'src/hooks';
import { commonStrings, organizationInvitationTableStrings } from 'src/languages/en-UK';
import {
  useDeleteOrganizationInviterInvitationMutation,
  useGetOrganizationInviterInvitationListQuery,
} from 'src/services/farmApi';

export const OrganizationInvitationTable = (): React.JSX.Element => {
  const tid = useTenantId();
  const currentUserRoles = useCurrentUserRoles();
  const isCBManager = currentUserRoles.includes(RolesEnum.CertificationBodyManager);
  const [
    isOrganizationInvitationEditDialogOpen,
    openOrganizationInvitationEditDialog,
    closeOrganizationInvitationEditDialog,
    organizationInvitationEditDialogProps,
  ] = useDialogState<OrganizationInvitationEditDialogProps>();
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog, deleteDialogProps] =
    useDialogState<Omit<GenericDeleteDialogProps, 'deleteMutation'>>();

  const { data: organizationInvitations, isLoading } = useGetOrganizationInviterInvitationListQuery(
    { tid },
    { skip: !tid },
  );

  const columns: GridEnrichedColDef[] = [
    { field: 'name', headerName: commonStrings('name'), flex: 2 },
    { field: 'invitee', headerName: commonStrings('email'), flex: 2 },
    {
      field: 'role_labels',
      headerName: commonStrings('role'),
      flex: 1,
      valueGetter: (params) => {
        const rolesLabel: string[] = [];
        params?.value?.forEach((role: string) => {
          rolesLabel.push(role);
        });
        return rolesLabel?.join(', ');
      },
    },
    {
      field: 'status',
      headerName: commonStrings('status'),
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<
          string,
          InviterOrganizationInvitationListOutputItem,
          ReactNode
        >,
      ) =>
        params.value && (
          <Chip
            label={params.value}
            variant="outlined"
          />
        ),
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'right',
      getActions: (params: GridRowParams<InviterOrganizationInvitationListOutputItem>) =>
        !params.row.is_pending
          ? [
              <GridActionsCellItem
                key={`${params.row.uuid}_delete`}
                icon={<DeleteRounded />}
                label={commonStrings('delete')}
                onClick={() =>
                  openDeleteDialog({
                    entities: [
                      {
                        header: organizationInvitationTableStrings('invitation'),
                        name: params.row.invitee,
                        uuid: params.row.uuid,
                      },
                    ],
                  })
                }
              />,
            ]
          : [
              <GridActionsCellItem
                key={`${params.row.uuid}_edit`}
                icon={<EditRounded />}
                label={commonStrings('edit')}
                onClick={() =>
                  openOrganizationInvitationEditDialog({
                    userId: params.row.uuid,
                    title: commonStrings('editingDialogTitle', { name: params.row.name || '' }),
                  })
                }
              />,
              <GridActionsCellItem
                key={`${params.row.uuid}_delete`}
                icon={<DeleteRounded />}
                label={commonStrings('delete')}
                onClick={() =>
                  openDeleteDialog({
                    entities: [
                      {
                        header: organizationInvitationTableStrings('invitation'),
                        name: params.row.invitee,
                        uuid: params.row.uuid,
                      },
                    ],
                  })
                }
              />,
            ],
    },
  ];

  return (
    <>
      <Toolbar
        disableGutters
        sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}
        variant="dense"
      >
        <Typography
          lineHeight={3.43}
          variant="subtitle2"
        >
          {commonStrings('invitations')}
        </Typography>
        <Button
          onClick={() =>
            openOrganizationInvitationEditDialog({
              title: organizationInvitationTableStrings('inviteUserToOrganization'),
              defaultValues: isCBManager ? { roles: 'auditor' } : {},
            })
          }
          startIcon={<AddRounded />}
          variant="contained"
        >
          {organizationInvitationTableStrings('addUserToOrganization')}
        </Button>
      </Toolbar>
      <Box
        height={408}
        marginBottom={2}
      >
        <MobileCompatibleDataGrid
          columns={columns}
          loading={isLoading}
          NoRowsOverlay={() =>
            CustomNoRowsOverlay(
              organizationInvitationTableStrings('organizationInvitationTableNoRowsMsg'),
            )
          }
          rows={organizationInvitations || []}
          sortModel={[{ field: 'name', sort: 'asc' }]}
        />
      </Box>
      <OrganizationInvitationEditDialog
        isOpen={isOrganizationInvitationEditDialogOpen}
        onClose={closeOrganizationInvitationEditDialog}
        {...organizationInvitationEditDialogProps}
      />
      <GenericDeleteDialog
        deleteMutation={useDeleteOrganizationInviterInvitationMutation}
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        {...deleteDialogProps}
      />
    </>
  );
};
