import { type CertificationBodyListOutputItem } from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { TagType } from '../TagType';

const RESOURCE = 'certification-bodies';

const certificationBodiesEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCertificationBodiesList: build.query<CertificationBodyListOutputItem[], string>({
      query: (tid) => ({
        url: `/tid/${tid}/${RESOURCE}/`,
        method: 'GET',
      }),
      providesTags: [TagType.CertificationBodies],
    }),
  }),
  overrideExisting: false,
});

export const { useGetCertificationBodiesListQuery } = certificationBodiesEndpoints;
