import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { type HelpDocumentFileList, type SimpleFile } from 'src/__generated__/InternalApiTypes';
import { extractErrorMessage } from 'src/services/farmApi';
import { DATE_DISPLAY_SERVER_FORMAT, FILE_TYPE_PDF } from 'src/settings';

export const getFileExtension = (file?: string, defaultFileType = FILE_TYPE_PDF) => {
  let reportUrlContainingFileName;

  if (file) {
    reportUrlContainingFileName = file;
  } else {
    return defaultFileType.toLowerCase();
  }

  const fileStringBeforeQuery = reportUrlContainingFileName.split('?').shift();

  if (fileStringBeforeQuery) {
    const fileStringAfterSubDirectory = fileStringBeforeQuery.split('/').pop();
    if (fileStringAfterSubDirectory) {
      const lastDotIndex = fileStringAfterSubDirectory.lastIndexOf('.');
      if (
        lastDotIndex === -1 ||
        lastDotIndex === 0 ||
        lastDotIndex === fileStringAfterSubDirectory.length - 1
      ) {
        return defaultFileType.toLowerCase(); // No extension
      }
      const extension = fileStringAfterSubDirectory.substring(lastDotIndex + 1);
      if (
        extension.length < 2 ||
        extension.length > 4 ||
        extension.includes(' ') ||
        extension.indexOf('.') !== -1
      ) {
        return defaultFileType.toLowerCase(); // No extension
      }
      const splitFileString = fileStringAfterSubDirectory.split('.');
      if (splitFileString.length <= 1) {
        return defaultFileType.toLowerCase();
      }
      return splitFileString.pop()?.toLowerCase() || defaultFileType;
    }
    return defaultFileType.toLowerCase();
  }

  return defaultFileType.toLowerCase();
};

export const getFileSourceFromUrl = async (
  file: SimpleFile | HelpDocumentFileList,
  fileName: string,
): Promise<[File, string]> => {
  const documentFileSource = file.file_object;
  const response = await fetch(documentFileSource, {
    cache: 'no-cache',
  });
  const blobSource = await response.blob();
  const newDocumentTitle = `${fileName} (${format(new Date(), DATE_DISPLAY_SERVER_FORMAT)})`;
  const newDocumentTitleWithExtension = `${newDocumentTitle}.${getFileExtension(documentFileSource)}`;
  const fileSource = new File([blobSource], newDocumentTitleWithExtension);
  return [fileSource, newDocumentTitle];
};

export const downloadFileToLocal = async (
  file: SimpleFile | HelpDocumentFileList,
  fileName: string,
  onError: (msg: string) => void,
): Promise<void> => {
  const fileSource = file?.file_object;
  const fileType = getFileExtension(fileSource);
  const hasFileNameExtension = getFileExtension(fileName, '') !== '';

  if (fileSource) {
    try {
      const response = await fetch(fileSource, { cache: 'no-cache' });
      if (response) {
        const newFileSource = await response.blob();
        if (newFileSource) {
          saveAs(newFileSource, hasFileNameExtension ? fileName : `${fileName}.${fileType}`);
        }
      }
    } catch (error) {
      const message = extractErrorMessage(error);
      onError(message);
    }
  }
};
