import {
  type PreliminaryQuestionListOutputItem as ListOutputItem,
  type PreliminaryQuestionsListParams as ListParams,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { injectQueryParams } from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'preliminary-questions';

const preliminaryQuestionEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPreliminaryQuestionList: build.query<ListOutputItem[], ListParams>({
      query: (params) => injectQueryParams(`/${RESOURCE}/`, params),
      providesTags: [TagType.PreliminaryQuestion, TagType.Assessment, TagType.UserProfile],
    }),
  }),
  overrideExisting: false,
});

export const { useGetPreliminaryQuestionListQuery } = preliminaryQuestionEndpoints;
