import { Box, type SxProps, useMediaQuery } from '@mui/material';
import { type ReactNode, type UIEventHandler, useContext } from 'react';

import { ThemeContext } from '../ThemeProvider';
import { wrapperStyle } from './AssessmentConfigMatrix.styles';

interface AssessmentConfigMatrixWrapperProps {
  children: ReactNode;
  onScroll: UIEventHandler<HTMLDivElement> | undefined;
  sx: SxProps;
}

export const AssessmentConfigMatrixWrapper = ({
  children,
  onScroll,
  sx,
}: AssessmentConfigMatrixWrapperProps): React.JSX.Element => {
  const { theme } = useContext(ThemeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box sx={{ ...(!isMobile && { ...wrapperStyle }), ...sx }}>
      <Box
        maxWidth="100%"
        onScroll={onScroll}
      >
        {children}
      </Box>
    </Box>
  );
};
