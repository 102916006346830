import { type AssessmentUserPermissionsOutput } from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { type DetailParamsWithTenantId } from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'assessment-user-permissions';

const assessmentUserPermissionEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAssessmentUserPermissions: build.query<
      AssessmentUserPermissionsOutput,
      DetailParamsWithTenantId
    >({
      query: ({ tid, id }) => `/tid/${tid}/${RESOURCE}/${id}/`,
      providesTags: [TagType.AssessmentUserPermission],
    }),
  }),
  overrideExisting: false,
});

export const { useGetAssessmentUserPermissionsQuery } = assessmentUserPermissionEndpoints;
