import { CloseRounded } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { CopyToClipboardText } from 'src/components/common/CopyToClipboardText';
import { useAppDispatch, useRoleCheck } from 'src/hooks';
import { assessmentShareDialogStrings, commonStrings } from 'src/languages/en-UK';
import { openSnackbar } from 'src/store/snackbarSlice';

export interface AssessmentShareDialogProps {
  onClose: () => void;
  isOpen: boolean;
  urlToShare: string;
}

export const AssessmentShareDialog = ({
  onClose,
  urlToShare,
  isOpen = false,
}: AssessmentShareDialogProps): React.JSX.Element => {
  const dispatch = useAppDispatch();
  const [hasCopiedUrl, setHasCopiedUrl] = useState(false);
  const { isFarm } = useRoleCheck();

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(urlToShare);
    setHasCopiedUrl(true);
    dispatch(
      openSnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        autoHideDuration: 2000,
        message: commonStrings('linkWasCopied'),
        severity: 'success',
      }),
    );
  };

  const handleClose = () => {
    setHasCopiedUrl(false);
    onClose();
  };

  return (
    <Dialog
      fullWidth
      onClose={handleClose}
      open={isOpen}
    >
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          padding: 1.5,
        }}
      >
        <CloseRounded />
      </IconButton>
      <DialogTitle>
        {isFarm
          ? assessmentShareDialogStrings('assessmentSharing')
          : assessmentShareDialogStrings('inspectionSharing')}
      </DialogTitle>
      <DialogContent>
        <Typography marginBottom={2}>
          {isFarm
            ? assessmentShareDialogStrings('shareAssessmentToColleagues')
            : commonStrings('shareInspectionToColleagues')}
        </Typography>
        <CopyToClipboardText
          isCopied={hasCopiedUrl}
          urlToShare={urlToShare}
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
          mt: 3,
        }}
      >
        <Button
          onClick={handleClose}
          variant="outlined"
        >
          {commonStrings('close')}
        </Button>
        <Button
          onClick={handleCopyUrl}
          variant="contained"
        >
          {commonStrings('copyLink')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
