import { Paper } from '@mui/material';
import { useTenantId } from 'src/hooks';
import {
  type TransformedAssessmentControlPointNonConformityOutputItem,
  useGetNonConformityAndCorrectiveActionListQuery,
} from 'src/services/farmApi';
import { appColors } from 'src/theme';

import { NonConformity } from '../ControlPointNonConformities/NonConformity';
import {
  CorrectiveAction,
  NewCorrectiveActionForm,
} from '../ControlPointNonConformityCorrectiveAction';

interface NonConformityAndCorrectiveActionsProps {
  controlPointId: string;
  isDisable: boolean;
  isNewCorrectiveActionFormVisible: boolean;
  nonConformity: TransformedAssessmentControlPointNonConformityOutputItem;
  setIsNewCorrectiveActionFormVisible: React.Dispatch<React.SetStateAction<boolean>>;
  shouldOpenCorrectiveActionSection: boolean;
}

export const NonConformityAndCorrectiveActionsView = ({
  controlPointId,
  nonConformity,
  setIsNewCorrectiveActionFormVisible,
  isDisable = false,
  isNewCorrectiveActionFormVisible = false,
  shouldOpenCorrectiveActionSection = false,
}: NonConformityAndCorrectiveActionsProps): React.JSX.Element => {
  const tid = useTenantId();
  const { data: nonConformityAndCorrectiveActionList } =
    useGetNonConformityAndCorrectiveActionListQuery({
      tid,
      shortcoming_id: [nonConformity.uuid],
    });
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          borderWidth: 1,
          borderRadius: 0.5,
          border: 1,
          px: 2,
          py: 2,
          borderColor: appColors.border,
        }}
      >
        <NonConformity
          isDisable={isDisable}
          nonConformity={nonConformity}
          setIsNewCorrectiveActionFormVisible={setIsNewCorrectiveActionFormVisible}
        />
        <CorrectiveAction
          correctiveActionList={nonConformityAndCorrectiveActionList}
          isDisable={isDisable}
          setIsNewCorrectiveActionFormVisible={setIsNewCorrectiveActionFormVisible}
        />
      </Paper>
      {isNewCorrectiveActionFormVisible && nonConformityAndCorrectiveActionList?.length <= 0 && (
        <NewCorrectiveActionForm
          controlPointId={controlPointId}
          nonConformityId={nonConformity.uuid}
          onSuccessCreateCorrectiveAction={() => {
            if (shouldOpenCorrectiveActionSection) {
              setIsNewCorrectiveActionFormVisible(true);
            }
          }}
        />
      )}
    </>
  );
};
