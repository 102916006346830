import { CloseRounded } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  Stack,
} from '@mui/material';
import { type UseMutation, type UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { type FormikValues } from 'formik';
import { useEffect } from 'react';
import { type DynamicObjectType } from 'src/global';
import { useAppDispatch, useMutationFeedback, useTenantId } from 'src/hooks';
import { commonStrings, formikDialogStrings } from 'src/languages/en-UK';
import { openSnackbar } from 'src/store/snackbarSlice';
import * as Yup from 'yup';

import { useForcedValueFormik } from './FormikDialog.hooks';
import {
  type FormikDialogFieldConfig,
  type InitialValues,
  type Validators,
} from './FormikDialog.types';

interface FormikDialogProps {
  // Since it is dynamic, it must be any type.
  createHook: UseMutation<any>;
  entityId: string;
  // Since it is dynamic, it must be any type.
  fetchHook: UseQuery<any>;
  fields: FormikDialogFieldConfig[];
  isOpen: boolean;
  onClose: () => void;
  propertyName: string;
  title: string;
  // Since it is dynamic, it must be any type.
  updateHook: UseMutation<any>;
  additionalReqParams?: Object;
  // Since it is dynamic, it must be any type.
  forcedValues?: { [key: string]: any };
  getBodyMap?: (values: FormikValues) => FormikValues;
  getFormMap?: (values: FormikValues) => FormikValues;
  // Since it is dynamic, it must be any type.
  getPropertyName?: (result: any) => string;
  hasCreatePermission?: boolean;
  hasUpdatePermission?: boolean;
  hideCreateSuccessMsg?: boolean;

  /** Handler which runs when an creation mutation is successful */
  // Since it is dynamic, it must be any type.
  onCreateSuccess?: (mutationResult?: any) => void;

  /** Handler which runs when a creation or update mutation fails with an error */
  onError?: (error?: string) => void;

  /** Handler which runs when a creation or update mutation is successful */
  // Since it is dynamic, it must be any type.
  onSuccess?: (mutationResult?: any) => void;

  /** Handler which runs when a creation mutation is successful */
  // Since it is dynamic, it must be any type.
  onUpdateSuccess?: (mutationResult?: any) => void;
  submitButtonText?: string;
}

export const FormikDialog = ({
  additionalReqParams,
  createHook,
  entityId: id,
  fetchHook,
  fields,
  getBodyMap,
  getFormMap,
  getPropertyName,
  onClose,
  onCreateSuccess,
  onError,
  onSuccess,
  onUpdateSuccess,
  propertyName,
  submitButtonText,
  title,
  updateHook,
  forcedValues = {},
  hasCreatePermission = true,
  hasUpdatePermission = true,
  hideCreateSuccessMsg = false,
  isOpen = false,
}: FormikDialogProps): React.JSX.Element => {
  const tid = useTenantId();
  const dispatch = useAppDispatch();

  // RTK Query hooks for fetching, updating, and creating an entity
  const [update, updateResult] = updateHook();
  const [create, createResult] = createHook();
  const { data, isFetching } = fetchHook(
    { id, tid, ...additionalReqParams },
    {
      skip: !id || !isOpen,
    },
  );
  const initialValues = fields.reduce((acc: InitialValues, field) => {
    if (!field.hidden) {
      const isArrayField = field.validator && field.validator.type === 'array';
      // Since it is dynamic, it must be any type.
      const defaultValue: any[] | string = isArrayField ? [] : '';
      acc[field.id] = field?.defaultValue || defaultValue;
    }
    return acc;
  }, {});

  const validators = fields.reduce((acc: Validators, field) => {
    if (field.validator && !field.hidden) {
      acc[field.id] = field.validator;
    }
    return acc;
  }, {});

  const formik = useForcedValueFormik({
    initialValues,
    forcedValues,
    validationSchema: Yup.object().shape(validators),
    onSubmit: (values: FormikValues) => {
      const body = getBodyMap ? getBodyMap(values) : values;

      // If there is an ID, we are updating, if not, we must be creating a fresh entity
      if (id) {
        if (hasUpdatePermission) {
          update({ id, body, tid, ...additionalReqParams });
        } else {
          dispatch(
            openSnackbar({
              message: commonStrings('notAuthorized'),
              severity: 'error',
            }),
          );
        }
      } else if (hasCreatePermission) {
        create({ body, tid, ...additionalReqParams });
      } else {
        dispatch(
          openSnackbar({
            message: commonStrings('notAuthorized'),
            severity: 'error',
          }),
        );
      }
    },
  });

  /**
   * If there is a change in the dialog's open state, which ID is being edited, or updated data from
   * the back-end, propagate those changes into the Formik instance.
   */
  useEffect(() => {
    const defaultValuesList = fields.filter(
      (field: FormikDialogFieldConfig) => field?.defaultValue,
    );
    if (isOpen && id && data) {
      // Create a base set of values from the initial values
      let updatedValues = { ...initialValues };

      // For each field, if there is a new value for a given field/ID, overwrite the initial value
      fields.forEach((field) => {
        // If the field uses 'getValue', use that, otherwise just pull the value out of the data
        const value = field.getValue ? field.getValue(data) : (data as DynamicObjectType)[field.id];

        // If there is an updated value and the field is not hidden, update Formik
        if (value && !field.hidden && !isFetching) {
          updatedValues[field.id] = value;
        }
      });
      if (getFormMap) {
        updatedValues = getFormMap(updatedValues);
      }

      // Update the form with all the new values
      formik.setValues(updatedValues);
    } else if (defaultValuesList?.length > 0) {
      let updatedValues = { ...initialValues };
      defaultValuesList.forEach((val) => {
        updatedValues = {
          ...updatedValues,
          [val.id]: val.defaultValue,
        };
      });
      // If the dialog is closed/has no ID/has no external data, reset the form (to initial values)
      formik.setValues(updatedValues);
    } else {
      // If the dialog is closed/has no ID/has no external data, reset the form (to initial values)
      formik.resetForm();
    }

    // TODOHasan: Do not add formik and initialValues as a dependency. Find a correct way.
  }, [data, fields, getFormMap, id, isFetching, isOpen]);

  /**
   * If the app wants to update a certain field (for example, with some programmatically set value),
   * update Formik with that value.
   */
  useEffect(() => {
    fields.forEach((field) => {
      if (field.value && !field.hidden) {
        formik.setFieldValue(field.id, field.value);
      }
    });
  }, [fields, formik]);

  /**
   * Handler for when the dialog is closed. Optionally runs onSuccess if it was provided.
   */
  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  /**
   * Get the current entity name. Contains a type guard because there may not be a name property or
   * field on the entity. In that case, "Unknown" is used as a name. The name as a whole is used for
   * feedback snack-bars.
   * @returns - The entity name
   */
  const getName = (): string => {
    const hasName = (d: DynamicObjectType) => typeof d[propertyName] === 'string';
    if (hasName(formik.values)) {
      return formik.values[propertyName];
    }
    return commonStrings('unknown');
  };

  useMutationFeedback({
    onError,
    result: updateResult,
    onSuccess: (mutationResult) => {
      if (onUpdateSuccess) {
        onUpdateSuccess(mutationResult);
      }
      if (onSuccess) {
        onSuccess(mutationResult);
      }
      handleClose();
    },
    successMessage: formikDialogStrings('updateFormSuccess', {
      name: getPropertyName ? getPropertyName(updateResult?.data) : getName(),
    }),
    errorMessage: formikDialogStrings('updateFormFail', {
      name: getPropertyName ? getPropertyName(updateResult?.data) : getName(),
    }),
  });

  useMutationFeedback({
    onError,
    result: createResult,
    onSuccess: (mutationResult) => {
      if (onCreateSuccess) {
        onCreateSuccess(mutationResult);
      }
      if (onSuccess) {
        onSuccess(mutationResult);
      }
      handleClose();
    },
    successMessage: !hideCreateSuccessMsg
      ? commonStrings('backendCreateSuccessMessage', {
          name: getPropertyName ? getPropertyName(createResult?.data) : getName(),
        })
      : '',
    errorMessage: commonStrings('backendCreateFailMessage', {
      name: getPropertyName ? getPropertyName(createResult?.data) : getName(),
    }),
  });

  const isMutationLoading = createResult.isLoading || updateResult.isLoading;

  const getDialogTitle = () => {
    if (isFetching) {
      return (
        <Skeleton
          variant="text"
          width="33%"
        />
      );
    }

    return isOpen ? title : '';
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={isOpen}
    >
      <form
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            padding: 1.5,
          }}
        >
          <CloseRounded />
        </IconButton>
        <DialogTitle>{getDialogTitle()}</DialogTitle>
        <DialogContent>
          <Stack
            marginTop={1}
            spacing={2}
          >
            {fields.map((field) => {
              // If field is hidden, or a forced value has been set for that field, don't render it
              if (field.hidden || forcedValues[field.id] !== undefined) {
                return null;
              }
              return field.renderAs({
                formik,
                disabled: isMutationLoading,
                id: field.id,
                key: field.id,
                loading: isFetching.toString(),
              });
            })}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ mt: 5 }}>
          {isOpen && (
            <Box>
              <LoadingButton
                disabled={Object.keys(formik.errors)?.length > 0}
                loading={isMutationLoading}
                type="submit"
                variant="contained"
              >
                {submitButtonText ||
                  (id ? commonStrings('saveChanges') : formikDialogStrings('create'))}
              </LoadingButton>
            </Box>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};
