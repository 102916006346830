import { Box } from '@mui/material';
import { useFormik } from 'formik';
import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { RolesEnum } from 'src/__generated__/InternalApiTypes';
import { type StandardClientIdentifier } from 'src/components/AssessmentConfigClientIdentifiers';
import { FormikSelectField } from 'src/components/formikcomponents/FormikSelectField';
import { FormikTextField } from 'src/components/formikcomponents/FormikTextField';
import { useCurrentUserRoles, useMutationFeedback, useTenantId } from 'src/hooks';
import { commonStrings } from 'src/languages/en-UK';
import {
  useCreateOrganizationClientIdentifierMutation,
  useGetOrganizationClientIdentifiersListQuery,
  useMoveStandardOrganizationClientIdentifierMutation,
  useUpdateOrganizationClientIdentifierMutation,
} from 'src/services/farmApi/endpoints/orgnizationClientIdentifiers';
import { type LabelValuePair } from 'src/types/LabelValuePair';
import { populateFormValues } from 'src/utils/populateFormValues';
import * as Yup from 'yup';

interface AssessmentConfigClientIdentifierFormProps {
  certificationBodyOptions: LabelValuePair[];
  isDisabled: boolean;
  setIsDisabled: (value: boolean) => void;
  standardClientIdentifier: StandardClientIdentifier;
}

export const AssessmentConfigClientIdentifierForm = ({
  certificationBodyOptions,
  setIsDisabled,
  standardClientIdentifier,
  isDisabled = false,
}: AssessmentConfigClientIdentifierFormProps): React.JSX.Element => {
  const tid = useTenantId();
  const currentUserRoles = useCurrentUserRoles();
  const isFarmEmployee = currentUserRoles.includes(RolesEnum.FarmEmployee);

  const [createClientIdentifier, createClientIdentifierResult] =
    useCreateOrganizationClientIdentifierMutation();
  const [updateClientIdentifier, updateClientIdentifierResult] =
    useUpdateOrganizationClientIdentifierMutation();
  const [moveStandardToNewClientIdentifier, moveStandardToNewClientIdentifierResult] =
    useMoveStandardOrganizationClientIdentifierMutation();
  const { data: organizationClientIdentifiers, isLoading: isLoadingOrganizationClientIdentifiers } =
    useGetOrganizationClientIdentifiersListQuery(tid, { skip: !tid });

  const defaultValues = {
    certification_body_id: '',
    value: '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: populateFormValues(
      defaultValues,
      standardClientIdentifier?.certificationBody
        ? {
            certification_body_id: standardClientIdentifier.certificationBody.uuid,
            value: standardClientIdentifier.value,
          }
        : defaultValues,
    ),
    validationSchema: Yup.object().shape({
      certification_body_id: Yup.string().required(commonStrings('required')),
      value: Yup.string().when('certification_body_id', {
        is: (certificationBodyId: string) =>
          !organizationClientIdentifiers?.some(
            (clientIdentifier) =>
              clientIdentifier?.certification_body?.uuid === certificationBodyId,
          ) || standardClientIdentifier.value,
        then: Yup.string().required(commonStrings('required')),
      }),
    }),
    onSubmit: undefined,
  });

  useEffect(() => {
    setIsDisabled(
      createClientIdentifierResult.isLoading ||
        updateClientIdentifierResult.isLoading ||
        moveStandardToNewClientIdentifierResult.isLoading ||
        isLoadingOrganizationClientIdentifiers ||
        isFarmEmployee,
    );
  }, [
    setIsDisabled,
    createClientIdentifierResult.isLoading,
    updateClientIdentifierResult.isLoading,
    moveStandardToNewClientIdentifierResult.isLoading,
    isLoadingOrganizationClientIdentifiers,
    isFarmEmployee,
  ]);

  useMutationFeedback({
    result: updateClientIdentifierResult,
    successMessage: commonStrings('backendSaveSuccessMessage', {
      name: `${commonStrings('clientId')} ${formik.values.value}`,
    }),
    errorMessage: commonStrings('backendSaveFailMessage', {
      name: `${commonStrings('clientId')} ${standardClientIdentifier?.value}`,
    }),
  });

  useMutationFeedback({
    result: createClientIdentifierResult,
    successMessage: commonStrings('backendSaveSuccessMessage', {
      name: `${commonStrings('clientId')} ${formik.values.value}`,
    }),
    errorMessage: commonStrings('backendSaveFailMessage', {
      name: `${commonStrings('clientId')} ${standardClientIdentifier?.value}`,
    }),
  });

  useMutationFeedback({
    result: moveStandardToNewClientIdentifierResult,
    successMessage: commonStrings('backendSaveSuccessMessage', {
      name: `${commonStrings('clientId')} ${formik.values.value}`,
    }),
    errorMessage: commonStrings('backendSaveFailMessage', {
      name: `${commonStrings('clientId')} ${standardClientIdentifier?.value}`,
    }),
  });

  const handleChangeClientIdentifier = () => {
    if (
      isEqual(formik.values, formik.initialValues) ||
      (formik.errors && Object.keys(formik.errors).length > 0)
    ) {
      return;
    }

    const existingOrganizationClientIdentifier = organizationClientIdentifiers?.find(
      (clientIdentifier) =>
        clientIdentifier?.certification_body?.uuid === formik.values.certification_body_id,
    );

    if (
      formik.initialValues.certification_body_id &&
      formik.values.certification_body_id !== standardClientIdentifier.certificationBody?.uuid
    ) {
      if (
        existingOrganizationClientIdentifier?.standards?.some(
          (standard) => standard.uuid === standardClientIdentifier.standardId,
        )
      ) {
        return;
      }

      if (tid && standardClientIdentifier?.clientIdentifierId) {
        moveStandardToNewClientIdentifier({
          tid,
          id: standardClientIdentifier.clientIdentifierId,
          body: {
            new_certification_body_id: formik.values.certification_body_id,
            standard_id: standardClientIdentifier.standardId,
            value: formik.values.value,
          },
        });
      }
    } else if (tid && existingOrganizationClientIdentifier?.uuid) {
      updateClientIdentifier({
        tid,
        id: existingOrganizationClientIdentifier.uuid,
        body: {
          value: formik.values.value || undefined,
          standard_ids: [
            ...existingOrganizationClientIdentifier.standards.map((standard) => standard.uuid),
            standardClientIdentifier.standardId,
          ],
        },
      });
    } else if (tid) {
      createClientIdentifier({
        tid,
        body: {
          certification_body_id: formik.values.certification_body_id,
          value: formik.values.value,
          standard_ids: [standardClientIdentifier.standardId],
        },
      });
    }
  };

  return (
    <Box
      display="grid"
      gap={1.5}
      gridAutoColumns="1fr 8fr 8fr"
      gridAutoFlow={{ xs: 'row', sm: 'column' }}
      marginBottom={3}
    >
      {standardClientIdentifier.logo && (
        <img
          alt=""
          src={standardClientIdentifier.logo}
          style={{
            marginRight: '4px',
            height: '40px',
            width: '40px',
            justifySelf: 'center',
            alignSelf: 'center',
          }}
        />
      )}
      <FormikSelectField
        disabled={isDisabled}
        formik={formik}
        id="certification_body_id"
        label={commonStrings('certificationCompany')}
        onBlur={(e) => {
          formik.handleBlur(e);
          handleChangeClientIdentifier();
        }}
        options={certificationBodyOptions}
        size="small"
      />
      <FormikTextField
        disabled={isDisabled || !formik.values.certification_body_id}
        formik={formik}
        id="value"
        label={commonStrings('clientId')}
        onBlur={(e) => {
          formik.handleBlur(e);
          handleChangeClientIdentifier();
        }}
        size="small"
      />
    </Box>
  );
};
