import { type FormikValues } from 'formik';
import { useMemo } from 'react';
import {
  type AssessmentOrganizationPlotCultivationCreateOutput,
  type AssessmentOrganizationPlotCultivationUpdateOutput,
} from 'src/__generated__/InternalApiTypes';
import { STANDARD_SELECTION_GLOBAL } from 'src/components/AssessmentConfigMatrix/AssessmentConfigMatrix.utils';
import { FormikDialog } from 'src/components/formikcomponents/FormikDialog';
import { FormikProductField } from 'src/components/formikcomponents/FormikProductField';
import { FormikProductionTechniqueField } from 'src/components/formikcomponents/FormikProductionTechniqueField';
import { FormikTextField } from 'src/components/formikcomponents/FormikTextField';
import { useAppDispatch, useAppSelector, useTenantId } from 'src/hooks';
import { type SelectionListChangeHandler } from 'src/hooks/useSelectionListState';
import { commonStrings } from 'src/languages/en-UK';
import {
  useCreateAssessmentPlotCultivationMutation,
  useGetAssessmentPlotCultivationQuery,
  useGetModuleListQuery,
  useGetSubscriptionListQuery,
  useUpdateAssessmentPlotCultivationMutation,
} from 'src/services/farmApi';
import { assessmentConfigActions } from 'src/store/assessmentConfig';
import { getCultivationDisplayName } from 'src/utils/getCultivationDisplayName';
import * as Yup from 'yup';

export interface AssessmentCultivationEditDialogProps {
  assessmentId: string;
  clientIdentifierIds: string[];
  isOpen: boolean;
  onChangeSelectedCultivationIds: SelectionListChangeHandler;
  onClose: () => void;
  title: string;
  cultivationId?: string;
}

export const AssessmentCultivationEditDialog = ({
  assessmentId,
  clientIdentifierIds,
  cultivationId,
  isOpen,
  onChangeSelectedCultivationIds,
  onClose,
  title,
}: AssessmentCultivationEditDialogProps): React.JSX.Element => {
  const tid = useTenantId();
  const defaultTitle = commonStrings('createOrEditCultivation');
  const dispatch = useAppDispatch();
  const { selections } = useAppSelector((state) => state.assessmentConfig);

  // Get the module list for filtering products
  const { data: moduleList } = useGetModuleListQuery(
    {
      has_parent: false,
    },
    { skip: !tid || !assessmentId },
  );
  const { data: subscriptions } = useGetSubscriptionListQuery(
    { tid, status: ['active'] },
    { skip: !tid || !assessmentId },
  );

  const paidModuleList = useMemo(
    () =>
      moduleList?.filter((standard) =>
        subscriptions?.some((sub) =>
          sub.subscription_plan_standard_ids.includes(standard.standard_id),
        ),
      ),
    [moduleList, subscriptions],
  );

  const moduleListStandardIds = useMemo(
    () => paidModuleList?.map((module) => module.standard_id),
    [paidModuleList],
  );

  const getFormikBodyMap = (values: FormikValues) => ({
    ...values,
    organization_plot_area: values.organization_plot_area
      ? parseFloat(values.organization_plot_area.toString().replace(',', '.'))
      : 0,
  });

  const getFormikPropertyName = (result: AssessmentOrganizationPlotCultivationUpdateOutput) => {
    if (result) {
      return getCultivationDisplayName(result);
    }
    return '';
  };

  const handleCreateResult = (result: AssessmentOrganizationPlotCultivationCreateOutput) => {
    if (result?.uuid && onChangeSelectedCultivationIds) {
      onChangeSelectedCultivationIds([result.uuid], true);
      const existingSelection = selections.find((s) => s.rowId === result?.uuid);
      dispatch(
        assessmentConfigActions.changeCultivationSelection({
          selection: {
            colId: existingSelection?.colId || STANDARD_SELECTION_GLOBAL,
            rowId: result?.uuid,
            malusPoint: existingSelection?.malusPoint,
          },
          isChecked: true,
        }),
      );
    }
  };

  return (
    <FormikDialog
      additionalReqParams={{ assessmentId }}
      createHook={useCreateAssessmentPlotCultivationMutation}
      entityId={cultivationId || ''}
      fetchHook={useGetAssessmentPlotCultivationQuery}
      fields={[
        {
          id: 'product_id',
          getValue: (selectedItem) => selectedItem?.product?.uuid,
          renderAs: (props) => (
            <FormikProductField
              {...props}
              required
              standardIds={moduleListStandardIds}
            />
          ),
          validator: Yup.string().uuid().required(commonStrings('required')),
        },
        {
          id: 'production_technique_id',
          getValue: (selectedItem) => selectedItem?.production_technique?.uuid,
          renderAs: (props) => (
            <FormikProductionTechniqueField
              {...props}
              label={commonStrings('cultivationType')}
              required
            />
          ),
          validator: Yup.string().required(commonStrings('required')),
        },
        {
          id: 'organization_plot_name',
          getValue: (selectedItem) => selectedItem?.organization_plot?.name,
          renderAs: (props) => (
            <FormikTextField
              {...props}
              label={commonStrings('plotLabel')}
              required
            />
          ),
          validator: Yup.string().required(commonStrings('required')),
        },
        {
          id: 'organization_plot_area',
          getValue: (selectedItem) => selectedItem?.organization_plot?.area,
          renderAs: (props) => (
            <FormikTextField
              {...props}
              label={commonStrings('plotSurfaceArea')}
              onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
              type="number"
            />
          ),
        },
      ]}
      forcedValues={{
        ...(clientIdentifierIds?.length > 0
          ? {
              client_identifiers: clientIdentifierIds,
            }
          : {}),
      }}
      getBodyMap={getFormikBodyMap}
      getPropertyName={getFormikPropertyName}
      isOpen={isOpen}
      onClose={onClose}
      onCreateSuccess={handleCreateResult}
      onError={onClose}
      propertyName="product_id"
      submitButtonText={commonStrings('save')}
      title={title || defaultTitle}
      updateHook={useUpdateAssessmentPlotCultivationMutation}
    />
  );
};
