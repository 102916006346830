import { Container, Stack } from '@mui/material';
import { useState } from 'react';
import { type AlertData } from 'src/components/common/types/AlertData';
import { FormFeedbackWrapper } from 'src/components/formikcomponents/FormFeedbackWrapper';
import { SignUpForm } from 'src/components/forms/SignUpForm';

export const SignUpPage = (): React.JSX.Element => {
  const [feedbackAlert, setFeedbackAlert] = useState<AlertData | undefined>(undefined);

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack spacing={2}>
        <FormFeedbackWrapper alert={feedbackAlert}>
          <SignUpForm setAlert={setFeedbackAlert} />
        </FormFeedbackWrapper>
      </Stack>
    </Container>
  );
};
