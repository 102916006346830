import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { useTenantId } from 'src/hooks';
import { commonStrings } from 'src/languages/en-UK';
import {
  type TransformedAssessmentControlPointCommentOutputItem,
  useGetAssessmentControlPointQuery,
} from 'src/services/farmApi';

import { Comment } from './Comment';
import { NewCommentForm } from './NewCommentForm';

interface ControlPointCommentsProps {
  assessmentControlPointId: string;
  controlPointId: string;
  isVisibleCommentSection: boolean;
}

export const ControlPointComments = ({
  assessmentControlPointId,
  controlPointId,
  isVisibleCommentSection = false,
}: ControlPointCommentsProps) => {
  const tid = useTenantId();
  const EMPTY_ARRAY: TransformedAssessmentControlPointCommentOutputItem[] = [];

  const { comments: controlPointCommentList, isLoading: isControlPointCommentListLoading } =
    useGetAssessmentControlPointQuery(
      {
        tid,
        id: assessmentControlPointId,
      },
      {
        selectFromResult: ({ data, isLoading }) => ({
          comments: data?.comments ?? EMPTY_ARRAY,
          isLoading,
        }),
      },
    );

  const sortedControlPointCommentList = controlPointCommentList
    ?.slice()
    ?.sort((a, b) => new Date(a.modified_at).getTime() - new Date(b.modified_at).getTime());

  return isControlPointCommentListLoading ? (
    <>
      <Skeleton
        height={15}
        variant="rounded"
        width={80}
      />
      <Box
        display="flex"
        flexDirection="row"
      >
        <Skeleton
          animation="pulse"
          height={40}
          sx={{ flex: '1 auto' }}
          variant="rounded"
        />
        <Skeleton
          animation="pulse"
          height={40}
          sx={{ ml: 1 }}
          variant="rounded"
          width={83.3}
        />
      </Box>
    </>
  ) : (
    <Stack
      spacing={1}
      sx={{
        '&&&': {
          mt: isVisibleCommentSection || sortedControlPointCommentList?.length > 0 ? '24px' : 0,
        },
      }}
    >
      {(isVisibleCommentSection || sortedControlPointCommentList?.length > 0) && (
        <Typography
          color="textSecondary"
          variant="overline"
        >
          {commonStrings('comments')}
        </Typography>
      )}
      {(sortedControlPointCommentList || []).map((comment) => (
        <Comment
          key={comment.uuid}
          comment={comment}
        />
      ))}
      {isVisibleCommentSection && <NewCommentForm controlPointId={controlPointId} />}
    </Stack>
  );
};
