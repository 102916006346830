import { FlagRounded, MoreVertRounded } from '@mui/icons-material';
import { Box, IconButton, Skeleton, Stack } from '@mui/material';
import { type MouseEvent, useContext, useEffect, useState } from 'react';
import {
  type AssessmentControlPointAnswerListOutputItem,
  type AssessmentControlPointListOutputItem,
  type HelpDocumentsListParams,
  LevelEnum,
  RolesEnum,
} from 'src/__generated__/InternalApiTypes';
import { ControlPointMasterDataContext } from 'src/context/ControlPointMasterData';
import { SectionId } from 'src/enums/SectionId';
import { useCurrentUserRoles, useTenantId } from 'src/hooks';
import { commonStrings, controlPointHeaderStrings } from 'src/languages/en-UK';
import {
  useGetAssessmentControlPointQuery,
  useGetRetailersListQuery,
  useUpdateAssessmentControlPointMutation,
} from 'src/services/farmApi';

import { HeaderChip } from '../common/HeaderChip';
import { MobileCompatibleTooltip } from '../common/MobileCompatibleTooltip';
import { ControlPointAnsweredByTriggerChip } from '../ControlPointAnsweredByTriggerChip';
import { ControlPointBreadCrumbs } from '../ControlPointBreadCrumbs';
import { ControlPointHeaderKebabMenu, type KebabMenuSlug } from '../ControlPointHeaderKebabMenu';

interface ControlPointHeaderProps {
  assessmentControlPoint: AssessmentControlPointListOutputItem;
  assessmentControlPointAnswer: AssessmentControlPointAnswerListOutputItem;
  filterParams: HelpDocumentsListParams;
  hidingMenuList: Array<KebabMenuSlug>;
  isHideBreadcrumbs: boolean;
  isLoading: boolean;
  isShowFlag: boolean;
  onChangeSection: (sectionId: SectionId) => void;
  onClickEvidence: () => void;
  onClickGuidance: () => void;
  onClickHelpDocuments: () => void;
}

export const ControlPointHeader = ({
  assessmentControlPoint,
  assessmentControlPointAnswer,
  filterParams,
  hidingMenuList,
  onClickEvidence: handleClickEvidence,
  onClickGuidance: handleClickGuidance,
  onClickHelpDocuments: handleClickHelpDocuments,
  onChangeSection: handleChangeSection,
  isHideBreadcrumbs = false,
  isLoading = false,
  isShowFlag = true,
}: ControlPointHeaderProps): React.JSX.Element => {
  const tid = useTenantId();
  const {
    criticality_id: criticalityId,
    control_point_id: controlPointId,
    level: controlPointlevel,
    show_control_point_level: showControlPointLevel,
    standard_setter: standardSetter,
    is_evidence_shared_with_standard_setter: isEvidenceSharedWithStandardSetter,
  } = assessmentControlPoint || {};
  const {
    trigger_answer: triggerAnswer,
    trigger_name: triggerName,
    trigger_type: triggerType,
  } = assessmentControlPointAnswer?.trigger_data || {};
  const { is_answer_set_by_evidence: answerSetByEvidence } = assessmentControlPointAnswer || {};

  const { data: masterRetailers } = useGetRetailersListQuery();
  const { controlPointRetailers } = useGetAssessmentControlPointQuery(
    {
      tid,
      id: assessmentControlPoint?.uuid,
    },
    {
      selectFromResult: ({ data }) => ({
        controlPointRetailers: masterRetailers?.filter((retailer) =>
          (data?.retailer_ids ?? []).includes(retailer.uuid),
        ),
      }),
    },
  );

  const [threeDotsMenuAnchor, setThreeDotsMenuAnchor] = useState<null | HTMLElement>(null);
  const [optimisticIsFlagged, setOptimisticIsFlagged] = useState(
    assessmentControlPoint?.is_flagged,
  );
  const openThreeDotsMenu = Boolean(threeDotsMenuAnchor);
  const currentUserRoles = useCurrentUserRoles();
  const isAuditor = currentUserRoles.includes(RolesEnum.Auditor);
  const { controlPointCriticalities } = useContext(ControlPointMasterDataContext);
  const criticality = (controlPointCriticalities || []).find((item) => item.uuid === criticalityId);

  const [updateAssessmentControlPoint, updateAssessmentControlPointResult] =
    useUpdateAssessmentControlPointMutation();

  useEffect(() => {
    if (updateAssessmentControlPointResult.isError) {
      setOptimisticIsFlagged(assessmentControlPoint?.is_flagged);
    }
  }, [
    updateAssessmentControlPointResult.isSuccess,
    updateAssessmentControlPointResult.isError,
    assessmentControlPoint?.is_flagged,
  ]);

  const handleClickMenu = (event: MouseEvent<HTMLElement>) => {
    setThreeDotsMenuAnchor(event.currentTarget);
  };

  const onClickThreeDotsMenuItem = (id: string) => {
    switch (id) {
      case '0':
        handleClickEvidence();
        break;
      case '1':
        handleChangeSection(SectionId.Comments);
        break;
      case '2':
        handleChangeSection(SectionId.NonConformitiesAndCorrectiveActions);
        break;
      case '3':
        handleClickHelpDocuments();
        break;
      case '4':
        handleClickGuidance();
        break;
      default:
        break;
    }
  };

  const handleIsFlaggedToggle = () => {
    if (updateAssessmentControlPointResult.isLoading) {
      return;
    }

    setOptimisticIsFlagged(!assessmentControlPoint?.is_flagged);
    updateAssessmentControlPoint({
      tid,
      id: assessmentControlPoint?.uuid,
      body: {
        is_flagged: !assessmentControlPoint?.is_flagged,
      },
    });
  };

  let headerChipName = '';
  if (controlPointlevel === LevelEnum.Cultivation) {
    headerChipName = controlPointHeaderStrings('cultivationLevel');
  } else if (controlPointlevel === LevelEnum.Farm) {
    headerChipName = controlPointHeaderStrings('farmLevel');
  } else if (controlPointlevel === LevelEnum.Plot) {
    headerChipName = controlPointHeaderStrings('plotLevel');
  }

  const getChipColor = (priority: number) => {
    switch (priority) {
      case 1:
        return 'error';
      case 2:
        return 'warning';
      case 3:
        return 'success';
      default:
        return 'default';
    }
  };

  const hasStandardSetterChip = isEvidenceSharedWithStandardSetter && standardSetter;
  const hasTriggerChip = triggerType || triggerName || triggerAnswer;
  const hasAnyChip =
    criticality?.name ||
    (showControlPointLevel && headerChipName) ||
    hasStandardSetterChip ||
    hasTriggerChip;

  return (
    <>
      {!isHideBreadcrumbs && (
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={1}
        >
          <ControlPointBreadCrumbs
            controlPointId={controlPointId}
            isLoading={isLoading}
          />
          <Stack
            alignItems="center"
            direction="row"
            spacing={0}
          >
            {isLoading ? (
              Array.from({ length: 2 }).map((_item, i) => (
                <Box
                  key={`${i}_skeleton`}
                  padding={0.5}
                >
                  <Skeleton
                    height={32}
                    variant="circular"
                    width={32}
                  />
                </Box>
              ))
            ) : (
              <>
                {isShowFlag && (
                  <MobileCompatibleTooltip
                    title={
                      assessmentControlPoint?.is_flagged
                        ? controlPointHeaderStrings('unflagControlPoint')
                        : controlPointHeaderStrings('flagControlPoint')
                    }
                  >
                    <IconButton
                      aria-label="flag"
                      id="flag-button"
                      onClick={handleIsFlaggedToggle}
                    >
                      <FlagRounded
                        color={
                          optimisticIsFlagged ||
                          (assessmentControlPoint?.is_flagged && optimisticIsFlagged)
                            ? 'error'
                            : 'inherit'
                        }
                      />
                    </IconButton>
                  </MobileCompatibleTooltip>
                )}
                <IconButton
                  aria-controls={openThreeDotsMenu ? 'three-dot-menu' : undefined}
                  aria-expanded={openThreeDotsMenu ? 'true' : undefined}
                  aria-haspopup="true"
                  aria-label="more"
                  id="three-dot-icon"
                  onClick={handleClickMenu}
                >
                  <MoreVertRounded />
                </IconButton>
                {openThreeDotsMenu && (
                  <ControlPointHeaderKebabMenu
                    assessmentControlPointId={assessmentControlPoint?.uuid}
                    controlPointId={controlPointId}
                    filterParams={filterParams}
                    hidingMenuList={hidingMenuList}
                    onClickMenuItem={onClickThreeDotsMenuItem}
                    setThreeDotsMenuAnchor={setThreeDotsMenuAnchor}
                    threeDotsMenuAnchor={threeDotsMenuAnchor}
                  />
                )}
              </>
            )}
          </Stack>
        </Stack>
      )}
      <Stack
        alignItems="center"
        direction="row"
        flexWrap="wrap"
        gap={1}
        sx={{
          '&&&': {
            mt: hasAnyChip ? '8px' : 0,
            mb: hasAnyChip ? '8px' : 0,
          },
        }}
      >
        {criticality?.name && (
          <HeaderChip
            chipColor={getChipColor(criticality?.priority)}
            isLoading={isLoading}
            label={criticality?.name}
            tooltipText={commonStrings('criticality')}
          />
        )}
        {showControlPointLevel && headerChipName && (
          <HeaderChip
            isLoading={isLoading}
            label={headerChipName}
          />
        )}
        {isEvidenceSharedWithStandardSetter && standardSetter && (
          <HeaderChip
            chipColor="info"
            isLoading={isLoading}
            label={
              isAuditor
                ? controlPointHeaderStrings('mandatoryEvidenceSharedWithStandardOwner')
                : controlPointHeaderStrings('mandatoryDocumentSharedWithStandardOwner')
            }
          />
        )}
        {/* Triggered by section */}
        {(triggerType || triggerName || triggerAnswer || answerSetByEvidence) && (
          <ControlPointAnsweredByTriggerChip
            answerSetByEvidence={answerSetByEvidence}
            isLoading={isLoading}
            label={controlPointHeaderStrings('autofilled')}
            triggerAnswer={triggerAnswer}
            triggerName={triggerName}
            triggerType={triggerType}
          />
        )}
        {controlPointRetailers?.map((retailer) => (
          <HeaderChip
            key={retailer.uuid}
            isLoading={isLoading}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  alt={`${retailer.name}`}
                  src={retailer.logo.file_object}
                  style={{
                    height: '18px',
                    width: '40px',
                  }}
                />
              </Box>
            }
          />
        ))}
      </Stack>
    </>
  );
};
