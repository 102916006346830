import { createContext } from 'react';

export type FilterStateType = {
  answer_type: string;
  category_id: string;
  criticality_id: string[];
  evidence_type_id: string[];
  has_comments: string;
  has_evidence: string;
  module_id: string;
  has_shortcomings: string;
  verification_method_id: string[];
  is_flagged: string;
};

type AssessmentControlPointStepFilterContextData = [
  FilterStateType,
  (
    | ((filterId: string, event: { target: { value?: string } }, isMultiSelect?: boolean) => void)
    | null
  ),
  (() => void) | null,
];

export const defaultState: FilterStateType = {
  answer_type: 'ALL',
  category_id: 'ALL',
  criticality_id: ['ALL'],
  evidence_type_id: ['ALL'],
  has_comments: 'ALL',
  has_evidence: 'ALL',
  module_id: 'ALL',
  has_shortcomings: 'ALL',
  verification_method_id: ['ALL'],
  is_flagged: 'ALL',
};

export const ControlPointFilterContext = createContext<AssessmentControlPointStepFilterContextData>(
  [defaultState, null, null],
);

export const ControlPointFilterProvider = ControlPointFilterContext.Provider;
