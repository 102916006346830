import {
  type AssessmentControlPointAnswerBulkUpdateInput,
  type AssessmentControlPointAnswerBulkUpdateOutput,
  type AssessmentControlPointAnswerCreateInput,
  type AssessmentControlPointAnswerCreateOutput,
  type AssessmentControlPointAnswerListOutputItem,
  type AssessmentControlPointAnswersListParams,
  type AssessmentControlPointAnswerUpdateInput,
  type AssessmentControlPointAnswerUpdateOutput,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import {
  type BulkUpdateParamsWithTenantId,
  type CreateParamsWithTenantId,
  type DeleteParamsWithTenantId,
  injectQueryParams,
  type UpdateParamsWithTenantId,
} from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'assessment-control-point-answers';

const assessmentControlPointAnswerEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAssessmentControlPointAnswerList: build.query<
      AssessmentControlPointAnswerListOutputItem[],
      AssessmentControlPointAnswersListParams
    >({
      query: ({ tid, ...restParams }) => injectQueryParams(`/tid/${tid}/${RESOURCE}/`, restParams),
      providesTags: [
        TagType.AssessmentControlPointAnswer,
        TagType.UserProfile,
        TagType.AssessmentControlPointEvidence,
      ],
    }),

    createAssessmentControlPointAnswer: build.mutation<
      AssessmentControlPointAnswerCreateOutput,
      CreateParamsWithTenantId<AssessmentControlPointAnswerCreateInput>
    >({
      query: ({ tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/create/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result: AssessmentControlPointAnswerCreateOutput) => [
        TagType.AssessmentControlPointAnswer,
        TagType.AssessmentControlPointAnswerTypeFilter,
        ...(result.has_associated_visibility_triggers
          ? [TagType.AssessmentControlPointList, TagType.AssessmentControlPoint]
          : []),
      ],
    }),

    updateAssessmentControlPointAnswer: build.mutation<
      AssessmentControlPointAnswerUpdateOutput,
      UpdateParamsWithTenantId<AssessmentControlPointAnswerUpdateInput>
    >({
      query: ({ id, tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/update/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result: AssessmentControlPointAnswerUpdateOutput) => [
        TagType.AssessmentControlPointAnswer,
        TagType.AssessmentControlPointAnswerTypeFilter,
        ...(result.has_associated_visibility_triggers
          ? [TagType.AssessmentControlPointList, TagType.AssessmentControlPoint]
          : []),
      ],
    }),

    deleteAssessmentControlPointAnswer: build.mutation<void, DeleteParamsWithTenantId>({
      query: ({ id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/delete/`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        TagType.AssessmentControlPointAnswer,
        TagType.AssessmentControlPointAnswerTypeFilter,
      ],
    }),

    bulkUpdateAssessmentControlPointAnswer: build.mutation<
      AssessmentControlPointAnswerBulkUpdateOutput[],
      BulkUpdateParamsWithTenantId<AssessmentControlPointAnswerBulkUpdateInput>
    >({
      query: ({ tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/bulk-update/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (results: AssessmentControlPointAnswerBulkUpdateOutput[]) => [
        TagType.AssessmentControlPointAnswer,
        TagType.AssessmentControlPointAnswerTypeFilter,
        ...(results.some((result) => result.has_associated_visibility_triggers)
          ? [TagType.AssessmentControlPointList, TagType.AssessmentControlPoint]
          : []),
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateAssessmentControlPointAnswerMutation,
  useDeleteAssessmentControlPointAnswerMutation,
  useGetAssessmentControlPointAnswerListQuery,
  useUpdateAssessmentControlPointAnswerMutation,
  useBulkUpdateAssessmentControlPointAnswerMutation,
} = assessmentControlPointAnswerEndpoints;
