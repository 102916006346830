import { CloseRounded } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { assessmentExportResultDialogStrings, commonStrings } from 'src/languages/en-UK';

export interface AssessmentExportResultDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AssessmentExportResultDialog = ({
  onClose,
  isOpen = false,
}: AssessmentExportResultDialogProps): React.JSX.Element => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      fullWidth
      onClose={handleClose}
      open={isOpen}
    >
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          padding: 1.5,
        }}
      >
        <CloseRounded />
      </IconButton>
      <DialogTitle>
        {assessmentExportResultDialogStrings('documentsExportIsInProgress')}
      </DialogTitle>
      <DialogContent>
        <Typography
          marginBottom={3}
          variant="body1"
        >
          {assessmentExportResultDialogStrings('youWillReceiveEmailNotificationWhenExportIsReady')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          size="large"
          variant="outlined"
        >
          {commonStrings('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
