import { Box, TextField, Typography, useMediaQuery } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { isValid, parse } from 'date-fns';
import { type ReactNode, useContext, useEffect, useState } from 'react';
import {
  AnswerTypeEnum,
  type AssessmentControlPointAnswerListOutputItem,
  type AssessmentControlPointListOutputItem,
  type OrganizationCultivationsAssessmentControlPointCultivationList,
} from 'src/__generated__/InternalApiTypes';
import { useGetCurrentUserProfile } from 'src/hooks';
import { commonStrings } from 'src/languages/en-UK';
import { DATE_DISPLAY_FORMAT } from 'src/settings';
import { getCultivationDisplayNameForControlPoint } from 'src/utils/getCultivationDisplayName';

import {
  type AnswerChipStatus,
  ControlPointAnswerPoints,
  getComplianceStatusLabel,
  mapComplianceStatusToAnswerChip,
} from '../ControlPointAnswerPoints';
import { ThemeContext } from '../ThemeProvider';

interface ControlPointDynamicAnswerProps {
  answerType: AnswerTypeEnum;
  assessmentControlPoint: AssessmentControlPointListOutputItem;
  existingAnswers: AssessmentControlPointAnswerListOutputItem[];
  hasPlotName: boolean;
  isDisabled: boolean;
  onChangeAnswer: (answers: string | number) => void;
  placeholder: string;
  cultivation?: OrganizationCultivationsAssessmentControlPointCultivationList;
  index?: number;
  isLast?: boolean;
  label?: ReactNode;
}

export const getDynamicInputData = (
  existingAnswers: AssessmentControlPointAnswerListOutputItem[],
  cultivation?: OrganizationCultivationsAssessmentControlPointCultivationList,
  // Any type comes from BE
): [AnswerChipStatus, string, Record<string, any>, number] => {
  const existingItem = existingAnswers?.find(
    (e) => e.organization_cultivation_id === cultivation?.organization_cultivation_id,
  );

  let answer: AssessmentControlPointAnswerListOutputItem;
  if (cultivation) {
    if (existingItem) {
      answer = existingItem;
    }
  } else if (existingAnswers?.length > 0) {
    answer = existingAnswers[0];
  }
  const status = mapComplianceStatusToAnswerChip(answer?.compliance_status);
  const statusLabel = getComplianceStatusLabel(answer?.compliance_status_label);
  return [status, statusLabel, answer?.dynamic_answer, answer?.points ?? 0];
};

export const ControlPointDynamicAnswer = ({
  answerType,
  assessmentControlPoint,
  cultivation,
  existingAnswers,
  index,
  label,
  onChangeAnswer,
  placeholder,
  hasPlotName = false,
  isDisabled = false,
  isLast = false,
}: ControlPointDynamicAnswerProps): React.JSX.Element => {
  const [status, statusLabel, value, points] = getDynamicInputData(existingAnswers, cultivation);
  const [errorMessage, setErrorMessage] = useState('');
  const { data: userProfile } = useGetCurrentUserProfile();
  const language = userProfile?.language;
  const {
    is_scored: isScored,
    max_answer_value: maxAnswerValue,
    max_decimal_places: maxDecimalPlaces,
    min_answer_value: minAnswerValue,
  } = assessmentControlPoint;
  const [dynamicValue, setDynamicValue] = useState(value);

  useEffect(() => {
    if (answerType === AnswerTypeEnum.Number && value && language !== 'en') {
      // Since it is dynamic, it must be any type.
      setDynamicValue(value.toString().replace('.', ',') as any);
    } else {
      setDynamicValue(value);
    }
  }, [answerType, language, value]);

  const getAnswerPointValue = () => points || 0;
  const { theme } = useContext(ThemeContext);
  const isSmallDesktop = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      marginBottom={isLast || !cultivation ? 0 : 2}
    >
      {cultivation && index === 0 && (
        <Typography
          marginBottom={1}
          variant="overline"
        >
          {commonStrings('answersByCultivations')}
        </Typography>
      )}
      <Box
        alignItems={{ xs: 'flex-start', lg: 'center' }}
        display="flex"
        flexDirection={{ xs: 'column', lg: 'row' }}
      >
        {cultivation ? (
          <Typography
            flex={{ xs: 1, sm: 0.3 }}
            maxWidth={isSmallDesktop ? undefined : 320}
            minWidth={isSmallDesktop ? undefined : 320}
            paddingRight={2}
            variant="body1"
          >
            {getCultivationDisplayNameForControlPoint(cultivation, hasPlotName)}
          </Typography>
        ) : (
          label
        )}
        <Box
          display="flex"
          flex={1}
          flexGrow={1}
          marginTop={isSmallDesktop ? 1 : undefined}
          width={isMobile ? '100%' : undefined}
        >
          {answerType === 'date' ? (
            <DatePicker
              disabled={isDisabled}
              disablePast
              inputFormat={DATE_DISPLAY_FORMAT}
              onChange={(date, keyboardInput) => {
                setDynamicValue(date);
                if (!keyboardInput && isValid(date)) {
                  onChangeAnswer(date.toISOString());
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onBlur={(e) => {
                    if (e?.target?.value) {
                      const date = parse(e.target.value, DATE_DISPLAY_FORMAT, new Date());
                      if (isValid(date)) {
                        onChangeAnswer(date.toISOString());
                      }
                    }
                  }}
                  size="small"
                  sx={{
                    '& .MuiInputBase-input': {
                      px: 1.5,
                    },
                    '& .MuiInputLabel-root': {
                      pl: 0.25,
                    },
                  }}
                />
              )}
              showToolbar={false}
              value={dynamicValue}
            />
          ) : (
            <TextField
              disabled={isDisabled}
              error={!!errorMessage}
              helperText={errorMessage}
              label={placeholder}
              onBlur={(e) => {
                let val = e.target.value;
                let errorMsg = '';
                if (answerType === AnswerTypeEnum.Number) {
                  if (val.endsWith(',') || val.endsWith('.') || val === '-') {
                    val = val.slice(0, -1);
                  }
                  const numberVal = val.replace(',', '.');
                  if (numberVal) {
                    if (minAnswerValue !== null && minAnswerValue > parseFloat(numberVal)) {
                      errorMsg = commonStrings('minErrorMessage', { min: minAnswerValue });
                    } else if (maxAnswerValue !== null && parseFloat(numberVal) > maxAnswerValue) {
                      errorMsg = commonStrings('maxErrorMessage', { max: maxAnswerValue });
                    }
                  }
                  setErrorMessage(errorMsg);
                }
                // Since it is dynamic, it must be any type.
                setDynamicValue(val as any);
                if (!errorMsg) {
                  onChangeAnswer(
                    answerType === AnswerTypeEnum.Number && val
                      ? parseFloat(val.replace(',', '.'))
                      : val,
                  );
                }
              }}
              onChange={(e) => {
                const val = e.target.value;
                if (answerType === AnswerTypeEnum.Number) {
                  const maxPlacesRegex = maxDecimalPlaces ?? '';
                  const seperatorRegex = language === 'en' ? '.' : ',';
                  const numberRegex = new RegExp(
                    `^$|^-?\\d{0,}(\\${seperatorRegex})?\\d{0,${maxPlacesRegex}}$`,
                  );
                  const isValidNumber = numberRegex.test(val);
                  if (isValidNumber) {
                    // Since it is dynamic, it must be any type.
                    setDynamicValue(val as any);
                  } else {
                    // Since it is dynamic, it must be any type.
                    setDynamicValue((dynamicValue as any) || '');
                  }
                } else {
                  // Since it is dynamic, it must be any type.
                  setDynamicValue(val as any);
                }
              }}
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  // It's MUI Textfield error so we should use any.
                  (ev?.target as any)?.blur?.();
                  ev.preventDefault();
                }
              }}
              size="small"
              sx={{
                width: answerType === AnswerTypeEnum.Number && !isMobile ? 192 : '100%',
                minWidth: answerType === AnswerTypeEnum.Number || isMobile ? undefined : 430,
                '& .MuiInputBase-input': {
                  px: 1.5,
                  textAlign: answerType === AnswerTypeEnum.Number ? 'center' : 'left',
                },
                '& .MuiInputLabel-root': {
                  pl: 0.25,
                },
              }}
              value={dynamicValue}
            />
          )}
        </Box>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          width={isSmallDesktop ? '100%' : undefined}
        >
          <ControlPointAnswerPoints
            showPointBox={isScored}
            status={status}
            statusLabel={statusLabel}
            value={getAnswerPointValue()}
          />
        </Box>
      </Box>
    </Box>
  );
};
