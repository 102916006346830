export const getMobileOperatingSystem = () => {
  const userAgent = navigator?.userAgent;
  let userOS = 'unknown';
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    userOS = 'Windows Phone';
  } else if (/android/i.test(userAgent)) {
    userOS = 'Android';
    // @ts-expect-error
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    userOS = 'iOS';
  } else if (/Macintosh/i.exec(navigator.userAgent)) {
    userOS = 'webOS';
  }
  const isMobile = userOS === 'iOS' || userOS === 'Android';

  return [isMobile, userOS];
};
