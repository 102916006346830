import {
  type AssessmentModuleProgressOutput,
  type AssessmentProgressGetAssessmentProgressListParams,
  type AssessmentProgressOutput,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import {
  type DetailParamsGeneric,
  type DetailParamsWithTenantId,
  injectQueryParams,
} from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'assessment-progress';

const assessmentProgressEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAssessmentProgress: build.query<
      AssessmentModuleProgressOutput[],
      DetailParamsGeneric<AssessmentProgressGetAssessmentProgressListParams>
    >({
      query: ({ tid, id, ...restParams }) =>
        injectQueryParams(`/tid/${tid}/${RESOURCE}/${id}/get-assessment-progress`, restParams),
      providesTags: [
        TagType.AssessmentProgress,
        TagType.AssessmentControlPointAnswer,
        TagType.Assessment,
      ],
    }),
    getAssessmentProgressBar: build.query<AssessmentProgressOutput, DetailParamsWithTenantId>({
      query: ({ tid, id }) => `/tid/${tid}/${RESOURCE}/${id}/get-assessment-progress-bar`,
      providesTags: [
        TagType.AssessmentProgress,
        TagType.AssessmentControlPointAnswer,
        TagType.Assessment,
      ],
    }),
  }),
  overrideExisting: false,
});

export const { useGetAssessmentProgressBarQuery, useLazyGetAssessmentProgressQuery } =
  assessmentProgressEndpoints;
