import { type PayloadAction } from '@reduxjs/toolkit';

import { type AssessmentConfigState, type MatrixSelection } from '../types';
import { doesSelectionExist } from '../utils/doesSelectionExist';

/**
 * Handles changes to single checkboxes in the matrix.
 *
 * @param selection - Selection object containing the standard ID and cultivation ID
 * @param isChecked - Whether this change is an addition or deletion to the selection set
 */
export const changeCultivationSelection = (
  state: AssessmentConfigState,
  action: PayloadAction<{
    selection: MatrixSelection;
    isChecked: boolean;
    isToggleButton?: boolean;
  }>,
): AssessmentConfigState => {
  const { selection, isChecked, isToggleButton } = action.payload;

  if (isChecked) {
    // If adding selections, store them in a temporary array
    const selectionsToAdd = [];

    // Add the selection to the "to-add" array
    if (!doesSelectionExist(state.selections, selection)) {
      selectionsToAdd.push(selection);
    }

    return {
      ...state,
      selections: [...state.selections, ...selectionsToAdd],
    };
  }

  // Otherwise, when removing selections, store them in a temporary array
  const selectionsToRemove: MatrixSelection[] = [];

  // To remove cultivation from tab component
  if (isToggleButton) {
    // Add the selection to the "to-remove" array
    if (doesSelectionExist(state.selections, selection)) {
      selectionsToRemove.push(selection);
    }
  } else {
    // To remove cultivation from selection list component
    state.selections.forEach((existingSelection) => {
      if (existingSelection.rowId === selection.rowId) {
        selectionsToRemove.push(existingSelection);
      }
    });
  }

  // Finally, remove them
  return {
    ...state,
    selections: state.selections.filter(
      (a) => !selectionsToRemove.find((b) => a.colId === b.colId && a.rowId === b.rowId),
    ),
  };
};
