import { Paper, Stack, Typography } from '@mui/material';
import { addYears } from 'date-fns';
import { type ChangeEvent, useContext, useRef, useState } from 'react';
import {
  type AssessmentControlPointAnswerListOutputItem,
  type AssessmentControlPointListOutputItem,
  AssessmentStatusEnum,
  RolesEnum,
} from 'src/__generated__/InternalApiTypes';
import { ControlPointMasterDataContext } from 'src/context/ControlPointMasterData';
import { SectionId } from 'src/enums/SectionId';
import {
  useCurrentUserRoles,
  useDialogState,
  useMutationFeedback,
  useTenantId,
  useUserAuth,
} from 'src/hooks';
import { controlPointStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import {
  useCreateAssessmentControlPointEvidenceMutation,
  useCreateEvidenceMutation,
  useGetAssessmentQuery,
} from 'src/services/farmApi';
import { parseHtml } from 'src/utils/parseHtml';

import { SkeletonMultiLineText } from '../common/SkeletonMultiLineText';
import { ControlPointAnswer } from '../ControlPointAnswer';
import { ControlPointComments } from '../ControlPointComments';
import { ControlPointHeader } from '../ControlPointHeader';
import { KebabMenuSlug } from '../ControlPointHeaderKebabMenu';
import { ControlPointUploadedEvidence } from '../ControlPointUploadedEvidence';
import {
  ControlPointEvidenceDialog,
  type ControlPointEvidenceDialogProps,
} from '../dialogs/ControlPointEvidenceDialog';
import {
  ControlPointGuidanceDialog,
  type ControlPointGuidanceDialogProps,
} from '../dialogs/ControlPointGuidanceDialog';
import { type EvidencePreviewDialogProps } from '../dialogs/EvidencePreviewDialog';
import { HelpDocumentsDialog, type HelpDocumentsDialogProps } from '../dialogs/HelpDocumentsDialog';

interface ControlPointProps {
  assessmentControlPoint: AssessmentControlPointListOutputItem;
  assessmentControlPointAnswer: AssessmentControlPointAnswerListOutputItem[];
  hidingMenuList?: Array<KebabMenuSlug>;
  includeOnlyNonCompliantCultivations?: boolean;
  isDisableAnswer?: boolean;
  isDisableNonConformity?: boolean;
  isLoadingNewModule?: boolean;
  isHideBreadcrumbs?: boolean;
  isHideComments?: boolean;
}

export const ControlPoint = ({
  assessmentControlPoint,
  assessmentControlPointAnswer,
  hidingMenuList,
  includeOnlyNonCompliantCultivations = false,
  isDisableNonConformity = false,
  isLoadingNewModule = false,
  isHideBreadcrumbs = false,
  isHideComments = false,
  isDisableAnswer = false,
}: ControlPointProps): React.JSX.Element => {
  const tid = useTenantId();
  const [assessmentId] = useContext(AssessmentPageContext);
  const { data: assessment } = useGetAssessmentQuery(
    { tid, id: assessmentId },
    { skip: !assessmentId || !tid },
  );
  const [visibleSectionId, setVisibleSectionId] = useState<SectionId | null>(null);
  const [createEvidence, createEvidenceResult] = useCreateEvidenceMutation();
  const [createControlPointEvidence] = useCreateAssessmentControlPointEvidenceMutation();
  const currentUser = useUserAuth();
  const currentUserRoles = useCurrentUserRoles();
  const isFarmManager = currentUserRoles.includes(RolesEnum.FarmManager);
  const isAuditor = currentUserRoles.includes(RolesEnum.Auditor);
  const isAssessmentAuthor = assessment?.author_id === currentUser?.userId;
  const isCBManager =
    currentUserRoles.includes(RolesEnum.CertificationBodyManager) && !isAssessmentAuthor;
  const isReviewer =
    isAuditor && !isAssessmentAuthor && assessment?.status === AssessmentStatusEnum.ToBeReviewed;
  const inputFileRef = useRef(null);
  const auditorClientIdentifierIds: string[] =
    isAuditor && assessment?.client_identifiers ? assessment?.client_identifiers : [];
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event?.target?.files && event.target.files.length > 0) {
      const file = event.currentTarget.files?.[0];
      const expiryDate = addYears(new Date(), 1);
      if (tid && file) {
        createEvidence({
          tid,
          body: {
            file,
            name: file.name,
            expiry_date: expiryDate,
            client_identifiers: auditorClientIdentifierIds,
          },
        });
      }
    }
  };

  const controlPointId = assessmentControlPoint?.control_point_id;
  let filterParams;
  if (controlPointId) {
    filterParams = { category_and_evidence_type_based_control_point_id: [controlPointId] };
  }

  useMutationFeedback({
    result: createEvidenceResult,
    onSuccess: (response) => {
      if (tid && assessmentId) {
        createControlPointEvidence({
          tid,
          body: {
            assessment_id: assessmentId,
            control_point_id: controlPointId,
            evidence_id: response.uuid,
          },
        });
      }
    },
    successMessage: controlPointStrings('attachDocumentSuccess'),
    errorMessage: controlPointStrings('attachDocumentFail'),
  });

  const handleChangeSection = (sectionId: SectionId) => {
    setVisibleSectionId(visibleSectionId === sectionId ? null : sectionId);
  };

  const { isFullyLoaded: isMasterDataLoaded } = useContext(ControlPointMasterDataContext);
  const [isEvidenceDialogOpen, openEvidenceDialog, closeEvidenceDialog] =
    useDialogState<ControlPointEvidenceDialogProps>();
  const [isGuidanceDialogOpen, openGuidanceDialog, closeGuidanceDialog] =
    useDialogState<ControlPointGuidanceDialogProps>();
  const [isHelpDocumentsDialogOpen, openHelpDocumentsDialog, closeHelpDocumentsDialog] =
    useDialogState<HelpDocumentsDialogProps>();

  const [isPreviewDialogOpen, openPreviewDialog, closePreviewDialog, previewDialogProps] =
    useDialogState<EvidencePreviewDialogProps>();

  // If there is no control point, show the whole thing as a loading skeleton
  const isLoading = !assessmentControlPoint || !isMasterDataLoaded || isLoadingNewModule;

  return (
    <Paper
      sx={{
        flex: '0 0 auto',
        p: { xs: 1, sm: 2 },
        mb: 2,
      }}
    >
      <Stack spacing={2}>
        <ControlPointHeader
          assessmentControlPoint={assessmentControlPoint}
          assessmentControlPointAnswer={
            assessmentControlPointAnswer?.length > 0 ? assessmentControlPointAnswer[0] : undefined
          }
          filterParams={filterParams}
          hidingMenuList={hidingMenuList}
          isHideBreadcrumbs={isHideBreadcrumbs}
          isLoading={isLoading}
          isShowFlag={!isReviewer && !isCBManager}
          onChangeSection={handleChangeSection}
          onClickEvidence={() => {
            if (isAuditor) {
              inputFileRef?.current?.click();
            } else {
              openEvidenceDialog();
            }
          }}
          onClickGuidance={openGuidanceDialog}
          onClickHelpDocuments={openHelpDocumentsDialog}
        />
        <input
          ref={inputFileRef}
          hidden
          onChange={handleFileChange}
          type="file"
        />
        {isLoading ? (
          <SkeletonMultiLineText
            lines={1}
            paddingBottom={1}
            variant="body1"
          />
        ) : (
          <Typography
            component="span"
            fontWeight={500}
            paddingBottom={1}
            variant="body1"
          >
            {parseHtml(
              `${
                assessmentControlPoint?.display_code || assessmentControlPoint?.code
              }: ${assessmentControlPoint?.main_text}`,
            )}
          </Typography>
        )}
        <ControlPointAnswer
          assessmentControlPoint={assessmentControlPoint}
          existingAnswers={assessmentControlPointAnswer}
          hasPlotName={isFarmManager}
          includeOnlyNonCompliantCultivations={includeOnlyNonCompliantCultivations}
          isAutoTriggerNonConformity={
            !isLoading && visibleSectionId === SectionId.NonConformitiesAndCorrectiveActions
          }
          isDisableAnswer={isDisableAnswer}
          isDisableJustificationText={isReviewer || isCBManager}
          isDisableNonConformity={isDisableNonConformity || isReviewer || isCBManager}
          isExpanded={assessmentControlPointAnswer?.length > 0}
          isLoading={isLoading}
        />
        {!isLoading && !isHideComments && (
          <ControlPointComments
            assessmentControlPointId={assessmentControlPoint?.uuid}
            controlPointId={controlPointId}
            isVisibleCommentSection={
              visibleSectionId === SectionId.Comments ||
              !!hidingMenuList?.includes(KebabMenuSlug.AddComment)
            }
          />
        )}
        <ControlPointUploadedEvidence
          assessmentControlPointId={assessmentControlPoint?.uuid}
          controlPointId={controlPointId}
          isLoading={isLoading}
          openPreviewDialog={openPreviewDialog}
        />
        {!isLoading && assessmentControlPoint && (
          <>
            <ControlPointEvidenceDialog
              assessmentControlPointId={assessmentControlPoint.uuid}
              closePreviewDialog={closePreviewDialog}
              controlPointId={controlPointId}
              evidenceTypeIds={assessmentControlPoint.evidence_type_ids}
              isOpen={isEvidenceDialogOpen}
              isPreviewDialogOpen={isPreviewDialogOpen}
              onClose={closeEvidenceDialog}
              openPreviewDialog={openPreviewDialog}
              previewDialogProps={previewDialogProps}
            />
            <ControlPointGuidanceDialog
              controlPointId={controlPointId}
              isOpen={isGuidanceDialogOpen}
              onClose={closeGuidanceDialog}
            />
            <HelpDocumentsDialog
              assessmentDetail={assessment}
              controlPointId={controlPointId}
              controlPointName={
                assessmentControlPoint?.display_code || assessmentControlPoint?.code
              }
              evidenceTypeIds={assessmentControlPoint.evidence_type_ids}
              filterParams={filterParams}
              isOpen={isHelpDocumentsDialogOpen}
              onClose={closeHelpDocumentsDialog}
            />
          </>
        )}
      </Stack>
    </Paper>
  );
};
