import { CloseRounded } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
} from '@mui/material';
import { DocumentPreview, imageFileExtensions } from 'src/components/common/DocumentPreview';
import { useAppDispatch } from 'src/hooks';
import { commonStrings } from 'src/languages/en-UK';
import { useGetHelpDocumentQuery } from 'src/services/farmApi';
import { openSnackbar } from 'src/store/snackbarSlice';
import { downloadFileToLocal, getFileExtension } from 'src/utils/fileUtils';

export interface HelpDocumentPreviewDialogProps {
  helpDocumentId: string;
  isOpen: boolean;
  onClose: (value?: string) => void;
}

export const HelpDocumentPreviewDialog = ({
  helpDocumentId,
  onClose,
  isOpen = false,
}: HelpDocumentPreviewDialogProps) => {
  const dispatch = useAppDispatch();
  const { data: helpDocument, isFetching } = useGetHelpDocumentQuery(helpDocumentId, {
    skip: !helpDocumentId,
  });

  let isImage = false;
  if (helpDocument?.file?.file_object) {
    const fileType = getFileExtension(helpDocument.file.file_object);
    isImage = imageFileExtensions.includes(fileType);
  }

  const handleClose = () => {
    onClose(helpDocumentId);
  };

  const handleDownload = (): void => {
    if (helpDocument?.file) {
      downloadFileToLocal(helpDocument.file, helpDocument?.name, (msg) =>
        dispatch(openSnackbar({ message: msg, severity: 'error' })),
      );
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={handleClose}
      open={isOpen}
      PaperProps={{
        style: {
          minHeight: '90%',
          maxHeight: '90%',
        },
      }}
    >
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          padding: 1.5,
        }}
      >
        <CloseRounded />
      </IconButton>
      <DialogTitle>
        {isFetching ? (
          <Skeleton
            variant="text"
            width="33%"
          />
        ) : (
          commonStrings('viewingPreviewTitle', {
            name: `${helpDocument?.name}.${getFileExtension(helpDocument?.file?.file_object)}`,
          })
        )}
      </DialogTitle>
      <DialogContent>
        <DocumentPreview
          id={helpDocument?.file?.uuid || ''}
          isLoading={isFetching}
          title={helpDocument?.name}
          url={helpDocument?.file?.file_object}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{commonStrings('close')}</Button>
        {isImage && (
          <Button
            onClick={handleDownload}
            variant="contained"
          >
            {commonStrings('download')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
