import { AuthStatus } from 'src/enums/AuthStatus';
import { useGetUserProfileQuery } from 'src/services/farmApi';

import { useUserAuth } from './useUserAuth';

export const useGetCurrentUserProfile = () => {
  const { authStatus, userId } = useUserAuth();
  const getUserProfileQuery = useGetUserProfileQuery(userId, {
    skip: !userId || authStatus !== AuthStatus.LoggedIn,
  });

  return getUserProfileQuery?.data?.uuid !== userId ? null : getUserProfileQuery;
};
