import { FormikSelectField } from '../FormikSelectField';
import { type FormikTextFieldProps } from '../FormikTextField';

type FormikLanguageFieldProps = FormikTextFieldProps;

/**
 * This component pre-fills a FormikSelectField with language options.
 */
export const FormikLanguageField = ({
  formik,
  id,
  label,
  isLoading = false,
  ...restProps
}: FormikLanguageFieldProps) => {
  /* These are intentionally left hard coded as they shouldn't be translated (e.g. they should
   * always appear in the native language of the user who would select them).
   */
  const options = [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Español' },
    { value: 'nl', label: 'Nederlands' },
    { value: 'de', label: 'Deutsch' },
    { value: 'fr', label: 'Français' },
    { value: 'it', label: 'Italiano' },
  ];
  return (
    <FormikSelectField
      formik={formik}
      id={id}
      isLoading={isLoading}
      label={label}
      options={options}
      {...restProps}
    />
  );
};
