import {
  type SubscriptionListOutputItem,
  type SubscriptionsListParams,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { injectQueryParams } from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'subscriptions';

const subscriptionEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSubscriptionList: build.query<SubscriptionListOutputItem[], SubscriptionsListParams>({
      query: ({ tid, ...restParams }) => injectQueryParams(`/tid/${tid}/${RESOURCE}/`, restParams),
      providesTags: [TagType.Subscriptions],
    }),
  }),
  overrideExisting: false,
});

export const { useGetSubscriptionListQuery } = subscriptionEndpoints;
