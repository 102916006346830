import { CheckRounded } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, FormControlLabel, Switch, Toolbar, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { RolesEnum } from 'src/__generated__/InternalApiTypes';
import { useCurrentUserRoles, useMutationFeedback, useTenantId, useUserAuth } from 'src/hooks';
import { accountNotificationsFormStrings, commonStrings } from 'src/languages/en-UK';
import {
  useGetOrganizationMemberListQuery,
  useUpdateOrganizationMemberMutation,
} from 'src/services/farmApi';
import { populateFormValues } from 'src/utils/populateFormValues';

export const AccountNotificationsForm = (): React.JSX.Element => {
  const tid = useTenantId();
  const [updateOrganizationMember, updateOrganizationMemberResult] =
    useUpdateOrganizationMemberMutation();
  const currentUser = useUserAuth();
  const currentUserRoles = useCurrentUserRoles();
  const isCBManager = currentUserRoles.includes(RolesEnum.CertificationBodyManager);
  const { data: organizationMembers, isLoading: isGetOrganizationMembersLoading } =
    useGetOrganizationMemberListQuery({ tid }, { skip: !tid });

  const organizationMember = organizationMembers?.find(
    (m) => m.user_profile_id === currentUser?.userId,
  );
  const defaultValues = {
    has_all_notifications_enabled: true,
    has_new_comment_notifications_enabled: false,
    has_new_request_notifications_enabled: false,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: populateFormValues(
      defaultValues,
      organizationMember
        ? {
            has_all_notifications_enabled: organizationMember?.has_all_notifications_enabled,
            has_new_comment_notifications_enabled:
              organizationMember?.has_new_comment_notifications_enabled,
            has_new_request_notifications_enabled:
              organizationMember?.has_new_request_notifications_enabled,
          }
        : defaultValues,
    ),
    onSubmit: (values) => {
      if (tid && organizationMember?.uuid) {
        updateOrganizationMember({
          tid,
          id: organizationMember.uuid,
          body: values,
        });
      }
    },
  });

  useMutationFeedback({
    result: updateOrganizationMemberResult,
    successMessage: commonStrings('backendSaveSuccessMessage', {
      name: formik.values.name,
    }),
    errorMessage: commonStrings('backendSaveFailMessage', {
      name: formik.values.name,
    }),
  });

  const isLoading = isGetOrganizationMembersLoading || updateOrganizationMemberResult.isLoading;

  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <Box
        display="grid"
        gap={2}
        gridTemplateAreas={`
          "notificationHeader notificationHeader"
          "notifyAll notifyAll"
          "notifyNewRequest notifyNewRequest"
          "notifyNewComment notifyNewComment"
        `}
        gridTemplateColumns="repeat(2, 1fr)"
        gridTemplateRows="auto"
      >
        <Typography
          gridArea="notificationHeader"
          lineHeight={3.43}
          variant="subtitle2"
        >
          {accountNotificationsFormStrings('notification')}
        </Typography>
        <FormControlLabel
          control={
            <Switch
              checked={formik.values.has_all_notifications_enabled}
              disabled={isLoading}
              name="has_all_notifications_enabled"
              onChange={(e) => {
                formik.setFieldValue('has_all_notifications_enabled', e.target.checked);
                if (
                  e.target.checked &&
                  (formik.values.has_new_request_notifications_enabled ||
                    formik.values.has_new_comment_notifications_enabled)
                ) {
                  formik.setFieldValue('has_new_request_notifications_enabled', false);
                  formik.setFieldValue('has_new_comment_notifications_enabled', false);
                }
              }}
            />
          }
          label={accountNotificationsFormStrings('sendEmailForAllNotifications')}
          sx={{ mr: 1, gridArea: 'notifyAll' }}
        />
        {!isCBManager && (
          <FormControlLabel
            control={
              <Switch
                checked={formik.values.has_new_request_notifications_enabled}
                disabled={isLoading}
                name="has_new_request_notifications_enabled"
                onChange={(e) => {
                  formik.setFieldValue('has_new_request_notifications_enabled', e.target.checked);
                  if (e.target.checked && formik.values.has_all_notifications_enabled) {
                    formik.setFieldValue('has_all_notifications_enabled', false);
                  }
                }}
              />
            }
            label={accountNotificationsFormStrings('sendEmailOnlyForRequests')}
            sx={{ mr: 1, gridArea: 'notifyNewRequest' }}
          />
        )}
        {!isCBManager && (
          <FormControlLabel
            control={
              <Switch
                checked={formik.values.has_new_comment_notifications_enabled}
                disabled={isLoading}
                name="has_new_comment_notifications_enabled"
                onChange={(e) => {
                  formik.setFieldValue('has_new_comment_notifications_enabled', e.target.checked);
                  if (e.target.checked && formik.values.has_all_notifications_enabled) {
                    formik.setFieldValue('has_all_notifications_enabled', false);
                  }
                }}
              />
            }
            label={accountNotificationsFormStrings('sendEmailOnlyForComments')}
            sx={{ mr: 1, gridArea: 'notifyNewComment' }}
          />
        )}
      </Box>
      <Toolbar
        disableGutters
        sx={{ justifyContent: 'flex-end', mt: isCBManager ? 0 : 2, gap: 1 }}
        variant="dense"
      >
        <LoadingButton
          loading={isLoading}
          startIcon={<CheckRounded />}
          type="submit"
          variant="contained"
        >
          {commonStrings('saveChanges')}
        </LoadingButton>
      </Toolbar>
    </form>
  );
};
