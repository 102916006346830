import { format } from 'date-fns';
import {
  type AssessmentControlPointCommentCreateInput,
  type AssessmentControlPointCommentCreateOutput,
} from 'src/__generated__/InternalApiTypes';
import { baseApi } from 'src/services/farmApi/baseApi';
import {
  type CreateParamsWithTenantId,
  type DeleteParamsWithTenantId,
  transformObject,
} from 'src/services/farmApi/helpers';
import { TagType } from 'src/services/farmApi/TagType';
import { DATE_SERVER_FORMAT } from 'src/settings';

const RESOURCE = 'assessment-control-point-comments';

const requestTransformations = {
  created_at: (value: Date): string => format(value, DATE_SERVER_FORMAT),
  modified_at: (value: Date): string => format(value, DATE_SERVER_FORMAT),
};

const assessmentControlPointCommentEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createAssessmentControlPointComment: build.mutation<
      AssessmentControlPointCommentCreateOutput,
      CreateParamsWithTenantId<AssessmentControlPointCommentCreateInput>
    >({
      query: ({ tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/create/`,
        method: 'POST',
        body: transformObject(body, requestTransformations),
      }),
      invalidatesTags: (result) => [
        { type: TagType.AssessmentControlPoint, id: result?.control_point_id },
      ],
    }),

    deleteAssessmentControlPointComment: build.mutation<void, DeleteParamsWithTenantId>({
      query: ({ id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/delete/`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, params) => [
        { type: TagType.AssessmentControlPoint, id: params.parentResourceId },
        { type: TagType.AssessmentControlPointComment, id: params.id },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateAssessmentControlPointCommentMutation,
  useDeleteAssessmentControlPointCommentMutation,
} = assessmentControlPointCommentEndpoints;
