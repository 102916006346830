import {
  ControlPointAnswerTypeEnum,
  type PreliminaryQuestionListOutputItem,
} from 'src/__generated__/InternalApiTypes';
import { commonStrings } from 'src/languages/en-UK';
import * as Yup from 'yup';

export const generateNumberInputValidation = (
  // Since it is dynamic, it must be any type.
  initialValidator: any,
  question: PreliminaryQuestionListOutputItem,
) => {
  let validator = initialValidator;
  if (question.answer_type === ControlPointAnswerTypeEnum.FarmLevelInputNumber) {
    if (question.min_answer_value && question.max_answer_value) {
      validator = Yup.number()
        .transform((_value, originalValue) =>
          originalValue ? Number(originalValue.toString()?.replace(/,/, '.')) : originalValue,
        )
        .min(
          question.min_answer_value,
          commonStrings('minErrorMessage', { min: question.min_answer_value }),
        )
        .max(
          question.max_answer_value,
          commonStrings('maxErrorMessage', { max: question.max_answer_value }),
        );
    } else if (question.min_answer_value) {
      validator = Yup.number()
        .transform((_value, originalValue) =>
          originalValue ? Number(originalValue.toString()?.replace(/,/, '.')) : originalValue,
        )
        .min(
          question.min_answer_value,
          commonStrings('minErrorMessage', { min: question.min_answer_value }),
        );
    } else if (question.max_answer_value) {
      validator = Yup.number()
        .transform((_value, originalValue) =>
          originalValue ? Number(originalValue.toString()?.replace(/,/, '.')) : originalValue,
        )
        .max(
          question.max_answer_value,
          commonStrings('maxErrorMessage', { max: question.max_answer_value }),
        );
    }
  }
  return validator;
};

export const regexForThousandSeperator = /\B(?=(\d{3})+(?!\d))/g;
