import './styles.scss';

import { Auth } from '@aws-amplify/auth';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { App } from 'src/components/App';
import { store } from 'src/store';

import { AppProviders } from './AppProviders';
import AWS_CONFIG from './aws-config';
import { AppRoutes } from './components/App/AppRoutes';

Sentry.init({
  dsn: window.appConfig.sentryDsn,
  environment: window.appConfig.environmentName,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0, //  Capture 0% of the transactions because of quota
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 0%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

LicenseInfo.setLicenseKey(window.appConfig.muiLicenseKey);

Auth.configure(AWS_CONFIG);

ReactDOM.render(
  <AppProviders store={store}>
    <App>
      <AppRoutes />
    </App>
  </AppProviders>,
  document.getElementById('root'),
);
