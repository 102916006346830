import { AssessmentAuditorEvidenceStep } from '../AssessmentAuditorEvidenceStep';
import { AssessmentConfigStep } from '../AssessmentConfigStep';
import { AssessmentControlPointStep } from '../AssessmentControlPointStep';
import { AssessmentEvidenceStep } from '../AssessmentEvidenceStep';
import { AssessmentPreliminaryQuestionsStep } from '../AssessmentPreliminaryQuestionsStep';
import { AssessmentReportStep } from '../AssessmentReportStep';
import { type StepperContentBaseProps } from './StepperPage.types';

export interface StepperConfigStep {
  component: ({ ...props }: StepperContentBaseProps) => React.JSX.Element;
  additionalProps: { hasSidebar: boolean; hasTabs: boolean };
}

interface StepperConfigMap {
  [key: string]: StepperConfigStep;
}

export const stepperConfig: StepperConfigMap = {
  'assessment-configuration': {
    component: AssessmentConfigStep,
    additionalProps: { hasSidebar: false, hasTabs: false },
  },
  'preliminary-questions': {
    component: AssessmentPreliminaryQuestionsStep,
    additionalProps: { hasSidebar: false, hasTabs: false },
  },
  'evidence-upload': {
    component: AssessmentEvidenceStep,
    additionalProps: { hasSidebar: false, hasTabs: true },
  },
  'evidence-review': {
    component: AssessmentAuditorEvidenceStep,
    additionalProps: { hasSidebar: false, hasTabs: false },
  },
  checklist: {
    component: AssessmentControlPointStep,
    additionalProps: { hasSidebar: true, hasTabs: false },
  },
  'assessment-report': {
    component: AssessmentReportStep,
    additionalProps: { hasSidebar: false, hasTabs: false },
  },
};
