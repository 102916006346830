import {
  AddRounded,
  ArrowDropDownRounded,
  DragHandleRounded,
  HorizontalRuleRounded,
  InfoRounded,
  KeyboardArrowDownRounded,
  ShareOutlined,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  ClickAwayListener,
  Container,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { type GridEnrichedColDef, type GridRenderCellParams } from '@mui/x-data-grid-pro';
import {
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import {
  AssessmentStatusEnum,
  type BonusPointsPerThemeCultivationDetails,
  type CultivationBonusPointsPerThemeOutputItem,
  type MalusCompensationCultivationDetails,
  type MalusComplensationPerCultivationOutputItem,
  type ModuleListOutputItem,
  type NonCompliantControlPointListOutputItem,
  RolesEnum,
} from 'src/__generated__/InternalApiTypes';
import { AssessmentActionBarButtons } from 'src/components/AssessmentActionBarButtons';
import { ColumnHeaderBlock } from 'src/components/AssessmentConfigMatrix/ColumnHeaderBlock';
import { LoadingArea } from 'src/components/common';
import { MobileCompatibleDataGrid } from 'src/components/common/MobileCompatibleDataGrid';
import { MobileCompatibleTooltip } from 'src/components/common/MobileCompatibleTooltip';
import { ControlPoint } from 'src/components/ControlPoint';
import {
  AssessmentExportResultDialog,
  type AssessmentExportResultDialogProps,
} from 'src/components/dialogs/AssessmentExportResultDialog';
import {
  AssessmentShareWithAuditorDialog,
  type AssessmentShareWithAuditorDialogProps,
} from 'src/components/dialogs/AssessmentShareWithAuditorDialog';
import { ControlPointMasterDataProvider } from 'src/context/ControlPointMasterData';
import {
  useCurrentUserRoles,
  useDialogState,
  useMutationFeedback,
  useTenantId,
  useUserAuth,
} from 'src/hooks';
import { assessmentReportStepStrings, commonStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import {
  useCreateAssessmentSummaryExportFileMutation,
  useCreateAssessmentSummaryExportWithEvidenceFileMutation,
  useGetAssessmentBaseModulesQuery,
  useGetAssessmentBonusPointsListQuery,
  useGetAssessmentComplianceReportListQuery,
  useGetAssessmentControlPointAnswerListQuery,
  useGetAssessmentMalusPointsListQuery,
  useGetAssessmentNonCompliantControlPointListQuery,
  useGetAssessmentQuery,
  useGetCertificationBodiesListQuery,
  useGetModuleListQuery,
  useGetOrganizationClientIdentifiersListQuery,
  useReportPageDataReadyApiMutation,
  useUpdateAssessmentAuditDurationMutation,
} from 'src/services/farmApi';
import { ROUTE_PATH_DASHBOARD } from 'src/settings';
import { type MatrixColumn } from 'src/store/assessmentConfig/types';
import { moduleToMatrixColumn } from 'src/store/assessmentConfig/utils/moduleToMatrixColumn';
import { appColors } from 'src/theme';
import { getCultivationDisplayNameForControlPoint } from 'src/utils/getCultivationDisplayName';

import { type StepperContentBaseProps } from '../StepperPage';

export const reformatTimeInputString = (timeString: string) => {
  // Reformat time input string to HH:MM

  // Remove any non-numeric characters except colons
  let value = timeString.replace(/[^0-9:]/g, '');

  // Auto-insert colons
  if (value.length === 3) {
    if (!value.includes(':')) {
      value = `${value.slice(0, 2)}:${value.slice(2)}`;
    }
  }

  // Limit length to 5 characters (hh:mm)
  value = value.slice(0, 5);
  if (value.length >= 4 && value.split(':').map(Number)[1] > 59) {
    value = `${value.slice(0, -2)}59`;
  }

  return value;
};

export const timeDurationStringToMinutes = (value: string) => {
  const [hours, minutes] = value.split(':').map(Number);
  return hours * 60 + minutes;
};

export const minutesToTimeDurationString = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}`;
};

type ComplianceRenderItem = {
  getText: () => string | ReactNode;
  id: string;
  isFirstItem: boolean;
  label: string;
  status: string;
  tooltipText?: string;
};

export const AssessmentReportStep = ({
  maxWidth = 'xl',
  ...props
}: StepperContentBaseProps): React.JSX.Element => {
  const tid = useTenantId();
  const navigate = useNavigate();
  const [assessmentId] = useContext(AssessmentPageContext);
  const [isNextTaskLoading, setIsNextTaskLoading] = useState(false);
  const [isPreviousTaskLoading, setIsPreviousTaskLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [activeTabId, setActiveTabId] = useState('');

  const [isShareDialogOpen, openShareDialog, closeShareDialog, shareDialogProps] =
    useDialogState<AssessmentShareWithAuditorDialogProps>();

  const [isExportDialogOpen, openExportDialog, closeExportDialog, exportDialogProps] =
    useDialogState<AssessmentExportResultDialogProps>();

  // Check the button name
  const currentUser = useUserAuth();
  const currentUserRoles = useCurrentUserRoles();
  const { data: assessmentDetail } = useGetAssessmentQuery(
    { tid, id: assessmentId },
    {
      skip: !assessmentId || !tid,
    },
  );
  const isAssessmentAuthor = assessmentDetail?.author_id === currentUser?.userId;
  const isAuditor = currentUserRoles.includes(RolesEnum.Auditor);
  const isFarmManager = currentUserRoles.includes(RolesEnum.FarmManager);
  const isFarmEmployee = currentUserRoles.includes(RolesEnum.FarmEmployee);
  const isCBManager =
    currentUserRoles.includes(RolesEnum.CertificationBodyManager) && !isAssessmentAuthor;
  const isReviewer =
    isAuditor &&
    !isAssessmentAuthor &&
    assessmentDetail.status === AssessmentStatusEnum.ToBeReviewed;

  const isNavigateToDashboardForAuditor =
    isAuditor &&
    !isReviewer &&
    assessmentDetail.status !== AssessmentStatusEnum.Open &&
    assessmentDetail.status !== AssessmentStatusEnum.ToBeAudited;

  const isNavigateToDashboardForAuthor =
    isAuditor &&
    !isAssessmentAuthor &&
    assessmentDetail.status !== AssessmentStatusEnum.ToBeReviewed;

  const isNavigateToDashboard =
    isNavigateToDashboardForAuditor ||
    isNavigateToDashboardForAuthor ||
    (isCBManager && assessmentDetail.status !== AssessmentStatusEnum.ToBeConfirmed);

  let actionButtonLabel = commonStrings('finish');
  if (isNavigateToDashboard) {
    actionButtonLabel = assessmentReportStepStrings('backToTheDashboard');
  } else if (isCBManager) {
    actionButtonLabel = commonStrings('confirm');
  } else if (isReviewer) {
    actionButtonLabel = commonStrings('complete');
  }

  // Modules Tab
  const { data: assessmentBaseModules, isLoading: isLoadingAssessmentBaseModules } =
    useGetAssessmentBaseModulesQuery(
      { tid, id: assessmentId },
      {
        skip: !assessmentId || !tid,
      },
    );

  const { data: moduleList, isLoading: isModuleListLoading } = useGetModuleListQuery(
    {
      has_parent: false,
    },
    { skip: !assessmentId || !tid },
  );

  const selectedStandardId = assessmentBaseModules?.find(
    (standard) => standard.uuid === activeTabId,
  )?.standard_id;

  const columns = (assessmentBaseModules || [])?.reduce(
    (acc: MatrixColumn[], module: ModuleListOutputItem): MatrixColumn[] => {
      const column = moduleToMatrixColumn(module);

      if (acc.findIndex((c) => c.id === column.id) < 0) {
        acc.push(column);
      }
      return acc;
    },
    [],
  );
  const sortedColumns = useMemo(() => [...columns].sort((a, b) => a.index - b.index), [columns]);

  // First call ReportPageDataReadyApi
  const [reportPageDataReady, { isSuccess: isReportPageDataReadySuccess }] =
    useReportPageDataReadyApiMutation();
  useEffect(() => {
    if (selectedStandardId && assessmentId && tid) {
      reportPageDataReady({
        tid,
        id: assessmentId,
        standardId: selectedStandardId,
      });
    }
  }, [assessmentId, selectedStandardId, tid, reportPageDataReady]);

  // Export Summary File
  const [createAssessmentSummaryExportFile, createAssessmentSummaryExportFileResult] =
    useCreateAssessmentSummaryExportFileMutation();
  const [
    createAssessmentSummaryExportFileWithEvidence,
    createAssessmentSummaryExportFileWithEvidenceResult,
  ] = useCreateAssessmentSummaryExportWithEvidenceFileMutation();

  const anchorRef = useRef<HTMLDivElement>(null);
  const isPDFGenerationLoading =
    createAssessmentSummaryExportFileResult.isLoading ||
    createAssessmentSummaryExportFileWithEvidenceResult.isLoading;
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const downloadOptions = [
    assessmentReportStepStrings('exportAssessmentDetails'),
    assessmentReportStepStrings('exportAssessmentAndEvidence'),
  ];

  const handleClick = () => {
    setOpen(false);
    if (tid && assessmentId && selectedStandardId) {
      const createFile =
        selectedIndex === 0
          ? createAssessmentSummaryExportFile
          : createAssessmentSummaryExportFileWithEvidence;

      createFile({
        tid,
        id: assessmentId,
        standardId: selectedStandardId,
      })
        .unwrap()
        .then(() => {
          openExportDialog();
        });
    }
  };

  useMutationFeedback({
    result:
      selectedIndex === 0
        ? createAssessmentSummaryExportFileResult
        : createAssessmentSummaryExportFileWithEvidenceResult,
    errorMessage: assessmentReportStepStrings('downloadAssessmentExportFileFail'),
  });

  const handleMenuItemClick = (_event: React.MouseEvent<HTMLLIElement>, index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  // Bonus/Malus Point List
  const { data: assessmentBonusPointsListResponse, isLoading: isLoadingAssessmentBonusPointsList } =
    useGetAssessmentBonusPointsListQuery(
      {
        tid,
        id: assessmentId,
        standardId: selectedStandardId,
      },
      { skip: !isReportPageDataReadySuccess || !selectedStandardId },
    );

  const { data: assessmentMalusPointsListResponse, isLoading: isLoadingAssessmentMalusPointsList } =
    useGetAssessmentMalusPointsListQuery(
      {
        tid,
        id: assessmentId,
        standardId: selectedStandardId,
      },
      { skip: !isReportPageDataReadySuccess || !selectedStandardId },
    );

  const assessmentMalusPointsList = assessmentMalusPointsListResponse
    ?.slice()
    ?.sort((a, b) =>
      Number(
        a.organization_cultivation_details?.product_name?.localeCompare(
          b.organization_cultivation_details?.product_name,
        ),
      ),
    );

  // Compliance Report
  const {
    data: assessmentComplianceReportListResponse,
    isLoading: isLoadingAssessmentComplianceReportList,
  } = useGetAssessmentComplianceReportListQuery(
    {
      tid,
      id: assessmentId,
      standardId: selectedStandardId,
    },
    {
      skip: !isReportPageDataReadySuccess || !selectedStandardId,
    },
  );

  // Non-compliant Data List
  const {
    data: assessmentNonCompliantControlPointListResponse,
    isLoading: isLoadingAssessmentNonCompliantControlPointList,
  } = useGetAssessmentNonCompliantControlPointListQuery(
    {
      tid,
      id: assessmentId,
      standardId: selectedStandardId,
    },
    {
      skip: !isReportPageDataReadySuccess || !selectedStandardId,
    },
  );

  const {
    data: assessmentControlPointAnswerList,
    isLoading: isLoadingAssessmentControlPointAnswerList,
  } = useGetAssessmentControlPointAnswerListQuery(
    {
      tid,
      assessment_id: [assessmentId],
    },
    {
      skip: !tid || !assessmentId || !isReportPageDataReadySuccess || !selectedStandardId,
      refetchOnMountOrArgChange: true,
    },
  );

  const loadingList = [
    isLoadingAssessmentBaseModules,
    isModuleListLoading,
    !isReportPageDataReadySuccess,
    isLoadingAssessmentComplianceReportList,
    isLoadingAssessmentControlPointAnswerList,
    isLoadingAssessmentBonusPointsList,
    isLoadingAssessmentMalusPointsList,
    isLoadingAssessmentNonCompliantControlPointList,
  ];

  const isLoading = loadingList.some((loading) => loading);

  const bonusPointsTableColumn: GridEnrichedColDef[] = [
    {
      field: 'organization_cultivation',
      headerName: assessmentReportStepStrings('bonusPointsDetalization'),
      flex: 6,
      sortable: false,
      renderCell: (
        params: GridRenderCellParams<
          BonusPointsPerThemeCultivationDetails,
          CultivationBonusPointsPerThemeOutputItem,
          ReactNode
        >,
      ) => (
        <Typography variant="body2">
          {getCultivationDisplayNameForControlPoint(params.value, isFarmManager || isFarmEmployee)}
        </Typography>
      ),
    },
  ];

  const bonusPointRows = assessmentBonusPointsListResponse?.map((r) => {
    const columnList = r.themes.reduce((acc, field) => {
      bonusPointsTableColumn.push({
        field: field.theme_slug,
        headerName: field.theme_name,
        flex: 3,
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        renderCell: (
          params: GridRenderCellParams<
            { status: string; label: string },
            CultivationBonusPointsPerThemeOutputItem,
            ReactNode
          >,
        ) =>
          params?.value &&
          (params.value.status ? (
            <Box
              bgcolor={
                params.value.status === 'not_compliant' ? appColors.error12 : appColors.success12
              }
              borderRadius={1}
              paddingX={1.25}
              paddingY={0.75}
            >
              <Typography variant="body2">{params.value.label}</Typography>
            </Box>
          ) : (
            <Typography variant="body2">{params.value.label}</Typography>
          )),
      });
      return {
        ...acc,
        [field.theme_slug]: field,
      };
    }, {});

    return {
      uuid: r.organization_cultivation_id,
      organization_cultivation: r.organization_cultivation_details,
      all_themes_point_sum: r.all_themes_point_sum,
      ...columnList,
    };
  });

  const validRows = bonusPointRows
    ?.slice()
    ?.sort((a, b) =>
      a.organization_cultivation?.product_name.localeCompare(
        b.organization_cultivation?.product_name,
      ),
    );

  bonusPointsTableColumn.push({
    field: 'all_themes_point_sum',
    headerName: assessmentReportStepStrings('allThemes'),
    flex: 2,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    headerClassName: 'customBackground',
    cellClassName: 'customBackground',
  });

  const malusPointsTableColumn: GridEnrichedColDef[] = [
    {
      field: 'organization_cultivation_details',
      headerName: '',
      flex: 1,
      sortable: false,
      renderCell: (
        params: GridRenderCellParams<
          MalusCompensationCultivationDetails,
          MalusComplensationPerCultivationOutputItem,
          ReactNode
        >,
      ) => (
        <Typography variant="body2">
          {getCultivationDisplayNameForControlPoint(params.value, isFarmManager || isFarmEmployee)}
        </Typography>
      ),
    },
    {
      field: 'total_malus_points',
      align: 'center',
      width: 220,
      sortable: false,
      renderHeader: () => (
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
        >
          <Typography
            letterSpacing={0.17}
            paddingX={1.25}
            textAlign="center"
            textTransform="initial"
            variant="button"
            whiteSpace="normal"
          >
            {assessmentReportStepStrings('totalMalusPoints')}
          </Typography>
          <AddRounded sx={{ marginX: 1 }} />
        </Box>
      ),
    },
    {
      field: 'bio_soil_crop_theme_bonus_points',
      width: 256,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (
        params: GridRenderCellParams<
          { status: string; label: string },
          MalusCompensationCultivationDetails,
          ReactNode
        >,
      ) => (
        <Box
          bgcolor={
            params.value.status === 'not_compliant' ? appColors.error12 : appColors.success12
          }
          borderRadius={1}
          paddingX={1.25}
          paddingY={0.75}
        >
          <Typography variant="body2">{params.value.label}</Typography>
        </Box>
      ),
      renderHeader: () => (
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
        >
          <Typography
            component="span"
            letterSpacing={0.17}
            paddingX={1.25}
            textAlign="center"
            textTransform="initial"
            variant="button"
            whiteSpace="normal"
          >
            {assessmentReportStepStrings('bonusPointsForOptionalMeasures')}
            <Typography
              fontSize={10}
              fontWeight={500}
              lineHeight={2.4}
              variant="body2"
            >
              {assessmentReportStepStrings('bonusPointsForOptionalMeasuresDetail')}
              <Typography
                color="error"
                component="span"
                fontSize={10}
                fontWeight={500}
                lineHeight={2.4}
                variant="body2"
              >
                *
              </Typography>
            </Typography>
          </Typography>
          <AddRounded sx={{ marginX: 1 }} />
        </Box>
      ),
    },
    {
      field: 'other_theme_bonus_points',
      width: 224,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderHeader: () => (
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
        >
          <Typography
            component="span"
            letterSpacing={0.17}
            paddingX={1.25}
            textAlign="center"
            textTransform="initial"
            variant="button"
            whiteSpace="normal"
          >
            {assessmentReportStepStrings('bonusPointsFromOtherCategories')}
            <Typography
              component="span"
              fontSize={10}
              fontWeight={500}
              lineHeight={2.4}
              variant="body2"
            >
              {assessmentReportStepStrings('bonusPointsFromOtherCategoriesDetail')}
            </Typography>
          </Typography>
          <HorizontalRuleRounded sx={{ marginX: 1 }} />
        </Box>
      ),
    },
    {
      field: 'minimum_extra_bonus_points_to_score',
      width: 220,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderHeader: () => (
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
        >
          <Typography
            letterSpacing={0.17}
            paddingX={1.25}
            textAlign="center"
            textTransform="initial"
            variant="button"
            whiteSpace="normal"
          >
            {assessmentReportStepStrings('minimalBonusPointsToScore')}
          </Typography>
          <DragHandleRounded sx={{ marginX: 1 }} />
        </Box>
      ),
    },
    {
      field: 'current_balance',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (
        params: GridRenderCellParams<
          { status: string; label: string },
          MalusCompensationCultivationDetails,
          ReactNode
        >,
      ) => (
        <Box
          bgcolor={
            params.value.status === 'not_compliant' ? appColors.error12 : appColors.success12
          }
          borderRadius={1}
          paddingX={1.25}
          paddingY={0.75}
        >
          <Typography variant="body2">{params.value.label}</Typography>
        </Box>
      ),
      renderHeader: () => (
        <Typography
          component="span"
          letterSpacing={0.17}
          textAlign="center"
          textTransform="initial"
          variant="button"
          whiteSpace="normal"
        >
          {assessmentReportStepStrings('currentBalance')}
          <Typography
            color="error"
            component="span"
            letterSpacing={0.17}
            textAlign="center"
            textTransform="initial"
            variant="button"
          >
            *
          </Typography>
        </Typography>
      ),
    },
  ];

  const nonCompliantData = assessmentNonCompliantControlPointListResponse?.filter(
    (controlPoint) =>
      (assessmentControlPointAnswerList || []).filter(
        (item) => item.control_point_id === controlPoint?.control_point_id,
      )?.length > 0,
  );

  const rowItem = (index: number, controlPoint: NonCompliantControlPointListOutputItem) => (
    <ControlPoint
      key={index}
      // TODOHasan: fix type error
      // @ts-expect-error
      assessmentControlPoint={controlPoint}
      assessmentControlPointAnswer={(assessmentControlPointAnswerList || []).filter(
        (item) => item.control_point_id === controlPoint?.control_point_id,
      )}
      includeOnlyNonCompliantCultivations
      isDisableAnswer
      isDisableNonConformity
      isHideBreadcrumbs
      isHideComments
    />
  );

  const handleChangeTab = (_evt: React.SyntheticEvent, currentId: string) => {
    setActiveTabId(currentId);
    setIsExpanded(true);
  };

  useEffect(() => {
    // Added to make the first tab active if the last selected standard is removed.
    if (sortedColumns?.length > 0 && !sortedColumns.find((c) => c.id === activeTabId)) {
      setActiveTabId(sortedColumns[0].id);
    }
  }, [activeTabId, sortedColumns]);

  const navigateToNextStep = useCallback(() => {
    if (props.isFinalTask) {
      if (isNavigateToDashboard) {
        navigate(ROUTE_PATH_DASHBOARD);
      } else {
        props.openFinishDialog();
      }
    } else {
      setIsNextTaskLoading(true);
      props.moveToNextTask();
    }

    // TODOHasan: Do not add props as a dependency. Find a correct way.
  }, [isNavigateToDashboard, navigate]);

  const navigateToPreviousStep = () => {
    setIsPreviousTaskLoading(true);
    props.moveToPreviousTask();
  };

  const renderComplianceReportItem = ({
    getText,
    id,
    isFirstItem,
    status,
    tooltipText,
    label,
  }: ComplianceRenderItem) => (
    <Box
      key={id}
      alignItems="center"
      display="flex"
      flex={1}
      justifyContent="space-between"
      paddingY={1}
    >
      <Box display="flex">
        <Typography
          component="span"
          paddingY="9px"
          variant="overline"
        >
          {getText()}
        </Typography>
        {tooltipText && (
          <MobileCompatibleTooltip
            sx={{ alignSelf: 'center', ml: 1 }}
            title={tooltipText}
          >
            <InfoRounded
              color="secondary"
              fontSize="small"
            />
          </MobileCompatibleTooltip>
        )}
      </Box>
      <Box
        alignItems="center"
        bgcolor={status === 'not_compliant' ? appColors.error12 : appColors.success12}
        borderRadius={2}
        display="flex"
        height={32}
        paddingX={1}
      >
        <Typography
          fontSize={isFirstItem ? 14 : 18}
          fontWeight={400}
          textTransform="uppercase"
          variant={isFirstItem ? 'overline' : 'h3'}
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );

  const renderFooter = () => (
    <Box>
      <Toolbar />
    </Box>
  );

  const getComplianceBoldText = (boldText: string) => (
    <>
      {assessmentReportStepStrings('numberOf')}
      <Typography
        fontWeight={500}
        variant="overline"
      >
        {` ${boldText} `}
      </Typography>
      {assessmentReportStepStrings('requirements')}
    </>
  );

  const renderExportButton = () =>
    !isReviewer &&
    !isCBManager && (
      <Box
        alignItems="center"
        display="flex"
        flex={1}
        justifyContent="space-between"
        maxWidth="lg"
      >
        <Typography
          fontSize={15}
          letterSpacing={0.46}
          lineHeight={1.734}
          variant="button"
        >
          {assessmentReportStepStrings('documentsExport')}
        </Typography>
        <ButtonGroup
          ref={anchorRef}
          size="small"
          variant="outlined"
        >
          <Button
            disabled={isPDFGenerationLoading}
            endIcon={
              isPDFGenerationLoading ? (
                <CircularProgress
                  size={16}
                  sx={{ color: appColors.black26, ml: 0.25, mr: 0.75 }}
                />
              ) : undefined
            }
            onClick={handleClick}
            size="small"
            variant="outlined"
          >
            {isPDFGenerationLoading
              ? assessmentReportStepStrings('preparingDocuments')
              : downloadOptions[selectedIndex]}
          </Button>
          <Button
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="menu"
            disabled={isPDFGenerationLoading}
            onClick={handleToggle}
            size="small"
          >
            <ArrowDropDownRounded />
          </Button>
        </ButtonGroup>
      </Box>
    );

  // Share with Auditor Conditions
  const { data: organizationClientIdentifiers } = useGetOrganizationClientIdentifiersListQuery(
    tid,
    { skip: !tid },
  );
  const { data: certificationBodies } = useGetCertificationBodiesListQuery(tid, { skip: !tid });
  const certificationBodyId = selectedStandardId
    ? organizationClientIdentifiers?.find((o) =>
        o.standards.some((s) => s.uuid === selectedStandardId),
      )?.certification_body.uuid
    : '';
  const certificationBody = certificationBodies?.find((cb) => cb.uuid === certificationBodyId);
  const selectedModule = moduleList?.find((moduleItem) => moduleItem.uuid === activeTabId);

  const renderShareAssessmentWithAuditorButton = () =>
    isFarmManager &&
    !selectedModule?.uses_malus_points &&
    certificationBody?.document_share_email && (
      <Box
        alignItems="center"
        display="flex"
        flex={1}
        justifyContent="space-between"
        maxWidth="lg"
      >
        <Typography
          fontSize={15}
          letterSpacing={0.46}
          lineHeight={1.734}
          variant="button"
        >
          {assessmentReportStepStrings('sharingAssessmentWithAuditor')}
        </Typography>
        <Button
          disabled={isPDFGenerationLoading}
          onClick={() => {
            openShareDialog({
              assessmentId,
              assessmentName: assessmentDetail?.name,
              standardName: assessmentBaseModules?.find((standard) => standard.uuid === activeTabId)
                ?.name,
              standardId: selectedStandardId,
            });
          }}
          size="small"
          startIcon={<ShareOutlined />}
          variant="outlined"
        >
          {commonStrings('share')}
        </Button>
      </Box>
    );

  const [auditDuration, setAuditDuration] = useState(
    assessmentDetail?.audit_duration
      ? minutesToTimeDurationString(assessmentDetail.audit_duration)
      : null,
  );
  const [updateAssessmentAuditDuration] = useUpdateAssessmentAuditDurationMutation();

  const handleTimeTrackingChange = (e: { target: { value: string } }) => {
    const { value } = e.target;
    setAuditDuration(reformatTimeInputString(value));
  };

  const handleMutateAuditDuration = () => {
    const totalMinutes = timeDurationStringToMinutes(auditDuration);

    updateAssessmentAuditDuration({
      tid,
      id: assessmentId,
      body: {
        audit_duration: totalMinutes,
      },
    });
  };

  const renderInspectionTimeTracking = () =>
    (isCBManager || isAuditor || isReviewer) && (
      <>
        <Typography
          fontSize={15}
          letterSpacing={0.46}
          lineHeight={1.734}
          variant="button"
        >
          {assessmentReportStepStrings('inspectionTimeTracking')}
        </Typography>
        <Paper sx={{ px: 2, maxWidth: 'lg' }}>
          <Box
            alignItems="center"
            display="flex"
            flex={1}
            justifyContent="space-between"
            paddingY={1}
          >
            <Typography
              component="span"
              paddingY="9px"
              variant="overline"
            >
              {assessmentReportStepStrings('totalAuditDuration')}
            </Typography>
            <TextField
              disabled={!isAssessmentAuthor}
              onBlur={handleMutateAuditDuration}
              onChange={handleTimeTrackingChange}
              placeholder={assessmentReportStepStrings('placeholderHHMM')}
              size="small"
              sx={{ width: 80 }}
              value={auditDuration}
            />
          </Box>
        </Paper>
      </>
    );

  const renderCriticalityData = () =>
    assessmentComplianceReportListResponse?.compliance_indication &&
    assessmentComplianceReportListResponse?.criticality_data && (
      <>
        <Typography
          fontSize={15}
          letterSpacing={0.46}
          lineHeight={1.734}
          variant="button"
        >
          {assessmentReportStepStrings('complianceReport')}
        </Typography>
        <Paper sx={{ px: 2, maxWidth: 'lg' }}>
          {assessmentComplianceReportListResponse.criticality_data?.map((criticalityData, ind) =>
            renderComplianceReportItem({
              id: `${criticalityData.criticality_id}_${ind}`,
              isFirstItem: false,
              label: criticalityData.label.toString(),
              status: criticalityData.status,
              getText: () => getComplianceBoldText(criticalityData?.text),
            }),
          )}
        </Paper>
      </>
    );

  const renderMalusBonusPointsTable = () =>
    validRows?.length > 0 &&
    assessmentMalusPointsList?.length > 0 && (
      <>
        <Typography
          fontSize={15}
          letterSpacing={0.46}
          lineHeight={1.734}
          paddingTop={2}
          variant="button"
        >
          {assessmentReportStepStrings('malusBonusPoints')}
        </Typography>
        <Box
          sx={{
            '& .customBackground': {
              backgroundColor: appColors.black4,
            },
          }}
          width="100%"
        >
          <MobileCompatibleDataGrid
            autoHeight
            columns={bonusPointsTableColumn}
            disableColumnMenu
            hideFooter
            hideToolbar
            rows={validRows}
            sx={{ backgroundColor: appColors.white }}
          />
        </Box>
        <Box>
          <MobileCompatibleDataGrid
            autoHeight
            columns={malusPointsTableColumn}
            disableColumnMenu
            getRowId={(row) => row.organization_cultivation_id}
            headerHeight={96}
            hideFooter
            hideToolbar
            rows={assessmentMalusPointsList}
            sx={{
              backgroundColor: appColors.white,
              '& .MuiDataGrid-columnHeaderTitle': {
                whiteSpace: 'normal',
                lineHeight: 'normal',
                textAlign: 'center',
              },
              '& .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '& .MuiDataGrid-columnHeader': {
                paddingX: 0,
              },
            }}
          />
          <Typography
            color="error"
            component="span"
            marginTop={0.5}
            variant="body2"
          >
            *
            <Typography
              color="textPrimary"
              component="span"
              variant="body2"
            >
              {assessmentReportStepStrings('tableColorDesc')}
            </Typography>
          </Typography>
        </Box>
      </>
    );

  const renderNonCompliantList = () =>
    nonCompliantData?.length > 0 && (
      <Accordion
        disableGutters
        elevation={0}
        expanded={isExpanded}
        onChange={() => setIsExpanded(!isExpanded)}
        square
        sx={{
          '&:before': {
            display: 'none',
          },
          backgroundColor: 'transparent',
        }}
      >
        <AccordionSummary
          expandIcon={<KeyboardArrowDownRounded />}
          sx={{
            maxWidth: 300,
            '&&&': {
              pl: 0,
              justifyContent: 'left',
            },
            '& .MuiAccordionSummary-content': {
              flexGrow: 0,
            },
          }}
        >
          <Typography
            fontSize={15}
            letterSpacing={0.46}
            lineHeight={1.734}
            variant="button"
          >
            {assessmentReportStepStrings('listOfNonCompliances')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            '& .MuiTypography-root': {
              letterSpacing: 0.15,
            },
            paddingX: 0,
          }}
        >
          <Virtuoso
            components={{ Footer: renderFooter }}
            data={nonCompliantData}
            itemContent={rowItem}
            useWindowScroll
          />
        </AccordionDetails>
      </Accordion>
    );

  return (
    <ControlPointMasterDataProvider>
      <Container
        maxWidth={maxWidth}
        sx={{ '&&&': { paddingX: 2 } }}
      >
        <ColumnHeaderBlock
          activeTabId={activeTabId}
          columns={sortedColumns}
          handleChangeTab={handleChangeTab}
          moduleList={assessmentBaseModules}
        />
        <Stack
          gap={3}
          marginTop={3}
        >
          {isLoading ? (
            <LoadingArea
              hasAgriplaceLoadingAnimation
              size={72}
              sx={{ mt: '25vh' }}
            />
          ) : (
            <>
              {renderExportButton()}
              {renderShareAssessmentWithAuditorButton()}
              {renderInspectionTimeTracking()}
              {renderCriticalityData()}
              {renderMalusBonusPointsTable()}
              {renderNonCompliantList()}
              {!isExpanded && renderFooter()}
            </>
          )}
        </Stack>
        <Popper
          anchorEl={anchorRef.current}
          disablePortal
          open={open}
          role={undefined}
          sx={{
            zIndex: 1,
          }}
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem
                    id="split-button-menu"
                  >
                    {downloadOptions.map((option, index) => (
                      <MenuItem
                        key={option}
                        onClick={(event) => handleMenuItemClick(event, index)}
                        selected={index === selectedIndex}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <AssessmentShareWithAuditorDialog
          isOpen={isShareDialogOpen}
          onClose={closeShareDialog}
          {...shareDialogProps}
        />
        <AssessmentExportResultDialog
          isOpen={isExportDialogOpen}
          onClose={closeExportDialog}
          {...exportDialogProps}
        />
      </Container>
      <AppBar
        position="fixed"
        sx={{ top: 'auto', bottom: 0 }}
      >
        <Container maxWidth={maxWidth}>
          <AssessmentActionBarButtons
            actionButtonLabel={actionButtonLabel}
            containerStyle={{ justifyContent: 'right' }}
            isDisabled={isPreviousTaskLoading || isNextTaskLoading}
            isFinalTask={props.isFinalTask}
            isFirstTask={props.isFirstTask}
            isNextButtonLoading={isNextTaskLoading}
            isPreviousButtonLoading={isPreviousTaskLoading}
            onNext={navigateToNextStep}
            onPrevious={navigateToPreviousStep}
          />
        </Container>
      </AppBar>
    </ControlPointMasterDataProvider>
  );
};
