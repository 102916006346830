import { Cancel, Clear } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  type OutlinedSelectProps,
  Select,
  type SelectChangeEvent,
  type SelectProps,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { type CSSProperties, useEffect, useState } from 'react';
import { appColors } from 'src/theme';
import { type LabelValuePair } from 'src/types/LabelValuePair';

interface SelectFieldProps
  extends Omit<SelectProps & OutlinedSelectProps, 'defaultValue' | 'onChange'> {
  id: string;
  label: string;
  onChange: (value: string[]) => void;
  onCloseMenu: (value: string[]) => void;
  options: LabelValuePair[];
  showClearAll: boolean;
  value: string[];
  isLoading?: boolean;
  menuStyle?: CSSProperties;
}

/**
 * This component mates a Formik instance (created via the useFormik hook) with a MUI (selectable)
 * TextField.
 */
export const SelectField = ({
  disabled,
  id,
  label,
  menuStyle,
  multiple,
  onCloseMenu,
  onChange,
  options,
  size,
  value,
  isLoading = false,
  showClearAll = false,
  ...restProps
}: SelectFieldProps) => {
  const [selectedItems, setSelectedItems] = useState(value || []);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    if (!multiple || !isMenuOpen) {
      setSelectedItems(value);
    }
  }, [multiple, isMenuOpen, value]);
  const onDeleteItem = (val: string) => {
    setSelectedItems(selectedItems.filter((item) => item !== val));
    onChange?.(selectedItems.filter((item) => item !== val));
    onCloseMenu?.(selectedItems.filter((item) => item !== val));
  };

  const onDeleteAll = () => {
    setSelectedItems([]);
    onChange?.([]);
    onCloseMenu?.([]);
  };

  const handleOpen = () => {
    setIsMenuOpen(true);
  };

  const handleClose = () => {
    setIsMenuOpen(false);
    onCloseMenu?.(selectedItems);
  };

  const onChangeItem = (evt: SelectChangeEvent<string | string[]>) => {
    const val = multiple ? (evt.target.value as string[]) : [evt.target.value as string];
    setSelectedItems(val);
    onChange?.(val);
  };

  const hasValue = selectedItems.filter((item) => item !== '').length > 0;
  let selectedval;
  if (multiple) {
    selectedval = selectedItems || [];
  } else {
    selectedval = selectedItems?.[0] || '';
  }

  return isLoading ? (
    <Box
      border={1}
      borderColor={appColors.border}
      borderRadius={1}
      paddingX="14px"
      paddingY="15px"
      width="100%"
    >
      <Skeleton
        animation="wave"
        variant="text"
        width="33%"
      />
    </Box>
  ) : (
    <FormControl
      focused={!!value && value?.length > 0}
      size={size}
      sx={{ width: '100%' }}
    >
      <InputLabel disabled={isLoading || disabled}>{label}</InputLabel>
      <Select
        {...restProps}
        disabled={isLoading || disabled}
        endAdornment={
          showClearAll && (
            <IconButton
              onClick={onDeleteAll}
              sx={{ display: hasValue ? 'flex' : 'none', mr: hasValue ? 1.25 : 0 }}
            >
              <Clear fontSize="small" />
            </IconButton>
          )
        }
        input={<OutlinedInput label={label} />}
        MenuProps={{
          autoFocus: false,
          sx: {
            '& .MuiListItemText-root': {
              overflowWrap: 'break-word',
              whiteSpace: 'normal',
              textOverflow: 'clip',
            },
          },
          PaperProps: {
            style: {
              ...menuStyle,
              width: '40%',
              maxHeight: 54 * 6 + 16,
            },
          },
        }}
        multiple={multiple}
        name={id}
        onChange={onChangeItem}
        onClose={handleClose}
        onOpen={handleOpen}
        placeholder={label}
        renderValue={(selected: string | string[]) => (
          <Stack
            direction="row"
            flexWrap="wrap"
            gap={1}
          >
            {multiple && typeof selected === 'object' ? (
              selected.map(
                (val) =>
                  val && (
                    <Chip
                      key={val}
                      deleteIcon={<Cancel onMouseDown={(event) => event.stopPropagation()} />}
                      disabled={isLoading || disabled}
                      label={options.find((item) => item.value === val)?.label}
                      onDelete={() => {
                        onDeleteItem(val);
                      }}
                      sx={{
                        backgroundColor: 'primary.contrastText',
                        border: 1,
                        borderColor: appColors.black26,
                        height: '100%',
                        '& .MuiChip-label': {
                          overflowWrap: 'break-word',
                          whiteSpace: 'normal',
                          textOverflow: 'clip',
                        },
                      }}
                    />
                  ),
              )
            ) : (
              <Typography
                textOverflow="clip"
                variant="body1"
                whiteSpace="normal"
              >
                {options.find((item) => item.value === selected)?.label}
              </Typography>
            )}
          </Stack>
        )}
        size={size}
        value={selectedval}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
          >
            {multiple && <Checkbox checked={selectedItems.includes(option.value)} />}
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
