import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Stack, Toolbar } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { FormikPasswordTextField } from 'src/components/formikcomponents/FormikPasswordTextField';
import { useMutationFeedback } from 'src/hooks';
import { changePasswordFormStrings, commonStrings } from 'src/languages/en-UK';
import { useChangePasswordMutation } from 'src/services/farmApi';
import { PASSWORD_REG_EX } from 'src/settings';
import { canSubmitFormik } from 'src/utils/canSubmitFormik';
import * as Yup from 'yup';

export interface ChangePasswordFormProps {
  onCancel: () => void;
  onSuccess: () => void;
}

export const ChangePasswordForm = ({
  onCancel,
  onSuccess,
}: ChangePasswordFormProps): React.JSX.Element => {
  // Form
  const [isChangePasswordPending, setIsChangePasswordPending] = useState(false);
  const [changePassword, changePasswordResult] = useChangePasswordMutation();
  let errorMsg = changePasswordFormStrings('changePasswordFail');
  if (changePasswordResult?.error && 'data' in changePasswordResult.error) {
    errorMsg = (changePasswordResult.error?.data as { code: string; message: string })?.message;
  }
  useMutationFeedback({
    onSuccess,
    result: changePasswordResult,
    successMessage: changePasswordFormStrings('changePasswordSuccess'),
    errorMessage: errorMsg,
  });

  const formik = useFormik({
    initialValues: {
      passwordOld: '',
      passwordNew: '',
      passwordRepeat: '',
    },
    validationSchema: Yup.object().shape({
      passwordOld: Yup.string().required(commonStrings('required')),
      passwordNew: Yup.string()
        .required(commonStrings('required'))
        .matches(PASSWORD_REG_EX, commonStrings('newPasswordRule')),
      passwordRepeat: Yup.string()
        .required(commonStrings('required'))
        .oneOf([Yup.ref('passwordNew'), null], commonStrings('passwordsNotMatch')),
    }),
    onSubmit: async (values) => {
      const { passwordOld, passwordNew } = values;
      setIsChangePasswordPending(true);
      await changePassword({ passwordOld, passwordNew });
      setIsChangePasswordPending(false);
    },
  });

  // TODOHasan: Implement unsaved changes prompt
  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <Stack
        paddingTop={1}
        spacing={2}
      >
        <FormikPasswordTextField
          formik={formik}
          id="passwordOld"
          label={changePasswordFormStrings('currentPassword')}
          required
        />
        <FormikPasswordTextField
          formik={formik}
          id="passwordNew"
          label={commonStrings('newPassword')}
          required
        />
        <FormikPasswordTextField
          formik={formik}
          id="passwordRepeat"
          label={changePasswordFormStrings('confirmNewPassword')}
          required
        />
      </Stack>
      <Toolbar
        disableGutters
        sx={{ justifyContent: 'flex-end', mt: 2, gap: 2 }}
        variant="dense"
      >
        <Button
          onClick={onCancel}
          variant="outlined"
        >
          {commonStrings('cancel')}
        </Button>
        <LoadingButton
          disabled={!canSubmitFormik(formik, true)}
          loading={isChangePasswordPending}
          type="submit"
          variant="contained"
        >
          {commonStrings('change')}
        </LoadingButton>
      </Toolbar>
    </form>
  );
};
