import { CloseRounded } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { assessmentShareWithAuditorResultDialogStrings, commonStrings } from 'src/languages/en-UK';

export interface AssessmentShareWithAuditorResultDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AssessmentShareWithAuditorResultDialog = ({
  onClose,
  isOpen = false,
}: AssessmentShareWithAuditorResultDialogProps): React.JSX.Element => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      fullWidth
      onClose={handleClose}
      open={isOpen}
    >
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          padding: 1.5,
        }}
      >
        <CloseRounded />
      </IconButton>
      <DialogTitle>
        {assessmentShareWithAuditorResultDialogStrings('assessmentSharingIsInProgress')}
      </DialogTitle>
      <DialogContent>
        <Typography
          marginBottom={3}
          variant="body1"
        >
          {assessmentShareWithAuditorResultDialogStrings('youWillReceiveEmailNotification')}
        </Typography>
        <Typography
          marginBottom={1}
          variant="body1"
        >
          {assessmentShareWithAuditorResultDialogStrings('changesWillNotBeReflected')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          size="large"
          variant="outlined"
        >
          {commonStrings('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
