import { Box, Paper, Stack, Toolbar, Typography } from '@mui/material';
import { groupBy, isEqual } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { GroupedVirtuoso, type GroupedVirtuosoHandle } from 'react-virtuoso';
import { AssessmentEvidenceCategory } from 'src/components/AssessmentEvidenceCategory';
import { LoadingArea } from 'src/components/common';
import {
  ControlPointGuidanceDialog,
  type ControlPointGuidanceDialogProps,
} from 'src/components/dialogs/ControlPointGuidanceDialog';
import {
  EvidenceFormsDialog,
  type EvidenceFormsDialogProps,
} from 'src/components/dialogs/EvidenceFormsDialog';
import {
  type EvidenceReviewDialogProps,
  EvidenceReviewDrawer,
} from 'src/components/drawers/EvidenceReviewDrawer';
import { EvidenceExpandableStackItem } from 'src/components/EvidenceExpandableStack/EvidenceExpandableStackItem';
import { useDialogState } from 'src/hooks';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import { type TransformedEvidenceListOutputItem } from 'src/services/farmApi';

import {
  type CategoryData,
  useGetAssessmentCriticalityCategories,
  useLevel3CategoryQueue,
} from './AssessmentEvidenceStep.hooks';

type AssessmentEvidenceStepTabContentProps = {
  assessmentAgriplaceCriticalityIds: string[];
  criticalityId: string;
  description: string;
};

export const AssessmentEvidenceStepTabContent = ({
  assessmentAgriplaceCriticalityIds,
  criticalityId,
  description,
}: AssessmentEvidenceStepTabContentProps): React.JSX.Element => {
  const [assessmentId] = useContext(AssessmentPageContext);
  const virtuosoRef = useRef<GroupedVirtuosoHandle>(null);

  const {
    assessmentCategories: assessmentLevel3Categories,
    isAssessmentCategoriesLoading: isAssessmentLevel3CategoriesLoading,
  } = useGetAssessmentCriticalityCategories(assessmentId, 3, criticalityId);

  const [currentEvidenceItem, setCurrentEvidenceItem] =
    useState<TransformedEvidenceListOutputItem | null>(null);
  const [selectedLevel3Category, setSelectedLevel3Category] = useState<CategoryData | null>();
  const {
    level3CategoryQueue,
    nextEvidenceItem,
    resetLevel3CategoryQueue,
    setEvidenceItemIndex,
    setEvidenceTypeIndex,
  } = useLevel3CategoryQueue();
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);

  // DIALOGS
  const [isReviewDrawerOpen, openReviewDrawer, closeReviewDrawer, evidenceReviewDialogProps] =
    useDialogState<EvidenceReviewDialogProps>();

  const [isGuidanceDialogOpen, openGuidanceDialog, closeGuidanceDialog, guidanceDialogProps] =
    useDialogState<ControlPointGuidanceDialogProps>();

  const [
    isEvidenceFormsDialogOpen,
    openEvidenceFormsDialog,
    closeEvidenceFormsDialog,
    evidenceFormsDialogProps,
  ] = useDialogState<EvidenceFormsDialogProps>();

  useEffect(() => {
    if (selectedLevel3Category === undefined && assessmentLevel3Categories?.length > 0) {
      setSelectedLevel3Category(assessmentLevel3Categories[0]);
    }
  }, [assessmentLevel3Categories, selectedLevel3Category]);

  const calculateItemIsFocused = (ind: number) => {
    const itemElement = document.getElementById(
      `${assessmentLevel3Categories[ind].uuid}_accordion`,
    );
    const bounding = itemElement?.getBoundingClientRect();
    if (
      !(
        bounding &&
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
    ) {
      // Item is not in view, scroll to the item
      virtuosoRef.current?.scrollToIndex?.({
        index: ind - 1 >= 0 ? ind - 1 : ind,
        align: 'start',
        behavior: 'auto',
      });
    }
  };

  // Use Grouped List for third level categories or modules

  const groupedControlPoints = groupBy(
    assessmentLevel3Categories,
    (controlPoint) => controlPoint.parentCategoryId,
  );

  const groupCounts = Object.values(groupedControlPoints).map(
    (controlPoints) => controlPoints.length,
  );

  const groups = Object.keys(groupedControlPoints);

  const renderGroupContent = (index: number) => (
    <Box
      alignItems="center"
      display="flex"
      flex={1}
      paddingBottom={1}
      paddingTop={5}
    >
      <Box
        bgcolor="divider"
        flex={1}
        height="1px"
      />
      <Typography
        paddingX={0.5}
        variant="overline"
      >
        {groupedControlPoints[groups[index]][0]?.parentCategoryName}
      </Typography>
      <Box
        bgcolor="divider"
        flex={1}
        height="1px"
      />
    </Box>
  );

  const renderItemContent = (index: number) => (
    <EvidenceExpandableStackItem
      criticalityId={criticalityId}
      expandedId={selectedLevel3Category?.uuid}
      id={assessmentLevel3Categories[index]?.uuid}
      index={index}
      item={{
        title:
          assessmentLevel3Categories[index]?.alternativeName ||
          assessmentLevel3Categories[index]?.name,
        details: (
          <AssessmentEvidenceCategory
            assessmentAgriplaceCriticalityIds={assessmentAgriplaceCriticalityIds}
            category={assessmentLevel3Categories[index]}
            criticalityId={criticalityId}
            index={index}
            level3CategoryQueue={level3CategoryQueue}
            openEvidenceFormsDialog={openEvidenceFormsDialog}
            openGuidanceDialog={openGuidanceDialog}
            openReviewDrawer={openReviewDrawer}
            setCurrentEvidenceItem={setCurrentEvidenceItem}
            setEvidenceItemIndex={setEvidenceItemIndex}
            setEvidenceTypeIndex={setEvidenceTypeIndex}
            setIsNextButtonDisabled={setIsNextButtonDisabled}
            virtuosoRef={virtuosoRef}
          />
        ),
      }}
      onChangeIndex={(ind) => {
        resetLevel3CategoryQueue();
        // Check if expanded row is clicked to collapse it.
        if (isEqual(assessmentLevel3Categories[ind], selectedLevel3Category)) {
          setSelectedLevel3Category(null);
        } else {
          setSelectedLevel3Category(assessmentLevel3Categories[ind]);
          setTimeout(() => {
            calculateItemIsFocused(ind);
          }, 500);
        }
      }}
    />
  );

  const renderFooter = () => (
    <Box paddingBottom={2}>
      <Toolbar />
    </Box>
  );

  return isAssessmentLevel3CategoriesLoading ? (
    <Stack spacing={1}>
      <Paper sx={{ p: 2 }}>
        <LoadingArea height={200} />
      </Paper>
      <Paper sx={{ p: 2 }}>
        <LoadingArea height={200} />
      </Paper>
      <Paper sx={{ p: 2 }}>
        <LoadingArea height={200} />
      </Paper>
    </Stack>
  ) : (
    <>
      <Paper sx={{ p: 2 }}>
        <Typography variant="body1">{description}</Typography>
      </Paper>
      <GroupedVirtuoso
        key={criticalityId}
        ref={virtuosoRef}
        components={{ Footer: renderFooter }}
        groupContent={renderGroupContent}
        groupCounts={groupCounts}
        itemContent={renderItemContent}
        useWindowScroll
      />
      {currentEvidenceItem && isReviewDrawerOpen && (
        <EvidenceReviewDrawer
          assessmentAgriplaceCriticalityIds={assessmentAgriplaceCriticalityIds}
          categoryId={selectedLevel3Category?.uuid}
          categoryName={selectedLevel3Category?.name}
          evidenceId={currentEvidenceItem.uuid}
          isDisableNextButton={isNextButtonDisabled}
          isOpen={isReviewDrawerOpen}
          onClose={closeReviewDrawer}
          onNext={nextEvidenceItem}
          {...evidenceReviewDialogProps}
        />
      )}
      <EvidenceFormsDialog
        isOpen={isEvidenceFormsDialogOpen}
        onClose={closeEvidenceFormsDialog}
        {...evidenceFormsDialogProps}
      />
      <ControlPointGuidanceDialog
        isOpen={isGuidanceDialogOpen}
        onClose={closeGuidanceDialog}
        {...guidanceDialogProps}
      />
    </>
  );
};
