import {
  type ControlPointDetailOutput,
  type ControlPointListOutputItem,
  type ControlPointsListParams,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { injectQueryParams } from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'control-points';

const controlPointEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getControlPointList: build.query<ControlPointListOutputItem[], ControlPointsListParams>({
      query: (params) => injectQueryParams(`/${RESOURCE}/`, params),
      providesTags: [TagType.ControlPoint],
    }),

    getControlPoint: build.query<ControlPointDetailOutput, string>({
      query: (id) => `/${RESOURCE}/${id}/`,
      providesTags: [TagType.ControlPoint],
    }),
  }),
  overrideExisting: false,
});

export const { useGetControlPointListQuery, useGetControlPointQuery } = controlPointEndpoints;
