import { Box, Skeleton, Typography } from '@mui/material';
import { useControlPointBreadcrumbs } from 'src/hooks';
import { useGetStandardLogoListQuery } from 'src/services/farmApi';

interface ControlPointBreadCrumbsProps {
  controlPointId: string;
  isLoading: boolean;
}

export const ControlPointBreadCrumbs = ({
  controlPointId,
  isLoading = false,
}: ControlPointBreadCrumbsProps): React.JSX.Element => {
  const { data: standardLogos } = useGetStandardLogoListQuery();
  const [controlPointBreadcrumb, isControlPointBreadcrumbsDataLoading] =
    useControlPointBreadcrumbs(controlPointId);
  const matchedLogo = standardLogos?.[controlPointBreadcrumb?.smallLogoId];

  return (
    <Box
      alignItems="center"
      display="flex"
      flex={1}
      flexDirection="row"
      gap={0.5}
    >
      {isControlPointBreadcrumbsDataLoading || isLoading ? (
        <Skeleton
          sx={{ flex: '1' }}
          variant="text"
        />
      ) : (
        <>
          {matchedLogo && (
            <img
              alt={`list item: ${matchedLogo?.name}`}
              src={matchedLogo?.file_object}
              style={{
                height: '20px',
                width: '20px',
              }}
            />
          )}
          <Typography
            color="text.disabled"
            variant="caption"
          >
            {controlPointBreadcrumb?.breadcrumb}
          </Typography>
        </>
      )}
    </Box>
  );
};
