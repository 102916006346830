import { type FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';

interface UseForcedValueFormikInput {
  initialValues: FormikValues;
  // Since it is dynamic, it must be any type.
  validationSchema: Yup.ObjectSchema<any>;
  forcedValues: FormikValues;
  // Since it is dynamic, it must be any type.
  onSubmit: (values: FormikValues) => void | Promise<any>;
}

/**
 * A hook to replace useFormik() when using forced values.
 * @param input - Input options for the hook
 * @returns Formik instance (same as useFormik())
 */
export const useForcedValueFormik = ({
  initialValues: inputValues,
  validationSchema: inputValidationSchema,
  forcedValues,
  onSubmit: handleSubmit,
}: UseForcedValueFormikInput) => {
  const initialValues = { ...inputValues };
  const validationSchema = { ...inputValidationSchema.fields };

  // For each field...
  Object.keys(inputValues).forEach((key) => {
    // If it has a forced value, or is hidden, remove it from the form and validation schema
    if (forcedValues[key] !== undefined || inputValues[key].hidden) {
      if (initialValues[key]) {
        delete initialValues[key];
      }
      if (validationSchema[key]) {
        delete validationSchema[key];
      }
    }
  });

  return useFormik({
    initialValues,
    validationSchema: Yup.object().shape(validationSchema),
    onSubmit: (values) => {
      handleSubmit({
        ...values,
        ...forcedValues,
      });
    },
  });
};
