import LoadingButton from '@mui/lab/LoadingButton';
import { Container, Paper, Stack, Toolbar, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { AppLogo } from 'src/components/common';
import { FormikLanguageField } from 'src/components/formikcomponents/FormikLanguageField';
import { FormikPasswordTextField } from 'src/components/formikcomponents/FormikPasswordTextField';
import { FormikTextField } from 'src/components/formikcomponents/FormikTextField';
import { AuthContext } from 'src/components/PreventAuth';
import { useAppDispatch } from 'src/hooks';
import { changeTemporaryPasswordPageStrings, commonStrings } from 'src/languages/en-UK';
import { useChangeTemporaryPasswordMutation } from 'src/services/farmApi';
import { PASSWORD_REG_EX } from 'src/settings';
import { openSnackbar } from 'src/store/snackbarSlice';
import { canSubmitFormik } from 'src/utils/canSubmitFormik';
import * as Yup from 'yup';

export const ChangeTemporaryPassword = (): React.JSX.Element => {
  const dispatch = useAppDispatch();
  const { user } = useContext(AuthContext);
  const [isChangeTempPasswordPending, setIsChangeTempPasswordPending] = useState(false);
  const [
    changeTemporaryPassword,
    { error: changeTempPasswordError, isSuccess: isChangeTempPasswordSuccess },
  ] = useChangeTemporaryPasswordMutation();

  useEffect(() => {
    if (changeTempPasswordError && 'data' in changeTempPasswordError) {
      const errorData = changeTempPasswordError.data as { code: string; message: string };

      dispatch(
        openSnackbar({
          severity: 'error',
          message: errorData.message,
        }),
      );
    }

    if (isChangeTempPasswordSuccess) {
      dispatch(
        openSnackbar({
          severity: 'success',
          message: changeTemporaryPasswordPageStrings('changeTemporaryPasswordSuccess'),
        }),
      );
    }
  }, [changeTempPasswordError, dispatch, isChangeTempPasswordSuccess]);

  const formik = useFormik({
    initialValues: {
      fullName: '',
      passwordNew: '',
      passwordRepeat: '',
      language: '',
    },
    validationSchema: Yup.object().shape({
      fullName: Yup.string().required(commonStrings('required')),
      passwordNew: Yup.string()
        .required(commonStrings('required'))
        .matches(PASSWORD_REG_EX, commonStrings('newPasswordRule')),
      passwordRepeat: Yup.string()
        .required(commonStrings('required'))
        .oneOf([Yup.ref('passwordNew'), null], commonStrings('passwordsNotMatch')),
    }),
    onSubmit: async (values) => {
      const { fullName, language, passwordNew } = values;
      if (user) {
        setIsChangeTempPasswordPending(true);
        await changeTemporaryPassword({
          fullName,
          language,
          passwordNew,
          user,
        });
        setIsChangeTempPasswordPending(false);
      }
    },
  });

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        justifyContent: 'center',
      }}
    >
      <Paper sx={{ p: 2, my: 4 }}>
        <AppLogo
          large
          showName
          sx={{ pt: 2, pb: 2.5, mx: 'auto' }}
        />
        <Stack spacing={2}>
          <Typography
            align="center"
            variant="h2"
          >
            {changeTemporaryPasswordPageStrings('completeYourProfile')}
          </Typography>
          <form
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <Stack spacing={2}>
              <FormikTextField
                formik={formik}
                id="fullName"
                label={commonStrings('fullName')}
                required
              />
              <FormikPasswordTextField
                formik={formik}
                id="passwordNew"
                label={commonStrings('newPassword')}
                required
              />
              <FormikPasswordTextField
                formik={formik}
                id="passwordRepeat"
                label={changeTemporaryPasswordPageStrings('newPasswordRepeat')}
                required
              />
              <FormikLanguageField
                formik={formik}
                id="language"
                label={commonStrings('languageAsComponent')}
                placeholder={commonStrings('language')}
              />
              <Toolbar
                disableGutters
                sx={{ justifyContent: 'flex-end' }}
                variant="dense"
              >
                <LoadingButton
                  disabled={!canSubmitFormik(formik, true)}
                  fullWidth
                  loading={isChangeTempPasswordPending}
                  type="submit"
                  variant="contained"
                >
                  {changeTemporaryPasswordPageStrings('setUpAccount')}
                </LoadingButton>
              </Toolbar>
            </Stack>
          </form>
        </Stack>
      </Paper>
    </Container>
  );
};
