import { type TransformedOrganizationCultivationListOutputItem } from 'src/services/farmApi';
import { getCultivationDisplayName } from 'src/utils/getCultivationDisplayName';

import { type MatrixRow } from '../types';

/**
 * Converts a cultivation list item to a matrix row.
 * @param cultivation - Cultivation to convert
 * @returns
 */
export const cultivationToMatrixRow = (
  cultivation: TransformedOrganizationCultivationListOutputItem,
  hasPlotName: boolean,
): MatrixRow => ({
  label: getCultivationDisplayName(cultivation, hasPlotName),
  id: cultivation.uuid,
  product: cultivation.product,
  productionTechnique: cultivation.production_technique,
});
