import { ToggleButton, ToggleButtonGroup, useMediaQuery } from '@mui/material';
import { useContext } from 'react';
import { type PossibleAnswerListOutputItem } from 'src/__generated__/InternalApiTypes';

import { ThemeContext } from '../ThemeProvider';

interface ControlPointButtonGroupAnswerProps {
  availablePossibleAnswers: PossibleAnswerListOutputItem[];
  isDisabled: boolean;
  onSelectAnswer: (answers: string[]) => void;
  value: string;
}

export const ControlPointButtonGroupAnswer = ({
  availablePossibleAnswers,
  isDisabled,
  onSelectAnswer,
  value,
}: ControlPointButtonGroupAnswerProps): React.JSX.Element => {
  const { theme } = useContext(ThemeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ToggleButtonGroup
      color="primary"
      exclusive
      fullWidth={isMobile}
      orientation={isMobile ? 'vertical' : 'horizontal'}
      size="small"
      sx={{
        boxShadow:
          '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
      }}
      value={value}
    >
      {availablePossibleAnswers?.map((answer) => (
        <ToggleButton
          key={answer.uuid}
          disabled={isDisabled}
          fullWidth
          onClick={(_evt, val) => {
            onSelectAnswer([val]);
          }}
          sx={{
            paddingX: 2,
            paddingY: 0.984,
            whiteSpace: 'nowrap',
          }}
          value={answer.uuid}
        >
          {answer.main_text}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
