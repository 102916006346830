import { CloseRounded } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import {
  type AssessmentStandardRequestListOutputItem,
  AssessmentStatusEnum,
  RolesEnum,
} from 'src/__generated__/InternalApiTypes';
import { FormikSelectField } from 'src/components/formikcomponents/FormikSelectField';
import { useCurrentUserRoles, useMutationFeedback, useTenantId, useUserAuth } from 'src/hooks';
import { commonStrings, pendingIncomingRequestDialogStrings } from 'src/languages/en-UK';
import {
  useApproveAssessmentStandardRequestMutation,
  useGetAssessmentListQuery,
  useRejectAssessmentStandardRequestMutation,
} from 'src/services/farmApi';
import * as Yup from 'yup';

export interface PendingIncomingRequestDialogProps {
  isOpen: boolean;
  onClose: () => void;
  requestList?: AssessmentStandardRequestListOutputItem;
  uuid?: string;
}

export const PendingIncomingRequestDialog = ({
  onClose,
  requestList,
  isOpen = false,
}: PendingIncomingRequestDialogProps): React.JSX.Element => {
  const tid = useTenantId();
  const currentUser = useUserAuth();
  const currentUserRoles = useCurrentUserRoles();
  const isAuditor = currentUserRoles.includes(RolesEnum.Auditor);
  const { data: assessmentList, isLoading: isAssessmentListLoading } = useGetAssessmentListQuery(
    {
      tid,
      standard_id: requestList?.standard_id ? [requestList.standard_id] : undefined,
      status: [AssessmentStatusEnum.Open, AssessmentStatusEnum.ToBeAudited],
      ...(isAuditor && {
        author_id: currentUser?.userId,
      }),
    },
    { skip: !tid },
  );

  const [rejectRequest, rejectRequestResult] = useRejectAssessmentStandardRequestMutation();
  const [approveRequest, approveRequestResult] = useApproveAssessmentStandardRequestMutation();

  const sortedAssessmentList = assessmentList
    ?.slice()
    ?.sort((a, b) => new Date(b.modified_at).getTime() - new Date(a.modified_at).getTime());

  const standardOptions = (sortedAssessmentList || []).map((standard) => ({
    value: standard.uuid || '',
    label: standard.name,
  }));
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      assessmentId: '',
    },
    validationSchema: Yup.object().shape({
      assessmentId: Yup.string().required(commonStrings('required')),
    }),
    onSubmit: (values) => {
      if (requestList?.uuid && tid) {
        approveRequest({
          tid,
          id: requestList.uuid,
          body: { assessment_id: values.assessmentId },
        });
      }
    },
  });

  const handleDenyAccess = () => {
    if (requestList?.uuid && tid) {
      rejectRequest({
        tid,
        id: requestList.uuid,
        body: { assessment_id: formik?.values?.assessmentId },
      });
    }
  };

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  useMutationFeedback({
    result: rejectRequestResult,
    onSuccess: () => {
      handleClose();
    },
    successMessage: pendingIncomingRequestDialogStrings('requestRejected'),
    errorMessage: pendingIncomingRequestDialogStrings('failedToDenyRequest'),
  });

  useMutationFeedback({
    result: approveRequestResult,
    onSuccess: () => {
      handleClose();
    },
    successMessage: commonStrings('requestApproved'),
    errorMessage: pendingIncomingRequestDialogStrings('failedToApproveRequest'),
  });

  return (
    <Dialog
      fullWidth
      onClose={handleClose}
      open={isOpen}
    >
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          padding: 1.5,
        }}
      >
        <CloseRounded />
      </IconButton>
      <form
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <DialogContent>
          <Stack spacing={2}>
            <Typography
              marginBottom={3}
              variant="h2"
            >
              {pendingIncomingRequestDialogStrings('newIncomingRequest')}
            </Typography>
            {requestList?.requester_name && (
              <Box
                alignItems="center"
                display="flex"
                paddingLeft={0.5}
                paddingY={0.5}
              >
                <Avatar
                  sx={{
                    fontSize: 'auto',
                  }}
                >
                  {requestList.requester_name.substring(0, 1).toUpperCase()}
                </Avatar>
                <Typography
                  marginLeft={0.5}
                  variant="body1"
                >
                  {`${requestList.requester_name} (${requestList.requester_email})`}
                </Typography>
              </Box>
            )}
            <Typography variant="body1">
              {pendingIncomingRequestDialogStrings('incomingRequestDesc')}
            </Typography>
            <FormikSelectField
              formik={formik}
              id="assessmentId"
              label={pendingIncomingRequestDialogStrings('openAssessments')}
              options={standardOptions}
              required
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end', pt: 3 }}>
          <LoadingButton
            color="warning"
            disabled={approveRequestResult?.isLoading}
            loading={rejectRequestResult?.isLoading}
            onClick={handleDenyAccess}
            variant="outlined"
          >
            {pendingIncomingRequestDialogStrings('denyAccess')}
          </LoadingButton>
          <LoadingButton
            disabled={rejectRequestResult?.isLoading}
            loading={isAssessmentListLoading || approveRequestResult?.isLoading}
            type="submit"
            variant="outlined"
          >
            {pendingIncomingRequestDialogStrings('allowAccess')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
