import { ArrowBackRounded, ArrowForwardRounded } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { type SxProps, Toolbar, useMediaQuery } from '@mui/material';
import { useContext } from 'react';
import { assessmentActionBarButtonsStrings, commonStrings } from 'src/languages/en-UK';

import { ThemeContext } from '../ThemeProvider';

interface AssessmentActionBarButtonProps {
  isDisabled: boolean;
  isFinalTask: boolean;
  isFirstTask: boolean;
  isNextButtonLoading: boolean;
  isPreviousButtonLoading: boolean;
  onNext: () => void;
  onPrevious: () => void;
  actionButtonLabel?: string;
  containerStyle?: SxProps;
  hideBackButton?: boolean;
  nextTaskName?: string;
  nextButtonVariant?: 'outlined' | 'contained';
  withoutToolbar?: boolean;
}

export const AssessmentActionBarButtons = ({
  actionButtonLabel,
  containerStyle,
  nextTaskName,
  onNext,
  onPrevious,
  nextButtonVariant = 'contained',
  hideBackButton = false,
  isFinalTask = false,
  isFirstTask = false,
  isNextButtonLoading = false,
  isPreviousButtonLoading = false,
  isDisabled = false,
  withoutToolbar = false,
}: AssessmentActionBarButtonProps): React.JSX.Element => {
  const { theme } = useContext(ThemeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  let actionButtonText = assessmentActionBarButtonsStrings('continue');
  if (nextTaskName && !isMobile) {
    actionButtonText = assessmentActionBarButtonsStrings('continueToNextTask', { nextTaskName });
  }
  if (actionButtonLabel) {
    actionButtonText = actionButtonLabel;
  } else if (isFinalTask) {
    actionButtonText = commonStrings('finish');
  }

  return withoutToolbar ? (
    <>
      {!hideBackButton && (
        <LoadingButton
          disabled={isDisabled}
          loading={isPreviousButtonLoading}
          onClick={onPrevious}
          startIcon={isFirstTask || isMobile ? undefined : <ArrowBackRounded />}
          sx={{ alignContent: 'space-around', mr: '5px' }}
          variant="outlined"
        >
          {isFirstTask ? commonStrings('cancel') : commonStrings('back')}
        </LoadingButton>
      )}
      <LoadingButton
        disabled={isDisabled}
        endIcon={isFinalTask || isMobile ? undefined : <ArrowForwardRounded />}
        loading={isNextButtonLoading}
        onClick={onNext}
        sx={{ alignContent: 'space-around', ml: '5px' }}
        variant={nextButtonVariant}
      >
        {actionButtonText}
      </LoadingButton>
    </>
  ) : (
    <Toolbar
      disableGutters
      sx={containerStyle}
    >
      <LoadingButton
        disabled={isDisabled}
        loading={isPreviousButtonLoading}
        onClick={onPrevious}
        startIcon={isFirstTask || isMobile ? undefined : <ArrowBackRounded />}
        sx={{ alignContent: 'space-around', mr: '5px' }}
        variant="outlined"
      >
        {isFirstTask ? commonStrings('cancel') : commonStrings('back')}
      </LoadingButton>
      <LoadingButton
        disabled={isDisabled}
        endIcon={isFinalTask || isMobile ? undefined : <ArrowForwardRounded />}
        loading={isNextButtonLoading}
        onClick={onNext}
        sx={{ alignContent: 'space-around', ml: '5px' }}
        variant={nextButtonVariant}
      >
        {actionButtonText}
      </LoadingButton>
    </Toolbar>
  );
};
