import { CountryEnum, TopCountryEnum } from 'src/__generated__/InternalApiTypes';
import { useCurrentLanguage, useLocalizedCountryName } from 'src/hooks';

import { FormikSelectField } from '../FormikSelectField';
import { type FormikTextFieldProps } from '../FormikTextField';

type FormikCountryFieldProps = FormikTextFieldProps;

/**
 * This component pre-fills a FormikSelectField with country options translated based on the user's
 * language (via the store).
 */
export const FormikCountryField = ({
  formik,
  id,
  label,
  isLoading = false,
  ...restProps
}: FormikCountryFieldProps) => {
  const language = useCurrentLanguage();
  const localizedCountryName = useLocalizedCountryName();
  const options = Object.keys(CountryEnum).map((code: keyof typeof CountryEnum) => ({
    value: CountryEnum[code],
    label: localizedCountryName.of(code),
  }));
  const topOptions = Object.keys(TopCountryEnum).map((code: keyof typeof TopCountryEnum) => ({
    value: TopCountryEnum[code],
    label: localizedCountryName.of(code),
  }));
  const combinedOptions = [...topOptions, ...options];
  return (
    <FormikSelectField
      disableAutoFocusItem
      dividerIndex={topOptions.length - 1}
      formik={formik}
      id={id}
      isLoading={isLoading || !language}
      label={label}
      options={combinedOptions}
      {...restProps}
    />
  );
};
