import { useGetControlPointProductionTechniqueListQuery } from 'src/services/farmApi';

import { FormikSelectField } from '../FormikSelectField';
import { type FormikTextFieldProps } from '../FormikTextField';

type FormikProductionTechniqueFieldProps = FormikTextFieldProps;

/**
 * This component pre-fills a FormikSelectField with production technique options.
 */
export const FormikProductionTechniqueField = ({
  formik,
  id,
  label,
  isLoading = false,
  ...restProps
}: FormikProductionTechniqueFieldProps) => {
  const { data: productionTechniques, isLoading: isProductionTechniqueLoading } =
    useGetControlPointProductionTechniqueListQuery();
  const options = (productionTechniques || []).map((prodTechnique) => ({
    value: prodTechnique.uuid,
    label: prodTechnique.name,
  }));

  return (
    <FormikSelectField
      formik={formik}
      id={id}
      isLoading={isLoading || isProductionTechniqueLoading}
      label={label}
      options={options}
      {...restProps}
    />
  );
};
