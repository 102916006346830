import { type DynamicObjectType } from 'src/global';

export const populateFormValues = (
  defaultValues: DynamicObjectType,
  values?: DynamicObjectType,
): DynamicObjectType =>
  Object.keys(defaultValues).reduce((acc: DynamicObjectType, key) => {
    // Prevent Formik from being initialized with values we don't care about.
    if (values) {
      // Convert null to empty string
      acc[key] = values[key] === null ? '' : values[key];
    } else {
      acc[key] = defaultValues[key];
    }
    return acc;
  }, {});
