import { type PossibleAnswerSetListOutputItem } from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { TagType } from '../TagType';

const RESOURCE = 'possible-answer-sets';

const possibleAnswerSetsEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPossibleAnswerSetList: build.query<PossibleAnswerSetListOutputItem[], void>({
      query: () => `/${RESOURCE}/`,
      providesTags: [TagType.PossibleAnswerSet, TagType.UserProfile],
    }),
  }),
  overrideExisting: false,
});

export const { useGetPossibleAnswerSetListQuery } = possibleAnswerSetsEndpoints;
