import { AccountTypeEnum } from 'src/__generated__/InternalApiTypes';
import { formikRegisterAccountTypeFieldStrings } from 'src/languages/en-UK';

import { FormikSelectField } from '../FormikSelectField';
import { type FormikTextFieldProps } from '../FormikTextField';

type FormikRegisterAccountTypeFieldProps = FormikTextFieldProps;

/**
 * This component pre-fills a FormikSelectField with register account types options.
 */
export const FormikRegisterAccountTypeField = ({
  formik,
  id,
  label,
  isLoading = false,
  ...restProps
}: FormikRegisterAccountTypeFieldProps) => {
  const options = [
    {
      value: AccountTypeEnum.Farmer,
      label: formikRegisterAccountTypeFieldStrings('farmOwnerAndManageCertification'),
    },
    {
      value: AccountTypeEnum.Auditor,
      label: formikRegisterAccountTypeFieldStrings('auditorAndCarryOutInspection'),
    },
    {
      value: AccountTypeEnum.FarmEmployee,
      label: formikRegisterAccountTypeFieldStrings('farmManagerOrEmployeeAndJoinOrganization'),
    },
  ];
  return (
    <FormikSelectField
      formik={formik}
      id={id}
      isLoading={isLoading}
      label={label}
      options={options}
      {...restProps}
    />
  );
};
