import { type FormikProps, type FormikValues } from 'formik';

export const canSubmitFormik = (
  formikInstance: FormikProps<FormikValues>,
  requireTouched = false,
): boolean => {
  const hasErrors = Object.keys(formikInstance.errors).length > 0;
  const wasTouched = requireTouched ? Object.keys(formikInstance.touched).length > 0 : true;
  return wasTouched && !hasErrors;
};
