import { configureStore } from '@reduxjs/toolkit';
import { farmApi } from 'src/services/farmApi';

import { assessmentConfigReducer } from './assessmentConfig';
import { reducer as snackbarReducer } from './snackbarSlice';

export const setupStore = () =>
  configureStore({
    reducer: {
      snackbar: snackbarReducer,
      assessmentConfig: assessmentConfigReducer,
      [farmApi.reducerPath]: farmApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(farmApi.middleware),
  });
export const store = setupStore();

export type Store = ReturnType<typeof setupStore>;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
