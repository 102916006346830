import { CloseRounded } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { DocumentPreview, imageFileExtensions } from 'src/components/common/DocumentPreview';
import { useAppDispatch, useTenantId } from 'src/hooks';
import { commonStrings } from 'src/languages/en-UK';
import { useGetEvidenceQuery } from 'src/services/farmApi';
import { openSnackbar } from 'src/store/snackbarSlice';
import { downloadFileToLocal, getFileExtension } from 'src/utils/fileUtils';

export interface EvidencePreviewDialogProps {
  evidenceId: string;
  isOpen: boolean;
  onClose: (value: string | undefined) => void;
}

export const EvidencePreviewDialog = ({
  evidenceId,
  onClose,
  isOpen = false,
}: EvidencePreviewDialogProps) => {
  const dispatch = useAppDispatch();
  const tid = useTenantId();
  const [skipPolling, setSkipPolling] = useState(false);
  const { data: evidence, isFetching } = useGetEvidenceQuery(
    { id: evidenceId, tid },
    {
      pollingInterval: 1000,
      skip: skipPolling || !evidenceId || !tid,
    },
  );

  useEffect(() => {
    if (evidence && evidence.uuid === evidenceId) {
      const shouldSkipPolling =
        !evidence?.is_edited_online ||
        (!!evidence?.is_edited_online && !!evidence?.is_saved_by_webhook);

      setSkipPolling(shouldSkipPolling);
    } else {
      setSkipPolling(false);
    }
  }, [evidence, evidenceId]);

  const isLoading = isFetching || !skipPolling;

  let isImage = false;
  if (evidence?.file?.file_object) {
    const fileType = getFileExtension(evidence.file.file_object);
    isImage = imageFileExtensions.includes(fileType);
  }

  const handleClose = () => {
    onClose?.(evidenceId);
  };

  const handleDownload = (): void => {
    if (evidence?.file) {
      downloadFileToLocal(evidence.file, evidence?.name, (msg) =>
        dispatch(openSnackbar({ message: msg, severity: 'error' })),
      );
    }
  };

  return (
    <Dialog
      fullScreen
      onClose={handleClose}
      open={isOpen}
      PaperProps={{
        style: {
          minWidth: '80%',
          maxWidth: '80%',
          minHeight: '90%',
          maxHeight: '90%',
        },
      }}
    >
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          padding: 1.5,
        }}
      >
        <CloseRounded />
      </IconButton>
      <DialogTitle>
        {isLoading ? (
          <Skeleton
            variant="text"
            width="33%"
          />
        ) : (
          commonStrings('viewingPreviewTitle', {
            name: evidence?.name,
          })
        )}
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        {isLoading ? (
          <Skeleton
            sx={{ height: '200rem' }}
            variant="rectangular"
          />
        ) : (
          <DocumentPreview
            id={evidence?.file?.uuid || ''}
            isLoading={isLoading}
            sx={{
              height: '200rem',
            }}
            title={evidence?.name}
            url={evidence?.file?.file_object}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{commonStrings('close')}</Button>
        {isImage && (
          <Button
            onClick={handleDownload}
            variant="contained"
          >
            {commonStrings('download')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
