import { FormControlLabel, Switch, useMediaQuery } from '@mui/material';
import { type ChangeEvent, memo, useContext } from 'react';
import { useAppDispatch } from 'src/hooks';
import { checkboxCellStrings } from 'src/languages/en-UK';
import { assessmentConfigActions } from 'src/store/assessmentConfig';

import { ThemeContext } from '../ThemeProvider';
import { AssessmentConfigDisabledContext } from './AssessmentConfigMatrix.context';
import { TableCell } from './TableCell';

interface CheckboxCellProps {
  colId: string;
  isChecked: boolean;
  isSwitchDisabled: boolean;
  rowId: string;
}

// Radio Selection for the cultivations
export const CheckboxCell = memo(
  ({
    colId,
    rowId,
    isChecked = false,
    isSwitchDisabled = false,
  }: CheckboxCellProps): React.JSX.Element => {
    const dispatch = useAppDispatch();
    const isDisabled = useContext(AssessmentConfigDisabledContext);
    const { theme } = useContext(ThemeContext);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
      dispatch(
        assessmentConfigActions.changeCultivationSelection({
          selection: {
            colId,
            rowId,
            malusPoint: undefined,
          },
          isChecked: e.target.checked,
          isToggleButton: true,
        }),
      );

    let switchLabel = '';
    if (!isMobile) {
      if (isChecked) {
        switchLabel = checkboxCellStrings('included');
      } else {
        switchLabel = checkboxCellStrings('excluded');
      }
    }

    return (
      <TableCell key={`cell-${rowId}-${colId}`}>
        <FormControlLabel
          control={
            <Switch
              checked={isChecked}
              disabled={isDisabled || isSwitchDisabled}
              onChange={handleChange}
            />
          }
          label={switchLabel}
          sx={{ mr: 1 }}
        />
      </TableCell>
    );
  },
);
