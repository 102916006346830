import { useGetCurrentAuthenticatedUserQuery } from 'src/services/farmApi';

export const useUserAuth = () => {
  const {
    data: userAuthData,
    isLoading: isUserAuthLoading,
    isError: isUserAuthError,
    isSuccess: isUserAuthSuccess,
    ...rest
  } = useGetCurrentAuthenticatedUserQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  return {
    ...userAuthData,
    isUserAuthLoading,
    isUserAuthError,
    isUserAuthSuccess,
    ...rest,
  };
};
