import { CloseRounded } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton, Paper, Typography } from '@mui/material';
import { ControlPointGuidanceTab } from 'src/components/ControlPointGuidanceTab';
import { controlPointGuidanceDialogStrings } from 'src/languages/en-UK';
import { useGetControlPointQuery } from 'src/services/farmApi';
import { parseHtml } from 'src/utils/parseHtml';

export interface ControlPointGuidanceDialogProps {
  controlPointId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ControlPointGuidanceDialog = ({
  onClose: handleClose,
  controlPointId,
  isOpen = false,
}: ControlPointGuidanceDialogProps): React.JSX.Element => {
  const skip = !controlPointId || !isOpen;
  const { data: controlPointDetail } = useGetControlPointQuery(controlPointId, { skip });

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={handleClose}
      open={isOpen}
      scroll="body"
    >
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          padding: 1.5,
        }}
      >
        <CloseRounded />
      </IconButton>
      <DialogTitle marginLeft={2}>
        {controlPointGuidanceDialogStrings('questionDetailsForQuestionCode', {
          questionCode: controlPointDetail?.display_code || controlPointDetail?.code,
        })}
      </DialogTitle>
      <DialogContent sx={{ p: 2 }}>
        <Paper
          sx={{
            p: 2,
          }}
          variant="outlined"
        >
          <Typography
            textTransform="uppercase"
            variant="body2"
          >
            {controlPointGuidanceDialogStrings('questionForQuestionCode', {
              questionCode: controlPointDetail?.display_code || controlPointDetail?.code,
            })}
          </Typography>
          <Typography
            component="span"
            variant="body2"
          >
            {controlPointDetail?.main_text ? parseHtml(controlPointDetail.main_text) : ''}
          </Typography>
        </Paper>
        <ControlPointGuidanceTab controlPointId={controlPointId} />
      </DialogContent>
    </Dialog>
  );
};
