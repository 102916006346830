import { AddRounded } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Stack } from '@mui/material';
import { generatePath, useNavigate } from 'react-router-dom';
import { AssessmentStatusEnum, RolesEnum } from 'src/__generated__/InternalApiTypes';
import {
  useCurrentUserRoles,
  useDialogState,
  useMutationFeedback,
  useTenantId,
  useUserAuth,
} from 'src/hooks';
import { commonStrings } from 'src/languages/en-UK';
import { useCreateAssessmentMutation, useGetAssessmentListQuery } from 'src/services/farmApi';
import { ROUTE_PATH_ASSESSMENT, ROUTE_PATH_ASSESSMENT_ARCHIVE } from 'src/settings';

import { DashboardBlockRow } from '../DashboardBlockRow';
import { DashboardBlockWrapper } from '../DashboardBlockWrapper';
import {
  AssessmentCreateDialog,
  type AssessmentCreateDialogProps,
} from '../dialogs/AssessmentCreateDialog';

export type TextsType = {
  allTitle: string;
  openTitle: string;
  title: string;
  confirmTitle?: string;
  createButton?: string;
};

interface DashboardAssessmentsBlockProps {
  showCreateDialog: boolean;
  texts: TextsType;
}

export const DashboardAssessmentsBlock = ({
  texts,
  showCreateDialog = false,
}: DashboardAssessmentsBlockProps): React.JSX.Element => {
  const navigate = useNavigate();
  const tid = useTenantId();
  const currentUser = useUserAuth();
  const currentUserRoles = useCurrentUserRoles();
  const isAuditor = currentUserRoles.includes(RolesEnum.Auditor);
  const skip = !tid;
  const { data: assessmentList, isLoading } = useGetAssessmentListQuery({ tid }, { skip });
  let filteredAssessmentList = assessmentList;
  if (isAuditor) {
    filteredAssessmentList = assessmentList?.filter(
      (assessment) => assessment.author_id === currentUser?.userId,
    );
  }

  const [createAssessment, createAssessmentResult] = useCreateAssessmentMutation();
  const { allTitle, confirmTitle, createButton, openTitle, title } = texts;

  const onCreateAssessment = () => {
    if (tid) {
      createAssessment({ tid, body: undefined });
    }
  };

  useMutationFeedback({
    result: createAssessmentResult,
    onSuccess: (result) => {
      navigate(generatePath(ROUTE_PATH_ASSESSMENT, { id: result.uuid }));
    },
    successMessage: commonStrings('backendCreateSuccessMessage', {
      name: createAssessmentResult?.data?.name,
    }),
    errorMessage: commonStrings('failedToCreateAssessment'),
  });

  const handleContinueExisting = (statusList: AssessmentStatusEnum[]) => {
    const filterParams = new URLSearchParams(
      statusList.map((statusLabel) => ['status', statusLabel]),
    ).toString();
    navigate({
      pathname: ROUTE_PATH_ASSESSMENT_ARCHIVE,
      search: filterParams,
    });
  };

  const handleViewAll = () => {
    navigate(ROUTE_PATH_ASSESSMENT_ARCHIVE);
  };

  const [isAssessmentCreateDialogOpen, openAssessmentCreateDialog, closeAssessmentCreateDialog] =
    useDialogState<AssessmentCreateDialogProps>();

  const openAssessmentCount =
    filteredAssessmentList?.filter(
      (r) =>
        r.status === AssessmentStatusEnum.Open || r.status === AssessmentStatusEnum.ToBeAudited,
    )?.length || 0;
  const confirmedAssessmentCount =
    filteredAssessmentList?.filter((r) => r.status === AssessmentStatusEnum.Confirmed)?.length || 0;
  const waitingForConfirmAssessmentCount =
    filteredAssessmentList?.filter((r) => r.status === AssessmentStatusEnum.ToBeConfirmed)
      ?.length || 0;

  return (
    <>
      <DashboardBlockWrapper title={title}>
        <Stack spacing={2}>
          {createButton && (
            <LoadingButton
              fullWidth
              loading={isLoading || createAssessmentResult.isLoading}
              onClick={showCreateDialog ? openAssessmentCreateDialog : onCreateAssessment}
              startIcon={<AddRounded />}
              variant="contained"
            >
              {createButton}
            </LoadingButton>
          )}
          <DashboardBlockRow
            count={
              confirmTitle
                ? waitingForConfirmAssessmentCount?.toString()
                : openAssessmentCount?.toString()
            }
            label={openTitle}
            onClick={() =>
              handleContinueExisting(
                confirmTitle
                  ? [AssessmentStatusEnum.ToBeConfirmed]
                  : [AssessmentStatusEnum.Open, AssessmentStatusEnum.ToBeAudited],
              )
            }
          />
          {confirmTitle && (
            <DashboardBlockRow
              count={confirmedAssessmentCount?.toString()}
              label={confirmTitle}
              onClick={() => handleContinueExisting([AssessmentStatusEnum.Confirmed])}
            />
          )}
          <DashboardBlockRow
            count={filteredAssessmentList?.length?.toString() || '0'}
            label={allTitle}
            onClick={handleViewAll}
          />
        </Stack>
      </DashboardBlockWrapper>
      <AssessmentCreateDialog
        isOpen={isAssessmentCreateDialogOpen}
        onClose={closeAssessmentCreateDialog}
      />
    </>
  );
};
