import { Box, Paper, type SxProps } from '@mui/material';
import { type ReactNode } from 'react';

interface ScrollPanelProps {
  children: ReactNode;
  sx: SxProps;
}

export const ScrollPanel = ({ children, sx = {} }: ScrollPanelProps): React.JSX.Element => (
  <Paper
    sx={{ overflow: 'hidden', ...sx }}
    variant="outlined"
  >
    <Box
      height="100%"
      padding={2}
      sx={{ overflowY: 'scroll' }}
    >
      {children}
    </Box>
  </Paper>
);
