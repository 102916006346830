import { AddRounded } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { RolesEnum } from 'src/__generated__/InternalApiTypes';
import {
  useCurrentOrganization,
  useCurrentUserRoles,
  useMutationFeedback,
  useTenantId,
} from 'src/hooks';
import { commonStrings, organizationClientIdentifiersStrings } from 'src/languages/en-UK';
import {
  extractErrorMessage,
  useGetCertificationBodiesListQuery,
  useGetOrganizationClientIdentifiersListQuery,
  useUpdateOrganizationMutation,
} from 'src/services/farmApi';

import { LoadingArea } from '../common';
import { FormikTextField } from '../formikcomponents/FormikTextField';
import { OrganizationClientIdentifierForm } from '../forms/OrganizationClientIdentifierForm';

export const OrganizationClientIdentifiers = (): React.JSX.Element => {
  const tid = useTenantId();
  const currentUserRoles = useCurrentUserRoles();
  const isFarmEmployee = currentUserRoles.includes(RolesEnum.FarmEmployee);

  const { data: organization, isLoading: isLoadingOrganization } = useCurrentOrganization();
  const [updateOrganization, updateOrganizationResult] = useUpdateOrganizationMutation();
  const { data: organizationClientIdentifiers, isLoading: isLoadingOrganizationClientIdentifiers } =
    useGetOrganizationClientIdentifiersListQuery(tid, { skip: !tid });
  const { data: certificationBodies, isLoading: isLoadingCertificationBodies } =
    useGetCertificationBodiesListQuery(tid, { skip: !tid });
  const [
    isShowingNewOrganizationClientIdentifierForm,
    setIsShowingNewOrganizationClientIdentifierForm,
  ] = useState(false);
  const [usedClientIds, setUsedClientIds] = useState<string[]>([]);

  const certificationBodyOptions = useMemo(() => {
    if (!organizationClientIdentifiers && certificationBodies) {
      return (
        certificationBodies?.map((certificationBody) => ({
          value: certificationBody.uuid || '',
          label: certificationBody.name,
        })) ?? []
      );
    }

    const usedCertificationBodies = organizationClientIdentifiers?.map(
      (organizationClientIdentifier) => organizationClientIdentifier.certification_body,
    );
    return (
      certificationBodies
        ?.filter(
          (certificationBody) =>
            !usedCertificationBodies?.some(
              (usedCertificationBody) => usedCertificationBody?.uuid === certificationBody.uuid,
            ),
        )
        .map((certificationBody) => ({
          value: certificationBody.uuid || '',
          label: certificationBody.name,
        })) ?? []
    );
  }, [certificationBodies, organizationClientIdentifiers]);

  const standardOptions = useMemo(() => {
    if (!organizationClientIdentifiers && certificationBodies) {
      return (
        organization?.active_subscription_standards?.map((standard) => ({
          value: standard.uuid,
          label: standard.name,
        })) ?? []
      );
    }

    const usedStandards =
      organizationClientIdentifiers?.flatMap(
        (organizationClientIdentifier) => organizationClientIdentifier.standards,
      ) || [];

    const allStandards = organization?.active_subscription_standards;

    return (
      allStandards
        ?.filter(
          (standard) => !usedStandards.some((usedStandard) => usedStandard?.uuid === standard.uuid),
        )
        .map((standard) => ({
          value: standard.uuid,
          label: standard.name,
        })) ?? []
    );
  }, [
    certificationBodies,
    organization?.active_subscription_standards,
    organizationClientIdentifiers,
  ]);

  const isLoading =
    isLoadingOrganizationClientIdentifiers || isLoadingCertificationBodies || isLoadingOrganization;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ggn: organization?.ggn ?? '',
    },
    onSubmit: undefined,
  });

  useEffect(() => {
    if (!isLoadingOrganizationClientIdentifiers && organizationClientIdentifiers?.length === 0) {
      setIsShowingNewOrganizationClientIdentifierForm(true);
    }
  }, [
    organizationClientIdentifiers,
    isLoadingOrganizationClientIdentifiers,
    setIsShowingNewOrganizationClientIdentifierForm,
  ]);

  useEffect(() => {
    if (!organizationClientIdentifiers) {
      return;
    }

    const updatedUsedClientIds = organizationClientIdentifiers.map(
      (organizationClientIdentifier) => organizationClientIdentifier.value,
    );
    setUsedClientIds(updatedUsedClientIds);
  }, [organizationClientIdentifiers, setUsedClientIds]);

  useMutationFeedback({
    result: updateOrganizationResult,
    successMessage: commonStrings('backendSaveSuccessMessage', {
      name: `${organizationClientIdentifiersStrings('ggn')} ${formik.values.ggn}`,
    }),
    errorMessage: commonStrings('backendSaveFailMessage', {
      name: `${organizationClientIdentifiersStrings('ggn')} ${organization?.ggn}`,
    }),
    onError: () => {
      if (updateOrganizationResult.error) {
        const errorMessage = extractErrorMessage(updateOrganizationResult.error);
        formik.setFieldError('ggn', JSON.parse(errorMessage).detail?.ggn?.[0]);
      }
    },
  });

  const handleCreateOrUpdateGgn = () => {
    if (formik.values.ggn !== formik.initialValues.ggn && tid && organization?.uuid) {
      updateOrganization({
        tid,
        id: organization.uuid,
        body: {
          ggn: formik.values.ggn,
        },
      });
    }
  };

  return (
    <Box minHeight="calc(70vh - 264px)">
      <Box>
        <Typography
          marginY={2}
          variant="body1"
        >
          {organizationClientIdentifiersStrings('clientIdentifiersDescription')}
        </Typography>
      </Box>
      {isLoading ? (
        <LoadingArea sx={{ minHeight: 'calc(40vh - 264px)' }} />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
        >
          <Box marginY={3}>
            <FormikTextField
              disabled={isFarmEmployee}
              formik={formik}
              id="ggn"
              label={organizationClientIdentifiersStrings('ggn')}
              onBlur={(e) => {
                formik.handleBlur(e);
                handleCreateOrUpdateGgn();
              }}
            />
          </Box>
          {organizationClientIdentifiers?.map((row) => (
            <OrganizationClientIdentifierForm
              key={row.uuid}
              certificationBodyOptions={certificationBodyOptions}
              isDeleteDisabled={isFarmEmployee}
              isReadOnly={isFarmEmployee}
              organizationClientIdentifier={row}
              standardOptions={standardOptions}
              usedClientIds={usedClientIds.filter((usedClientId) => usedClientId !== row.value)}
            />
          ))}
          {isShowingNewOrganizationClientIdentifierForm && (
            <OrganizationClientIdentifierForm
              certificationBodyOptions={certificationBodyOptions}
              isDeleteDisabled={organizationClientIdentifiers?.length === 0 || isFarmEmployee}
              isReadOnly={isFarmEmployee}
              setIsShowingNewOrganizationClientIdentifierForm={
                setIsShowingNewOrganizationClientIdentifierForm
              }
              standardOptions={standardOptions}
              usedClientIds={usedClientIds}
            />
          )}
          <Box>
            <Button
              disabled={
                isShowingNewOrganizationClientIdentifierForm ||
                certificationBodyOptions.length === 0 ||
                standardOptions.length === 0
              }
              onClick={() => setIsShowingNewOrganizationClientIdentifierForm(true)}
              size="small"
              startIcon={<AddRounded />}
              variant="outlined"
            >
              {organizationClientIdentifiersStrings('addClientIdentifierButton')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};
