import { type RetailerListOutputItem } from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { TagType } from '../TagType';

const RESOURCE = 'retailers';

const retailerEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getRetailersList: build.query<RetailerListOutputItem[], void>({
      query: () => `/${RESOURCE}/`,
      providesTags: [TagType.Retailers],
    }),
  }),
  overrideExisting: false,
});

export const { useGetRetailersListQuery } = retailerEndpoints;
