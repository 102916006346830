import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@mui/icons-material';
import { AppBar, Box, Button, Container, Paper, Toolbar, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { groupBy } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { GroupedVirtuoso } from 'react-virtuoso';
import {
  type PreliminaryQuestionAnswerListBulkUpdateInput,
  RolesEnum,
} from 'src/__generated__/InternalApiTypes';
import { AssessmentActionBarButtons } from 'src/components/AssessmentActionBarButtons';
import { SkeletonMultiLineText } from 'src/components/common/SkeletonMultiLineText';
import { FormikTextField } from 'src/components/formikcomponents/FormikTextField';
import { type DynamicObjectType } from 'src/global';
import { useCurrentUserRoles, useTenantId } from 'src/hooks';
import { assessmentPreliminaryQuestionsStepStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import { useGetAssessmentQuery } from 'src/services/farmApi';
import { parseHtml } from 'src/utils/parseHtml';
import * as Yup from 'yup';

import { type StepperContentBaseProps } from '../StepperPage';
import {
  useGetPreliminaryQuestionFields,
  useUpdatePreliminaryQuestionAnswers,
} from './AssessmentPreliminaryQuestionsStep.hooks';
import { type FormikFieldConfig } from './AssessmentPreliminaryQuestionsStep.types';
import {
  convertFormikValueToRequestItem,
  haveAnswersChanged,
} from './AssessmentPreliminaryQuestionsStep.utils';

export const AssessmentPreliminaryQuestionsStep = ({
  maxWidth = 'xl',
  ...props
}: StepperContentBaseProps): React.JSX.Element => {
  const tid = useTenantId();
  const [assessmentId] = useContext(AssessmentPageContext);
  const currentUserRoles = useCurrentUserRoles();
  const isAuditor = currentUserRoles.includes(RolesEnum.Auditor);
  const isCBManager = currentUserRoles.includes(RolesEnum.CertificationBodyManager);
  /* const [answerValues, setAnswerValues] = useState<PreliminaryQuestionAnswerListBulkUpdateInput[]>(
    [],
  ); */

  const {
    // shouldOpenConfirmDialog,
    shouldContinue,
    // evidenceToBeDetached,
    draftUpdate,
    // nonDraftUpdate,
    isLoading: isUpdatePreliminaryQuestionAnswersLoading,
  } = useUpdatePreliminaryQuestionAnswers();

  /* const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useDialogState<AssessmentPreliminaryQuestionsConfirmDialogProps>(); */

  const { data: assessmentDetail, isLoading: isAssessmentDetailLoading } = useGetAssessmentQuery(
    { tid, id: assessmentId },
    {
      skip: !assessmentId || !tid,
    },
  );

  const [isNextTaskLoading, setIsNextTaskLoading] = useState(false);
  const [isPreviousTaskLoading, setIsPreviousTaskLoading] = useState(false);
  const [selectedDetailId, setSelectedDetailId] = useState('');

  const {
    fields,
    isLoading: isFieldsLoading,
    preliminaryQuestionList,
  } = useGetPreliminaryQuestionFields(assessmentDetail);
  const hasGroupList = assessmentDetail?.standard_ids?.length > 1;
  const groupedData = groupBy(fields, (item) => item.categoryName || '');
  const groups = Object.keys(groupedData);
  const groupCounts = Object.values(groupedData).map((group) => group.length);
  const navigateToNextStep = useCallback(() => {
    if (props.isFinalTask) {
      props.openFinishDialog();
    } else {
      setIsNextTaskLoading(true);
      props.moveToNextTask();
    }

    // TODOHasan: Do not add props as a dependency. Find a correct way.
  }, []);

  const navigateToPreviousStep = () => {
    setIsPreviousTaskLoading(true);
    props.moveToPreviousTask();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: fields.reduce(
      (acc, field) => ({
        ...acc,
        [field.id]: field.initialValue,
      }),
      {},
    ),
    validationSchema: Yup.object().shape(
      fields.reduce(
        (acc, field) => ({
          ...acc,
          [field.id]: field.validator,
        }),
        {},
      ),
    ),
    onSubmit: (values: DynamicObjectType) => {
      // If changes were made, fire the mutation, otherwise simply navigate to the next step
      if (formik.dirty && haveAnswersChanged(formik.initialValues, values)) {
        const preliminaryQuestionAnswers: PreliminaryQuestionAnswerListBulkUpdateInput[] =
          Object.keys(values).reduce(
            (result, key) => [
              ...result,
              convertFormikValueToRequestItem(
                fields.find((f) => f.id === key),
                values[key],
              ),
            ],
            [],
          );

        // setAnswerValues(preliminaryQuestionAnswers);
        draftUpdate(preliminaryQuestionAnswers);
      } else {
        navigateToNextStep();
      }
    },
  });

  /* useEffect(() => {
    if (shouldOpenConfirmDialog) {
      openConfirmDialog();
    }
    // TODOHasan: Do not add openConfirmDialog as a dependency. Find a correct way.
  }, [shouldOpenConfirmDialog]); */

  useEffect(() => {
    if (shouldContinue) {
      navigateToNextStep();
    }
  }, [navigateToNextStep, shouldContinue]);

  const isLoading = isAssessmentDetailLoading || isFieldsLoading;

  const renderField = (field: FormikFieldConfig) => {
    const questionData = preliminaryQuestionList.find((question) => question.uuid === field.id);
    const {
      description,
      main_text: mainText,
      standard_names: standardNames,
      uuid,
    } = questionData || {};
    const isSelected = selectedDetailId === uuid;
    return (
      <Paper
        key={field.id}
        sx={{ borderRadius: 2, px: 2, py: 2, mb: 2 }}
      >
        <Typography
          marginBottom={description ? 0.5 : 2}
          variant="body1"
        >
          {parseHtml(mainText)}
        </Typography>
        {description && (
          <Box
            display="flex"
            flexDirection="row"
            paddingBottom={isSelected ? 0 : 2}
          >
            <Button
              endIcon={isSelected ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
              onClick={() => {
                if (isSelected) {
                  setSelectedDetailId('');
                } else if (uuid) {
                  setSelectedDetailId(uuid);
                }
              }}
              size="small"
              sx={{
                color: 'text.secondary',
                textTransform: 'initial',
                padding: 0,
              }}
            >
              {assessmentPreliminaryQuestionsStepStrings('showDetailedQuestionDescription')}
            </Button>
          </Box>
        )}
        {isSelected && description && (
          <Box
            paddingBottom={2}
            paddingTop={0.5}
          >
            <Typography
              color="textPrimary"
              variant="caption"
            >
              {parseHtml(description)}
            </Typography>
          </Box>
        )}
        {field.renderAs({
          disabled: isUpdatePreliminaryQuestionAnswersLoading,
          formik,
          isLoading,
        })}
        {assessmentDetail?.standard_ids?.length > 1 && standardNames.length > 0 && (
          <Box marginTop={2}>
            {standardNames?.map((tag) => (
              <Typography
                key={tag}
                color="text.disabled"
                marginRight={1}
                variant="caption"
              >
                {`#${tag}`}
              </Typography>
            ))}
          </Box>
        )}
      </Paper>
    );
  };

  const renderGroupContent = (index: number) =>
    hasGroupList && groups[index] ? (
      <Box
        alignItems="center"
        display="flex"
        flex={1}
        paddingBottom={2}
        paddingTop={index === 0 ? 1 : 0}
      >
        <Box
          bgcolor="divider"
          flex={1}
          height="1px"
        />
        <Typography
          color="textSecondary"
          paddingX={0.5}
          variant="overline"
        >
          {groups[index]}
        </Typography>
        <Box
          bgcolor="divider"
          flex={1}
          height="1px"
        />
      </Box>
    ) : (
      <Box
        alignItems="center"
        display="flex"
        flex={1}
        minHeight={2}
        paddingTop={index === 0 ? 1 : 0}
      />
    );

  const renderFooter = () => <Toolbar />;

  return (
    <>
      <form
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <Container maxWidth={maxWidth}>
          <Paper sx={{ borderRadius: 2, px: 2, py: 2, mb: 2 }}>
            <Typography
              marginBottom={3}
              variant="h1"
            >
              {isAuditor
                ? assessmentPreliminaryQuestionsStepStrings('preparatoryQuestions')
                : assessmentPreliminaryQuestionsStepStrings('startingQuestions')}
            </Typography>
            <Typography variant="body1">
              {isAuditor || isCBManager
                ? assessmentPreliminaryQuestionsStepStrings(
                    'preparatoryQuestionsPageDescriptionForAuditor',
                  )
                : assessmentPreliminaryQuestionsStepStrings('preparatoryQuestionsStepDescription')}
            </Typography>
          </Paper>
          {isLoading &&
            Array.from({ length: 4 }).map((_item, i) => (
              <Paper
                key={`${i}_skeleton`}
                sx={{ borderRadius: 2, px: 2, py: 2, mb: 2 }}
              >
                <SkeletonMultiLineText variant="body1" />
                <FormikTextField
                  id={`skeleton-field-${i}`}
                  isLoading
                />
              </Paper>
            ))}
          <GroupedVirtuoso
            components={{ Footer: renderFooter }}
            groupContent={renderGroupContent}
            groupCounts={groupCounts}
            itemContent={(index) => {
              if (fields?.length > 0) {
                return renderField(fields[index]);
              }
              return null;
            }}
            useWindowScroll
          />
        </Container>
        <AppBar
          position="fixed"
          sx={{ top: 'auto', bottom: 0 }}
        >
          <Container maxWidth={maxWidth}>
            <AssessmentActionBarButtons
              containerStyle={{ justifyContent: 'right' }}
              isDisabled={
                isUpdatePreliminaryQuestionAnswersLoading ||
                isPreviousTaskLoading ||
                isNextTaskLoading
              }
              isFinalTask={props.isFinalTask}
              isFirstTask={props.isFirstTask}
              isNextButtonLoading={
                isUpdatePreliminaryQuestionAnswersLoading || isNextTaskLoading || isFieldsLoading
              }
              isPreviousButtonLoading={isPreviousTaskLoading}
              nextTaskName={props.nextTaskName}
              onNext={() => formik.handleSubmit()}
              onPrevious={navigateToPreviousStep}
            />
          </Container>
        </AppBar>
      </form>
      {/* <AssessmentPreliminaryQuestionsConfirmDialog
        draftDetachedEvidence={evidenceToBeDetached}
        isLoading={isUpdatePreliminaryQuestionAnswersLoading}
        isOpen={isConfirmDialogOpen}
        onClose={closeConfirmDialog}
        onConfirm={nonDraftUpdate}
        values={answerValues}
      /> */}
    </>
  );
};
