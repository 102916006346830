import { useState } from 'react';

type UseDialogStateReturnValue<T> = [
  boolean,
  (newDialogProps?: Omit<T, 'isOpen' | 'onClose'>) => void,
  () => void,
  Omit<T, 'isOpen' | 'onClose'> | null,
];

/**
 * A hook to reduce repetitive "set open/set closed" code for dialog controls. Exposes a boolean and
 * two handler functions. Proposed usage is as follows:
 *
 * const [is{DialogName}Open, handleOpen{DialogName}, handleClose{DialogName}] = useDialogState();
 *
 * @param defaultState - An optional, default open/not-open state for the dialog
 * @returns
 */
export const useDialogState = <T>(defaultState = false): UseDialogStateReturnValue<T> => {
  const [isOpen, setIsOpen] = useState(defaultState);
  const [dialogProps, setDialogProps] = useState<Omit<T, 'isOpen' | 'onClose'> | null>(null);
  const handleOpen = (newDialogProps?: Omit<T, 'isOpen' | 'onClose'>) => {
    if (newDialogProps) {
      setDialogProps(newDialogProps);
    }
    setIsOpen(true);
  };
  const handleClose = () => {
    if (dialogProps) {
      setDialogProps(null);
    }
    setIsOpen(false);
  };
  return [isOpen, handleOpen, handleClose, dialogProps];
};
