// TODOHasan: Which application uses this variable?
export const cognitoLocalStorageTokenLocation = `CognitoIdentityServiceProvider.${window.appConfig.userPoolClientId}`;

export default {
  apiUrl: `${window.appConfig.apiUrl}`,
  cognito: {
    REGION: window.appConfig.userRegion,
    USER_POOL_ID: window.appConfig.userPoolId,
    APP_CLIENT_ID: window.appConfig.userPoolClientId,
  },
};
