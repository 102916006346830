export const pageWrapperStyle = () => ({
  display: 'flex',
  width: '100vw',
  height: 'calc(100vh - 64px)',
  flexDirection: 'column',
  px: 5,
  pt: 2,
  pb: 5,
  backgroundColor: 'background.default',
});
