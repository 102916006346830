import { ArrowBackRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  Paper,
  Stack,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material';
import { cloneElement, type ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { commonStrings, tabbedPageStrings } from 'src/languages/en-UK';
import { ROUTE_PATH_DASHBOARD } from 'src/settings';

import { LoadingArea } from '../LoadingArea';
import { useTabs } from './TabbedPage.hooks';
import { type TabbedPageTabDef } from './TabbedPage.types';

interface TabbedPageProps {
  tabs: TabbedPageTabDef[];
  title: ReactNode;
  hasStepperButton?: boolean;
  seenTabIndex?: number;
  setSeenTabIndex?: React.Dispatch<React.SetStateAction<number>>;
}

export const TabbedPage = ({
  seenTabIndex,
  setSeenTabIndex,
  tabs,
  title,
  hasStepperButton = false,
}: TabbedPageProps): React.JSX.Element => {
  const navigate = useNavigate();
  const [activeTabIndex, setActiveTabIndex] = useTabs(tabs);

  useEffect(() => {
    if (hasStepperButton && activeTabIndex > seenTabIndex) {
      setSeenTabIndex(activeTabIndex);
    }
  }, [activeTabIndex, hasStepperButton, seenTabIndex, setSeenTabIndex]);

  return (
    <Container
      disableGutters
      maxWidth="lg"
    >
      <Toolbar />
      <Paper
        sx={{
          mt: 2,
          p: 2,
          width: '100%',
        }}
      >
        {activeTabIndex === null ? (
          <LoadingArea />
        ) : (
          <Stack spacing={2}>
            <Typography
              paddingTop={2}
              variant="h1"
            >
              {title}
            </Typography>
            <Box
              borderBottom={1}
              borderColor="divider"
            >
              <Tabs
                onChange={setActiveTabIndex}
                TabIndicatorProps={{ sx: { height: 4 } }}
                value={activeTabIndex}
              >
                {tabs.map((tab, ind) => (
                  <Tab
                    key={tab.slug}
                    disabled={hasStepperButton && ind > seenTabIndex}
                    label={tab.title}
                  />
                ))}
              </Tabs>
            </Box>
            {tabs.map((tab, i) =>
              activeTabIndex === i ? cloneElement(tab.content, { key: tab.slug }) : null,
            )}
          </Stack>
        )}
        {hasStepperButton && activeTabIndex > 0 && (
          <Toolbar
            disableGutters
            sx={{
              justifyContent: 'flex-end',
              mt: 2,
              gap: 1,
            }}
            variant="dense"
          >
            <Button
              disabled={activeTabIndex === 0}
              onClick={(evt) => setActiveTabIndex(evt, activeTabIndex - 1)}
              startIcon={<ArrowBackRounded />}
              variant="outlined"
            >
              {commonStrings('back')}
            </Button>
            <Button
              onClick={(evt) => {
                if (activeTabIndex === tabs.length - 1) {
                  navigate(ROUTE_PATH_DASHBOARD);
                } else {
                  setActiveTabIndex(evt, activeTabIndex + 1);
                }
              }}
              variant="contained"
            >
              {activeTabIndex === tabs.length - 1
                ? tabbedPageStrings('completeOrganizationSettings')
                : commonStrings('next')}
            </Button>
          </Toolbar>
        )}
      </Paper>
      <Box
        height={16}
        width="100%"
      />
    </Container>
  );
};
