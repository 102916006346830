import { CloseRounded } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { ChangePasswordForm } from 'src/components/forms/ChangePasswordForm';
import { commonStrings } from 'src/languages/en-UK';

export interface ChangePasswordDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ChangePasswordDialog = ({
  onClose: handleClose,
  isOpen = false,
}: ChangePasswordDialogProps): React.JSX.Element => (
  <Dialog
    fullWidth
    maxWidth="sm"
    onClose={handleClose}
    open={isOpen}
  >
    <IconButton
      onClick={handleClose}
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        padding: 1.5,
      }}
    >
      <CloseRounded />
    </IconButton>
    <DialogTitle>{commonStrings('changePassword')}</DialogTitle>
    <DialogContent sx={{ mb: 0 }}>
      <ChangePasswordForm
        onCancel={handleClose}
        onSuccess={handleClose}
      />
    </DialogContent>
  </Dialog>
);
