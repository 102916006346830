import { LinkOffRounded, LinkRounded } from '@mui/icons-material';
import { Button, Switch } from '@mui/material';
import { type SyntheticEvent, useContext, useEffect, useState } from 'react';
import {
  useAppDispatch,
  useGetAssessmentUserPermission,
  useMutationFeedback,
  useTenantId,
} from 'src/hooks';
import { commonStrings, evidenceAttachToggleStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import {
  type TransformedEvidenceDetailOutput,
  useCreateAssessmentControlPointEvidenceMutation,
  useDeleteAssessmentControlPointEvidenceMutation,
} from 'src/services/farmApi';
import { openSnackbar } from 'src/store/snackbarSlice';

import { MobileCompatibleTooltip } from '../common/MobileCompatibleTooltip';
import { ThemeContext } from '../ThemeProvider';

interface EvidenceAttachToggleProps {
  controlPointId: string | undefined;
  evidence: Pick<TransformedEvidenceDetailOutput, 'name' | 'uuid'> | undefined;
  evidenceLinkId: string;
  attachedList?: string[];
  tooltipText?: string;
  variant?: 'switch' | 'button';
}

export const EvidenceAttachToggle = ({
  attachedList,
  controlPointId,
  evidence,
  evidenceLinkId,
  tooltipText,
  variant = 'button',
}: EvidenceAttachToggleProps): React.JSX.Element | null => {
  const tid = useTenantId();
  const { theme } = useContext(ThemeContext);
  const [assessmentId] = useContext(AssessmentPageContext);
  const [createControlPointEvidence, createResult] =
    useCreateAssessmentControlPointEvidenceMutation();
  const [evidenceChecked, setEvidenceChecked] = useState(false);
  const dispatch = useAppDispatch();
  const permissions = useGetAssessmentUserPermission(assessmentId);

  // Show feedback (via snackbar) for mutation success and error
  useMutationFeedback({
    result: createResult,
    successMessage: evidenceAttachToggleStrings('attachEvidenceSuccess', {
      evidenceName: evidence?.name,
    }),
    errorMessage: evidenceAttachToggleStrings('attachEvidenceFail', {
      evidenceName: evidence?.name,
    }),
  });

  const [deleteControlPointEvidence, deleteResult] =
    useDeleteAssessmentControlPointEvidenceMutation();

  // Show feedback (via snackbar) for mutation success and error
  useMutationFeedback({
    result: deleteResult,
    successMessage: evidenceAttachToggleStrings('detachEvidenceSuccess', {
      evidenceName: evidence?.name,
    }),
    errorMessage: evidenceAttachToggleStrings('detachEvidenceFail', {
      evidenceName: evidence?.name,
    }),
  });

  /*
   * When the user toggles the switch, we disable it.
   * When the parent updates evidenceLinkId (via an automatic re-fetch), we know the mutation is
   * complete and can re-enable the switch.
   */
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    setIsDisabled(false);
    setEvidenceChecked(Boolean(evidenceLinkId));
  }, [evidenceLinkId]);

  const onChange = (checked: boolean): void => {
    setEvidenceChecked(checked);

    // Disable the switch's functionality until the next update (re-fetch via parent)
    setIsDisabled(true);
    if (!checked) {
      if (permissions?.evidence?.delete && tid && evidenceLinkId) {
        deleteControlPointEvidence({
          tid,
          id: evidenceLinkId,
          parentResourceId: controlPointId,
        });
      } else {
        dispatch(
          openSnackbar({
            message: commonStrings('notAuthorized'),
            severity: 'error',
          }),
        );
      }
    } else if (
      permissions?.evidence?.create &&
      tid &&
      assessmentId &&
      controlPointId &&
      evidence?.uuid
    ) {
      createControlPointEvidence({
        tid,
        body: {
          assessment_id: assessmentId,
          control_point_id: controlPointId,
          evidence_id: evidence.uuid,
        },
      });
    } else {
      dispatch(
        openSnackbar({
          message: commonStrings('notAuthorized'),
          severity: 'error',
        }),
      );
    }
  };

  const getButtonText = (): string => {
    if (isDisabled) {
      return evidenceAttachToggleStrings('loadingButtonText');
    }
    return evidenceLinkId ? evidenceAttachToggleStrings('detach') : commonStrings('attach');
  };

  const getButtonIcon = (): React.JSX.Element | null => {
    if (isDisabled) {
      return null;
    }
    return evidenceLinkId ? <LinkOffRounded /> : <LinkRounded />;
  };

  if (variant === 'switch') {
    const isFalsePositive =
      controlPointId && attachedList?.includes(controlPointId) && !evidenceChecked;
    return tooltipText ? (
      <MobileCompatibleTooltip
        placement="right"
        title={tooltipText}
      >
        <span>
          <Switch
            checked={evidenceChecked}
            onChange={(_event: SyntheticEvent, checked: boolean) => onChange(checked)}
            sx={
              isFalsePositive
                ? {
                    '& .MuiSwitch-track': {
                      backgroundColor: theme.palette.primary.main,
                      opacity: '0.38 !important',
                    },
                  }
                : null
            }
          />
        </span>
      </MobileCompatibleTooltip>
    ) : (
      <Switch
        checked={evidenceChecked}
        disabled={isDisabled}
        onChange={(_event: SyntheticEvent, checked: boolean) => onChange(checked)}
      />
    );
  }
  if (variant === 'button') {
    return (
      <Button
        color={evidenceLinkId ? 'error' : 'info'}
        disabled={isDisabled}
        onClick={() => onChange(!evidenceLinkId)}
        size="small"
        startIcon={getButtonIcon()}
        sx={{ ml: 'auto', alignSelf: 'flex-end' }}
        variant={evidenceLinkId ? 'outlined' : 'contained'}
      >
        {getButtonText()}
      </Button>
    );
  }
  return null;
};
