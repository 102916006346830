import { DeleteForeverRounded, EditRounded } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useContext } from 'react';
import {
  useAppDispatch,
  useDialogState,
  useGetAssessmentUserPermission,
  useGetCurrentUserProfile,
  useMutationFeedback,
  useTenantId,
} from 'src/hooks';
import { commonStrings, nonConformityStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import {
  type TransformedAssessmentControlPointNonConformityOutputItem,
  useDeleteAssessmentControlPointNonConformityMutation,
} from 'src/services/farmApi';
import { DATE_DISPLAY_FORMAT } from 'src/settings';
import { openSnackbar } from 'src/store/snackbarSlice';

import { MobileCompatibleTooltip } from '../common/MobileCompatibleTooltip';
import {
  EditNonConformityDialog,
  type EditNonConformityDialogProps,
} from './EditNonConformityDialog';
import { OccurrenceEnumLabel } from './NewNonConformityForm';

interface NonConformityProps {
  isDisable: boolean;
  nonConformity: TransformedAssessmentControlPointNonConformityOutputItem;
  setIsNewCorrectiveActionFormVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NonConformity = ({
  nonConformity,
  setIsNewCorrectiveActionFormVisible,
  isDisable = false,
}: NonConformityProps): React.JSX.Element => {
  const tid = useTenantId();
  const { data: userProfile } = useGetCurrentUserProfile();
  const [assessmentId] = useContext(AssessmentPageContext);
  const dispatch = useAppDispatch();
  const permissions = useGetAssessmentUserPermission(assessmentId);

  const [isEditDialogOpen, openEditDialog, closeEditDialog, editDialogProps] =
    useDialogState<EditNonConformityDialogProps>();

  const [deleteNonConformity, deleteNonConformityResult] =
    useDeleteAssessmentControlPointNonConformityMutation();

  const handleDeleteNonConformity = () => {
    if (permissions?.shortcomings?.delete) {
      deleteNonConformity({
        tid,
        id: nonConformity.uuid,
        parentResourceId: nonConformity.control_point_id,
      });
    } else {
      dispatch(
        openSnackbar({
          message: commonStrings('notAuthorized'),
          severity: 'error',
        }),
      );
    }
  };

  useMutationFeedback({
    result: deleteNonConformityResult,
    errorMessage: nonConformityStrings('deleteNonConformityFail'),
    successMessage: nonConformityStrings('deleteNonConformitySuccess'),
    onSuccess: () => setIsNewCorrectiveActionFormVisible(false),
  });

  return (
    <>
      <Stack gap={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
        >
          <Stack gap={0.75}>
            <Typography
              color="text.disabled"
              variant="overline"
            >
              {nonConformityStrings('nonConformity')}
            </Typography>
            <Typography variant="body1">{nonConformity.text}</Typography>
          </Stack>
          <Stack
            alignItems="center"
            direction="row"
            gap={2}
          >
            {nonConformity.author_id === userProfile.uuid && !isDisable && (
              <>
                <IconButton
                  onClick={() =>
                    openEditDialog({
                      nonConformityId: String(nonConformity.uuid),
                      title: commonStrings('editNonConformity'),
                    })
                  }
                  sx={{ padding: 0, maxHeight: 24 }}
                >
                  <MobileCompatibleTooltip title={commonStrings('editNonConformity')}>
                    <EditRounded />
                  </MobileCompatibleTooltip>
                </IconButton>
                <IconButton
                  onClick={handleDeleteNonConformity}
                  sx={{ padding: 0, maxHeight: 24 }}
                >
                  <MobileCompatibleTooltip title={nonConformityStrings('deleteNonConformity')}>
                    <DeleteForeverRounded />
                  </MobileCompatibleTooltip>
                </IconButton>
              </>
            )}
          </Stack>
        </Stack>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          gap={{ xs: 1, sm: 3 }}
        >
          <Stack gap={0.75}>
            <Typography
              color="text.disabled"
              variant="overline"
            >
              {commonStrings('dateNonConformityReported')}
            </Typography>
            <Typography variant="body2">
              {format(nonConformity.reported_date, DATE_DISPLAY_FORMAT)}
            </Typography>
          </Stack>
          <Stack gap={0.75}>
            <Typography
              color="text.disabled"
              variant="overline"
            >
              {commonStrings('timeNonConformityAppear')}
            </Typography>
            <Typography variant="body2">{OccurrenceEnumLabel[nonConformity.occurrence]}</Typography>
          </Stack>
        </Stack>
      </Stack>
      <EditNonConformityDialog
        isOpen={isEditDialogOpen}
        onClose={closeEditDialog}
        {...editDialogProps}
      />
    </>
  );
};
