import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { OrganizationTypesEnum, RequestStatusEnum } from 'src/__generated__/InternalApiTypes';
import { useCurrentOrganization, useDialogState, useTenantId, useUserAuth } from 'src/hooks';
import { useGetUserOrigin } from 'src/hooks/useGetUserOrigin';
import { commonStrings, dashboardRequestBlockStrings } from 'src/languages/en-UK';
import { useGetAssessmentStandardRequestListQuery } from 'src/services/farmApi';
import { SignUpOriginEnum } from 'src/services/farmApi/endpoints/auth';
import { ROUTE_PATH_DASHBOARD_REQUESTS } from 'src/settings';

import { DashboardBlockRow } from '../DashboardBlockRow';
import { DashboardBlockWrapper } from '../DashboardBlockWrapper';
import {
  ApprovedOutgoingRequestDialog,
  type ApprovedOutgoingRequestDialogProps,
} from '../dialogs/ApprovedOutgoingRequestDialog';
import {
  PendingIncomingRequestDialog,
  type PendingIncomingRequestDialogProps,
} from '../dialogs/PendingIncomingRequestDialog';

export enum RequestType {
  Incoming = 'incoming',
  Outgoing = 'outgoing',
}

type DashboardRequestsBlockProps = {
  requestType: RequestType;
};

export const DashboardRequestsBlock = ({
  requestType,
}: DashboardRequestsBlockProps): React.JSX.Element => {
  const navigate = useNavigate();
  const tid = useTenantId();
  const currentUser = useUserAuth();
  const { data: organization, isLoading: isOrganizationLoading } = useCurrentOrganization();
  const origin = useGetUserOrigin();
  const { data: requestList } = useGetAssessmentStandardRequestListQuery(
    {
      tid,
      ...(organization?.type === OrganizationTypesEnum.CertificationBody && {
        requester_user_profile_id: currentUser?.userId,
      }),
    },
    { skip: !tid || !organization },
  );

  const pendingRequestCount =
    requestList?.filter((r) => r.status === RequestStatusEnum.Pending)?.length || 0;
  const resolvedRequestCount =
    requestList?.filter((r) => r.status !== RequestStatusEnum.Pending)?.length || 0;
  const approvedRequestCount =
    requestList?.filter((r) => r.status === RequestStatusEnum.Approved)?.length || 0;

  const handleContinueExisting = (status: string[]) => {
    navigate({
      pathname: ROUTE_PATH_DASHBOARD_REQUESTS,
      search: `?status=${status.join(',')}`,
    });
  };

  const handleViewAll = () => {
    navigate(ROUTE_PATH_DASHBOARD_REQUESTS);
  };

  const [
    isRequestApprovedDialogOpen,
    openRequestApprovedDialog,
    closeRequestApprovedDialog,
    requestApprovedDialogProps,
  ] = useDialogState<ApprovedOutgoingRequestDialogProps>();

  const [isRequestPendingDialogOpen, openRequestPendingDialog, closeRequestPendingDialog] =
    useDialogState<PendingIncomingRequestDialogProps>();

  const approvedOrResolvedClick = () => {
    if (requestType === RequestType.Outgoing) {
      if (approvedRequestCount === 1) {
        openRequestApprovedDialog({
          requestDetail: requestList?.find((r) => r.status === RequestStatusEnum.Approved),
        });
      } else {
        handleContinueExisting([RequestStatusEnum.Approved]);
      }
    } else {
      handleContinueExisting([
        RequestStatusEnum.Approved,
        RequestStatusEnum.Rejected,
        RequestStatusEnum.InspectionCreated,
      ]);
    }
  };

  return (
    <>
      <DashboardBlockWrapper
        caption={
          origin === SignUpOriginEnum.AGRIPLACE && organization?.type === OrganizationTypesEnum.Farm
            ? dashboardRequestBlockStrings('functionalityForPlanetProof')
            : ''
        }
        isLoading={isOrganizationLoading}
        title={
          organization?.type === OrganizationTypesEnum.Farm
            ? dashboardRequestBlockStrings('requestsFromAuditors')
            : commonStrings('createdRequest')
        }
      >
        <Stack spacing={2}>
          <DashboardBlockRow
            count={pendingRequestCount.toString()}
            label={dashboardRequestBlockStrings('pendingRequests')}
            onClick={
              requestType === RequestType.Incoming && pendingRequestCount === 1
                ? openRequestPendingDialog
                : () => handleContinueExisting([RequestStatusEnum.Pending])
            }
          />
          <DashboardBlockRow
            count={
              requestType === RequestType.Incoming
                ? resolvedRequestCount.toString()
                : approvedRequestCount.toString()
            }
            label={
              requestType === RequestType.Incoming
                ? dashboardRequestBlockStrings('resolvedRequests')
                : dashboardRequestBlockStrings('approvedRequests')
            }
            onClick={approvedOrResolvedClick}
          />
          <DashboardBlockRow
            count={requestList?.length?.toString() || '0'}
            label={dashboardRequestBlockStrings('allRequests')}
            onClick={handleViewAll}
          />
        </Stack>
      </DashboardBlockWrapper>
      <ApprovedOutgoingRequestDialog
        isOpen={isRequestApprovedDialogOpen}
        onClose={closeRequestApprovedDialog}
        {...requestApprovedDialogProps}
      />
      <PendingIncomingRequestDialog
        isOpen={isRequestPendingDialogOpen}
        onClose={closeRequestPendingDialog}
        requestList={requestList?.find((r) => r.status === RequestStatusEnum.Pending)}
      />
    </>
  );
};
