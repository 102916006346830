import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { ConfirmationStatusEnum } from 'src/__generated__/InternalApiTypes';
import { FormikSelectField } from 'src/components/formikcomponents/FormikSelectField';
import { assessmentFinishDialogForCBManagersStrings, commonStrings } from 'src/languages/en-UK';
import { canSubmitFormik } from 'src/utils/canSubmitFormik';
import * as Yup from 'yup';

interface AssessmentFinishDialogForCBManagersProps {
  onClose: () => void;
  onFinish: (confirmationStatus: ConfirmationStatusEnum | null) => void;
}

export const AssessmentFinishDialogForCBManagers = ({
  onClose,
  onFinish,
}: AssessmentFinishDialogForCBManagersProps): React.JSX.Element => {
  const formik = useFormik({
    initialValues: {
      confirmationStatus: null,
      confirmationCheckbox: false,
    },
    validationSchema: Yup.object().shape({
      confirmationStatus: Yup.string().required(commonStrings('required')),
      confirmationCheckbox: Yup.boolean().oneOf([true], ''),
    }),
    onSubmit: (values) => {
      onFinish(values?.confirmationStatus);
    },
  });

  const options = [
    {
      value: ConfirmationStatusEnum.Confirmed,
      label: commonStrings('compliant'),
    },
    {
      value: ConfirmationStatusEnum.Declined,
      label: commonStrings('nonCompliant'),
    },
  ];
  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <DialogTitle>{assessmentFinishDialogForCBManagersStrings('auditConclusion')}</DialogTitle>
      <DialogContent>
        <Typography
          marginBottom={3}
          variant="body1"
        >
          {assessmentFinishDialogForCBManagersStrings('decisionOnAuditResult')}
        </Typography>
        <FormikSelectField
          formik={formik}
          id="confirmationStatus"
          label={assessmentFinishDialogForCBManagersStrings('conclusion')}
          options={options}
          required
        />
        <FormControlLabel
          control={<Checkbox checked={formik.values.confirmationCheckbox} />}
          label={assessmentFinishDialogForCBManagersStrings('confirmAssessmentResults')}
          name="confirmationCheckbox"
          onChange={formik.handleChange}
          required
          sx={{ ml: 0, mt: 3, mb: 2 }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          mt: 3,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Button
          onClick={onClose}
          size="large"
          variant="outlined"
        >
          {commonStrings('cancel')}
        </Button>
        <Button
          disabled={!canSubmitFormik(formik, true)}
          size="large"
          type="submit"
          variant="contained"
        >
          {commonStrings('confirm')}
        </Button>
      </DialogActions>
    </form>
  );
};
