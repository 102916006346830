import { InfoRounded, MoreVertRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import {
  type MouseEvent,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { type ModuleListOutputItem } from 'src/__generated__/InternalApiTypes';
import { useAppDispatch, useAppSelector, useTenantId } from 'src/hooks';
import { commonStrings, selectionListStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import { useGetAssessmentPlotCultivationListQuery } from 'src/services/farmApi';
import { assessmentConfigActions } from 'src/store/assessmentConfig';

import { STANDARD_SELECTION_GLOBAL } from '../AssessmentConfigMatrix/AssessmentConfigMatrix.utils';
import { LoadingArea } from '../common';
import { MobileCompatibleTooltip } from '../common/MobileCompatibleTooltip';
import { SelectionListKebabMenu } from '../SelectionListKebabMenu';

export type CultivationOptionsType = {
  id: string;
  name: string;
};

export interface StandardOptionsType extends CultivationOptionsType {
  description: string;
  standardId: string;
  image?: string;
}

interface SelectionListProps {
  isDisabledInitialSelectAll: boolean;
  isLoading: boolean;
  onChange: (standardIds: string[], checked: boolean) => void;
  options: CultivationOptionsType[] | StandardOptionsType[];
  selectedIds: string[];
  title: ReactNode;
  isDisabled?: boolean;
  onDeleteCultivationClick?: (item: CultivationOptionsType | undefined) => void;
  onEditCultivationClick?: (item: CultivationOptionsType | undefined) => void;
  paidModuleList?: ModuleListOutputItem[];
  selectedRows?: string[];
  shouldChangeStandardSelection?: boolean;
}

export const SelectionList = ({
  onChange,
  onDeleteCultivationClick,
  onEditCultivationClick,
  options,
  paidModuleList,
  title,
  isDisabled = false,
  isLoading = false,
  isDisabledInitialSelectAll = false,
  selectedIds = [],
  selectedRows = [],
  shouldChangeStandardSelection = false,
}: SelectionListProps): React.JSX.Element => {
  const tid = useTenantId();
  const [assessmentId] = useContext(AssessmentPageContext);
  const dispatch = useAppDispatch();
  const { columns, rows, selections } = useAppSelector((state) => state.assessmentConfig);
  const buttonRef = useRef(null);
  let sortedOptions = options;
  if (paidModuleList?.length === 1) {
    sortedOptions = (options as StandardOptionsType[]).sort((a) => {
      if (a.standardId === paidModuleList[0].standard_id) {
        return -1;
      }
      return 1;
    });
  }
  // Edit and delete kebab menu
  const [threeDotsMenuAnchor, setThreeDotsMenuAnchor] = useState<null | HTMLElement>(null);
  const openThreeDotsMenu = Boolean(threeDotsMenuAnchor);
  const [selectedCultivationItem, setSelectedCultivationItem] = useState<CultivationOptionsType>();
  const { data: plotCultivationList, isLoading: isPlotCultivationListLoading } =
    useGetAssessmentPlotCultivationListQuery(
      { tid, assessmentId },
      { skip: !tid || !assessmentId },
    );

  const handleClickMenu = (event: MouseEvent<HTMLElement>, cultivation: CultivationOptionsType) => {
    setThreeDotsMenuAnchor(event.currentTarget);
    setSelectedCultivationItem(cultivation);
  };

  const handleDeleteCultivation = () => {
    onDeleteCultivationClick?.(selectedCultivationItem);
    setThreeDotsMenuAnchor(null);
  };

  const handleEditCultivation = () => {
    onEditCultivationClick?.(selectedCultivationItem);
    setThreeDotsMenuAnchor(null);
  };

  const clearSelectedCultivationItem = () => {
    setSelectedCultivationItem(undefined);
  };

  const handleChange = useCallback(
    (changedIds: string[], checked: boolean) => {
      changedIds.forEach((selectedId) => {
        if (shouldChangeStandardSelection) {
          // To change standard
          const existingSelection = selections.find((s) => s.colId === selectedId);
          dispatch(
            assessmentConfigActions.changeStandardSelection({
              selectedRows,
              selection: {
                colId: selectedId,
                rowId: existingSelection?.rowId || STANDARD_SELECTION_GLOBAL,
                malusPoint: existingSelection?.malusPoint,
              },
              isChecked: checked,
            }),
          );
        } else {
          // To change cultivation
          const existingSelection = selections.find((s) => s.rowId === selectedId);
          dispatch(
            assessmentConfigActions.changeCultivationSelection({
              selection: {
                colId: existingSelection?.colId || STANDARD_SELECTION_GLOBAL,
                rowId: selectedId,
                malusPoint: existingSelection?.malusPoint,
              },
              isChecked: checked,
            }),
          );
        }
      });
    },
    [dispatch, selectedRows, selections, shouldChangeStandardSelection],
  );

  const createOnClick =
    (id: string): (() => void) =>
    () => {
      if (selectedIds.includes(id)) {
        onChange([id], false);
        handleChange([id], false);
      } else {
        onChange([id], true);
        handleChange([id], true);
      }
    };

  const isPaidModule = useCallback(
    (item: StandardOptionsType) => paidModuleList?.some((p) => p.standard_id === item.standardId),
    [paidModuleList],
  );

  const handleSelectAll = useCallback((): void => {
    if (isDisabled) {
      sortedOptions.forEach((item) => {
        if (isPaidModule(item)) {
          onChange([item.id], true);
          handleChange([item.id], true);
        }
      });
    } else {
      onChange(
        sortedOptions.map((item) => item.id),
        true,
      );
      handleChange(
        sortedOptions.map((item) => item.id),
        true,
      );
    }
  }, [handleChange, isDisabled, isPaidModule, onChange, sortedOptions]);

  const handleSelectNone = (): void => {
    onChange(
      sortedOptions.map((item) => item.id),
      false,
    );
    handleChange(
      sortedOptions.map((item) => item.id),
      false,
    );
  };

  useEffect(() => {
    // To unselect all deleted cultivation
    if (
      !shouldChangeStandardSelection &&
      selectedCultivationItem &&
      !sortedOptions?.some((opt) => opt.id === selectedCultivationItem.id)
    ) {
      onChange([selectedCultivationItem.id], false);
      handleChange([selectedCultivationItem.id], false);
    }
    // TODOHasan: Do not add onChange and handleChange as a dependency. Find a correct way
  }, [sortedOptions, selectedCultivationItem, shouldChangeStandardSelection]);

  useEffect(() => {
    // To select all standard and cultivations if just started to assessment by default
    if (
      (columns.length === 0 || rows.length === 0) &&
      sortedOptions.length > 0 &&
      !isDisabledInitialSelectAll
    ) {
      handleSelectAll();
    }

    // TODOHasan: Do not add handleSelectAll as a dependency. Find a correct way
  }, [columns, isDisabledInitialSelectAll, sortedOptions.length, rows]);

  const { can_be_deleted: canBeDeleted, can_be_edited: canBeEdited } =
    plotCultivationList?.find((cultivation) => cultivation.uuid === selectedCultivationItem?.id) ||
    {};

  const renderItem = () => {
    if (isLoading) {
      return <LoadingArea />;
    }
    return sortedOptions.length === 0 && !shouldChangeStandardSelection ? (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
      >
        <Typography
          color="textSecondary"
          marginBottom={2}
          variant="overline"
        >
          {selectionListStrings('noCultivationsAdded')}
        </Typography>
      </Box>
    ) : (
      <List
        dense
        sx={{ width: '100%', py: 0 }}
      >
        {(sortedOptions as StandardOptionsType[]).map((item) => {
          const { can_be_deleted: isDeleted, can_be_edited: isEdited } =
            plotCultivationList?.find((cultivation) => cultivation.uuid === item.id) || {};
          const showKebabMenuIcon =
            !shouldChangeStandardSelection &&
            !isPlotCultivationListLoading &&
            (isDeleted || isEdited);
          const hasTooltip = shouldChangeStandardSelection && item.description;

          return (
            <ListItem
              key={item.id}
              disablePadding
            >
              <ListItemButton
                disabled={isPaidModule(item) ? false : isDisabled}
                onClick={createOnClick(item.id)}
                sx={{ mb: 1, py: '5px', px: 0 }}
              >
                <ListItemIcon
                  sx={{
                    justifyContent: 'center',
                    minWidth: 5.25,
                    pl: 0.5,
                  }}
                >
                  <Checkbox
                    checked={selectedIds.includes(item.id)}
                    disableRipple
                    edge="start"
                    sx={{ ml: 0 }}
                    tabIndex={-1}
                  />
                </ListItemIcon>
                {item.image && (
                  <img
                    alt={`list item: ${item.name}`}
                    src={item.image}
                    style={{
                      marginRight: '4px',
                      height: '40px',
                      width: '40px',
                    }}
                  />
                )}
                <ListItemText primary={item.name} />
              </ListItemButton>
              {showKebabMenuIcon && (
                <IconButton
                  aria-controls={openThreeDotsMenu ? 'cultivation-three-dot-menu' : undefined}
                  aria-expanded={openThreeDotsMenu ? 'true' : undefined}
                  aria-haspopup="true"
                  aria-label="more"
                  id="cultivation-three-dot-icon"
                  onClick={(evt) => handleClickMenu(evt, item)}
                  sx={{ mb: 1 }}
                >
                  <MoreVertRounded />
                </IconButton>
              )}
              {hasTooltip && (
                <MobileCompatibleTooltip
                  sx={{ alignSelf: 'center', mb: 1 }}
                  title={item.description}
                >
                  <InfoRounded
                    color="secondary"
                    fontSize="small"
                  />
                </MobileCompatibleTooltip>
              )}
            </ListItem>
          );
        })}
      </List>
    );
  };

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      flexGrow={1}
    >
      <Typography
        marginBottom={2}
        variant="button"
      >
        {title}
      </Typography>
      <Paper
        sx={{ overflowY: 'auto', flex: '1 auto', p: 2 }}
        variant="outlined"
      >
        {renderItem()}
        {openThreeDotsMenu && (
          <SelectionListKebabMenu
            onClose={clearSelectedCultivationItem}
            onDeleteCultivationClick={handleDeleteCultivation}
            onEditCultivationClick={handleEditCultivation}
            setThreeDotsMenuAnchor={setThreeDotsMenuAnchor}
            showDeleteItem={canBeDeleted}
            showEditItem={canBeEdited}
            threeDotsMenuAnchor={threeDotsMenuAnchor}
          />
        )}
      </Paper>
      <Stack
        direction="row"
        marginBottom={1}
        marginTop={2}
      >
        <ButtonGroup size="small">
          <Button
            ref={buttonRef}
            disabled={
              isLoading || sortedOptions.length === 0 || selectedIds.length === sortedOptions.length
            }
            onClick={handleSelectAll}
          >
            {commonStrings('all')}
          </Button>
          <Button
            disabled={isLoading || selectedIds?.length < 1}
            onClick={handleSelectNone}
          >
            {selectionListStrings('none')}
          </Button>
        </ButtonGroup>
      </Stack>
    </Box>
  );
};
