import {
  type CamundaProcessDefinitionListOutputItem,
  type CamundaProcessInstanceByAssessmentIdDetailOutput,
  type CamundaProcessInstancesByAssessmentIdRetrieveParams,
  type CamundaTaskCompleteInput,
  type CamundaTaskCompleteOutput,
  type CamundaTaskDefinitionListOutputItem,
  type CamundaTaskListOutputItem,
  type CamundaTasksListParams,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { injectQueryParams, type UpdateParamsWithTenantId } from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'camunda';

const camundaEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCamundaTaskDefinitionList: build.query<CamundaTaskDefinitionListOutputItem[], string>({
      query: (processDefinitionId) => `/${RESOURCE}/task-definitions/${processDefinitionId}/`,
      providesTags: [TagType.CamundaTaskDefinitionList],
    }),
    getCamundaProcessDefinitionList: build.query<CamundaProcessDefinitionListOutputItem[], string>({
      query: (tid) => `/tid/${tid}/${RESOURCE}/process-definitions/`,
      providesTags: [TagType.CamundaProcessDefinitionList],
    }),
    getCamundaTaskList: build.query<CamundaTaskListOutputItem[], CamundaTasksListParams>({
      query: ({ tid, ...restParams }) =>
        injectQueryParams(`/tid/${tid}/${RESOURCE}/tasks/`, restParams),
      providesTags: [TagType.CamundaTaskList],
    }),
    getCamundaProcessInstanceByAssessmentId: build.query<
      CamundaProcessInstanceByAssessmentIdDetailOutput,
      CamundaProcessInstancesByAssessmentIdRetrieveParams
    >({
      query: ({ uuid: assessmentId, tid, ...restParams }) => ({
        url: injectQueryParams(
          `/tid/${tid}/${RESOURCE}/process-instances/by-assessment-id/${assessmentId}/`,
          restParams,
        ),
        method: 'GET',
      }),
    }),
    completeCamundaTask: build.mutation<
      CamundaTaskCompleteOutput,
      UpdateParamsWithTenantId<CamundaTaskCompleteInput>
    >({
      query: ({ id, tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/tasks/${id}/complete/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TagType.CamundaTask, TagType.CamundaTaskList],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCamundaTaskDefinitionListQuery,
  useGetCamundaProcessDefinitionListQuery,
  useGetCamundaTaskListQuery,
  useGetCamundaProcessInstanceByAssessmentIdQuery,
  useCompleteCamundaTaskMutation,
} = camundaEndpoints;
