import {
  type UseMutation,
  type UseMutationStateResult,
} from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useEffect, useState } from 'react';
import { useTenantId } from 'src/hooks';

type UseBulkMutationOutput = {
  queue: string[];
  enqueueIds: (ids: string[]) => void;
  isLoading: boolean;
};

export const useBulkMutation = (
  // Since it is dynamic, it must be any type.
  mutationHook: UseMutation<any>,
  onComplete?: () => void,
  // Since it is dynamic, it must be any type.
  onAdvance?: (result: UseMutationStateResult<any, any>) => void,
  additionalReqParams?: Object,
): UseBulkMutationOutput => {
  const tid = useTenantId();

  const [mutate, mutateResult] = mutationHook();
  const [isLoading, setIsLoading] = useState(false);
  const [queue, setQueue] = useState<string[]>([]);

  const enqueueIds = (items: string[]): void => {
    setQueue(items);
    setIsLoading(true);
  };

  useEffect(() => {
    if (queue.length > 0) {
      const id = queue[0];
      mutate({ id, tid, ...additionalReqParams });
    } else {
      setIsLoading(false);
      if (onComplete) {
        onComplete();
      }
    }
    // TODOHasan: Do not add additionalReqParams and onComplete as a dependency. Find a correct way.
  }, [mutate, queue, tid]);

  useEffect(() => {
    if (mutateResult.isSuccess || mutateResult.isError) {
      if (onAdvance) {
        onAdvance(mutateResult);
      }
      mutateResult.reset();
      const updatedQueue = [...queue];
      updatedQueue.shift();
      setQueue(updatedQueue);
    }
  }, [queue, mutateResult, onAdvance]);

  return {
    queue,
    enqueueIds,
    isLoading,
  };
};
