import { Box, Link as MuiLink, TextField, Typography } from '@mui/material';
import { organizationClientIdentifierFormLeafStrings } from 'src/languages/en-UK';
import { appColors } from 'src/theme';

export const OrganizationClientIdentifierFormLeaf = (): React.JSX.Element => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
    <Typography
      sx={{ lineHeight: 3.43 }}
      variant="subtitle2"
    >
      {organizationClientIdentifierFormLeafStrings('charityMembership')}
    </Typography>
    <TextField
      disabled
      label={organizationClientIdentifierFormLeafStrings('charityMembershipNumber')}
      value=""
    />
    <TextField
      disabled
      label={organizationClientIdentifierFormLeafStrings('charityMembershipType')}
      value=""
    />
    <Box sx={{ mt: 2 }}>
      <Typography
        sx={{ lineHeight: '20px' }}
        variant="subtitle2"
      >
        {organizationClientIdentifierFormLeafStrings('marqueMembership')}
      </Typography>
      <Typography
        component="span"
        sx={{ color: 'text.disabled' }}
        variant="caption"
      >
        {organizationClientIdentifierFormLeafStrings('marqueMembershipDesc')}
        <MuiLink
          component="a"
          sx={{
            ml: 0.5,
            cursor: 'pointer',
            color: appColors.blue,
          }}
          variant="caption"
        >
          {organizationClientIdentifierFormLeafStrings('certificationBody')}
        </MuiLink>
      </Typography>
    </Box>
    <TextField
      disabled
      label={organizationClientIdentifierFormLeafStrings('marqueMembershipNumber')}
      value=""
    />
    <TextField
      disabled
      label={organizationClientIdentifierFormLeafStrings('certificationBodyName')}
      value=""
    />
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <TextField
          disabled
          label={organizationClientIdentifierFormLeafStrings('marqueEquivalenceScheme')}
          value=""
        />
        <Typography
          sx={{ color: 'text.disabled', mt: 0.5, mx: 1.75 }}
          variant="caption"
        >
          {organizationClientIdentifierFormLeafStrings('marqueEquivalenceSchemeInfo')}
        </Typography>
      </Box>
      <TextField
        disabled
        label={organizationClientIdentifierFormLeafStrings('marqueEquivalenceSchemeNumber')}
        sx={{ flex: 1 }}
        value=""
      />
    </Box>
    <Box sx={{ display: 'flex', gap: 2 }}>
      <TextField
        disabled
        label={organizationClientIdentifierFormLeafStrings('farmAssuranceScheme')}
        sx={{ flex: 1 }}
        value=""
      />
      <TextField
        disabled
        label={organizationClientIdentifierFormLeafStrings('farmAssuranceNumber')}
        sx={{ flex: 1 }}
        value=""
      />
    </Box>
    <Typography
      sx={{ lineHeight: 3.43 }}
      variant="subtitle2"
    >
      {organizationClientIdentifierFormLeafStrings('otherMemberships')}
    </Typography>
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <TextField
        label={organizationClientIdentifierFormLeafStrings('countryParishHoldingNumber')}
        value=""
      />
      <Typography
        sx={{ color: 'text.disabled', mt: 0.5, mx: 1.75 }}
        variant="caption"
      >
        {organizationClientIdentifierFormLeafStrings('countryParishHoldingNumberInfo')}
      </Typography>
    </Box>
  </Box>
);
