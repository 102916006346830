import { ArrowDropDown } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { groupBy } from 'lodash';
import React, { useContext, useState } from 'react';
import * as styles from 'src/components/common/ExpandableArea/ExpandableArea.module.scss';
import { ThemeContext } from 'src/components/ThemeProvider';
import {
  ControlPointFilterContext,
  type FilterStateType,
} from 'src/context/ControlPointFilterContext';
import { commonStrings, filtersStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import { useGetControlPointCriticalityListQuery } from 'src/services/farmApi';

type OptionType = {
  value: string | boolean;
  label: string;
  standard_id?: string;
  standard_name?: string;
};

type FiltersType = {
  id: keyof FilterStateType;
  label: string;
  options: OptionType[];
  isLoading?: boolean;
};

interface SelectedFilterItemType extends OptionType {
  id: string;
}

export const Filters = (): React.JSX.Element => {
  const { theme } = useContext(ThemeContext);
  const [assessmentId] = useContext(AssessmentPageContext);

  const { data: controlPointCriticalityList, isLoading: isControlPointCriticalityListLoading } =
    useGetControlPointCriticalityListQuery({ assessment_id: assessmentId });

  const filters: FiltersType[] = [
    {
      id: 'criticality_id',
      label: commonStrings('criticality'),
      options: (controlPointCriticalityList || [])
        .map((item) => ({
          label: item.name,
          value: item.uuid || '',
          standard_id: item.standard_id,
          standard_name: item.standard_name,
        }))
        .sort((a, b) => b.standard_id.localeCompare(a.standard_id)),
      isLoading: isControlPointCriticalityListLoading,
    },
    {
      id: 'has_evidence',
      label: filtersStrings('evidence'),
      options: [
        { label: filtersStrings('hasEvidence'), value: true },
        { label: filtersStrings('doesntHaveEvidence'), value: false },
      ],
    },
    {
      id: 'is_flagged',
      label: filtersStrings('flagged'),
      options: [
        { label: filtersStrings('hasBeenFlagged'), value: true },
        { label: filtersStrings('hasNotBeenFlagged'), value: false },
      ],
    },
    {
      id: 'has_comments',
      label: commonStrings('comments'),
      options: [
        { label: filtersStrings('hasComments'), value: true },
        { label: filtersStrings('doesntHaveComments'), value: false },
      ],
    },
    {
      id: 'has_shortcomings',
      label: commonStrings('nonConformities'),
      options: [
        { label: filtersStrings('hasNonConformityRecorded'), value: true },
        { label: filtersStrings('doesntHaveNonConformityRecorded'), value: false },
      ],
    },
    {
      id: 'answer_type',
      label: filtersStrings('answers'),
      options: [
        { label: filtersStrings('fullyAnswered'), value: 'full' },
        { label: filtersStrings('partiallyAnswered'), value: 'partial' },
        { label: filtersStrings('notAnswered'), value: 'none' },
      ],
    },
  ];

  const [filterState, handleChange, resetFilterState] = useContext(ControlPointFilterContext);
  const filterStateForRequest = Object.entries(filterState).reduce(
    (acc, [key, value]) =>
      value !== 'ALL' && value?.[0] !== 'ALL'
        ? {
            ...acc,
            [key]: value,
          }
        : { ...acc },
    {},
  );
  const selectedFilterState: SelectedFilterItemType[] & SelectedFilterItemType[][] = [];
  Object.entries(filterStateForRequest).forEach(([key, value]) => {
    const selectedFilterItem = filters.find((f) => f.id === key);
    if (Array.isArray(value) && value?.length > 0) {
      const selectedItem = selectedFilterItem?.options
        ?.filter((o) => value.includes(o.value?.toString()))
        ?.map((opt) => ({
          ...opt,
          id: key,
        }));
      if (selectedItem) {
        selectedFilterState.push(selectedItem as SelectedFilterItemType[]);
      }
    } else {
      const selectedItem = selectedFilterItem?.options?.filter(
        (o) => o.value?.toString() === value?.toString(),
      )?.[0];
      if (selectedItem) {
        selectedFilterState.push({ ...selectedItem, id: key });
      }
    }
  });

  let initialExpandedId = filters[0].id;
  if (selectedFilterState?.length > 0) {
    const filteredData = filters.find((filterItem) =>
      selectedFilterState.some((selectedFilterItem) => selectedFilterItem.id === filterItem.id),
    );
    if (filteredData) {
      initialExpandedId = filteredData?.id;
    }
  }
  const [expandedId, setExpandedId] = useState<string | null>(initialExpandedId);

  const filterCriticalityRadioButtonItem = (filter: FiltersType) => {
    const groupsByStandard = groupBy(filter.options, 'standard_name');

    return Object.keys(groupsByStandard).map((standardName: string) => (
      <React.Fragment key={standardName}>
        <Typography
          fontWeight={500}
          py={1.5}
          variant="body2"
        >
          {standardName}
        </Typography>
        {filter.options
          .filter((o) => o.standard_name === standardName)
          .map((option) => (
            <FormControlLabel
              key={option.value?.toString()}
              checked={filterState[filter.id].includes(option.value as string)}
              control={<Checkbox />}
              label={option.label}
              // It's MUI FormControlLabel error so we should use any.
              onChange={(event) => handleChange?.(filter?.id, event as any, true)}
              sx={{
                px: 2,
                py: '7px',
                '& .MuiFormControlLabel-label': {
                  pl: 1.75,
                  maxWidth: 230,
                  wordWrap: 'break-word',
                },
              }}
              value={option.value}
            />
          ))}
      </React.Fragment>
    ));
  };

  const filterRadioButtonItem = (filter: FiltersType, isMultiSelect = false) => (
    <>
      <FormControlLabel
        key="ALL"
        checked={isMultiSelect ? filterState[filter.id][0] === 'ALL' : undefined}
        control={isMultiSelect ? <Checkbox /> : <Radio />}
        label={commonStrings('all')}
        // It's MUI FormControlLabel error so we should use any.
        onChange={(event) => handleChange?.(filter?.id, event as any, true)}
        sx={{
          py: '7px',
          '& .MuiFormControlLabel-label': { pl: 1.75 },
        }}
        value="ALL"
      />
      {filter.options.map((option) => (
        <FormControlLabel
          key={option.value?.toString()}
          checked={
            isMultiSelect ? filterState[filter.id].includes(option.value as string) : undefined
          }
          control={isMultiSelect ? <Checkbox /> : <Radio />}
          label={option.label}
          // It's MUI FormControlLabel error so we should use any.
          onChange={(event) => handleChange?.(filter?.id, event as any, true)}
          sx={{
            py: '7px',
            '& .MuiFormControlLabel-label': { pl: 1.75, maxWidth: 230, wordWrap: 'break-word' },
          }}
          value={option.value}
        />
      ))}
    </>
  );

  return (
    <Box
      paddingLeft={2}
      width={335}
    >
      <Box
        bgcolor="primary.contrastText"
        borderBottom={1}
        borderColor="divider"
        marginTop={-1}
        paddingX={2}
        paddingY={1}
        position="sticky"
        top={0}
        zIndex={2}
      >
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
        >
          <Typography variant="body2">{filtersStrings('filterBy')}</Typography>
          <Button
            color="info"
            onClick={resetFilterState || undefined}
            size="small"
            sx={{
              p: 0.5,
              letterSpacing: 0.46,
              textTransform: 'initial',
            }}
          >
            {filtersStrings('clearAll')}
          </Button>
        </Box>
        {selectedFilterState.map((selectedChip) =>
          Array.isArray(selectedChip)
            ? selectedChip?.map((chip) => (
                <Chip
                  key={`selectedFilter-${chip.value}`}
                  color="info"
                  label={chip.label}
                  onClick={() => {
                    handleChange?.(
                      chip.id,
                      {
                        target: {
                          value: chip.value as string,
                        },
                      },
                      true,
                    );
                  }}
                  onDelete={() => {
                    handleChange?.(
                      chip.id,
                      {
                        target: {
                          value: chip.value as string,
                        },
                      },
                      true,
                    );
                  }}
                  size="small"
                  sx={{ mr: 1 }}
                  variant="outlined"
                />
              ))
            : selectedChip && (
                <Chip
                  key={`selectedFilter-${selectedChip.label}`}
                  color="info"
                  label={selectedChip.label}
                  onClick={() => {
                    handleChange?.(selectedChip.id, {
                      target: { value: 'ALL' },
                    });
                  }}
                  onDelete={() => {
                    handleChange?.(selectedChip.id, {
                      target: { value: 'ALL' },
                    });
                  }}
                  size="small"
                  sx={{ mr: 1 }}
                  variant="outlined"
                />
              ),
        )}
      </Box>
      {filters.map((filter) => (
        <Accordion
          key={filter.id}
          disableGutters
          elevation={0}
          expanded={expandedId === filter.id}
          onChange={() => {
            if (expandedId === filter.id) {
              setExpandedId(null);
            } else {
              setExpandedId(filter.id);
            }
          }}
          square
          sx={{
            '&:before': {
              display: 'none',
            },
            '&:not(:first-of-type)': {
              borderTopColor: `${theme.palette.divider}!important`,
              borderTop: 1,
            },
          }}
        >
          <AccordionSummary
            aria-controls={`${filter.id}-content`}
            expandIcon={<ArrowDropDown />}
            id={`${filter.id}-header`}
            sx={{
              '& .MuiTypography-root': {
                letterSpacing: 0.15,
              },
              '& .MuiContainer-root': {
                pl: 2,
                pr: 1,
              },
            }}
          >
            <Typography>{filter.label}</Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: styles.AccordionDetailsRoot,
            }}
            sx={{
              '& .MuiTypography-root': {
                letterSpacing: 0.15,
              },
            }}
          >
            <Box paddingLeft={3.5}>
              <FormControl>
                {filter.id === 'criticality_id' ? (
                  filterCriticalityRadioButtonItem(filter)
                ) : (
                  <RadioGroup
                    aria-label={filter.label.toString()}
                    name={`${filter.label}-radioGroup`}
                    onChange={(event) => handleChange?.(filter?.id, event)}
                    value={filterState[filter.id]}
                  >
                    {filterRadioButtonItem(filter)}
                  </RadioGroup>
                )}
              </FormControl>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};
