import { CheckCircleOutlineRounded, RadioButtonUncheckedRounded } from '@mui/icons-material';
import { Box, Link as MuiLink, Skeleton, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { type GroupedVirtuosoHandle } from 'react-virtuoso';
import {
  type AssessmentCategoryEvidenceTypeControlPointListOutputItem,
  CategoryEvidenceTypeStatusEnum,
} from 'src/__generated__/InternalApiTypes';
import { useAppDispatch, useTenantId } from 'src/hooks';
import { assessmentEvidenceCategoryStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import {
  type TransformedEvidenceListOutputItem,
  useGetAssessmentCategoryEvidenceTypeControlPointListQuery,
  useGetAssessmentCategoryEvidenceTypeStatusQuery,
} from 'src/services/farmApi';
import { evidenceEndpoints } from 'src/services/farmApi/endpoints/evidence';
import { appColors } from 'src/theme';
import { parseHtml } from 'src/utils/parseHtml';

import {
  type CategoryData,
  useGetFilteredEvidenceList,
} from '../assessmentsteps/AssessmentEvidenceStep/AssessmentEvidenceStep.hooks';
import { SkeletonMultiLineText } from '../common/SkeletonMultiLineText';
import { type ControlPointGuidanceDialogProps } from '../dialogs/ControlPointGuidanceDialog';
import { type EvidenceFormsDialogProps } from '../dialogs/EvidenceFormsDialog';
import { type EvidenceReviewDialogProps } from '../drawers/EvidenceReviewDrawer';
import { EvidenceExpandableStack } from '../EvidenceExpandableStack';
import { EvidenceTable } from './EvidenceTable';
import { PartiallyCompletedIcon } from './PartiallyCompletedIcon';

interface AssessmentEvidenceCategoryProps {
  assessmentAgriplaceCriticalityIds: string[];
  category: CategoryData;
  criticalityId: string;
  index: number;
  level3CategoryQueue: {
    currentLevel3CategoryIndex: number;
    currentEvidenceTypeIndex: number;
    currentEvidenceItemIndex: number;
  };
  openEvidenceFormsDialog: (props: EvidenceFormsDialogProps) => void;
  openGuidanceDialog: (props: Omit<ControlPointGuidanceDialogProps, 'isOpen' | 'onClose'>) => void;
  openReviewDrawer: (params: EvidenceReviewDialogProps) => void;
  setCurrentEvidenceItem: React.Dispatch<React.SetStateAction<TransformedEvidenceListOutputItem>>;
  setEvidenceItemIndex: (ind: number) => void;
  setEvidenceTypeIndex: (ind: number) => void;
  setIsNextButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  virtuosoRef: React.MutableRefObject<GroupedVirtuosoHandle | null>;
}

export const getStatusIcon = (status: CategoryEvidenceTypeStatusEnum | undefined) => {
  switch (status) {
    case CategoryEvidenceTypeStatusEnum.Completed:
      return (
        <CheckCircleOutlineRounded
          color="success"
          fontSize="small"
        />
      );
    case CategoryEvidenceTypeStatusEnum.PartiallyCompleted:
      return <PartiallyCompletedIcon color={appColors.blue} />;
    case CategoryEvidenceTypeStatusEnum.NotCompleted:
      return (
        <RadioButtonUncheckedRounded
          color="info"
          fontSize="small"
        />
      );
    default:
      return (
        <Skeleton
          height={20}
          variant="circular"
          width={20}
        />
      );
  }
};

export const AssessmentEvidenceCategory = ({
  assessmentAgriplaceCriticalityIds,
  category,
  criticalityId,
  index,
  level3CategoryQueue,
  openEvidenceFormsDialog,
  openGuidanceDialog,
  openReviewDrawer,
  setCurrentEvidenceItem,
  setEvidenceItemIndex,
  setEvidenceTypeIndex,
  setIsNextButtonDisabled,
  virtuosoRef,
}: AssessmentEvidenceCategoryProps): React.JSX.Element => {
  const tid = useTenantId();
  const dispatch = useAppDispatch();
  const [assessmentId] = useContext(AssessmentPageContext);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(category.uuid);
  const [selectedType, setSelectedType] = useState<string | null | undefined>(
    category.evidenceTypes && category.evidenceTypes.length > 0
      ? category.evidenceTypes[0].uuid
      : '',
  );
  const [filteredAssessmentControlPoints, setFilteredAssessmentControlPoints] = useState<
    AssessmentCategoryEvidenceTypeControlPointListOutputItem[]
  >([]);

  const { filteredEvidenceList, isFilteredEvidenceListFetching } = useGetFilteredEvidenceList(
    selectedCategory,
    selectedType,
  );

  const { data: assessmentCategoryEvidenceTypeStatus } =
    useGetAssessmentCategoryEvidenceTypeStatusQuery(
      {
        tid,
        id: assessmentId,
        agriplace_criticality_id: criticalityId,
        category_id: category.uuid,
      },
      { skip: !tid || !assessmentId },
    );

  useEffect(() => {
    setSelectedCategory(category.uuid);
    setSelectedType(category?.evidenceTypes?.[level3CategoryQueue.currentEvidenceTypeIndex]?.uuid);
  }, [category.evidenceTypes, category.uuid, level3CategoryQueue.currentEvidenceTypeIndex]);

  useEffect(() => {
    if (level3CategoryQueue.currentEvidenceItemIndex >= filteredEvidenceList.length - 1) {
      setIsNextButtonDisabled(true);
    } else {
      setIsNextButtonDisabled(false);
    }
    if (!isFilteredEvidenceListFetching && filteredEvidenceList) {
      setCurrentEvidenceItem(filteredEvidenceList[level3CategoryQueue.currentEvidenceItemIndex]);
    }
  }, [
    filteredEvidenceList,
    isFilteredEvidenceListFetching,
    level3CategoryQueue.currentEvidenceItemIndex,
    setCurrentEvidenceItem,
    setIsNextButtonDisabled,
  ]);

  const calculateItemIsFocused = () => {
    const itemElement = document.getElementById(
      `${level3CategoryQueue.currentEvidenceItemIndex}_type_accordion`,
    );
    const bounding = itemElement?.getBoundingClientRect();
    if (
      !(
        bounding &&
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
    ) {
      // Item is not in view, scroll to the item
      virtuosoRef.current?.scrollToIndex?.({
        index: index - 1 >= 0 ? index - 1 : index,
        align: 'start',
        behavior: 'auto',
      });
    }
  };

  const handleChangeEvidenceType = (evidenceTypeIndex: number, categoryId: string): void => {
    setSelectedCategory(categoryId);
    setSelectedType(category?.evidenceTypes?.[evidenceTypeIndex]?.uuid);
    setEvidenceTypeIndex(evidenceTypeIndex);
    setTimeout(() => {
      calculateItemIsFocused();
    }, 500);
  };

  const handleOnReview = (evidenceId: string): void => {
    const evidenceIndex = filteredEvidenceList.findIndex(
      (evidence) => evidence.uuid === evidenceId,
    );
    const count = filteredEvidenceList.length;
    if (evidenceIndex >= 0 && evidenceIndex < count) {
      setCurrentEvidenceItem(filteredEvidenceList[evidenceIndex]);
      setEvidenceItemIndex(evidenceIndex);
    } else if (count > 0) {
      setCurrentEvidenceItem(filteredEvidenceList[0]);
      setEvidenceItemIndex(evidenceIndex);
    }
    openReviewDrawer({ evidenceTypeId: selectedType });
  };

  const { data: assessmentControlPointList, isLoading: isAssessmentControlPointListLoading } =
    useGetAssessmentCategoryEvidenceTypeControlPointListQuery(
      {
        tid,
        agriplace_criticality_id: assessmentAgriplaceCriticalityIds,
        assessment_id: assessmentId,
        category_id: category.uuid,
        evidence_type_id: selectedType,
      },
      { skip: !selectedType || !tid || !assessmentId },
    );

  useEffect(() => {
    if (!isAssessmentControlPointListLoading && assessmentControlPointList?.results) {
      setFilteredAssessmentControlPoints(assessmentControlPointList.results);
    }
  }, [assessmentControlPointList, isAssessmentControlPointListLoading, selectedType]);

  const controlPointIds = filteredAssessmentControlPoints.map((cp) => cp.uuid);

  return (
    <EvidenceExpandableStack
      key={category.uuid}
      elevation={0}
      expandedIndex={level3CategoryQueue.currentEvidenceTypeIndex}
      items={category.evidenceTypes?.map((evidenceType) => ({
        title: evidenceType.name,
        icon: getStatusIcon(
          assessmentCategoryEvidenceTypeStatus?.evidence_types?.find(
            (type) => type.uuid === evidenceType.uuid,
          )?.status,
        ),
        details: (
          <>
            <Box
              paddingBottom={4.5}
              paddingTop={2}
            >
              {isAssessmentControlPointListLoading ? (
                <SkeletonMultiLineText variant="body2" />
              ) : (
                <>
                  <Typography
                    display="-webkit-box"
                    marginBottom={2}
                    textTransform="uppercase"
                    variant="overline"
                  >
                    {assessmentEvidenceCategoryStrings('provideRelatedQuestions', {
                      evidenceTypeName: evidenceType.name,
                    })}
                  </Typography>
                  {filteredAssessmentControlPoints.map((assessmentControlPoint) => {
                    const {
                      code,
                      uuid: controlPointId,
                      display_code: displayCode,
                      logo: smallLogo,
                      main_text: mainText,
                    } = assessmentControlPoint || {};
                    return (
                      <Box
                        key={controlPointId}
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        paddingBottom={1}
                      >
                        {smallLogo && (
                          <img
                            alt={`list item: ${smallLogo?.name}`}
                            src={smallLogo?.file_object}
                            style={{
                              height: '20px',
                              width: '20px',
                              marginRight: '4px',
                            }}
                          />
                        )}
                        <Typography
                          component="span"
                          display="-webkit-box"
                          marginBottom={0}
                          overflow="hidden"
                          sx={{
                            width: 'calc(100% - 120px)',
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical',
                          }}
                          textOverflow="ellipsis"
                          variant="body2"
                        >
                          {parseHtml(`${displayCode || code}: ${mainText}`)}
                        </Typography>
                        <MuiLink
                          component="span"
                          onClick={() => {
                            openGuidanceDialog({ controlPointId });
                          }}
                          sx={{
                            ml: 1,
                            cursor: 'pointer',
                            color: appColors.blue,
                          }}
                          variant="body2"
                        >
                          {assessmentEvidenceCategoryStrings('readCriteria')}
                        </MuiLink>
                      </Box>
                    );
                  })}
                </>
              )}
            </Box>
            <EvidenceTable
              category={category}
              controlPointIds={controlPointIds}
              evidenceList={filteredEvidenceList}
              evidenceType={evidenceType}
              isFetching={isFilteredEvidenceListFetching}
              onReview={handleOnReview}
              openEvidenceFormsDialog={openEvidenceFormsDialog}
              refetchEvidenceList={() => {
                if (tid) {
                  dispatch(
                    evidenceEndpoints.endpoints.getEvidenceList.initiate(
                      { tid, is_archived: false },
                      {
                        forceRefetch: true,
                      },
                    ),
                  );
                }
              }}
              totalQuestionCount={filteredAssessmentControlPoints?.length}
            />
          </>
        ),
      }))}
      onChangeIndex={(ind) => handleChangeEvidenceType(ind, category.uuid)}
      variant="outlined"
    />
  );
};
