import { commonStrings } from 'src/languages/en-UK';
import { useGetProductListQuery } from 'src/services/farmApi';

import { FormikAutocompleteField } from '../FormikAutocompleteField';
import { type FormikTextFieldProps } from '../FormikTextField';

type FormikProductFieldProps = FormikTextFieldProps & {
  standardIds: string[];
};

/**
 * This component pre-fills a FormikSelectField with standard product options.
 */
export const FormikProductField = ({
  formik,
  id,
  label,
  standardIds,
  isLoading = false,
  ...restProps
}: FormikProductFieldProps) => {
  const { data: productList, isFetching } = useGetProductListQuery({ standard_id: standardIds });
  const options = (productList || [])
    .map((product) => ({
      value: product.uuid,
      label: product.name,
    }))
    ?.sort((a, b) => Number(a.label.localeCompare(b.label)));

  return (
    <FormikAutocompleteField
      formik={formik}
      id={id}
      isLoading={isLoading || isFetching}
      label={label || commonStrings('product')}
      options={options}
      {...restProps}
    />
  );
};
