import { useContext } from 'react';
import { TenantContext } from 'src/components/TenantProvider';
import { AuthStatus } from 'src/enums/AuthStatus';
import { useGetOrganizationQuery } from 'src/services/farmApi';

import { useUserAuth } from './useUserAuth';

export const useCurrentOrganization = (isSkip = false) => {
  const { organizationId, tenantId, activeMembership } = useContext(TenantContext);
  const { authStatus } = useUserAuth();

  return useGetOrganizationQuery(
    { id: organizationId, tid: tenantId },
    {
      skip:
        !activeMembership?.organization_id ||
        !organizationId ||
        !tenantId ||
        authStatus !== AuthStatus.LoggedIn ||
        isSkip,
    },
  );
};
