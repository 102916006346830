import { useContext } from 'react';
import { useGetAssessmentUserPermission } from 'src/hooks';
import { commonStrings, editCorrectiveActionDialogStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import {
  useCreateNonConformityAndCorrectiveActionMutation,
  useGetNonConformityAndCorrectiveActionQuery,
  useUpdateNonConformityAndCorrectiveActionMutation,
} from 'src/services/farmApi';
import * as Yup from 'yup';

import { FormikDateField } from '../formikcomponents/FormikDateField';
import { FormikDialog } from '../formikcomponents/FormikDialog';
import { FormikTextField } from '../formikcomponents/FormikTextField';

export interface EditCorrectiveActionDialogProps {
  correctiveActionId: string;
  isOpen: boolean;
  onClose: () => void;
  title: string;
}

export const EditCorrectiveActionDialog = ({
  correctiveActionId,
  onClose,
  title,
  isOpen = false,
}: EditCorrectiveActionDialogProps): React.JSX.Element => {
  const defaultTitle = commonStrings('editCorrectiveAction');
  const [assessmentId] = useContext(AssessmentPageContext);
  const permissions = useGetAssessmentUserPermission(assessmentId);

  return (
    <FormikDialog
      createHook={useCreateNonConformityAndCorrectiveActionMutation}
      entityId={correctiveActionId}
      fetchHook={useGetNonConformityAndCorrectiveActionQuery}
      fields={[
        {
          id: 'text',
          renderAs: (props) => (
            <FormikTextField
              {...props}
              label={editCorrectiveActionDialogStrings('correctiveActionDescription')}
              multiline
              required
            />
          ),
          validator: Yup.string().required(commonStrings('required')),
        },
        {
          id: 'reported_date',
          renderAs: (props) => (
            <FormikDateField
              {...props}
              label={commonStrings('dateCorrectiveActionsReported')}
            />
          ),
        },
      ]}
      hasCreatePermission={permissions?.corrective_actions?.create}
      hasUpdatePermission={permissions?.corrective_actions?.update}
      isOpen={isOpen}
      onClose={onClose}
      propertyName="text"
      title={title || defaultTitle}
      updateHook={useUpdateNonConformityAndCorrectiveActionMutation}
    />
  );
};
