import { type PayloadAction } from '@reduxjs/toolkit';

import { type AssessmentConfigState, type MatrixSelection } from '../types';

/**
 * Handles selections changes in the matrix.
 *
 * @param selections - Selection object containing the standard ID and cultivation ID
 */
export const changeSelection = (
  state: AssessmentConfigState,
  action: PayloadAction<{ selections: MatrixSelection[] }>,
): AssessmentConfigState => {
  const { selections } = action.payload;

  return {
    ...state,
    selections,
  };
};
