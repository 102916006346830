import { Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { RolesEnum } from 'src/__generated__/InternalApiTypes';
import { useCurrentUserRoles, useTenantId } from 'src/hooks';
import { commonStrings } from 'src/languages/en-UK';
import {
  type TransformedAssessmentControlPointNonConformityOutputItem,
  useGetAssessmentControlPointQuery,
} from 'src/services/farmApi';

import { NonConformityAndCorrectiveActionsView } from '../ControlPointNonConformityAndCorrectiveActionsView';
import { NewNonConformityForm } from './NewNonConformityForm';

interface ControlPointNonConformityProps {
  assessmentControlPointId: string;
  controlPointId: string;
  isDisable: boolean;
  isVisible: boolean;
}

export const ControlPointNonConformities = ({
  assessmentControlPointId,
  controlPointId,
  isDisable = false,
  isVisible = false,
}: ControlPointNonConformityProps): React.JSX.Element => {
  const tid = useTenantId();
  const currentUserRoles = useCurrentUserRoles();
  const isFarmManager = currentUserRoles.includes(RolesEnum.FarmManager);
  const isFarmEmployee = currentUserRoles.includes(RolesEnum.FarmEmployee);
  const [isNewCorrectiveActionFormVisible, setIsNewCorrectiveActionFormVisible] = useState(false);
  const EMPTY_ARRAY: TransformedAssessmentControlPointNonConformityOutputItem[] = [];

  const {
    shortcomings: controlPointNonConformityList,
    isLoading: isLoadingControlPointNonConformityList,
  } = useGetAssessmentControlPointQuery(
    {
      tid,
      id: assessmentControlPointId,
    },
    {
      selectFromResult: ({ data, isLoading }) => ({
        shortcomings: data?.shortcomings ?? EMPTY_ARRAY,
        isLoading,
      }),
    },
  );

  useEffect(() => {
    if (controlPointNonConformityList?.length > 0 && (isFarmManager || isFarmEmployee)) {
      setIsNewCorrectiveActionFormVisible(true);
    }
  }, [controlPointNonConformityList?.length, isFarmEmployee, isFarmManager]);

  return (
    !isLoadingControlPointNonConformityList && (
      <Stack
        spacing={1}
        sx={{
          '&&&': {
            mt: isVisible || controlPointNonConformityList?.length > 0 ? '24px' : 0,
          },
        }}
      >
        {((isVisible && !isDisable) || controlPointNonConformityList?.length > 0) && (
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={1}
          >
            <Typography
              color="textSecondary"
              variant="overline"
            >
              {commonStrings('nonConformities')}
            </Typography>
          </Stack>
        )}
        {(controlPointNonConformityList || []).map((nonConformity) => (
          <NonConformityAndCorrectiveActionsView
            key={nonConformity.uuid}
            controlPointId={controlPointId}
            isDisable={isDisable}
            isNewCorrectiveActionFormVisible={isNewCorrectiveActionFormVisible && !isDisable}
            nonConformity={nonConformity}
            setIsNewCorrectiveActionFormVisible={setIsNewCorrectiveActionFormVisible}
            shouldOpenCorrectiveActionSection={isFarmManager || isFarmEmployee}
          />
        ))}
        {isVisible &&
          !isDisable &&
          !isNewCorrectiveActionFormVisible &&
          controlPointNonConformityList?.length === 0 && (
            <NewNonConformityForm controlPointId={controlPointId} />
          )}
      </Stack>
    )
  );
};
