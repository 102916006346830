import { keyBy } from 'lodash';
import {
  type SimpleFile,
  type StandardLogoListOutputItem,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';

const RESOURCE = 'standard-logos';

interface TransformedStandardLogoListOutputItem extends SimpleFile {
  standard_id: string;
}

const standardLogosEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getStandardLogoList: build.query<Record<string, TransformedStandardLogoListOutputItem>, void>({
      query: () => `/${RESOURCE}/`,
      transformResponse: (
        response: StandardLogoListOutputItem[],
      ): Record<string, TransformedStandardLogoListOutputItem> =>
        keyBy(
          response.map((item) => ({ ...item.small_logo, standard_id: item.standard_id })),
          'uuid',
        ),
    }),
  }),
  overrideExisting: false,
});

export const { useGetStandardLogoListQuery } = standardLogosEndpoints;
