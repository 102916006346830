import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Stack, Tab, Typography } from '@mui/material';
import { type SyntheticEvent, useEffect, useState } from 'react';
import { ControlPointDetailGuidanceTabs } from 'src/enums/ControlPointDetailGuidanceTabs';
import { commonStrings, controlPointGuidanceTabStrings } from 'src/languages/en-UK';
import {
  useGetControlPointQuery,
  useGetControlPointVerificationMethodListQuery,
} from 'src/services/farmApi';
import { parseHtml } from 'src/utils/parseHtml';

import { LoadingArea } from '../common';
import { getInitialTab } from './ControlPointGuidanceTab.utils';

interface ControlPointGuidanceTabProps {
  controlPointId: string;
}
export const ControlPointGuidanceTab = ({
  controlPointId,
}: ControlPointGuidanceTabProps): React.JSX.Element => {
  const skip = !controlPointId;
  const { data: controlPointVerificationMethodList } =
    useGetControlPointVerificationMethodListQuery(null, {
      skip,
    });
  const { data: controlPointDetail, isFetching: isControlPointDetailFetching } =
    useGetControlPointQuery(controlPointId, { skip });

  const initialTab = getInitialTab(controlPointDetail);
  const [selectedTab, setSelectedTab] = useState<ControlPointDetailGuidanceTabs | null>(initialTab);

  const handleTabChange = (
    _event: SyntheticEvent,
    newIndexValue: ControlPointDetailGuidanceTabs,
  ): void => {
    setSelectedTab(newIndexValue);
  };

  useEffect(() => {
    if (!selectedTab && initialTab) {
      setSelectedTab(initialTab);
    }
  }, [controlPointDetail, initialTab, selectedTab]);

  if (isControlPointDetailFetching) {
    return (
      <div>
        <LoadingArea />
      </div>
    );
  }
  if (!selectedTab) {
    return (
      <Stack margin={2}>
        <Typography>{controlPointGuidanceTabStrings('noCriteriaOrGuidanceAdded')}</Typography>
      </Stack>
    );
  }

  return (
    <Stack spacing={2}>
      <Box width="100%">
        <TabContext value={selectedTab}>
          <Box
            borderBottom={1}
            borderColor="divider"
          >
            <TabList onChange={handleTabChange}>
              {controlPointDetail?.criteria !== null && (
                <Tab
                  label={commonStrings('criteria')}
                  value={ControlPointDetailGuidanceTabs.Criteria}
                />
              )}
              {controlPointDetail?.guidance !== null && (
                <Tab
                  label={controlPointGuidanceTabStrings('guidance')}
                  value={ControlPointDetailGuidanceTabs.Guidance}
                />
              )}
              {controlPointDetail?.justification_guideline !== null && (
                <Tab
                  label={controlPointGuidanceTabStrings('justificationGuideline')}
                  value={ControlPointDetailGuidanceTabs.JustificationGuideline}
                />
              )}
              {controlPointDetail?.verification_method_ids.length !== 0 && (
                <Tab
                  label={controlPointGuidanceTabStrings('verificationMethod')}
                  value={ControlPointDetailGuidanceTabs.VerificationMethod}
                />
              )}
            </TabList>
          </Box>
          <TabPanel value={ControlPointDetailGuidanceTabs.Criteria}>
            <Typography
              component="span"
              variant="body2"
            >
              {controlPointDetail?.criteria ? parseHtml(controlPointDetail.criteria) : ''}
            </Typography>
          </TabPanel>
          <TabPanel value={ControlPointDetailGuidanceTabs.Guidance}>
            <Typography
              component="span"
              variant="body2"
            >
              {controlPointDetail?.guidance ? parseHtml(controlPointDetail.guidance) : ''}
            </Typography>
          </TabPanel>
          <TabPanel value={ControlPointDetailGuidanceTabs.JustificationGuideline}>
            <Typography
              component="span"
              variant="body2"
            >
              {controlPointDetail?.justification_guideline
                ? parseHtml(controlPointDetail.justification_guideline)
                : ''}
            </Typography>
          </TabPanel>
          <TabPanel value={ControlPointDetailGuidanceTabs.VerificationMethod}>
            <ul>
              {(controlPointDetail?.verification_method_ids || []).map((verificationMethodId) => (
                <li key={verificationMethodId}>
                  {
                    (controlPointVerificationMethodList || []).find(
                      (item) => item.uuid === verificationMethodId,
                    )?.name
                  }
                </li>
              ))}
            </ul>
          </TabPanel>
        </TabContext>
      </Box>
    </Stack>
  );
};
