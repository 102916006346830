import { Cached, Check } from '@mui/icons-material';
import { AppBar, Box, Button, Chip, Container, Paper, Toolbar, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useContext, useState } from 'react';
import { AssessmentActionBarButtons } from 'src/components/AssessmentActionBarButtons';
import { LoadingArea } from 'src/components/common';
import { CustomNoRowsOverlay } from 'src/components/common/MobileCompatibleDataGrid';
import {
  ControlPointPresentationVariants,
  EvidenceReviewDrawer,
  type EvidenceReviewDrawerProps,
} from 'src/components/drawers/EvidenceReviewDrawer';
import { useDialogState, useTenantId } from 'src/hooks';
import { assessmentAuditorEvidenceStepStrings, commonStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import {
  type TransformedEvidenceListOutputItem,
  useGetAssessmentControlPointEvidenceListQuery,
  useGetAssessmentQuery,
  useGetEvidenceListQuery,
} from 'src/services/farmApi';
import { DATE_DISPLAY_FORMAT } from 'src/settings';
import { appColors } from 'src/theme';

import { type StepperContentBaseProps } from '../StepperPage';

export const AssessmentAuditorEvidenceStep = ({
  maxWidth = 'xl',
  ...props
}: StepperContentBaseProps): React.JSX.Element => {
  const tid = useTenantId();
  const [assessmentId] = useContext(AssessmentPageContext);
  const { data: assessment } = useGetAssessmentQuery(
    { tid, id: assessmentId },
    { skip: !assessmentId || !tid },
  );
  const [currentEvidenceItem, setCurrentEvidenceItem] =
    useState<TransformedEvidenceListOutputItem | null>(null);
  const [currentEvidenceItemIndex, setCurrentEvidenceItemIndex] = useState<number | null>(null);
  const [isNextTaskLoading, setIsNextTaskLoading] = useState(false);
  const [isPreviousTaskLoading, setIsPreviousTaskLoading] = useState(false);
  const clientIdentifierIds: string[] = assessment?.client_identifiers
    ? assessment?.client_identifiers
    : [];

  const navigateToNextStep = () => {
    if (props.isFinalTask) {
      props.openFinishDialog();
    } else {
      setIsNextTaskLoading(true);
      props.moveToNextTask();
    }
  };

  const navigateToPreviousStep = () => {
    setIsPreviousTaskLoading(true);
    props.moveToPreviousTask();
  };

  const { data: evidenceList, isLoading: isEvidenceListLoading } = useGetEvidenceListQuery(
    {
      tid,
      client_identifier_id: clientIdentifierIds,
      auditor_assessment_id: assessmentId,
      is_expired: false,
      is_archived: false,
    },
    { skip: !tid },
  );

  // DIALOGS
  const [isReviewDrawerOpen, openReviewDrawer, closeReviewDrawer] =
    useDialogState<EvidenceReviewDrawerProps>();

  const { data: assessmentControlPointEvidenceList } =
    useGetAssessmentControlPointEvidenceListQuery(
      {
        tid,
        assessment_id: [assessmentId],
      },
      { skip: !tid },
    );

  const getAttachedToControlPointCount = (evidenceId: string): number =>
    (assessmentControlPointEvidenceList || []).filter(
      (attachedEvidence) => attachedEvidence.evidence_id === evidenceId,
    ).length;

  const nextEvidenceItem = () => {
    if (currentEvidenceItemIndex === evidenceList.length - 1) {
      closeReviewDrawer();
      return;
    }

    setCurrentEvidenceItem(evidenceList[currentEvidenceItemIndex + 1]);
    setCurrentEvidenceItemIndex(currentEvidenceItemIndex + 1);
  };

  const renderEvidenceList = () =>
    evidenceList?.length === 0
      ? CustomNoRowsOverlay(assessmentAuditorEvidenceStepStrings('noEvidenceWasProvided'))
      : evidenceList?.map((evidenceItem, i) => {
          const attachedToControlPointCount = getAttachedToControlPointCount(evidenceItem.uuid);
          return (
            <Box
              key={evidenceItem.uuid}
              alignItems="center"
              display="grid"
              gridTemplateAreas={`
              "name expiryDate status attachedTo review"
              "name expiryDate status attachedTo review"
            `}
              gridTemplateColumns={{
                xs: '50px repeat(2, 1fr) auto',
                md: 'minmax(0, 2fr) repeat(3, 1fr) auto',
              }}
              marginY={2}
              sx={{
                '& .MuiTypography-h3': {
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  maxWidth: '85%',
                },
              }}
            >
              <Box gridArea="name">
                <Typography
                  color="text.disabled"
                  variant="body2"
                >
                  {commonStrings('name')}
                </Typography>
                <Typography
                  overflow="hidden"
                  sx={{
                    textDecoration: 'underline',
                  }}
                  textOverflow="ellipsis"
                  variant="body2"
                  whiteSpace="nowrap"
                >
                  {evidenceItem.name}
                </Typography>
              </Box>
              <Box gridArea="expiryDate">
                <Typography
                  color="text.disabled"
                  variant="body2"
                >
                  {commonStrings('expiryDate')}
                </Typography>
                <Typography variant="body2">
                  {format(evidenceItem.expiry_date, DATE_DISPLAY_FORMAT)}
                </Typography>
              </Box>
              <Box
                display={{ xs: 'none', sm: 'block' }}
                gridArea="status"
              >
                <Typography
                  color="text.disabled"
                  variant="body2"
                >
                  {commonStrings('status')}
                </Typography>
                <Chip
                  icon={attachedToControlPointCount > 0 ? <Check /> : <Cached />}
                  label={
                    attachedToControlPointCount > 0
                      ? commonStrings('attached')
                      : commonStrings('preloaded')
                  }
                  size="small"
                  variant="outlined"
                />
              </Box>
              <Box
                gridArea="attachedTo"
                marginRight={2}
              >
                <Typography
                  color="text.disabled"
                  variant="body2"
                >
                  {commonStrings('attachedTo')}
                </Typography>
                <Typography variant="body2">
                  {assessmentAuditorEvidenceStepStrings('questionsWithNumber', {
                    attachedToControlPointCount,
                  })}
                </Typography>
              </Box>
              <Box gridArea="review">
                <Button
                  color="info"
                  onClick={() => {
                    openReviewDrawer();
                    setCurrentEvidenceItem(evidenceItem);
                    setCurrentEvidenceItemIndex(i);
                  }}
                  size="small"
                  variant="contained"
                >
                  {assessmentAuditorEvidenceStepStrings('reviewAndAttach')}
                </Button>
              </Box>
            </Box>
          );
        });

  return (
    <>
      <Container maxWidth={maxWidth}>
        <Paper
          sx={{
            p: 2,
            mb: 2,
          }}
        >
          <Typography
            marginBottom={3}
            variant="h1"
          >
            {assessmentAuditorEvidenceStepStrings('reviewEvidenceAttachedByTheFarmManager')}
          </Typography>
          <Typography
            marginBottom={2}
            variant="body1"
          >
            {assessmentAuditorEvidenceStepStrings('evidenceReviewStepDescriptionForAuditor')}
          </Typography>
        </Paper>
        <Paper>
          <Box
            border="1px solid"
            borderColor={appColors.greyBorder}
            borderRadius="6px"
            paddingX={{ xs: 1, sm: 4 }}
            paddingY={4}
          >
            {isEvidenceListLoading ? <LoadingArea /> : renderEvidenceList()}
          </Box>
          {isReviewDrawerOpen && (
            <EvidenceReviewDrawer
              evidenceId={currentEvidenceItem?.uuid}
              hasAuditorAssessmentId
              isDisableNextButton={currentEvidenceItemIndex === evidenceList.length - 1}
              isOpen={isReviewDrawerOpen}
              onClose={closeReviewDrawer}
              onNext={nextEvidenceItem}
              variant={ControlPointPresentationVariants.DATATABLE}
            />
          )}
        </Paper>
      </Container>
      <Toolbar />
      <AppBar
        position="fixed"
        sx={{ top: 'auto', bottom: 0 }}
      >
        <Container maxWidth={maxWidth}>
          <AssessmentActionBarButtons
            containerStyle={{ justifyContent: 'right' }}
            isDisabled={isPreviousTaskLoading || isNextTaskLoading}
            isFinalTask={props.isFinalTask}
            isFirstTask={props.isFirstTask}
            isNextButtonLoading={isNextTaskLoading}
            isPreviousButtonLoading={isPreviousTaskLoading}
            nextTaskName={props.nextTaskName}
            onNext={navigateToNextStep}
            onPrevious={navigateToPreviousStep}
          />
        </Container>
      </AppBar>
    </>
  );
};
