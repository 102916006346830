import { omit } from 'lodash';
import { useMemo } from 'react';
import {
  type UserProfileUpdateInput,
  type UserProfileUpdateOutput,
} from 'src/__generated__/InternalApiTypes';
import { TabbedPage } from 'src/components/common/TabbedPage';
import { AccountDetailsForm } from 'src/components/forms/AccountDetailsForm';
import { AccountNotificationsForm } from 'src/components/forms/AccountNotificationsForm';
import { AccountInvitationTable } from 'src/components/tables/AccountInvitationTable';
import { useAppDispatch, useGetCurrentUserProfile, useUserAuth } from 'src/hooks';
import { accountPageStrings, commonStrings } from 'src/languages/en-UK';
import { extractErrorMessage, useUpdateUserProfileMutation } from 'src/services/farmApi';
import { openSnackbar } from 'src/store/snackbarSlice';

export const AccountPage = (): React.JSX.Element => {
  const dispatch = useAppDispatch();
  const { userId } = useUserAuth();
  const { data: currentUser, isLoading: isCurrentUserProfileLoading } =
    useGetCurrentUserProfile() || {};
  const [updateUserProfile, updateUserProfileResult] = useUpdateUserProfileMutation();

  const tabs = useMemo(() => {
    const handleSubmit = async (values: UserProfileUpdateInput) => {
      try {
        if (userId) {
          await updateUserProfile({
            id: userId,
            body: {
              ...values,
            },
          });
          dispatch(
            openSnackbar({
              message: accountPageStrings('successfullySavedChanges'),
              severity: 'success',
            }),
          );
        }
      } catch (error) {
        const message = extractErrorMessage(error);
        dispatch(openSnackbar({ message, severity: 'error' }));
      }
    };
    return [
      {
        slug: 'details',
        title: commonStrings('details'),
        content: (
          <AccountDetailsForm
            initialValues={
              omit(currentUser, ['membership', 'account_type', 'uuid']) as Omit<
                UserProfileUpdateOutput,
                'memberships' | 'account_type' | 'uuid'
              >
            }
            isLoading={!!isCurrentUserProfileLoading}
            isSubmitLoading={updateUserProfileResult?.isLoading}
            onSubmit={handleSubmit}
            showLogoutButton={!!(currentUser?.memberships && currentUser.memberships.length <= 0)}
            userId={currentUser?.uuid}
          />
        ),
      },
      {
        slug: 'invitations',
        title: commonStrings('invitations'),
        content: (
          <AccountInvitationTable
            currentUser={currentUser}
            isLoading={!!isCurrentUserProfileLoading}
          />
        ),
      },
      {
        slug: 'notifications',
        title: accountPageStrings('notifications'),
        content: <AccountNotificationsForm />,
      },
    ];
  }, [
    currentUser,
    dispatch,
    isCurrentUserProfileLoading,
    updateUserProfile,
    updateUserProfileResult?.isLoading,
    userId,
  ]);

  return (
    <TabbedPage
      tabs={tabs}
      title={accountPageStrings('userAccount')}
    />
  );
};
