import { AddRounded, CloseRounded, EditRounded, LinkRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link as MuiLink,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  GridActionsCellItem,
  type GridEnrichedColDef,
  type GridRenderCellParams,
  type GridRowModel,
  type GridRowParams,
  type GridRowsProp,
} from '@mui/x-data-grid-pro';
import { addYears, format } from 'date-fns';
import { differenceBy } from 'lodash';
import { type ReactNode, useContext, useState } from 'react';
import {
  type EvidenceCreateOutput,
  type EvidenceTypeListOutputItem,
} from 'src/__generated__/InternalApiTypes';
import {
  useAppDispatch,
  useDialogState,
  useGetAssessmentUserPermission,
  useMutationFeedback,
  useTenantId,
} from 'src/hooks';
import { commonStrings, evidenceTableUploadDialogStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import {
  type TransformedEvidenceListOutputItem,
  useCreateBulkAssessmentControlPointEvidenceMutation,
  useGetEvidenceListQuery,
  useGetEvidenceTypeListQuery,
} from 'src/services/farmApi';
import { DATE_DISPLAY_FORMAT_FOR_TABLE } from 'src/settings';
import { openSnackbar } from 'src/store/snackbarSlice';
import { appColors, theme } from 'src/theme';

import { type CategoryData } from '../assessmentsteps/AssessmentEvidenceStep/AssessmentEvidenceStep.hooks';
import { CustomNoRowsOverlay, MobileCompatibleDataGrid } from '../common/MobileCompatibleDataGrid';
import { EvidenceEditDialog, type EvidenceEditDialogProps } from '../dialogs/EvidenceEditDialog';
import { type EvidencePreviewDialogProps } from '../dialogs/EvidencePreviewDialog';

export interface EvidenceTableUploadDialogProps {
  category: CategoryData;
  controlPointIds: string[];
  evidenceType: EvidenceTypeListOutputItem;
  existingEvidenceList: TransformedEvidenceListOutputItem[];
  isOpen: boolean;
  onClose: () => void;
  openPreviewDialog: (detail: Omit<EvidencePreviewDialogProps, 'isOpen' | 'onClose'>) => void;
  refetchEvidenceList: () => void;
}

export const EvidenceTableUploadDialog = ({
  category,
  controlPointIds,
  evidenceType,
  existingEvidenceList,
  onClose: handleClose,
  openPreviewDialog,
  refetchEvidenceList,
  isOpen = false,
}: EvidenceTableUploadDialogProps): React.JSX.Element => {
  const dispatch = useAppDispatch();
  const tid = useTenantId();
  const [assessmentId] = useContext(AssessmentPageContext);
  const permissions = useGetAssessmentUserPermission(assessmentId);
  const [isLoadingDocument, setIsLoadingDocument] = useState(false);
  const [clickedButtonId, setClickedButtonId] = useState('');
  const isSmallDesktop = useMediaQuery(theme.breakpoints.down('lg'));
  const [isEditDialogOpen, openEditDialog, closeEditDialog, editDialogProps] =
    useDialogState<EvidenceEditDialogProps>();

  const { data: evidenceList, isLoading: isEvidenceListLoading } = useGetEvidenceListQuery(
    { tid, is_expired: false, is_archived: false },
    { skip: !isOpen || !tid },
  );

  const filteredEvidenceList = (evidenceList || []).filter(
    (evidence) => evidence.evidence_type_id === evidenceType.uuid,
  );

  const otherValidDocuments = differenceBy(filteredEvidenceList, existingEvidenceList, 'uuid');

  const { data: evidenceTypeList, isLoading: isEvidenceTypeListLoading } =
    useGetEvidenceTypeListQuery(undefined, { skip: !isOpen });

  const [createBulkControlPointEvidence, createBulkControlPointEvidenceResult] =
    useCreateBulkAssessmentControlPointEvidenceMutation();

  const closeDialog = () => {
    handleClose();
  };

  useMutationFeedback({
    result: createBulkControlPointEvidenceResult,
    successMessage: commonStrings('bulkAttachEvidenceSuccess'),
    errorMessage: commonStrings('bulkAttachEvidenceFail'),
    onSuccess: (response) => {
      if (response) {
        setClickedButtonId('');
        setIsLoadingDocument(false);
        refetchEvidenceList();
      }
    },
    onError: () => {
      setClickedButtonId('');
      setIsLoadingDocument(false);
    },
  });

  const isLoading =
    isEvidenceListLoading ||
    isEvidenceTypeListLoading ||
    createBulkControlPointEvidenceResult?.isLoading;

  /**
   * Associate newly uploaded evidence with this control point (and assessment).
   * @param evidence - Evidence creation response data
   */
  const handleCreateSuccess = (evidence: EvidenceCreateOutput): void => {
    closeDialog();
    if (permissions?.evidence?.create && tid && assessmentId && controlPointIds && evidence?.uuid) {
      createBulkControlPointEvidence({
        tid,
        body: {
          assessment_id: assessmentId,
          control_point_ids: controlPointIds,
          evidence_id: evidence.uuid,
        },
      });
    } else {
      dispatch(
        openSnackbar({
          message: commonStrings('notAuthorized'),
          severity: 'error',
        }),
      );
    }
  };

  const handleBulkEvidenceAttach = (evidenceId: string) => {
    setClickedButtonId(evidenceId);
    setIsLoadingDocument(true);
    if (tid && assessmentId && controlPointIds) {
      createBulkControlPointEvidence({
        tid,
        body: {
          assessment_id: assessmentId,
          control_point_ids: controlPointIds,
          evidence_id: evidenceId,
        },
      });
    }
  };

  const columns: GridEnrichedColDef[] = [
    {
      field: 'name',
      headerName: commonStrings('name'),
      flex: 2,
      renderCell: (
        params: GridRenderCellParams<string, TransformedEvidenceListOutputItem, ReactNode>,
      ) => (
        <MuiLink
          onClick={() =>
            openPreviewDialog({
              evidenceId: String(params.id),
            })
          }
          sx={{
            cursor: 'pointer',
            color: appColors.blue,
          }}
          variant="body1"
        >
          {params.value}
        </MuiLink>
      ),
    },
    {
      field: 'evidence_type',
      headerName: commonStrings('documentType'),
      flex: 2,
    },
    {
      field: 'expiry_date',
      headerName: commonStrings('expiryDate'),
      type: 'date',
      flex: 1,
    },
    {
      field: 'created_at',
      headerName: commonStrings('uploadDate'),
      type: 'date',
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'right',
      flex: isSmallDesktop ? 2.6 : 2,
      getActions: (params: GridRowParams<TransformedEvidenceListOutputItem>) => [
        <Box
          key={`${params.id}_attach`}
          marginRight={1}
        >
          <LoadingButton
            color="info"
            disabled={isLoadingDocument || isLoading}
            loading={isLoadingDocument && clickedButtonId === params.row.uuid}
            onClick={() => handleBulkEvidenceAttach(params.row.uuid)}
            size="small"
            startIcon={<LinkRounded />}
            variant="contained"
          >
            {commonStrings('attachToAll')}
          </LoadingButton>
        </Box>,
        <GridActionsCellItem
          key={`${params.id}_edit`}
          icon={<EditRounded />}
          label={commonStrings('edit')}
          onClick={() => {
            openEditDialog({
              evidenceTypeIds: [params.row.evidence_type_id],
              evidenceId: String(params.id),
              hideFileUpload: true,
              title: commonStrings('editingDialogTitle', {
                name: params.row.name,
              }),
              submitButtonText: commonStrings('save'),
            });
          }}
        />,
      ],
    },
  ];

  const rows: GridRowsProp = (otherValidDocuments || []).map(
    (evidence): GridRowModel => ({
      uuid: evidence.uuid,
      name: evidence.name,
      evidence_type_id: evidence.evidence_type_id,
      evidence_type:
        evidenceTypeList?.find(
          (evidenceTypeItem) => evidenceTypeItem.uuid === evidence.evidence_type_id,
        )?.name || '',
      expiry_date: format(evidence.expiry_date, DATE_DISPLAY_FORMAT_FOR_TABLE),
      created_at: format(evidence.created_at, DATE_DISPLAY_FORMAT_FOR_TABLE),
    }),
  );

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={closeDialog}
        open={isOpen}
      >
        <IconButton
          onClick={closeDialog}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            padding: 1.5,
          }}
        >
          <CloseRounded />
        </IconButton>
        <DialogTitle>
          {evidenceTableUploadDialogStrings('attachCategoryType', {
            category: category?.alternativeName,
            evidenceType: evidenceType.name,
          })}
        </DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="row"
            gap={1}
            justifyContent="flex-end"
            marginBottom={2.5}
            width="100%"
          >
            <Button
              color="primary"
              id="attach_file_button"
              onClick={() =>
                openEditDialog({
                  evidenceTypeIds: [evidenceType.uuid],
                  title: commonStrings('uploadDocument'),
                  defaultValues: {
                    expiry_date: addYears(new Date(), 1),
                  },
                })
              }
              size="large"
              startIcon={<AddRounded />}
              variant="outlined"
            >
              {commonStrings('uploadNewDocument')}
            </Button>
          </Box>
          <Tabs
            TabIndicatorProps={{ sx: { height: 4 } }}
            value={0}
          >
            <Tab
              label={
                <Typography
                  color="textPrimary"
                  lineHeight={1.25}
                  variant="button"
                >
                  {evidenceTableUploadDialogStrings('existingDocumentsOfThisType')}
                </Typography>
              }
              sx={{ px: 1.5 }}
              value={0}
            />
          </Tabs>
          <MobileCompatibleDataGrid
            columns={columns}
            disableColumnSelector
            loading={isLoading}
            NoRowsOverlay={() =>
              CustomNoRowsOverlay(evidenceTableUploadDialogStrings('noDocumentsOfThisTypeEmptyMsg'))
            }
            rows={rows}
            rowsPerPageOptions={[10, 15, 25]}
            sx={{ minHeight: '50vh', my: 3 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={closeDialog}
            variant="contained"
          >
            {commonStrings('complete')}
          </Button>
        </DialogActions>
      </Dialog>
      {isOpen && (
        <EvidenceEditDialog
          isOpen={isEditDialogOpen}
          onClose={closeEditDialog}
          onCreateSuccess={handleCreateSuccess}
          {...editDialogProps}
        />
      )}
    </>
  );
};
