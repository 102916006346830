import './styles.scss';

import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { type Store } from 'src/store';

import { CustomThemeProvider } from './components/ThemeProvider';

interface AppProvidersProps {
  store: Store;
  children: React.ReactNode;
}

export const AppProviders = ({ store, children }: AppProvidersProps) => (
  <StyledEngineProvider injectFirst>
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StoreProvider store={store}>
          <CustomThemeProvider>
            <CssBaseline />
            {children}
          </CustomThemeProvider>
        </StoreProvider>
      </LocalizationProvider>
    </BrowserRouter>
  </StyledEngineProvider>
);
