import { Cancel, Clear } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  type InputBaseProps,
  InputLabel,
  inputLabelClasses,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Stack,
} from '@mui/material';
import { type FormikProps, type FormikValues } from 'formik';
import { useEffect, useState } from 'react';
import { appColors } from 'src/theme';
import { type LabelValuePair } from 'src/types/LabelValuePair';

interface FormikMultiSelectFieldProps {
  chipSize: 'small' | 'medium';
  id: string;
  isDisabled: boolean;
  formik: FormikProps<FormikValues>;
  label: string;
  onDelete: () => void;
  onBlur: InputBaseProps['onBlur'];
  options: LabelValuePair[];
  isLoading?: boolean;
  required?: boolean;
  showClearAll?: boolean;
}

/**
 * This component mates a Formik instance (created via the useFormik hook) with a MUI (selectable)
 * TextField.
 */
export const FormikMultiSelectField = ({
  formik,
  id,
  label,
  options,
  onBlur,
  chipSize = 'medium',
  isDisabled = false,
  isLoading = false,
  onDelete = () => {},
  required = false,
  showClearAll = false,
  ...restProps
}: FormikMultiSelectFieldProps) => {
  const [isOnDeleteTriggered, setIsOnDeleteTriggered] = useState(false);
  const onDeleteItem = (val: string) => {
    formik?.setFieldValue(
      id,
      formik.values[id].filter((item: string) => item !== val),
    );
    setIsOnDeleteTriggered(true);
  };

  const onDeleteAll = () => {
    formik?.setFieldValue(id, []);
  };

  const hasValue = formik?.values[id]?.filter((item: string) => item !== '').length > 0;
  const hasError = formik?.touched[id] && formik.errors[id];

  // Workaround for issue detailed here: https://github.com/jaredpalmer/formik/issues/529
  useEffect(() => {
    if (onDelete && isOnDeleteTriggered) {
      onDelete();
      setIsOnDeleteTriggered(false);
    }
  }, [isOnDeleteTriggered, onDelete]);

  return isLoading ? (
    <Box
      border={1}
      borderColor={appColors.border}
      borderRadius={1}
      paddingX="14px"
      paddingY="15px"
      width="100%"
    >
      <Skeleton
        animation="wave"
        variant="text"
        width="33%"
      />
    </Box>
  ) : (
    <FormControl>
      <InputLabel
        required={required}
        sx={{
          [`&.${inputLabelClasses.shrink}`]: {
            color: hasError && 'error.main',
          },
        }}
      >
        {label}
      </InputLabel>
      <Select
        {...restProps}
        disabled={isLoading || isDisabled}
        endAdornment={
          showClearAll && (
            <IconButton
              onClick={onDeleteAll}
              sx={{ display: hasValue ? 'flex' : 'none', mr: hasValue ? 1.25 : 0 }}
            >
              <Clear fontSize="small" />
            </IconButton>
          )
        }
        error={formik?.touched[id] && Boolean(formik.errors[id])}
        input={<OutlinedInput label={label} />}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 54 * 6 + 16,
            },
          },
        }}
        multiple
        name={id}
        onBlur={(evt) => {
          formik?.handleBlur(evt);
          onBlur?.(evt);
        }}
        onChange={formik?.handleChange}
        placeholder={label}
        renderValue={(selected: string[]) => (
          <Stack
            direction="row"
            flexWrap="wrap"
            gap={1}
          >
            {selected.map(
              (val) =>
                val && (
                  <Chip
                    key={val}
                    deleteIcon={
                      <Cancel
                        onMouseDown={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                      />
                    }
                    disabled={isLoading || isDisabled}
                    label={options.find((item) => item.value === val)?.label}
                    onDelete={() => {
                      onDeleteItem(val);
                    }}
                    size={chipSize}
                    sx={{
                      backgroundColor: 'primary.contrastText',
                      border: 1,
                      borderColor: appColors.black26,
                    }}
                  />
                ),
            )}
          </Stack>
        )}
        value={formik?.values[id]}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
          >
            <Checkbox checked={formik?.values[id]?.includes(option.value)} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
      {hasError && (
        <FormHelperText sx={{ color: 'error.main' }}>{formik.errors[id] as string}</FormHelperText>
      )}
    </FormControl>
  );
};
