import { AddRounded, DeleteRounded, EditRounded } from '@mui/icons-material';
import { Box, Button, Toolbar } from '@mui/material';
import {
  GridActionsCellItem,
  type GridEnrichedColDef,
  type GridRowParams,
  type GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import {
  type OrganizationPlotCultivationListOutputItem,
  RolesEnum,
} from 'src/__generated__/InternalApiTypes';
import {
  CustomNoRowsOverlay,
  MobileCompatibleDataGrid,
} from 'src/components/common/MobileCompatibleDataGrid';
import {
  GenericDeleteDialog,
  type GenericDeleteDialogProps,
} from 'src/components/dialogs/GenericDeleteDialog';
import {
  OrganizationCultivationEditDialog,
  type OrganizationCultivationEditDialogProps,
} from 'src/components/dialogs/OrganizationCultivationEditDialog';
import { useCurrentUserRoles, useDialogState, useTenantId } from 'src/hooks';
import { commonStrings, organizationCultivationTableStrings } from 'src/languages/en-UK';
import {
  type TransformedOrganizationCultivationListOutputItem,
  useDeleteOrganizationPlotCultivationMutation,
  useGetOrganizationPlotCultivationListQuery,
} from 'src/services/farmApi';
import { getCultivationDisplayName } from 'src/utils/getCultivationDisplayName';

type ValueGetterParams = GridValueGetterParams<
  number | Date,
  TransformedOrganizationCultivationListOutputItem
>;

export const OrganizationCultivationTable = (): React.JSX.Element => {
  const tid = useTenantId();
  const currentUserRoles = useCurrentUserRoles();
  const isFarmManager = currentUserRoles.includes(RolesEnum.FarmManager);
  const isFarmEmployee = currentUserRoles.includes(RolesEnum.FarmEmployee);
  const [
    isCultivationEditDialogOpen,
    openCultivationEditDialog,
    closeCultivationEditDialog,
    cultivationEditDialogProps,
  ] = useDialogState<OrganizationCultivationEditDialogProps>();
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog, deleteDialogProps] =
    useDialogState<Omit<GenericDeleteDialogProps, 'deleteMutation'>>();

  const { data: cultivations, isLoading } = useGetOrganizationPlotCultivationListQuery(
    { tid },
    { skip: !tid },
  );

  const columns: GridEnrichedColDef[] = [
    {
      field: 'productName',
      headerName: commonStrings('product'),
      flex: 2,
      valueGetter: (params: ValueGetterParams) => params?.row?.product?.name,
    },
    {
      field: 'name',
      headerName: organizationCultivationTableStrings('plot'),
      flex: 2,
      valueGetter: (params: ValueGetterParams) => params?.row?.organization_plot?.name,
    },
    {
      field: 'production_technique',
      headerName: commonStrings('cultivationType'),
      flex: 2,
      valueGetter: (params: ValueGetterParams) => params?.row?.production_technique?.name,
    },
    {
      field: 'area',
      headerName: commonStrings('plotSurfaceArea'),
      flex: 1.5,
      valueGetter: (params: ValueGetterParams) => params?.row?.organization_plot?.area,
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'right',
      getActions: (params: GridRowParams<OrganizationPlotCultivationListOutputItem>) => {
        const buttons = [];
        if (params.row.can_be_edited) {
          buttons.push(
            <GridActionsCellItem
              key={`${params.row.uuid}_edit`}
              icon={<EditRounded />}
              label={commonStrings('edit')}
              onClick={() => {
                openCultivationEditDialog({
                  cultivationId: params.row.uuid,
                  title: commonStrings('editingDialogTitle', {
                    name: getCultivationDisplayName(params.row, isFarmManager || isFarmEmployee),
                  }),
                });
              }}
            />,
          );
        }
        if (params.row.can_be_deleted) {
          buttons.push(
            <GridActionsCellItem
              key={`${params.row.uuid}_delete`}
              icon={<DeleteRounded />}
              label={commonStrings('delete')}
              onClick={() =>
                openDeleteDialog({
                  entities: [
                    {
                      header: commonStrings('cultivation'),
                      name: getCultivationDisplayName(params.row, isFarmManager || isFarmEmployee),
                      uuid: params.row.uuid,
                    },
                  ],
                  customErrorCode: 400,
                  customErrorMessage: commonStrings('cultivationDeleteCustomErrorMessage', {
                    name: getCultivationDisplayName(params.row, isFarmManager || isFarmEmployee),
                  }),
                })
              }
            />,
          );
        }
        return buttons;
      },
    },
  ];

  const handleAddCultivation = () => {
    openCultivationEditDialog({ title: commonStrings('addNewCultivation') });
  };

  return (
    <>
      <Toolbar
        disableGutters
        sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}
        variant="dense"
      >
        <Button
          onClick={handleAddCultivation}
          startIcon={<AddRounded />}
          variant="contained"
        >
          {commonStrings('addCultivation')}
        </Button>
      </Toolbar>
      <Box
        display="flex"
        flexDirection="column"
        height="calc(100vh - 328px)"
        width="100%"
      >
        <MobileCompatibleDataGrid
          columns={columns}
          disableColumnSelector
          loading={isLoading}
          NoRowsOverlay={() =>
            CustomNoRowsOverlay(
              isFarmManager || isFarmEmployee
                ? organizationCultivationTableStrings('cultivationTableNoRowsMsgForFarmManagers')
                : organizationCultivationTableStrings('cultivationTableNoRowsMsgForAuditors'),
            )
          }
          rows={cultivations || []}
          sortModel={[{ field: 'productName', sort: 'asc' }]}
        />
      </Box>
      <OrganizationCultivationEditDialog
        isOpen={isCultivationEditDialogOpen}
        onClose={closeCultivationEditDialog}
        {...cultivationEditDialogProps}
      />
      <GenericDeleteDialog
        deleteMutation={useDeleteOrganizationPlotCultivationMutation}
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        {...deleteDialogProps}
      />
    </>
  );
};
