import { DeleteForeverRounded } from '@mui/icons-material';
import { Box, IconButton, Link as MuiLink, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import {
  type AssessmentControlPointEvidenceOutputItem,
  RolesEnum,
} from 'src/__generated__/InternalApiTypes';
import {
  useAppDispatch,
  useCurrentUserRoles,
  useGetAssessmentUserPermission,
  useMutationFeedback,
  useTenantId,
  useUserAuth,
} from 'src/hooks';
import { commonStrings, controlPointUploadedEvidenceStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import {
  useDeleteAssessmentControlPointEvidenceMutation,
  useGetAssessmentControlPointQuery,
  useGetEvidenceListQuery,
} from 'src/services/farmApi';
import { openSnackbar } from 'src/store/snackbarSlice';
import { appColors } from 'src/theme';

import { MobileCompatibleTooltip } from '../common/MobileCompatibleTooltip';
import { type EvidencePreviewDialogProps } from '../dialogs/EvidencePreviewDialog';

interface ControlPointUploadedEvidenceProps {
  assessmentControlPointId: string;
  controlPointId: string;
  isLoading: boolean;
  openPreviewDialog: (detail: Omit<EvidencePreviewDialogProps, 'isOpen' | 'onClose'>) => void;
}

export const ControlPointUploadedEvidence = ({
  assessmentControlPointId,
  controlPointId,
  openPreviewDialog,
  isLoading = false,
}: ControlPointUploadedEvidenceProps) => {
  const tid = useTenantId();
  const currentUser = useUserAuth();
  const currentUserRoles = useCurrentUserRoles();
  const dispatch = useAppDispatch();
  const isAuditor = currentUserRoles.includes(RolesEnum.Auditor);
  const [assessmentId] = useContext(AssessmentPageContext);
  const permissions = useGetAssessmentUserPermission(assessmentId);
  const [isVisibleEvidence, setIsVisibleEvidence] = useState(true);
  const EMPTY_ARRAY: AssessmentControlPointEvidenceOutputItem[] = [];

  const { evidence: controlPointEvidenceLinks } = useGetAssessmentControlPointQuery(
    {
      tid,
      id: assessmentControlPointId,
    },
    {
      skip: !assessmentControlPointId || !tid,
      selectFromResult: ({ data }) => ({
        evidence: data?.evidence ?? EMPTY_ARRAY,
      }),
    },
  );
  const { data: evidenceList } = useGetEvidenceListQuery(
    { tid },
    { skip: !controlPointId || !tid },
  );
  const existingEvidences = evidenceList?.filter((evidence) =>
    controlPointEvidenceLinks?.some((cpel) => cpel.evidence_id === evidence.uuid),
  );
  const [deleteControlPointEvidence, deleteResult] =
    useDeleteAssessmentControlPointEvidenceMutation();

  const handleDelete = (evidenceId: string) => {
    const evidenceLinkId = controlPointEvidenceLinks.find(
      (cpel) => cpel.evidence_id === evidenceId,
    )?.uuid;
    if (evidenceLinkId) {
      if (permissions?.evidence?.delete && tid) {
        deleteControlPointEvidence({ tid, id: evidenceLinkId, parentResourceId: controlPointId });
      } else {
        dispatch(
          openSnackbar({
            message: commonStrings('notAuthorized'),
            severity: 'error',
          }),
        );
      }
    }
  };

  useMutationFeedback({
    result: deleteResult,
    successMessage: controlPointUploadedEvidenceStrings('deleteEvidenceSuccess'),
    errorMessage: controlPointUploadedEvidenceStrings('deleteEvidenceFail'),
  });

  return !isLoading && existingEvidences && existingEvidences.length > 0 ? (
    <Stack
      spacing={1}
      sx={{
        '&&&': {
          mt: '24px',
        },
      }}
    >
      <Typography variant="overline">
        {controlPointUploadedEvidenceStrings('uploadedEvidence')}
      </Typography>
      {existingEvidences.map((evidence, ind) => {
        const isEvidenceAuthor = evidence.author_id === currentUser?.userId;
        return ind === 0 || (ind > 0 && isVisibleEvidence) ? (
          <Box
            key={evidence.uuid}
            alignItems="center"
            display="flex"
          >
            <MuiLink
              onClick={() =>
                openPreviewDialog({
                  evidenceId: String(evidence.uuid),
                })
              }
              sx={{
                cursor: 'pointer',
                color: appColors.blue,
              }}
              variant="body1"
            >
              {evidence.name}
            </MuiLink>
            {isAuditor && isEvidenceAuthor && (
              <IconButton
                onClick={() => {
                  handleDelete(evidence.uuid);
                }}
                sx={{ padding: 0, marginX: 1, maxHeight: 24 }}
              >
                <MobileCompatibleTooltip
                  title={controlPointUploadedEvidenceStrings('deleteEvidence')}
                >
                  <DeleteForeverRounded />
                </MobileCompatibleTooltip>
              </IconButton>
            )}
            {existingEvidences.length > 1 &&
              ((ind === 0 && !isVisibleEvidence) || ind === existingEvidences.length - 1) && (
                <MuiLink
                  onClick={() => {
                    setIsVisibleEvidence((prev) => !prev);
                  }}
                  sx={{
                    cursor: 'pointer',
                    color: appColors.blue,
                    ml: isAuditor ? 0 : 3,
                  }}
                  variant="body1"
                >
                  {isVisibleEvidence
                    ? controlPointUploadedEvidenceStrings('showLess')
                    : controlPointUploadedEvidenceStrings('showAllExistingEvidences', {
                        existingEvidencesLength: existingEvidences.length,
                      })}
                </MuiLink>
              )}
          </Box>
        ) : null;
      })}
    </Stack>
  ) : null;
};
