import { CheckRounded, ErrorOutline } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, TextField, Toolbar, Typography } from '@mui/material';
import { useFormik } from 'formik';
import {
  type UserProfileDetailOutput,
  type UserProfileUpdateInput,
} from 'src/__generated__/InternalApiTypes';
import {
  ChangePasswordDialog,
  type ChangePasswordDialogProps,
} from 'src/components/dialogs/ChangePasswordDialog';
import {
  GenericDeleteDialog,
  type GenericDeleteDialogProps,
} from 'src/components/dialogs/GenericDeleteDialog';
import { FormikLanguageField } from 'src/components/formikcomponents/FormikLanguageField';
import { FormikTextField } from 'src/components/formikcomponents/FormikTextField';
import { useAppDispatch, useDialogState } from 'src/hooks';
import { accountDetailsFormStrings, commonStrings } from 'src/languages/en-UK';
import { farmApi, useDeleteUserProfileMutation, useSignOutMutation } from 'src/services/farmApi';
import { appColors } from 'src/theme';
import { canSubmitFormik } from 'src/utils/canSubmitFormik';
import { populateFormValues } from 'src/utils/populateFormValues';
import * as Yup from 'yup';

type InitialValuesType = Omit<UserProfileDetailOutput, 'memberships' | 'account_type' | 'uuid'>;

interface AccountDetailsFormProps {
  initialValues: InitialValuesType;
  isLoading: boolean;
  isSubmitLoading: boolean;
  onSubmit: (values: UserProfileUpdateInput) => void;
  showLogoutButton: boolean;
  userId: string | undefined;
}

export const AccountDetailsForm = ({
  initialValues,
  onSubmit,
  userId,
  isLoading = false,
  isSubmitLoading = false,
  showLogoutButton = false,
}: AccountDetailsFormProps): React.JSX.Element => {
  const dispatch = useAppDispatch();
  const [signOut] = useSignOutMutation();

  const formik = useFormik({
    enableReinitialize: true, // Reinitialize the form once the async data is available
    initialValues: populateFormValues(
      {
        email: '',
        full_name: '',
        language: '',
      },
      initialValues,
    ),
    validationSchema: Yup.object().shape({
      full_name: Yup.string().required(commonStrings('required')),
      language: Yup.string().required(commonStrings('required')),
    }),
    onSubmit: async (values: InitialValuesType) => {
      onSubmit({ full_name: values?.full_name, language: values?.language });
    },
  });

  const [isChangePasswordDialogOpen, openChangePasswordDialog, closeChangePasswordDialog] =
    useDialogState<ChangePasswordDialogProps>();

  /**
   * Navigates to the 'Change Password' page
   */
  const handleChangePassword = () => {
    openChangePasswordDialog();
  };

  // TODOHasan: Implement unsaved changes prompt

  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog, deleteDialogProps] =
    useDialogState<Omit<GenericDeleteDialogProps, 'deleteMutation'>>();

  const handleLogOut = async () => {
    localStorage.removeItem('storedTenantId');
    await signOut();
    dispatch(farmApi.util.resetApiState());
  };

  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <Box
        display="grid"
        gap={2}
        gridTemplateAreas={`
          "generalHeader generalHeader"
          "full_name full_name"
          "email email"
          "language language"
          "password changePasswordButton"
          "deactivateAccountHeader deactivateAccountHeader"
          "deactivateAccountMsg deactivateAccountMsg"
          "deactivateAccountBtn deactivateAccountBtn"
        `}
        gridTemplateColumns="repeat(2 1fr)"
        gridTemplateRows="auto"
      >
        <Typography
          gridArea="generalHeader"
          lineHeight={3.43}
          variant="subtitle2"
        >
          {commonStrings('general')}
        </Typography>
        <FormikTextField
          formik={formik}
          id="full_name"
          isLoading={isLoading}
          label={accountDetailsFormStrings('fullNameAsComponent')}
          placeholder={commonStrings('fullName')}
          required
          sx={{ gridArea: 'full_name' }}
        />
        <FormikTextField
          disabled
          formik={formik}
          id="email"
          isLoading={isLoading}
          label={accountDetailsFormStrings('emailAddressAsComponent')}
          placeholder={accountDetailsFormStrings('emailAddress')}
          sx={{ gridArea: 'email' }}
        />
        <FormikLanguageField
          formik={formik}
          id="language"
          isLoading={isLoading}
          label={commonStrings('languageAsComponent')}
          placeholder={commonStrings('language')}
          required
          sx={{ gridArea: 'language' }}
        />
        <TextField
          disabled
          id="password"
          label={commonStrings('passwordAsComponent')}
          placeholder={commonStrings('password')}
          sx={{ gridArea: 'password' }}
          value="*************"
        />
        <Stack
          alignItems="center"
          direction="row"
          gridArea="changePasswordButton"
          id="changePasswordButton"
        >
          <Button
            color="info"
            onClick={handleChangePassword}
            size="large"
            variant="outlined"
          >
            {commonStrings('changePassword')}
          </Button>
        </Stack>
        <Typography
          gridArea="deactivateAccountHeader"
          lineHeight={3.43}
          variant="subtitle2"
        >
          {accountDetailsFormStrings('deactivateAccount')}
        </Typography>
        <Stack
          alignItems="center"
          bgcolor={appColors.error20}
          borderRadius={1}
          direction="row"
          gap={1.5}
          gridArea="deactivateAccountMsg"
          id="deactivateAccountMsg"
          paddingX={2}
          paddingY={0.75}
        >
          <ErrorOutline color="error" />
          <Typography
            color={appColors.errorShades60}
            paddingY={1}
            variant="body2"
          >
            {accountDetailsFormStrings('deactivateAccountWarningMessage')}
          </Typography>
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          gridArea="deactivateAccountBtn"
          id="deactivateAccountBtn"
        >
          <Button
            color="error"
            onClick={() =>
              openDeleteDialog({
                entities: [
                  {
                    uuid: userId,
                    header: accountDetailsFormStrings('account'),
                    name: initialValues.email,
                  },
                ],
              })
            }
            size="large"
            variant="outlined"
          >
            {accountDetailsFormStrings('deactivateAccount')}
          </Button>
        </Stack>
      </Box>
      <Toolbar
        disableGutters
        sx={{ justifyContent: 'flex-end', mt: 2 }}
        variant="dense"
      >
        {showLogoutButton && (
          <Button
            onClick={handleLogOut}
            sx={{ mr: 1 }}
            variant="outlined"
          >
            {commonStrings('logOut')}
          </Button>
        )}
        <LoadingButton
          disabled={!canSubmitFormik(formik)}
          loading={isSubmitLoading}
          startIcon={<CheckRounded />}
          type="submit"
          variant="contained"
        >
          {commonStrings('saveChanges')}
        </LoadingButton>
      </Toolbar>
      <ChangePasswordDialog
        isOpen={isChangePasswordDialogOpen}
        onClose={closeChangePasswordDialog}
      />
      <GenericDeleteDialog
        deleteMutation={useDeleteUserProfileMutation}
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        {...deleteDialogProps}
      />
    </form>
  );
};
