import { ToggleButton, ToggleButtonGroup, Toolbar } from '@mui/material';
import { useContext } from 'react';
import { ThemeContext } from 'src/components/ThemeProvider';
import { assessmentControlPointDrawerToggleStrings } from 'src/languages/en-UK';

export enum SidebarToggleButtonTypeEnum {
  SelfAssessmentMode = 'self',
  AuditingMode = 'audit',
}

interface AssessmentControlPointDrawerToggleProps {
  selectedMode: SidebarToggleButtonTypeEnum;
  setSelectedMode: React.Dispatch<React.SetStateAction<SidebarToggleButtonTypeEnum>>;
}

export const AssessmentControlPointDrawerToggle = ({
  selectedMode,
  setSelectedMode,
}: AssessmentControlPointDrawerToggleProps): React.JSX.Element => {
  const { theme } = useContext(ThemeContext);
  return (
    <>
      <Toolbar />
      <ToggleButtonGroup
        aria-label="mode selection"
        color="primary"
        exclusive
        onChange={(_event, value) => {
          if (value) {
            setSelectedMode(value);
          }
        }}
        size="small"
        sx={{
          mt: 2.5,
          mb: 2,
          mx: 1.25,
          width: 'max-content',
        }}
        value={selectedMode}
      >
        <ToggleButton
          aria-label="self assessment mode"
          sx={{
            color: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: `${theme.palette.primary.main}0A`,
            },
          }}
          value="self"
        >
          {assessmentControlPointDrawerToggleStrings('selfAssessmentMode')}
        </ToggleButton>
        <ToggleButton
          aria-label="auditing mode"
          sx={{
            color: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: `${theme.palette.primary.main}0A`,
            },
          }}
          value="audit"
        >
          {assessmentControlPointDrawerToggleStrings('auditingMode')}
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};
