import { CloseRounded, DownloadRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogContent, DialogTitle, IconButton, Link as MuiLink } from '@mui/material';
import {
  GridActionsCellItem,
  type GridEnrichedColDef,
  type GridRenderCellParams,
  type GridRowId,
  type GridRowParams,
} from '@mui/x-data-grid-pro';
import { addYears } from 'date-fns';
import { type ReactNode, useState } from 'react';
import {
  type AssessmentDetailOutput,
  AssessmentStatusEnum,
  type HelpDocumentListOutputItem,
  type HelpDocumentsListParams,
  RolesEnum,
} from 'src/__generated__/InternalApiTypes';
import {
  CustomNoRowsOverlay,
  MobileCompatibleDataGrid,
} from 'src/components/common/MobileCompatibleDataGrid';
import {
  useAppDispatch,
  useCurrentUserRoles,
  useDialogState,
  useMutationFeedback,
  useTenantId,
  useUserAuth,
} from 'src/hooks';
import { commonStrings, helpDocumentsDialogStrings } from 'src/languages/en-UK';
import {
  extractErrorMessage,
  useCreateEvidenceMutation,
  useGetCategoryListQuery,
  useGetControlPointQuery,
  useGetHelpDocumentListQuery,
  useGetHelpDocumentTypeListQuery,
} from 'src/services/farmApi';
import { type TransformedHelpDocumentListOutputItem } from 'src/services/farmApi';
import { openSnackbar } from 'src/store/snackbarSlice';
import { appColors } from 'src/theme';
import { createDataGridLinkedIdValueGetter } from 'src/utils/createDataGridLinkedIdValueGetter';
import { dataGridValueFormatterForDate } from 'src/utils/dataGridUtils';
import { downloadFileToLocal, getFileSourceFromUrl } from 'src/utils/fileUtils';

import { CreateEvidenceDialog, type CreateEvidenceDialogProps } from '../CreateEvidenceDialog';
import {
  HelpDocumentPreviewDialog,
  type HelpDocumentPreviewDialogProps,
} from '../HelpDocumentPreviewDialog';

export interface HelpDocumentsDialogProps {
  assessmentDetail: AssessmentDetailOutput;
  controlPointId: string;
  controlPointName: string;
  evidenceTypeIds: string[];
  filterParams: Omit<HelpDocumentsListParams, 'tid'>;
  isOpen: boolean;
  onClose: () => void;
}

export const HelpDocumentsDialog = ({
  assessmentDetail,
  controlPointId,
  controlPointName,
  evidenceTypeIds,
  onClose: handleClose,
  filterParams = {},
  isOpen = false,
}: HelpDocumentsDialogProps): React.JSX.Element => {
  const dispatch = useAppDispatch();
  const skip = !isOpen;
  const tid = useTenantId();
  const [isLoadingDocument, setIsLoadingDocument] = useState(false);
  const [clickedButtonId, setClickedButtonId] = useState<string | null>(null);
  const [createEvidence, createEvidenceResult] = useCreateEvidenceMutation();
  const currentUserRoles = useCurrentUserRoles();
  const currentUser = useUserAuth();
  const isAssessmentAuthor = assessmentDetail?.author_id === currentUser?.userId;
  const isAuditor = currentUserRoles.includes(RolesEnum.Auditor);
  const isCBManager = currentUserRoles.includes(RolesEnum.CertificationBodyManager);
  const isReviewer =
    isAuditor &&
    !isAssessmentAuthor &&
    assessmentDetail?.status === AssessmentStatusEnum.ToBeReviewed;

  const { data: controlPointDetail, isLoading: isControlPointDetailLoading } =
    useGetControlPointQuery(controlPointId, { skip });
  const { data: level3Categories, isLoading: isLevel3CategoriesLoading } = useGetCategoryListQuery({
    taxonomic_level: [3],
  });
  const level3CategoryIds = level3Categories?.map((category) => category.uuid);
  const filteredCategoryIds =
    controlPointDetail?.category_ids?.filter((id) => level3CategoryIds?.includes(id)) || [];

  const [
    isCreateEvidenceDialogOpen,
    openCreateEvidenceDialog,
    closeCreateEvidenceDialog,
    createEvidenceDialogProps,
  ] = useDialogState<CreateEvidenceDialogProps>();
  const [isPreviewDialogOpen, openPreviewDialog, closePreviewDialog, previewDialogProps] =
    useDialogState<HelpDocumentPreviewDialogProps>();

  const { data: helpDocumentList, isLoading: isHelpDocumentListLoading } =
    useGetHelpDocumentListQuery(filterParams, { skip });
  const { data: helpDocumentTypeList, isLoading: isHelpDocumentTypeListLoading } =
    useGetHelpDocumentTypeListQuery(null, { skip });

  const sortedHelpDocumentList = [...(helpDocumentList || [])]?.sort((a) =>
    a.is_evidence_form ? -1 : 1,
  );

  const handleDownload = (row: HelpDocumentListOutputItem): void => {
    downloadFileToLocal(row?.file, row?.name, (msg) =>
      dispatch(openSnackbar({ message: msg, severity: 'error' })),
    );
  };

  const handleClickPreview = (id: GridRowId): void => {
    openPreviewDialog({
      helpDocumentId: String(id),
    });
  };

  const handleCreateEvidence = async (
    selectedHelpDocument: HelpDocumentListOutputItem,
  ): Promise<void> => {
    if (selectedHelpDocument?.file?.file_object) {
      setClickedButtonId(selectedHelpDocument.uuid);
      setIsLoadingDocument(true);
      try {
        const [fileSource, newDocumentTitle] = await getFileSourceFromUrl(
          selectedHelpDocument.file,
          selectedHelpDocument.name,
        );

        if (tid) {
          createEvidence({
            tid,
            body: {
              file: fileSource,
              name: newDocumentTitle,
              expiry_date: addYears(new Date(), 1),
              is_draft: true,
              source_help_document_id: selectedHelpDocument.uuid,
              category_ids: filteredCategoryIds,
            },
          });
        }
      } catch (error) {
        setClickedButtonId(null);
        setIsLoadingDocument(false);
        const message = extractErrorMessage(error);
        dispatch(openSnackbar({ message, severity: 'error' }));
      }
    }
  };

  useMutationFeedback({
    result: createEvidenceResult,
    onSuccess: (response) => {
      if (response) {
        setClickedButtonId(null);
        setIsLoadingDocument(false);
        openCreateEvidenceDialog({
          controlPointName,
          evidenceTypeIds,
          assessmentId: assessmentDetail?.uuid,
          controlPointIds: [controlPointId],
          evidenceId: String(response.uuid),
          isSignatureRequired: response.requires_signature,
          onCloseEvidenceFormsDialog: handleClose,
        });
      }
    },
    onError: () => {
      setClickedButtonId(null);
      setIsLoadingDocument(false);
    },
    successMessage: commonStrings('createDraftDocumentWithEvidenceFormSuccess'),
    errorMessage: commonStrings('createDraftDocumentWithEvidenceFormFail'),
  });

  const columns: GridEnrichedColDef[] = [
    {
      field: 'name',
      headerName: commonStrings('name'),
      flex: 2.5,
      renderCell: (
        params: GridRenderCellParams<string, TransformedHelpDocumentListOutputItem, ReactNode>,
      ) => (
        <MuiLink
          onClick={() => handleClickPreview(params.id)}
          sx={{
            color: appColors.blue,
            cursor: 'pointer',
          }}
          variant="body1"
        >
          {params.value}
        </MuiLink>
      ),
    },
    {
      field: 'document_type',
      headerName: commonStrings('type'),
      type: 'string',
      flex: 1,
      valueGetter: createDataGridLinkedIdValueGetter(helpDocumentTypeList, 'help_document_type_id'),
    },
    {
      field: 'organization_name',
      headerName: commonStrings('publishedBy'),
      type: 'string',
      flex: 1.4,
    },
    {
      field: 'expiry_date',
      headerName: commonStrings('expiryDate'),
      type: 'date',
      flex: 1,
      valueFormatter: dataGridValueFormatterForDate,
    },
    {
      field: 'created_at',
      headerName: commonStrings('uploadedDate'),
      type: 'date',
      flex: 1,
      valueFormatter: dataGridValueFormatterForDate,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 1.6,
      align: 'right',
      getActions: (params: GridRowParams<HelpDocumentListOutputItem>) => {
        const buttons = [];
        if (params.row.is_evidence_form && !isReviewer && !isCBManager) {
          buttons.push(
            <LoadingButton
              key={`${params.row.uuid}_create`}
              color="info"
              disabled={isLoadingDocument}
              loading={isLoadingDocument && clickedButtonId === params.row.uuid}
              onClick={() => handleCreateEvidence(params.row)}
              size="small"
              variant="contained"
            >
              {commonStrings('createEvidence')}
            </LoadingButton>,
          );
        }
        buttons.push(
          <GridActionsCellItem
            key={`${params.row.uuid}_download`}
            icon={<DownloadRounded />}
            label={commonStrings('download')}
            onClick={() => handleDownload(params.row)}
          />,
        );
        return buttons;
      },
    },
  ];

  const isLoading =
    isHelpDocumentListLoading ||
    isHelpDocumentTypeListLoading ||
    isControlPointDetailLoading ||
    isLevel3CategoriesLoading;

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        open={isOpen}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            padding: 1.5,
          }}
        >
          <CloseRounded />
        </IconButton>
        <DialogTitle>{commonStrings('evidenceFormsAndGuidelines')}</DialogTitle>
        <DialogContent>
          <MobileCompatibleDataGrid
            columns={columns}
            disableColumnSelector
            loading={isLoading}
            NoRowsOverlay={() =>
              CustomNoRowsOverlay(helpDocumentsDialogStrings('noHelpDocumentToDisplay'))
            }
            rows={sortedHelpDocumentList}
            sx={{ minHeight: '50vh' }}
          />
        </DialogContent>
      </Dialog>
      <HelpDocumentPreviewDialog
        isOpen={isPreviewDialogOpen}
        onClose={closePreviewDialog}
        {...previewDialogProps}
      />
      <CreateEvidenceDialog
        isOpen={isCreateEvidenceDialogOpen}
        onClose={closeCreateEvidenceDialog}
        {...createEvidenceDialogProps}
      />
    </>
  );
};
