import LoadingButton from '@mui/lab/LoadingButton';
import { Container, Link as MuiLink, Stack, Toolbar, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormikPasswordTextField } from 'src/components/formikcomponents/FormikPasswordTextField';
import { FormikTextField } from 'src/components/formikcomponents/FormikTextField';
import { AuthContext } from 'src/components/PreventAuth';
import { AuthStatus } from 'src/enums/AuthStatus';
import { useAppDispatch } from 'src/hooks';
import { useGetUserOrigin } from 'src/hooks/useGetUserOrigin';
import { commonStrings, loginPageStrings } from 'src/languages/en-UK';
import { useSignInMutation } from 'src/services/farmApi';
import { type UserData } from 'src/services/farmApi/endpoints/auth';
import { ROUTE_PATH_CHANGE_TEMPORARY_PASSWORD, ROUTE_PATH_FORGOT_PASSWORD } from 'src/settings';
import { openSnackbar } from 'src/store/snackbarSlice';
import { appColors } from 'src/theme';
import { canSubmitFormik } from 'src/utils/canSubmitFormik';
import * as Yup from 'yup';

export const LogInPage = (): React.JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const origin = useGetUserOrigin();
  const [signIn, { error: signInError }] = useSignInMutation();
  const [isLogInPending, setIsLogInPending] = useState(false);
  const { setUser } = useContext(AuthContext);

  useEffect(() => {
    localStorage.removeItem('hasRedirect');
    if (signInError && 'data' in signInError) {
      const errorData = signInError.data as { code: string; message: string };

      dispatch(
        openSnackbar({
          severity: 'error',
          message: errorData.message,
        }),
      );

      /* Only disable the loading state if something went wrong. If it was successful, we've already
       * set the userId and <App /> has re-routed us to the dashboard. Attempting to setLogInPending
       * as false at this point will emit a warning about updating unmounted components.
       */
      setIsLogInPending(false);
    }
  }, [dispatch, signInError]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email(commonStrings('invalidEmail')).required(commonStrings('required')),
      password: Yup.string().required(commonStrings('required')),
    }),
    onSubmit: async (values) => {
      const { email, password } = values;
      setIsLogInPending(true);
      const result = await signIn({ email, password });
      if ((result as { data: UserData })?.data?.authStatus === AuthStatus.PasswordRequired) {
        setUser?.((result as { data: UserData }).data.user);
        navigate(ROUTE_PATH_CHANGE_TEMPORARY_PASSWORD);
      }
    },
  });

  return (
    <Container
      maxWidth="sm"
      sx={{
        px: { xs: 0, sm: 2 },
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <form
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <Stack spacing={2}>
          <FormikTextField
            formik={formik}
            id="email"
            label={commonStrings('email')}
            required
            type="email"
          />
          <FormikPasswordTextField
            formik={formik}
            id="password"
            label={commonStrings('passwordAsComponent')}
            placeholder={commonStrings('password')}
            required
          />
          <Toolbar disableGutters>
            <LoadingButton
              disabled={!canSubmitFormik(formik)}
              fullWidth
              id="button-log-in"
              loading={isLogInPending}
              type="submit"
              variant="contained"
            >
              {commonStrings('logIn')}
            </LoadingButton>
          </Toolbar>
        </Stack>
        <Typography
          display="flex"
          justifyContent={{ xs: 'center', sm: 'flex-start' }}
        >
          <MuiLink
            component="span"
            onClick={() => {
              navigate(
                origin
                  ? `${ROUTE_PATH_FORGOT_PASSWORD}?origin=${origin}`
                  : ROUTE_PATH_FORGOT_PASSWORD,
              );
            }}
            sx={{
              cursor: 'pointer',
              color: appColors.blue,
            }}
            underline="hover"
          >
            {loginPageStrings('forgotYourPassword')}
          </MuiLink>
        </Typography>
      </form>
    </Container>
  );
};
