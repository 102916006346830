import { AddRounded, AssistantRounded, ListAltRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Link as MuiLink, Skeleton, Typography, useMediaQuery } from '@mui/material';
import {
  type GridEnrichedColDef,
  type GridRenderCellParams,
  type GridRowParams,
} from '@mui/x-data-grid-pro';
import { addYears, format } from 'date-fns';
import { type ReactNode, useContext, useState } from 'react';
import {
  type EvidenceTypeListOutputItem,
  type SimpleFile,
} from 'src/__generated__/InternalApiTypes';
import { useAppDispatch, useDialogState, useMutationFeedback, useTenantId } from 'src/hooks';
import { commonStrings, evidenceTableStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import {
  extractErrorMessage,
  type TransformedEvidenceListOutputItem,
  useCreateEvidenceMutation,
  useGetAssessmentControlPointEvidenceListQuery,
  useGetHelpDocumentListQuery,
} from 'src/services/farmApi';
import { DATE_DISPLAY_FORMAT_FOR_TABLE } from 'src/settings';
import { openSnackbar } from 'src/store/snackbarSlice';
import { appColors, theme } from 'src/theme';
import { getFileSourceFromUrl } from 'src/utils/fileUtils';

import { type CategoryData } from '../assessmentsteps/AssessmentEvidenceStep/AssessmentEvidenceStep.hooks';
import { CustomNoRowsOverlay, MobileCompatibleDataGrid } from '../common/MobileCompatibleDataGrid';
import { MobileCompatibleTooltip } from '../common/MobileCompatibleTooltip';
import {
  CreateEvidenceDialog,
  type CreateEvidenceDialogProps,
} from '../dialogs/CreateEvidenceDialog';
import { type EvidenceFormsDialogProps } from '../dialogs/EvidenceFormsDialog';
import {
  EvidencePreviewDialog,
  type EvidencePreviewDialogProps,
} from '../dialogs/EvidencePreviewDialog';
import {
  EvidenceTableUploadDialog,
  type EvidenceTableUploadDialogProps,
} from './EvidenceTableUploadDialog';

interface EvidenceTableProps {
  category: CategoryData;
  controlPointIds: string[];
  evidenceList: TransformedEvidenceListOutputItem[];
  evidenceType: EvidenceTypeListOutputItem;
  onReview: (evidenceId: string) => void;
  openEvidenceFormsDialog: (props: Omit<EvidenceFormsDialogProps, 'isOpen' | 'onClose'>) => void;
  refetchEvidenceList: () => void;
  isFetching?: boolean;
  totalQuestionCount?: number;
}

type EvidenceTableListItem = {
  attachmentCount: number;
  isArchived: boolean;
  isAutoAttached: boolean;
  isExpired: boolean;
  expiryDate: string;
  file: SimpleFile;
  name: string;
  uuid: string;
};

export const EvidenceTable = ({
  controlPointIds,
  evidenceList,
  category,
  evidenceType,
  onReview,
  openEvidenceFormsDialog,
  refetchEvidenceList,
  isFetching = false,
  totalQuestionCount = 0,
}: EvidenceTableProps): React.JSX.Element => {
  const tid = useTenantId();
  const [assessmentId] = useContext(AssessmentPageContext);
  const dispatch = useAppDispatch();
  const isSmallDesktop = useMediaQuery(theme.breakpoints.down('md'));

  const [isPreviewDialogOpen, openPreviewDialog, closePreviewDialog, previewDialogProps] =
    useDialogState<EvidencePreviewDialogProps>();

  const [isEvidenceDialogOpen, openEvidenceDialog, closeEvidenceDialog] =
    useDialogState<EvidenceTableUploadDialogProps>();

  const [
    isCreateEvidenceDialogOpen,
    openCreateEvidenceDialog,
    closeCreateEvidenceDialog,
    createEvidenceDialogProps,
  ] = useDialogState<CreateEvidenceDialogProps>();

  const [isLoadingDocument, setIsLoadingDocument] = useState(false);
  const [clickedButtonId, setClickedButtonId] = useState<string | null>(null);
  const [createEvidence, createEvidenceResult] = useCreateEvidenceMutation();

  useMutationFeedback({
    result: createEvidenceResult,
    onSuccess: (response) => {
      if (response) {
        setClickedButtonId(null);
        setIsLoadingDocument(false);
        openCreateEvidenceDialog({
          assessmentId,
          controlPointIds,
          evidenceId: response.uuid,
          isSignatureRequired: response.requires_signature,
          evidenceTypeIds: [response.evidence_type_id],
        });
      }
    },
    onError: () => {
      setClickedButtonId(null);
      setIsLoadingDocument(false);
    },
    successMessage: commonStrings('createDraftDocumentSuccess'),
    errorMessage: commonStrings('createDraftDocumentFail'),
  });

  const {
    data: assessmentControlPointEvidenceList,
    isFetching: isAssessmentControlPointEvidenceListFetching,
    isLoading: isAssessmentControlPointEvidenceListLoading,
  } = useGetAssessmentControlPointEvidenceListQuery(
    {
      tid,
      assessment_id: [assessmentId],
      control_point_id: controlPointIds,
    },
    { skip: !tid },
  );

  const { data: helpDocumentList } = useGetHelpDocumentListQuery(
    {
      control_point_id: controlPointIds,
      evidence_type_id: [evidenceType?.uuid],
      category_id: [category?.uuid],
      is_evidence_form: true,
    },
    { skip: controlPointIds?.length <= 0 },
  );

  const handleCreateEvidence = async (selectedEvidence: EvidenceTableListItem): Promise<void> => {
    setClickedButtonId(selectedEvidence.uuid);
    setIsLoadingDocument(true);
    if (selectedEvidence?.file?.file_object) {
      try {
        const [fileSource, newDocumentTitle] = await getFileSourceFromUrl(
          selectedEvidence.file,
          selectedEvidence.name,
        );

        if (tid) {
          createEvidence({
            tid,
            body: {
              file: fileSource,
              name: newDocumentTitle,
              expiry_date: addYears(new Date(), 1),
              is_draft: true,
              evidence_type_id: evidenceType.uuid,
              source_evidence_id: selectedEvidence?.uuid,
            },
          });
        }
      } catch (error) {
        setClickedButtonId(null);
        setIsLoadingDocument(false);
        const message = extractErrorMessage(error);
        dispatch(openSnackbar({ message, severity: 'error' }));
      }
    }
  };

  const getAttachedEvidenceCount = (evidenceId: string): number =>
    (assessmentControlPointEvidenceList || []).filter(
      (attachedEvidence) => attachedEvidence.evidence_id === evidenceId,
    ).length;

  const getIsAutoAttached = (evidenceId: string): boolean =>
    (assessmentControlPointEvidenceList || []).filter(
      (attachedEvidence) =>
        attachedEvidence.evidence_id === evidenceId && attachedEvidence.is_auto_attached,
    )?.length > 0;

  const rows = (evidenceList || []).map((item) => ({
    uuid: item.uuid,
    name: item.name,
    file: item.file,
    isArchived: item.is_archived,
    isExpired: item.is_expired,
    isAutoAttached: getIsAutoAttached(item.uuid),
    expiryDate: item.expiry_date,
    status: getAttachedEvidenceCount(item.uuid) > 0,
    attachmentCount: getAttachedEvidenceCount(item.uuid),
  }));

  const columns: GridEnrichedColDef[] = [
    {
      field: 'name',
      flex: 2,
      renderCell: (params: GridRenderCellParams<string, EvidenceTableListItem, ReactNode>) =>
        isSmallDesktop ? (
          <Box
            display="flex"
            flexDirection="column"
          >
            <Typography
              color="text.disabled"
              paddingBottom={0.5}
              paddingTop={0.75}
              variant="overline"
            >
              {commonStrings('name')}
            </Typography>
            <MuiLink
              onClick={() =>
                openPreviewDialog({
                  evidenceId: String(params.id),
                })
              }
              sx={{
                cursor: 'pointer',
                color: appColors.blue,
              }}
              variant="body1"
            >
              {params.value}
            </MuiLink>
          </Box>
        ) : (
          <MuiLink
            onClick={() =>
              openPreviewDialog({
                evidenceId: String(params.id),
              })
            }
            sx={{
              cursor: 'pointer',
              color: appColors.blue,
            }}
            variant="body1"
          >
            {params.value}
          </MuiLink>
        ),
    },
    {
      field: 'expiryDate',
      type: 'date',
      flex: 1,
      renderCell: (params: GridRenderCellParams<string, EvidenceTableListItem, ReactNode>) => (
        <Box>
          <Typography
            color="text.disabled"
            variant="overline"
          >
            {commonStrings('expiryDate')}
          </Typography>
          <Typography
            paddingTop={1.125}
            variant="body2"
          >
            {params.value ? format(new Date(params.value), DATE_DISPLAY_FORMAT_FOR_TABLE) : ''}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'attachmentCount',
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams<string, EvidenceTableListItem, ReactNode>) =>
        !params.row.isExpired && (
          <Box>
            <Typography
              color="text.disabled"
              variant="overline"
            >
              {commonStrings('attachedTo')}
            </Typography>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              paddingTop={0.625}
            >
              <Typography
                lineHeight={1.714}
                sx={{ mr: 0.5 }}
                variant="body2"
              >
                {evidenceTableStrings('numberOfQuestions', {
                  questionCount: `${params.value} / ${totalQuestionCount}`,
                })}
              </Typography>
              {params.row.isAutoAttached && (
                <MobileCompatibleTooltip title={evidenceTableStrings('autoAttachedTooltipText')}>
                  <AssistantRounded sx={{ color: appColors.lightPurple }} />
                </MobileCompatibleTooltip>
              )}
            </Box>
          </Box>
        ),
    },
    {
      field: 'actions',
      type: 'actions',
      align: isSmallDesktop ? 'left' : 'right',
      flex: 1,
      getActions: (params: GridRowParams<EvidenceTableListItem>) => {
        if (params.row.isExpired) {
          return [
            <LoadingButton
              key={`${params.row.uuid}_update`}
              color="info"
              disabled={isLoadingDocument}
              loading={isLoadingDocument && clickedButtonId === params.row.uuid}
              onClick={() => handleCreateEvidence(params.row)}
              size="small"
              variant="contained"
            >
              {commonStrings('update')}
            </LoadingButton>,
          ];
        }
        return [
          <LoadingButton
            key={`${params.row.uuid}_review`}
            color="info"
            disabled={isLoadingDocument}
            loading={isLoadingDocument && clickedButtonId === params.row.uuid}
            onClick={() => onReview(params.row.uuid)}
            size="small"
            variant="outlined"
          >
            {evidenceTableStrings('manageAttachment')}
          </LoadingButton>,
        ];
      },
    },
  ];

  const handleClickCreateEvidenceForm = (): void => {
    openEvidenceFormsDialog({
      controlPointIds,
      categoryId: category.uuid,
      evidenceTypeId: evidenceType.uuid,
      helpDocuments: helpDocumentList,
    });
  };

  const isLoading =
    isFetching ||
    isAssessmentControlPointEvidenceListFetching ||
    isAssessmentControlPointEvidenceListLoading;

  return (
    <>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        gap={1.25}
        paddingBottom={2}
        paddingRight={2}
      >
        <Button
          disabled={isLoadingDocument}
          onClick={() => openEvidenceDialog()}
          size="small"
          startIcon={<AddRounded />}
          sx={{ alignSelf: 'flex-start' }}
          variant={rows?.length > 0 ? 'outlined' : 'contained'}
        >
          {commonStrings('attach')}
        </Button>
        {helpDocumentList && helpDocumentList.length > 0 && (
          <Button
            disabled={isLoadingDocument}
            onClick={handleClickCreateEvidenceForm}
            size="small"
            startIcon={<ListAltRounded />}
            variant={rows?.length > 0 ? 'outlined' : 'contained'}
          >
            {evidenceTableStrings('createWithEvidenceForm')}
          </Button>
        )}
      </Box>
      {isLoading ? (
        <Box
          display="flex"
          flexDirection={isSmallDesktop ? 'column' : 'row'}
          gap={2}
        >
          <Skeleton
            height={76}
            width={isSmallDesktop ? '100%' : '40%'}
          />
          <Skeleton
            height={76}
            width={isSmallDesktop ? '100%' : '20%'}
          />
          <Skeleton
            height={76}
            width={isSmallDesktop ? '100%' : '20%'}
          />
          <Skeleton
            height={76}
            width={isSmallDesktop ? '100%' : '20%'}
          />
        </Box>
      ) : (
        rows?.length > 0 && (
          <Box>
            <MobileCompatibleDataGrid
              autoHeight={!isSmallDesktop}
              columns={columns}
              disableColumnSelector
              disableVirtualization
              getCellClassName={(params) => {
                let customCellClassName;
                if (isSmallDesktop) {
                  if (params.row.isExpired) {
                    customCellClassName = 'mobileCell mobileCellHidden';
                  } else {
                    customCellClassName = 'mobileCell';
                  }
                }
                return customCellClassName;
              }}
              getRowClassName={(params) =>
                params.row.isAutoAttached ? 'autoAttachedBackground' : 'mobileRow'
              }
              headerHeight={0}
              hideFooter
              hideToolbar
              NoRowsOverlay={() =>
                CustomNoRowsOverlay(commonStrings('uploadDocumentTableEmptyMsg'))
              }
              pagination={false}
              rowHeight={76}
              rows={rows}
              sx={{
                '& .mobileRow': { flexDirection: { xs: 'column', md: 'row' }, width: '100%' },
                '& .autoAttachedBackground': {
                  backgroundColor: appColors.lightPurple4,
                  flexDirection: { xs: 'column', md: 'row' },
                  width: '100%',
                },
                '& .mobileCell': {
                  minWidth: '100% !important',
                  maxWidth: '100% !important',
                },
                '& .mobileCell[data-colindex="0"]': {
                  borderBottomColor: 'transparent',
                },
                '& .mobileCell[data-colindex="1"]': {
                  borderBottomColor: 'transparent',
                },
                '& .mobileCell[data-colindex="2"]': {
                  borderBottomColor: 'transparent',
                },
                '& .mobileCellHidden[data-colindex="2"]': {
                  borderBottomColor: 'transparent',
                  display: 'none',
                },
                '& .MuiDataGrid-virtualScrollerRenderZone': {
                  width: { xs: '100%', md: 'auto' },
                },
                '& .MuiDataGrid-columnHeaders': { display: 'none' },
                '& .MuiDataGrid-virtualScroller': { marginTop: '0!important' },
                height: {
                  xs:
                    304 * rows.filter((r) => !r.isExpired).length +
                    228 * rows.filter((r) => r.isExpired).length,
                  md: 'auto',
                },
                border: 'none',
                mx: 1,
              }}
            />
          </Box>
        )
      )}
      <EvidencePreviewDialog
        isOpen={isPreviewDialogOpen}
        onClose={closePreviewDialog}
        {...previewDialogProps}
      />
      <CreateEvidenceDialog
        isOpen={isCreateEvidenceDialogOpen}
        onClose={closeCreateEvidenceDialog}
        {...createEvidenceDialogProps}
      />
      <EvidenceTableUploadDialog
        category={category}
        controlPointIds={controlPointIds}
        evidenceType={evidenceType}
        existingEvidenceList={evidenceList}
        isOpen={isEvidenceDialogOpen}
        onClose={closeEvidenceDialog}
        openPreviewDialog={openPreviewDialog}
        refetchEvidenceList={refetchEvidenceList}
      />
    </>
  );
};
