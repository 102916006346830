import {
  type OrganizationCultivationListOutputItem,
  type OrganizationCultivationsListParams,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { injectQueryParams, transformObject } from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'organization-cultivations';

export type TransformedOrganizationCultivationListOutputItem = Omit<
  OrganizationCultivationListOutputItem,
  'season_end_date' | 'season_start_date'
> & {
  season_end_date: Date;
  season_start_date: Date;
};

const responseTransformations = {
  season_end_date: (value: string): Date => new Date(value),
  season_start_date: (value: string): Date => new Date(value),
};

const organizationCultivationEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationCultivationList: build.query<
      TransformedOrganizationCultivationListOutputItem[],
      OrganizationCultivationsListParams
    >({
      query: ({ tid, ...restParams }) => injectQueryParams(`/tid/${tid}/${RESOURCE}/`, restParams),
      providesTags: [
        TagType.OrganizationCultivation,
        TagType.AssessmentPlotCultivations,
        TagType.UserProfile,
      ],
      transformResponse: (data: OrganizationCultivationListOutputItem[]) =>
        data.map((item) => transformObject(item, responseTransformations)),
    }),
  }),
  overrideExisting: false,
});

export const { useGetOrganizationCultivationListQuery } = organizationCultivationEndpoints;
