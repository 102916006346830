import { Badge, Box, Tab, Tabs, Typography } from '@mui/material';
import { type SyntheticEvent } from 'react';
import {
  type AssessmentBaseModuleOutputItem,
  type ModuleListOutputItem,
} from 'src/__generated__/InternalApiTypes';
import { type MatrixColumn, type MatrixRow } from 'src/store/assessmentConfig/types';

interface ColumnHeaderBlockProps {
  activeTabId: string;
  columns: MatrixColumn[];
  handleChangeTab: (event: SyntheticEvent, value: string) => void;
  moduleList: ModuleListOutputItem[] | AssessmentBaseModuleOutputItem[];
  rows?: MatrixRow[];
  seenTabIds?: string[];
  selectedModuleList?: ModuleListOutputItem[];
}

// To show selected standards as Tab
export const ColumnHeaderBlock = ({
  activeTabId,
  columns,
  handleChangeTab,
  moduleList,
  rows,
  seenTabIds,
  selectedModuleList,
}: ColumnHeaderBlockProps): React.JSX.Element | null =>
  activeTabId && columns.find((c) => c.id === activeTabId) ? (
    <Tabs
      allowScrollButtonsMobile
      onChange={handleChangeTab}
      sx={{
        borderRadius: 1,
        borderBottom: 1,
        borderBottomColor: 'divider',
        '& .MuiButtonBase-root.MuiTabScrollButton-root': {
          width: 20,
        },
        '& .Mui-disabled.MuiTabs-scrollButtons': {
          opacity: 0.3,
        },
      }}
      TabIndicatorProps={{ sx: { height: 4 } }}
      value={activeTabId}
      variant="scrollable"
    >
      {columns.map((cell) => {
        const smallLogo = moduleList?.find((m) => m.uuid === cell.id)?.small_logo; // TODO: change to standard logo after re-factoring assessment configuration page to use standards
        const productIds = selectedModuleList?.find((m) => m.uuid === cell.id)?.product_ids;
        let hasExcludedProductBadge = false;
        if (productIds?.length > 0 && !seenTabIds.includes(cell.id)) {
          hasExcludedProductBadge = rows.some((r) => !productIds.includes(r.product?.uuid));
        }
        return (
          <Tab
            key={cell.id}
            label={
              <Badge
                color="warning"
                invisible={!hasExcludedProductBadge}
                sx={{ '& .MuiBadge-badge': { mr: -0.5 }, flex: 1 }}
                variant="dot"
              >
                <Box
                  alignItems="center"
                  display="flex"
                >
                  {smallLogo && (
                    <img
                      alt={`list item: ${smallLogo.name}`}
                      src={smallLogo.file_object}
                      style={{
                        marginRight: '4px',
                        height: '40px',
                        width: '40px',
                      }}
                    />
                  )}
                  <Typography
                    color={cell.id === activeTabId ? 'textPrimary' : 'textSecondary'}
                    fontSize={15}
                    fontWeight={500}
                    letterSpacing={0.46}
                    lineHeight={1.334}
                    textAlign="initial"
                  >
                    {cell.label}
                  </Typography>
                </Box>
              </Badge>
            }
            sx={{
              maxWidth: 248,
              borderBottom: 4,
              px: 1.5,
              py: 1,
              borderBottomColor: activeTabId === cell.id ? 'primary.main' : 'transparent',
            }}
            value={cell.id}
          />
        );
      })}
    </Tabs>
  ) : null;
