import { useContext } from 'react';
import { TenantContext } from 'src/components/TenantProvider';
import { useGetAssessmentUserPermissionsQuery } from 'src/services/farmApi';

export const useGetAssessmentUserPermission = (assessmentId: string | null) => {
  const { tenantId } = useContext(TenantContext);

  const { data: permissions } = useGetAssessmentUserPermissionsQuery(
    { id: assessmentId, tid: tenantId },
    { skip: !tenantId || !assessmentId },
  );

  return permissions;
};
