import { intersectionBy } from 'lodash';
import { useState } from 'react';
import { type EvidenceTypeListOutputItem } from 'src/__generated__/InternalApiTypes';
import { useTenantId } from 'src/hooks';
import {
  useGetAssessmentCategoryEvidenceTypeListQuery,
  useGetAssessmentCriticalityCategoryEvidenceTypeListQuery,
  useGetCategoryListQuery,
  useGetEvidenceListQuery,
  useGetEvidenceTypeListQuery,
} from 'src/services/farmApi';

export interface CategoryData {
  name: string;
  uuid: string;
  parentCategoryId: string;
  parentCategoryName: string;
  alternativeName?: string;
  evidenceTypes?: EvidenceTypeListOutputItem[];
}

interface UseGetAssessmentCategoriesReturnValue {
  assessmentCategories: CategoryData[];
  isAssessmentCategoriesLoading: boolean;
}

export const useGetAssessmentCategories = (
  assessmentId: string | null,
  taxonomicLevel: 1 | 2 | 3,
  filterByHasEvidenceTypes: boolean = undefined,
): UseGetAssessmentCategoriesReturnValue => {
  const tid = useTenantId();
  const { data: evidenceTypeList } = useGetEvidenceTypeListQuery();
  const {
    data: assessmentCategoryEvidenceTypeList,
    isLoading: isAssessmentCategoryEvidenceTypeListLoading,
  } = useGetAssessmentCategoryEvidenceTypeListQuery(
    {
      tid,
      id: assessmentId,
      has_evidence_types: filterByHasEvidenceTypes,
    },
    { skip: !tid || !assessmentId },
  );

  const { data: levelFilteredCategories, isLoading: islevelFilteredCategoriesLoading } =
    useGetCategoryListQuery({ taxonomic_level: [taxonomicLevel] });

  const assessmentCategories = intersectionBy(
    assessmentCategoryEvidenceTypeList,
    levelFilteredCategories,
    'uuid',
  ).map((category) => ({
    name: category.name,
    uuid: category.uuid,
    parentCategoryId: category.parent_category_id,
    parentCategoryName: category.parent_category_name,
    alternativeName: category.alternative_name,
    evidenceTypes: category.evidence_type_ids.map((evidenceTypeId) =>
      evidenceTypeList?.find((evidenceType) => evidenceType.uuid === evidenceTypeId),
    ),
  }));

  return {
    assessmentCategories,
    isAssessmentCategoriesLoading:
      isAssessmentCategoryEvidenceTypeListLoading || islevelFilteredCategoriesLoading,
  };
};

export const useGetAssessmentCriticalityCategories = (
  assessmentId: string,
  taxonomicLevel: 1 | 2 | 3,
  criticalityId: string,
): UseGetAssessmentCategoriesReturnValue => {
  const tid = useTenantId();
  const { data: evidenceTypeList } = useGetEvidenceTypeListQuery();
  const {
    data: assessmentCategoryEvidenceTypeList,
    isLoading: isAssessmentCategoryEvidenceTypeListLoading,
  } = useGetAssessmentCriticalityCategoryEvidenceTypeListQuery(
    {
      tid,
      id: assessmentId,
      agriplace_criticality_id: criticalityId,
    },
    { skip: !tid },
  );

  const filteredAssessmentCategoryEvidenceTypeList = assessmentCategoryEvidenceTypeList?.filter(
    (category) => category.evidence_type_ids?.length > 0,
  );

  const { data: levelFilteredCategories, isLoading: islevelFilteredCategoriesLoading } =
    useGetCategoryListQuery({ taxonomic_level: [taxonomicLevel] });

  const assessmentCategories = intersectionBy(
    filteredAssessmentCategoryEvidenceTypeList,
    levelFilteredCategories,
    'uuid',
  ).map((category) => ({
    name: category.name,
    uuid: category.uuid,
    parentCategoryId: category.parent_category_id,
    parentCategoryName: category.parent_category_name,
    alternativeName: category.alternative_name,
    evidenceTypes: category.evidence_type_ids.map((evidenceTypeId) =>
      evidenceTypeList?.find((evidenceType) => evidenceType.uuid === evidenceTypeId),
    ),
  }));

  return {
    assessmentCategories,
    isAssessmentCategoriesLoading:
      isAssessmentCategoryEvidenceTypeListLoading || islevelFilteredCategoriesLoading,
  };
};

export const useGetFilteredEvidenceList = (
  categoryId: string | null,
  evidenceTypeId?: string | null,
) => {
  const tid = useTenantId();
  const { data: evidenceList, isFetching: isFilteredEvidenceListFetching } =
    useGetEvidenceListQuery(
      { tid, is_archived: false },
      { skip: !tid || !categoryId || !evidenceTypeId },
    );

  const filteredEvidenceList = (evidenceList || []).filter(
    (evidence) =>
      categoryId &&
      evidence.category_ids.includes(categoryId) &&
      evidence.evidence_type_id === evidenceTypeId,
  );

  return {
    filteredEvidenceList,
    isFilteredEvidenceListFetching,
  };
};

export const useLevel3CategoryQueue = () => {
  const [currentLevel3CategoryIndex, setCurrentLevel3CategoryIndex] = useState(0);
  const [currentEvidenceTypeIndex, setCurrentEvidenceTypeIndex] = useState(0);
  const [currentEvidenceItemIndex, setCurrentEvidenceItemIndex] = useState(0);

  const nextEvidenceItem = () => {
    setCurrentEvidenceItemIndex((prev) => prev + 1);
  };

  const resetLevel3CategoryQueue = () => {
    setCurrentLevel3CategoryIndex(0);
    setCurrentEvidenceTypeIndex(0);
    setCurrentEvidenceItemIndex(0);
  };

  const setEvidenceItemIndex = (ind: number) => {
    setCurrentEvidenceItemIndex(ind);
  };
  const setEvidenceTypeIndex = (ind: number) => {
    setCurrentEvidenceTypeIndex(ind);
  };

  const level3CategoryQueue = {
    currentLevel3CategoryIndex,
    currentEvidenceTypeIndex,
    currentEvidenceItemIndex,
  };

  return {
    level3CategoryQueue,
    nextEvidenceItem,
    resetLevel3CategoryQueue,
    setEvidenceItemIndex,
    setEvidenceTypeIndex,
  };
};
