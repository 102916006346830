import { type CognitoUser } from '@aws-amplify/auth';
import { createContext, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { AccountTypeEnum } from 'src/__generated__/InternalApiTypes';
import { AuthStatus } from 'src/enums/AuthStatus';
import { useGetCurrentUserProfile } from 'src/hooks';
import { useUserAuth } from 'src/hooks/useUserAuth';
import { useGetOrganizationInviteeInvitationListQuery } from 'src/services/farmApi';
import {
  ROUTE_PATH_ACCOUNT_INVITATIONS,
  ROUTE_PATH_CREATE_ORGANIZATION,
  ROUTE_PATH_DASHBOARD,
} from 'src/settings';

import { LoadingArea } from '../common';

interface PreventAuthProps {
  children: React.JSX.Element;
}

type AuthContextValueType = {
  user: CognitoUser | null;
  setUser: React.Dispatch<React.SetStateAction<CognitoUser | null>> | undefined;
};

export const AuthContext = createContext<AuthContextValueType>({
  user: null,
  setUser: undefined,
});

export const PreventAuth = ({ children }: PreventAuthProps): React.JSX.Element => {
  const { authStatus } = useUserAuth();
  const { data: userProfile } = useGetCurrentUserProfile() || {};
  const [user, setUser] = useState(null);
  const [searchParams] = useSearchParams();
  const redirectPath = searchParams.get('redirect');
  const { data: accountInvitations, isLoading: isLoadingAccountInvitations } =
    useGetOrganizationInviteeInvitationListQuery(
      { is_pending: true },
      {
        skip: authStatus !== AuthStatus.LoggedIn,
      },
    );
  const hasInvitation = accountInvitations && accountInvitations.length > 0;

  // If user is logged in, redirect them
  if (authStatus === AuthStatus.LoggedIn) {
    if (isLoadingAccountInvitations) {
      return (
        <LoadingArea
          hasAgriplaceLoadingAnimation
          size={72}
        />
      );
    }

    let navigateLink = ROUTE_PATH_ACCOUNT_INVITATIONS;

    const noInvitationFlow = () => {
      if (userProfile?.memberships && userProfile.memberships.length <= 0) {
        if (userProfile?.account_type === AccountTypeEnum.Farmer) {
          navigateLink = ROUTE_PATH_CREATE_ORGANIZATION;
        } else {
          navigateLink = ROUTE_PATH_ACCOUNT_INVITATIONS;
        }
      } else {
        navigateLink = ROUTE_PATH_DASHBOARD;
      }
    };

    if (redirectPath) {
      navigateLink = `/${redirectPath}`;
    } else if (!hasInvitation) {
      noInvitationFlow();
    }

    return (
      <Navigate
        replace
        to={navigateLink}
      />
    );
  }

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AuthContext.Provider value={{ user, setUser }}>{children}</AuthContext.Provider>
  );
};
