import { Check, ContentCopyRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { MobileCompatibleTooltip } from 'src/components/common/MobileCompatibleTooltip';
import { ThemeContext } from 'src/components/ThemeProvider';
import { assessmentFinishDialogForAuditorsStrings, commonStrings } from 'src/languages/en-UK';

interface AssessmentFinishDialogForAuditorsProps {
  onClose: () => void;
  onFinish: () => void;
}

export const AssessmentFinishDialogForAuditors = ({
  onClose,
  onFinish,
}: AssessmentFinishDialogForAuditorsProps): React.JSX.Element => {
  const { theme } = useContext(ThemeContext);
  const urlToShare = window.location.toString().split('?')[0];
  const [hasCopiedUrl, setHasCopiedUrl] = useState(false);
  const handleCopyUrl = () => {
    navigator.clipboard.writeText(urlToShare);
    setHasCopiedUrl(true);
  };
  const onFinishWithCopy = () => {
    handleCopyUrl();
    onFinish();
  };
  return (
    <>
      <DialogTitle>{assessmentFinishDialogForAuditorsStrings('inspectionWasSaved')}</DialogTitle>
      <DialogContent>
        <Typography marginBottom={2}>
          {assessmentFinishDialogForAuditorsStrings('completeAssessmentSuccess')}
        </Typography>
        <Typography marginBottom={2}>{commonStrings('shareInspectionToColleagues')}</Typography>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
        >
          <TextField
            disabled
            fullWidth
            id="outlined-url"
            multiline
            sx={{
              pr: 1,
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: theme.palette.text.primary,
              },
            }}
            value={urlToShare}
            variant="outlined"
          />
          <IconButton
            aria-haspopup="true"
            aria-label="copy shareable link"
            id="copy-button"
            onClick={handleCopyUrl}
          >
            {hasCopiedUrl ? (
              <MobileCompatibleTooltip title={commonStrings('copied')}>
                <Check color="success" />
              </MobileCompatibleTooltip>
            ) : (
              <MobileCompatibleTooltip title={commonStrings('copyLinkToClipboard')}>
                <ContentCopyRounded />
              </MobileCompatibleTooltip>
            )}
          </IconButton>
        </Box>
      </DialogContent>
      <DialogActions sx={{ mt: 3 }}>
        <Button
          onClick={onClose}
          variant="outlined"
        >
          {assessmentFinishDialogForAuditorsStrings('backToEdit')}
        </Button>
        <Button
          onClick={onFinishWithCopy}
          variant="contained"
        >
          {assessmentFinishDialogForAuditorsStrings('copyLinkAndFinish')}
        </Button>
      </DialogActions>
    </>
  );
};
