import {
  type UserProfileCreateOutput,
  type UserProfileDetailOutput,
  type UserProfileUpdateInput,
  type UserProfileUpdateOutput,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { type DetailParams, type UpdateParams } from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'user-profiles';

const userProfileEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUserProfile: build.query<UserProfileDetailOutput, string>({
      query: (id) => `${RESOURCE}/${id}/`,
      providesTags: [TagType.UserProfile],
    }),

    updateUserProfile: build.mutation<
      UserProfileUpdateOutput,
      UpdateParams<UserProfileUpdateInput>
    >({
      query: ({ id, body }) => ({
        url: `/${RESOURCE}/${id}/update/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [TagType.UserProfile],
    }),

    // TODOHasan: This endpoint is missing BE
    deleteUserProfile: build.mutation<void, DetailParams>({
      query: ({ id }) => ({
        url: `${RESOURCE}/${id}/delete/`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, args) => [
        { type: TagType.UserProfile, id: 'LIST' },
        { type: TagType.UserProfile, id: args.id },
      ],
    }),

    getOrCreateUserProfile: build.mutation<UserProfileCreateOutput, string>({
      query: (id) => ({
        url: `/${RESOURCE}/create/`,
        method: 'POST',
        body: { user_profile_uuid: id },
      }),
      invalidatesTags: [TagType.UserProfile],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetOrCreateUserProfileMutation,
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useDeleteUserProfileMutation,
} = userProfileEndpoints;
