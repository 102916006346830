import {
  AppBar,
  Box,
  CircularProgress,
  Container,
  Paper,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { cloneElement, useContext, useState } from 'react';
import { type AssessmentAgriplaceCriticalityListOutputItem } from 'src/__generated__/InternalApiTypes';
import { AssessmentActionBarButtons } from 'src/components/AssessmentActionBarButtons';
import { useTabs } from 'src/components/common/TabbedPage/TabbedPage.hooks';
import { useTenantId } from 'src/hooks';
import { assessmentEvidenceStepStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import {
  useGetAssessmentAgriplaceCriticalityListQuery,
  useGetAssessmentCriticalityProgressIndicationQuery,
} from 'src/services/farmApi';

import { type StepperContentBaseProps } from '../StepperPage';
import { AssessmentEvidenceStepTabContent } from './AssessmentEvidenceStepTabContent';

export const AssessmentEvidenceStep = ({
  maxWidth = 'xl',
  ...props
}: StepperContentBaseProps): React.JSX.Element => {
  const tid = useTenantId();
  const [assessmentId] = useContext(AssessmentPageContext);
  const { data: assessmentAgriplaceCriticalityList } =
    useGetAssessmentAgriplaceCriticalityListQuery(
      {
        tid,
        id: assessmentId,
      },
      { skip: !tid || !assessmentId },
    );

  const { data: assessmentCriticalityProgressIndication } =
    useGetAssessmentCriticalityProgressIndicationQuery(
      {
        tid,
        id: assessmentId,
      },
      { skip: !tid || !assessmentId },
    );

  const getTabComponent = (criticality: AssessmentAgriplaceCriticalityListOutputItem) => {
    const assessmentAgriplaceCriticalityIds = assessmentAgriplaceCriticalityList?.map(
      (c) => c.uuid,
    );

    switch (criticality?.priority) {
      case 1:
        return {
          id: criticality.uuid,
          slug: criticality.slug,
          title: assessmentEvidenceStepStrings('mandatoryDocumentation'),
          content: (
            <AssessmentEvidenceStepTabContent
              assessmentAgriplaceCriticalityIds={assessmentAgriplaceCriticalityIds}
              criticalityId={criticality.uuid}
              description={assessmentEvidenceStepStrings('mandatoryDocumentationDesc')}
            />
          ),
        };
      case 2:
        return {
          id: criticality.uuid,
          slug: criticality.slug,
          title: assessmentEvidenceStepStrings('essentialDocumentation'),
          content: (
            <AssessmentEvidenceStepTabContent
              assessmentAgriplaceCriticalityIds={assessmentAgriplaceCriticalityIds}
              criticalityId={criticality.uuid}
              description={assessmentEvidenceStepStrings('essentialDocumentationDesc')}
            />
          ),
        };
      case 3:
        return {
          id: criticality.uuid,
          slug: criticality.slug,
          title: assessmentEvidenceStepStrings('optionalDocumentation'),
          content: (
            <AssessmentEvidenceStepTabContent
              assessmentAgriplaceCriticalityIds={assessmentAgriplaceCriticalityIds}
              criticalityId={criticality.uuid}
              description={assessmentEvidenceStepStrings('optionalDocumentationDesc')}
            />
          ),
        };
      default:
        return null;
    }
  };

  const tabs = (assessmentAgriplaceCriticalityList || []).map((criticality) =>
    getTabComponent(criticality),
  );

  const [activeTabIndex, handleChangeTab] = useTabs(tabs);
  const [isNextTaskLoading, setIsNextTaskLoading] = useState(false);
  const [isPreviousTaskLoading, setIsPreviousTaskLoading] = useState(false);

  const navigateToNextStep = () => {
    if (props.isFinalTask) {
      props.openFinishDialog();
    } else {
      setIsNextTaskLoading(true);
      props.moveToNextTask();
    }
  };

  const navigateToPreviousStep = () => {
    setIsPreviousTaskLoading(true);
    props.moveToPreviousTask();
  };

  return (
    <>
      <Container maxWidth={maxWidth}>
        <Paper sx={{ p: 2, mb: 2 }}>
          <Typography
            marginBottom={3}
            variant="h1"
          >
            {assessmentEvidenceStepStrings('attachingDocumentation')}
          </Typography>
          <Typography variant="body1">
            {assessmentEvidenceStepStrings('documentReviewStepDescription')}
          </Typography>
        </Paper>
        <Tabs
          allowScrollButtonsMobile
          onChange={handleChangeTab}
          sx={{
            flex: 1,
            flexGrow: 1,
            borderRadius: 1,
            borderBottom: 1,
            borderBottomColor: 'divider',
            '& .MuiButtonBase-root.MuiTabScrollButton-root': {
              width: 20,
            },
            '& .Mui-disabled.MuiTabs-scrollButtons': {
              opacity: 0.3,
            },
          }}
          TabIndicatorProps={{ sx: { height: 4 } }}
          value={activeTabIndex}
          variant="scrollable"
        >
          {tabs.map((tab) => {
            const { progress } =
              assessmentCriticalityProgressIndication?.find(
                (indication) => indication.criticality_id === tab?.id,
              ) || {};
            return (
              <Tab
                key={tab?.slug}
                icon={
                  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    {progress === undefined ? (
                      <Skeleton
                        height={40}
                        variant="circular"
                        width={40}
                      />
                    ) : (
                      <>
                        <CircularProgress
                          value={progress}
                          variant="determinate"
                        />
                        <Box
                          sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography
                            color="textSecondary"
                            component="div"
                            letterSpacing={0}
                            variant="caption"
                          >
                            {progress || progress === 0 ? `${Math.round(progress)}%` : ''}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Box>
                }
                iconPosition="end"
                label={tab?.title}
                sx={{
                  py: 0,
                  minHeight: 54,
                  minWidth: `calc(100% * (1/${tabs.length}))`,
                }}
              />
            );
          })}
        </Tabs>
        <Box paddingTop={2}>
          {tabs?.map((tab, i) =>
            activeTabIndex === i ? cloneElement(tab?.content, { key: tab?.slug }) : null,
          )}
        </Box>
      </Container>
      <AppBar
        position="fixed"
        sx={{ top: 'auto', bottom: 0 }}
      >
        <Container maxWidth={maxWidth}>
          <AssessmentActionBarButtons
            containerStyle={{ justifyContent: 'right' }}
            isDisabled={isPreviousTaskLoading || isNextTaskLoading}
            isFinalTask={props.isFinalTask}
            isFirstTask={props.isFirstTask}
            isNextButtonLoading={isNextTaskLoading}
            isPreviousButtonLoading={isPreviousTaskLoading}
            nextTaskName={props.nextTaskName}
            onNext={navigateToNextStep}
            onPrevious={navigateToPreviousStep}
          />
        </Container>
      </AppBar>
    </>
  );
};
