import { type Theme } from '@mui/material';

export const borderWidth = 1;
export const standardBorder = (theme: Theme) => `${borderWidth}px solid ${theme.palette.divider}`;
export const gridSize = 42;
export const headerTextStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
  height: '100%',
  px: 1,
};

export const wrapperStyle = {
  overflow: 'auto',
  flex: '0 1 auto',
  height: '100%',
  display: 'flex',
};

export const cultivationAreaStyle = () => ({
  maxWidth: 600,
});

export const cultivationRowStyle = {
  display: 'flex',
  width: 'min-content',
  zIndex: 993,
};

export const bodyEmptyStateStyle = () => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  width: '100%',
});
