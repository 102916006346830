import { useGetUserProfileQuery } from 'src/services/farmApi';

import { useUserAuth } from './useUserAuth';

export const useCurrentUser = (additionalSkip = false) => {
  const { userId } = useUserAuth();
  const {
    data: userProfileData,
    isLoading: isUserProfileLoading,
    isError: isUserProfileError,
    isSuccess: isUserProfileSuccess,
    ...rest
  } = useGetUserProfileQuery(userId, {
    skip: !userId || additionalSkip,
  });

  return {
    ...userProfileData,
    isUserProfileLoading,
    isUserProfileError,
    isUserProfileSuccess,
    ...rest,
  };
};
