import { CloseRounded } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { generatePath, useNavigate } from 'react-router-dom';
import { type AssessmentStandardRequestListOutputItem } from 'src/__generated__/InternalApiTypes';
import { useMutationFeedback, useTenantId } from 'src/hooks';
import { approvedOutgoingRequestDialogStrings, commonStrings } from 'src/languages/en-UK';
import { useCreateConnectedAssessmentMutation } from 'src/services/farmApi';
import { ROUTE_PATH_ASSESSMENT } from 'src/settings';

export interface ApprovedOutgoingRequestDialogProps {
  isOpen: boolean;
  onClose: () => void;
  requestDetail: AssessmentStandardRequestListOutputItem;
}

export const ApprovedOutgoingRequestDialog = ({
  onClose,
  requestDetail,
  isOpen = false,
}: ApprovedOutgoingRequestDialogProps): React.JSX.Element => {
  const tid = useTenantId();
  const navigate = useNavigate();

  const [createConnectedAssessment, createConnectedAssessmentResult] =
    useCreateConnectedAssessmentMutation();

  const onCreateConnectedAssessment = () => {
    if (requestDetail?.uuid && tid) {
      createConnectedAssessment({
        tid,
        body: {
          assessment_standard_request_id: requestDetail.uuid,
        },
      });
    }
  };

  useMutationFeedback({
    result: createConnectedAssessmentResult,
    onSuccess: (result) => {
      navigate(generatePath(ROUTE_PATH_ASSESSMENT, { id: result.uuid }));
    },
    successMessage: approvedOutgoingRequestDialogStrings('createConnectedAssessmentSuccess'),
    errorMessage: approvedOutgoingRequestDialogStrings('createConnectedAssessmentFail'),
  });

  return (
    <Dialog
      fullWidth
      onClose={onClose}
      open={isOpen}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          padding: 1.5,
        }}
      >
        <CloseRounded />
      </IconButton>
      <DialogContent>
        <Stack spacing={2}>
          <Typography
            marginBottom={3}
            variant="h2"
          >
            {commonStrings('requestApproved')}
          </Typography>
          {requestDetail?.organization_name && (
            <Box
              alignItems="center"
              display="flex"
              paddingLeft={0.5}
              paddingY={0.5}
            >
              <Avatar
                sx={{
                  fontSize: 'auto',
                }}
              >
                {requestDetail.organization_name.substring(0, 1).toUpperCase()}
              </Avatar>
              <Typography
                marginLeft={0.5}
                variant="body1"
              >
                {`${requestDetail.organization_name} (${requestDetail.approver_email}; ${requestDetail.client_identifier_value})`}
              </Typography>
            </Box>
          )}
          <Typography variant="body1">
            {approvedOutgoingRequestDialogStrings('approvedOutgoingRequestDialogMessage')}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', pt: 3 }}>
        <LoadingButton
          loading={createConnectedAssessmentResult.isLoading}
          onClick={onCreateConnectedAssessment}
          variant="contained"
        >
          {approvedOutgoingRequestDialogStrings('createConnectedInspection')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
