import {
  type InviteeOrganizationInvitationAcceptOutput,
  type InviteeOrganizationInvitationListOutputItem,
  type InviteeOrganizationInvitationRejectOutput,
  type InviterOrganizationInvitationCreateInput,
  type InviterOrganizationInvitationCreateOutput,
  type InviterOrganizationInvitationDetailOutput,
  type InviterOrganizationInvitationListOutputItem,
  type InviterOrganizationInvitationUpdateInput,
  type InviterOrganizationInvitationUpdateOutput,
  type OrganizationInvitationsInviteeInvitationsListParams,
  type OrganizationInvitationsInviterInvitationsListParams,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import {
  type CreateParamsWithTenantId,
  type DeleteParamsWithTenantId,
  type DetailParamsWithTenantId,
  injectQueryParams,
  type UpdateParamsWithTenantId,
} from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'organization-invitations';

const organizationInvitationsEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationInviterInvitationList: build.query<
      InviterOrganizationInvitationListOutputItem[],
      OrganizationInvitationsInviterInvitationsListParams
    >({
      query: ({ tid, ...restParams }) =>
        injectQueryParams(`/tid/${tid}/${RESOURCE}/inviter-invitations/`, restParams),
      providesTags: [{ type: TagType.OrganizationInvitations, id: 'LIST' }],
    }),

    getOrganizationInviterInvitation: build.query<
      InviterOrganizationInvitationDetailOutput,
      DetailParamsWithTenantId
    >({
      query: ({ tid, id }) => `/tid/${tid}/${RESOURCE}/inviter-invitations/${id}/`,
      providesTags: (result) => [{ type: TagType.OrganizationInvitations, id: result?.uuid }],
    }),

    createOrganizationInviterInvitation: build.mutation<
      InviterOrganizationInvitationCreateOutput,
      CreateParamsWithTenantId<InviterOrganizationInvitationCreateInput>
    >({
      query: ({ tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/inviter-invitations/create/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: TagType.OrganizationInvitations, id: 'LIST' }],
    }),

    deleteOrganizationInviterInvitation: build.mutation<void, DeleteParamsWithTenantId>({
      query: ({ id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/inviter-invitations/${id}/delete/`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, args) => [
        { type: TagType.OrganizationInvitations, id: 'LIST' },
        { type: TagType.OrganizationInvitations, id: args.id },
      ],
    }),

    updateOrganizationInviterInvitation: build.mutation<
      InviterOrganizationInvitationUpdateOutput,
      UpdateParamsWithTenantId<InviterOrganizationInvitationUpdateInput>
    >({
      query: ({ id, tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/inviter-invitations/${id}/update/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_result, _error, args) => [
        { type: TagType.OrganizationInvitations, id: 'LIST' },
        { type: TagType.OrganizationInvitations, id: args.id },
      ],
    }),

    getOrganizationInviteeInvitationList: build.query<
      InviteeOrganizationInvitationListOutputItem[],
      OrganizationInvitationsInviteeInvitationsListParams
    >({
      query: ({ ...restParams }) =>
        injectQueryParams(`${RESOURCE}/invitee-invitations/`, restParams, true),
      providesTags: [{ type: TagType.OrganizationInvitations, id: 'LIST' }],
    }),

    acceptOrganizationInviteeInvitation: build.mutation<
      InviteeOrganizationInvitationAcceptOutput,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${RESOURCE}/invitee-invitations/${id}/accept/`,
        method: 'PATCH',
      }),
      invalidatesTags: (_result, _error, args) => [
        { type: TagType.OrganizationInvitations, id: 'LIST' },
        { type: TagType.OrganizationInvitations, id: args.id },
        { type: TagType.UserProfile },
      ],
    }),

    rejectOrganizationInviteeInvitation: build.mutation<
      InviteeOrganizationInvitationRejectOutput,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${RESOURCE}/invitee-invitations/${id}/reject/`,
        method: 'PATCH',
      }),
      invalidatesTags: (_result, _error, args) => [
        { type: TagType.OrganizationInvitations, id: 'LIST' },
        { type: TagType.OrganizationInvitations, id: args.id },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetOrganizationInviterInvitationListQuery,
  useGetOrganizationInviteeInvitationListQuery,
  useGetOrganizationInviterInvitationQuery,
  useCreateOrganizationInviterInvitationMutation,
  useDeleteOrganizationInviterInvitationMutation,
  useUpdateOrganizationInviterInvitationMutation,
  useAcceptOrganizationInviteeInvitationMutation,
  useRejectOrganizationInviteeInvitationMutation,
} = organizationInvitationsEndpoints;
