import { DeleteForeverRounded, EditRounded, Send as SendIcon } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { type MutableRefObject, useEffect, useState } from 'react';
import { type AssessmentControlPointAnswerListOutputItem } from 'src/__generated__/InternalApiTypes';
import { commonStrings, justificationTextFieldStrings } from 'src/languages/en-UK';

interface JustificationTextFieldProps {
  answerItem: AssessmentControlPointAnswerListOutputItem;
  inputRef: MutableRefObject<HTMLInputElement>;
  isDisabled: boolean;
  isLoading: boolean;
  onUpdate: (textValue: string, cultivationId: string, existingTextValue: string) => void;
}

export const JustificationTextField = ({
  answerItem,
  inputRef,
  onUpdate,
  isDisabled = false,
  isLoading = false,
}: JustificationTextFieldProps): React.JSX.Element => {
  const [textValue, setTextValue] = useState(answerItem?.justification_text || '');
  const [isEditActive, setIsEditActive] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setTextValue(answerItem?.justification_text || '');
    if (answerItem?.justification_text) {
      setIsEditActive(true);
    }
  }, [answerItem?.justification_text]);

  const showEditDeleteButtons =
    isEditActive &&
    !isDisabled &&
    !isFocused &&
    textValue &&
    answerItem?.justification_text === textValue;

  return (
    (!isDisabled || (textValue && isDisabled)) && (
      <>
        <Typography
          sx={{ color: 'textSecondary', mb: 1, mt: 3 }}
          variant="overline"
        >
          {justificationTextFieldStrings('justification')}
        </Typography>
        <Stack sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
          <TextField
            disabled={isDisabled}
            fullWidth
            InputProps={
              showEditDeleteButtons
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => {
                            setIsEditActive(false);
                            setIsFocused(true);
                            inputRef.current.focus();
                          }}
                          sx={{ mr: 0 }}
                        >
                          <EditRounded />
                        </IconButton>
                        <IconButton
                          edge="end"
                          onClick={() => {
                            setTextValue('');
                            onUpdate(
                              '',
                              answerItem?.organization_cultivation_id,
                              answerItem?.justification_text,
                            );
                          }}
                        >
                          <DeleteForeverRounded />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }
                : undefined
            }
            inputRef={inputRef}
            maxRows={5}
            multiline
            onBlur={(e) => {
              if (e?.target?.value === answerItem?.justification_text) {
                setIsEditActive(true);
              }
              setIsFocused(false);
            }}
            onChange={(e) => {
              const val = e.target.value;
              setTextValue(val);
            }}
            onFocus={() => {
              setIsFocused(true);
            }}
            placeholder={justificationTextFieldStrings('addJustification')}
            size="small"
            sx={{
              whiteSpace: 'nowrap',
            }}
            value={textValue}
          />
          {!showEditDeleteButtons && (
            <LoadingButton
              color="info"
              endIcon={<SendIcon />}
              loading={isLoading}
              onClick={() => {
                onUpdate(
                  textValue,
                  answerItem?.organization_cultivation_id,
                  answerItem?.justification_text,
                );
              }}
              sx={{ paddingY: 0.875, ml: 1 }}
              type="submit"
              variant="outlined"
            >
              {commonStrings('add')}
            </LoadingButton>
          )}
        </Stack>
      </>
    )
  );
};
