import { useContext, useEffect, useState } from 'react';
import {
  type AssessmentDetailOutput,
  // type DraftDetachedEvidence,
  type PreliminaryQuestionAnswerListBulkUpdateInput,
  type PreliminaryQuestionListOutputItem,
} from 'src/__generated__/InternalApiTypes';
import {
  useAppDispatch,
  useGetAssessmentUserPermission,
  useGetCurrentUserProfile,
  useMutationFeedback,
  useTenantId,
} from 'src/hooks';
import { assessmentPreliminaryQuestionsStepStrings, commonStrings } from 'src/languages/en-UK';
import { AssessmentPageContext } from 'src/pages/AssessmentPage/AssessmentPage.context';
import {
  useBulkUpdateAssessmentPreliminaryQuestionAnswersDraftMutation,
  useBulkUpdateAssessmentPreliminaryQuestionAnswersMutation,
  useGetAssessmentPreliminaryQuestionAnswerListQuery,
  useGetPossibleAnswerListQuery,
  useGetPossibleAnswerSetListQuery,
  useGetPreliminaryQuestionListQuery,
} from 'src/services/farmApi';
import { openSnackbar } from 'src/store/snackbarSlice';

import { type FormikFieldConfig } from './AssessmentPreliminaryQuestionsStep.types';
import { createFormikFields } from './AssessmentPreliminaryQuestionsStep.utils';

export const useGetPreliminaryQuestionFields = (
  assessment?: AssessmentDetailOutput,
): {
  fields: FormikFieldConfig[];
  isLoading: boolean;
  preliminaryQuestionList: PreliminaryQuestionListOutputItem[];
} => {
  const [fields, setFields] = useState<FormikFieldConfig[]>([]);
  const tid = useTenantId();

  // Get user data
  const { data: preliminaryQuestionAnswerList, isLoading: isPreliminaryAnswerListLoading } =
    useGetAssessmentPreliminaryQuestionAnswerListQuery(
      { assessment_id: [assessment?.uuid], tid },
      {
        skip: !assessment || !tid,
      },
    );

  // Get master data
  const { data: preliminaryQuestionList, isLoading: isPreliminaryQuestionListLoading } =
    useGetPreliminaryQuestionListQuery(
      {
        preliminary_question_set_id: assessment?.preliminary_question_set_id,
        assessment_id: assessment?.uuid,
      },
      {
        skip: !assessment,
      },
    );
  const { data: possibleAnswerSetList, isLoading: isPossibleAnswerSetListLoading } =
    useGetPossibleAnswerSetListQuery();
  const { data: possibleAnswerList, isLoading: isPossibleAnswerListLoading } =
    useGetPossibleAnswerListQuery();
  const { data: userProfile } = useGetCurrentUserProfile();
  const language = userProfile?.language;

  const isLoading =
    isPossibleAnswerListLoading ||
    isPossibleAnswerSetListLoading ||
    isPreliminaryAnswerListLoading ||
    isPreliminaryQuestionListLoading;

  // Crunching...
  useEffect(() => {
    if (!isLoading) {
      // Moved to util file mostly to improve readability
      setFields(
        createFormikFields(
          possibleAnswerList,
          possibleAnswerSetList,
          preliminaryQuestionAnswerList,
          preliminaryQuestionList,
          language,
        ),
      );
    }
  }, [
    language,
    possibleAnswerList,
    possibleAnswerSetList,
    preliminaryQuestionAnswerList,
    preliminaryQuestionList,
    isLoading,
  ]);

  return {
    fields,
    isLoading,
    preliminaryQuestionList,
  };
};

export const useUpdatePreliminaryQuestionAnswers = () => {
  const tid = useTenantId();
  // const [shouldOpenConfirmDialog, setShouldOpenConfirmDialog] = useState(false);
  const [shouldContinue, setShouldContinue] = useState(false);
  // const [evidenceToBeDetached, setEvidenceToBeDetached] = useState<DraftDetachedEvidence[]>([]);
  const [assessmentId] = useContext(AssessmentPageContext);
  const dispatch = useAppDispatch();
  const permissions = useGetAssessmentUserPermission(assessmentId);
  const [answerValues, setAnswerValues] =
    useState<PreliminaryQuestionAnswerListBulkUpdateInput[]>();
  const [
    bulkUpdateAssessmentPreliminaryQuestionAnswersDraft,
    bulkUpdateAssessmentPreliminaryQuestionAnswerDraftResult,
  ] = useBulkUpdateAssessmentPreliminaryQuestionAnswersDraftMutation();

  const [
    bulkUpdateAssessmentPreliminaryQuestionAnswers,
    bulkUpdateAssessmentPreliminaryQuestionAnswersResult,
  ] = useBulkUpdateAssessmentPreliminaryQuestionAnswersMutation();

  const draftUpdate = (answers: PreliminaryQuestionAnswerListBulkUpdateInput[]) => {
    if (answers?.length > 0) {
      // setShouldOpenConfirmDialog(false);
      setAnswerValues(answers);
      if (permissions?.preliminary_questions?.update) {
        bulkUpdateAssessmentPreliminaryQuestionAnswersDraft({
          tid,
          body: {
            assessment_id: assessmentId,
            preliminary_question_answers: answers,
          },
        });
      } else {
        dispatch(
          openSnackbar({
            message: commonStrings('notAuthorized'),
            severity: 'error',
          }),
        );
      }
    }
  };

  const nonDraftUpdate = (answers: PreliminaryQuestionAnswerListBulkUpdateInput[]) => {
    if (permissions?.preliminary_questions?.update) {
      bulkUpdateAssessmentPreliminaryQuestionAnswers({
        tid,
        body: {
          assessment_id: assessmentId,
          preliminary_question_answers: answers,
        },
      });
    } else {
      dispatch(
        openSnackbar({
          message: commonStrings('notAuthorized'),
          severity: 'error',
        }),
      );
    }
  };

  useEffect(() => {
    if (bulkUpdateAssessmentPreliminaryQuestionAnswerDraftResult?.isSuccess) {
      /* if (
        bulkUpdateAssessmentPreliminaryQuestionAnswerDraftResult?.data?.expected_changes
          .detached_evidence.length > 1
      ) {
        setEvidenceToBeDetached(
          bulkUpdateAssessmentPreliminaryQuestionAnswerDraftResult?.data?.expected_changes
            .detached_evidence,
        );
        setShouldOpenConfirmDialog(true);
      } else */ if (permissions?.preliminary_questions?.update) {
        bulkUpdateAssessmentPreliminaryQuestionAnswers({
          tid,
          body: {
            assessment_id: assessmentId,
            preliminary_question_answers: answerValues,
          },
        });
      } else {
        dispatch(
          openSnackbar({
            message: commonStrings('notAuthorized'),
            severity: 'error',
          }),
        );
      }
    }
  }, [bulkUpdateAssessmentPreliminaryQuestionAnswerDraftResult?.isSuccess]);

  useEffect(() => {
    if (bulkUpdateAssessmentPreliminaryQuestionAnswersResult?.isSuccess) {
      setShouldContinue(true);
    }
  }, [bulkUpdateAssessmentPreliminaryQuestionAnswersResult?.isSuccess, setShouldContinue]);

  useMutationFeedback({
    result: bulkUpdateAssessmentPreliminaryQuestionAnswerDraftResult,
    errorMessage: assessmentPreliminaryQuestionsStepStrings('savePreliminaryQuestionsFail'),
  });

  useMutationFeedback({
    result: bulkUpdateAssessmentPreliminaryQuestionAnswersResult,
    successMessage: assessmentPreliminaryQuestionsStepStrings('savePreliminaryQuestionsSuccess'),
    errorMessage: assessmentPreliminaryQuestionsStepStrings('savePreliminaryQuestionsFail'),
  });

  return {
    isLoading:
      bulkUpdateAssessmentPreliminaryQuestionAnswerDraftResult?.isLoading ||
      bulkUpdateAssessmentPreliminaryQuestionAnswersResult?.isLoading,
    draftUpdate,
    nonDraftUpdate,
    // shouldOpenConfirmDialog,
    shouldContinue,
    // evidenceToBeDetached,
  };
};
