import { CloseRounded } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
} from '@mui/material';
import { DocumentPreview } from 'src/components/common/DocumentPreview';
import { useDialogState, useTenantId } from 'src/hooks';
import { commonStrings, createEvidenceDialogStrings } from 'src/languages/en-UK';
import { useGetEvidenceQuery } from 'src/services/farmApi';

import {
  CreateEvidenceSaveDialog,
  type CreateEvidenceSaveDialogProps,
} from '../CreateEvidenceSaveDialog';

export interface CreateEvidenceDialogProps {
  evidenceId: string;
  isOpen: boolean;
  isSignatureRequired: boolean;
  onClose: () => void;
  assessmentId?: string;
  controlPointIds?: string[];
  controlPointName?: string;
  evidenceTypeIds?: string[];
  onCloseEvidenceFormsDialog?: () => void;
}

export const CreateEvidenceDialog = ({
  assessmentId,
  controlPointIds,
  controlPointName,
  evidenceId,
  evidenceTypeIds,
  onClose,
  onCloseEvidenceFormsDialog,
  isOpen = false,
  isSignatureRequired = false,
}: CreateEvidenceDialogProps) => {
  const tid = useTenantId();
  const { data: evidence, isFetching } = useGetEvidenceQuery(
    { tid, id: evidenceId },
    {
      skip: !evidenceId || !tid,
    },
  );

  const [
    isCreateEvidenceSaveDialogOpen,
    openCreateEvidenceSaveDialog,
    closeCreateEvidenceSaveDialog,
    createEvidenceSaveDialogProps,
  ] = useDialogState<CreateEvidenceSaveDialogProps>();

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    if (evidence) {
      openCreateEvidenceSaveDialog({
        assessmentId,
        controlPointIds,
        controlPointName,
        evidenceTypeIds,
        isSignatureRequired,
        onCloseEvidenceFormsDialog,
        documentTitle: evidence?.name,
        evidenceId: evidence?.uuid,
        onCloseCreateEvidenceDialog: onClose,
      });
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        open={isOpen}
        PaperProps={{
          style: {
            minHeight: '90%',
            maxHeight: '90%',
          },
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            padding: 1.5,
          }}
        >
          <CloseRounded />
        </IconButton>
        <DialogTitle>
          {isFetching ? (
            <Skeleton
              variant="text"
              width="33%"
            />
          ) : (
            createEvidenceDialogStrings('creatingEvidenceTitle', {
              name: evidence?.name,
            })
          )}
        </DialogTitle>
        <DialogContent>
          <DocumentPreview
            id={evidence?.file?.uuid || ''}
            isEditable
            isLoading={isFetching}
            title={evidence?.name}
            url={evidence?.file?.file_object}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSave}
            variant="contained"
          >
            {commonStrings('saveAs')}
          </Button>
        </DialogActions>
      </Dialog>
      {isCreateEvidenceSaveDialogOpen && (
        <CreateEvidenceSaveDialog
          isOpen={isCreateEvidenceSaveDialogOpen}
          onClose={closeCreateEvidenceSaveDialog}
          {...createEvidenceSaveDialogProps}
        />
      )}
    </>
  );
};
