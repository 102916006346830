import { type PossibleAnswerListOutputItem } from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { TagType } from '../TagType';

const RESOURCE = 'possible-answers';

const possibleAnswerEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPossibleAnswerList: build.query<PossibleAnswerListOutputItem[], void>({
      query: () => `/${RESOURCE}/`,
      providesTags: [TagType.PossibleAnswer, TagType.UserProfile],
    }),
  }),
  overrideExisting: false,
});

export const { useGetPossibleAnswerListQuery } = possibleAnswerEndpoints;
