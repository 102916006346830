import { type EvidenceTypeListOutputItem } from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { TagType } from '../TagType';

const RESOURCE = 'evidence-types';

const evidenceTypeEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getEvidenceTypeList: build.query<EvidenceTypeListOutputItem[], void>({
      query: () => `/${RESOURCE}/`,
      providesTags: [TagType.EvidenceType],
    }),
  }),
  overrideExisting: false,
});

export const { useGetEvidenceTypeListQuery } = evidenceTypeEndpoints;
