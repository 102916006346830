import { format } from 'date-fns';
import {
  type AssessmentControlPointShortcomingCreateInput,
  type AssessmentControlPointShortcomingCreateOutput,
  type AssessmentControlPointShortcomingDetailOutput,
  type AssessmentControlPointShortcomingListOutputItem,
  type AssessmentControlPointShortcomingUpdateInput,
  type AssessmentControlPointShortcomingUpdateOutput,
} from 'src/__generated__/InternalApiTypes';
import { baseApi } from 'src/services/farmApi/baseApi';
import {
  type CreateParamsWithTenantId,
  type DeleteParamsWithTenantId,
  type DetailParamsWithTenantId,
  transformObject,
  type UpdateParamsWithTenantId,
} from 'src/services/farmApi/helpers';
import { TagType } from 'src/services/farmApi/TagType';
import { DATE_SERVER_FORMAT } from 'src/settings';

const RESOURCE = 'assessment-control-point-shortcomings';

export type TransformedAssessmentControlPointNonConformityListOutputItem = Omit<
  AssessmentControlPointShortcomingListOutputItem,
  'created_at' | 'modified_at' | 'reported_date'
> & {
  created_at: Date;
  modified_at: Date;
  reported_date: Date;
};

type TransformedAssessmentControlPointNonConformitiesDetailOutput = Omit<
  AssessmentControlPointShortcomingDetailOutput,
  'created_at' | 'modified_at' | 'reported_date'
> & {
  created_at: Date;
  reported_date: Date;
  modified_at: Date;
};

const responseTransformations = {
  created_at: (value: string): Date => new Date(value),
  modified_at: (value: string): Date => new Date(value),
  reported_date: (value: string): Date => new Date(value),
};

const requestTransformations = {
  created_at: (value: Date): string => format(value, DATE_SERVER_FORMAT),
  modified_at: (value: Date): string => format(value, DATE_SERVER_FORMAT),
  reported_date: (value: Date | string): string =>
    typeof value === 'object' ? format(value, DATE_SERVER_FORMAT) : value,
};

const assessmentControlPointNonConformitiesEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAssessmentControlPointNonConformity: build.query<
      TransformedAssessmentControlPointNonConformitiesDetailOutput,
      DetailParamsWithTenantId
    >({
      query: ({ id, tid }) => `/tid/${tid}/${RESOURCE}/${id}/`,
      providesTags: (result, _error, _params) => [
        { type: TagType.AssessmentControlPointNonConformities, id: result?.uuid },
      ],
      transformResponse: (data: AssessmentControlPointShortcomingDetailOutput) =>
        transformObject(data, responseTransformations),
    }),

    createAssessmentControlPointNonConformity: build.mutation<
      AssessmentControlPointShortcomingCreateOutput,
      CreateParamsWithTenantId<AssessmentControlPointShortcomingCreateInput>
    >({
      query: ({ tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/create/`,
        method: 'POST',
        body: transformObject(body, requestTransformations),
      }),
      invalidatesTags: (result) => [
        { type: TagType.AssessmentControlPoint, id: result?.control_point_id },
      ],
    }),

    deleteAssessmentControlPointNonConformity: build.mutation<void, DeleteParamsWithTenantId>({
      query: ({ id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/delete/`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, params) => [
        { type: TagType.AssessmentControlPoint, id: params.parentResourceId },
        { type: TagType.AssessmentControlPointNonConformities, id: params.id },
      ],
    }),

    updateAssessmentControlPointNonConformity: build.mutation<
      AssessmentControlPointShortcomingUpdateOutput,
      UpdateParamsWithTenantId<AssessmentControlPointShortcomingUpdateInput>
    >({
      query: ({ id, tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/update/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, _error, params) => [
        { type: TagType.AssessmentControlPoint, id: result?.control_point_id },
        { type: TagType.AssessmentControlPointNonConformities, id: params.id },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateAssessmentControlPointNonConformityMutation,
  useDeleteAssessmentControlPointNonConformityMutation,
  useGetAssessmentControlPointNonConformityQuery,
  useUpdateAssessmentControlPointNonConformityMutation,
} = assessmentControlPointNonConformitiesEndpoints;
