import { isEqual } from 'lodash';
import {
  type AssessmentPreliminaryQuestionAnswerListOutputItem,
  ControlPointAnswerTypeEnum,
  type PossibleAnswerListOutputItem,
  type PossibleAnswerSetListOutputItem,
  type PreliminaryQuestionListOutputItem,
} from 'src/__generated__/InternalApiTypes';
import { FormikDateField } from 'src/components/formikcomponents/FormikDateField';
import { FormikMultiSelectField } from 'src/components/formikcomponents/FormikMultiSelectField';
import { FormikSelectField } from 'src/components/formikcomponents/FormikSelectField';
import { FormikTextField } from 'src/components/formikcomponents/FormikTextField';
import { FormikToggleButtonField } from 'src/components/formikcomponents/FormikToggleButtonField';
import { type DynamicObjectType } from 'src/global';
import { commonStrings } from 'src/languages/en-UK';
import { generateNumberInputValidation } from 'src/utils/getNumberInputValidation';
import * as Yup from 'yup';

import {
  type FormikFieldConfig,
  type FormikFieldProps,
} from './AssessmentPreliminaryQuestionsStep.types';

const STANDARD_VALIDATORS = {
  [ControlPointAnswerTypeEnum.FarmLevelInputDate]: Yup.date(),
  [ControlPointAnswerTypeEnum.FarmLevelInputNumber]: Yup.number()
    .transform((_value, originalValue) =>
      originalValue ? Number(originalValue.toString()?.replace(/,/, '.')) : originalValue,
    )
    .positive(commonStrings('minErrorMessage', { min: 0 })),
  [ControlPointAnswerTypeEnum.FarmLevelInputString]: Yup.string(),
  [ControlPointAnswerTypeEnum.SingleChoiceFarmLevelButtonGroup]: Yup.string(),
  [ControlPointAnswerTypeEnum.SingleChoiceFarmLevelDropdown]: Yup.string(),
  [ControlPointAnswerTypeEnum.MultipleChoiceFarmLevelDropdown]: Yup.string(),
};

const STANDARD_COMPONENTS = {
  [ControlPointAnswerTypeEnum.FarmLevelInputDate]: FormikDateField,
  [ControlPointAnswerTypeEnum.FarmLevelInputNumber]: FormikTextField,
  [ControlPointAnswerTypeEnum.FarmLevelInputString]: FormikTextField,
  [ControlPointAnswerTypeEnum.SingleChoiceFarmLevelButtonGroup]: FormikToggleButtonField,
  [ControlPointAnswerTypeEnum.SingleChoiceFarmLevelDropdown]: FormikSelectField,
  [ControlPointAnswerTypeEnum.MultipleChoiceFarmLevelDropdown]: FormikMultiSelectField,
};

export const haveAnswersChanged = (
  initialAnswers: DynamicObjectType,
  newAnswers: DynamicObjectType,
) => {
  const stringifiedInitialAnswers: DynamicObjectType = {};
  Object.keys(initialAnswers).forEach((key) => {
    if (typeof initialAnswers[key] !== 'string') {
      stringifiedInitialAnswers[key] = JSON.stringify(initialAnswers[key]);
    } else {
      stringifiedInitialAnswers[key] = initialAnswers[key];
    }
  });

  return !isEqual(stringifiedInitialAnswers, newAnswers);
};

export const convertFormikValueToRequestItem = (
  field: FormikFieldConfig,
  value: number | string,
) => {
  const staticFieldTypes = [
    ControlPointAnswerTypeEnum.SingleChoiceFarmLevelButtonGroup,
    ControlPointAnswerTypeEnum.SingleChoiceFarmLevelDropdown,
    ControlPointAnswerTypeEnum.MultipleChoiceFarmLevelDropdown,
  ];

  // Use the pure value (a UUID) for select inputs
  if (
    staticFieldTypes.includes(field.type as ControlPointAnswerTypeEnum) &&
    field.possibleAnswerIds.length > 1
  ) {
    return {
      possible_answer_id: value,
      preliminary_question_id: field.questionId,
    };
  }

  if (field.type === ControlPointAnswerTypeEnum.FarmLevelInputNumber) {
    return value
      ? {
          possible_answer_id: field.possibleAnswerIds[0],
          preliminary_question_id: field.questionId,
          dynamic_answer: parseFloat(value?.toString?.().replace?.(',', '.')),
        }
      : {
          ...(field.initialValue && { dynamic_answer: field.initialValue }),
          possible_answer_id: field.initialValue ? field.possibleAnswerIds[0] : '',
          preliminary_question_id: field.questionId,
        };
  }

  return {
    possible_answer_id: field.possibleAnswerIds[0],
    preliminary_question_id: field.questionId,
    dynamic_answer: value,
  };
};

export const createFormikFields = (
  possibleAnswerList: PossibleAnswerListOutputItem[],
  possibleAnswerSetList: PossibleAnswerSetListOutputItem[],
  preliminaryQuestionAnswerList: AssessmentPreliminaryQuestionAnswerListOutputItem[],
  preliminaryQuestionList: PreliminaryQuestionListOutputItem[],
  language: string,
): FormikFieldConfig[] => {
  // If any piece of required data is missing, return an empty array
  if (
    !preliminaryQuestionList?.length ||
    !possibleAnswerSetList?.length ||
    !possibleAnswerList?.length
  ) {
    return [];
  }

  const getPossibleAnswers = (question: PreliminaryQuestionListOutputItem) => {
    const possibleAnswerSet = possibleAnswerSetList.find(
      (answerSet) => answerSet.uuid === question.possible_answer_set_id,
    );
    return possibleAnswerList.filter((possibleAnswer) =>
      possibleAnswerSet.ordered_possible_answers.find(
        (answerInSet) => answerInSet.uuid === possibleAnswer.uuid,
      ),
    );
  };

  const getExistingAnswer = (questionId: string): string | number | Date => {
    if (!preliminaryQuestionAnswerList?.length) {
      return '';
    }
    const answer = preliminaryQuestionAnswerList.find(
      (a) => a.preliminary_question_id === questionId,
    );
    return answer?.dynamic_answer || answer?.possible_answer_id || '';
  };

  return preliminaryQuestionList.map((question) => {
    const possibleAnswers = getPossibleAnswers(question);
    // Since it is dynamic, it must be any type.
    const Component = (STANDARD_COMPONENTS as any)[question.answer_type];
    const options =
      possibleAnswers.length > 1
        ? possibleAnswers.map((item) => ({
            value: item.uuid,
            label: item.main_text,
          }))
        : undefined;

    const id = question.uuid;
    const questionId = question.uuid;
    const validator = generateNumberInputValidation(
      // Since it is dynamic, it must be any type.
      (STANDARD_VALIDATORS as any)[question.answer_type],
      question,
    );
    const isNumberType = question.answer_type === ControlPointAnswerTypeEnum.FarmLevelInputNumber;
    return {
      id,
      questionId,
      validator,
      initialValue: getExistingAnswer(questionId),
      possibleAnswerIds: possibleAnswers.map((possibleAnswer) => possibleAnswer.uuid),
      type: question.answer_type,
      categoryName: question.category_name,
      renderAs: (props: FormikFieldProps) =>
        props.formik.values[id] === undefined ? null : (
          <Component
            key={id}
            id={id}
            label={question.main_text}
            onKeyDown={(ev: React.KeyboardEvent<HTMLDivElement>) => {
              if (ev.key === 'Enter') {
                // It's MUI Textfield error so we should use any.
                (ev?.target as any)?.blur?.();
                ev.preventDefault();
              }
            }}
            options={options}
            placeholder={question.main_text}
            {...props}
            onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              let val = e.target.value;
              if (isNumberType) {
                if (val.endsWith(',') || val.endsWith('.')) {
                  val = val.slice(0, -1);
                }
                props.formik.setFieldValue(id, val);
              }
              props.formik.handleBlur(e);
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              const val = e.target.value;
              if (isNumberType) {
                const maxPlacesRegex = question.max_decimal_places ?? '';
                const seperatorRegex = language === 'en' ? '.' : ',';
                const numberRegex = new RegExp(
                  `^$|^-?\\d{0,}(\\${seperatorRegex})?\\d{0,${maxPlacesRegex}}$`,
                );
                const isValidNumber = numberRegex.test(val);
                if (isValidNumber) {
                  props.formik.handleChange(e);
                }
              } else {
                props.formik.handleChange(e);
              }
            }}
            value={
              isNumberType && language !== 'en'
                ? props.formik.values[id]?.toString?.().replace?.('.', ',')
                : props.formik.values[id]
            }
          />
        ),
    };
  });
};
