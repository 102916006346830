import { type PayloadAction } from '@reduxjs/toolkit';

import { type AssessmentConfigState } from '../types';

/**
 * Handles malus point changes in the matrix.
 *
 * @param rowId - Changed cultivation ID
 * @param colId - Changed standard ID
 * @param malusPoint - Malus point entered by user
 * @param isChecked - Whether this change is an addition or deletion to the selection set
 */
export const changeMalusPoint = (
  state: AssessmentConfigState,
  action: PayloadAction<{
    rowId: string;
    colId: string;
    malusPoint: number | undefined;
    isChecked: boolean;
  }>,
): void => {
  const { rowId, colId, malusPoint, isChecked } = action.payload;

  const selectedItem = state.selections.find((s) => s.rowId === rowId && s.colId === colId);
  if (selectedItem) {
    selectedItem.malusPoint = isChecked ? malusPoint : undefined;
  }
};
