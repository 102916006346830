import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  IconButton,
  InputAdornment,
  Skeleton,
  type StandardTextFieldProps,
  TextField,
} from '@mui/material';
import { type FormikProps, type FormikValues } from 'formik';
import { useState } from 'react';
import { appColors } from 'src/theme';

type FormikPasswordTextFieldProps = Partial<StandardTextFieldProps> & {
  id: string;
  formik: FormikProps<FormikValues>;
  isLoading?: boolean;
};

export const FormikPasswordTextField = ({
  formik,
  id,
  label,
  disabled = false,
  isLoading = false,
  placeholder = '',
  ...restProps
}: FormikPasswordTextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return isLoading || !formik ? (
    <Box
      border={1}
      borderColor={appColors.border}
      borderRadius={1}
      paddingX="14px"
      paddingY="15px"
      sx={restProps?.sx}
      width="100%"
    >
      <Skeleton
        animation="wave"
        variant="text"
        width="33%"
      />
    </Box>
  ) : (
    <TextField
      disabled={isLoading || disabled}
      error={formik.touched[id] && Boolean(formik.errors[id])}
      fullWidth
      helperText={formik.touched[id] && (formik.errors[id] as string)}
      id={id}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
              onClick={handleClickShowPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      label={label}
      name={id}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      placeholder={typeof label === 'string' ? placeholder || label : placeholder}
      type={showPassword ? 'text' : 'password'}
      value={formik.values[id]}
      {...restProps}
    />
  );
};
